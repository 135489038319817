export const Walking = () => {
  return (
    <svg
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 2.25C10.25 3.35457 9.35457 4.25 8.25 4.25C7.14543 4.25 6.25 3.35457 6.25 2.25C6.25 1.14543 7.14543 0.25 8.25 0.25C9.35457 0.25 10.25 1.14543 10.25 2.25Z"
        fill="currentColor"
      />
      <path
        d="M10.75 9.2L13.325 10.05C13.85 10.225 14.125 10.8 13.95 11.325C13.8 11.725 13.425 12 13 12C12.875 12 12.775 11.975 12.675 11.95L9.67499 10.95C9.39999 10.85 9.19999 10.65 9.07499 10.4L8.59999 9.3L7.92499 12.675L10.35 14.45C10.6 14.625 10.75 14.925 10.75 15.25V20.75C10.75 21.3 10.3 21.75 9.74999 21.75C9.19999 21.75 8.74999 21.3 8.74999 20.75V15.75L6.04999 13.8L5.44999 16.7C5.42499 16.85 5.34999 17 5.24999 17.125L1.74999 21.375C1.54999 21.625 1.27499 21.75 0.974989 21.75C0.749989 21.75 0.524989 21.675 0.349989 21.525C-0.0750108 21.175 -0.125011 20.55 0.224989 20.125L3.57499 16.05L5.29999 7.625L4.24999 8.025L3.22499 10.625C3.07499 11.025 2.69999 11.25 2.29999 11.25C2.17499 11.25 2.04999 11.225 1.92499 11.175C1.39999 10.975 1.17499 10.375 1.37499 9.875L2.62499 6.875C2.72499 6.625 2.92499 6.425 3.17499 6.325L6.67499 4.95C6.92499 4.825 7.22499 4.75 7.52499 4.75C8.27499 4.75 8.92499 5.175 9.27499 5.8C9.32499 5.9 10.75 9.2 10.75 9.2Z"
        fill="currentColor"
      />
    </svg>
  );
};
