import { useI18n } from "@wordpress/react-i18n";

import { VisibilityOff } from "@/components/icons/VisibilityOff";

type Props = {
  showDate?: boolean;
};

export const ConcealedEntry: React.FC<Props> = ({ showDate }) => {
  const { __ } = useI18n();
  return (
    <div
      aria-label={__(`Entry is concealed (till selected).`)}
      className="concealed"
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        marginLeft: showDate ? "0" : "48px", // width of date badge to get center
      }}
    >
      <VisibilityOff color="textPrimary" />
    </div>
  );
};
