import { Asymmetric } from "@/crypto/DOCryptoBasics";

export const privateKeyFromPEM = (pemString: string) => {
  return crypto.subtle.importKey(
    "pkcs8",
    Asymmetric.PEM.toBytes(pemString),
    { name: "RSA-OAEP", hash: "SHA-1" },
    true,
    ["decrypt"],
  );
};
