import { useI18n } from "@wordpress/react-i18n";

import { D1Modal } from "@/components/D1Modal";

type ModalProps = {
  message: string;
  onClose: () => void;
};

export const FileUploadMessagesModal: React.FC<ModalProps> = ({
  message,
  onClose,
}) => {
  const { __ } = useI18n();
  return (
    <D1Modal
      title={__("Some files couldn't be uploaded")}
      onRequestClose={onClose}
      sx={{
        width: "420px",
      }}
    >
      <div
        sx={{
          pl: 4,
          pr: 4,
          "& pre": {
            mb: 4,
            lineHeight: "1.3rem",
            fontSize: "1",
            fontFamily: "inherit",
          },
        }}
      >
        <pre>{message}</pre>
      </div>
    </D1Modal>
  );
};
