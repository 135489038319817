import { ThemeUICSSObject } from "@theme-ui/css";
import { FlexItem } from "@wordpress/components";

import { Item } from "@/components/ItemGroup/Item";

type ItemProps = {
  children: React.ReactNode;
  sx?: ThemeUICSSObject;
  className?: string;
};

export const SelectItemWrapper = (props: ItemProps) => {
  return (
    <Item
      sx={{
        pb: 3,
      }}
      className={props.className}
    >
      <FlexItem
        sx={{
          flexGrow: 1,
          "& label": {
            fontSize: 1,
            color: "textPrimary",
          },
          "&& .components-input-control__container": {
            width: "auto",
          },
        }}
      >
        {props.children}
      </FlexItem>
    </Item>
  );
};
