import { useI18n } from "@wordpress/react-i18n";

import { AnsweredCheck } from "@/components/icons/AnsweredCheck";

export const PromptAnswered = () => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        color: "answered",
        fontWeight: "medium",
        display: "flex",
        gap: 2,
        alignItems: "center",
        lineHeight: "heading",
      }}
    >
      <AnsweredCheck />
      <span>{__("Answered")}</span>
    </div>
  );
};
