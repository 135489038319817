import { CreateNewEntryOpts } from "@/data/repositories/EntryRepository";

export const hasPrefilledContent = (opts?: CreateNewEntryOpts) => {
  if (
    !opts ||
    ((opts?.prefill?.richTextJson?.length ?? 0) === 0 &&
      (opts?.prefill?.tags?.length ?? 0) === 0)
  ) {
    return true;
  }
  return false;
};
