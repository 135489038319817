export const Love: React.FC = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="16.5" fill="#CF2F89" stroke="#A6266E" />
      <path
        d="M17 26.9999L15.55 25.6999C13.8667 24.1832 12.475 22.8749 11.375 21.7749C10.275 20.6749 9.4 19.6874 8.75 18.8124C8.1 17.9374 7.64583 17.1332 7.3875 16.3999C7.12917 15.6666 7 14.9166 7 14.1499C7 12.5832 7.525 11.2749 8.575 10.2249C9.625 9.1749 10.9333 8.6499 12.5 8.6499C13.3667 8.6499 14.1917 8.83324 14.975 9.1999C15.7583 9.56657 16.4333 10.0832 17 10.7499C17.5667 10.0832 18.2417 9.56657 19.025 9.1999C19.8083 8.83324 20.6333 8.6499 21.5 8.6499C23.0667 8.6499 24.375 9.1749 25.425 10.2249C26.475 11.2749 27 12.5832 27 14.1499C27 14.9166 26.8708 15.6666 26.6125 16.3999C26.3542 17.1332 25.9 17.9374 25.25 18.8124C24.6 19.6874 23.725 20.6749 22.625 21.7749C21.525 22.8749 20.1333 24.1832 18.45 25.6999L17 26.9999Z"
        fill="white"
      />
    </svg>
  );
};
