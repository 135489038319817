import type { Theme } from "@theme-ui/core";

import { journalColorValues } from "@/styles/JournalColorValues";
import { surfaceColors, surfaceShadows } from "@/styles/SurfaceValues";
import { userProfileColors } from "@/styles/UserProfileColors";

export const dayOneBlue = journalColorValues.dayOneBlue;

// These basically match the Tailwind CSS defaults.
// Link 👉 https://unpkg.com/browse/tailwindcss@3.0.18/stubs/defaultConfig.stub.js
const scale = {
  0: "0px",
  1: "0.25rem",
  1.5: "0.375rem",
  2: "0.5rem",
  2.5: "0.75rem",
  3: "1rem",
  3.5: "1.25rem",
  4: "1.5rem",
  5: "2rem",
  6: "2.5rem",
  7: "3rem",
  8: "3.5rem",
  9: "4rem",
  10: "5rem",
  11: "6rem",
  12: "7rem",
  13: "8rem",
  14: "9rem",
  15: "10rem",
  16: "11rem",
  17: "12rem",
  18: "13rem",
  19: "14rem",
  20: "15rem",
  21: "16rem",
  22: "18rem",
  23: "20rem",
  24: "24rem",
};

// z-index Values
export const Z_INDEX_JOURNAL_SIDEBAR = 10000;
export const Z_INDEX_FULL_SCREEN_MEDIA = 100000;
export const Z_INDEX_SNACKBAR = Z_INDEX_FULL_SCREEN_MEDIA + 1;
export const Z_INDEX_SCREEN_READER_ONLY = -100000;
// the Gutenberg block inserter is 31: https://github.com/WordPress/gutenberg/blob/trunk/packages/base-styles/_z-index.scss#L121
export const Z_INDEX_EDITOR_PANEL_TOOLBAR = 32;
export const Z_INDEX_OVERLAY = Z_INDEX_JOURNAL_SIDEBAR - 1;

export const breakpoints = [600, 768, 1024, 1280, 1536];

export const theme: Theme = {
  config: {
    useColorSchemeMediaQuery: "system", // set initial colorMode from media query if no color mode is stored
    initialColorModeName: "light", // default that is only used if media query detection fails
    useLocalStorage: false, // don't store color mode in localStorage
  },
  breakpoints: breakpoints.map((bp) => `${bp}px`),

  sizes: {
    // useful if you want to do max-width to a screen size
    screenSm: "640px",
    screenMd: "768px",
    screenLg: "1024px",
    screenXl: "1280px",
    screen2xl: "1536px",
    ...scale,
  },
  space: scale,

  fonts: {
    body: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    prompt: '"Noto Serif", "Times New Roman", serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
    supportForm:
      '"Avenir Next", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", "sans-serif"',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    light: 200,
    body: 400,
    medium: 500,
    heading: 600,
    bold: 700, // h3
    bolder: 750, // h2
    boldest: 850, // h1
  },
  lineHeights: {
    body: 1.5,
    preview: 1.35,
    heading: 1.4,
    // take a small amount of the sizes from scale
    1: 1,
    2: 1.3,
    3: 1.8,
    4: 2,
  },
  letterSpacings: {
    sm: "0.05em",
  },
  radii: {
    0: 0,
    1: 2,
    2: 4,
    3: 9,
    4: 16,
    5: 9999,
    6: "100%",
    rounded: "2rem",
    circle: "100%",
    none: 0,
    xs: 3,
    sm: 6,
    md: 9,
    lg: 12,
    xl: 15,
  },
  shadows: {
    shadowDefault: "2px 2px 13px 0px rgb(0 0 0 / 20%)",
    shadowSm: "0px 0px 2px rgb(0 0 0 / 20%)",
    shadowMd: "0px 2px 4px rgb(0 0 0 / 15%)",
    focusInner: `0 0 0 2px ${dayOneBlue} inset`,
    focusInnerLg: `0 0 0 3px ${dayOneBlue} inset`,

    // To be used with surfaces, see https://dayonedesignp2.wordpress.com/2022/11/25/web-client-surface-styles-i1/
    shadow1: surfaceShadows.shadow1,
    shadow2: surfaceShadows.shadow2,
    shadow3: surfaceShadows.shadow3,
  },
  transitions: {
    standard: "0.1s ease-in-out",
  },
  colors: {
    red: "#D55745",
    redLighter: "#FF644C",

    // This is text colors that contrast with the background color.
    //   primary -> Most contrast (default)
    //   secondary -> Less contrast
    //   etc...
    textPrimary: "rgba(0, 0, 0, 0.85)",
    textSecondary: "rgba(0, 0, 0, 0.60)",
    textTertiary: "rgba(0, 0, 0, 0.35)",

    textPrimaryInverse: "rgba(255, 255, 255, 0.9)",
    textSecondaryInverse: "rgba(255, 255, 255, 0.70)",

    textOnColor: "white",

    // Text that's against a dark or colored background
    textPrimaryLight: "#fff",
    textSecondaryLight: "#666",

    /*******************************
     * Elevation Background Colors
     *******************************
     * In Light mode most stuff is white and contrasted with box shadows.
     * When you're doing dark mode you can't use shadows to differentiate stuff from the background.
     *
     * To solve this problem in dark mode we use _lighter colors_ for UI as it
     * gets metaphorically closer to the screen and darker as they get farther away.
     */

    // For more information, see this post: https://dayonedesignp2.wordpress.com/2022/11/25/web-client-surface-styles-i1/
    // In light mode, surfaces are separated by shadows and some color change, there are 3 color levels in light mode.
    // In dark mode, shadows aren't very visible, so we use 5 color levels. The first 3 levels have sensible default
    // analogs between light and dark mode, so they're defined here and below in the "dark" object.
    // There are surfaces that change level between light mode and dark mode. Those will use special combination colors
    // below, like "surface_light1_dark4". By defining these colors in the theme for both light and dark modes, we can
    // save the trouble of having to use a hook to detect light and dark mode in lots of components throughout the app.
    surface_light1_dark1: surfaceColors.light1,
    surface_light2_dark2: surfaceColors.light2,
    surface_light3_dark3: surfaceColors.light3,
    surface_light3_dark1: surfaceColors.light3,

    // ## Modifiers. Overlay on top of the light surfaces. Look for their analogs in the "dark" object
    surfaceHover: surfaceColors.lightHover,
    surfaceActive: surfaceColors.lightActive,
    surfaceDrag: surfaceColors.lightDrag,

    // A drag color without an alpha channel
    surfaceDragOpaque: "#F0F0F0",

    surfaceHighlight: "#E4F6FF",
    surfaceHighlightAnimateStart: "rgba(228, 246, 255, 1)",
    surfaceHighlightAnimateEnd: "rgba(228, 246, 255, 0)",

    // Surfaces that change level based on the color mode
    // there are less shades in light mode, so we use the same color for lightBase_dark3,4,5
    surface_light1_dark4: surfaceColors.light1,
    surface_light1_dark5: surfaceColors.light1,
    surface_light3_dark4: surfaceColors.light3,
    surface_light1_dark3: surfaceColors.light1,
    surface_light2_dark5: surfaceColors.light2,
    surface_light2_dark4: surfaceColors.light2,
    surface_light1_dark2: surfaceColors.light1,
    surface_toolbar: surfaceColors.light1,
    surface_light2_dark1: surfaceColors.light2,

    // Black transparent overlay for when the journal
    // sidebar is open on mobile or when modals are open.
    bgSkrim: "rgba(0,0,0,0.5)",

    borderLight: "rgba(255, 255, 255, 0.1)",

    // Non-transparent version of borderPrimary
    // useful when you want to overlap borders
    // and don't want them to be darker on the overlap
    borderPrimaryOpaque: "rgba(230, 230, 230, 1)",

    borderPrimary: "rgba(0, 0, 0, 0.1)",
    borderHighlight: "rgba(0, 0, 0, 0.4)",

    // Used for the journal color picker
    journalColorPickerBorder: "rgb(0 0 0 / 20%)",

    checkboxBg: "#f3f5f7",
    checkboxBorder: "#717678",

    // Used for buttons
    // Defaults to "Day One Blue" but will probably be overridden
    // by the journal color which is selected
    primary: dayOneBlue,
    // some buttons will use this color
    // https://www.figma.com/file/0x0Uz7UKOFzWujIANg0Psv/Premium-Assets?type=design&node-id=0-1&mode=design&t=W8KYUYYDvOY1rxaZ-0
    primaryPurple: "#4C4C9E",
    primaryPurpleHover: "#2D2D75",
    secondaryPurple: "#F2F2FE",

    buttonTextDisabled: "#757575",
    forest: "#556a5a",
    primaryButtonText: journalColorValues.dayOneBlueDark,

    // Editor specific theme options
    editorTextDefault: "var(--theme-ui-colors-textPrimary)",
    editorTextSecondary: "var(--theme-ui-colors-textSecondary)",
    editorBgSecondary: "#f0f0f0",
    editorBorderSecondary: "rgba(0, 0, 0, 0.1)",
    editorTextDisabled: "rgba(0,0,0, 0.25)",
    editorBorderDefault: "#ccc",
    editorBorderHighlight: "rgba(0, 0, 0, 0.15)",
    editorBgHighlight: "#e5e5e5",
    // No dark mode equivalent exists for this due to this overlay being dark in both modes at the moment.
    editorMediaFullscreenBorderHighlight: "rgba(255, 255, 255, 0.15)",
    editorFullscreenMediaViewerBg: "#fff",
    editorFullscreenMediaViewerHeaderBg: "#272727",

    mainToolbarBg: "#1E1E1E",
    contextToolbarBg: "#1E1E1E",
    contextToolbarBgActive: "#565656",

    // Journal Text Colors
    // We use the opposite of the current color mode - dark/light, for higher contrast
    dayOneBlue: journalColorValues.dayOneBlueDark,
    aqua: journalColorValues.aquaDark,
    green: journalColorValues.greenDark,
    honey: journalColorValues.honeyDark,
    watermelon: journalColorValues.watermelonDark,
    salmon: journalColorValues.salmonDark,
    journalBlue: journalColorValues.journalBlueDark,
    pewter: journalColorValues.pewterDark,
    tortilla: journalColorValues.tortillaDark,
    fire: journalColorValues.fireDark,
    lavender: journalColorValues.lavenderDark,
    journalRed: journalColorValues.journalRedDark,
    charcoal: journalColorValues.charcoalDark,
    stone: journalColorValues.stoneDark,
    lime: journalColorValues.limeDark,
    hotPink: journalColorValues.hotPinkDark,
    magenta: journalColorValues.magentaDark,
    iris: journalColorValues.irisDark,

    // Warning Background Colors
    bgWarning: "#fef8ee",
    bgSuccess: "#eff9f1",
    bgError: "rgba(231, 76, 60, 0.10)",

    warningLink: "#0088cc",

    lightShade: "rgba(0, 0, 0, 0.2)",
    // Sync spinner
    meterProgress: "#6C6C6C",

    calendarFocus: "#1A1A1A",
    check: "#5FD48A",
    answered: "#009047",
    loadingBackground:
      "linear-gradient(270deg, rgba(240,240,240,1) 8%, rgba(255,255,255,1) 18%, rgba(240,240,240,1) 40%);",
    ...userProfileColors,

    modes: {
      dark: {
        // Surfaces!
        surface_light1_dark1: surfaceColors.dark1,
        surface_light2_dark2: surfaceColors.dark2,
        surface_light3_dark3: surfaceColors.dark3,
        surface_light3_dark1: surfaceColors.dark1,

        // Surface modifiers
        surfaceHover: surfaceColors.darkHover,
        surfaceActive: surfaceColors.darkActive,
        surfaceDrag: surfaceColors.darkDrag,

        // A drag color without an alpha channel
        surfaceDragOpaque: "#4F4F4F",

        surfaceHighlight: "#434F56",
        surfaceHighlightAnimateStart: "rgba(67, 79, 86, 1)",
        surfaceHighlightAnimateEnd: "rgba(67, 79, 86, 0)",

        // Surfaces that change level based on the color mode
        surface_light1_dark4: surfaceColors.dark4,
        surface_light1_dark5: surfaceColors.dark5,
        surface_light3_dark4: surfaceColors.dark4,
        surface_light1_dark3: surfaceColors.dark3,
        surface_light2_dark4: surfaceColors.dark4,
        surface_light2_dark5: surfaceColors.dark5,
        surface_light1_dark2: surfaceColors.dark2,
        surface_light2_dark1: surfaceColors.dark1,
        surface_toolbar: surfaceColors.darkToolbar,

        textPrimary: "rgba(255, 255, 255, 0.9)",
        textSecondary: "rgba(255, 255, 255, 0.7)",
        textTertiary: "rgba(255, 255, 255, 0.5)",

        buttonTextDisabled: "#999999",
        primaryButtonText: dayOneBlue,

        primaryPurple: "#6C6CBE",
        primaryPurpleHover: "#434396",
        secondaryPurple: "rgba(255, 255, 255, 0.05)",

        checkboxBg: "#6c6c6c",
        checkboxBorder: "#8a8a8a",

        textOnColor: "rgba(0, 0, 0, 0.85)",

        borderPrimary: "rgba(255, 255, 255, 0.1)",
        borderHighlight: "rgba(255, 255, 255, 0.4)",
        borderPrimaryOpaque: "rgba(90, 90, 90, 1)",

        textPrimaryLight: "#000",
        textSecondaryLight: "#666",

        mainToolbarBg: "#fff",
        contextToolbarBg: "#4F4F4F",
        contextToolbarBgActive: "#7B7B7B",

        editorTextDefault: "#fff",
        editorTextDisabled: "rgba(255, 255, 255, 0.5)",
        editorBgHighlight: "rgba(255, 255, 255, 0.5)",
        editorBgSecondary: "#1e1e1e",
        journalColorPickerBorder: "rgb(255 255 255 / 20%)",

        // Journal Text Colors
        // We use the opposite of the current color mode - dark/light, for higher contrast
        dayOneBlue: journalColorValues.dayOneBlueLight,
        aqua: journalColorValues.aquaLight,
        green: journalColorValues.greenLight,
        honey: journalColorValues.honeyLight,
        watermelon: journalColorValues.watermelonLight,
        salmon: journalColorValues.salmonLight,
        journalBlue: journalColorValues.journalBlueLight,
        pewter: journalColorValues.pewterLight,
        tortilla: journalColorValues.tortillaLight,
        fire: journalColorValues.fireLight,
        lavender: journalColorValues.lavenderLight,
        journalRed: journalColorValues.journalRedLight,
        charcoal: journalColorValues.charcoalLight,
        stone: journalColorValues.stoneLight,
        lime: journalColorValues.limeLight,
        hotPink: journalColorValues.hotPinkLight,
        magenta: journalColorValues.magentaLight,
        iris: journalColorValues.irisLight,

        // Warning Background Colors
        bgWarning: "#4c4a47",
        bgSuccess: "#405143",
        warningLink: "#99cfeb",

        forest: "#495a4f",

        lightShade: "rgba(255, 255, 255, 0.2)",
        // Sync spinner
        meterProgress: "#A9A9A9",

        calendarFocus: "#FFFFFF",
        loadingBackground:
          "linear-gradient(270deg, rgba(30,30,30,1) 8%, rgba(50,50,50,1) 18%, rgba(30,30,30,1) 40%);",

        editorFullscreenMediaViewerBg: "#121212",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      color: "textPrimary",
      background: "surface_light1_dark1",
    },
  },
};
