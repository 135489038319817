import * as Base64Arraybuffer from "base64-arraybuffer";

import { Utf8 } from "@/crypto/utf8";

type DigestOptions = {
  secret: Uint8Array;
  body: string;
};

const algorithm = { name: "HMAC", hash: "SHA-256" };

export async function generateHMAC({
  secret,
  body,
}: DigestOptions): Promise<string> {
  const key = await crypto.subtle.importKey("raw", secret, algorithm, false, [
    "sign",
    "verify",
  ]);

  const signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    Utf8.toUintArray(body),
  );

  return Base64Arraybuffer.encode(signature);
}
