import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { Item } from "@/components/ItemGroup";
import { RemoveSyncDataModal } from "@/components/Settings/Account/RemoveSyncDataModal";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";

export const RemoveSyncData: React.FC = () => {
  const { __ } = useI18n();

  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <>
      <Item>
        <FlexItem>
          <p sx={{ color: "journalRed" }}>
            {__("Remove Day One sync data from servers")}
          </p>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={__("Remove all sync data from Day One servers")}
            onClick={() => setShowConfirm(true)}
            title={__("Remove")}
          />
        </FlexItem>
      </Item>
      {showConfirm && (
        <RemoveSyncDataModal onRequestClose={() => setShowConfirm(false)} />
      )}
    </>
  );
};
