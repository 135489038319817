export const paragraphStyles = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-paragraph": {
      lineHeight: 3,
      fontSize: 2,
      fontWeight: "body",
      mark: {
        paddingY: "4px",
      },
    },
  },
};
