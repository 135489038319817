import { TextareaControl as WpTextareaControl } from "@wordpress/components";
import { TextareaControlProps } from "@wordpress/components/build-types/textarea-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

interface Props
  extends WordPressComponentProps<TextareaControlProps, "textarea", false> {
  resize?: boolean;
}
export const TextareaControl: React.FC<Props> = (props) => {
  const allowResize = props.resize ? undefined : "none";
  return (
    <div
      sx={{
        "& .components-textarea-control__input": {
          resize: allowResize,
          backgroundColor: "transparent",
          color: "textPrimary",
          borderColor: "borderPrimary",
        },
      }}
    >
      <WpTextareaControl {...props} />
    </div>
  );
};
