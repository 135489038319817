export type SyncStatus = "IDLE" | "UPLOADING" | "DOWNLOADING" | "ERROR";

// Just a convenience so we don't have to type string literals everywhere
export const syncStates: Record<SyncStatus, SyncStatus> = {
  IDLE: "IDLE",
  UPLOADING: "UPLOADING",
  DOWNLOADING: "DOWNLOADING",
  ERROR: "ERROR",
};

export type SyncState = typeof syncStates;

export interface SyncWorkerMessage {
  name: string;
}
export const workerMessage_runSync = { name: "runSyncEvent" };
export const workerMessage_stopSync = { name: "stopSyncEvent" };
export const workerMessage_runPush = { name: "runPushEvent" };
export const SYNC_DELAY_TIME = 5000; // 5 seconds
