import { Theme, ThemeUICSSObject } from "@theme-ui/css";
import { TimePicker, DatePicker, Popover } from "@wordpress/components";
import { dateI18n, getSettings } from "@wordpress/date";
import { __ } from "@wordpress/i18n";
import { useCallback, useState } from "react";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { useDevice } from "@/data/hooks/LayoutProvider";

interface Props {
  initialDate: Date;
  isAllDay: boolean;
  changeDate: (date: Date, toggleAllDay?: boolean) => void;
  anchorRef: React.MutableRefObject<HTMLElement | null>;
  handleFocusOutside: () => void;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "left-start"
    | "left-end"
    | "right-start"
    | "right-end";
}

export const DateTimePicker: React.FC<Props> = ({
  initialDate,
  isAllDay,
  changeDate,
  anchorRef,
  handleFocusOutside,
  placement,
}) => {
  const { isMobile } = useDevice();
  // Once entry sync is fixed (#2786), we should rely on that data instead of local state
  const [entryDate, setEntryDate] = useState(initialDate);
  const [isEntryAllDay, setIsEntryAllDay] = useState(isAllDay);

  const onDateChange = useCallback(
    (date: Date, toggleAllDay?: boolean) => {
      if (toggleAllDay === true) {
        setIsEntryAllDay(!isEntryAllDay);
      }
      setEntryDate(date);
      changeDate(date, toggleAllDay);
    },
    [isEntryAllDay, changeDate, setEntryDate, setIsEntryAllDay],
  );

  const today = new Date();
  const { formats } = getSettings();
  const localizedDate = dateI18n(
    formats.date,
    today,
    -today.getTimezoneOffset(),
  );

  const todaySelector = `&&& button[aria-label='${localizedDate}']`;
  const todaysStyles: ThemeUICSSObject = {};
  todaysStyles[todaySelector] = {
    backgroundColor: "dayOneBlue",
    color: "textPrimaryInverse",
  };

  return (
    <Popover
      sx={{
        "&": {
          // This is a CSS variable that is used for most hover/active states
          "--wp-admin-theme-color-darker-10": (theme) =>
            // NOTE: this can't just be "textPrimary" because theme-ui doesn't know this is a color
            theme.colors?.primary,

          // This is the root wrapper for the calendar that
          // floats it above the rest of the page.
          // It also differentitates it from what it's above
          // and serves as the main background color.
          "& .components-popover__content": {
            p: 3,
            outlineColor: "transparent",
            boxShadow: "shadow2",
            borderRadius: "sm",
            color: "textPrimary",
          },

          // don't display the timezone picker
          ".components-datetime__timezone": {
            display: "none",
          },

          // All the borders that wrap around the inputs
          "&&&& .components-input-control__backdrop, .components-datetime__time-separator":
            {
              borderColor: "transparent",
            },
          // round the corners of the time inputs
          "&&& .components-input-control__backdrop": {
            borderRadius: 2,
          },

          ".components-datetime__time-field": {
            borderRadius: 2,
            border: (theme: Theme) =>
              `1px solid ${theme.colors?.borderPrimary}`,
            boxShadow: "shadow1",
          },

          // background for the inputs
          ".components-input-control__container": {
            bg: "transparent",

            // mostly for the Arrow next to the month dropdown
            fill: "textSecondary",
          },

          // The text color for all the inputs
          // besides the AM/PM buttons
          [`&&& .components-input-control__input,
          && .css-intonn-Select-fontSizeStyles-sizeStyles,
          &&&& .components-select-control__input
          `]: {
            color: "textPrimary",
          },

          // Month and year title above the calendar
          // where you click on the days
          ".CalendarMonth_caption": {
            color: "textPrimary",
          },

          // AM/PM buttons
          ".components-button-group .components-button": {
            // this is used for the border
            boxShadow: (theme: Theme) =>
              `inset 0 0 0 1px ${theme.colors?.borderPrimary}`,

            // This shows when you've selected the button
            // with tab or clicking
            "&:focus": {
              boxShadow: (theme: Theme) =>
                `inset 0 0 0 2px ${theme.colors?.primary}`,
            },
          },

          // ---- Calendar ----
          // these below are "calendar" styles that are below
          // the input selectors
          // Color for the days of the month
          ".CalendarDay__default": {
            color: "textPrimary",
          },
          // Selected day of the month on the calendar
          "&&& .CalendarDay__selected": {
            bg: "primary",
            color: "textPrimaryLight",
          },
          // bg for calendar
          ".CalendarMonth, .CalendarMonthGrid, .DayPicker__horizontal, .components-datetime__date .CalendarDay":
            {
              bg: "transparent",
            },
          "&& .components-heading": {
            color: "textPrimary",
          },
        },
        ...todaysStyles,
      }}
      anchor={anchorRef.current}
      focusOnMount={isMobile ? undefined : "firstElement"}
      onFocusOutside={handleFocusOutside}
      animate={true}
      placement={placement || "bottom"}
      offset={2}
      shift
    >
      <DatePicker
        currentDate={entryDate}
        onChange={(date) => onDateChange(date ? new Date(date) : new Date())}
      />
      <div
        sx={{
          mt: 3,
          ".components-datetime__time": {
            display: "flex",
            flexDirection: "column-reverse",
            gap: 3,
          },
          ...(isEntryAllDay && {
            ".components-datetime__time fieldset:first-of-type": {
              pointerEvents: "none",
              opacity: 0.4,
            },
          }),
        }}
      >
        <TimePicker
          currentTime={entryDate}
          onChange={(date) => onDateChange(date ? new Date(date) : new Date())}
          is12Hour={true}
        />
      </div>
      <ToggleSetting
        sx={{
          padding: 0,
        }}
        label={__("All day")}
        checked={isEntryAllDay}
        onChange={() => {
          const newDate = new Date(entryDate);
          newDate.setHours(0, 0, 0, 0);
          onDateChange(newDate, true);
        }}
      />
    </Popover>
  );
};
