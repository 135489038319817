import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Item } from "@/components/ItemGroup";
import { AppleConnect } from "@/components/Settings/Account/AppleConnect";
import { Attribute } from "@/components/Settings/Attribute";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { Label } from "@/components/Settings/Label";
import { AppleLogo } from "@/components/icons/AppleLogo";
import { primaryViewState } from "@/view_state/ViewStates";

export const Apple: React.FC = observer(() => {
  const user = primaryViewState.user;
  const userStore = d1Classes.userStore;

  const [showEdit, setShowEdit] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const hasApple = user!.credentials.includes("SIWA");
  const { __ } = useI18n();

  function handleClose() {
    setShowEdit(false);
  }

  const buttonLabel = hasApple ? __("Unlink") : __("Link Account");
  const buttonLabelAria = hasApple
    ? __("Unlink Apple Account")
    : __("Link Apple Account");

  const unlinkAccount = async () => {
    const res = await d1Classes.fetchWrapper.fetchAPI("/users/siwa/unlink", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      userStore.sync();
      setShowUnlink(false);
      setError(null);
    } else {
      setShowUnlink(false);
      setError(__("Error unlinking Apple account. Please try again."));
    }
  };

  return (
    <>
      {showEdit && (
        <AppleConnect title={buttonLabel} handleClose={handleClose} />
      )}

      {showUnlink && (
        <ConfirmDialog
          handleClose={() => setShowUnlink(false)}
          message={__("Are you sure you want to unlink your Apple account?")}
          isDestructive={true}
          handleAction={unlinkAccount}
        />
      )}

      <Item>
        <FlexItem
          sx={{
            flexGrow: 0,
            "& svg path": { fill: "textPrimary" },
          }}
        >
          <div sx={{ width: "24px", height: "24px", textAlign: "center" }}>
            <AppleLogo />
          </div>
        </FlexItem>
        <FlexItem
          sx={{
            ml: 2,
            flexGrow: 1,
            "& svg": {
              top: "4px",
              left: "-4px",
              position: "relative",
              fill: "textSecondary",
            },
          }}
        >
          <Label>{__("Apple ID")}</Label>
          <Attribute
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {error || __("Sign in with your Apple Credentials")}
          </Attribute>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={buttonLabelAria}
            onClick={
              hasApple
                ? () => {
                    setShowUnlink(!showUnlink);
                  }
                : () => setShowEdit(!showEdit)
            }
            title={buttonLabel}
          />
        </FlexItem>
      </Item>
    </>
  );
});

Apple.displayName = "Apple";
