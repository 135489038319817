export type GoogleFile = {
  name: string;
  id: string;
};

export const CLIENT_ID =
  process.env.NODE_ENV === "test"
    ? "TEST_CLIENT_ID"
    : process.env.VITE_GOOGLE_OAUTH_CLIENT_ID || "";

if (CLIENT_ID === "") {
  throw new Error("CLIENT_ID not found.");
}
// Authorization scopes required by the Google Drive API to retrieve
// file from AppData folder - required for Google Store/Retrieve master key
// multiple scopes can be included, separated by spaces.
export const SCOPES_GOOGLE_DRIVE =
  "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata";
