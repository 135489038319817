import { observer } from "mobx-react-lite";

import { useDevice } from "@/data/hooks/LayoutProvider";
import { useClassInstance } from "@/hooks/d1_hooks";
import { LoggedOut } from "@/layouts/LoggedOut";
import { Empty } from "@/pages/prefilledUrl/Empty";
import { EntryPreview } from "@/pages/prefilledUrl/EntryPreview";
import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";
import { Prompt } from "@/pages/prefilledUrl/Prompt";
import { viewStates } from "@/view_state/ViewStates";

const PrefilledEntryPreview: React.FC = observer(() => {
  const state = useClassInstance(
    () => new PrefilledEntryViewState(viewStates.primary),
  );

  const { isMobile } = useDevice();

  const bg =
    state.type === "entry" ? "surface_light2_dark1" : "surface_light1_dark1";

  return (
    <LoggedOut background={bg}>
      {state.type === "entry" && (
        <EntryPreview state={state} isMobile={isMobile} />
      )}
      {state.type === "prompt" && <Prompt state={state} isMobile={isMobile} />}
      {state.type === "empty" && <Empty state={state} isMobile={isMobile} />}
    </LoggedOut>
  );
});

PrefilledEntryPreview.displayName = "NewPrefilledEntryPreview";

export default PrefilledEntryPreview;
