import { makeAutoObservable, reaction } from "mobx";

import { PrimaryViewState } from "@/view_state/PrimaryViewState";

export class MasterKeyViewState {
  hasSavedKeyToGDrive = false;
  hasSavedKeyToCloudKit = false;
  hasSavedPDFKey = false;
  showKeyModal = true;
  workingWithGDrive = false;
  workingWithCloudKit = false;

  constructor(private primary: PrimaryViewState) {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => this.primary.user?.master_key_storage,
      (storage) => {
        if (storage) {
          this.setHasSavedKeyToGDrive(
            storage.includes("drive") || storage.includes("driveWeb"),
          );
          this.setHasSavedKeyToCloudKit(storage.includes("cloudkit"));
          this.setHasSavedPDFKey(storage.includes("pdfDownloadWeb"));
        }
      },
      {
        name: "MasterKeyViewState_watchStorage",
        fireImmediately: true,
      },
    );
  }

  setWorkingWithGDrive(working: boolean) {
    this.workingWithGDrive = working;
  }

  setShowKeyModal(show: boolean) {
    this.showKeyModal = show;
  }

  setHasSavedKeyToGDrive(hasSaved: boolean) {
    this.hasSavedKeyToGDrive = hasSaved;
  }

  setHasSavedKeyToCloudKit(hasSaved: boolean) {
    this.hasSavedKeyToCloudKit = hasSaved;
  }

  setHasSavedPDFKey(hasSaved: boolean) {
    this.hasSavedPDFKey = hasSaved;
  }

  setWorkingWithCloudKit(working: boolean) {
    this.workingWithCloudKit = working;
  }

  get hasKeyBackedUpRemotely() {
    return this.hasSavedKeyToGDrive || this.hasSavedKeyToCloudKit;
  }

  get hasKeyBackedUp() {
    return (
      this.hasSavedKeyToGDrive ||
      this.hasSavedKeyToCloudKit ||
      this.hasSavedPDFKey
    );
  }
}
