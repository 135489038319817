import { z } from "zod";

import { Sentry } from "@/Sentry";

class ZodAPIDecoderError extends Error {
  constructor() {
    super();
    this.name = "ZodAPIDecoderError";
  }
}

export const safelyDecodeAPIResponse = <T extends z.ZodTypeAny>(
  schema: T,
  value: unknown,
): z.infer<T> => {
  try {
    return schema.parse(value);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error("Zod decoding error", error.errors);
    }
    Sentry.captureException(error);
    throw new ZodAPIDecoderError();
  }
};
