import { createContext, useContext } from "react";

interface EditorPanelContext {
  editorWidth: number;
  editorHeight: number;
}

export const editorPanelContext = createContext<EditorPanelContext>({
  editorWidth: 0,
  editorHeight: 0,
});

export const useEditorPanelContext = () => useContext(editorPanelContext);
