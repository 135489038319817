import md5wasm from "md5-wasm";

import { BinaryFormat } from "@/crypto/types/BinaryFormat";
import { Chunk } from "@/crypto/types/Chunk";
import { Utf8 } from "@/crypto/utf8";
import { toHex } from "@/crypto/utils";

export const parseD1 = async (data: Uint8Array) => {
  const d1Header = extract(
    {
      start: 0,
      length: 2,
    },
    data,
  );
  if (!areBuffersEqual(Utf8.toUintArray("D1"), d1Header)) {
    console.error("Could Not Parse Supplied D1 With Header");
    console.error("Data:", data);
    throw new Error("Could Not Parse Supplied D1 With Header");
  }

  const binaryFormatC = {
    start: 3,
    length: 1,
  };

  const match = extract(binaryFormatC, data)[0] as BinaryFormat;
  if (match > 2 || match < 0) {
    throw new Error("Binary Format Not Recognized");
  }
  const hasLockedKey = match !== 0;
  const fingerprintChunkStart = getEnd(binaryFormatC);
  const fingerprintChunkLength = hasLockedKey ? 32 : 0;
  const fingerprintChunk = {
    start: fingerprintChunkStart,
    length: fingerprintChunkLength,
  };
  const signatureLengthChunkStart = getEnd(fingerprintChunk);
  const signatureLengthChunkLength = hasLockedKey ? 2 : 0;
  const signatureLengthChunk = {
    start: signatureLengthChunkStart,
    length: signatureLengthChunkLength,
  };
  const signatureLength = readBigEndianInt(extract(signatureLengthChunk, data));
  const signatureChunkStart = getEnd(signatureLengthChunk);
  const signatureChunkLength = hasLockedKey ? signatureLength : 0;
  const signatureC = {
    start: signatureChunkStart,
    length: signatureChunkLength,
  };
  const lockedKeyChunkStart = getEnd(signatureC);
  const lockedKeyChunkLength = hasLockedKey ? 256 : 0;
  const lockedKeyChunk = {
    start: lockedKeyChunkStart,
    length: lockedKeyChunkLength,
  };
  const ivChunkStart = getEnd(lockedKeyChunk);
  const ivChunk = {
    start: ivChunkStart,
    length: 12,
  };
  const length = (((data.byteLength - 32) | 0) - getEnd(ivChunk)) | 0;
  const cipherTextChunkStart = getEnd(ivChunk);
  const cipherTextChunk = {
    start: cipherTextChunkStart,
    length: length,
  };
  const gcmChunkStart = getEnd(cipherTextChunk);
  const gcmChunk = {
    start: gcmChunkStart,
    length: 16,
  };
  const checkSumChunkStart = getEnd(gcmChunk);
  const checkSumChunk = {
    start: checkSumChunkStart,
    length: 16,
  };

  const checkSumRemoved = extract(
    { start: 0, length: data.byteLength - 16 },
    data,
  );
  const hash = await md5wasm(checkSumRemoved);
  const checkSumBytes = extract(checkSumChunk, data);
  const givenHash = toHex(checkSumBytes);

  if (hash !== givenHash) {
    throw new Error("MD5 Checksum is not correct");
  }

  return {
    binaryFormat: match,
    lockedKeyInfo:
      match > 0 // EncryptedContent
        ? {
            fingerprint: extract(fingerprintChunk, data),
            signature: extract(signatureC, data),
            lockedKey: extract(lockedKeyChunk, data),
          }
        : undefined,
    iv: extract(ivChunk, data),
    cipherText: extract(cipherTextChunk, data),
    gcmTag: extract(gcmChunk, data),
    checksum: checkSumBytes,
  };
};

/*********  Helper Functions **********/

const readBigEndianInt = (buff: Uint8Array) => {
  return buff.reduce(function (memo, i) {
    return ((memo << 8) + i) | 0;
  }, 0);
};

const extract = (chunk: Chunk, buff: Uint8Array) => {
  return buff.slice(chunk.start, getEnd(chunk));
};

const areBuffersEqual = (buffer1: Uint8Array, buffer2: Uint8Array) => {
  if (buffer1.byteLength !== buffer2.byteLength) {
    return false;
  }
  for (let i = 0; i < buffer1.byteLength; i++) {
    if (buffer1[i] !== buffer2[i]) {
      return false;
    }
  }
  return true;
};

export const getEnd = (chunk: Chunk) => {
  return (chunk.start + chunk.length) | 0;
};
