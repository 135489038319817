import { liveQuery } from "dexie";

import { FetchWrapper } from "@/api/FetchWrapper";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64, toBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import { CommentDBRow, CommentFromAPI } from "@/data/db/migrations/comment";
import { CommentReactionDBRow } from "@/data/db/migrations/reaction";
import { Outbox } from "@/data/models/Outbox";
import { CommentSendable, OutboxResult } from "@/data/models/OutboxTypes";
import { CommentReactionRepository } from "@/data/repositories/CommentReactionRepository";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { createRichTextJsonString } from "@/utils/rtj";
import { uuid } from "@/utils/uuid";

export class CommentRepository {
  constructor(
    protected db: DODexie,
    private fetch: FetchWrapper,
    private syncStateRepository: SyncStateRepository,
    private outbox: Outbox,
    private journalRepository: JournalRepository,
    private vaultRepository: VaultRepository,
    private commentReactionRepository: CommentReactionRepository,
  ) {}

  async getCommentsForOneEntry(journalId: string, entryId: string) {
    const cursor = await this.syncStateRepository.getCommentsCursor(
      journalId,
      entryId,
    );
    const cursorQs = cursor ? `?cursor=${cursor}` : "";
    const res = await this.fetch.fetchAPI(
      `/journals/${journalId}/entries/${entryId}/comments${cursorQs}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (res.ok) {
      const data = await res.json();
      this.syncStateRepository.setCommentsCursor(
        journalId,
        entryId,
        data.cursor,
      );
      if (data.comments.length > 0) {
        await this.insertCommentsFromAPI(data.comments);
      }
      if (!data.finished) {
        this.getCommentsForOneEntry(journalId, entryId);
      }
    }
  }

  async getCommentById(commentId: string) {
    return await this.db.comments.get(commentId);
  }

  async pushCommentToServerFromLocal(
    outboxItem: CommentSendable,
  ): Promise<OutboxResult> {
    const comment = await this.db.comments.get(outboxItem.commentId);
    if (!comment) {
      return {
        result: "failed",
        message: `Comment ${outboxItem.commentId} not found in local DB`,
      };
    }
    const res = await this.pushCommentToServer(
      comment.journal_id,
      comment.entry_id,
      comment.content,
    );
    if (res.ok) {
      await this.getCommentsForOneEntry(comment.journal_id, comment.entry_id);
      await this.db.comments.delete(comment.id);
      return { result: "success" };
    } else {
      return { result: "failed", message: "Failed to push comment to server" };
    }
  }

  async updateCommentFromLocal(
    outboxItem: CommentSendable,
  ): Promise<OutboxResult> {
    const comment = await this.db.comments.get(outboxItem.commentId);
    if (!comment) {
      return {
        result: "failed",
        message: `Comment ${outboxItem.commentId} not found in local DB`,
      };
    }
    const res = await this.updateCommentOnServer(comment);
    if (res.ok) {
      await this.getCommentsForOneEntry(comment.journal_id, comment.entry_id);
      return { result: "success" };
    } else {
      return {
        result: "failed",
        message: `Failed to update comment ${comment.id} on server. Status: ${res.status}`,
      };
    }
  }

  private async insertCommentsFromAPI(comments: CommentFromAPI[]) {
    let content = "";
    let reactionsToUpsert: CommentReactionDBRow[] = [];
    const processed = await Promise.all(
      comments.map(async (c) => {
        const journal = await this.journalRepository.getById(c.journal_id);
        if (journal?.e2e && !c.deleted_at) {
          content = await DOCrypto.Comment.decrypt(
            c.content,
            "Unable to decrypt comment",
            this.vaultRepository,
          );
        } else {
          content = Utf8.decode(fromBase64(c.content));
        }

        // Extract the reactions that we will need to update as well
        const reactions = (c.reactions || []).map((reaction) => {
          return {
            id: reaction.id,
            journal_id: c.journal_id,
            entry_id: c.entry_id,
            comment_id: c.id,
            user_id: reaction.user_id,
            reaction: reaction.reaction,
            timestamp: reaction.timestamp,
          } as CommentReactionDBRow;
        });
        if (!c.deleted_at) {
          reactionsToUpsert = reactionsToUpsert.concat(reactions);
        }

        return {
          id: c.id,
          author_id: c.author_id,
          journal_id: c.journal_id,
          entry_id: c.entry_id,
          created_at: c.created_at,
          updated_at: c.updated_at || "",
          deleted_at: c.deleted_at || "",
          content,
        } as CommentDBRow;
      }),
    );
    const deleted = processed.filter((c) => c.deleted_at !== "");
    const active = processed.filter((c) => c.deleted_at === "");

    await this.db.comments.bulkPut(active);
    await this.db.comments.bulkDelete(deleted.map((c) => c.id));

    await this.commentReactionRepository.bulkUpdate(active, reactionsToUpsert);
    await this.commentReactionRepository.bulkDelete(deleted);
  }

  async createLocalComment(
    journalId: string,
    entryId: string,
    content: string,
    userId: string,
  ) {
    const comment = {
      id: `local:${uuid()}`,
      author_id: userId,
      journal_id: journalId,
      entry_id: entryId,
      content: createRichTextJsonString([{ text: content }]),
      created_at: new Date().toISOString(),
      updated_at: "",
      deleted_at: "",
    } as CommentDBRow;
    await this.db.comments.put(comment);
    const sendable = {
      commentId: comment.id,
      type: "Comment",
      action: "CREATE",
    } as CommentSendable;
    this.outbox.add(sendable);
  }

  async updateLocalComment(comment: CommentDBRow, content: string) {
    await this.db.comments.update(comment.id, {
      content: createRichTextJsonString([{ text: content }]),
      updated_at: new Date().toISOString(),
    });
    const sendable = {
      commentId: comment.id,
      type: "Comment",
      action: "UPDATE",
    } as CommentSendable;
    this.outbox.add(sendable);
  }

  async getComments(journalId: string, entryId: string) {
    return await this.db.comments
      .where(["journal_id", "entry_id", "deleted_at"])
      .equals([journalId, entryId, ""])
      .sortBy("created_at");
  }

  subscribeToComments(
    journalId: string,
    entryId: string,
    callback: (comments: CommentDBRow[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const comments = await this.getComments(journalId, entryId);
      return comments;
    }).subscribe((comments) => callback(comments));
    return () => stream.unsubscribe();
  }

  async deleteCommentFromServer(
    outboxItem: CommentSendable,
  ): Promise<OutboxResult> {
    const comment = await this.db.comments.get(outboxItem.commentId);
    if (!comment) {
      return {
        result: "failed",
        message: `Comment ${outboxItem.commentId} not found in local DB`,
      };
    }
    const res = await this.fetch.fetchAPI(
      `/journals/${comment.journal_id}/entries/${comment.entry_id}/comments/${comment.id}`,
      {
        method: "DELETE",
      },
    );
    if (res.ok) {
      await this.db.comments.delete(comment.id);
      return { result: "success" };
    } else {
      return {
        result: "failed",
        message: `Failed to delete comment ${comment.id} from server: ${res.status}`,
      };
    }
  }

  async delete(commentId: string) {
    await this.db.comments.update(commentId, {
      deleted_at: new Date().toISOString(),
    });
    // If the comment is a local comment no need to try to delete it from the server
    if (commentId.startsWith("local:")) {
      return;
    }
    const sendable = {
      commentId: commentId,
      type: "Comment",
      action: "DELETE",
    } as CommentSendable;
    this.outbox.add(sendable);
  }

  async pushCommentToServer(
    journalId: string,
    entryId: string,
    comment: string,
  ) {
    const journal = await this.journalRepository.getById(journalId);
    let content = "";
    if (journal?.e2e) {
      const vault = await this.vaultRepository.getVaultByJournalId(journalId);
      if (vault) {
        content = await DOCrypto.Comment.encrypt(
          comment,
          vault.vault.keys[0],
          2,
        );
      }
    } else {
      content = toBase64(Utf8.toUintArray(comment));
    }
    return this.fetch.fetchAPI(
      `/journals/${journalId}/entries/${entryId}/comments`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content,
        }),
      },
    );
  }

  async updateCommentOnServer(comment: CommentDBRow) {
    let content = "";
    const journal = await this.journalRepository.getById(comment.journal_id);
    if (journal?.e2e) {
      const vault = await this.vaultRepository.getVaultByJournalId(
        comment.journal_id,
      );
      if (vault) {
        content = await DOCrypto.Comment.encrypt(
          comment.content,
          vault.vault.keys[0],
          2,
        );
      }
    } else {
      content = toBase64(Utf8.toUintArray(comment.content));
    }
    return this.fetch.fetchAPI(
      `/journals/${comment.journal_id}/entries/${comment.entry_id}/comments/${comment.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content,
        }),
      },
    );
  }

  async test_makeTestComment(
    journalId: string,
    entryId: string,
  ): Promise<CommentDBRow> {
    const x: CommentDBRow = {
      id: uuid(),
      author_id: uuid(),
      journal_id: journalId,
      entry_id: entryId,
      content: createRichTextJsonString([{ text: "This is a test comment" }]),
      created_at: new Date().toISOString(),
      updated_at: "",
      deleted_at: "",
    };
    await this.db.comments.put(x);
    return x;
  }
}
