import Dexie from "dexie";
import { createRoot } from "react-dom/client";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Cache } from "@/cache";
import { fromHex, toBase64, toHex } from "@/crypto/utils";
import { App } from "@/pages/app";
import { deleteIndexedDB } from "@/pages/logout/utils";
import { isDevelopment } from "@/utils/environment";
import { isDevEnvironment } from "@/utils/is-dev-environment";
import { ignoreErrors, tracePropagationTargets } from "@/utils/sentry";
import { viewStates } from "@/view_state/ViewStates";

if (isDevelopment()) {
  (window as any).d1 = d1Classes;
  (window as any).viewStates = viewStates;
  (window as any).syncService = d1MainThreadClasses.syncService;
  (window as any).minKey = Dexie.minKey;
  (window as any).maxKey = Dexie.maxKey;
  (window as any).toHex = toHex;
  (window as any).toBase64 = toBase64;
  (window as any).fromHex = fromHex;
}

window.onunhandledrejection = function (event) {
  // This is to monitor schema errors to see the number that we get.
  // and if we can properly capture them here.
  // If we can we can use this to detect if someones dexie schema has gotten into an error state
  // and if it has we can redirect them to the force logout page. But before we do that we
  // should see how often this is happening.
  if (event?.reason?.message?.startsWith("SchemaError")) {
    analytics.tracks.recordEvent(EVENT.schemaError, {
      error: event.reason,
    });
  }
  if (event?.reason?.message?.startsWith("VersionError")) {
    deleteIndexedDB();
  }
};

// bootstrap
if (!isDevelopment()) {
  Sentry.init({
    environment: isDevEnvironment() ? "development" : "production",
    release: isDevEnvironment()
      ? `dev:${process.env.APP_VERSION}`
      : process.env.APP_VERSION,
    dsn: `https://${process.env.VITE_SENTRY_PUBLIC_KEY}@o248881.ingest.sentry.io/${process.env.VITE_SENTRY_PROJECT_ID}`,
    ignoreErrors,
    integrations: [Sentry.browserTracingIntegration()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets,
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 20% of the transactions
  });

  Sentry.setTag(
    "app_version",
    `dayone_web@${process.env.APP_VERSION ?? "unknown"}`,
  );
}

Cache.init();
const container = document.getElementById("app");
const root = createRoot(container!);
root.render(<App />);
