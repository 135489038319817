import { useI18n } from "@wordpress/react-i18n";

import { MockContent } from "@/components/Search/MockContent";

export const EmptySearchHistoryAndFavoritesNavigation: React.FC = () => {
  const { __ } = useI18n();
  const percentages = ["60%", "30%", "90%"];

  return (
    <div
      sx={{
        position: "absolute",
        margin: "auto",
        inset: 0,
        width: "fit-content",
        height: "fit-content",
        textAlign: "center",
      }}
    >
      <div sx={{ display: "flex", pb: 3 }}>
        <div sx={{ display: "flex", gap: 1, flexDirection: "column", flex: 1 }}>
          {percentages.map((percentage) => (
            <MockContent key={percentage} height="12px" width={percentage} />
          ))}
        </div>
        <MockContent height="50px" width="50px" />
      </div>
      <p sx={{ fontSize: 4, fontWeight: "medium", pb: 2.5 }}>
        {__("Search History")}
      </p>
      <p sx={{ fontSize: 1, fontWeight: "body" }}>
        {__("Your recent search queries will appear here.")}
      </p>
    </div>
  );
};
