export const SleetNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0781 9.689C16.5161 9.689 15.9861 9.805 15.5231 10.036C15.1761 7.753 13.1911 6 10.8091 6C8.21209 6 6.09509 8.084 6.02909 10.665C4.47409 10.863 3.26709 12.187 3.26709 13.824C3.26609 15.577 4.68909 17 6.44209 17H17.0781C19.0961 17 20.7341 15.362 20.7341 13.344C20.7341 11.326 19.0961 9.689 17.0781 9.689V9.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0498 6.01402C12.3018 3.77002 14.1858 2.02002 16.4998 2.02002C16.4998 4.50202 18.5148 6.51402 20.9998 6.51402C20.9998 8.01702 20.2538 9.34002 19.1188 10.155"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 20L17.5 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 22L8.5 23"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99512 19.995C5.99212 19.995 5.99012 19.997 5.99012 20C5.99012 20.003 5.99212 20.005 5.99512 20.005C5.99812 20.005 6.00012 20.003 6.00012 20C6.00012 19.997 5.99812 19.995 5.99512 19.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9951 19.995C11.9921 19.995 11.9901 19.997 11.9901 20C11.9901 20.003 11.9921 20.005 11.9951 20.005C11.9981 20.005 12.0001 20.003 12.0001 20C12.0001 19.997 11.9981 19.995 11.9951 19.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 22.9901C13.997 22.9901 13.995 22.9921 13.995 22.9951C13.995 22.9981 13.997 23.0001 14 23.0001C14.003 23.0001 14.005 22.9981 14.005 22.9951C14.005 22.9921 14.003 22.9901 14 22.9901"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
