import { z } from "zod";

export const supportFormCategorySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const supportFormTopicSchema = z.object({
  id: z.string(),
  name: z.string(),
  category_ids: z.array(z.string()),
  help_pages: z
    .array(
      z.object({
        text: z.string(),
        link: z.string(),
      }),
    )
    .optional(),
  help_text: z.string().optional(),
  order: z.number().optional(),
  available_to_non_premium_users: z.boolean().optional(),
});

export const supportFormConfigSchema = z.object({
  categories: z.array(supportFormCategorySchema).nonempty(),
  topics: z.array(supportFormTopicSchema).nonempty(),
});

export type SupportFormConfig = z.infer<typeof supportFormConfigSchema>;
export type SupportFormCategory = z.infer<typeof supportFormCategorySchema>;
export type SupportFormTopic = z.infer<typeof supportFormTopicSchema>;
