import { useState } from "react";

import { generateZip } from "@/utils/export";

type Cancel = {
  fn: (() => void) | undefined;
};

export const useExport = () => {
  const [cancel, setCancel] = useState<Cancel | undefined>(undefined);
  return {
    generateZip,
    cancel,
    setCancel,
  };
};
