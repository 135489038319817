import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";

/**
 * Signs the PEM representation of a public key with a private key.
 */
export const signPublicKey = async ({
  userPrivateKey,
  userPublicKey,
}: {
  userPrivateKey: CryptoKey;
  userPublicKey: CryptoKey;
}) => {
  return Asymmetric.Private.signArrayBuffer({
    userPrivateKey,
    buffer: Utf8.toUintArray(await Asymmetric.Public.toPEM(userPublicKey)),
  });
};
