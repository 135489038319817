import { ThemeUICSSObject } from "@theme-ui/css";
import { Button } from "@wordpress/components";
import { Link } from "wouter";

const style: ThemeUICSSObject = {
  fontWeight: "body",
  fontSize: 1,
  letterSpacing: "-0.288px",
  display: "block",
  border: 0,
  borderBottom: "1px solid",
  borderColor: "borderPrimary",
  height: 8,
  lineHeight: "40px",
  margin: "0 auto",
  textAlign: "center",
  width: 24,
  "&:last-child": {
    border: 0,
  },
  "&&": {
    color: "textSecondary",
  },
};

export const FooterLink: React.FC<{
  children: React.ReactNode;
  external?: boolean;
  href: string;
}> = ({ children, external, href }) => {
  const button = () => (
    <Button sx={style} href={href}>
      {children}
    </Button>
  );

  return external ? (
    button()
  ) : (
    <Link asChild href={href}>
      {button()}
    </Link>
  );
};
