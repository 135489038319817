import { NotificationIcon } from "@/components/Notifications/NotificationIcon";
import { IconColors } from "@/components/Notifications/NotificationIcon";
type Props = {
  type: "offer" | "complete";
};

const icon = (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.20002 11.55L1.40002 8.74995L4.20002 5.94995L4.94377 6.6937L3.41252 8.22495H7.70002V9.27495H3.41252L4.94377 10.8062L4.20002 11.55ZM9.80002 8.04995L9.05627 7.3062L10.5875 5.77495H6.30002V4.72495H10.5875L9.05627 3.1937L9.80002 2.44995L12.6 5.24995L9.80002 8.04995Z"
      fill="currentColor"
    />
  </svg>
);

const color = {
  offer: IconColors.blue.color,
  complete: IconColors.green.color,
};
const backgroundColor = {
  offer: IconColors.blue.bgColor,
  complete: IconColors.green.bgColor,
};

export const TransferIcon: React.FC<Props> = ({ type }) => {
  return (
    <NotificationIcon
      icon={icon}
      color={color[type]}
      bgColor={backgroundColor[type]}
      size={14}
    />
  );
};
