/**
 * Use this function to exhaustively check a union type at runtime and specify
 * return types for all valid cases. It's great for when you can trust the input
 * to be the type it claims to be.
 *
 * @param switcher The value to switch on. Must be a member of the union type `T`.
 * @param cases An object that maps each member of the union type `T` to a function that returns a value of type `O`.
 * @returns The value returned by the function corresponding to the member of `T` that `switcher` matches.
 *
 * @example
 * // Define a union type
 * type MyUnion = "one" | "two" | "three";
 *
 * // Define an object with functions for each member of the union type
 * const myCases = {
 *   "one": () => 1,
 *   "two": () => 2,
 *   "three": () => 3
 * };
 *
 * // Call the switchOn function with a value that matches one of the members of the union type
 * const result = switchOn("two", myCases); // result is 2
 */
export function switchOnValue<T extends string | number | symbol, O>(
  switcher: T,
  cases: Record<T, () => O>,
): O {
  return cases[switcher]();
}

/**
 * Use this function to switch over a discriminated union type with a discriminating field and return a value based on the specific type of the object.
 *
 * @param keyName The name of the key to use for discriminating the union type.
 * @param value The value to switch on. Must have the key from keyName
 * @param matcher An object that maps each possible type of the discriminated union to a function that takes a value of that type and returns a value of type `O`.
 * @param defaultReturn A default return in case the keyName field is not found.
 * @returns The value returned by the matching function.
 *
 * @example
 * // Define a discriminated union type
 * type Animal = { type: "dog"; tailwagging: boolean } | { type: "cat"; paws: string };
 *
 * // Define a function for each possible type of the discriminated union
 * const speak = (animal: Animal): string => {
 *   return switchOnRecordType("type", animal, {
 *     dog: (dog) => `The dog wags its tail: ${dog.tailwagging}`,
 *     cat: (cat) => `The cat has soft paws: ${cat.paws}`
 *   });
 * };
 *
 * // Call the speak function with different animals
 * console.log(speak({ type: "dog", tailwagging: true })); // "The dog wags its tail: true"
 * console.log(speak({ type: "cat", paws: "soft" })); // "The cat has soft paws: soft"
 */
export function switchOnRecordKey<
  KeyName extends string | number | symbol,
  T extends { [key in KeyName]: string | number | symbol },
  O,
>(
  keyName: KeyName,
  value: T,
  matcher: {
    [Branch in T as Branch[KeyName]]: (a: Branch) => O;
  },
  defaultReturn: O,
): O {
  /* This is advanced typescript syntax. It's called a "mapped type" and it
  /* allows us to create a new type by mapping over the keys of an existing
  /* type. In this case, we're mapping over the keys of the union type
  /* `T["type"]`, which is the type of the `type` field of the discriminated
  /* union `T`. So if `T` is `{ type: "dog" } | { type: "cat" }`, then
  /* `T["type"]` is `"dog" | "cat"`, and the mapped type `{ [Branch in T as
  /* Branch["type"]]: (a: Branch) => O }` is
  /*
  /* {
  /*   dog: (a: { type: "dog" }) => O,
  /*   cat: (a: { type: "cat" }) => O
  /* }
  /* Learn more here: https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
  */
  const func = matcher[value[keyName]];
  if (typeof func === "function") {
    return func(value);
  } else {
    return defaultReturn;
  }
}

/**
 * A simpler, more specific version of `switchOnRecordKey` that only works for
 * objects with a discriminating "name" field.
 *
 * @param value The value to switch on. Must have a "name" field.
 * @param matcher An object that maps each possible type of the discriminated union to a function that takes a value of that type and returns a value of type `O`.
 * @param defaultReturn A default return in case the name field is not found.
 * @returns The value returned by the matching function.
 */
export function switchOnRecordName<
  T extends { name: string | number | symbol },
  ReturnType,
>(
  value: T,
  matcher: {
    [Branch in T as Branch["name"]]: (a: Branch) => ReturnType;
  },
  defaultReturn: ReturnType,
): ReturnType {
  return switchOnRecordKey("name", value, matcher, defaultReturn);
}

/**
 * A simpler, more specific version of `switchOnRecordKey` that only works for
 * objects with a discriminating "type" field.
 *
 * @param value The value to switch on. Must have a "type" field.
 * @param matcher An object that maps each possible type of the discriminated union to a function that takes a value of that type and returns a value of type `O`.
 * @param defaultReturn A default return in case the type field is not found.
 * @returns The value returned by the matching function.
 */
export function switchOnRecordType<
  T extends { type: string | number | symbol },
  ReturnType,
>(
  value: T,
  matcher: {
    [Branch in T as Branch["type"]]: (a: Branch) => ReturnType;
  },
  defaultReturn: ReturnType,
): ReturnType {
  return switchOnRecordKey("type", value, matcher, defaultReturn);
}

/**
 * A simpler, more specific version of `switchOnRecordKey` that only works for
 * objects with a discriminating "event" field.
 *
 * @param value The value to switch on. Must have a "event" field.
 * @param matcher An object that maps each possible type of the discriminated union to a function that takes a value of that type and returns a value of type `O`.
 * @param defaultReturn A default return in case the event field is not found.
 * @returns The value returned by the matching function.
 */
export function switchOnRecordEvent<
  T extends { event: string | number | symbol },
  ReturnType,
>(
  value: T,
  matcher: {
    [Branch in T as Branch["event"]]: (a: Branch) => ReturnType;
  },
  defaultReturn: ReturnType,
): ReturnType {
  return switchOnRecordKey("event", value, matcher, defaultReturn);
}

/**
 * Use this function to exhaustively check a union type at runtime and specify
 * return types for all valid cases. It's great for when you can't trust the
 * input to be the type it claims to be.
 *
 * **Note that this function can only enforce exhaustiveness if `T` is manually specified by the caller, since the type of `switcher` is `any`.**
 *
 * @param switcher The value to switch on. Can be any value.
 * @param cases An object that maps each member of the union type `T` to a function that returns a value of type `O`, as well as a "default" key that maps to a function that returns a value of type `O`.
 * @returns The value returned by the function corresponding to the member of `T` that `switcher` matches, or the value returned by the "default" function if `switcher` doesn't match any of the keys in `cases`.
 *
 * @example
 * // Define a union type
 * type MyUnion = "one" | "two" | "three";
 *
 * // Define an object with functions for each member of the union type
 * const myCases = {
 *   "one": () => 1,
 *   "two": () => 2,
 *   "three": () => 3,
 *   "default": () => 0
 * };
 *
 * // Call the switchOnUntrustedValue function with a value that may not match any of the members of the union type
 * const result = switchOnUntrustedValue<MyUnion, number>("four", myCases); // result is 0
 */
export function switchOnUntrustedValue<
  // Sets up the exhaustive check
  T extends string | number | symbol,
  // Specifies the expected return type
  O,
>(
  // Allows any value to be passed in
  switcher: any,
  // Returns values if the switcher matches
  cases: Record<T, (v: T) => O> & { default: (v: any) => O },
): O {
  if (switcher in cases) {
    return cases[switcher as T](switcher);
  } else {
    return cases.default(switcher);
  }
}
