import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { DODexie } from "@/data/db/dexie_db";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { ReactionDBRow, ReactionType } from "@/data/db/migrations/reaction";
import { uuid } from "@/utils/uuid";

export class ReactionRepository {
  constructor(
    protected db: DODexie,
    private fetch: FetchWrapper,
  ) {}

  async bulkAddForEntry(reactions: ReactionDBRow[]) {
    return await this.db.reactions.bulkPut(reactions);
  }

  async getReactionsForEntry(journalId: string, entryId: string) {
    const reactions = await this.db.reactions
      .where(["journal_id", "entry_id"])
      .equals([journalId, entryId])
      .toArray();
    return reactions;
  }

  async getReactionCountForJournalMember(journalId: string, userId: string) {
    const reactions = await this.db.reactions
      .where(["journal_id", "user_id"])
      .equals([journalId, userId])
      .count();
    return reactions;
  }

  async addReaction(globalEntryID: GlobalEntryID, reaction: ReactionType) {
    const res = await this.fetch.fetchAPI(
      `/shares/${globalEntryID.journal_id}/entries/${globalEntryID.id}/reaction`,
      {
        method: "put",
        body: JSON.stringify({ reaction }),
      },
    );
    if (res.status === 200) {
      const body = await res.json();
      this.db.reactions.put({
        id: body.id,
        journal_id: globalEntryID.journal_id,
        entry_id: globalEntryID.id,
        user_id: body.user_id,
        reaction: body.reaction,
        timestamp: body.timestamp,
      } as ReactionDBRow);
    } else {
      Sentry.captureException(
        new Error(`Unable to add/change reaction to entry ${globalEntryID.id}`),
      );
    }
  }

  async removeReaction(globalEntryID: GlobalEntryID, userID: string) {
    const res = await this.fetch.fetchAPI(
      `/shares/${globalEntryID.journal_id}/entries/${globalEntryID.id}/reaction`,
      {
        method: "delete",
      },
    );
    if (res.status === 200) {
      return await this.db.reactions
        .where({
          journal_id: globalEntryID.journal_id,
          entry_id: globalEntryID.id,
          user_id: userID,
        })
        .delete();
    }
  }

  subscribeToReactionsByEntry(
    callback: (reactions: ReactionDBRow[]) => void,
    journalId: string,
    entryId: string,
  ) {
    const stream = liveQuery(async () => {
      const reactions = await this.db.reactions
        .where(["journal_id", "entry_id"])
        .equals([journalId, entryId])
        .toArray();
      return reactions;
    }).subscribe((reactions) => callback(reactions));
    return () => stream.unsubscribe();
  }

  async removeLocalReactionsForJournals(journalIds: string[]) {
    await this.db.reactions.where("journal_id").anyOf(journalIds).delete();
  }

  async test_makeTestReaction(
    journal_id: string,
    entry_id: string,
  ): Promise<ReactionDBRow> {
    const x: ReactionDBRow = {
      id: uuid(),
      entry_id,
      journal_id,
      reaction: "like",
      user_id: uuid(),
    };
    await this.db.reactions.put(x);
    return x;
  }
}
