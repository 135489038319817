import { debounce } from "@wordpress/compose";

import { Outbox } from "@/data/models/Outbox";
import { JournalStore } from "@/data/stores/JournalStore";
import { SyncWorkerClient } from "@/worker/SyncWorkerClient";
import { SYNC_DELAY_TIME } from "@/worker/SyncWorkerTypes";

export class SyncService {
  private worker: SyncWorkerClient;

  constructor(
    private journalStore: JournalStore,
    outbox: Outbox,
  ) {
    this.worker = new SyncWorkerClient();
    outbox.onAdd(() => this.pushDelayed());
  }

  pushDelayed = debounce(() => {
    this.worker.push();
  }, SYNC_DELAY_TIME);

  sync() {
    this.worker.sync();
  }

  stop() {
    this.worker.stop();
  }

  terminate() {
    this.worker.terminate();
  }

  // Used after the user enters their encryption key
  async resyncJournals() {
    await this.journalStore.resetJournalPullState();
    this.sync();
  }
}
