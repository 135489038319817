export const headingBase = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 0H2.96857V5.34214H9.14V0H11.6086V13.5H9.14V7.65643H2.96857V13.5H0.5V0Z" />
    </svg>
  );
};
