export const SnowWind: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.84717 7.665C5.91517 5.084 8.09717 3 10.7732 3C13.2272 3 15.2732 4.753 15.6312 7.036C16.1082 6.805 16.6532 6.689 17.2332 6.689C19.3132 6.689 21.0002 8.326 21.0002 10.344C21.0002 12.362 19.3132 14 17.2332 14H11.7532"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 18.6107C8.77335 18.8603 9.12987 18.9991 9.5 19V19C10.3284 19 11 18.3284 11 17.5V17.5C11 16.6716 10.3284 16 9.5 16H7"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 20.3102C1.87626 20.7468 2.42361 20.9985 3 21V21C4.10457 21 5 20.1046 5 19V19C5 17.8954 4.10457 17 3 17H1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 11.3893C4.77335 11.1397 5.12987 11.0009 5.5 11V11C6.32843 11 7 11.6716 7 12.5V12.5C7 13.3284 6.32843 14 5.5 14H2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0049 16.9799C17.0019 16.9799 16.9999 16.9819 16.9999 16.9849C16.9999 16.9879 17.0019 16.9899 17.0049 16.9899C17.0079 16.9899 17.0099 16.9879 17.0099 16.9849C17.0099 16.9819 17.0079 16.9799 17.0049 16.9799"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 19.9852C13.997 19.9852 13.995 19.9872 13.995 19.9902C13.995 19.9932 13.997 19.9952 14 19.9952C14.003 19.9952 14.005 19.9932 14.005 19.9902C14.005 19.9872 14.003 19.9852 14 19.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
