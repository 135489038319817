import { journalColorNameFromColor } from "@/data/models/JournalFns";
import { dayOneBlue } from "@/styles/theme";

type Props = { color?: string };

export const Like: React.FC<Props> = ({ color }) => {
  const reactionColor = color ? journalColorNameFromColor(color) : undefined;

  return (
    <svg
      id="like"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "& circle": {
          fill: color ? color : dayOneBlue,
          stroke: reactionColor ? reactionColor : "#0087CC",
        },
      }}
    >
      <circle cx="17" cy="17" r="16.5" fill="black" stroke="black" />
      <path
        d="M17.4684 8C17.1716 8 16.8901 8.13182 16.7002 8.35982L12.4636 13.4437C12.164 13.8032 12 14.2562 12 14.7241V23C12 24.1046 12.8954 25 14 25H20.8394C21.5571 25 22.2198 24.6154 22.5758 23.9923L25.7365 18.4611C25.9092 18.159 26 17.8169 26 17.4689V16C26 14.8954 25.1046 14 24 14H19V9C19 8.44772 18.5523 8 18 8H17.4684Z"
        fill="white"
      />
      <path
        d="M9 15C8.44772 15 8 15.4477 8 16V24C8 24.5523 8.44772 25 9 25H10C10.5523 25 11 24.5523 11 24V16C11 15.4477 10.5523 15 10 15H9Z"
        fill="white"
      />
    </svg>
  );
};
