import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";

type Props = {
  state: PrefilledEntryViewState;
  isMobile?: boolean;
};
export const OpenInAppAction: React.FC<Props> = ({ state }) => {
  const { __ } = useI18n();

  return (
    <Button
      sx={{
        "&&&": {
          borderRadius: "sm",
          color: "white",
        },
        width: "100%",
        justifyContent: "center",
      }}
      variant="primary"
      href={state.appLink}
      onClick={state.openInApp}
    >
      {state.type === "entry" || state.type === "empty"
        ? __("Open in App")
        : __("Answer Prompt")}
    </Button>
  );
};
