export const Sad: React.FC = () => {
  return (
    <svg
      id="sad"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="url(#paint0_linear_1139_60463)"
        stroke="#B67500"
      />
      <path
        d="M26.5 23L26.8969 22.6959L26.5 22.178L26.1031 22.6959L26.5 23ZM26.5 23C26.8969 22.6959 26.8969 22.6959 26.897 22.696L26.8971 22.6962L26.8977 22.697L26.8999 22.6998L26.9079 22.7103L26.9382 22.7503C26.9646 22.7852 27.0029 22.8362 27.0516 22.9016C27.1488 23.0324 27.2874 23.2208 27.4534 23.4528C27.7852 23.9162 28.2285 24.5557 28.6726 25.2578C29.1156 25.9583 29.5652 26.7301 29.9059 27.4567C30.2376 28.1645 30.5 28.8985 30.5 29.5C30.5 31.7091 28.7091 33.5 26.5 33.5C24.2909 33.5 22.5 31.7091 22.5 29.5C22.5 28.8985 22.7624 28.1645 23.0941 27.4567C23.4348 26.7301 23.8844 25.9583 24.3274 25.2578C24.7715 24.5557 25.2148 23.9162 25.5466 23.4528C25.7126 23.2208 25.8512 23.0324 25.9484 22.9016C25.9971 22.8362 26.0354 22.7852 26.0618 22.7503L26.0921 22.7103L26.1001 22.6998L26.1023 22.697L26.1029 22.6962L26.103 22.696C26.1031 22.6959 26.1031 22.6959 26.5 23Z"
        fill="url(#paint1_linear_1139_60463)"
        stroke="#01237C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1054 12.553C20.8584 13.047 21.0586 13.6477 21.5526 13.8946L26.5526 16.3946C27.0465 16.6416 27.6472 16.4414 27.8942 15.9474C28.1412 15.4535 27.941 14.8528 27.447 14.6058L22.447 12.1058C21.953 11.8588 21.3523 12.059 21.1054 12.553Z"
        fill="#64460F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8946 12.553C13.1416 13.047 12.9414 13.6477 12.4474 13.8946L7.44743 16.3946C6.95345 16.6416 6.35278 16.4414 6.10579 15.9474C5.8588 15.4535 6.05903 14.8528 6.55301 14.6058L11.553 12.1058C12.047 11.8588 12.6477 12.059 12.8946 12.553Z"
        fill="#64460F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5542 27.3325C13.5527 27.3335 13.5511 27.3345 13.5496 27.3355C13.55 27.3352 13.5507 27.3348 13.5516 27.3343C13.556 27.3314 13.5659 27.3252 13.5812 27.3161C13.6119 27.2979 13.6638 27.2681 13.7361 27.2303C13.8809 27.1548 14.1062 27.0482 14.4043 26.9398C15.0006 26.723 15.8847 26.5 17.0001 26.5C18.1156 26.5 18.9996 26.723 19.5959 26.9398C19.8941 27.0482 20.1193 27.1548 20.2641 27.2303C20.3364 27.2681 20.3884 27.2979 20.419 27.3161C20.4343 27.3252 20.4442 27.3314 20.4487 27.3343L20.4476 27.3335L20.4466 27.3328L20.446 27.3325C20.4457 27.3323 20.4454 27.3321 21.0001 26.5L20.4454 27.3321M20.4506 27.3355C20.4507 27.3355 20.4507 27.3355 20.4507 27.3355C20.9097 27.6375 21.527 27.5125 21.8322 27.0547C22.1385 26.5952 22.0143 25.9743 21.5548 25.6679L21.0209 26.4688C21.5548 25.6679 21.5545 25.6677 21.5542 25.6675L21.5535 25.6671L21.552 25.6661L21.5487 25.6639L21.5403 25.6584L21.5166 25.6433C21.4979 25.6314 21.4732 25.6162 21.4426 25.5979C21.3814 25.5615 21.2966 25.5132 21.1892 25.4572C20.9746 25.3452 20.6687 25.2018 20.2793 25.0602C19.5006 24.777 18.3847 24.5 17.0001 24.5C15.6156 24.5 14.4996 24.777 13.7209 25.0602C13.3316 25.2018 13.0256 25.3452 12.811 25.4572C12.7036 25.5132 12.6188 25.5615 12.5576 25.5979C12.527 25.6162 12.5023 25.6314 12.4836 25.6433L12.46 25.6584L12.4515 25.6639L12.4482 25.6661L12.4467 25.6671L12.446 25.6675C12.4457 25.6677 12.4454 25.6679 13.0001 26.5L12.4454 25.6679C11.9859 25.9743 11.8617 26.5952 12.1681 27.0547C12.4732 27.5125 13.0905 27.6375 13.5495 27.3355"
        fill="#211603"
      />
      <path
        d="M26 19C26 21.2091 24.8807 21 23.5 21C22.1193 21 21 21.2091 21 19C21 16.7909 22.1193 16 23.5 16C24.8807 16 26 16.7909 26 19Z"
        fill="url(#paint2_linear_1139_60463)"
      />
      <path
        d="M13 19C13 21.2091 11.8807 21 10.5 21C9.11929 21 8 21.2091 8 19C8 16.7909 9.11929 16 10.5 16C11.8807 16 13 16.7909 13 19Z"
        fill="url(#paint3_linear_1139_60463)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_60463"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF788" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_60463"
          x1="26.5"
          y1="23"
          x2="26.5"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4876EC" />
          <stop offset="1" stopColor="#0F3CAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_60463"
          x1="23.5"
          y1="15"
          x2="23.5"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_60463"
          x1="10.5"
          y1="14"
          x2="10.5"
          y2="22"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
      </defs>
    </svg>
  );
};
