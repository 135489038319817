import * as Base64Arraybuffer from "base64-arraybuffer";

export const signArrayBuffer = async ({
  userPrivateKey,
  buffer,
}: {
  userPrivateKey: CryptoKey;
  buffer: ArrayBuffer;
}) => {
  const exportedKey = await crypto.subtle.exportKey("pkcs8", userPrivateKey);
  // This feels silly, we've got to export the key and then re-import
  // it in a different mode to get signing capabilities :eyeroll:
  const signingKey = await crypto.subtle.importKey(
    "pkcs8",
    exportedKey,
    { name: "RSASSA-PKCS1-v1_5", hash: "SHA-256" },
    true,
    ["sign"],
  );

  const result = Base64Arraybuffer.encode(
    await crypto.subtle.sign(
      {
        name: "RSASSA-PKCS1-v1_5",
      },
      signingKey,
      buffer,
    ),
  );
  return result;
};
