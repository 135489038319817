export const Dust: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10H18.061C18.829 10 19.597 9.70702 20.182 9.12102C21.354 7.94902 21.354 6.05002 20.182 4.87802C19.01 3.70602 17.111 3.70602 15.939 4.87802"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 13H20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.5 10H10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 16H11.665C12.305 16 12.945 16.244 13.433 16.732C14.409 17.708 14.409 19.291 13.433 20.268C12.457 21.245 10.874 21.244 9.897 20.268"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
