import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { ErrorMessage } from "@/components/ErrorMessage";
import { AppleLogo } from "@/components/icons/AppleLogo";
import { useExternalScript } from "@/hooks/useExternalScript";
import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { makeDebugLogger } from "@/utils/debugLog";

const logDebug = makeDebugLogger("SignInWithApple", true);

type AppleLoginProps = {
  action: (token: string) => void;
};

export const SignInWithApple: React.FC<AppleLoginProps> = ({ action }) => {
  const { inDarkMode } = useColorMode();
  const { __ } = useI18n();

  const status = useExternalScript(
    "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js",
    true,
  );

  const [token, setToken] = useState("");
  const [appleError, setAppleError] = useState<string | null>(null);

  const doAppleLogin = (token: string) => {
    action(token);
  };

  useEffect(() => {
    if (token !== "") {
      doAppleLogin(token);
    }
  }, [token]);

  useEffect(() => {
    if (status !== "ready") {
      return;
    }

    // We moved the app from my.dayone.app to dayone.me
    // so we need to support both redirect URIs. The ENV var is the
    // new URL, but we'll dynamically check to see if we're running on
    // dayone.app and use the old redirect if so.
    const isDayOneDotApp = window.location.hostname === "my.dayone.app";

    const redirectURI = isDayOneDotApp
      ? process.env.VITE_SIGN_IN_WITH_APPLE_REDIRECT_URI_OLD_DAYONEDOTAPP
      : process.env.VITE_SIGN_IN_WITH_APPLE_REDIRECT_URI;

    AppleID.auth.init({
      clientId: "com.bloombuilt.dayone-web",
      scope: "name email",
      redirectURI,
      usePopup: true,
    });
  }, [status, inDarkMode]);

  document.addEventListener("AppleIDSignInOnSuccess", (event) => {
    // Handle successful response.
    const ev = event as Event & { detail: AppleSignInAPI.SignInResponseI };
    const id_token = ev.detail.authorization.id_token;
    setToken(JSON.stringify({ token: id_token }));
  });

  // Listen for authorization failures.
  document.addEventListener("AppleIDSignInOnFailure", (event) => {
    logDebug("AppleIDSignInOnFailure", event);
    setAppleError("Sign in with Apple failed. Please try again");
  });

  return (
    <div
      sx={{
        position: "relative",
        borderRadius: "sm",
        border: "1px solid",
        borderColor: inDarkMode ? "rgba(255, 255, 255, 0.12)" : "#000000",
        overflow: "hidden",
        "&&& #appleid-signin  div": {
          bg: "transparent",
        },
        "&& #appleid-signin *, & div": {
          fontFamily:
            'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif',
          fontWeight: 500,
          letterSpacing: "0.25px",
        },
        "&& #appleid-signin svg": {
          visibility: "hidden",
        },
      }}
    >
      <div
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 0,
          width: "100%",
          textAlign: "center",
          lineHeight: "40px",
          bg: inDarkMode ? "#333333" : "#000000",
          color: "white",
          maxWidth: "unset",
          pb: 1,
          "& svg": {
            mr: 1,
            position: "relative",
            top: "1px",
            height: "16px",
            "& path": {
              fill: "white",
            },
          },
        }}
      >
        <>
          <AppleLogo />
          {__("Continue with Apple")}
        </>
      </div>
      <div
        id="appleid-signin"
        data-color={inDarkMode ? "black" : "white"}
        data-border="false"
        data-border-radius="0"
        data-height="42"
        data-type="continue"
      ></div>
      {appleError && <ErrorMessage message={appleError} />}
    </div>
  );
};
