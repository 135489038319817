import { BlockInstance } from "@wordpress/blocks";
import { QuoteNode, TextNode } from "types/rtj-format";

import { convertNodeToRTJFormatting } from "@/components/Editor/gb2rtj/formatting";
import { getQuoteHTML } from "@/components/Editor/gb2rtj/gb2md";
import {
  convertTextToMarkup,
  endsWithNewLine,
} from "@/components/Editor/rtj2gb/format-node";
import { createParagraphBlock } from "@/components/Editor/rtj2gb/rtj2gb";
import { takeUntil } from "@/components/Editor/utils/take-until";

export const convertGBQuoteToRTJNodes = (block: BlockInstance) => {
  const blockQuoteHTML = getQuoteHTML(block);
  const blockQuoteNodes = new DOMParser()
    .parseFromString(blockQuoteHTML, "text/html")
    .querySelector("body")?.childNodes;

  if (
    !blockQuoteNodes ||
    blockQuoteNodes.length < 1 ||
    blockQuoteNodes[0].childNodes?.length < 1
  ) {
    return [];
  }

  const rtjNodes = Array.from(blockQuoteNodes).reduce<TextNode[]>(
    (acc, blockQuoteNode) => {
      const paragraphNodes = Array.from(blockQuoteNode.childNodes);
      const children: TextNode[] = Array.from(paragraphNodes).flatMap(
        (paragraphNode: ChildNode) => {
          const addNewlineToLastTextNode = (textNodes: TextNode[]) => {
            if (textNodes.length) {
              textNodes[textNodes.length - 1].text += "\n";
            }
          };

          // A paragraphNode like this:
          //
          // BOLD-ITALIC normal
          //
          // has this node structure:
          //
          // node.nodeName => P
          //  node.childNodes => NodeList(2) [em, #text]
          //   0: em
          // .   node.childNodes => NodeList(1) [strong]
          // .   .   0: strong
          // .   .   .   node.childNodes => NodeList(1) [#text]
          // .   .   .   .   0: #text
          // .   .   .   .   .   node.nodeValue => "BOLD-ITALIC"
          // . 1: #text
          // .   node.nodeValue => " normal"
          const paragraphChildNodes = paragraphNode.childNodes;
          const textNodes = Array.from(paragraphChildNodes).reduce<TextNode[]>(
            (acc, paragraphChildNode) => {
              const resultArray = convertNodeToRTJFormatting(
                paragraphChildNode,
              ).filter((result) => {
                result.attributes = {
                  ...result.attributes,
                  line: { quote: true },
                };
                // filter out empty nodes
                return result.text !== "";
              });
              return [...acc, ...resultArray];
            },
            [],
          );

          addNewlineToLastTextNode(textNodes);
          return textNodes;
        },
      );

      children.forEach((child: TextNode) => {
        acc.push(child);
      });

      return acc;
    },
    [],
  );

  return rtjNodes;
};

export const convertNodesToQuoteBlockAttributes = (nodes: QuoteNode[]) => {
  const result = nodes.reduce<{
    skippedItems: number;
    blocks: BlockInstance[];
  }>(
    (acc, _, index, arr) => {
      const currentIndex = index + acc.skippedItems;
      const currentNode = arr[currentIndex];
      if (currentNode) {
        if (!endsWithNewLine(currentNode.text)) {
          const nodeWithSiblings = takeUntil(arr.slice(currentIndex), (node) =>
            endsWithNewLine(node.text),
          );

          const formattedTexts = nodeWithSiblings.map((node) =>
            convertTextToMarkup(node),
          );

          acc.skippedItems += nodeWithSiblings.length - 1;
          acc.blocks.push(createParagraphBlock(formattedTexts.join("")));

          return acc;
        } else {
          acc.blocks.push(
            createParagraphBlock(convertTextToMarkup(currentNode)),
          );
          return acc;
        }
      }

      return acc;
    },
    {
      skippedItems: 0,
      blocks: [],
    },
  );

  return result.blocks;
};
