import { Button, Icon } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { Activity } from "@/data/db/migrations/entry";
import { EntryModel } from "@/data/models/EntryModel";
import { getActivityIcon } from "@/utils/icons";
import { snackbarViewState } from "@/view_state/ViewStates";

type Props = {
  entry: EntryModel;
  update: (activity: Activity) => void;
  handleClose: () => void;
};

type ActivityObject = {
  label: Activity;
  icon: React.FC;
  translated: string;
};

export const ActivityChoiceModal: React.FC<Props> = observer(
  ({ update, handleClose, entry }) => {
    const { __ } = useI18n();
    const activities: ActivityObject[] = [
      {
        label: "Stationary",
        icon: getActivityIcon("Stationary"),
        translated: __("Stationary"),
      },
      {
        label: "Walking",
        icon: getActivityIcon("Walking"),
        translated: __("Walking"),
      },
      {
        label: "Running",
        icon: getActivityIcon("Running"),
        translated: __("Running"),
      },
      {
        label: "Biking",
        icon: getActivityIcon("Biking"),
        translated: __("Biking"),
      },
      {
        label: "Eating",
        icon: getActivityIcon("Eating"),
        translated: __("Eating"),
      },
      {
        label: "Automotive",
        icon: getActivityIcon("Automotive"),
        translated: __("Automotive"),
      },
      {
        label: "Flying",
        icon: getActivityIcon("Flying"),
        translated: __("Flying"),
      },
      {
        label: "Train",
        icon: getActivityIcon("Train"),
        translated: __("Train"),
      },
    ];

    useEffect(() => {
      analytics.tracks.recordEvent(EVENT.screenView, {
        screen: "activitySelection",
      });
    }, []);

    return (
      <D1Modal onRequestClose={handleClose} title={__("Set Activity")}>
        <div sx={{ display: "flex", flexDirection: "column" }}>
          {activities.map((activity) => (
            <Button
              key={activity.label}
              sx={{
                display: "flex",
                mb: 1,
                "&&": {
                  backgroundColor:
                    entry?.activity === activity.label
                      ? "surfaceActive"
                      : "transparent",
                  ":hover": {
                    backgroundColor: "surfaceHover",
                    color: "textPrimary",
                  },
                  svg: {
                    stroke: "textPrimary",
                    fill: "none",
                  },
                },
              }}
              onClick={() => {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: "activitySelection_selectButton",
                });
                snackbarViewState.newMessage(
                  entry?.activity === activity.label
                    ? __("Activity removed")
                    : __("Activity updated"),
                );
                update(
                  entry?.activity === activity.label ? "" : activity.label,
                );

                handleClose();
              }}
            >
              <div sx={{ width: "24px", mr: 4 }}>
                <Icon icon={activity.icon} />
              </div>
              <div>{__(activity.translated)}</div>
            </Button>
          ))}
        </div>
      </D1Modal>
    );
  },
);
