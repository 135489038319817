export const HazyNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0781 9.689C16.5161 9.689 15.9861 9.805 15.5231 10.036C15.1761 7.753 13.1911 6 10.8091 6C8.21209 6 6.09509 8.084 6.02909 10.665C4.47409 10.863 3.26709 12.187 3.26709 13.824C3.26609 15.577 4.68909 17 6.44209 17H17.0781C19.0961 17 20.7341 15.362 20.7341 13.344C20.7341 11.326 19.0961 9.689 17.0781 9.689V9.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0498 6.01402C12.3018 3.77002 14.1858 2.02002 16.4998 2.02002C16.4998 4.50202 18.5148 6.51402 20.9998 6.51402C20.9998 8.01702 20.2538 9.34002 19.1188 10.155"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 20.5H19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
