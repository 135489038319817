export const ENTRY_LIST_ITEM_HEIGHT = 104;
export const SHARED_ENTRY_LIST_ITEM_HEIGHT = 134;
export const ENTRY_LIST_DIV_ID = "dayone-entry-list";
export const ROW_HEIGHT = ENTRY_LIST_ITEM_HEIGHT;

export type HeaderElement = {
  type: "header";
  label: string;
  offset: number;
  height: number;
};

export type EntryElement = {
  type: "entry" | "shared";
  id: string;
  journal_id: string;
  offset: number;
  height: number;
  showDate: boolean;
  isFirstInBlock: boolean;
};

export type TimeLineElement = HeaderElement | EntryElement;
