import { observer } from "mobx-react-lite";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { EditProfile } from "@/components/JournalSettings/EditProfile";
import { MemberInfo } from "@/components/JournalSettings/MemberInfo";
import { ParticipantMenu } from "@/components/JournalSettings/ParticipantMenu";
import { SharedJournalSettings } from "@/view_state/PrimaryViewState";

interface ManageMemberModalProps {
  participantId: string;
  onClose: () => void;
  journalSettings: SharedJournalSettings;
}

export const ManageMemberModal: React.FC<ManageMemberModalProps> = observer(
  ({ participantId, onClose, journalSettings }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const participant = journalSettings.findParticipant(participantId);

    const participantIsTransferee = participant
      ? journalSettings.participantIsTransferee(participant)
      : false;

    if (!participant || !journalSettings?.journal) {
      return null;
    }

    return (
      <D1Modal
        onRequestClose={onClose}
        hideHeader
        sx={{
          "& .components-modal__content": {
            width: "380px",
            mt: 6,
          },
        }}
      >
        <div
          sx={{
            maxWidth: "400px",
            minHeight: "300px",
          }}
        >
          {!isEdit ? (
            <>
              <ParticipantMenu
                participant={participant}
                sharedJournalSettings={journalSettings}
                participantIsTransferee={participantIsTransferee}
                setIsEdit={setIsEdit}
                onClose={onClose}
                setError={setError}
              />
              <MemberInfo
                participant={participant}
                journalSettings={journalSettings}
                participantIsTransferee={participantIsTransferee}
                error={error}
              />
            </>
          ) : (
            <EditProfile />
          )}
        </div>
      </D1Modal>
    );
  },
);

ManageMemberModal.displayName = "ManageMemberModal";
