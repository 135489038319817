import { d1Classes } from "@/D1Classes";
import { userSettingsViewState } from "@/view_state/ViewStates";

export const DevDiv = () => {
  const handleSecretHandshake = (e: React.MouseEvent) => {
    if (e.detail === 3) {
      e.stopPropagation();
      e.preventDefault();
      // Clear any selection caused by triple-clicking
      window.getSelection()?.removeAllRanges();
      userSettingsViewState.enableDeveloperMode();
      d1Classes.keyValueStore.set("is-dev", true);
    }
  };
  return (
    <div
      id="dev-div"
      sx={{
        width: "100px",
        height: "25px",
        position: "absolute",
        bottom: 0,
        m: 0,
      }}
      onClick={handleSecretHandshake}
    >
      {" "}
    </div>
  );
};
