import { encryptD1NoLockedKey } from "./DOCrypto/D1/encryptD1NoLockedKey";
import { decryptSymmetricD1 } from "./DOCryptoBasics/symmetric/decryptD1Symmetric";

import { pemToBytes } from "@/crypto/DOCryptoBasics/asymmetric/PEM/pemToBytes";
import { decryptAsymmetric } from "@/crypto/DOCryptoBasics/asymmetric/Private/decryptAsymmetric";
import { decryptPrivateKey } from "@/crypto/DOCryptoBasics/asymmetric/Private/decryptPrivateKey";
import { privateKeyFromBuffer } from "@/crypto/DOCryptoBasics/asymmetric/Private/privateKeyFromBuffer";
import { privateKeyFromPEM } from "@/crypto/DOCryptoBasics/asymmetric/Private/privateKeyFromPEM";
import { privateKeyFromRawBytes } from "@/crypto/DOCryptoBasics/asymmetric/Private/privateKeyFromRawBytes";
import { privateKeyToPEM } from "@/crypto/DOCryptoBasics/asymmetric/Private/privateKeyToPEM";
import { privateKeyToUint8Array } from "@/crypto/DOCryptoBasics/asymmetric/Private/privateKeyToUint8Array";
import { signArrayBuffer } from "@/crypto/DOCryptoBasics/asymmetric/Private/signArrayBuffer";
import { signPublicKey } from "@/crypto/DOCryptoBasics/asymmetric/Private/signPublicKey";
import { encryptAsymmetric } from "@/crypto/DOCryptoBasics/asymmetric/Public/encryptAsymmetric";
import { publicKeyFromBytes } from "@/crypto/DOCryptoBasics/asymmetric/Public/publicKeyFromBytes";
import { publicKeyFromPEM } from "@/crypto/DOCryptoBasics/asymmetric/Public/publicKeyFromPEM";
import { publicKeyToPEM } from "@/crypto/DOCryptoBasics/asymmetric/Public/publicKeyToPEM";
import { publicKeyToUint8Array } from "@/crypto/DOCryptoBasics/asymmetric/Public/publicKeyToUint8Array";
import { generateNewKeyPair } from "@/crypto/DOCryptoBasics/asymmetric/generateNewKeyPair";
import { getFingerprintForKeyBytes } from "@/crypto/DOCryptoBasics/fingerprint/getFingerprintForKeyBytes";
import { getFingerprintForPEM } from "@/crypto/DOCryptoBasics/fingerprint/getFingerprintForPEM";
import { getFingerprintForPublicKey } from "@/crypto/DOCryptoBasics/fingerprint/getFingerprintForPublicKey";
import { getFingerprintFromPrivateKey } from "@/crypto/DOCryptoBasics/fingerprint/getFingerprintFromPrivateKey";
import { decryptBufferAndIVSymmetric } from "@/crypto/DOCryptoBasics/symmetric/decryptBufferAndIVSymmetric";
import { decryptSymmetricKey } from "@/crypto/DOCryptoBasics/symmetric/decryptSymmetricKey";
import { deriveSymmetricKeyFromMaster } from "@/crypto/DOCryptoBasics/symmetric/deriveSymmetricKeyFromMaster";
import { encryptSymmetric } from "@/crypto/DOCryptoBasics/symmetric/encryptSymmetric";
import { exportSymmetricKey } from "@/crypto/DOCryptoBasics/symmetric/exportSymmetricKey";
import { generateHMAC } from "@/crypto/DOCryptoBasics/symmetric/generateHMAC";
import { symmetricKeyFromBuffer } from "@/crypto/DOCryptoBasics/symmetric/keyFromBuffer";
import { symmetricKeyFromRawBytes } from "@/crypto/DOCryptoBasics/symmetric/keyFromRawBytes";
import { mkIV } from "@/crypto/DOCryptoBasics/symmetric/mkIV";
import { newSymmetricKey } from "@/crypto/DOCryptoBasics/symmetric/newSymmetricKey";
import { newSymmetricKeyFromUserId } from "@/crypto/DOCryptoBasics/symmetric/newSymmetricKeyFromUserId";

export const Symmetric = {
  mkIV,
  encryptToD1: encryptD1NoLockedKey,
  // This is more of a low-level operation, use encryptToD1 most of the time
  encryptToUint8ArrayWithIV: encryptSymmetric,

  decryptD1: decryptSymmetricD1,
  decryptBufferAndIV: decryptBufferAndIVSymmetric,
  generateHMAC,

  Key: {
    new: newSymmetricKey,
    decrypt: decryptSymmetricKey,
    newFromUserId: newSymmetricKeyFromUserId,

    fromBuffer: symmetricKeyFromBuffer,
    fromRawBytes: symmetricKeyFromRawBytes,
    fromMasterKey: deriveSymmetricKeyFromMaster,

    toUintArray: exportSymmetricKey,
  },
};

export const Asymmetric = {
  generateNewPair: generateNewKeyPair,

  Public: {
    // verify: verifySignature,
    encrypt: encryptAsymmetric,
    fromPEM: publicKeyFromPEM,
    fromBytes: publicKeyFromBytes,
    toUint8Array: publicKeyToUint8Array,
    toPEM: publicKeyToPEM,
  },

  Private: {
    decrypt: decryptAsymmetric,
    decryptKey: decryptPrivateKey,
    signArrayBuffer: signArrayBuffer,
    signPublicKey: signPublicKey,

    fromPEM: privateKeyFromPEM,
    fromBuffer: privateKeyFromBuffer,
    fromRawBytes: privateKeyFromRawBytes,

    toPEM: privateKeyToPEM,
    toUint8Array: privateKeyToUint8Array,
  },

  PEM: {
    toBytes: pemToBytes,
  },
};

export const Fingerprint = {
  forPEM: getFingerprintForPEM,
  forPublicKey: getFingerprintForPublicKey,
  forPrivateKey: getFingerprintFromPrivateKey,
  forKeyBytes: getFingerprintForKeyBytes,
};

/**
 * Normally this DOCryptoBasics shouldn't be used,
 * I'm making for development purposes only.
 * Just import Fingerprint, Asymmetric, etc. directly
 */
export const DOCryptoBasics = {
  Symmetric: Symmetric,
  Asymmetric: Asymmetric,
  Fingerprint: Fingerprint,
};
