import { Entry } from "@/data/repositories/V2API";
import { decodeRichTextJson } from "@/utils/rtj";

export const decodeEntryContent = (entry: Entry) => {
  const rtj = decodeRichTextJson(entry?.richTextJSON);

  return {
    ...entry,
    richTextJSON: rtj,
  };
};
