import {
  EditorSelection,
  store as blockEditorStore,
} from "@wordpress/block-editor";
import { BlockInstance, createBlock } from "@wordpress/blocks";
import { useSelect } from "@wordpress/data";
import { addFilter, removeFilter } from "@wordpress/hooks";
import { nanoid } from "nanoid";
import { useMemo } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  CHECKLIST_ITEM_BLOCK_ID,
  HEADING_BLOCK_ID,
  QUOTE_BLOCK_ID,
  CODE_BLOCK_ID,
  SEPARATOR_BLOCK_ID,
  DICTATION_BLOCK_ID,
} from "@/components/Editor/blocks/constants";

type InserterItem = {
  name: string;
  position: EditorSelection;
  index: number;
  content: string;
};

type insertBlock = (
  block: BlockInstance,
  index?: number,
  rootClientId?: string,
  updateSelection?: boolean,
) => void;

export const createSlashMenuAutoCompleter = () => {
  return {
    name: "dayone-slash-menu",
    className: "block-editor-autocompleters__block",
    triggerPrefix: "/",

    useItems(filterValue: string) {
      const { getSelectionEnd, getBlockIndex, getBlock } = useSelect<any>(
        blockEditorStore,
        [],
      );

      const selection = getSelectionEnd();
      const currentIndex = getBlockIndex(selection.clientId);
      const block = getBlock(selection.clientId);
      const content = block.attributes?.content || "";
      const hasDictationBlockEnabled =
        "SpeechRecognition" in window || "webkitSpeechRecognition" in window;

      const options = useMemo(() => {
        const optionNames = [
          "time",
          "date",
          "today",
          "today-full",
          "tomorrow",
          "tomorrow-full",
          "yesterday",
          "yesterday-full",
          "now",
          "now-full",
          "line",
          "todo",
          "code",
          "quote",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
        ];

        if (hasDictationBlockEnabled) {
          optionNames.push("dictation");
        }

        const options = optionNames.map((name) => {
          return {
            key: name,
            value: {
              position: selection,
              index: currentIndex,
              name,
              content,
            },
            label: <span>{name}</span>,
            isDisabled: false,
          };
        });

        if (filterValue) {
          return options.filter(({ value: { name } }) =>
            name.includes(filterValue),
          );
        }

        return options;
      }, [filterValue]);

      return [options];
    },

    allowContext(before: string, after: string) {
      const beforeGood =
        before === "" || /\s/.test(before.substring(before.length - 1));
      const afterGood = after === "" || /\s/.test(after.substring(0, 1));
      return beforeGood && afterGood;
    },

    getOptionCompletion(inserterItem: InserterItem) {
      analytics.tracks.recordEvent(EVENT.slashCommandUse, {
        type: inserterItem.name,
      });

      switch (inserterItem.name) {
        case "time":
          return insertTimeCompletion();
        case "date":
        case "today":
          return insertDateCompletion();
        case "today-full":
          return insertDateCompletion(true);
        case "tomorrow":
          return insertTomorrowCompletion();
        case "tomorrow-full":
          return insertTomorrowCompletion(true);
        case "yesterday":
          return insertYesterdayCompletion();
        case "yesterday-full":
          return insertYesterdayCompletion(true);
        case "now":
          return insertNowCompletion();
        case "now-full":
          return insertNowCompletion(true);
        case "line":
          return insertBlock(SEPARATOR_BLOCK_ID, inserterItem);
        case "todo":
          return insertBlock(CHECKLIST_ITEM_BLOCK_ID, inserterItem, {
            checked: false,
            content: removeSlashCommand(
              inserterItem.content,
              inserterItem.position.offset,
            ),
            identifier: nanoid(),
            indent: 1,
          });
        case "code":
          return insertBlock(CODE_BLOCK_ID, inserterItem);
        case "quote":
          return insertBlock(QUOTE_BLOCK_ID, inserterItem);
        case "h1":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 1 });
        case "h2":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 2 });
        case "h3":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 3 });
        case "h4":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 4 });
        case "h5":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 5 });
        case "h6":
          return insertBlock(HEADING_BLOCK_ID, inserterItem, { level: 6 });
        case "dictation":
          return insertBlock(DICTATION_BLOCK_ID, inserterItem, {});
      }

      return { action: "insert-at-caret", value: "" };
    },
  };
};

const insertTimeCompletion = () => {
  const { date } = getDateOffset();
  return {
    action: "insert-at-caret",
    value: date.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
    }),
  };
};

const insertDateCompletion = (full = false) => {
  const { date, dateOptions: options } = getDateOffset(full);
  return {
    action: "insert-at-caret",
    value: date.toLocaleDateString(
      undefined,
      options as Intl.DateTimeFormatOptions,
    ),
  };
};

const insertTomorrowCompletion = (full = false) => {
  const { date, dateOptions: options } = getDateOffset(full, 1);
  return {
    action: "insert-at-caret",
    value: date.toLocaleDateString(
      undefined,
      options as Intl.DateTimeFormatOptions,
    ),
  };
};

const insertYesterdayCompletion = (full = false) => {
  const { date, dateOptions: options } = getDateOffset(full, -1);
  return {
    action: "insert-at-caret",
    value: date.toLocaleDateString(
      undefined,
      options as Intl.DateTimeFormatOptions,
    ),
  };
};

const insertNowCompletion = (full = false) => {
  const { date, timeOptions: options } = getDateOffset(full);
  return {
    action: "insert-at-caret",
    value: date.toLocaleDateString(
      undefined,
      options as Intl.DateTimeFormatOptions,
    ),
  };
};

const removeSlashCommand = (content: string, offset?: number) => {
  const end = offset || content.length;
  const start = content.lastIndexOf("/", end);
  return (
    content.substring(0, start - 1) + content.substring(end, content.length)
  );
};

const insertBlock = (
  blockName: string,
  item: InserterItem,
  attributes?: Partial<Record<string, any>>,
) => {
  const content = removeSlashCommand(item.content, item.position.offset);
  const newBlock = createBlock(blockName, { ...attributes, content });

  return {
    action: "replace",
    value: newBlock,
  };
};

export const registerCompleters = async (additionalAutoCompleters?: any[]) => {
  const autoCompleters = [createSlashMenuAutoCompleter()];
  if (additionalAutoCompleters) {
    autoCompleters.push(...additionalAutoCompleters);
  }
  removeFilter("editor.Autocomplete.completers", "dayone/editor/autocomplete");
  addFilter(
    "editor.Autocomplete.completers",
    "dayone/editor/autocomplete",
    () => {
      return autoCompleters;
    },
  );
};

const getDateOffset = (full = false, offset = 0) => {
  const dateOptions = full
    ? {
        dateStyle: "full",
      }
    : {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };

  const timeOptions = full
    ? {
        year: "numeric",
        month: "long",
        weekday: "long",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
      }
    : {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
      };

  const date = new Date();
  if (offset !== 0) {
    date.setDate(date.getDate() + offset);
  }
  return {
    date,
    dateOptions,
    timeOptions,
  };
};
