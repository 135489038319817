import { ThemeUICSSObject } from "@theme-ui/css";
import { PropsWithChildren } from "react";

const headingStyle: ThemeUICSSObject = {
  fontStyle: "normal",
  fontWeight: "boldest",
  fontSize: 6,
  lineHeight: "heading",
  display: "block",
  alignItems: "center",
  textAlign: "center",
  color: "textPrimary",
  mt: 8,
  mx: "auto",
  mb: 2,
  width: "325px",
  maxWidth: "90%",
};

type Props = {
  className?: "string";
  sx?: ThemeUICSSObject;
  title?: string | null;
};

export const Heading: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  title,
}) => (
  <h1 sx={headingStyle} className={className} title={title ?? undefined}>
    {children}
  </h1>
);
