import { decryptComment } from "@/crypto/DOCrypto/Comment/decryptComment";
import { encryptComment } from "@/crypto/DOCrypto/Comment/encryptComment";
import { decryptD1 } from "@/crypto/DOCrypto/D1/decryptD1";
import { encryptD1NoLockedKey } from "@/crypto/DOCrypto/D1/encryptD1NoLockedKey";
import { encryptD1WithLockedKey } from "@/crypto/DOCrypto/D1/encryptD1WithLockedKey";
import { getBodyAsArrayBuffer } from "@/crypto/DOCrypto/D1/getBodyAsArrayBuffer";
import { parseD1 } from "@/crypto/DOCrypto/D1/parseD1";
import { decryptEntryBlob } from "@/crypto/DOCrypto/EntryBlob/decryptEntryBlob";
import { encryptEntryBlob } from "@/crypto/DOCrypto/EntryBlob/encryptEntryBlob";
import { decryptWithUserKey } from "@/crypto/DOCrypto/Grant/decryptWithUserKey";
import { makeNewGrant } from "@/crypto/DOCrypto/Grant/makeNewGrant";
import { vaultKeyFromGrant } from "@/crypto/DOCrypto/Grant/vaultKeyFromGrant";
import { decryptJournalD1 } from "@/crypto/DOCrypto/Journal/decryptJournalD1";
import { decryptJournalName } from "@/crypto/DOCrypto/Journal/decryptJournalName";
import { unlockAndStoreAllJournalKeys } from "@/crypto/DOCrypto/JournalKey/JournalKeysunlockAndStoreAll";
import { encryptWithJournalKey } from "@/crypto/DOCrypto/JournalKey/encryptWithJournalKey";
import {
  makeJournalVault,
  makeNewJournalKey,
} from "@/crypto/DOCrypto/JournalVault/makeJournalVault";
import { decryptMedia } from "@/crypto/DOCrypto/Media/decryptMedia";

export const DOCrypto = {
  EntryBlob: {
    encrypt: encryptEntryBlob,
    decrypt: decryptEntryBlob,
  },

  Comment: {
    encrypt: encryptComment,
    decrypt: decryptComment,
  },

  Media: {
    decrypt: decryptMedia,
  },

  Journal: {
    decryptName: decryptJournalName,
    decryptD1: decryptJournalD1,
  },

  Template: {
    encrypt: encryptD1WithLockedKey,
  },

  JournalVault: {
    make: makeJournalVault,
  },

  /**
   * Grants are an encrypted blob that contains the vault key.
   * The Vault key is used to decrypt the Journal key. (see below)
   */
  Grant: {
    decryptWithUserKey: decryptWithUserKey,
    make: makeNewGrant,
    getVaultKey: vaultKeyFromGrant,
  },

  /**
   * Journal key is used to encrypt the entry data and Journal's Name and Description
   * In fact the EntryBlob.encrypt() function uses this method to encrypt the entry data.
   * This is also used to encrypt moment's data.
   */
  JournalKey: {
    encrypt: encryptWithJournalKey,
    unlockAndStoreAll: unlockAndStoreAllJournalKeys,
    make: makeNewJournalKey,
  },

  /**
   * D1 is the format used by Day One to store the data in a binary format.
   * This is used to encrypt the Journal's Name and Description.
   */
  D1: {
    parse: parseD1,
    decrypt: decryptD1,
    encryptNoLockedKey: encryptD1NoLockedKey,
    encryptWithLockedKey: encryptD1WithLockedKey,
    getBodyAsArrayBuffer: getBodyAsArrayBuffer,
  },
};
