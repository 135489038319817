// This file is here to insantiate classes that require code which is only available
// on the main thread. This is usually code that references "window".
// It's allowed to depend on things in d1Classes, but d1Classes aren't allowed to
// depend on things in this class, since d1Classes gets constructed in the worker
// as well as the main thread.

import { d1Classes } from "./D1Classes";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { UserLoginService } from "@/data/UserLoginService";
import { localStorageWrapper } from "@/data/db/DOLocalStorage";
import { SyncService } from "@/data/services/SyncService";

export class D1MainThreadClasses {
  userLoginService: UserLoginService;
  syncService: SyncService;
  constructor(private classes: typeof d1Classes) {
    this.syncService = new SyncService(
      this.classes.journalStore,
      this.classes.outbox,
    );

    this.userLoginService = new UserLoginService(
      this.classes.mainServerAPIRepository,
      this.classes.userStore,
      this.classes.userRepository,
      this.classes.secureKeyValueStore,
      analytics,
      this.syncService,
      localStorageWrapper,
      Sentry,
      this.classes.db,
      this.classes.userKeysStore,
    );
  }
}

export const d1MainThreadClasses = new D1MainThreadClasses(d1Classes);
