import { UserDBRow } from "@/data/db/migrations/user";
import { JournalLimit, SharedProfile } from "@/data/repositories/UserAPI";
import { isDeepEqual } from "@/utils/is-equal";

export class UserModel {
  constructor(public data: UserDBRow) {}
  get id() {
    return this.data.id;
  }
  set id(value: string) {
    this.data.id = value;
  }
  get avatar() {
    return this.data.avatar;
  }
  set avatar(value: string) {
    this.data.avatar = value;
  }
  get bio() {
    return this.data.bio;
  }
  set bio(value: string) {
    this.data.bio = value;
  }
  get display_name(): string | undefined {
    return this.data.display_name;
  }
  set display_name(value: string | undefined) {
    this.data.display_name = value;
  }
  get email() {
    return this.data.email;
  }
  set email(value: string | undefined) {
    this.data.email = value;
  }
  get journal_order() {
    return this.data.journal_order;
  }
  set journal_order(value: string[]) {
    this.data.journal_order = value;
  }
  get shared_journal_order() {
    return this.data.shared_journal_order;
  }
  set shared_journal_order(value: string[]) {
    this.data.shared_journal_order = value;
  }
  get full_journal_order() {
    return this.journal_order.concat(this.shared_journal_order);
  }
  get unified_journal_order() {
    return this.data.unified_journal_order;
  }
  get sync_upload_base_url() {
    return this.data.sync_upload_base_url;
  }
  set sync_upload_base_url(value: string) {
    this.data.sync_upload_base_url = value;
  }
  get subscription_status() {
    return this.data.subscription_status;
  }
  get features() {
    return this.data.features;
  }
  get credentials() {
    return JSON.parse(this.data.credentials || "");
  }
  set credentials(value: string[]) {
    this.data.credentials = JSON.stringify(value);
  }
  get profile_color() {
    return this.data.profile_color;
  }
  set profile_color(value: string) {
    this.data.profile_color = value;
  }
  get website() {
    return this.data.website;
  }
  set website(value: string) {
    this.data.website = value;
  }
  get shared_profile() {
    return this.data.shared_profile;
  }
  set shared_profile(value: SharedProfile) {
    this.data.shared_profile = value;
  }
  get initials() {
    return this.data.initials
      ? this.data.initials
      : this.data.display_name
          ?.split(" ")
          .map((n) => n[0])
          .slice(0, 2)
          .join("") || "";
  }

  set initials(value: string) {
    this.data.initials = value;
  }

  get master_key_storage() {
    return this.data.master_key_storage || [];
  }

  get journalLimit() {
    return this.data.features?.find(
      (f) => f.name === "journalLimit",
    ) as JournalLimit;
  }

  public equals(other: UserModel | null): boolean {
    return !!other && isDeepEqual(this.data, other.data);
  }
}
