import { FlexItem, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Item } from "@/components/ItemGroup";
import { Attribute } from "@/components/Settings/Attribute";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { Label } from "@/components/Settings/Label";
import { UserStore } from "@/data/stores/UserStore";
import { usePromise } from "@/hooks/d1_hooks";

type Props = {
  userStore: UserStore;
  showUpgrade?: () => void;
};

export const Subscription: React.FC<Props> = ({ userStore, showUpgrade }) => {
  const [subscription, subscriptionError] = usePromise(
    userStore.getActiveUserSubscription(),
  );
  const [loading, set_loading] = useState(false);
  const { __ } = useI18n();
  useEffect(() => {
    if (subscriptionError) {
      Sentry.captureException(subscriptionError);
    }
  }, [subscriptionError]);

  // We make a GET to the server with the user's auth token header in place, and the
  // server gives us a custom Stripe URL to redirect the user to. That will give them
  // premium after checking out.
  async function goToStripeUrl() {
    set_loading(true);
    const url = await d1Classes.fetchWrapper.fetchRedirectLocation(
      `/api/subscription/create_customer_portal_session`,
    );
    if (!url) {
      set_loading(false);
      Sentry.captureException(
        new Error("No URL returned from server for stripe checkout"),
      );
      alert("Something went wrong. Please try again later.");
      return;
    }
    window.location = url as unknown as string & Location;
  }

  return (
    <>
      {subscription && (
        <>
          <Item>
            <FlexItem>
              <Label>{__("Account Status")}</Label>
              <Attribute>
                {subscription.subscriptionName === "free" && <span>Basic</span>}
                {(subscription.subscriptionName === "grandfathered" ||
                  subscription.subscriptionName === "plus") && (
                  <span>
                    Plus ({subscription.bundleReason || "purchase"}) )
                  </span>
                )}
                {subscription.subscriptionName === "premium" && (
                  <span>
                    Premium ({subscription.bundleReason || "purchase"})
                  </span>
                )}
              </Attribute>
            </FlexItem>
            <FlexItem>
              {subscription.subscriptionName === "free" && (
                <EditLinkButton
                  onClick={() => {
                    if (showUpgrade) {
                      showUpgrade();
                    }
                  }}
                  title={__("Go Premium")}
                  label={__("Go Premium")}
                />
              )}
              {subscription.subscriptionName === "premium" &&
                subscription.source === "stripe" && (
                  <EditLinkButton
                    onClick={() => {
                      goToStripeUrl().catch((err) =>
                        Sentry.captureException(err),
                      );
                    }}
                    title={
                      loading ? (
                        <>
                          <Spinner
                            // @ts-ignore
                            sx={{
                              maxWidth: "12px",
                              margin: 0,
                              marginRight: "6px",
                            }}
                          ></Spinner>
                          __("Loading")
                        </>
                      ) : (
                        __("Manage")
                      )
                    }
                    label={!loading ? __("Manage") : __("Loading")}
                  />
                )}

              {subscription.subscriptionName === "premium" &&
                subscription.source !== "stripe" && (
                  <EditLinkButton
                    onClick={() => {
                      window.open(
                        "https://help.dayoneapp.com/en/articles/1027334-view-change-or-cancel-your-premium-subscription",
                        "_blank",
                      );
                    }}
                    title={__("Manage")}
                    label={__("Manage")}
                  />
                )}
            </FlexItem>
          </Item>
          {subscription.subscriptionName !== "free" && (
            <Item>
              <FlexItem>
                <p>{__("Expires")}</p>
              </FlexItem>
              <FlexItem>
                <p>
                  {!subscription.expirationDate &&
                    subscription.source in ["stripe", "receipt"] &&
                    __("Will renew")}
                  {subscription.expirationDate &&
                    subscription.expirationDate < Date.now() &&
                    __("Premium expired on: ")}

                  {subscription.expirationDate &&
                    new Date(subscription.expirationDate).toLocaleDateString()}
                  {!subscription.expirationDate && __("Valid indefinitely")}
                </p>
              </FlexItem>
            </Item>
          )}
        </>
      )}
      {subscriptionError && (
        <ErrorMessage
          message={__("There was a problem loading your subscription info")}
        ></ErrorMessage>
      )}
    </>
  );
};
