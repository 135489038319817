import { Spinner } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/Settings/Button";
import { usePriceInfo } from "@/hooks/usePriceInfo";

type Props = {
  type: string;
  color?: "primary" | "primaryPurple";
  withPricingDetails?: boolean;
};

export const UpgradeCTA: React.FC<Props> = ({
  type,
  color,
  withPricingDetails = false,
}) => {
  const { __ } = useI18n();
  const [loading, set_loading] = useState(false);
  const [error, setError] = useState(false);
  const {
    fetchingInfo,
    fetchPriceError,
    priceInfo: { price, trialPeriod },
  } = usePriceInfo();

  // We make a GET to the server with the user's auth token header in place, and the
  // server gives us a custom Stripe URL to redirect the user to. That will give them
  // premium after checking out.
  async function goToStripeUrl() {
    set_loading(true);
    const url = await d1Classes.fetchWrapper.fetchRedirectLocation(
      `/api/subscription/start-checkout-session?source=webapp`,
    );
    if (!url) {
      set_loading(false);
      Sentry.captureException(
        new Error("No URL returned from server for stripe checkout"),
      );

      setError(true);
      return;
    }
    window.location = url as unknown as string & Location;
  }
  const buttonText =
    trialPeriod > 0 && withPricingDetails
      ? sprintf(__("Start my %d-month Free Trial"), trialPeriod)
      : __("Go Premium");

  return fetchingInfo ? (
    <Spinner sx={{ maxWidth: "12px", margin: 0, marginRight: "6px" }} />
  ) : (
    <>
      {(error || fetchPriceError) && (
        <ErrorMessage
          message={__("Something went wrong. Please try again later.")}
        />
      )}
      <Button
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.stripeClickThrough, { type });
          goToStripeUrl().catch((err) => Sentry.captureException(err));
        }}
        variant="primary"
        sx={{
          justifyContent: "center",
          width: "100%",
          height: 6,
          ...(color === "primaryPurple" && {
            "&.components-button.is-primary": {
              color: "textPrimary",
              backgroundColor: "primaryPurple",
            },
            "&.components-button.is-primary:hover": {
              backgroundColor: "primaryPurpleHover",
            },
          }),
        }}
      >
        {loading && (
          <Spinner sx={{ maxWidth: "12px", margin: 0, marginRight: "6px" }} />
        )}
        {loading ? __("Loading") : buttonText}
      </Button>
      {withPricingDetails && (
        <p
          sx={{
            fontSize: 1,
            color: "textSecondary",
          }}
        >
          {trialPeriod > 0
            ? sprintf(
                __("%d-month free, then $%s per year"),
                trialPeriod,
                price,
              )
            : sprintf(__("$%s Get one year of Day One Premium"), price)}
        </p>
      )}
    </>
  );
};
