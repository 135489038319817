import { __ } from "@wordpress/i18n";

import { journalColorValues } from "@/styles/JournalColorValues";

type JournalColor = {
  name: string;
  color: string;
  label: string;
};

const freeJournalColors: JournalColor[] = [
  {
    name: "dayOneBlue",
    color: journalColorValues.dayOneBlue,
    label: __("Day One Blue"),
  },
  { name: "aqua", color: journalColorValues.aqua, label: __("Aqua") },
  { name: "green", color: journalColorValues.green, label: __("Green") },
  { name: "honey", color: journalColorValues.honey, label: __("Honey") },
  {
    name: "watermelon",
    color: journalColorValues.watermelon,
    label: __("Watermelon"),
  },
  { name: "salmon", color: journalColorValues.salmon, label: __("Salmon") },
  {
    name: "journalBlue",
    color: journalColorValues.journalBlue,
    label: __("Blue"),
  },
  { name: "pewter", color: journalColorValues.pewter, label: __("Pewter") },
  {
    name: "tortilla",
    color: journalColorValues.tortilla,
    label: __("Tortilla"),
  },
  { name: "fire", color: journalColorValues.fire, label: __("Fire") },
  {
    name: "lavender",
    color: journalColorValues.lavender,
    label: __("Lavender"),
  },
  {
    name: "journalRed",
    color: journalColorValues.journalRed,
    label: __("Red"),
  },
];

const premiumJournalColors: JournalColor[] = [
  {
    name: "charcoal",
    color: journalColorValues.charcoal,
    label: __("Charcoal"),
  },
  { name: "stone", color: journalColorValues.stone, label: __("Stone") },
  { name: "lime", color: journalColorValues.lime, label: __("Lime") },
  { name: "hotPink", color: journalColorValues.hotPink, label: __("Hot Pink") },
  { name: "magenta", color: journalColorValues.magenta, label: __("Magenta") },
  { name: "iris", color: journalColorValues.iris, label: __("Iris") },
];

export const journalColorList = [...freeJournalColors, ...premiumJournalColors];
export const journalColors = { freeJournalColors, premiumJournalColors };
