import { observer } from "mobx-react-lite";

import { viewStates } from "@/view_state/ViewStates";

export const EntryTags: React.FC = observer(() => {
  return (
    <div>
      {viewStates.activeEntry.tags.length > 0 &&
        viewStates.activeEntry.tags.map((t) => (
          <span
            key={t}
            sx={{
              display: "inline-block",
              whiteSpace: "nowrap",
              mr: 2,
              border: "1px solid",
              borderColor: "borderPrimary",
              px: 2,
              py: 1,
              mb: 2,
              borderRadius: 2,
              backgroundColor: "surface_light2_dark2",
              color: "textPrimary",
              fontSize: 1,
            }}
          >
            {t}
          </span>
        ))}
    </div>
  );
});
