export const Song: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4178 3.93037C12.1767 3.97055 12 4.17914 12 4.42356V14.4682C11.4117 14.1704 10.7286 14 10 14C7.79086 14 6 15.567 6 17.5C6 19.433 7.79086 21 10 21C12.2091 21 14 19.433 14 17.5V8.66667L17.5822 8.06963C17.8233 8.02945 18 7.82086 18 7.57644V3.59023C18 3.28126 17.7226 3.04624 17.4178 3.09703L12.4178 3.93037Z"
        fill="currentColor"
      />
    </svg>
  );
};
