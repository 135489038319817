/* https://developer.mozilla.org/en-US/docs/Web/API/TextEncoder */

/* Encode */
const encoder = new TextEncoder();
const encode = (str: string) => encoder.encode(str);
const toUintArray = encode;

/* Decode */
const decoder = new TextDecoder();
const decode = (str: BufferSource) => decoder.decode(str);
const fromBufferSource = decode;

export const Utf8 = {
  encoder,
  encode,
  decoder,
  decode,
  toUintArray,
  fromBufferSource,
};
