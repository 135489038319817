import { useState } from "react";

export const useShowHideModal = () => {
  const [shouldShowModal, setShouldShowModal] = useState(false);

  const showModal = () => setShouldShowModal(true);
  const hideModal = () => setShouldShowModal(false);

  return {
    shouldShowModal,
    showModal,
    hideModal,
  };
};
