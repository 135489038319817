import { binaryHeader } from "@/crypto/DOCrypto/D1/d1BinaryHeader";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { uintArrayConcat, fromHex, toBase64 } from "@/crypto/utils";
import { md5 } from "@/crypto/utils/md5";

const binaryNoLockKeyFormat = new Uint8Array([0]);

export const encryptD1NoLockedKey = async (
  data: string,
  encryptionKey: CryptoKey,
) => {
  const { iv, encrypted } = await Symmetric.encryptToUint8ArrayWithIV(
    encryptionKey,
    Utf8.toUintArray(data),
  );

  const d1 = uintArrayConcat([
    ...binaryHeader,
    binaryNoLockKeyFormat, // Binary format is one of 0 | 1 | 2, always 0 in this case
    iv,
    encrypted, // Encrypted content and gcmTag. Last 16 Bytes are the gcmTag
  ]);

  const hash = fromHex(await md5(d1));
  return toBase64(uintArrayConcat([d1, hash]));
};
