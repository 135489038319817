import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";

// A global, in-memory construct to parse,validate, and unlock things with the master key.
export class MasterKey {
  public wholeString: string;
  public userId: string;
  public passwordBytes: Uint8Array;
  public derivedKey: Promise<CryptoKey>;

  constructor(wholeString: string) {
    this.wholeString = wholeString;

    const splitted = wholeString.split("-");
    if (splitted.length <= 2) {
      throw new Error("Invalid master token, not enough parts");
    }
    // Skip the D1- prefix, and get the user ID out
    this.userId = splitted[1];

    // Remember, this isn't the user's account password, but the characters
    // in the master token that represent the "password" we send to PBKDF2
    // to generate the derived master key.
    this.passwordBytes = Utf8.toUintArray(splitted.slice(2).join(""));

    const importedToken = crypto.subtle.importKey(
      "raw",
      this.passwordBytes,
      { name: "PBKDF2" },
      false,
      ["deriveBits", "deriveKey"],
    );

    this.derivedKey = importedToken.then((token) => {
      return Symmetric.Key.fromMasterKey(this.userId, token);
    });
  }

  async unlockUserKey(
    userId: string,
    encryptedPrivateKey: string,
  ): Promise<CryptoKey> {
    if (userId !== this.userId) {
      throw new Error("Master key user ID does not match key user ID");
    }
    const key = await Asymmetric.Private.decryptKey(
      await this.derivedKey,
      encryptedPrivateKey,
    );
    return key;
  }

  // The URL used for the Master Key QR code
  get url() {
    return `dayone2://masterkey?userString=${encodeURIComponent(
      this.wholeString,
    )}`;
  }
}

export function generateUserMasterKey(accountId: string): MasterKey {
  // Character pool from which to draw
  // We exclude characters that are easily confused with each other
  const charPool = "ABCDEFGHJKLMNPQRTUVWXYZ2346789";

  // Calculate the maximum random value that is a multiple of charPool.length
  // This ensures that when we use the modulo operator to select an index of charPool,
  // each character in the pool is equally likely to be selected.
  const max = Math.floor((2 ** 32 - 1) / charPool.length) * charPool.length;

  const addRandomLetter = () => {
    let randomValue;

    // Generate random values until we get one that's less than our maximum.
    // This might seem inefficient, but it's necessary for creating a truly uniform distribution.
    // It also happens quite quickly in practice, so the efficiency impact is minimal.
    do {
      randomValue = crypto.getRandomValues(new Uint32Array(1))[0];
    } while (randomValue >= max);

    const randomIndex = randomValue % charPool.length;
    secretKey += charPool[randomIndex];
  };

  let secretKey = "";
  // First a 6-character section (no idea why this is different)
  for (let i = 0; i < 6; i++) addRandomLetter();

  // then 5 groups of 5 characters separated by dashes
  for (let i = 0; i < 5; i++) {
    secretKey += "-";
    for (let j = 0; j < 5; j++) {
      addRandomLetter();
    }
  }

  // The user master key consists of the key format, account ID, and the secret key
  const userMasterKey = `D1-${accountId}-${secretKey}`;

  return new MasterKey(userMasterKey);
}
