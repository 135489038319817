export const headingOne = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8 6H13.2V18H10.8V13.2H5.99998V18H3.59998V6H5.99998V10.8H10.8V6ZM18.72 6C18 7.08 16.92 8.04 15.6 8.4V9.6H18V18H20.4V6H18.72Z" />
    </svg>
  );
};
