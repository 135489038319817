import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";

export const D1DevDecryptor: React.FC = observer(() => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDecrypt = async () => {
    if (selectedFile) {
      console.log("Decrypting file:", selectedFile.name);

      // Build a Uint8Array from the file
      const fileArray = new Uint8Array(await selectedFile.arrayBuffer());
      // Now let's see if we can decrypt it
      try {
        const decrypted = await d1Classes.decryptionService.decryptMedia(
          fileArray,
          "Developer d1 decryptor",
          d1Classes.vaultRepository,
        );

        console.log("Decrypted file successfully, offering download");
        // We're done! Offer to download the file
        const blob = new Blob([decrypted], {
          type: "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = selectedFile.name + ".decrypted";
        a.click();
      } catch (error) {
        console.error("Error decrypting file:", error);
      }
    }
  };

  return (
    <div>
      <label>
        <input type="file" accept=".d1" onChange={handleFileChange} />
      </label>
      <Button
        variant="primary"
        onClick={() => handleDecrypt().catch(console.error)}
        disabled={!selectedFile}
        sx={{ mt: 2 }}
      >
        {__("Decrypt")}
      </Button>
    </div>
  );
});
D1DevDecryptor.displayName = "D1DevDecryptor";
