import { DOCrypto } from "@/crypto/DOCrypto";
import { Utf8 } from "@/crypto/utf8";
import { toBase64 } from "@/crypto/utils";
import { Key } from "@/data/repositories/Syncables";

export const encryptComment = async (
  comment: string,
  journalKey: Key,
  format: 1 | 2,
) => {
  const needsEncrypted = Utf8.toUintArray(comment);

  return toBase64(
    await DOCrypto.JournalKey.encrypt(needsEncrypted, journalKey, format),
  );
};
