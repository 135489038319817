import { makeAutoObservable } from "mobx";

export class ToolbarViewState {
  isHeadingTransformDropdownOpen = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setHeadingTransformDropdownOpen = (isOpen: boolean) => {
    this.isHeadingTransformDropdownOpen = isOpen;
  };
}
