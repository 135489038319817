import { ToggleControl as WpToggleControl } from "@wordpress/components";
import { ToggleControlProps } from "@wordpress/components/build-types/toggle-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

export const ToggleControl: React.FC<
  WordPressComponentProps<ToggleControlProps, "input", false>
> = (props) => {
  return (
    <WpToggleControl
      sx={{
        ".components-form-toggle.is-checked .components-form-toggle__track": {
          backgroundColor: "primary",
          borderColor: "borderPrimary",
        },
      }}
      {...props}
    />
  );
};
