import { Sentry } from "@/Sentry";
import { EntryDBRow } from "@/data/db/migrations/entry";
import { MomentDBRow, momentType } from "@/data/db/migrations/moment";
import {
  EntryMoment,
  EntryWithFeedRecord,
  EnvelopeMomentFromServer,
} from "@/data/repositories/V2API";
import { inlineThrow } from "@/utils/throwExpression";

// Utility functions for constructing local database rows
// from the data that the API returns
export function entryDBRowFromFeed(
  entryRevision: EntryWithFeedRecord,
  existingEntry: EntryDBRow | undefined,
  hideAllEntries: boolean,
  userId?: string,
): EntryDBRow {
  const { entry, record: envelope } = entryRevision;
  if (!entry)
    inlineThrow(
      `Can't turn an empty entry revision into a DB row: ${envelope.revision.journalId}:${envelope.revision.entryId}`,
    );
  if (!entry.date) {
    const message = `Entry is expected to have a date field and it is not present. JournalID: ${envelope.revision.journalId}, EntryID: ${envelope.revision.entryId}`;
    console.error(message);
    Sentry.captureException(message);
  }

  return {
    id: entry.id,
    body: entry.body || "",
    journal_id: envelope.revision.journalId,
    owner_user_id: envelope.revision.ownerUserId,
    editor_user_id: envelope.revision.editorUserId,
    creator_user_id: envelope.revision.creatorUserId,
    activity: entry.activity || "",
    date: entry.date,
    duration: entry.duration || 0,
    edit_date: envelope.revision.editDate || 0,
    revision_id: envelope.revision.revisionId,
    editing_time: entry.editingTime || 0,
    is_all_day: entry.isAllDay ? 1 : 0,
    // in shared journals we only want to show the entry pinned
    // for the entry creator. These entries won't sync up
    // so it's ok to save the modified value
    is_pinned:
      entry.isPinned && userId === envelope.revision.creatorUserId ? 1 : 0,
    is_starred: entry.starred ? 1 : 0,
    location: entry.location || null,
    weather: entry.weather || null,
    timezone: entry.timeZone || "",
    user_edit_date: envelope.revision.editDate || 0,
    last_editing_device_name: existingEntry?.last_editing_device_name || "",
    last_editing_device_id: existingEntry?.last_editing_device_id || "",
    rich_text_json: JSON.stringify(entry.richTextJSON || {}),
    client_meta: JSON.stringify(entry.clientMeta || {}),
    templateID: entry.templateID || null,
    promptID: entry.promptID || null,
    is_deleted: 0,
    feature_flags: envelope.revision.featureFlags,
    reactions: envelope.revision.reactions || [],
    unread_marker_id: envelope.revision.unread_marker_id || null,
    // Is shared comes from the journal record
    is_shared: envelope.revision.is_shared ? 1 : 0,
    steps: entry.steps ?? undefined,
    music: entry.music ?? undefined,
    // Hide in all entries comes from the journal record
    hide_all_entries: hideAllEntries ? 1 : 0,
  };
}

export function momentDBRowFromFeed(
  journalId: string,
  entryId: string,
  // The moment in the body will have a decrypted md5
  momentFromBody: EntryMoment,
  // The moment in the envelope may have an encrypted md5
  momentFromEnvelope: EnvelopeMomentFromServer | undefined,
): MomentDBRow {
  const metadata = {
    duration: momentFromBody.duration || null,
    fileSize: momentFromEnvelope?.fileSize || null,
    location: momentFromBody.location || null,
    audioChannels: momentFromBody.audioChannels || null,
    format: momentFromBody.format || null,
    recordingDevice: momentFromBody.recordingDevice || null,
    sampleRate: momentFromBody.sampleRate || null,
    timeZoneName: momentFromBody.timeZoneName || null,
    pdfName: momentFromBody.pdfName || null,
    title: momentFromBody.title || null,
  };
  return {
    id: momentFromBody.id,
    entry_id: entryId,
    journal_id: journalId,
    md5_envelope: momentFromEnvelope?.md5 || null,
    md5_body: momentFromBody.md5,
    content_type: momentFromBody.contentType,
    date: momentFromBody.date || 0,
    creation_device: momentFromBody.creationDevice || "",
    creation_device_identifier: momentFromBody.creationDeviceIdentifier || "",
    favorite: momentFromBody.favorite ? 1 : 0,
    is_sketch: momentFromBody.isSketch ? 1 : 0,
    height: momentFromBody.height || 0,
    width: momentFromBody.width || 0,
    thumbnail_content_type: momentFromBody?.thumbnail?.contentType || "",
    thumbnail_md5_envelope: momentFromEnvelope?.thumbnail?.md5 || null,
    thumbnail_md5_body: momentFromBody?.thumbnail?.md5 || "",
    thumbnail_height: momentFromBody?.thumbnail?.height || 0,
    thumbnail_width: momentFromBody?.thumbnail?.width || 0,
    thumbnail_size_bytes: momentFromBody?.thumbnail?.fileSize || null,
    type: momentFromBody.type as momentType,
    is_promise: momentFromEnvelope?.md5 ? 0 : 1,
    metadata,
    pdfName: momentFromBody.pdfName || null,
  };
}
