export const GoogleLogo: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5326 10.1868C19.5326 9.36743 19.4661 8.76949 19.3222 8.14941H9.96558V11.8477H15.4577C15.347 12.7668 14.7491 14.1509 13.4203 15.081L13.4017 15.2048L16.3601 17.4967L16.565 17.5171C18.4474 15.7787 19.5326 13.2208 19.5326 10.1868Z"
        fill="#4285F4"
      />
      <path
        d="M9.96566 19.9312C12.6563 19.9312 14.9152 19.0453 16.5651 17.5173L13.4204 15.0812C12.5789 15.6681 11.4494 16.0777 9.96566 16.0777C7.33032 16.0777 5.0936 14.3393 4.29627 11.9365L4.1794 11.9464L1.10322 14.3271L1.06299 14.439C2.70177 17.6944 6.06795 19.9312 9.96566 19.9312Z"
        fill="#34A853"
      />
      <path
        d="M4.29626 11.9363C4.08588 11.3163 3.96412 10.6518 3.96412 9.96534C3.96412 9.27878 4.08588 8.61443 4.28519 7.99435L4.27962 7.86229L1.16489 5.44336L1.06298 5.49183C0.387557 6.84275 0 8.35977 0 9.96534C0 11.5709 0.387557 13.0879 1.06298 14.4388L4.29626 11.9363Z"
        fill="#FBBC05"
      />
      <path
        d="M9.96566 3.85336C11.837 3.85336 13.0992 4.66168 13.819 5.33718L16.6315 2.59107C14.9042 0.985496 12.6563 0 9.96566 0C6.06795 0 2.70177 2.23672 1.06299 5.49214L4.2852 7.99466C5.0936 5.59183 7.33032 3.85336 9.96566 3.85336Z"
        fill="#EB4335"
      />
    </svg>
  );
};
