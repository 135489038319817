import { v4, v5 } from "uuid";

export const uuid = v4;

export const mkEntryID = (seed?: string) => {
  const uuid_namespace = "c0c01473-4f84-4f15-ae17-ab43385fe18d";
  const name = seed ? `${Date.now()}-${seed}` : `${Date.now()}`;
  const id = v5(name, uuid_namespace).toUpperCase().replace(/-/g, "");
  return id;
};
