import { getClientMeta } from "@/data/utils/clientMeta";

export const getDeviceHeaders = async () => {
  const language = navigator?.language || "en-US";
  const country = language.split("-")[1] || "US";
  const deviceMeta = await getClientMeta();
  const {
    deviceName,
    creationDevice,
    creationDeviceModel,
    creationOSName,
    creationOSVersion,
  } = deviceMeta;

  const osVersionIsValid = creationOSVersion.match(/^[0-9.]+$/);
  let fixedOSVersion = creationOSVersion;
  if (!osVersionIsValid) {
    fixedOSVersion = "0.0.0";
  }

  const deviceInfo = `Id="${
    deviceMeta.deviceId || "unknown"
  }"; Model="${creationOSName} ${creationDeviceModel}"; Name="${deviceName}"; Language="${language}"; Country="${country}"; app_id="com.bloombuilt.dayone-web"`;

  const producName = "DayOneWeb";
  const productVersion = process.env.APP_VERSION ?? "1.0.0";
  const locale = navigator?.language || "en-US";
  const xUserAgent = `${producName}/${productVersion} (${locale}; ${creationOSName}/${fixedOSVersion}; ${creationDeviceModel}; ${creationDevice}; Release/1; Core/1.0.0)`;

  return { deviceInfo, xUserAgent };
};
