import { BlockInstance } from "@wordpress/blocks";
import { Button, Icon, TabPanel } from "@wordpress/components";
import { __, sprintf } from "@wordpress/i18n";
import { moreHorizontal } from "@wordpress/icons";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Modal } from "@/components/D1Modal";
import { Previewer } from "@/components/Editor/Previewer";
import { TemplateEditor } from "@/components/Editor/TemplateEditor";
import { convertGBBlocksToRTJNodes } from "@/components/Editor/gb2rtj/gb2rtj";
import { convertRTJToBlocks } from "@/components/Editor/rtj2gb/rtj2gb";
import { MyTemplatesList } from "@/components/Templates/MyTemplatesList";
import { TemplateGalleryList } from "@/components/Templates/TemplateGalleryList";
import {
  TemplateModalFooter,
  TEMPLATE_MODAL_FOOTER_HEIGHT,
} from "@/components/Templates/TemplateModalFooter";
import {
  TemplateModalHeader,
  TEMPLATE_MODAL_HEADER_HEIGHT,
} from "@/components/Templates/TemplateModalHeader";
import {
  TemplateSettingsModal,
  fixTags,
} from "@/components/Templates/TemplateSettingsModal";
import {
  GalleryTemplate,
  PersonalTemplate,
  SelectedTemplate,
  TemplateGalleryCategory,
  TemplateGalleryDBRow,
} from "@/data/db/migrations/template";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { useCreateNewEntry } from "@/hooks/useCreateNewEntry";
import { useElementSize } from "@/hooks/useElementSize";
import { templatesTab } from "@/layouts/ModalRoutes";
import { createRichTextJsonString, decodeRichTextJson } from "@/utils/rtj";
import { switchOnValue } from "@/utils/switching";
import { uuid } from "@/utils/uuid";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
  tab?: templatesTab;
  templateId?: string;
};

export const ManageTemplates: React.FC<Props> = observer(
  ({ handleClose, tab = "gallery", templateId }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const templates = viewStates.primary.templates;
    const [selectedTemplate, setSelectedTemplate] =
      useState<SelectedTemplate | null>(null);

    const { isMobile } = useDevice();

    const [mobileView, setMobileView] = useState<"list" | "editor">("list");

    const [modalClass, setModalClass] = useState("components-modal__content");
    const { height: modalHeight } = useElementSize(modalClass, false);
    const [isLoading, setIsLoading] = useState(true);
    const [editorContent, setEditorContent] = useState<BlockInstance[]>([]);
    const [showSettings, setShowSettings] = useState(false);
    const [templateTitle, setTemplateTitle] = useState(__("New Template"));
    const [hasChanges, setHasChanges] = useState(false);
    const canEncrypt = viewStates.primary.isEncryptionReady;
    const [galleryTemplates, setGalleryTemplates] = useState<
      Map<string, TemplateGalleryDBRow[]>
    >(new Map());
    const [galleryTemplateCategories, setGalleryTemplateCategories] = useState<
      TemplateGalleryCategory[]
    >([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const { createNewEntry } = useCreateNewEntry(
      "template_management_gallery",
      "template",
    );

    const encoded =
      selectedTemplate?.type === "gallery"
        ? selectedTemplate?.content
        : selectedTemplate?.richText;

    const rtj = decodeRichTextJson(encoded);
    const content = convertRTJToBlocks(rtj.contents, "", "");

    useLayoutEffect(() => {
      const classList = modalRef.current?.firstElementChild?.classList
        .toString()
        .split(" ");
      if (classList) {
        const elementClass = classList[classList.length - 1];
        if (elementClass) {
          setModalClass(elementClass);
        }
      }
    }, []);

    const loadTemplates = async () => {
      await d1Classes.templateGalleryRepository.synchronize();
      const categories =
        await d1Classes.templateGalleryRepository.getCategories();
      const galleryTemplateMap = new Map<string, TemplateGalleryDBRow[]>();
      await Promise.all(
        categories.map(async (category) => {
          const templates =
            await d1Classes.templateGalleryRepository.getTemplatesByCategory(
              category.id,
            );
          galleryTemplateMap.set(category.id, templates);
        }),
      );

      setGalleryTemplateCategories(categories);
      setGalleryTemplates(galleryTemplateMap);
      setIsLoading(false);
      if (tab === "gallery") {
        if (selectedTemplate?.type === "gallery") {
          return;
        }
        const firstTemplate =
          await d1Classes.templateGalleryRepository.getFirstTemplate();
        if (tab === "gallery" && firstTemplate) {
          setSelectedTemplate({ ...firstTemplate, type: "gallery" });
        }
      } else if (tab === "myTemplates") {
        if (
          selectedTemplate?.type === "mine" ||
          selectedTemplate?.type === "new"
        ) {
          return;
        }

        if (templates.length > 0) {
          // If there is a template open in the editor and we get a new version of it from the server
          // don't change the selected template so we don't get a rerender of the editor
          const firstTemplate = templates[0];
          const selectedTemplate = templateId
            ? templates.find((t) => t.clientId === templateId) || firstTemplate
            : firstTemplate;

          setSelectedTemplate((prev) => {
            if (
              prev?.type === "mine" &&
              prev.clientId === selectedTemplate.clientId
            ) {
              return prev;
            } else {
              return { ...selectedTemplate, type: "mine" };
            }
          });
        } else {
          setSelectedTemplate(null);
        }
      } else {
        setSelectedTemplate(null);
      }
    };

    useEffect(() => {
      loadTemplates();
    }, [tab, templates, canEncrypt]);

    useEffect(() => {
      setHasChanges(false);
      if (selectedTemplate?.type === "mine") {
        setEditorContent(content);
        setTemplateTitle(selectedTemplate.title);
      } else if (selectedTemplate?.type === "gallery") {
        setEditorContent([]);
        setTemplateTitle(selectedTemplate?.name);
      } else {
        setEditorContent([]);
        setTemplateTitle(__("New Template"));
      }
    }, [selectedTemplate]);

    const templateListClickHandler = (template: SelectedTemplate) => {
      setSelectedTemplate(template);
      if (isMobile) {
        setMobileView("editor");
      }
    };

    const showPanel = () => {
      return switchOnValue(tab, {
        gallery: () => (
          <TemplateGalleryList
            modalHeight={modalHeight}
            handleClick={templateListClickHandler}
            activeTemplate={selectedTemplate as GalleryTemplate}
            isLoading={isLoading}
            categories={galleryTemplateCategories}
            galleryTemplates={galleryTemplates}
          />
        ),
        myTemplates: () => (
          <MyTemplatesList
            modalHeight={modalHeight}
            templates={templates}
            handleClick={templateListClickHandler}
            activeTemplate={selectedTemplate as PersonalTemplate}
            canEncrypt={canEncrypt}
          />
        ),
      });
    };

    const handleUseGalleryTemplateClick = async () => {
      createNewEntry(viewStates.primary.selectedJournalId || "", {
        prefill: {
          richTextJson: rtj.contents,
          templateId: selectedTemplate?.id || "",
          galleryTemplateID: selectedTemplate?.id,
        },
      });
      viewStates.snackbar.newMessage(
        sprintf(
          __(`A new entry was created with the template "%s".`),
          templateTitle,
        ),
      );
      viewStates.modalRouter.close();
    };

    const handleSaveTemplate = async (template: SelectedTemplate) => {
      if (template?.type !== "mine") {
        return;
      }
      const convertedBlocks = convertGBBlocksToRTJNodes(editorContent);

      const journalName = template.journalSyncID
        ? primaryViewState.journals.find((j) => {
            return j.id === template.journalSyncID;
          })?.name
        : "";
      await d1Classes.templateRepository.updateLocalTemplate(
        template.clientId,
        {
          title: template.title,
          richText: createRichTextJsonString(convertedBlocks),
          order: Date.now(),
          tags: fixTags(template.tags),
          journalName: journalName,
          journalSyncID: template.journalSyncID,
          client_id: template.clientId,
        },
      );
      const newTemplate = await d1Classes.templateRepository.getById(
        template.clientId,
      );
      if (newTemplate) {
        setSelectedTemplate({ ...newTemplate, type: "mine" });
      }
      viewStates.snackbar.newMessage(__("Template saved"));
    };

    const handleDeleteTemplate = async () => {
      if (!selectedTemplate || selectedTemplate.type !== "mine") {
        return;
      }
      await d1Classes.templateRepository.deleteTemplate(
        selectedTemplate.clientId,
      );
      analytics.tracks.recordEvent(EVENT.templateDeleted, {});
      setSelectedTemplate(null);
    };

    const handleCreateNewTemplate = async (template: SelectedTemplate) => {
      if (template?.type !== "new") {
        return;
      }
      const rtj = convertGBBlocksToRTJNodes(editorContent);
      const journalName = template.journalSyncID
        ? primaryViewState.journals.find((j) => {
            return j.id === template.journalSyncID;
          })?.name
        : "";
      await d1Classes.templateRepository.createLocalTemplate({
        id: "",
        title: template?.title || "",
        richText: createRichTextJsonString(rtj),
        order: Date.now(),
        tags: fixTags(template.tags),
        journalName: journalName,
        journalSyncID: template?.journalSyncID || "",
        client_id: uuid(),
      });
      viewStates.snackbar.newMessage(__("New template saved"));
      analytics.tracks.recordEvent(EVENT.templateAdded, {});
    };

    const handleCreateTemplateFromGallery = async (
      templateName: string,
      templateID: string,
    ) => {
      const templateId = uuid();
      await d1Classes.templateRepository.createLocalTemplate({
        id: "",
        title: templateName,
        richText: JSON.stringify(rtj),
        order: Date.now(),
        tags: [],
        journalName: "",
        journalSyncID: "",
        client_id: templateId,
        galleryTemplateID: templateID,
      });
      viewStates.modalRouter.openTemplateManagement("myTemplates", templateId);
      analytics.tracks.recordEvent(EVENT.templateAdded, {
        gallery_template_id: templateID,
      });
      const myTemplatesButton = document.querySelector(
        ".myTemplates-tab",
      ) as HTMLButtonElement;
      if (myTemplatesButton) {
        myTemplatesButton.click();
      }
      viewStates.snackbar.newMessage(
        __("New template saved from the Template Gallery"),
      );
    };

    const mobileHeight = `calc(100% -  40px)`;

    const disabledButton = !hasChanges || isLoading;

    const titleWithSettings = (title: string) => {
      return (
        <div
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flexGrow: 0,
            }}
            title={title}
          >
            {title}
          </div>
          <Button
            sx={{ mr: 3 }}
            className="template-settings"
            label={__("Template settings")}
            onClick={() => {
              setShowSettings(!showSettings);
            }}
          >
            <Icon icon={moreHorizontal} />
          </Button>
        </div>
      );
    };

    return (
      <>
        <D1Modal
          ref={modalRef}
          onRequestClose={handleClose}
          hideHeader
          sx={{
            width: ["100%", "100%", "750px"],
            height: [mobileHeight, mobileHeight, "80vh"],
            maxHeight: ["fill-available", "fill-available", "90vh"],
            ".components-modal__content": { p: 0, mt: 0, overflow: "hidden" },
            ".components-tab-panel__tabs": {
              height: `${TEMPLATE_MODAL_HEADER_HEIGHT}px`,
              borderBottom: "1px solid",
              borderColor: "borderPrimary",
              justifyContent: "center",
              "& button": {
                height: `${TEMPLATE_MODAL_HEADER_HEIGHT}px`,
                color: "textSecondary",
              },
              "& button.is-active": {
                color: "textPrimary",
              },
              "& .is-active::after": {
                height: "3px",
                backgroundColor: "textPrimary",
              },
            },
            "& footer": {
              display: isMobile && mobileView === "list" ? "none" : "flex",
            },
          }}
        >
          <div
            sx={{
              display: "flex",
              height: [mobileHeight, mobileHeight, "90vh"],
            }}
          >
            <div
              sx={{
                flexShrink: 0,
                width: ["100%", "100%", "225px"],
                backgroundColor: "surface_light2_dark2",
                display: isMobile && mobileView === "editor" ? "none" : "block",
              }}
            >
              <TabPanel
                onSelect={(tabName: string) => {
                  setSelectedTemplate(null);
                  if (tabName !== tab) {
                    viewStates.modalRouter.openTemplateManagement(
                      tabName as templatesTab,
                    );
                  }
                }}
                initialTabName={tab}
                tabs={[
                  {
                    name: "gallery",
                    title: __("Gallery"),
                    className: "gallery-tab",
                  },
                  {
                    name: "myTemplates",
                    title: __("My templates"),
                    className: "myTemplates-tab",
                  },
                ]}
              >
                {() => (
                  <div
                    id="d1-template-management-panel__content"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      flexGrow: 1,
                    }}
                  >
                    {showPanel()}
                  </div>
                )}
              </TabPanel>
            </div>
            <div
              sx={{
                width: ["0", "0", "calc(100% - 225px)"],
                flexBasis: ["0", "0", "100%"],
                display: isMobile && mobileView !== "editor" ? "none" : "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: `${modalHeight}px`,
              }}
            >
              {!selectedTemplate && (
                <TemplateModalHeader
                  setMobileView={setMobileView}
                ></TemplateModalHeader>
              )}
              {tab === "myTemplates" && selectedTemplate?.type === "mine" && (
                <>
                  <TemplateModalHeader setMobileView={setMobileView}>
                    {titleWithSettings(templateTitle)}
                  </TemplateModalHeader>

                  <TemplateEditor
                    content={editorContent}
                    onChange={(blocks: BlockInstance[]) => {
                      setHasChanges(true);
                      setEditorContent(blocks);
                    }}
                    viewStates={viewStates}
                    height={
                      modalHeight -
                      TEMPLATE_MODAL_HEADER_HEIGHT -
                      TEMPLATE_MODAL_FOOTER_HEIGHT
                    }
                    footer={
                      <TemplateModalFooter>
                        <div
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            onClick={() => setShowConfirmDelete(true)}
                            variant="tertiary"
                            sx={{
                              "&&": {
                                color: "red",
                              },
                            }}
                          >
                            {__("Delete Template")}
                          </Button>
                          <div>
                            <Button
                              sx={{ mr: 2 }}
                              variant="secondary"
                              disabled={disabledButton}
                              onClick={() => {
                                setSelectedTemplate({ ...selectedTemplate });
                              }}
                            >
                              {__("Undo Changes")}
                            </Button>
                            <Button
                              disabled={disabledButton}
                              variant="primary"
                              onClick={() => {
                                handleSaveTemplate(selectedTemplate);
                              }}
                            >
                              {__("Save")}
                            </Button>
                          </div>
                          {showConfirmDelete && (
                            <ConfirmDialog
                              handleClose={() => setShowConfirmDelete(false)}
                              handleAction={() => {
                                handleDeleteTemplate();
                                setShowConfirmDelete(false);
                              }}
                              message={__(
                                "Are you sure you want to delete this template?",
                              )}
                              isDestructive={true}
                            />
                          )}
                        </div>
                      </TemplateModalFooter>
                    }
                  />
                </>
              )}
              {selectedTemplate?.type === "new" && (
                <>
                  <TemplateModalHeader setMobileView={setMobileView}>
                    {titleWithSettings(templateTitle)}
                  </TemplateModalHeader>

                  <TemplateEditor
                    content={editorContent}
                    onChange={(blocks: BlockInstance[]) => {
                      setHasChanges(true);
                      setEditorContent(blocks);
                    }}
                    viewStates={viewStates}
                    height={
                      modalHeight -
                      TEMPLATE_MODAL_HEADER_HEIGHT -
                      TEMPLATE_MODAL_FOOTER_HEIGHT
                    }
                    footer={
                      <TemplateModalFooter>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setSelectedTemplate({ ...selectedTemplate });
                          }}
                        >
                          {__("Cancel")}
                        </Button>
                        <Button
                          disabled={disabledButton}
                          variant="primary"
                          onClick={() => {
                            handleCreateNewTemplate(selectedTemplate);
                          }}
                        >
                          {__("Save")}
                        </Button>
                      </TemplateModalFooter>
                    }
                  />
                </>
              )}
              {tab === "gallery" && selectedTemplate?.type === "gallery" && (
                <>
                  <TemplateModalHeader setMobileView={setMobileView}>
                    {selectedTemplate.name}
                  </TemplateModalHeader>
                  <Previewer
                    blocks={content}
                    showAdditionaInfo={false}
                    height={`${
                      modalHeight -
                      TEMPLATE_MODAL_HEADER_HEIGHT -
                      TEMPLATE_MODAL_FOOTER_HEIGHT
                    }px`}
                    footer={
                      <TemplateModalFooter>
                        <Button
                          variant="secondary"
                          onClick={handleUseGalleryTemplateClick}
                        >
                          {__("Use Now")}
                        </Button>
                        {canEncrypt && (
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleCreateTemplateFromGallery(
                                selectedTemplate.name,
                                selectedTemplate.id,
                              )
                            }
                          >
                            {__("Save to My Templates")}
                          </Button>
                        )}
                      </TemplateModalFooter>
                    }
                  />
                </>
              )}
            </div>
          </div>
        </D1Modal>
        {showSettings && selectedTemplate?.type !== "gallery" && (
          <TemplateSettingsModal
            handleClose={() => setShowSettings(false)}
            selectedTemplate={selectedTemplate}
            saveTemplate={
              selectedTemplate?.type === "mine"
                ? (template: SelectedTemplate) => {
                    handleSaveTemplate(template);
                  }
                : (template: SelectedTemplate) => {
                    handleCreateNewTemplate(template);
                  }
            }
          />
        )}
      </>
    );
  },
);
