import { d1Classes } from "@/D1Classes";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
export interface KeyStore {
  getMainUserPublicKey(): Promise<CryptoKey | null>;
  getMainUserPrivateKey(): Promise<CryptoKey | null>;
}

export class KeyGenerator {
  constructor(protected userKeysStore: KeyStore = d1Classes.userKeysStore) {}

  async getPublicKey(): Promise<CryptoKey> {
    const publicKey = await this.userKeysStore.getMainUserPublicKey();
    if (!publicKey) {
      throw new Error("No public key found");
    }
    return publicKey;
  }

  async getPrivateKey(): Promise<CryptoKey> {
    const privateKey = await this.userKeysStore.getMainUserPrivateKey();
    if (!privateKey) {
      throw new Error("No private key found");
    }
    return privateKey;
  }

  async signPublicKey(publicKey: string): Promise<string> {
    const privateKey = await this.getPrivateKey();
    const signature = await Asymmetric.Private.signArrayBuffer({
      userPrivateKey: privateKey,
      // This is a bit funky, we're actually signing the PEM as UTF-8
      // bytes rather than the bytes of the private key itself
      // but that's what the server expects
      buffer: Utf8.toUintArray(publicKey),
    });
    return signature;
  }
}
