import { Flex, FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import React from "react";

import { Button } from "@/components/Settings/Button";

type DoneProps = {
  doClose: () => void;
  message: string;
};

export const Done: React.FC<DoneProps> = ({ doClose, message }) => {
  const { __ } = useI18n();
  return (
    <>
      <div sx={{ mb: 3 }}>{message}</div>
      <Flex>
        <div sx={{ flex: 1 }} />
        <FlexItem>
          <Button variant="primary" onClick={doClose} autoFocus>
            {__("OK")}
          </Button>
        </FlexItem>
      </Flex>
    </>
  );
};
