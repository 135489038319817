export const Location: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12695 10.045V9.873C5.12695 6.077 8.20395 3 12 3V3C15.796 3 18.873 6.077 18.873 9.873V10.045C18.873 13.506 14.491 18.716 12.725 20.676C12.336 21.108 11.664 21.108 11.275 20.676C9.50895 18.716 5.12695 13.506 5.12695 10.045Z"
        stroke="currentColor"
        strokeWidth="1.4468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 9.95497C10 11.06 10.895 11.955 12 11.955V11.955C13.105 11.955 14 11.06 14 9.95497V9.91797C14 8.81297 13.105 7.91797 12 7.91797V7.91797C10.895 7.91797 10 8.81297 10 9.91797"
        stroke="currentColor"
        strokeWidth="1.4468"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
