import { TopBanner } from "@/components/AppDownload/TopBanner";
import { DayOneLogo } from "@/components/LoggedOut/DayOneLogo";
import { DayOneLogo as IconLogo } from "@/components/icons/DayOneLogo";
import { useDevice } from "@/data/hooks/LayoutProvider";

export type Props = {
  noBorder?: boolean;
  background?: string;
  showIconLogo?: boolean;
};
export const HEADER_HEIGHT = "64px";

export const HeaderBar: React.FC<Props> = ({
  noBorder = false,
  background,
  showIconLogo = false,
}) => {
  const { isMobile } = useDevice();
  return (
    <>
      <TopBanner />
      <div
        sx={{
          bg: background || "surface_light2_dark2",
          boxShadow: noBorder ? "unset" : "0 1px 0 0 rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          height: HEADER_HEIGHT,
          width: "100%",
          ...(showIconLogo && { pl: isMobile ? 3.5 : 7 }),
          ...(isMobile &&
            showIconLogo && { "svg:first-of-type": { width: 27, height: 27 } }),
        }}
      >
        {showIconLogo && <IconLogo filled />}
        <DayOneLogo />
      </div>
    </>
  );
};
