import { store as blockEditorStore } from "@wordpress/block-editor";
import { createBlock, cloneBlock } from "@wordpress/blocks";
import { useRefEffect } from "@wordpress/compose";
import { useSelect, useDispatch } from "@wordpress/data";
import { useRef } from "@wordpress/element";
import { nanoid } from "nanoid";

import {
  CHECKLIST_ITEM_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { ENTER } from "@/components/Editor/utils/keycodes";

export default function useEnter(props: any) {
  const { replaceBlocks, selectionChange, updateBlockAttributes } =
    useDispatch(blockEditorStore);
  const { getBlock, getSelectionStart, getSelectionEnd } = useSelect(
    blockEditorStore,
    [],
  );

  const propsRef = useRef(props);
  propsRef.current = props;

  return useRefEffect((element) => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.defaultPrevented || event.key !== ENTER) {
        return;
      }
      event.preventDefault();

      const { content, clientId, indent } = propsRef.current;

      // If empty block, replace with paragraph.
      if (!content.trim().length) {
        // If it's an empty block with minimal indent, replace with paragraph
        if (indent === 1) {
          replaceBlocks(clientId, [createBlock(PARAGRAPH_BLOCK_ID)]);
          return;
        }
        // If it's an empty block with more than minimal indent, keep it and outdent
        updateBlockAttributes([clientId], { indent: indent - 1 });
        return;
      }

      // Get the current cursor selection
      const selectionStart = getSelectionStart();
      const selectionEnd = getSelectionEnd();

      const selOffset = selectionEnd.offset;
      let headContent = content.slice(0, selOffset);
      // This means there is text selected and we need to remove it on enter
      if (selectionStart.offset !== selOffset) {
        // current block will have the selected text removed
        headContent = content.slice(0, selectionStart.offset);
      }

      const currentBlock = getBlock(clientId);
      // Split the current block at the selection offset
      const head = cloneBlock({
        ...currentBlock,
        attributes: {
          ...currentBlock.attributes,
          content: headContent,
        },
      });

      // Create a new block with the remaining content
      const newBlock = createBlock(CHECKLIST_ITEM_BLOCK_ID, {
        checked: false,
        content: content.slice(selOffset),
        identifier: nanoid(),
        indent,
      });

      // Replace the current block with the split blocks
      replaceBlocks(clientId, [head, newBlock]);
      // Change selection to the new block
      selectionChange(newBlock.clientId, "content");
    };

    element.addEventListener("keydown", onKeyDown as EventListener);
    return () => {
      element.removeEventListener("keydown", onKeyDown as EventListener);
    };
  }, []);
}
