import { createBlobURL } from "@wordpress/blob";
import { BlockInstance } from "@wordpress/blocks";
import { createBlock } from "@wordpress/blocks";

import {
  AUDIO_BLOCK_ID,
  IMAGE_BLOCK_ID,
  PDF_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
type MediaType = "audio/" | "image/" | "application/pdf" | "video/";

type MediaBlockId =
  | typeof AUDIO_BLOCK_ID
  | typeof IMAGE_BLOCK_ID
  | typeof PDF_BLOCK_ID
  | typeof VIDEO_BLOCK_ID;

export function deduplicateClientId(blocks: BlockInstance[]): BlockInstance[] {
  const clientIdSet = new Set();
  const deduplicate = (blocks: BlockInstance[]): BlockInstance[] => {
    const deduplicatedBlocks: BlockInstance[] = [];
    for (const block of blocks) {
      if (!clientIdSet.has(block.clientId)) {
        deduplicatedBlocks.push(block);
        clientIdSet.add(block.clientId);
      }
      if (block.innerBlocks) {
        const deduplicatedInnerBlocks = deduplicate(block.innerBlocks);
        if (deduplicatedInnerBlocks.length > 0) {
          const newBlock = { ...block, innerBlocks: deduplicatedInnerBlocks };
          deduplicatedBlocks[deduplicatedBlocks.length - 1] = newBlock;
        }
      }
    }
    return deduplicatedBlocks;
  };
  return deduplicate(blocks);
}

function createTransform(typeMatch: MediaType, blockId: MediaBlockId) {
  return {
    from: [
      {
        // the "files" transform runs when a file is pasted into the editor
        type: "files",
        isMatch: (files: File[]) => {
          return files.length === 1 && files[0].type.startsWith(typeMatch);
        },
        transform: (files: File[]) => {
          // Because we can't access state or async functions within transforms,
          // we create an incomplete block here, storing the blob URL in an attribute.
          // We'll then create the moment in the edit function (above)
          const file = files[0];
          const block = createBlock(blockId, {
            src: createBlobURL(file),
          });
          return block;
        },
      },
    ],
  };
}

const audioTransform = createTransform("audio/", AUDIO_BLOCK_ID);
const pdfTransform = createTransform("application/pdf", PDF_BLOCK_ID);
const imageTransform = createTransform("image/", IMAGE_BLOCK_ID);
const videoTransform = createTransform("video/", VIDEO_BLOCK_ID);

export const mediaTransforms = {
  audioTransform,
  pdfTransform,
  imageTransform,
  videoTransform,
};
