import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";

type Props = {
  url: string;
};
export const NoPreview: React.FC<Props> = ({ url }) => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        bg: "surface_light3_dark3",
        color: "textPrimary",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        my: 3,
        fontSize: 0,
        p: 5,
        "& p": {
          mb: 2,
          lineHeight: 2,
        },
      }}
    >
      <p>
        {sprintf(
          __(
            "We were not able to generate the preview for %s at this time. Perhaps you are currently offline? You can try visiting the link below.",
          ),
          url,
        )}
      </p>
      {url ? (
        <p>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </p>
      ) : null}
    </div>
  );
};
