export const headingTwo = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.40001 5.99988H10.8V17.9999H8.40001V13.1999H3.60001V17.9999H1.20001V5.99988H3.60001V10.7999H8.40001V5.99988ZM18 15.5999C18.6 15.1199 18.72 14.8799 19.32 14.2799C19.8 13.7999 20.28 13.3199 20.76 12.7199C21.12 12.2399 21.48 11.7599 21.84 11.1599C22.08 10.6799 22.2 10.1999 22.2 9.59988C22.2 9.11988 22.08 8.51988 21.84 8.03988C21.6 7.55988 21.36 7.19988 20.88 6.83988C20.52 6.47988 20.04 6.23988 19.44 6.11988C18.84 5.87988 18.24 5.87988 17.64 5.87988C17.16 5.87988 16.8 5.87988 16.32 5.99988C15.96 6.11988 15.48 6.23988 15.12 6.35988C14.76 6.47988 14.4 6.71988 14.04 6.95988C13.68 7.19988 13.32 7.43988 13.08 7.79988L14.52 9.23988C14.88 8.87988 15.24 8.63988 15.72 8.39988C16.2 8.15988 16.56 8.03988 17.16 8.03988C17.76 8.03988 18.24 8.15988 18.72 8.51988C19.08 8.87988 19.32 9.35988 19.32 9.83988C19.32 10.3199 19.2 10.7999 18.84 11.1599C18.48 11.7599 18.12 12.2399 17.64 12.5999C17.16 13.0799 16.44 13.6799 15.72 14.2799C15 14.8799 14.04 15.5999 13.08 16.1999V17.9999H22.68V15.5999H18Z" />
    </svg>
  );
};
