import { Button } from "@wordpress/components";
import { useState } from "react";

import { TextControl } from "@/components/Form/TextControl";
import { Item } from "@/components/ItemGroup";

export const TestCSP: React.FC = () => {
  const [cspResult, setCspResult] = useState("");
  const [url, setUrl] = useState("");

  const testCSP = async () => {
    let message = "";
    if (url.trim() !== "") {
      try {
        const result = await fetch(url);
        if (result.ok) {
          message = `Successfully reached the URL ${url}`;
        } else {
          message = `Failed to reach the URL ${url}`;
        }
      } catch (e) {
        message = `Failed to reach the URL ${url}`;
      }
    }

    const head = document.getElementsByTagName("head")[0];
    if (head) {
      message += "\nCheck browser network tab to see if images loaded via CSS";
      const style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML =
        "body { background-image: url(https://dayoneapp.com/wp-content/uploads/2021/09/intro-devices402x.jpg); }";
      head.appendChild(style);
      setCspResult(message);
      setTimeout(() => {
        head.removeChild(style);
      }, 1000);
    }
  };
  return (
    <Item>
      <div sx={{ fontSize: 1, flex: 2 }}>
        <TextControl
          value={url}
          onChange={(value) => {
            setUrl(value);
          }}
          onKeyUp={(e) => {
            if (e.key.toLowerCase() === "enter") {
              setCspResult("");
              testCSP();
            }
          }}
        />
        {cspResult === "" && "Enter a URL to test"}
        {cspResult !== "" && (
          <>
            <p sx={{ whiteSpace: "pre" }}>{cspResult}</p>
          </>
        )}
      </div>
      <div
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="primary"
          onClick={() => testCSP()}
          sx={{ display: "block" }}
        >
          Test
        </Button>
      </div>
    </Item>
  );
};
