import { Icon, chevronLeft } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ItemButton } from "@/components/ItemGroup";
import { EditJournalMain } from "@/components/JournalSettings/EditJournalMain";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { ViewName } from "@/components/JournalSettings/JournalSettingsTypes";
import { JournalList } from "@/components/Settings/Journals/JournalList";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  modalRef: React.RefObject<HTMLDivElement>;
};

export type View = {
  primary: "list" | "edit";
  secondary: ViewName | null;
  journalID: string | null;
};

export const Journals: React.FC<Props> = observer(({ modalRef }) => {
  const journalStore = d1Classes.journalStore;

  const [view, setView] = useState<View>({
    primary: "list",
    secondary: null,
    journalID: null,
  });
  const journal = primaryViewState.getJournalById(view.journalID ?? "");
  const { __ } = useI18n();

  const changeView = (view: View) => {
    setView(view);
    modalRef.current
      ?.querySelector(".components-modal__content main")
      ?.scrollTo({ top: 0 });
  };

  return (
    <AnimatePresence initial={false}>
      {view.primary === "list" && <JournalList onJournalSelect={changeView} />}
      {view.primary === "edit" && journal && (
        <SettingsPanel
          header={
            view.secondary === "sharing" ? (
              <ItemButton
                onClick={() => {
                  changeView({
                    primary: "edit",
                    secondary: null,
                    journalID: view.journalID,
                  });
                }}
                label={__("Back to journal settings")}
              >
                <Icon icon={chevronLeft} size={24} />

                {__("Journal Sharing")}
              </ItemButton>
            ) : (
              <ItemButton
                onClick={() => {
                  changeView({
                    primary: "list",
                    secondary: null,
                    journalID: null,
                  });
                }}
                label={__("Back to journal list")}
              >
                <Icon icon={chevronLeft} size={24} />
                {journal.is_shared
                  ? __("Edit Shared Journal")
                  : __("Edit Journal")}
              </ItemButton>
            )
          }
        >
          <JournalSettingsSlideContent name="edit" direction="left">
            <EditJournalMain
              handleClose={() => {
                changeView({
                  primary: "list",
                  secondary: null,
                  journalID: null,
                });
              }}
              journalID={view.journalID ?? ""}
              journalStore={journalStore}
              primaryViewState={primaryViewState}
              snackbar={viewStates.snackbar}
              inModal={false}
              modalRef={modalRef}
              setParentView={(secondary: ViewName) => {
                changeView({ ...view, secondary });
              }}
              parentView={view.secondary}
            />
          </JournalSettingsSlideContent>
        </SettingsPanel>
      )}
    </AnimatePresence>
  );
});

Journals.displayName = "NewJournal";
