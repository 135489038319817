import { __experimentalItem as WPItem } from "@wordpress/components/";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Item: React.FC<Props> = ({ children, className }) => {
  return (
    <WPItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "48px",
        px: 3,
        "&&": {
          borderColor: "borderPrimary",
        },
        p: {
          fontSize: 1,
        },
      }}
      className={className}
    >
      {children}
    </WPItem>
  );
};
