import { DODexie } from "@/data/db/dexie_db";
import { GlobalEntryID } from "@/data/db/migrations/entry";

export async function syncOperations_removeEntriesAndAssociatedStuff(
  db: DODexie,
  globalIds: GlobalEntryID[],
): Promise<void> {
  // In a transaction, delete the following:
  // - entries
  // - moments
  // - tags
  // - reactions
  // - comments
  // - comment reactions
  // - notifications
  // - outbox items
  await db.transaction(
    "rw",
    [
      db.entries,
      db.moments,
      db.tags,
      db.reactions,
      db.comments,
      db.comment_reactions,
      db.notifications,
      db.outbox_items,
    ],
    async () => {
      await Promise.all([
        removeEntries(globalIds),
        removeMoments(globalIds),
        removeTags(globalIds),
        removeReactions(globalIds),
        removeComments(globalIds),
        removeCommentReactions(globalIds),
        removeNotifications(globalIds),
        removeOutboxItems(globalIds),
      ]);
    },
  );

  async function removeEntries(globalIDs: GlobalEntryID[]) {
    await db.entries
      .where(["journal_id", "id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeMoments(globalIDs: GlobalEntryID[]) {
    await db.moments
      .where(["journal_id", "entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeTags(globalIDs: GlobalEntryID[]) {
    await db.tags
      .where(["journal_id", "entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeReactions(globalIDs: GlobalEntryID[]) {
    await db.reactions
      .where(["journal_id", "entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeComments(globalIDs: GlobalEntryID[]) {
    await db.comments
      .where(["journal_id", "entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeCommentReactions(globalIDs: GlobalEntryID[]) {
    await db.comment_reactions
      .where(["journal_id", "entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeNotifications(globalIDs: GlobalEntryID[]) {
    await db.notifications
      .where(["metadata.journal_id", "metadata.entry_id"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }

  async function removeOutboxItems(globalIDs: GlobalEntryID[]) {
    await db.outbox_items
      .where(["journalId", "entryId"])
      .anyOf(globalIDs.map((id) => [id.journal_id, id.id]))
      .delete();
  }
}
