export const Tag: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6868 7.75887C15.5349 7.91079 15.5349 8.15709 15.6868 8.309C15.8388 8.46091 16.0851 8.46091 16.237 8.309C16.3889 8.15709 16.3889 7.91079 16.237 7.75887C16.0851 7.60696 15.8388 7.60696 15.6868 7.75887"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.756 3.02509L13.907 3.00009C13.376 2.99509 12.865 3.20509 12.489 3.58009L3.585 12.4881C2.804 13.2691 2.804 14.5361 3.585 15.3171L8.679 20.4141C9.46 21.1961 10.728 21.1961 11.509 20.4141L20.42 11.5001C20.791 11.1291 21 10.6251 21 10.0991V7.28709C21 6.76109 20.791 6.25709 20.42 5.88609L18.14 3.60509C17.772 3.23709 17.275 3.02909 16.756 3.02509Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
