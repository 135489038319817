import { Flex } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { HEADER_HEIGHT } from "@/components/LoggedOut/HeaderBar";
import { Action } from "@/pages/prefilledUrl/Action";
import { ErrorMessage } from "@/pages/prefilledUrl/ErrorMessage";
import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";

type Props = {
  state: PrefilledEntryViewState;
  isMobile: boolean;
};

export const Prompt: React.FC<Props> = observer(({ state, isMobile }) => {
  return (
    <Flex
      sx={{
        width: "100%",
        maxWidth: "800px",
        padding: isMobile ? 4 : 8,
        alignSelf: "center",
        justifySelf: "center",
        justifyContent: "center",
        mt: `-${HEADER_HEIGHT}`,
        borderRadius: isMobile ? 0 : 3,
        height: `calc(100dvh - ${HEADER_HEIGHT})`,
        textAlign: isMobile ? "left" : "center",
      }}
      direction="column"
      align="center"
    >
      {/* Error message! */}
      {state.error && <ErrorMessage />}
      <h1
        sx={{
          fontFamily: "prompt",
          mb: 4,
          textAlign: "center",
          fontSize: 5,
          fontWeight: "medium",
        }}
      >
        {state.prompt}
      </h1>
      {/* Buttons! */}
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "320px",
          maxWidth: "100%",
        }}
      >
        <Action state={state} isMobile={isMobile} />
      </div>
    </Flex>
  );
});
