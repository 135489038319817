import { action, makeAutoObservable } from "mobx";

import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Outbox } from "@/data/models/Outbox";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { syncStates, SyncStatus } from "@/worker/SyncWorkerTypes";

export class SyncViewState {
  state: SyncStatus = syncStates.IDLE;
  statSyncStatus: SyncStatus = syncStates.IDLE;
  outboxSize = 0;
  isOnline: boolean = window.navigator.onLine;

  constructor(
    private syncStateRepository: SyncStateRepository,
    private outbox: Outbox,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.listenToWindowOfflineEvents();
    this.listenToDatabase();
  }

  private listenToWindowOfflineEvents() {
    window.addEventListener("online", () => {
      action("window_listener_reported_online", () => {
        d1MainThreadClasses.syncService.pushDelayed();
        this.isOnline = true;
      })();
    });
    window.addEventListener("offline", () => {
      action("window_listener_reported_offline", () => {
        this.isOnline = false;
      })();
    });
  }

  private listenToDatabase() {
    this.syncStateRepository.subscribeToSyncStatus((newState) => {
      action("new_sync_state_from_db", () => {
        this.state = newState || syncStates.IDLE;
      })();
    });
    this.syncStateRepository.subscribeToStatSyncStatus((newState) => {
      action("new_stat_sync_state_from_db", () => {
        this.statSyncStatus = newState || syncStates.IDLE;
      })();
    });
    this.outbox.subscribeToSize((size) => {
      action("new_outbox_size_from_db", () => {
        this.outboxSize = size;
      })();
    });
  }
}
