import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { userSettingsViewState } from "@/view_state/ViewStates";

export const Hashtags: React.FC = observer(() => {
  const createHashtags = userSettingsViewState.createTagsFromHashtags;
  return (
    <ToggleSetting
      label={__("Create Tags from Hashtags")}
      checked={createHashtags}
      onChange={() => {
        userSettingsViewState.saveSettings({
          create_tags_from_hashtags: !createHashtags,
        });
      }}
    />
  );
});

Hashtags.displayName = "Hashtags";
