import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { SharedJournalSettings } from "@/components/JournalSettings/SharedJournalSettings";
import { JournalDBRow } from "@/data/db/migrations/journal";

type Props = {
  journal: JournalDBRow | null;
  handleClose: () => void;
};

export const SharedJournalSettingsModal: React.FC<Props> = observer(
  ({ journal, handleClose }) => {
    const { __ } = useI18n();

    if (!journal || !journal.is_shared) {
      return null;
    }

    return (
      <D1Modal
        title={__("Members")}
        onRequestClose={handleClose}
        sx={{ width: 420 }}
      >
        <SharedJournalSettings journalId={journal.id} />
      </D1Modal>
    );
  },
);
SharedJournalSettingsModal.displayName = "SharedJournalSettingsModal";
