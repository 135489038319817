import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { moreHorizontal } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Dropdown } from "@/components/D1Dropdown";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { iconButtonHoverBackground } from "@/styles/mixins";
import { SharedJournalSettings } from "@/view_state/PrimaryViewState";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = {
  participant: { id: string; name: string };
  participantIsTransferee: boolean;
  sharedJournalSettings: SharedJournalSettings;
  setIsEdit: (isEdit: boolean) => void;
  onClose: () => void;
  setError: (error: string) => void;
};

export const ParticipantMenu: React.FC<Props> = ({
  participant,
  participantIsTransferee,
  sharedJournalSettings,
  setIsEdit,
  onClose,
  setError,
}) => {
  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showConfirmRevokeAccess, setShowConfirmRevokeAccess] = useState(false);
  const { __, _x } = useI18n();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const currentUser = primaryViewState.user;

  const {
    journal,
    userIsOwner,
    canBeTransferred,
    isPendingTransfer,
    getTransferConfirmationMessage,
  } = sharedJournalSettings;

  const lookingAtSelf = currentUser?.id === participant.id;
  const showTransferButton =
    !lookingAtSelf && canBeTransferred && !isPendingTransfer;
  const showCancelTransferButton = isPendingTransfer && participantIsTransferee;
  const showRevokeAccessButton = userIsOwner && !lookingAtSelf;
  const showEditProfileButton = lookingAtSelf;
  const noOptionsAvailable =
    !showCancelTransferButton &&
    !showTransferButton &&
    !showRevokeAccessButton &&
    !showEditProfileButton;

  useEffect(() => {
    async function _getTransferConfirmationMessage() {
      const message = await getTransferConfirmationMessage(participant.id);
      setConfirmationMessage(message);
    }
    _getTransferConfirmationMessage();
  }, [participant.id]);

  const handleInitiateTransfer = () => {
    if (journal && participant) {
      d1Classes.journalStore
        .initiateSharedJournalTransfer(journal, participant.id)
        .catch((error) => {
          setError(
            __(
              "There was an error initiating the journal transfer. Please try again later. ",
            ) + error,
          );
        });
    }
    setShowConfirmTransfer(false);
  };

  const handleCancelTransfer = async () => {
    if (sharedJournalSettings.journal) {
      d1Classes.journalStore
        .cancelSharedJournalTransfer(sharedJournalSettings.journal)
        .catch((error) => {
          setError(
            __(
              "There was an error canceling the journal transfer. Please try again later. ",
            ) + error,
          );
        });
    }
    setShowConfirmCancel(false);
  };

  const handleRevokeAccess = () => {
    if (journal && participant.id) {
      d1Classes.journalStore
        .revokeAccessToSharedJournal(journal.id, participant.id)
        .catch((error) => {
          setError(
            __(
              "There was an error revoking access to this journal. Please try later. ",
            ) + error,
          );
        });
      setShowConfirmRevokeAccess(false);
      onClose();
    }
  };

  if (noOptionsAvailable) return null;

  return (
    <>
      <D1Dropdown
        renderToggle={({ isOpen, onToggle }) => (
          <Button
            onClick={() => {
              onToggle();
              analytics.tracks.recordEvent(EVENT.buttonTap, {
                button_identifier: "participantProfile_optionsMenuButton",
              });
            }}
            label={__("Participant Options Menu")}
            icon={moreHorizontal}
            sx={{
              mt: 3,
              ml: 3,
              color: "textPrimary",
              ...iconButtonHoverBackground,
            }}
            aria-expanded={isOpen}
          />
        )}
        sx={{
          "&.components-dropdown": {
            position: "absolute",
            top: 0,
            left: 0,
          },
        }}
        renderContent={({ onToggle }) => (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "editorTextMuted",
              fontSize: 1,
              backgroundColor: "surface_light1_dark4",
              minWidth: "160px",
            }}
          >
            <div sx={{ padding: 2.5, "& button": { mt: 1 } }}>
              {showEditProfileButton && (
                <MenuButton
                  onClick={() => {
                    onToggle();
                    setIsEdit(true);
                  }}
                >
                  {__("Edit Profile")}
                </MenuButton>
              )}
              {showCancelTransferButton && (
                <MenuButton
                  onClick={() => {
                    onToggle();
                    setShowConfirmCancel(true);
                  }}
                >
                  {__("Cancel Transfer")}
                </MenuButton>
              )}
              {showTransferButton && (
                <MenuButton
                  onClick={() => {
                    onToggle();
                    setShowConfirmTransfer(true);
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "participantProfile_transferOwnership",
                    });
                  }}
                >
                  {__("Transfer Journal")}
                </MenuButton>
              )}

              {showRevokeAccessButton && (
                <MenuButton
                  isDestructive
                  onClick={() => {
                    onToggle();
                    setShowConfirmRevokeAccess(true);
                  }}
                >
                  {__("Revoke Access")}
                </MenuButton>
              )}
            </div>
          </div>
        )}
      />
      {showConfirmTransfer && (
        <ConfirmDialog
          handleClose={() => setShowConfirmTransfer(false)}
          title={sprintf(
            _x(
              "Transfer Ownership",
              "Title to confirm transfer ownership of shared journal to another user, include journal name",
            ),
            sharedJournalSettings.journal?.name,
          )}
          message={sprintf(
            __(confirmationMessage),
            sharedJournalSettings.journal?.name,
            participant?.name,
          )}
          actionLabel={__("Transfer Shared Journal")}
          handleAction={handleInitiateTransfer}
          isDestructive={true}
        />
      )}
      {showConfirmCancel && (
        <ConfirmDialog
          handleClose={() => setShowConfirmCancel(false)}
          title={sprintf(
            _x(
              "Cancel Ownership Transfer",
              "Title to confirm the user wants to cancel ownership transfer of a shared journal to another user.",
            ),
            sharedJournalSettings.journal?.name,
          )}
          message={sprintf(
            __(
              "Are you sure you want to cancel the pending ownership transfer of this journal?",
            ),
            sharedJournalSettings.journal?.name,
            participant?.name,
          )}
          actionLabel={__("Cancel Ownership Transfer")}
          handleAction={handleCancelTransfer}
          isDestructive={true}
        />
      )}
      {showConfirmRevokeAccess && (
        <ConfirmDialog
          handleClose={() => setShowConfirmRevokeAccess(false)}
          title={sprintf(
            _x(
              "Revoke acess",
              "Title to revoke access to a shared journal for a user",
            ),
            sharedJournalSettings.journal?.name,
          )}
          message={sprintf(
            __("Are you sure you want to remove access to %s from %s?"),
            sharedJournalSettings.journal?.name,
            participant?.name,
          )}
          actionLabel={__("Revoke Access")}
          handleAction={handleRevokeAccess}
          isDestructive={true}
        />
      )}
    </>
  );
};
