import { Button, Notice } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { InviteLink } from "@/data/repositories/V6API";
import { primaryViewState } from "@/view_state/ViewStates";
import { modalRouterViewState } from "@/view_state/ViewStates";

type Props = {
  journal: JournalDBRow | null;
  handleClose: () => void;
};

const localLinks = true;

export const SharedJournalInviteLinkModal: React.FC<Props> = observer(
  ({ journal, handleClose }) => {
    const { __ } = useI18n();

    if (!journal || !journal.is_shared) {
      return null;
    }

    const journalStore = d1Classes.journalStore;
    const { showPremiumUpgradeModal } = modalRouterViewState;

    const [inviteLink, setInviteLink] = useState<InviteLink | null>(null);
    const [generateLinkError, setGenerateLinkError] = useState(false);

    const generateLink = async () => {
      try {
        const inviteLink =
          await journalStore.generateSharedJournalLink(journal);

        if (!inviteLink) return;
        if (localLinks) {
          // make localhost links work
          inviteLink.link = inviteLink.link.replace(
            /.*dayone.me/,
            // current URL
            `${window.location.protocol}//${window.location.host}`,
          );
        }
        setInviteLink(inviteLink);
      } catch (e) {
        setGenerateLinkError(true);
        console.error(e);
      }
    };

    const createInvite = async () => {
      const userCanInviteParticipants =
        primaryViewState.userCanInviteParticipants(journal);
      if (userCanInviteParticipants) {
        generateLink();
      } else {
        showPremiumUpgradeModal("shared_journal_invite");
      }
    };

    useEffect(() => {
      createInvite();
    }, [journal.id]);

    const [justCopied, setCopiedLink] = useState<boolean>(false);
    const copyLink = (link: string) => {
      analytics.tracks.recordEvent(EVENT.buttonTap, {
        button_identifier: "sharedJournal_addMembersView_copy",
      });
      navigator.clipboard.writeText(link);
      setCopiedLink(true);
      setTimeout(() => {
        setCopiedLink(false);
      }, 2000);
    };

    // make the modal less jump while it's trying to get the invite link
    if (!inviteLink && !generateLinkError) {
      return null;
    }

    return (
      <D1Modal
        title={__("Add Members")}
        onRequestClose={handleClose}
        sx={{ width: 390 }}
      >
        {generateLinkError && (
          <Notice status="error" isDismissible={false} sx={{ my: 2 }}>
            {__("Error generating invite link")}
          </Notice>
        )}
        {inviteLink && (
          <div>
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderColor: "borderPrimary",
                borderWidth: 1,
                borderRadius: 3,
                overflow: "hidden",
              }}
            >
              <input
                readOnly
                sx={{
                  fontSize: 0,
                  m: 1,
                  mr: 0,
                  color: "textPrimary",
                  backgroundColor: "transparent",
                  flexGrow: 1,
                  p: 2.5,
                  borderRadius: 2,
                }}
                value={inviteLink.link}
                onSelect={(e) => {
                  e.currentTarget.select();
                }}
              ></input>
              <Button
                variant="tertiary"
                sx={{
                  fontSize: 0,
                  m: 1,
                  "&&": {
                    color: "primary",
                  },
                }}
                onClick={() => copyLink(inviteLink.link)}
              >
                {justCopied ? "Copied!" : "Copy"}
              </Button>
            </div>

            <div
              sx={{
                mt: 1,
                fontSize: 0,
                color: "textSecondary",
                lineHeight: "body",
                p: 2,
              }}
            >
              <p>
                {__(
                  "Share this invite link so they can request to join. You can approve requests for up to 30 members. Link expires in 14 days.",
                )}
              </p>
            </div>
          </div>
        )}
      </D1Modal>
    );
  },
);
