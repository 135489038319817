import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";

import { EntryListItem } from "@/components/Search/EntryListItem";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { primaryViewState } from "@/view_state/ViewStates";
interface Props {
  globalEntryId: GlobalEntryID;
  height: number;
  onClick: () => void;
  baseURL: string;
}
export const ClickableEntryListItem: React.FC<Props> = observer(
  ({ globalEntryId, height, onClick, baseURL }) => {
    const {
      revealEntry,
      revealedEntriesInclude,
      setRevealedEntries,
      revealedEntries,
      getJournalById,
    } = primaryViewState;

    const originalRevealedEntries = useRef(revealedEntries);

    // we cannot use primaryViewState.isEntryRevealed because
    // it does not take into account the globalEntryId's journal conceal value
    const isEntryRevealed = useCallback(
      (globalEntryId: GlobalEntryID) => {
        const journal = getJournalById(globalEntryId.journal_id);
        return journal?.conceal !== 1 || revealedEntriesInclude(globalEntryId);
      },
      [getJournalById, revealedEntriesInclude],
    );

    const shouldBeLink = isEntryRevealed(globalEntryId);
    const handleClick = (event: React.MouseEvent) => {
      event.preventDefault();
      if (shouldBeLink) {
        onClick();
      } else {
        revealEntry(globalEntryId);
      }
    };

    useEffect(() => {
      return () => {
        setRevealedEntries(originalRevealedEntries.current);
      };
    }, []);
    return (
      <div
        id={`search-item-${globalEntryId.journal_id}_${globalEntryId.id}`}
        onClick={handleClick}
        sx={{
          "& a": {
            display: "flex",
            alignItems: "center",
            mb: 1,
            height: `${height}px`,
            borderRadius: "md",
            pl: 3,
            pr: 2,
            "&:hover": {
              bg: "surfaceHover",
              "&& .search-entry-media-list:after": {
                background: (theme) =>
                  `linear-gradient(270deg, ${theme?.colors?.surface_light2_dark4} 70%, rgba(255, 255, 255, 0.00) 100%)`,
              },
            },

            "&:focus": {
              borderColor: "var(--theme-ui-colors-primary)",
            },
            "&:focus-visible": {
              outline: 0,
            },
          },
          "&& .search-entry-media-list:after": {
            background: (theme) =>
              `linear-gradient(270deg, ${theme?.colors?.surface_light1_dark3} 70%, rgba(255, 255, 255, 0.00) 100%)`,
          },
          "& :has(.concealed)": {
            display: "flex",
            alignItems: "center",
            height: `${height}px`,
            pt: 1,
            mb: 1,
          },
          "& span svg": {
            boxShadow: (theme) =>
              `0 0 0 2px transparent,0 0 0 2px ${theme?.colors?.surface_light1_dark3}`,
          },
          "&:hover #reaction-summary svg, & a:hover #reaction-summary svg": {
            boxShadow: (theme) =>
              `0 0 0 2px ${theme?.colors?.surfaceHover},0 0 0 2px ${theme?.colors?.surface_light1_dark3}`,
          },
        }}
      >
        <EntryListItem
          globalEntryID={globalEntryId}
          height={height}
          baseURL={baseURL}
          link={shouldBeLink}
          isEntryRevealed={isEntryRevealed}
        />
      </div>
    );
  },
);
