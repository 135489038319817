import { observer } from "mobx-react-lite";

type Props = {
  tags: string[] | null;
  color?: string | null;
};

export const EntryTags: React.FC<Props> = observer(({ tags, color }) => {
  if (!tags || tags?.length === 0) {
    return null;
  }
  const tagsString = tags.slice(0, 3).join(", ");
  return (
    <span
      sx={{
        fontSize: 0,
        color: color,
      }}
    >
      {tagsString}
    </span>
  );
});
