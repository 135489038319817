import { Button } from "@wordpress/components";

import { modalRouterViewState } from "@/view_state/ViewStates";

export const BackupHintButton: React.FC<{
  label: string;
  isCreatingSharedJournal: boolean;
}> = ({ label, isCreatingSharedJournal }) => (
  <Button
    variant="link"
    onClick={() =>
      modalRouterViewState.showKeyModal({
        openedFrom: "enter_key_modal",
        denyRetrieve: false,
        isCreatingSharedJournal,
      })
    }
  >
    {label}
  </Button>
);
