import { Weather } from "@/data/db/migrations/entry";

const fahrenheitFromCelsius = (temperatureInCelsius: number) => {
  return temperatureInCelsius * (9 / 5) + 32;
};

export type TemperatureUnit = "C" | "F";

export const getDefaultTemparatureUnit = (): TemperatureUnit => {
  if (navigator && navigator.language) {
    if (navigator.language.includes("US")) {
      return "F";
    }
  }
  return "C";
};

export const displayTemperature = (
  temperatureInCelsius: number,
  unit: TemperatureUnit,
) => {
  const temperature =
    unit === "C"
      ? temperatureInCelsius
      : fahrenheitFromCelsius(temperatureInCelsius);
  return `${Math.round(temperature)}°${unit}`;
};

export const getTemperatureString = (
  weather: Weather,
  temperatureUnit: TemperatureUnit,
) => {
  return weather?.tempCelsius
    ? `${displayTemperature(weather.tempCelsius, temperatureUnit)} ${
        weather.description || ""
      }`
    : "";
};
