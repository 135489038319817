import { register as registerBold } from "@/components/Editor/formats/bold";
import { register as registerCode } from "@/components/Editor/formats/code";
import { register as registerHashtag } from "@/components/Editor/formats/hashtags";
import { register as registerHighlight } from "@/components/Editor/formats/highlight";
import { register as registerItalic } from "@/components/Editor/formats/italic";
import { register as registerLink } from "@/components/Editor/formats/link";
import { register as registerStrikethrough } from "@/components/Editor/formats/strikethrough";

export const registerFormatTypes = (createTagsFromHashtags: boolean) => {
  // non-alphabetized function list: this is the order they will appear in the menu
  [
    registerBold,
    registerItalic,
    registerHighlight,
    registerStrikethrough,
    registerCode,
    registerHashtag(createTagsFromHashtags),
    registerLink,
  ].forEach((register) => register());
};
