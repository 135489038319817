import { differenceInDays } from "date-fns";

import { SyncStateRepository } from "./SyncStateRepository";

import { Sentry } from "@/Sentry";
import { MSApiClient } from "@/data/MSApiClient";
import { DODexie } from "@/data/db/dexie_db";
import {
  TemplateGalleryCategory,
  TemplateGalleryDBRow,
} from "@/data/db/migrations/template";

export class TemplateGalleryRepository {
  isSynchronizing: boolean;

  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
    private msApiClient: MSApiClient,
  ) {
    this.isSynchronizing = false;
  }

  async synchronize() {
    // Download the template gallery at most once per day
    const cursor = await this.syncStateRepository.getTemplateGalleryCursor();
    const now = new Date();
    if (differenceInDays(now, new Date(cursor)) < 1) {
      return;
    }
    const json = await this.msApiClient.getTemplateGalleryData.query();
    if (!json) {
      Sentry.captureException(
        new Error(`Error fetching templates from gallery`),
      );
      return;
    }
    this.syncStateRepository.setTemplateGalleryCursor(now.toISOString());
    const categories = (json.categories as TemplateGalleryCategory[]).map(
      (c, i) => {
        return {
          ...c,
          order: i,
        };
      },
    );

    const templatesFromAPI = json.templates as TemplateGalleryDBRow[];
    const templatePromises = await Promise.allSettled(
      templatesFromAPI.map(async (t, i) => {
        const image = await this.msApiClient.getTemplateGalleryImage.query({
          // We replace the domain from the URL so we can proxy them from the miniserver
          imageName: t.image.replace(
            "https://publish.dayone.app/templates/images/",
            "",
          ),
        });
        return {
          ...t,
          image: image || "",
          order: i,
        } as TemplateGalleryDBRow;
      }),
    );

    const templates = templatePromises.reduce(
      (
        acc: TemplateGalleryDBRow[],
        p: PromiseSettledResult<TemplateGalleryDBRow>,
      ) => {
        if (p.status === "fulfilled") {
          acc.push(p.value);
        }
        return acc;
      },
      [],
    );

    await this.db.template_gallery_categories.clear();
    await this.db.template_gallery_items.clear();
    await this.db.template_gallery_categories.bulkPut(categories);
    await this.db.template_gallery_items.bulkPut(templates);
  }

  async getFirstTemplate() {
    const firstCategory = (await this.getCategories())[0];
    if (!firstCategory) {
      return null;
    }
    const firstTemplate =
      (await this.getTemplateById(firstCategory.templates[0])) || null;
    return firstTemplate;
  }

  async getCategories() {
    return (await this.db.template_gallery_categories.toArray()).sort(
      (a, b) => {
        return a.order - b.order;
      },
    );
  }

  getTemplateById(templateId: string) {
    return this.db.template_gallery_items.get(templateId);
  }

  async getTemplatesByCategory(categoryId: string) {
    const category = await this.db.template_gallery_categories.get(categoryId);
    if (!category) {
      return [];
    }
    return this.db.template_gallery_items
      .where("id")
      .anyOf(category.templates)
      .sortBy("order");
  }
}
