export const FilledArrow: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
    >
      <path d="M0 0H6L3 4L0 0Z" fill="currentColor" />
    </svg>
  );
};
