import { makeAutoObservable } from "mobx";

import { UserSettingsViewState } from "@/view_state/UserSettingsViewState";

export class ThemeViewState {
  constructor(private userSettingsViewState: UserSettingsViewState) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get selectedTheme() {
    return this.userSettingsViewState.settings?.theme ?? "system";
  }

  selectTheme = (theme: string) => {
    this.userSettingsViewState.saveSettings({ theme: theme as any });
  };
}
