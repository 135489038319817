export const AnsweredCheck = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C5.61554 0 4.26216 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532847 4.32122C0.00303299 5.6003 -0.13559 7.00777 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9498C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.39971 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73785 14 8.38447 14 7C13.9979 5.14414 13.2597 3.36491 11.9474 2.05261C10.6351 0.740322 8.85586 0.00213768 7 0ZM11.151 4.68731L5.7987 10.0719C5.69826 10.173 5.56184 10.2302 5.41932 10.2309C5.2768 10.2316 5.13982 10.1758 5.03839 10.0757L2.85277 7.92185C2.7601 7.82006 2.70992 7.68665 2.71256 7.54902C2.7152 7.41139 2.77044 7.27999 2.86695 7.18183C2.96346 7.08367 3.09389 7.0262 3.23146 7.02122C3.36903 7.01625 3.50327 7.06415 3.60662 7.15508L5.41047 8.932L10.3875 3.93077C10.437 3.87922 10.4963 3.83805 10.5619 3.80966C10.6275 3.78127 10.6981 3.76623 10.7696 3.76541C10.8411 3.7646 10.912 3.77802 10.9783 3.80491C11.0445 3.8318 11.1047 3.87161 11.1554 3.92201C11.2061 3.97242 11.2463 4.03242 11.2735 4.0985C11.3008 4.16459 11.3146 4.23544 11.3142 4.30693C11.3138 4.37842 11.2991 4.44911 11.2711 4.51488C11.2431 4.58065 11.2023 4.64018 11.151 4.69V4.68731Z"
        fill="currentColor"
      />
    </svg>
  );
};
