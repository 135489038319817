import { __ } from "@wordpress/i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";

export const UsageStatistics: React.FC = () => {
  const [usageStatisticsEnabled, setUsageStatisticsEnabled] = useState(true);
  d1Classes.usageStatisticsStore.subscribe((value) => {
    setUsageStatisticsEnabled(value);
  });

  return (
    <ToggleSetting
      label={__("Usage Statistics")}
      checked={usageStatisticsEnabled}
      onChange={() => {
        d1Classes.usageStatisticsStore.set(!usageStatisticsEnabled);
      }}
    />
  );
};
