export const decryptBufferAndIVSymmetric = async (
  iv: BufferSource,
  key: CryptoKey,
  data: BufferSource,
) => {
  const algo = {
    name: "AES-GCM",
    iv: iv,
  };
  return new Uint8Array(await crypto.subtle.decrypt(algo, key, data));
};
