export const Stationary: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14H6V11.5C6 10.672 5.328 10 4.5 10H3.5C2.672 10 2 10.672 2 11.5V16C2 17.105 2.895 18 4 18H20C21.105 18 22 17.105 22 16V11.5C22 10.672 21.328 10 20.5 10H19.5C18.672 10 18 10.672 18 11.5V14Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 18V20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 18V20"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 10V8C4 6.343 5.343 5 7 5H17C18.657 5 20 6.343 20 8V10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
