import { toHex } from "@/crypto/utils";
import { FeedRecord } from "@/data/repositories/V2API";
import { mergeUintArrays } from "@/data/utils/buffers";
import { makeDebugLogger } from "@/utils/debugLog";

const debugLog = makeDebugLogger("SyncFeedParser", false);

// This is a very close copy of:
// https://github.com/bloom/DayOne-Apple/blob/fab1715c915ab709a6875d8053b94e932f69752f/core/DOCore/DOCore/Sync/DOSyncFeedPullOperation.swift#L247

export type FeedRecordWithData = {
  record: FeedRecord;
  // This may be a D1 if the entry is encrypted, or a utf-8 string if it's not.
  // Will be null if the revision says it's a delete.
  data: Uint8Array | string | null;
};

export async function* parseFeed(
  entryStream: ReadableStream<Uint8Array>,
  ignoreDeletes = false,
  debugInfo: Record<string, any> = {},
): AsyncGenerator<FeedRecordWithData> {
  debugLog("Parse feed called");
  let buffer = new Uint8Array();
  let recordAwaitingData: FeedRecord | null = null;

  function addData(data: Uint8Array): FeedRecordWithData[] {
    debugLog("New data added to the bucket");
    buffer = mergeUintArrays([buffer, data]);
    if (shouldFlushBuffer()) {
      return flushBuffer();
    } else {
      return [];
    }
  }

  function finish(): FeedRecordWithData[] {
    debugLog("Finishing the stream");
    return flushBuffer();
  }

  function shouldFlushBuffer(): boolean {
    const shouldFlush = buffer.length > 100_000;
    debugLog("Should flush the buffer?", shouldFlush);
    return shouldFlush;
  }

  // Mutates the buffer, consuming bytes and optionally returns parts
  // that were extracted
  function eatBufferBytes(n: number | null): Uint8Array | null {
    if (n == null) {
      n = buffer.length;
    }
    if (buffer.length < n) return null;
    const result = buffer.slice(0, n);
    buffer = buffer.slice(n);
    return result;
  }

  function eatBufferLine(): Uint8Array | null {
    // Find a newline character
    // The linefeed character is 10 in utf-8, AKA "\n"
    const newlineChar = 10;
    const index = buffer.indexOf(newlineChar);
    if (index == -1) {
      return null;
    }
    // Get the line up to the newline character
    const lineData = eatBufferBytes(index);
    if (lineData == null) return null;
    // Eat the newline character and throw it away
    eatBufferBytes(1);
    return lineData;
  }

  // Tries to read entry data from the feed after a record.
  // It it succeeds, it consumes that data from the buffer.
  function readPayloadForRecord(
    record: FeedRecord,
  ): Uint8Array | string | null {
    debugLog("Reading payload for a record");
    const contentLength = record.contentLength;
    if (contentLength == null && !record.outcome) return null;
    const payload = eatBufferBytes(contentLength || null);
    if (payload == null) return null;
    // 123 is the "}" character in utf-8
    if (payload[0] == 123) {
      debugLog("Found plaintext payload");
      // Looks like we've got plain text JSON
      return new TextDecoder("utf-8").decode(payload);
    }
    // 68 and 49 are the "D" and "1" characters in utf-8
    // The header for an encrypted entry.
    else if (payload[0] == 68 && payload[1] == 49) {
      debugLog("Found encrypted payload");
      // Looks like we've got encrypted data
      return payload;
    } else {
      const debugPart = payload.slice(0, 15);
      throw new Error(
        `Unknown bytes following feed record: ${debugPart} (as hex: ${toHex(
          debugPart,
        )})`,
      );
    }
  }

  function flushBuffer(): FeedRecordWithData[] {
    debugLog("Flushing buffer");
    const records: FeedRecordWithData[] = [];
    let consumedData = false;
    do {
      consumedData = false;
      if (buffer.length == 0) {
        debugLog("Empty buffer, breaking");
        break;
      }
      if (recordAwaitingData) {
        debugLog("Found a record awaiting data");
        const record = recordAwaitingData;
        // Try to read the entry data from the feed, if we can't then we'll have to wait for more data
        const data = readPayloadForRecord(record);
        if (!data) {
          // We don't have enough data to read the payload yet
          // Keep waiting for more data to come in.
          break;
        }
        consumedData = true;
        recordAwaitingData = null;
        debugLog("Completed record fetch");
        records.push({ record, data });
      } else {
        debugLog(
          "Did not find a record awaiting data. Looking for new record",
          new Date().toISOString(),
        );
        const lineData = eatBufferLine();
        if (lineData == null) {
          debugLog(
            "Found no line data. Looping again",
            new Date().toISOString(),
          );
          continue;
        }
        consumedData = true;
        if (lineData.length == 0) {
          debugLog(
            "Found an empty line. Looping again",
            new Date().toISOString(),
          );
          // This is an empty line, let's skip it
          continue;
        }
        try {
          debugLog("Trying to parse a feed record");
          const record: FeedRecord = JSON.parse(
            new TextDecoder("utf-8").decode(lineData),
          );
          if (record.contentLength || record.outcome) {
            debugLog("Founda record, looping again");
            recordAwaitingData = record;
            continue;
          }

          // If the caller specifies, ignore deletes from the feed
          // In the future when we support the trash can feature we'll
          // want to only ignore deletes if they're also purged.
          if (ignoreDeletes && record.revision.type == "delete") {
            debugLog(
              "Ignoring deletes, and we found a delete. Looping!",
              new Date().toISOString(),
            );
            continue;
          }
          debugLog(
            "Found a record with no data. Looping",
            new Date().toISOString(),
          );
          records.push({ record, data: null });
        } catch (err) {
          throw new Error(
            `Failed while parsing journal feed, turning feed record into JSON. ${JSON.stringify(
              debugInfo,
            )}`,
          );
        }
      }
      debugLog("Reached end of loop", {
        consumedData,
      });
    } while (consumedData);
    debugLog(`Done flushing buffer, returning ${records.length} records`);
    return records;
  }

  const reader = entryStream.getReader();
  while (true) {
    const { value: chunk, done } = await reader.read();
    if (chunk) {
      const records = addData(chunk);
      for (const record of records) {
        yield record;
      }
    }
    if (done) {
      const records = finish();
      for (const record of records) {
        yield record;
      }
      break;
    }
  }
}
