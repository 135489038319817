import { store as blockEditorStore } from "@wordpress/block-editor";
import { useRefEffect } from "@wordpress/compose";
import { useSelect, useDispatch } from "@wordpress/data";

import { CHECKLIST_ITEM_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { TAB, SPACE } from "@/components/Editor/utils/keycodes";

export default function useSpace(clientId: string) {
  const {
    getSelectionStart,
    getSelectionEnd,
    getBlockIndex,
    getBlock,
    getPreviousBlockClientId,
  } = useSelect(blockEditorStore, []);
  const { updateBlockAttributes } = useDispatch(blockEditorStore);

  return useRefEffect(
    (element) => {
      function onKeyDown(event: KeyboardEvent) {
        const { key, shiftKey, altKey, metaKey, ctrlKey } = event;

        if (
          event.defaultPrevented ||
          (key !== SPACE && key !== TAB) ||
          // Only override when no modifiers are pressed.
          altKey ||
          metaKey ||
          ctrlKey
        ) {
          return;
        }

        const selectionStart = getSelectionStart();
        const selectionEnd = getSelectionEnd();
        if (selectionStart.offset === 0 && selectionEnd.offset === 0) {
          event.preventDefault();
          const previousSiblingId = getPreviousBlockClientId(clientId);
          const prevBlock = getBlock(previousSiblingId);

          if (!prevBlock || prevBlock.name !== CHECKLIST_ITEM_BLOCK_ID) {
            return true;
          }
          const prevBlockIndent = prevBlock.attributes.indent;
          const currentBlock = getBlock(clientId);

          if (shiftKey) {
            // Note that backspace behaviour in defined in onMerge.
            if (key === TAB) {
              const newIndent = Math.max(
                (currentBlock.attributes.indent ?? 1) - 1,
                1,
              );
              updateBlockAttributes([clientId], { indent: newIndent });
              return true;
            }
          } else if (getBlockIndex(clientId) !== 0) {
            const newIndent = Math.min(
              (currentBlock.attributes.indent ?? 1) + 1,
              prevBlockIndent + 1,
            );
            updateBlockAttributes([clientId], { indent: newIndent });
            return true;
          }
        }
      }

      element.addEventListener("keydown", onKeyDown as EventListener);
      return () => {
        element.removeEventListener("keydown", onKeyDown as EventListener);
      };
    },
    [clientId],
  );
}
