export const SharedJournals = () => {
  return (
    <svg
      width="138"
      height="60"
      viewBox="0 0 138 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117 24C117 29.799 112.971 34.5 108 34.5C103.029 34.5 99 29.799 99 24C99 18.201 103.029 13.5 108 13.5C112.971 13.5 117 18.201 117 24Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78 30C78 13.4315 91.4315 0 108 0C124.569 0 138 13.4315 138 30C138 46.5685 124.569 60 108 60C91.4315 60 78 46.5685 78 30ZM108 6C94.7452 6 84 16.7452 84 30C84 35.2458 85.683 40.0985 88.5385 44.0476C91.6612 40.2075 99.1982 37.5 108 37.5C116.802 37.5 124.339 40.2075 127.461 44.0476C130.317 40.0985 132 35.2458 132 30C132 16.7452 121.255 6 108 6Z"
        fill="currentColor"
      />
      <g opacity="0.5">
        <path
          d="M86.0787 5.33261C81.2316 1.97034 75.3459 0 69 0C52.4315 0 39 13.4315 39 30C39 46.5685 52.4315 60 69 60C75.3459 60 81.2316 58.0297 86.0787 54.6674C81.2173 50.344 77.6425 44.6053 76.0008 38.0976C73.8111 37.7106 71.4548 37.5 69 37.5C60.1982 37.5 52.6612 40.2075 49.5385 44.0476C46.683 40.0985 45 35.2458 45 30C45 16.7452 55.7452 6 69 6C73.7583 6 78.1932 7.38473 81.9236 9.77321C83.1703 8.16831 84.5619 6.68156 86.0787 5.33261Z"
          fill="currentColor"
        />
        <path
          d="M76.8943 18.9534C75.3644 15.7031 72.4028 13.5 69 13.5C64.0294 13.5 60 18.201 60 24C60 29.799 64.0294 34.5 69 34.5C71.3278 34.5 73.4492 33.4689 75.047 31.7769C75.0158 31.1886 75 30.5961 75 30C75 26.1256 75.6677 22.4072 76.8943 18.9534Z"
          fill="currentColor"
        />
      </g>
      <g opacity="0.25">
        <path
          d="M47.0787 5.33261C42.2316 1.97034 36.3459 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C36.3459 60 42.2316 58.0297 47.0787 54.6674C42.2173 50.344 38.6425 44.6053 37.0008 38.0976C34.8111 37.7106 32.4548 37.5 30 37.5C21.1982 37.5 13.6612 40.2075 10.5385 44.0476C7.68302 40.0985 6 35.2458 6 30C6 16.7452 16.7452 6 30 6C34.7583 6 39.1932 7.38473 42.9236 9.77321C44.1703 8.16831 45.5619 6.68156 47.0787 5.33261Z"
          fill="currentColor"
        />
        <path
          d="M37.8943 18.9534C36.3644 15.7031 33.4028 13.5 30 13.5C25.0294 13.5 21 18.201 21 24C21 29.799 25.0294 34.5 30 34.5C32.3278 34.5 34.4492 33.4689 36.047 31.7769C36.0158 31.1886 36 30.5961 36 30C36 26.1256 36.6677 22.4072 37.8943 18.9534Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
