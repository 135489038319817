import { useCallback } from "react";

import {
  DEFAULT_EDITOR_ID,
  ValidEditorID,
} from "@/components/Editor/utils/editor-constants";

export function useFocusEditor(editorId: ValidEditorID = DEFAULT_EDITOR_ID) {
  const focusEditor = useCallback(() => {
    // BAND-AID ALERT: without a short timeout, the element does not yet exist in the DOM
    // @todo we should instead use state on the Entry or Editor component
    // and ask them to focus themselves when the entry is brand-new
    setTimeout(() => {
      document.getElementById(editorId + "-clickzone")?.click();
    }, 500);
  }, [editorId]);

  return focusEditor;
}
