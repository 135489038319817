import { Flex, Icon } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { chevronLeft } from "@wordpress/icons";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import { ItemButton } from "@/components/ItemGroup";
import { JournalSettingsAdvancedView } from "@/components/JournalSettings/JournalSettingsAdvancedView";
import { JournalSettingsModalHeader } from "@/components/JournalSettings/JournalSettingsModalHeader";
import { JournalSettingsStartView } from "@/components/JournalSettings/JournalSettingsStartView";
import { Button } from "@/components/Settings/Button";
import { JournalStore } from "@/data/stores/JournalStore";
import { ModalRouterViewState } from "@/view_state/ModalRouterViewState";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

type Props = {
  handleClose: () => void;
  isShared?: boolean;
  journalStore: JournalStore;
  primaryViewState: PrimaryViewState;
  modalRouterViewState: ModalRouterViewState;
};

export const NewJournal: React.FC<Props> = ({
  handleClose,
  isShared = false,
  journalStore,
  primaryViewState,
  modalRouterViewState,
}) => {
  const canEncrypt = primaryViewState.isEncryptionReady;
  const templates = primaryViewState.templates;
  const initialJournal = {
    ...journalStore.blankJournal,
    name: isShared ? __("Shared Journal") : __("Journal"),
    e2e: canEncrypt ? 1 : 0,
    is_shared: isShared,
    comments_disabled: isShared ? 0 : 1,
  };

  const [journal, setJournal] = useState(initialJournal);
  const [view, setView] = useState("start");
  const [error, setError] = useState<string | null>(null);
  const [creatingJournal, setCreatingJournal] = useState(false);

  const [, setLocation] = useLocation();

  useEffect(() => {
    setJournal((prevJournal) => {
      return { ...prevJournal, e2e: canEncrypt ? 1 : 0 };
    });
  }, [canEncrypt]);

  const closeModal = () => {
    setJournal(initialJournal);
    setView("start");
    setError(null);
    handleClose();
  };

  const toggleCommentsDisabled = (value: number) => {
    setJournal((prevJournal) => {
      return {
        ...prevJournal,
        comments_disabled: value,
      };
    });
  };

  const submitJournal = async () => {
    if (journal.name === "") {
      setError(__("Journal name cannot be blank"));
      return;
    }
    if (!error) {
      try {
        const journalAfterSave = await journalStore.saveJournal(journal);
        if (journalAfterSave) {
          analytics.tracks.recordEvent(EVENT.journalCreate, {
            shared_journal: journalAfterSave.is_shared,
            conceal: !!journalAfterSave.conceal,
            hide_on_this_day: !!journalAfterSave.hide_on_this_day,
            hide_today_view: !!journalAfterSave.hide_today_view,
            hide_streaks: !!journalAfterSave.hide_streaks,
            add_location_to_entries:
              journalAfterSave.add_location_to_new_entries,
          });
          setCreatingJournal(false);
          if (journalAfterSave.is_shared) {
            setJournal(journalAfterSave);
            modalRouterViewState.openSharedJournalSettings(journalAfterSave.id);
          } else {
            closeModal();
          }
          setLocation(`/journals/${journalAfterSave.id}`);
        }
      } catch (e) {
        Sentry.captureException(e);
        setError(__("Could not create journal. Please try again"));
      }
    }
  };

  return (
    <D1Modal
      hideHeader={true}
      title={isShared ? __("New Shared Journal") : __("New Journal")}
      sx={{
        width: ["100%", "100%", "420px"],
        height: ["calc(100dvh - 40px)", "calc(100dvh - 40px)", "600px"],
        maxHeight: "80%",
        "& .components-modal__content": {
          pt: 0,
          "> div:nth-of-type(2)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          },
        },
      }}
      onRequestClose={handleClose}
    >
      {error && <ErrorMessage message={error} />}
      <AnimatePresence initial={false}>
        {view === "start" && (
          <>
            <JournalSettingsStartView
              journal={journal}
              setView={setView}
              setJournal={setJournal}
              primaryViewState={primaryViewState}
              isEditing={false}
              direction="left"
            />
            <JournalSettingsModalHeader>
              <span>
                {journal.is_shared
                  ? __("New Shared Journal")
                  : __("New Journal")}
              </span>
            </JournalSettingsModalHeader>
          </>
        )}
        {view === "advanced" && (
          <>
            <JournalSettingsAdvancedView
              journal={journal}
              setView={setView}
              setJournal={setJournal}
              canEncrypt={canEncrypt}
              isShared={isShared}
              templates={templates}
              toggleCommentsDisabled={toggleCommentsDisabled}
              isEditing={false}
              direction="right"
            />
            <JournalSettingsModalHeader>
              <ItemButton
                onClick={() => {
                  setView("start");
                }}
                label={__("Back to journal settings")}
              >
                <Icon icon={chevronLeft} size={24} /> {__("Advanced")}
              </ItemButton>
            </JournalSettingsModalHeader>
          </>
        )}
      </AnimatePresence>
      <Flex
        sx={{
          mt: 4,
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="primary"
          disabled={creatingJournal}
          onClick={() => {
            setCreatingJournal(true);
            submitJournal();
          }}
        >
          {__("Create Journal")}
        </Button>
      </Flex>
    </D1Modal>
  );
};
