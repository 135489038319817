import { TextControl as WpTextControl } from "@wordpress/components";
import { TextControlProps } from "@wordpress/components/build-types/text-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

type CustomTextControlProps = Omit<TextControlProps, "type"> & {
  type?:
    | "date"
    | "email"
    | "number"
    | "password"
    | "tel"
    | "text"
    | "search"
    | "url";
};

export const TextControl: React.FC<
  WordPressComponentProps<CustomTextControlProps, "input", false>
> = (props) => {
  return (
    //@ts-ignore
    <WpTextControl
      sx={{
        "&&& .components-text-control__input": {
          backgroundColor: "transparent",
          color: "textPrimary",
          px: 2.5,
          py: 2,
          borderColor: "borderPrimary",
        },
      }}
      {...props}
    />
  );
};
