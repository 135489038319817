export const Snow: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 16.9901C17.997 16.9901 17.995 16.9921 17.995 16.9951C17.995 16.9981 17.997 17.0001 18 17.0001C18.003 17.0001 18.005 16.9981 18.005 16.9951C18.005 16.9921 18.003 16.9901 18 16.9901"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0781 6.689C16.5161 6.689 15.9861 6.805 15.5231 7.036C15.1761 4.753 13.1911 3 10.8091 3C8.21209 3 6.09509 5.084 6.02909 7.665C4.47409 7.863 3.26709 9.187 3.26709 10.824C3.26609 12.577 4.68909 14 6.44209 14H17.0781C19.0961 14 20.7341 12.362 20.7341 10.344C20.7341 8.326 19.0961 6.689 17.0781 6.689V6.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16.995C11.997 16.995 11.995 16.997 11.995 17C11.995 17.003 11.997 17.005 12 17.005C12.003 17.005 12.005 17.003 12.005 17C12.005 16.997 12.003 16.995 12 16.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16.9901C5.997 16.9901 5.995 16.9921 5.995 16.9951C5.995 16.9981 5.997 17.0001 6 17.0001C6.003 17.0001 6.005 16.9981 6.005 16.9951C6.005 16.9921 6.003 16.9901 6 16.9901"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19.9999C8.997 19.9999 8.995 20.0019 8.995 20.0049C8.995 20.0079 8.997 20.0099 9 20.0099C9.003 20.0099 9.005 20.0079 9.005 20.0049C9.005 20.0019 9.003 19.9999 9 19.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9951 19.995C14.9921 19.995 14.9901 19.997 14.9901 20C14.9901 20.003 14.9921 20.005 14.9951 20.005C14.9981 20.005 15.0001 20.003 15.0001 20C15.0001 19.997 14.9981 19.995 14.9951 19.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
