import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { fromBase64 } from "@/crypto/utils";

/**
 * This decrypts stuff that was encrypted with the user's private key.
 *
 * @param userPrivateKey this is the key that is unlocked with the user's D1-xxxx-xxx-xxx thingy
 * @param d1 the thing as a base64 string that we wish to decrypt (eg: a grant, or any V4 API encrypted blob)
 * @param debuggingMessage just a small message to help with debugging when the decryption fails
 *
 * @returns decrypted data as a Promise<ArrayBuffer>
 */
export const decryptWithUserKey = async (
  userPrivateKey: CryptoKey,
  d1: string,
  debuggingMessage: string,
) => {
  return await Asymmetric.Private.decrypt(
    userPrivateKey,
    fromBase64(d1),
    debuggingMessage,
  );
};
