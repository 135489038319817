import { ColorPalette as WPColorPalette } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { journalColors } from "@/components/JournalSettings/JournalColors";
import { Enabled } from "@/data/repositories/UserAPI";
import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { viewStates } from "@/view_state/ViewStates";

type JournalColorPaletteProps = {
  onChange: (color?: string) => void;
  value?: string;
  label?: string;
  primaryViewState: PrimaryViewState;
};

const getPremiumStar = (shouldShowDarkMode?: boolean) => {
  return shouldShowDarkMode
    ? "url(/assets/dark_theme_star.svg)"
    : "url(/assets/light_theme_star.svg)";
};

export const JournalColorPalette: React.FC<JournalColorPaletteProps> = observer(
  ({
    onChange,
    value = journalColors.freeJournalColors[0].color,
    label,
    primaryViewState,
  }: JournalColorPaletteProps) => {
    const colorPermission = primaryViewState.user?.features?.find(
      (f) => f.name === "colors",
    ) as Enabled;
    const canUsePremiumColors = colorPermission?.enabled || false;

    const { inDarkMode } = useColorMode();
    const premiumStar = getPremiumStar(inDarkMode);
    const onClickPremiumColor = canUsePremiumColors
      ? onChange
      : () => {
          viewStates.modalRouter.showPremiumUpgradeModal("premium_color");
        };
    return (
      <>
        <div
          sx={{
            "&& .components-circular-option-picker__option-wrapper::before": {
              display: "none",
            },
          }}
        >
          {label && <p sx={{ fontSize: 1, mb: 2 }}>{label}</p>}
          <WPColorPalette
            colors={journalColors.freeJournalColors}
            value={value.toUpperCase()}
            onChange={onChange}
            disableCustomColors={true}
            clearable={false}
            sx={{
              mb: 3,
              "& .components-circular-option-picker__option::after": {
                boxShadow: (theme) =>
                  `inset 0 0 0 1px ${theme.colors?.journalColorPickerBorder}`,
              },
              "& .components-circular-option-picker__option": {
                borderRadius: "50% !important",
              },
              "& .components-popover.components-tooltip": {
                display: "none !important",
              },
            }}
          />
          <WPColorPalette
            colors={journalColors.premiumJournalColors}
            value={value.toUpperCase()}
            onChange={onClickPremiumColor}
            disableCustomColors={true}
            clearable={false}
            sx={{
              mb: 3,
              "& .components-circular-option-picker__option::after": {
                boxShadow: (theme) =>
                  `inset 0 0 0 1px ${theme.colors?.journalColorPickerBorder}`,
              },
              "& .components-circular-option-picker__option": {
                borderRadius: "50% !important",
                position: "relative",
              },
              "& .components-popover.components-tooltip": {
                display: "none !important",
              },
              "& .components-circular-option-picker__option-wrapper::after": {
                width: "12px",
                height: "12px",
                backgroundSize: "contain",
                content: '" "',
                position: "absolute",
                top: -1,
                right: 0,
                backgroundImage: premiumStar,
                backgroundColor: "white",
                borderRadius: "50%",
                border: "1px solid",
                borderColor: "surface_light1_dark5",
                zIndex: 12,
              },
            }}
          />
        </div>
      </>
    );
  },
);

JournalColorPalette.displayName = "JournalColorPalette";
