// This file is to do some mapping between the Apple icon names we get from the Template Gallery API
// and local icon files that we can use in app. It's not ideal and in the future we should have a better
// icon system in general and have the API updated to return the icon names we want to use in app.

const iconDictionary = {
  "sun.max.fill": "sun",
  "moon.fill": "moon",
  calendar: "calendar-days",
  menucard: "calendar-check",
  "arrow.down": "hands-praying",
  "theatermasks.fill": "face-smile-beam",
  "figure.wave": "person-walking",
  "sun.haze": "sun",
  "bolt.fill": "bolt",
  photo: "image",
  "fork.knife": "bowl-food",
  "takeoutbag.and.cup.and.straw": "kitchen-set",
  "figure.walk": "person-running",
  "arrow.triangle.2.circlepath": "chart-line",
  "face.dashed": "face-grin",
  "brain.head.profile": "brain",
  "bed.double": "bed",
  bandage: "heart",
  "figure.stand": "person",
  "arrow.up.to.line": "check",
  scroll: "check-double",
  "person.fill.checkmark": "user-check",
  checklist: "list-check",
  "hand.raised": "hands-praying",
  brain: "hands-asl-interpreting",
  "plus.message": "user-plus",
  airplane: "plane",
  "globe.americas.fill": "earth-americas",
  suitcase: "suitcase-rolling",
  magazine: "book",
  lightbulb: "lightbulb",
  "pencil.and.outline": "pencil",
  "character.cursor.ibeam": "marker",
  hourglass: "hourglass-half",
  "aqi.medium": "champagne-glasses",
  "camera.macro": "seedling",
  "sun.min": "sun",
  "leaf.fill": "leaf",
  snowflake: "snowflake",
  crown: "crown",
  "list.clipboard": "clipboard-list",
  "list.bullet": "list",
  cart: "cart-shopping",
  "person.line.dotted.person": "handshake-simple",
  "arrow.triangle.branch": "diagram-project",
  "lamp.desk": "pen-to-square",
  "frying.pan": "drumstick-bite",
};

type iconDictionaryType = typeof iconDictionary;
export type iconDictionaryKeyType = keyof iconDictionaryType;

export const getTemplateGalleryIconName = (name: iconDictionaryKeyType) => {
  return iconDictionary[name];
};
