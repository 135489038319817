import md5wasm from "md5-wasm";

async function getMD5Hash(
  input: string | ArrayBuffer | Uint8Array,
): Promise<string> {
  const uint8Array = convertToUint8Array(input);
  const result = await md5wasm(uint8Array);
  return result;
}

function convertToUint8Array(input: string | ArrayBuffer | Uint8Array) {
  let bytes: Uint8Array;
  if (input instanceof ArrayBuffer) {
    bytes = new Uint8Array(input);
  } else if (input instanceof Uint8Array) {
    bytes = input;
  } else {
    const encoded = new TextEncoder().encode(input);
    bytes = encoded;
  }
  return bytes;
}

export { getMD5Hash as md5, convertToUint8Array };
