import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import {
  CommentSendable,
  EntrySendable,
  OutboxItem,
  TemplateSendable,
  UploadOriginalMomentSendable,
} from "@/data/models/OutboxTypes";

type Props = {
  onClose: () => void;
};
export const OutboxItems: React.FC<Props> = ({ onClose }) => {
  const [, setLocation] = useLocation();
  const [outboxItems, setOutboxItems] = useState<OutboxItem[]>([]);

  useEffect(() => {
    const unsub = d1Classes.outbox.subscribeToAll((items) => {
      setOutboxItems(items);
    });
    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      {outboxItems.map((item) => {
        let journal = "";
        let entry = "";
        let itemID = "";
        switch (item.type) {
          case "Entry":
            {
              const i = item as unknown as EntrySendable;
              itemID = i.entryId;
              journal = i.journalId;
              entry = i.entryId;
            }
            break;
          case "OriginalMedia":
            {
              const i = item as unknown as UploadOriginalMomentSendable;
              itemID = i.momentId;
              journal = i.journalId;
              entry = i.entryId;
            }
            break;
          case "Comment":
            {
              const i = item as unknown as CommentSendable;
              itemID = i.commentId;
            }
            break;
          case "Template":
            {
              const i = item as unknown as TemplateSendable;
              itemID = i.templateId;
            }
            break;
        }

        return (
          <div
            key={itemID}
            sx={{
              display: "flex",
              flexDirection: "column",
              borderBottom: "1px solid",
              borderColor: "borderPrimary",
              fontSize: 0,
              mb: 2,
              pb: 2,
              "& > div": {
                py: 1,
              },
            }}
          >
            {itemID && (
              <div>
                {__("ID")}: {itemID}
              </div>
            )}
            {journal && (
              <div>
                {__("Journal")}: {journal}
              </div>
            )}
            {entry && (
              <div>
                {__("Entry")}:{" "}
                <Button
                  sx={{ fontSize: 0 }}
                  variant="link"
                  onClick={() => {
                    setLocation(`/journals/${journal}/${entry}`);
                    onClose();
                  }}
                >
                  {entry}
                </Button>
              </div>
            )}
            <div>
              {__("Type")}: {item.type}
            </div>
            <div>
              {__("Action")}: {item.action}
            </div>
            {item.failedMessage && (
              <div>
                {__("Message")}: {item.failedMessage}
              </div>
            )}
            {item.failedDate && (
              <div>
                {__("Failed date")}:{" "}
                {format(item.failedDate, "yyyy-MM-d hh:mm:ss")}
              </div>
            )}
            <div>
              {__("Date")}: {format(item.userModifiedAt, "yyyy-MM-d hh:mm:ss")}
            </div>
          </div>
        );
      })}
      {outboxItems.length === 0 && (
        <p sx={{ fontSize: 0 }}>
          {__("No pending items to sync to the server")}
        </p>
      )}
    </div>
  );
};
