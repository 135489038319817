import { Button, CheckboxControl } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextareaControl } from "@/components/Form/TextareaControl";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { primaryViewState } from "@/view_state/ViewStates";

type ModalProps = {
  onClose: () => void;
};

export const ReportProblemModal = observer(({ onClose }: ModalProps) => {
  const { __ } = useI18n();
  const [description, setDescription] = useState("");
  const [canReproduce, setCanReproduce] = useState(false);
  const [steps, setSteps] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isMobile } = useDevice();
  const user = primaryViewState.user;
  const descriptionLabel = "Please give a short description of the problem";
  const canReproduceLabel = "Can you reliably repeat the problem?";
  const stepsLabel = "If so, what steps do you take to find the problem?";

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e.preventDefault();

    const email = user?.email;
    let comment = "Sent from the Day One Web App\n\n";
    if (description.length > 0) {
      comment += `${descriptionLabel}:\n ${description}\n\n`;
    }

    comment += `${canReproduceLabel}:\n ${canReproduce ? "Yes" : "No"} \n\n`;

    if (steps.length > 0) {
      comment += `${stepsLabel}:\n ${steps}`;
    }
    const res = await d1Classes.fetchWrapper.fetchAPI("/support/ticket", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, comment }),
    });

    if (res.ok) {
      setSuccess(true);
    } else {
      setError(true);
    }
    setIsSubmitting(false);
  };

  return (
    <D1Modal
      title="Submit Support Request"
      onRequestClose={onClose}
      sx={{
        width: "420px",
        "& .components-modal__content": { pr: isMobile ? 0 : 4 },
      }}
    >
      <div
        sx={{
          "& p": {
            mb: 4,
            lineHeight: "1.3rem",
          },
        }}
      >
        {error && (
          <ErrorMessage
            message={__(
              "We weren't able to send your message. Please try again later",
            )}
          />
        )}
        {success ? (
          <div>{__("Thank you for your feedback")}</div>
        ) : (
          <>
            <p sx={{ fontSize: 1 }}>
              <a
                sx={{
                  color: "primary",
                  textDecoration: "underline",
                }}
                href="https://dayoneapp.com/guides/day-one-on-the-web/getting-started-on-the-web"
                target="_blank"
                rel="noopener"
              >
                {__("Have you seen our FAQ yet?")}
              </a>{" "}
              {__(
                "We answer a lot of the most common questions there. If your concern is not addressed please let us know.",
              )}
            </p>
            <form onSubmit={handleSubmit}>
              <TextareaControl
                label={__(descriptionLabel)}
                value={description}
                onChange={(value: string) => {
                  setDescription(value);
                }}
              />
              <CheckboxControl
                label={__(canReproduceLabel)}
                checked={canReproduce}
                onChange={(value) => {
                  setCanReproduce(value);
                }}
              />
              <TextareaControl
                label={__(stepsLabel)}
                value={steps}
                onChange={(value: string) => {
                  setSteps(value);
                }}
              />
              <div sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
                <Button
                  variant="secondary"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    onClose();
                  }}
                >
                  {__("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    (description === "" && steps === "") || isSubmitting
                  }
                  type="submit"
                >
                  {__("Send")}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </D1Modal>
  );
});

ReportProblemModal.displayName = "ReportProblemModal";
