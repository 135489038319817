export const PartlyCloudy: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3836 15.387C10.8986 15.387 11.3836 15.493 11.8076 15.706C12.1256 13.61 13.9446 12 16.1256 12C18.5046 12 20.4436 13.914 20.5046 16.283C21.9286 16.465 23.0346 17.68 23.0346 19.184C23.0346 20.794 21.7316 22.1 20.1256 22.1H10.3836C8.53564 22.1 7.03564 20.596 7.03564 18.744C7.03564 16.89 8.53564 15.387 10.3836 15.387Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.33 8.50019C13.9493 6.10873 10.8913 5.28935 8.49989 6.67006C6.10843 8.05077 5.28905 11.1087 6.66976 13.5002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 3V1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3598 4.64016L18.0698 3.93016"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93016 18.0698L4.64016 17.3598"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11H3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93016 3.93016L4.64016 4.64016"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
