import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import {
  getFilterIdAndLabel,
  MultipleFilterOptions,
} from "@/components/Search/FilterPills";
import { MultipleOptions } from "@/components/Search/MultipleOptions";
import { Pill } from "@/components/Search/Pill";
import { truncateString } from "@/utils/strings";
import { viewStates } from "@/view_state/ViewStates";

interface Props {
  filterOptions: MultipleFilterOptions;
  selectedOptions: string[];
  noOptionsText: string;
}
const MAX_LENGTH = 24;

export const MultipleOptionsFilter: React.FC<Props> = observer(
  ({ filterOptions, selectedOptions, noOptionsText }) => {
    const { __ } = useI18n();

    const { search: searchViewState } = viewStates;

    const { setFilterByType } = searchViewState;

    const toggleOption = useCallback(
      (optionId: string) => {
        const newSelectedOptions = selectedOptions?.includes(optionId)
          ? selectedOptions.filter((_id) => _id !== optionId)
          : [...selectedOptions, optionId];
        setFilterByType(filterOptions.type, newSelectedOptions);
      },
      [selectedOptions, setFilterByType],
    );

    const [pillText, setPillText] = useState(__("Any"));

    useEffect(() => {
      if (selectedOptions.length > 0) {
        const truncatedNames: string[] = [];
        filterOptions.options.map((option) => {
          const { optionId, label } = getFilterIdAndLabel(
            filterOptions.type,
            option,
            __,
          );
          if (selectedOptions?.includes(optionId)) {
            truncatedNames.push(truncateString(label || "", MAX_LENGTH));
          }
        });
        if (truncatedNames.length > 2) {
          setPillText(`${truncatedNames[0]},+${truncatedNames.length - 1}`);
        } else {
          setPillText(truncatedNames.join(", "));
        }
      } else {
        setPillText(__("Any"));
      }
    }, [filterOptions.options, selectedOptions]);

    return (
      <D1Dropdown
        focusOnMount
        popoverProps={{
          placement: "bottom-start",
          variant: "primary",
        }}
        onClose={() => {
          searchViewState.setFilterDropdownOpen(false);
        }}
        renderToggle={({ isOpen, onToggle }) => (
          <Pill
            icon={null}
            text={pillText}
            isActive={selectedOptions.length > 0}
            aria-expanded={isOpen}
            fullWidth
            onClick={() => {
              searchViewState.setFilterDropdownOpen(true);
              onToggle();
              if (!isOpen) {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: `searchFilter_filterRow_${filterOptions.type}`,
                });
              }
            }}
          />
        )}
        renderContent={() => (
          <div
            sx={{
              mx: -2,
              mb: -2,
            }}
          >
            <div
              sx={{
                overflowY: "auto",
                maxHeight: "350px",
                width: "245px",
                pb: 2,
                pt: 1,
              }}
            >
              <MultipleOptions
                filterOptions={filterOptions}
                selectedOptions={selectedOptions}
                onOptionToggle={toggleOption}
                noOptionsText={noOptionsText}
                onToggleAll={() => {
                  setFilterByType(filterOptions.type, [], true);
                }}
              />
            </div>
          </div>
        )}
      />
    );
  },
);
