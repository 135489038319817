import { Sentry } from "@/Sentry";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { JournalVault } from "@/data/repositories/Syncables";
import { Key } from "@/data/repositories/Syncables";
import { UnlockedUserPrivateKey } from "@/data/repositories/UserKeysRepository";
import { Journal } from "@/data/repositories/V6API";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export async function unlockAndStoreAllJournalKeys(
  userId: string,
  journals: JournalDBRow[] | Journal[],
  userPrivateKeys: UnlockedUserPrivateKey[],
  vaultRepository: VaultRepository,
): Promise<void> {
  const vaults = await vaultRepository.readAll();
  journals.map((journal) => {
    const encryption = vaults.find((v) => v.journal_id === journal.id);

    if (!encryption) {
      return Promise.resolve(undefined);
    }

    return Promise.all(
      encryption.vault.grants.map(async (grant) => {
        if (grant.user_id === userId) {
          try {
            const vaultKey = await DOCrypto.Grant.getVaultKey(
              userPrivateKeys,
              grant,
              journal.id,
            );
            await vaultRepository.cacheVaultKey(journal.id, vaultKey);
            const x = await decryptAndCacheJournalKeys(
              vaultKey,
              encryption.vault,
              vaultRepository,
            );
            return x;
          } catch (err) {
            Sentry.captureException(err);
            console.error(
              `Failed to decrypt vault key, continuing: ${err}. JournalId: ${journal.id} - Grant: ${grant}`,
            );
          }
        }

        // User's grant doesn't match userId.
        return Promise.resolve(undefined);
      }),
    );
  });
}

const decryptAndCacheJournalKeys = (
  vaultKey: CryptoKey,
  vault: JournalVault,
  vaultRepository: VaultRepository,
) => {
  return Promise.all(
    vault.keys.map(async (keyMeta) => {
      const journalPrivateKey = await decryptJournalKey(vaultKey, keyMeta);
      return vaultRepository.cacheJournalKey(
        keyMeta.fingerprint,
        journalPrivateKey,
      );
    }),
  );
};

const decryptJournalKey = (vaultKey: CryptoKey, keyMeta: Key) => {
  return Asymmetric.Private.decryptKey(vaultKey, keyMeta.encrypted_private_key);
};
