import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { D1Modal } from "@/components/D1Modal";
import { useAuth } from "@/data/hooks/AuthProvider";

export const PendingLogout: React.FC<{ outboxSize: number }> = ({
  outboxSize,
}) => {
  const { __, _n } = useI18n();
  const { doLogout, doForceLogout } = useAuth();
  // save the outbox size to state and only update it after the prop has changed with a delay
  // in case there are more changes to the prop we don't want to update things too quickly
  // as the prop can drop to 0 during processing but then immediately back if the processing fails.
  const [outboxSizeState, setOutboxSizeState] = useState(outboxSize);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setOutboxSizeState(outboxSize);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [outboxSize]);

  const hasOutboxItems = outboxSizeState > 0;

  useEffect(() => {
    if (hasOutboxItems) {
      d1MainThreadClasses.syncService.pushDelayed();
    }
  }, []);

  const message = hasOutboxItems
    ? __(
        "There are still some items that haven't synced to the server. Please wait until they complete to log out, otherwise your changes will be lost.",
      )
    : __("It's now safe to log out. All items have been synced to the server.");

  const buttonText = hasOutboxItems
    ? __("Log out anyway")
    : __("Safely log out");

  const handleLogout = () => {
    d1Classes.keyValueStore.set("pending-logout", false);
    if (hasOutboxItems) {
      doForceLogout();
    } else {
      doLogout();
    }
  };

  return (
    <D1Modal
      sx={{
        width: "420px",
        "& .components-modal__content": {
          pt: 1,
        },
      }}
      isDismissible={false}
      shouldCloseOnClickOutside={false}
      title={__("Logging Out")}
      onRequestClose={() => {}}
    >
      <div sx={{ "& p": { py: 2, lineHeight: "body" } }}>
        {hasOutboxItems && (
          <p sx={{ backgroundColor: "surface_light2_dark2", px: 2 }}>
            {sprintf(
              _n(
                "%d item left to sync.",
                "%d items left to sync.",
                outboxSizeState,
              ),
              outboxSizeState,
            )}
          </p>
        )}
        <p>{message}</p>
      </div>
      <div sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}>
        <Button
          variant="secondary"
          onClick={() => {
            d1Classes.keyValueStore.set("pending-logout", false);
          }}
        >
          {__("Cancel")}
        </Button>
        <Button
          onClick={handleLogout}
          variant="primary"
          isDestructive={hasOutboxItems}
        >
          {buttonText}
        </Button>
      </div>
    </D1Modal>
  );
};
