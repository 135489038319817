import { useEffect } from "react";
import { useLocation } from "wouter";

import { searchViewState } from "@/view_state/ViewStates";

export const SearchKeyboardNavigation = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [, setLocation] = useLocation();
  const handleKeyboardEvent = (event: KeyboardEvent) => {
    if (
      !document.getElementById("search-list") ||
      !["ArrowUp", "ArrowDown", "Enter"].includes(event.key)
    ) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    switch (event.key) {
      case "Enter":
        {
          const href = (event.target as HTMLAnchorElement)?.getAttribute(
            "href",
          );
          if (href) {
            setLocation(href);
          }
        }
        break;
      case "ArrowUp":
        searchViewState.moveUp();
        break;
      case "ArrowDown":
        searchViewState.moveDown();
        break;
    }
  };

  useEffect(() => {
    addEventListener("keydown", handleKeyboardEvent, { capture: true });
    return () =>
      removeEventListener("keydown", handleKeyboardEvent, { capture: true });
  }, []);

  return <>{children}</>;
};
