import { PropsWithChildren } from "react";

export const Message: React.FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return (
    <div
      sx={{
        textAlign: "center",
        color: "textSecondary",
        mb: 8,
        fontSize: 2,
        a: { textDecoration: "underline", color: "textPrimary" },
      }}
      className={className}
    >
      {children}
    </div>
  );
};
