import { Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { TextControl } from "@/components/Form/TextControl";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { JournalDBRow } from "@/data/db/migrations/journal";

type EditJournalNameProps = {
  currentJournal: JournalDBRow;
  handleClose: () => void;
  setJournal: (journal: JournalDBRow) => void;
};
export const EditJournalName: React.FC<EditJournalNameProps> = ({
  currentJournal,
  handleClose,
  setJournal,
}) => {
  const [name, setName] = useState(currentJournal.name);
  return (
    <D1Modal
      title={__("Edit Journal Name")}
      onRequestClose={handleClose}
      sx={{
        width: ["100dvw", "100dvw", "420px"],
      }}
    >
      <EditSection>
        <FlexItem sx={{ flex: 1 }}>
          <Label htmlFor="journal-name">{__("Journal name")}</Label>
          <Flex sx={{ mb: 2 }}>
            <FlexBlock>
              <TextControl
                name="journal-name"
                id="journal-name"
                onChange={(value) => {
                  setName(value);
                }}
                value={name}
                autoFocus
              />
            </FlexBlock>
          </Flex>
          <Flex>
            <EditButtons
              isSaving={false}
              doSave={() => {
                setJournal({ ...currentJournal, name });
                handleClose();
              }}
              attributeChanged={name !== currentJournal.name && name !== ""}
              doCancel={handleClose}
            />
          </Flex>
        </FlexItem>
      </EditSection>
    </D1Modal>
  );
};
