import { Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { JournalColorPalette } from "@/components/JournalSettings/JournalColorPalette";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

type EditJournalColorProps = {
  currentJournal: JournalDBRow;
  handleClose: () => void;
  setJournal: (journal: JournalDBRow) => void;
  primaryViewState: PrimaryViewState;
};
export const EditJournalColor: React.FC<EditJournalColorProps> = ({
  currentJournal,
  handleClose,
  setJournal,
  primaryViewState,
}) => {
  const [color, setColor] = useState(currentJournal.color);
  return (
    <D1Modal
      title={__("Edit Journal Color")}
      onRequestClose={handleClose}
      sx={{ width: "420px" }}
    >
      <EditSection>
        <FlexItem sx={{ flex: 1 }}>
          <Flex sx={{ mb: 2 }}>
            <FlexBlock>
              <JournalColorPalette
                primaryViewState={primaryViewState}
                onChange={(color) => {
                  setColor(color || "");
                }}
                value={color}
                label={__("Journal color")}
              />
            </FlexBlock>
          </Flex>
          <Flex>
            <EditButtons
              isSaving={false}
              doSave={() => {
                setJournal({ ...currentJournal, color });
                handleClose();
              }}
              attributeChanged={color !== currentJournal.color}
              doCancel={handleClose}
            />
          </Flex>
        </FlexItem>
      </EditSection>
    </D1Modal>
  );
};
