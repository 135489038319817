export const EntryLink: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0147 18.0145L11.4853 19.5439C9.54415 21.4851 6.39697 21.4851 4.45584 19.5439V19.5439C2.51472 17.6028 2.51472 14.4556 4.45584 12.5145L6.44975 10.5206C7.38743 9.58294 8.6592 9.05615 9.98529 9.05615C11.3114 9.05615 12.5831 9.58294 13.5208 10.5206"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9853 5.98528L12.5148 4.45584C14.4559 2.51472 17.6031 2.51472 19.5442 4.45584V4.45584C21.4853 6.39697 21.4853 9.54415 19.5442 11.4853L17.5503 13.4792C15.5977 15.4318 12.4319 15.4318 10.4792 13.4792"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
