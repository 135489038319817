import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

const icon = (
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.20001 10.8V2.09995C1.20001 1.85245 1.28814 1.64058 1.46439 1.46433C1.64064 1.28808 1.85251 1.19995 2.10001 1.19995H9.90001C10.1475 1.19995 10.3594 1.28808 10.5356 1.46433C10.7119 1.64058 10.8 1.85245 10.8 2.09995V8.09995C10.8 8.34745 10.7119 8.55933 10.5356 8.73558C10.3594 8.91183 10.1475 8.99995 9.90001 8.99995H3.00001L1.20001 10.8ZM3.00001 3.89995H9.00001V2.99995H3.00001V3.89995ZM3.00001 7.19995H7.20001V6.29995H3.00001V7.19995ZM3.00001 5.54995H9.00001V4.64995H3.00001V5.54995Z"
      fill="currentColor"
    />
  </svg>
);

export const CommentIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon={icon}
      color={IconColors.blue.color}
      bgColor={IconColors.blue.bgColor}
      size={12}
    />
  );
};
