import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { PropsWithChildren } from "react";

import { D1Modal } from "@/components/D1Modal";

type Props = {
  title: string;
  handleClose: () => void;
  successButton?: React.ReactNode;
};

export const ResultModal: React.FC<PropsWithChildren<Props>> = ({
  title,
  handleClose,
  successButton,
  children,
}) => {
  const { __ } = useI18n();
  return (
    <D1Modal title={title} onRequestClose={handleClose}>
      {children}
      <div sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}>
        {successButton}
        <Button variant="primary" onClick={handleClose}>
          {__("Ok")}
        </Button>
      </div>
    </D1Modal>
  );
};
