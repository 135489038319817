import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance } from "@wordpress/blocks";
import { useSelect, useDispatch } from "@wordpress/data";
import { useState } from "react";

export function useEditorBlocks() {
  const [blocks, setBlocks] = useState<BlockInstance[]>([]);
  const [firstBlock, setFirstBlock] = useState<BlockInstance | null>(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [lastBlock, setLastBlock] = useState<BlockInstance | null>(null);

  const {
    insertBlock,
    multiSelect,
    removeBlock,
    selectBlock,
    resetBlocks,
    selectionChange,
  } = useDispatch(blockEditorStore);

  // blockEditorStore selectors reference is available at
  // https://github.com/WordPress/gutenberg/blob/trunk/docs/reference-guides/data/data-core-block-editor.md
  // @ts-ignore - The type defs are out of date.
  const { getSelectionEnd } = useSelect(
    (select) => select(blockEditorStore),
    [],
  );
  useSelect((select) => {
    // @ts-ignore - The type defs are out of date.
    const blocks = select(blockEditorStore).getBlocks();
    const isEmpty = !blocks.length;

    setBlocks(blocks);
    setFirstBlock(blocks[0]);
    setIsEmpty(isEmpty);
    setLastBlock(blocks[blocks.length - 1]);
  }, []);

  return {
    blocks,
    firstBlock,
    isEmpty,
    lastBlock,
    insertBlock,
    multiSelect,
    removeBlock,
    getSelectionEnd,
    selectBlock,
    resetBlocks,
    selectionChange,
  };
}
