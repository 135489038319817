import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { ItemGroup } from "@/components/ItemGroup";
import { ButtonItem } from "@/components/ItemGroup/ButtonItem";
import { AppleLogo } from "@/components/icons/AppleLogo";
import { useCloudKit } from "@/hooks/useCloudKit";

interface Props {
  handleClose: () => void;
}

export const RetrieveEncryptionKeyFromApple: React.FC<Props> = observer(
  ({ handleClose }) => {
    const { workingWithCloudKit, openiCloudLoginWindow, loading } = useCloudKit(
      handleClose,
      "retrieve",
    );

    const { __ } = useI18n();

    return (
      <ItemGroup>
        <ButtonItem
          key={"Sign in to iCloud"}
          onClick={openiCloudLoginWindow}
          disabled={workingWithCloudKit || loading}
        >
          <Flex
            sx={{
              justifyContent: "flex-start",
              gap: 3,
              flexDirection: "row",
            }}
          >
            <AppleLogo />
            <span
              sx={{
                fontSize: 1,
                lineHeight: "1.5rem",
                whiteSpace: "nowrap",
              }}
            >
              {__("Sign in with iCloud")}
            </span>
          </Flex>
        </ButtonItem>
      </ItemGroup>
    );
  },
);
