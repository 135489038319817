import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Item } from "@/components/ItemGroup";
import { Button } from "@/components/Settings/Button";
import { Label } from "@/components/Settings/Label";
import { viewStates } from "@/view_state/ViewStates";

export const PasskeysSettingsRow: React.FC = observer(() => {
  const { __ } = useI18n();
  const isDev = viewStates.featureFlags.enablePasskeys;

  return isDev ? (
    <>
      <Item>
        <FlexItem>
          <Label>{__("Passkeys")}</Label>
        </FlexItem>
        <FlexItem>
          <Button onClick={() => viewStates.modalRouter.showPasskeysManager()}>
            {__("Manage Passkeys")}
          </Button>
        </FlexItem>
      </Item>
    </>
  ) : null;
});

PasskeysSettingsRow.displayName = "PasskeysSettingsRow";
