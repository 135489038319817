import { ThemeProvider } from "@theme-ui/core";

import { DayOneColorModeProvider } from "@/styles/DayOneColorModeProvider";
import { theme } from "@/styles/theme";

export const DayOneThemeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <DayOneColorModeProvider>{children}</DayOneColorModeProvider>
    </ThemeProvider>
  );
};
