import { Button, Flex, Icon } from "@wordpress/components";
import { copy } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { TextControl } from "@/components/Form/TextControl";
import { viewStates } from "@/view_state/ViewStates";

type ShareModalProps = {
  onClose: () => void;
};

export const ShareModal: React.FC<ShareModalProps> = observer(({ onClose }) => {
  const { __ } = useI18n();

  return (
    <D1Modal
      sx={{
        maxWidth: "420px",
        width: "100%",
      }}
      title={__("Share Prefilled Entry URL")}
      onRequestClose={() => onClose()}
      shouldCloseOnClickOutside={true}
    >
      <p>
        {__(
          "This link lets someone else make an entry in their own journal, starting with the content of this entry. Only text and formatting are supported, not media.",
        )}
      </p>
      <Flex sx={{ justifyContent: "center", alignItems: "center", pt: 4 }}>
        <TextControl
          sx={{
            width: "100%",
            borderRadius: 2,
          }}
          value={viewStates.activeEntry.linkToPrefilledEntry ?? ""}
          onChange={() => {}}
          // @ts-ignore this field works but isn't in the type defs
          __nextHasNoMarginBottom={true}
        />
        <Button
          sx={{ borderRadius: 2 }}
          onClick={() => {
            if (viewStates.activeEntry.linkToPrefilledEntry) {
              navigator.clipboard.writeText(
                viewStates.activeEntry.linkToPrefilledEntry,
              );
            }
          }}
        >
          <Icon icon={copy} />
        </Button>
      </Flex>
    </D1Modal>
  );
});

ShareModal.displayName = "ShareModal";
