type Props = {
  color?: string;
};

export const AddReaction: React.FC<Props> = ({ color }) => {
  return (
    <svg
      id="add-reaction"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        "& path": {
          fill: color ? color : "currentColor",
        },
      }}
    >
      <path
        d="M12.4684 3C12.1716 3 11.8901 3.13182 11.7002 3.35982L7.46356 8.44373C7.16403 8.80316 7 9.25623 7 9.7241V18C7 19.1046 7.89543 20 9 20H15.8394C16.5571 20 17.2198 19.6154 17.5758 18.9923L20.7365 13.4611C20.9092 13.159 21 12.8169 21 12.4689V11C21 9.89543 20.1046 9 19 9H14V4C14 3.44772 13.5523 3 13 3H12.4684Z"
        fill="currentColor"
      />
      <path
        d="M4 10C3.44772 10 3 10.4477 3 11V19C3 19.5523 3.44772 20 4 20H5C5.55228 20 6 19.5523 6 19V11C6 10.4477 5.55228 10 5 10H4Z"
        fill="currentColor"
      />
    </svg>
  );
};
