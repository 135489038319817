import { centerIconStyle } from "@/components/JournalSidebar/SyncStatusBadge";

export function SyncSpinner({ progress = null }: { progress?: number | null }) {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...centerIconStyle,

        "& > .spinner": {
          animation: progress ?? "rotate 2s linear infinite",
          zIndex: "2",
          width: "25px",
          height: "25px",

          "& > .circle": {
            stroke: "currentcolor",
            strokeLinecap: "round",
          },
          "& .bg-circle": {
            stroke: "lightShade",
          },

          "& .under-spinner-line": {
            animation: "rotate 5s linear infinite",
            // this is the little dots that appear when the spinner is spinning
            // with progress. You can adjust the number of dots by changing the
            // 2nd value of the strokeDasharray but be warned that one of the dots
            // may be too near another one where the seam is if you chose the wrong value
            strokeDasharray: "1, 25",

            // this makes it so that it spins around the center
            transformBox: "fill-box",
            transformOrigin: "center",

            color: "var(--wp-admin-theme-color)",
            stroke: "currentcolor",
          },

          "& .spinner-line": {
            transformBox: "fill-box",
            transformOrigin: "center",
            transform: "rotate(270deg)",
            transition: "stroke-dasharray 0.35s ease-in-out",

            color: "var(--wp-admin-theme-color)",
            stroke: "currentcolor",
            ...(progress !== null
              ? {
                  // This basically is a dashed line that we carefully pick vales for so that it looks like a progress bar
                  // the first value is the size of the dash, the second is the size of the gap
                  strokeDasharray: `${(progress / 100) * 124}, 138`,
                }
              : {
                  // this makes the line get bigger and smaller and spin around
                  animation: "dash 1.5s ease-in-out infinite",
                }),
          },
        },

        "@keyframes rotate": {
          "100%": {
            transform: "rotate(360deg)",
          },
        },

        "@keyframes dash": {
          "0%": {
            strokeDasharray: "1, 150",
            strokeDashoffset: "0",
          },
          "50%": {
            strokeDasharray: "90, 150",
            strokeDashoffset: "-35",
          },
          "100%": {
            strokeDasharray: "90, 150",
            strokeDashoffset: "-124",
          },
        },
      }}
    >
      <svg className="spinner" viewBox="0 0 50 50">
        {progress && (
          <circle
            className="under-spinner-line circle"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="6"
          ></circle>
        )}
        <circle
          className="bg-circle circle"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="6"
        ></circle>
        <circle
          className="spinner-line circle"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="6"
        ></circle>
      </svg>
    </div>
  );
}
