import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { EditSection } from "@/components/Settings/EditSection";
import { SignInWithApple } from "@/components/SignInWithApple";

type AppleConnectProps = {
  title: string;
  handleClose: () => void;
};

export const AppleConnect: React.FC<AppleConnectProps> = ({
  title,
  handleClose,
}) => {
  const userStore = d1Classes.userStore;
  const { __ } = useI18n();
  const [result, setResult] = useState<string | null>(null);

  const linkAccount = async (token: string) => {
    const res = await d1Classes.fetchWrapper.fetchAPI(
      "/users/sign-in-with-apple",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: token,
      },
    );
    if (res.ok) {
      userStore.sync();
      setResult(null);
      handleClose();
    } else {
      setResult(
        __(
          "We weren't able to link your Apple account. Please try again later",
        ),
      );
    }
  };

  return (
    <D1Modal title={title} onRequestClose={handleClose} sx={{ width: "420px" }}>
      <EditSection>
        <FlexItem sx={{ flex: 1 }}>
          <SignInWithApple action={linkAccount} />
          {result}
        </FlexItem>
      </EditSection>
    </D1Modal>
  );
};
