import { PropsWithChildren } from "react";

export const JournalSettingsModalHeader: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <header
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "60px",
        lineHeight: "60px",
        borderBottom: "1px solid",
        p: 0,
        borderBottomColor: "borderPrimary",
        "& button": {
          fontWeight: 600,
          fontSize: 2,
          ":hover": {
            opacity: "0.6",
          },
        },
        "& span": {
          pl: 4,
        },
      }}
    >
      {children}
    </header>
  );
};
