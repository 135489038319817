import { useEffect } from "react";

import { useGoogleLogin } from "@/hooks/google/useGoogleLogin";
import { useColorMode } from "@/styles/DayOneColorModeProvider";

type GoogleLoginProps = {
  action: (token: string) => void;
  context?: string;
};

const CLIENT_ID = process.env.VITE_GOOGLE_OAUTH_CLIENT_ID || "";

export const GoogleLogin: React.FC<GoogleLoginProps> = ({
  context,
  action,
}) => {
  const { inDarkMode } = useColorMode();
  const { googleLibraryLoaded, token } = useGoogleLogin({
    client_id: CLIENT_ID,
  });

  if (!context) {
    context = "login";
  }

  const doGlogin = () => {
    action(token);
  };

  useEffect(() => {
    if (googleLibraryLoaded && window.google) {
      window.google.accounts.id.renderButton(
        document.getElementById("googleLogin")!,
        {
          theme: inDarkMode ? "filled_black" : "outline",
          text: "continue_with",
          size: "large",
          type: "standard",
        },
      );
    }
  }, [googleLibraryLoaded, inDarkMode]);

  useEffect(() => {
    if (token !== "") {
      doGlogin();
    }
  }, [token]);

  return (
    <div
      sx={{
        mb: 2,
        mt: 2,
        border: "1px solid",
        borderColor: inDarkMode ? "rgba(255, 255, 255, 0.12)" : "borderPrimary",
        borderRadius: "sm",
        overflow: "hidden",
        color: "textPrimary",
        "&&& div": {
          bg: inDarkMode ? "#333333" : "#FFFFFF",
          border: "none",
          fontSize: "16px",
        },
        ":hover": {
          borderColor: context === "link" ? "borderHighlight" : "borderPrimary",
        },
        // Container in the button which will sometimes change background color on hover or focus
        "&&& .nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc:hover .nsm7Bb-HzV7m-LgbsSe-MJoBVe, &&& .nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc:focus .nsm7Bb-HzV7m-LgbsSe-MJoBVe":
          {
            bg: inDarkMode ? "#333333" : "#FFFFFF",
          },
        // The flex container within the button.
        // Let's hack that icon over closer to the text
        "&&& .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb": {
          justifyContent: "center",
        },
        // The text of the button
        "&&& .nsm7Bb-HzV7m-LgbsSe-BPrWId": {
          flexGrow: 0,
          flexShrink: 1,
          fontFamily: "body",
          color: "textPrimary",
        },
        // The SVG icon, and also the light mode container, it seems?
        "&&& .nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c": {
          height: "14px",
          marginRight: "4px",
          minWidth: "14px",
          width: "14px",
        },
        // The SVG Icon container
        "&&& .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf": {
          marginRight: "3px",
          marginLeft: "0px",
          width: "16px !important",
          minWidth: "unset !important",
          height: "auto",
          flex: 0,
        },
      }}
      id="googleLogin"
    ></div>
  );
};
