import { BlockInstance, createBlock } from "@wordpress/blocks";

import { ZERO_WIDTH_NO_BREAK_SPACE } from "../rtj2gb/text2paragraph";

import { PARAGRAPH_BLOCK_ID } from "@/components/Editor/blocks/constants";

export const squashParagraphBlocks = (blocks: BlockInstance[]) => {
  const squashed: BlockInstance[] = [];
  let buffer: string[] = [];

  const pushParagraphBlock = (suffix = "") => {
    const trimmedBuffers = buffer.join("\n").trim();
    const newBlock = createBlock(PARAGRAPH_BLOCK_ID, {
      content: `${trimmedBuffers}${suffix}`,
    });
    squashed.push(newBlock);
    buffer = [];
  };

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    // If the current block isn't a paragraph add it to the list of blocks to return
    if (block.name !== PARAGRAPH_BLOCK_ID) {
      squashed.push(block);
      continue;
    }
    const trimmedBlockContent = block.attributes.content.trim();
    if (
      trimmedBlockContent === "" ||
      trimmedBlockContent === ZERO_WIDTH_NO_BREAK_SPACE
    ) {
      buffer.push(ZERO_WIDTH_NO_BREAK_SPACE);
    } else {
      buffer.push(trimmedBlockContent);
    }
    // If there is no next block add the content we have in the buffer as a paragraph to the list of blocks to return
    if (i + 1 < blocks.length) {
      // If there is a next block and it's not a paragraph add the content we have in the buffer as a paragraph along with a newline
      if (blocks[i + 1].name !== PARAGRAPH_BLOCK_ID) {
        pushParagraphBlock("\n");
      }
    } else {
      pushParagraphBlock();
    }
  }
  return squashed;
};
