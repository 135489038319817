import { observer } from "mobx-react-lite";
import { createElement, useEffect } from "react";
import { useRoute, useLocation, DefaultParams } from "wouter";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { FullScreenLoadingMessage } from "@/components/FullScreenLoadingMessage";
import { reactToURLChange } from "@/data/URLFunctions";
import { useAuth } from "@/data/hooks/AuthProvider";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  children?: React.ReactNode | { (params: DefaultParams | null): any };
  component?: React.FC;
  match?: [];
  path: string;
  privatePath?: boolean;
};

export const AuthRoute: React.FC<Props> = observer(
  ({ children, component, match, path, privatePath }) => {
    const { isAuthenticating, isAuthenticated } = useAuth();
    const [, setLocation] = useLocation();

    const paramMatchesFromUseRoute = useRoute<DefaultParams>(path);
    const [, params] = match || paramMatchesFromUseRoute;

    const gotoErrorPage = (url: string) => {
      history.replaceState(null, "", url);
    };

    useEffect(() => {
      if (viewStates.primary.userLoading) {
        return;
      }
      reactToURLChange(
        path,
        params && {
          journalId: params?.journalId,
          entryId: params?.entryId,
          date: params?.date,
          promptId: params?.promptId,
        },
        !!privatePath,
        d1Classes.userStore,
        d1Classes.journalRepository,
        d1Classes.entryStore,
        d1MainThreadClasses.syncService.sync.bind(
          d1MainThreadClasses.syncService,
        ),
        setLocation,
        viewStates.primary,
        viewStates.calendar,
        gotoErrorPage,
      );
    }, [
      path,
      params?.entryId,
      params?.journalId,
      params?.date,
      params?.promptId,
      viewStates.primary.journals,
      viewStates.primary.userLoading,
    ]);

    // If we're still authenticating or we're authenticated but the journals haven't loaded yet, show a loading screen
    if (
      isAuthenticating ||
      (isAuthenticated && !viewStates.primary.journalsLoaded)
    ) {
      return <FullScreenLoadingMessage message="Loading..." />;
    }

    // React-Router style `component` prop
    if (component) {
      return createElement(component, { ...params });
    }
    return typeof children === "function" ? children(params ?? null) : children;
  },
);
