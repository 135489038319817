import { observer } from "mobx-react-lite";

import { ParticipantName } from "@/components/SharedJournals/ParticipantName";
import { ReactionSummary } from "@/components/SharedJournals/ReactionSummary";
import { PartialReaction } from "@/components/SharedJournals/Reactions";

type Props = {
  creatorId: string | null;
  reactions: PartialReaction[];
  bgColor?: string;
  color?: string;
  invertedText?: boolean;
  className?: string;
};

export const SharedEntryInfoPreview: React.FC<Props> = observer(
  ({
    creatorId,
    reactions,
    bgColor,
    color,
    invertedText = false,
    className,
  }) => {
    return (
      <div
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          mt: 2,
        }}
        className={`shared-entry-info ${className}`}
      >
        {creatorId && <ParticipantName userId={creatorId} size="small" />}
        {reactions.length > 0 && (
          <span
            sx={{
              "&&:before": {
                content: "'·'",
                color: invertedText ? "textSecondaryInverted" : "textSecondary",
                fontSize: 0,
              },
            }}
          />
        )}
        <ReactionSummary
          reactions={reactions}
          bgColor={bgColor}
          color={color}
          invertedText={invertedText}
        />
      </div>
    );
  },
);

SharedEntryInfoPreview.displayName = "SharedEntryInfoPreview";
