import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric, Fingerprint, Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64, uintArrayConcat } from "@/crypto/utils";
import { UserModel } from "@/data/models/UserModel";
import { JournalVault, Key } from "@/data/repositories/Syncables";

export type WrappedVault = {
  vault: JournalVault;
};

export const makeJournalVault = async (
  user: UserModel,
  userPublicKey: CryptoKey,
  userPrivateKey: CryptoKey,
) => {
  const newJournalVaultKey = await makeJournalVaultKey();
  const newGrant = await DOCrypto.Grant.make(
    user.id,
    userPublicKey,
    newJournalVaultKey,
  );
  const vaultKeyFingerprint = newGrant?.vault_key_fingerprint;

  const newKey: Key = await makeNewJournalKey(
    newJournalVaultKey,
    user,
    userPrivateKey,
  );

  const vault = {
    vault: {
      vault_key_fingerprint: vaultKeyFingerprint,
      grants: [newGrant],
      keys: [newKey],
    },
  } as WrappedVault;

  return { vault, key: newJournalVaultKey };
};

const makeJournalVaultKey = async () => {
  const newKeyData = crypto.getRandomValues(new Uint8Array(32)); // 256-bit symmetric key
  return await Symmetric.Key.fromBuffer(newKeyData);
};

export const makeNewJournalKey = async (
  vaultKey: CryptoKey,
  user: UserModel,
  userPrivateKey: CryptoKey,
): Promise<Key> => {
  const newKeyPair = await Asymmetric.generateNewPair();
  const { publicKeyPEM, privateKeyPEM } = newKeyPair;

  const journalKeyFingerprint = await Fingerprint.forPEM(publicKeyPEM);
  const lockedJournalKey = await DOCrypto.D1.encryptNoLockedKey(
    privateKeyPEM,
    vaultKey,
  );
  const journal_signature = await Asymmetric.Private.signArrayBuffer({
    userPrivateKey: newKeyPair.keyPair.privateKey,
    buffer: fromBase64(lockedJournalKey),
  });

  const dataToSign = uintArrayConcat([
    // See https://github.com/bloom/DayOne-Apple/blob/fe95266936656eebb0c65e7add7e554ca68aa823/core/DOCore/DOCore/DOWebEncryptionTypes.swift#L102
    Utf8.toUintArray(publicKeyPEM),
    fromBase64(lockedJournalKey),
  ]);

  const updatedSignature = userPrivateKey
    ? await Asymmetric.Private.signArrayBuffer({
        userPrivateKey,
        buffer: dataToSign,
      })
    : "";

  // See https://github.com/bloom/DayOne-Apple/blob/fe95266936656eebb0c65e7add7e554ca68aa823/core/DOCore/DOCore/DOWebEncryptionTypes.swift#L115
  return {
    fingerprint: journalKeyFingerprint,
    public_key: publicKeyPEM,
    encrypted_private_key: lockedJournalKey,
    journal_signature,
    updated: {
      at: Date.now(),
      by_id: user.id,
      fingerprint: await Fingerprint.forPrivateKey(userPrivateKey),
      signature: updatedSignature,
    },
  };
};
