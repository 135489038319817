import { Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { EnterEncryptionKeyModal } from "@/components/Settings/Account/EncryptionKeyModal";
import { MasterKeyManage } from "@/components/Settings/Account/MasterKeyModal/MasterKeyManage";
import { RetrieveEncryptionKeyModal } from "@/components/Settings/Account/MasterKeyModal/RetrieveEncryptionKeyModal";
import { JournalStore } from "@/data/stores/JournalStore";
import { useGoogleDrive } from "@/hooks/google/useGoogleDrive";
import { EncryptionModalOpenMethods } from "@/layouts/ModalRoutes";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";
import { masterKeyViewState } from "@/view_state/ViewStates";

type Props = {
  journalStore: JournalStore;
  primaryViewState: PrimaryViewState;
  snackbar: SnackbarViewState;
  openedFrom: EncryptionModalOpenMethods;
  isCreatingSharedJournal: boolean;
  agreedToCreateKey: boolean;
  hasDeniedKeyRetrieval: boolean;
  onClose: (masterKeyString?: string | null) => void;
};

export const MasterKeyModal = observer(
  ({
    primaryViewState: primary,
    snackbar,
    onClose: closeModal,
    openedFrom,
    isCreatingSharedJournal,
    agreedToCreateKey,
    hasDeniedKeyRetrieval,
  }: Props) => {
    const { loading: googleDriveLoading } = useGoogleDrive(primary);

    const hasUserKeys = primary.userEncryptionKeyExists;

    const { __ } = useI18n();

    const onClose = () => closeModal(primary.masterKeyString);

    // This is a hack to prevent a race condition where the master key
    // has been created and saved to the server but hasn't fully entered
    // all the stores yet which causes the enterKeyModal to flash before
    // going back to the <MasterKeyManage>
    const [savingMasterKey, setSavingMasterKey] = useState(false);

    if (hasUserKeys && googleDriveLoading) {
      return (
        <D1Modal
          sx={{
            height: "unset !important",
            borderRadius: "12px !important",
            my: "auto",
            mx: "auto",
            maxWidth: "300px",
          }}
          title={__("Finding Encryption Key")}
          onRequestClose={onClose}
        >
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              ".components-spinner": {
                width: "100px",
                height: "100px",
              },
            }}
          >
            <Spinner css={{ width: "500px" }} />
          </div>
        </D1Modal>
      );
    }

    // if we know they have backed up their key to Google or CloudKit we can direct them to retrieve from there,
    // but they can bypass this with `hasDeniedKeyRetrieval`
    if (
      !savingMasterKey &&
      !primary.masterKeyString &&
      masterKeyViewState.hasKeyBackedUpRemotely &&
      !hasDeniedKeyRetrieval
    ) {
      return (
        <RetrieveEncryptionKeyModal
          handleClose={onClose}
          openedFrom={openedFrom}
          isCreatingSharedJournal={isCreatingSharedJournal}
        />
      );
    }

    // next we check if they have a key and if they do we ask them to enter it
    if (!savingMasterKey && hasUserKeys && !primary.masterKeyString) {
      return (
        <EnterEncryptionKeyModal
          snackbar={snackbar}
          onClose={onClose}
          openedFrom={openedFrom}
          isCreatingSharedJournal={isCreatingSharedJournal}
        />
      );
    }

    // generate master key or backup master key
    return (
      <MasterKeyManage
        onClose={onClose}
        agreedToCreateKey={agreedToCreateKey}
        setSavingMasterKey={setSavingMasterKey}
        isCreatingSharedJournal={isCreatingSharedJournal}
      />
    );
  },
);
