export const Tag = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.86328 12.4805C7.02539 13.3184 6.16406 13.3301 5.30273 12.4746L1.59375 8.77148C0.732422 7.91602 0.75 7.04883 1.58789 6.21094L7.31836 0.492188C7.75781 0.0527344 8.00391 0.0234375 8.61914 0.0234375H10.8516C11.4727 0.0234375 11.666 0.175781 12.1055 0.615234L13.4531 1.96289C13.8984 2.4082 14.0449 2.60156 14.0449 3.22266V5.44922C14.0449 6.06445 14.0156 6.31641 13.5762 6.75586L7.86328 12.4805ZM7.23047 11.7715L12.9141 6.08789C13.0312 5.9707 13.1016 5.84766 13.1016 5.6543V3.18164C13.1016 3 13.0371 2.87695 12.9141 2.75391L11.3145 1.16016C11.1914 1.03711 11.0684 0.966797 10.8867 0.966797H8.41992C8.2207 0.966797 8.09766 1.04297 7.98047 1.16016L2.29102 6.83789C1.875 7.25391 1.86914 7.70508 2.29688 8.13281L5.93555 11.7656C6.36328 12.1992 6.80859 12.1934 7.23047 11.7715ZM10.166 4.71094C9.70312 4.71094 9.36328 4.35352 9.36328 3.9082C9.36328 3.45703 9.70312 3.10547 10.166 3.10547C10.623 3.10547 10.9688 3.45703 10.9688 3.9082C10.9688 4.35352 10.623 4.71094 10.166 4.71094Z" />
    </svg>
  );
};
