import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { Icon, warning } from "@wordpress/icons";

import { D1Modal } from "@/components/D1Modal";

type Props = {
  onClose: () => void;
  onAlreadySaved: () => void;
};

export const BackupWarningModal: React.FC<Props> = ({
  onClose,
  onAlreadySaved,
}) => {
  const closeWarning = () => {};
  const title = __("Back Up Your Encryption Key");

  return (
    <D1Modal
      title={title}
      onRequestClose={closeWarning}
      hideHeader={true}
      isDismissible={false}
      sx={{
        maxWidth: "420px",
        "& .components-modal__content": {
          pt: 0,
        },
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          "& svg": {
            mx: "auto",
            mt: 0,
            mb: 3,
          },
        }}
      >
        <Icon icon={warning} sx={{ fill: "textPrimary" }} size={72} />
        <h1 sx={{ fontSize: 5, px: 7, mb: 4 }}>{title}</h1>
        <p sx={{ px: 4, lineHeight: "body", fontSize: 1 }}>
          {__(
            "It looks like you haven't backed up your encryption key yet. Without your key, you could lose access to your journals.",
          )}
        </p>
      </div>
      <div
        sx={{
          mt: 8,
          "& button": {
            width: "100%",
            display: "block",
            my: 3,
          },
        }}
      >
        <Button variant="primary" onClick={onClose}>
          {__("Back Up My Key Now")}
        </Button>
        <Button variant="tertiary" onClick={onAlreadySaved}>
          {__("I Already Saved My Key")}
        </Button>
      </div>
    </D1Modal>
  );
};
