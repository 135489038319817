export const CheckboxChecked = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15 10.4L11.1167 6.45L10.2667 5.6L7.15 8.7L5.73333 7.3L4.88333 8.15L7.15 10.4ZM3.6 13.6C3.27 13.6 2.9875 13.4825 2.7525 13.2475C2.5175 13.0125 2.4 12.73 2.4 12.4V3.6C2.4 3.27 2.5175 2.9875 2.7525 2.7525C2.9875 2.5175 3.27 2.4 3.6 2.4H12.4C12.73 2.4 13.0125 2.5175 13.2475 2.7525C13.4825 2.9875 13.6 3.27 13.6 3.6V12.4C13.6 12.73 13.4825 13.0125 13.2475 13.2475C13.0125 13.4825 12.73 13.6 12.4 13.6H3.6Z"
        fill="currentColor"
      />
    </svg>
  );
};
