export const RainSnow: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0781 6.689C16.5161 6.689 15.9861 6.805 15.5231 7.036C15.1761 4.753 13.1911 3 10.8091 3C8.21209 3 6.09509 5.084 6.02909 7.665C4.47409 7.863 3.26709 9.187 3.26709 10.824C3.26609 12.577 4.68909 14 6.44209 14H17.0781C19.0961 14 20.7341 12.362 20.7341 10.344C20.7341 8.326 19.0961 6.689 17.0781 6.689V6.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17L17.5 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 20L8.5 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99512 16.995C5.99212 16.995 5.99012 16.997 5.99012 17C5.99012 17.003 5.99212 17.005 5.99512 17.005C5.99812 17.005 6.00012 17.003 6.00012 17C6.00012 16.997 5.99812 16.995 5.99512 16.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9951 16.995C11.9921 16.995 11.9901 16.997 11.9901 17C11.9901 17.003 11.9921 17.005 11.9951 17.005C11.9981 17.005 12.0001 17.003 12.0001 17C12.0001 16.997 11.9981 16.995 11.9951 16.995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 19.9901C13.997 19.9901 13.995 19.9921 13.995 19.9951C13.995 19.9981 13.997 20.0001 14 20.0001C14.003 20.0001 14.005 19.9981 14.005 19.9951C14.005 19.9921 14.003 19.9901 14 19.9901"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
