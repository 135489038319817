import { RTJNode, SuggestionTypes } from "types/rtj-format";

import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  isEmbeddableContentNode,
  isSuggestionType,
} from "@/components/Editor/rtj2gb/rtj-type-checks";
import {
  Activity,
  EntryDBRow,
  GlobalEntryID,
} from "@/data/db/migrations/entry";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { EntryModel } from "@/data/models/EntryModel";
import {
  isEntryMoveSendable,
  isEntrySendable,
  isOriginalMediaSendable,
  Outbox,
} from "@/data/models/Outbox";
import {
  EntryMoveSendable,
  EntrySendable,
  OutboxResult,
} from "@/data/models/OutboxTypes";
import {
  CreateNewEntryOpts,
  EntryRepository,
} from "@/data/repositories/EntryRepository";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import {
  JournalSyncStatus,
  SyncStateRepository,
} from "@/data/repositories/SyncStateRepository";
import { JournalStore } from "@/data/stores/JournalStore";
import { MomentStore } from "@/data/stores/MomentStore";
import { hasPrefilledContent } from "@/utils/entry";
export interface EntryPreviewMediaItem {
  id: string;
  type: string;
  moment: MomentDBRow | null;
}

export class EntryStore {
  constructor(
    private entryRepository: EntryRepository,
    private momentStore: MomentStore,
    private momentRepository: MomentRepository,
    private getJournalStore: () => JournalStore,
    private outbox: Outbox,
    private syncStateRepo: SyncStateRepository,
  ) {}

  public makeEntryModel(data: EntryDBRow, moments?: MomentDBRow[]) {
    return new EntryModel(data, moments);
  }

  /*
  SUBS ------------------------------------------------------------------------
  */

  subToCount(journalId: string, callback: (count: number) => void) {
    return this.entryRepository.subscribeToCount(journalId, callback);
  }

  subToEntry(
    globalEntryID: GlobalEntryID,
    callback: (entry: EntryModel | null) => void,
  ) {
    return this.entryRepository.subscribeToEntry(
      globalEntryID,
      ({ entry, moments }) => {
        const entryModel = entry ? this.makeEntryModel(entry, moments) : null;
        callback(entryModel);
      },
    );
  }

  /*
  ASYNC -----------------------------------------------------------------------
  */

  async getEntryById(journalId: string, entryId: string) {
    const entry = await this.entryRepository.getEntryById({
      journalId,
      entryId,
    });
    if (entry) {
      return this.makeEntryModel(entry);
    }
    return null;
  }

  async persistEntry(entry: EntryModel, oldContents?: RTJNode[]) {
    if (!entry.isChanged) {
      return;
    }
    try {
      const x = await this.entryRepository.updateEntryLocal(
        entry.journalId,
        entry.id,
        entry.richTextJSON.contents,
        entry.body,
        entry.date,
        entry.isAllDay,
        entry.editDate,
        entry.weather,
        entry.templateID,
        entry.promptID,
        entry.isStarred,
        entry.isPinned,
        oldContents,
        entry.activity,
      );
      const sendable: EntrySendable = {
        id: `${entry.journalId}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journalId,
      };

      this.outbox.add(sendable);

      // Reseting the Entry's changed state to make sure it's not persisted through
      // multiple edits
      entry.resetChangedState();

      return x;
    } catch (e: unknown) {
      Sentry.captureException(e);
    }
  }

  async createNewEntry(
    journalId: string,
    entryDate: Date,
    opts?: CreateNewEntryOpts,
  ) {
    const entry = await this.entryRepository.createNew(
      journalId,
      entryDate,
      opts,
    );

    if (entry && !hasPrefilledContent(opts)) {
      const sendable: EntrySendable = {
        id: `${entry.journal_id}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journal_id,
      };
      this.outbox.add(sendable);
    }
    return this.makeEntryModel(entry);
  }

  async importEntry(entry: EntryDBRow, contents: RTJNode[], tags: string[]) {
    const savedEntry = await this.entryRepository.importEntry(
      entry,
      contents,
      tags,
    );
    if (savedEntry) {
      const sendable: EntrySendable = {
        id: `${entry.journal_id}-${entry.id}`,
        type: "Entry",
        action: "UPDATE",
        entryId: entry.id,
        journalId: entry.journal_id,
      };
      this.outbox.add(sendable);
    }
    return this.makeEntryModel(entry);
  }

  async deleteEntry(entry: EntryModel) {
    // If we successfully delete the entry:
    const success = await this.entryRepository.deleteById(
      entry.id,
      entry.journalId,
    );
    if (success) {
      await this.outbox.removeItemsForEntry(entry.journalId, entry.id);

      const sendable: EntrySendable = {
        id: `${entry.journalId}-${entry.id}`,
        type: "Entry",
        action: "DELETE",
        entryId: entry.id,
        journalId: entry.journalId,
      };
      this.outbox.add(sendable);
      await this.momentRepository.markAsDeletedForEntry(
        entry.journalId,
        entry.id,
      );
    }
    return success;
  }

  async deleteEntryFromServer(entryId: string, journalId: string) {
    const response = await this.entryRepository.pushEntryDelete(
      entryId,
      journalId,
    );
    if (response.result === "success") {
      // Then delete associated moments, too
      await this.momentStore.deleteForEntry(journalId, entryId);
    }
    return response;
  }

  async updateEntryDate(entry: EntryModel, date: Date) {
    entry.updateDate(date);
    return this.persistEntry(entry);
  }

  async toggleAllDay(entry: EntryModel, date: Date) {
    entry.toggleAllDay();
    entry.updateDate(date);
    return this.persistEntry(entry);
  }

  async updatedEntryTags(entry: EntryModel) {
    entry.updatedTags();
    return this.persistEntry(entry);
  }

  async updateEntryTemplateInfo(entry: EntryModel, templateID: string) {
    entry.updateTemplateID(templateID);
    return this.persistEntry(entry);
  }

  async toggleFavorite(entry: EntryModel) {
    entry.toggleFavorite();
    return this.persistEntry(entry);
  }

  async updateActivity(entry: EntryModel, activity: Activity) {
    entry.updateActivity(activity);
    return this.persistEntry(entry);
  }

  async togglePinned(entry: EntryModel) {
    entry.togglePinned();
    return this.persistEntry(entry);
  }

  async updateEntryContents(
    entry: EntryModel,
    editDate: number,
    markdown: string,
    rtjNodes: RTJNode[],
    templateID?: string,
  ) {
    const oldContents = entry.richTextJSON.contents;
    entry.updateContents(markdown, rtjNodes, editDate, /*tags,*/ templateID);

    await Promise.all([
      this.persistEntry(entry, oldContents),
      this.momentRepository.updateMomentDeletionFromRTJ(
        entry.journalId,
        entry.id,
        rtjNodes,
      ),
    ]);
  }

  async isE2E(entry: EntryModel) {
    const j = await this.getJournalStore().isJournalE2EE(entry.journalId);
    if (j == null) {
      Sentry.captureException(
        new Error(
          `Journal ${entry.journalId} not found when checking if entry is E2EE`,
        ),
      );
    }
    return !!j;
  }

  async markEntryRead(globalEntryID: GlobalEntryID, unreadMarkerId: string) {
    return this.entryRepository.markEntryRead(globalEntryID, unreadMarkerId);
  }

  /*
    SYNC ------------------------------------------------------------------------
  */

  async updateSyncStatus(
    journalId: string,
    status: JournalSyncStatus,
    hasCompleted?: boolean,
  ) {
    this.syncStateRepo.setJournalSyncStatus(journalId, status, hasCompleted);
  }

  async getSyncIds() {
    return this.syncStateRepo.getIdsForSyncingJournals();
  }

  async syncOneJournal(id: string) {
    try {
      await this.updateSyncStatus(id, "SYNCING");
      await this.entryRepository.synchronizeByJournalId(id);
      await this.updateSyncStatus(id, "IDLE", true);
    } catch (e: any) {
      Sentry.captureException(e);
      await this.updateSyncStatus(id, "ERROR");
    }
  }

  async sync(excludeJournalId?: string): Promise<void> {
    // First check if there's a journal that's waiting for initial sync
    const waitingJournalId =
      await this.syncStateRepo.getIdForNextQueuedInitialSync();
    // If so, do that and recurse.
    if (waitingJournalId) {
      await this.syncOneJournal(waitingJournalId);
      // Passing the waitingJournalId to avoid syncing it again below
      return this.sync(waitingJournalId);
    }
    // If not, sync all existing IDs and don't recurse.
    const journalIds = await this.syncStateRepo.getIdsForSyncingJournals();
    if (journalIds.length) {
      // Sync journals in sequence
      for (const id of journalIds) {
        // This avoids syncing the same journal twice on initial sync
        if (id === excludeJournalId) {
          continue;
        }
        await this.syncOneJournal(id);
      }
    }
  }

  // Only entries read remotely need to be updated locally
  // If an entry came with a new unread marker it will be because it
  // has been updated remotely and in that case the new entry revision
  // will already have the correct unread marker.
  async syncUnread(): Promise<void> {
    const entriesFromDB = (await this.entryRepository.getUnreadEntries()).map(
      (entry) => ({ journal_id: entry.journal_id, id: entry.id }),
    );
    const entriesFromApi = (await this.entryRepository.syncUnread()).map(
      (unreadEntry: { id: string; journal_id: string; entry_id: string }) => ({
        journal_id: unreadEntry.journal_id,
        id: unreadEntry.entry_id,
      }),
    ) as GlobalEntryID[];

    const entriesToMarkRead = entriesFromDB.reduce(
      (acc: GlobalEntryID[], globalEntryId) => {
        if (
          !entriesFromApi.find(
            (e) =>
              e.id === globalEntryId.id &&
              e.journal_id === globalEntryId.journal_id,
          )
        ) {
          acc.push(globalEntryId);
        }
        return acc;
      },
      [],
    );
    await this.entryRepository.bulkUpdateUnreadMarker(entriesToMarkRead);
  }

  async subToSync(
    callback: (status: Record<string, JournalSyncStatus>) => void,
  ) {
    return this.syncStateRepo.subscribeToJournalSyncStatuses(callback);
  }

  subscribeToUnreadByJournal(
    callback: (hasUnread: boolean) => void,
    journalId: string,
  ) {
    return this.entryRepository.subscribeToUnreadByJournal(callback, journalId);
  }

  async pushEntryToServer(
    journalId: string,
    entryId: string,
  ): Promise<OutboxResult> {
    return this.entryRepository
      .syncEntryUp(journalId, entryId)
      .then((response) => {
        if (analytics) {
          analytics.tracks.recordEvent(EVENT.entryUpload, {
            entry_id: entryId,
            status: response.result,
          });
        }
        return response;
      });
  }

  removeJournalFromSyncJobs(journalId: string) {
    return this.syncStateRepo.disableSyncForJournal(journalId);
  }

  public addJournalToSyncJobs(journalId: string) {
    return this.syncStateRepo.enableSyncForJournal(journalId);
  }

  public getOrderedMomentsAndSuggestions = async (
    entry: EntryModel,
    useMarkdown?: boolean,
  ) => {
    const orderedMoments: {
      audio: EntryPreviewMediaItem[];
      favorites: EntryPreviewMediaItem[];
      suggestions: EntryPreviewMediaItem[];
      rest: EntryPreviewMediaItem[];
    } = {
      audio: [],
      favorites: [],
      suggestions: [],
      rest: [],
    };

    const isRTJEntry = !useMarkdown && entry?.richTextJSON.contents.length > 0;

    const addToSortedMoments = (
      id: string,
      type: string,
      moment: MomentDBRow | null,
    ) => {
      const mediaNode = {
        id,
        type,
        moment,
      };

      if (orderedMoments.audio.length < 1 && moment?.type === "audio") {
        orderedMoments.audio.push(mediaNode);
      } else if (moment?.favorite) {
        orderedMoments.favorites.push(mediaNode);
      } else if (isSuggestionType(mediaNode.type as SuggestionTypes)) {
        orderedMoments.favorites.push(mediaNode);
      } else {
        orderedMoments.rest.push(mediaNode);
      }
    };
    if (isRTJEntry) {
      for (const content of entry.entryContents) {
        if (isEmbeddableContentNode(content)) {
          for (const node of content.embeddedObjects) {
            if (node.identifier) {
              // if its audio,podcast or contact we push the artwork/photo id
              //if its any other suggestion we push the identifier
              const id =
                node.type === "song" || node.type === "podcast"
                  ? node.artworkIdentifier
                  : node.type === "contact"
                    ? node.photoIdentifier
                    : node.identifier;

              // If the the node is a suggestion without thumbnail it won't have a moment
              const moment = await this.momentRepository.getMomentById(
                entry.journalId,
                entry.id,
                id,
              );
              addToSortedMoments(id, node.type, moment);
            }
          }
        }
      }
    }
    // If it's not RTJ we are processing a Markdown Entry.
    else {
      const moments = await this.entryRepository.getEntryMomentsFromBody(entry);
      if (moments) {
        for (const moment of moments) {
          addToSortedMoments(moment.id, moment.type, moment);
        }
      }
    }

    return [
      ...orderedMoments.audio,
      ...orderedMoments.favorites,
      ...orderedMoments.suggestions,
      ...orderedMoments.rest,
    ];
  };

  // move journal locally
  public async moveToJournal(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
    fail = false,
  ) {
    const { shouldAddToOutbox, moved } =
      await this.entryRepository.moveEntryToJournal(
        entryId,
        sourceJournalId,
        destinationJournalId,
      );

    /**
     * In order to keep Updates working after we move an entry we need to make sure the move is done
     * before any pending updates are sent to the server.
     *
     * To achieve this, we need to:
     * 1. Get the existing outbox items
     * 2. Remove the outbox items so we can add them after the move item
     * 2. Add the move to the outbox
     * 3. Add the outbox items back, with the new journalId
     */
    // Get any existing Outbox Items with the source journalId
    const outboxItems = await this.outbox.getItemsForEntry(
      sourceJournalId,
      entryId,
    );
    // Remove the outbox items so we can add them after the move item
    await this.outbox.removeItemsForEntry(sourceJournalId, entryId);

    // Make sure we need to add the entry move to the outbox
    if (shouldAddToOutbox) {
      // Check if there's an existing entry move in the outbox and, if it exists
      // use that as the source journalId
      const oldEntryMove = await this.outbox.getExistingEntryMove(entryId);
      const journalId = oldEntryMove?.journalId ?? sourceJournalId;

      // Create the Move Entry Sendable
      const sendable: EntryMoveSendable = {
        id: `${journalId}-${destinationJournalId}-${entryId}`,
        type: "Entry",
        action: "MOVE",
        entryId,
        journalId,
        destinationJournalId,
        _testFailMove: fail,
      };

      // If the entry move already exists, update it with the new destination journalId
      if (oldEntryMove) {
        await this.outbox.updateExistingEntryMove(sendable, oldEntryMove);
      } else {
        // Otherwise, add the new entry move to the outbox
        await this.outbox.add(sendable);
      }
    }

    // Finally, add all the old outbox items back with the new journalId
    // This will have a more recent timestamp than the entry move, so will be processed afterwards
    outboxItems?.forEach((item) => {
      const hasJournalId =
        isOriginalMediaSendable(item) || isEntrySendable(item);
      const shouldSkip = isEntryMoveSendable(item);

      const updatedItem = {
        ...item,
        id: `${destinationJournalId}-${entryId}`,
      };
      if (hasJournalId && !shouldSkip) {
        updatedItem.journalId = destinationJournalId;
      }
      this.outbox.add(updatedItem);
    });

    return moved;
  }

  public async pushEntryMove(
    entryId: string,
    sourceJournalId: string,
    destinationJournalId: string,
    fail = false,
  ) {
    const entryMoveReponse = await this.entryRepository.pushEntryMove(
      entryId,
      sourceJournalId,
      destinationJournalId,
      fail,
    );

    return entryMoveReponse;
  }
}
