import { DODexie } from "@/data/db/dexie_db";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { ReactionDBRow } from "@/data/db/migrations/reaction";
import { TagDBRow } from "@/data/db/migrations/tag";
import { momentsAreEqual } from "@/data/repositories/MomentRepository";
import { syncOperations_removeEntriesAndAssociatedStuff } from "@/data/repositories/SyncOperations_RemoveEntries";
import {
  Entry,
  EntryRevisionData,
  EntryWithFeedRecord,
} from "@/data/repositories/V2API";
import { momentDBRowFromFeed } from "@/data/utils/converters";

/**
 * This class is responsible for operations (in response to sync)
 * that involve multiple tables and don't fit into any one repository.
 *
 * @class
 */
export class SyncOperationsRepository {
  constructor(private db: DODexie) {}

  /**
   * Removes the local entry rows and everything that's associated with them
   * when we get a chunk of entry deletes from the feed. This is necessary
   * because we don't have cascading deletes in IndexedDB.
   *
   * @returns {Promise<void>}
   */
  async removeEntriesAndAssociatedStuff(
    globalIds: GlobalEntryID[],
  ): Promise<void> {
    syncOperations_removeEntriesAndAssociatedStuff(this.db, globalIds);
  }

  async updateMomentsFromEntrySync(
    updatesPerEntry: MomentUpdatesFromSyncPerEntry[],
  ) {
    if (updatesPerEntry.length === 0) {
      return [];
    }

    const modifiedEntryIDs = updatesPerEntry.map((e) => [
      e.journal_id,
      e.entry_id,
    ]);

    const incomingMoments: Array<MomentDBRow> = updatesPerEntry.flatMap(
      (e) => e.moments,
    );

    // Open a rw transaction since we're about to do a read and a filter
    // based on the read contents.
    await this.db.transaction("rw", this.db.moments, async () => {
      const existingMoments = await this.db.moments
        .where(["journal_id", "entry_id"])
        .anyOf(modifiedEntryIDs)
        .toArray();

      // Even though we drop all moments no longer in the moment arrays for
      // incoming entries, we want to keep moments that were deleted locally
      // in case the user wants to undo a moment deletion.
      const momentsToKeepForUndo = existingMoments.filter(
        (m) => !!m._local_deleted && m._local_deleted > Date.now() - 86400000,
      );

      // Delete all moments pertaining to the modified entries. We'll re-insert
      // the ones that are still present in the incoming entries or that were
      // deleted locally.
      await this.db.moments
        .where(["journal_id", "entry_id"])
        .anyOf(modifiedEntryIDs)
        .delete();

      const momentsToReinsert = incomingMoments.reduce((acc, moment) => {
        // We track moment uploads with `_local` fields that would get ovedrriden
        // if there were a change to an entry after edit but before moment upload.
        // Here we make sure to pick up those local values before re-inserting.
        const momentToInsert = moment;
        const matchingExistingMoment = existingMoments.find((m) =>
          momentsAreEqual(m, momentToInsert),
        );
        if (
          matchingExistingMoment &&
          !!matchingExistingMoment._local_created_locally
        ) {
          // Copy relevant local fields from the existing moment to the incoming moment
          momentToInsert._local_created_locally =
            matchingExistingMoment._local_created_locally;
          momentToInsert._local_original_uploaded =
            matchingExistingMoment._local_original_uploaded;
          momentToInsert._local_thumbnail_uploaded =
            matchingExistingMoment._local_thumbnail_uploaded;
        }
        acc.push(momentToInsert);
        return acc;
      }, momentsToKeepForUndo);

      await this.db.moments.bulkPut(momentsToReinsert);
    });
  }

  async updateTagsFromEntrySync(
    updates: Array<Entry & { journal_id: string }>,
  ) {
    if (updates.length === 0) {
      return [];
    }

    const incomingTags: TagDBRow[] = updates.flatMap((e) => {
      if (e.tags?.length === 0) {
        return [];
      }
      return (
        e.tags?.map((t) => ({
          tag: t,
          journal_id: e.journal_id,
          entry_id: e.id,
        })) ?? []
      );
    });

    // Delete all tags for the given entry IDs and reinsert the ones from the feed.
    // This removes tags that are no longer associated with the entry.
    await this.db.tags
      .where(["journal_id", "entry_id"])
      .anyOf(updates.map((u) => [u.journal_id, u.id]))
      .delete();

    await this.db.tags.bulkPut(incomingTags);
  }

  async updateReactionsFromEntrySync(revisions: EntryRevisionData[]) {
    if (revisions.length === 0) {
      return [];
    }

    const incomingReactions: ReactionDBRow[] = revisions.flatMap(
      (e) =>
        e.reactions?.map((r) => ({
          id: r.id,
          user_id: r.byUser,
          reaction: r.reaction,
          journal_id: e.journalId,
          entry_id: e.entryId,
        })) ?? [],
    );

    // Delete all tags for the given entry IDs and reinsert the ones from the feed.
    // This removes tags that are no longer associated with the entry.
    await this.db.reactions
      .where(["journal_id", "entry_id"])
      .anyOf(revisions.map((u) => [u.journalId, u.entryId]))
      .delete();

    await this.db.reactions.bulkPut(incomingReactions);
  }
}

// This type represents information about moments that are coming in from sync. The moments
// array may be empty, in which case we should delete all moments for that entry. This case
// is why we don't just pass an array of moments to updateMomentsFromEntrySync. We need to
// know about moment updates for every entry that was changed.
export interface MomentUpdatesFromSyncPerEntry {
  journal_id: string;
  entry_id: string;
  moments: MomentDBRow[];
}

/**
 * Convert incoming entry updates to an object that we can use to easily update our local state.
 * @param entry The coupling of decrypted entry body and envelope/revision from the feed.
 * @returns A MomentUpdatesFromSyncPerEntry if the entry isn't a delete. Otherwise null.
 */
export function makeMomentUpdateFromEntryInFeed(
  thing: EntryWithFeedRecord,
): MomentUpdatesFromSyncPerEntry | null {
  if (!thing.entry) {
    return null;
  }
  const journalId = thing.record.revision.journalId;
  const entryId = thing.record.revision.entryId;
  // Build a list of pairings of moments from the entry body with their equivalent
  // moment from the envelope so we can generate a list of moment DB rows.
  const momentPairs = (thing.entry.moments ?? []).map((momentFromBody) => {
    return {
      body: momentFromBody,
      envelope: thing.record.revision.moments.find(
        (m) => m.id === momentFromBody.id,
      ),
    };
  });
  const dbRows = momentPairs.map((pair) =>
    momentDBRowFromFeed(journalId, entryId, pair.body, pair.envelope),
  );
  return { journal_id: journalId, entry_id: entryId, moments: dbRows };
}
