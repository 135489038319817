import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Dropdown } from "@/components/D1Dropdown";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { primaryViewState } from "@/view_state/ViewStates";

type AvatarMenuProps = {
  showEditPhoto: (show: boolean) => void;
  showChooseColor: (show: boolean) => void;
  showEditInitials: (show: boolean) => void;
};

export const AvatarMenu: React.FC<AvatarMenuProps> = observer(
  ({ showEditPhoto, showChooseColor, showEditInitials }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const { __ } = useI18n();

    const hasAvatar = !!primaryViewState.user?.avatar;

    return (
      <>
        <D1Dropdown
          popoverProps={{ placement: "bottom-end" }}
          renderToggle={({ isOpen, onToggle }) => (
            <EditLinkButton
              onClick={onToggle}
              label={__("Edit Avatar")}
              aria-expanded={isOpen}
            />
          )}
          sx={{
            "& .components-popover__content > div": {
              p: 0,
            },
            "& .components-popover__content": {
              borderWidth: 1,
              borderColor: "borderPrimary",
              borderRadius: "sm",
              boxShadow: "shadow2",
              padding: 0,
              marginTop: "-2",
              outline: "none",
            },
          }}
          renderContent={({ onClose }) => (
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "textSecondary",
                fontSize: 1,
                backgroundColor: "surface_light1_dark4",
                width: "260px",
              }}
            >
              {
                <>
                  <div
                    sx={{
                      padding: 3,
                      "&& button": {
                        mt: 1,
                        color: "var(--theme-ui-color-editorTextMuted)",
                      },
                    }}
                  >
                    <MenuButton
                      onClick={() => {
                        onClose();
                        showChooseColor(true);
                      }}
                    >
                      {__("Choose Color")}
                    </MenuButton>
                    <MenuButton
                      onClick={() => {
                        onClose();
                        showEditInitials(true);
                      }}
                    >
                      {__("Edit Text")}
                    </MenuButton>
                    <MenuButton
                      onClick={() => {
                        onClose();
                        showEditPhoto(true);
                      }}
                    >
                      {__("Choose Photo...")}
                    </MenuButton>
                    {hasAvatar && (
                      <MenuButton
                        isDestructive
                        onClick={() => {
                          onClose();
                          setShowConfirm(true);
                        }}
                      >
                        {__("Remove Photo")}
                      </MenuButton>
                    )}
                  </div>
                </>
              }
            </div>
          )}
        />
        {showConfirm && (
          <ConfirmDialog
            handleClose={() => setShowConfirm(false)}
            handleAction={() => {
              d1Classes.userStore.removeAvatar();
              setShowConfirm(false);
            }}
            message={__("Are you sure you want to delete your avatar?")}
          />
        )}
      </>
    );
  },
);

AvatarMenu.displayName = "AvatarMenu";
