import { gzipSync } from "fflate";

import { binaryHeader } from "@/crypto/DOCrypto/D1/d1BinaryHeader";
import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { uintArrayConcat, fromHex } from "@/crypto/utils";
import { md5 } from "@/crypto/utils/md5";

export const encryptD1WithLockedKey = async (
  data: Uint8Array,
  fingerprint: string,
  publicKey: string,
  format: 1 | 2,
) => {
  const binaryFormat = new Uint8Array([format]);

  const newKey = await Symmetric.Key.new();
  const newKeyData = await Symmetric.Key.toUintArray(newKey);
  const publicKeyFingerprint = fromHex(fingerprint);
  const encryptingPublicKey = await Asymmetric.Public.fromPEM(publicKey);
  const encryptedLockedKey = await Asymmetric.Public.encrypt(
    encryptingPublicKey,
    newKeyData,
  );

  const needsEncrypted = format === 2 ? gzipSync(data) : data;

  const { iv, encrypted } = await Symmetric.encryptToUint8ArrayWithIV(
    newKey,
    needsEncrypted,
  );

  const d1 = uintArrayConcat([
    ...binaryHeader,
    binaryFormat, // Binary format is one of 0 | 1 | 2 we only deal with 1 and 2 here
    publicKeyFingerprint,
    new Uint8Array([0, 0]), // Signature length 0 to ignore the signature as we determined it isn't useful
    new Uint8Array(encryptedLockedKey),
    iv,
    encrypted, // Encrypted content and gcmTag. Last 16 Bytes are the gcmTag
  ]);

  const hash = fromHex(await md5(d1));
  return uintArrayConcat([d1, hash]);
};
