import { useMoment } from "@/data/hooks/moments";
import { dayOneBlue } from "@/styles/theme";

export type BlobAudioProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  isSelected: boolean;
};

// Display a syncable audio by MD5
export const SyncableAudio: React.FC<BlobAudioProps> = ({
  journalId,
  entryId,
  clientId,
  isSelected,
}) => {
  const { blob, moment, isLoading } = useMoment(journalId, entryId, clientId);
  if (isLoading) {
    return <div>Loading audio...</div>;
  } else if (blob) {
    const blobType = blob.type === "audio/m4a" ? "audio/x-m4a" : blob.type;
    return (
      <audio
        controls
        title={moment?.data.metadata?.title || "Audio"}
        sx={{
          border: "3px solid",
          borderColor: isSelected ? dayOneBlue : "transparent",
          borderRadius: "30px",
        }}
      >
        <source src={URL.createObjectURL(blob)} type={blobType} />
      </audio>
    );
  }

  return null;
};
