import { __experimentalHeading as WPHeading } from "@wordpress/components/";

type Props = {
  children: React.ReactNode;
  id?: string;
};

export const GroupHeading: React.FC<Props> = ({ children, id }) => {
  return (
    <WPHeading
      id={id}
      level={4}
      sx={{
        color: "textPrimary",
        fontSize: 1,
        lineHeight: "24px",
        fontWeight: "medium",
        mb: 1,
        mt: 3,
        ml: 3,
      }}
    >
      {children}
    </WPHeading>
  );
};
