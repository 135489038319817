import { Button } from "@wordpress/components";
import { __, sprintf, _n } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import {
  getRTJPreview,
  getEntryPreview,
} from "@/components/EditorPanel/GutenbergEntryTools";
import { JournalName } from "@/components/Notifications/JournalName";
import { Message } from "@/components/Notifications/Message";
import { Avatar } from "@/components/SharedJournals/Avatar";
import { getMappedReactions } from "@/components/SharedJournals/Reaction";
import { Haha } from "@/components/icons/Haha";
import { Wow } from "@/components/icons/Wow";
import { CommentIcon } from "@/components/icons/notifications/CommentIcon";
import { DeleteIcon } from "@/components/icons/notifications/DeleteIcon";
import { EntryIcon } from "@/components/icons/notifications/EntryIcon";
import { JoinedIcon } from "@/components/icons/notifications/JoinedIcon";
import { LeftIcon } from "@/components/icons/notifications/LeftIcon";
import { TransferIcon } from "@/components/icons/notifications/TransferIcon";
import { getEntryURL, getSingleJournalURL } from "@/data/URLFunctions";
import { Notification } from "@/data/db/migrations/notification";
import { ReactionType } from "@/data/db/migrations/reaction";
import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";
import { sanitizeText } from "@/utils/strings";
import { switchOnRecordEvent } from "@/utils/switching";
import {
  modalRouterViewState,
  primaryViewState,
  userSettingsViewState,
  viewStates,
} from "@/view_state/ViewStates";

export const NOTIFICATION_ROW_HEIGHT = "80px";

type NotificationsItemProps = {
  notification: Notification;
  isLast: boolean;
};

type NotificationDetails = {
  userid: string;
  message: string;
  action: () => void;
  title: string;
  iconName?:
    | ReactionType
    | "joined"
    | "left"
    | "entry"
    | "delete"
    | "comment"
    | "transferOffer"
    | "transferComplete";
  type?: "journal" | "entry";
};

const reactionWords: { [key in ReactionType]: string } = {
  like: __("Like"),
  love: __("Love"),
  care: __("Care"),
  haha: __("HaHa"),
  wow: __("Wow"),
  sad: __("Sad"),
  angry: __("Angry"),
};

const avatarIcons = {
  ...getMappedReactions(),
  joined: <JoinedIcon />,
  left: <LeftIcon />,
  entry: <EntryIcon />,
  delete: <DeleteIcon />,
  comment: <CommentIcon />,
  transferOffer: <TransferIcon type="offer" />,
  transferComplete: <TransferIcon type="complete" />,
  // Temporary icons
  laugh: <Haha />,
  surprised: <Wow />,
};

export const NotificationsItem: React.FC<NotificationsItemProps> = observer(
  ({ notification, isLast }) => {
    const [, setLocation] = useLocation();
    const [preview, setPreview] = useState<{
      type: "comment" | "entry";
      excerpt: string;
    } | null>(null);
    const journal = primaryViewState.getJournalById(
      notification.metadata.journal_id,
    );
    const currentUser = primaryViewState.user;
    const { shownSharedJournalsInfo } = userSettingsViewState;

    const goToJournal = (journalId: string) => {
      if (!journal) {
        showSnackbar(__("Journal no longer available"));
        return;
      }
      const JournalURL = getSingleJournalURL(
        journalId,
        primaryViewState.selectedEntryView,
      );
      setLocation(JournalURL);
    };

    const goToEntry = (entryId: string) => {
      if (!journal) {
        showSnackbar(__("Entry no longer available"));
        return;
      }
      const entryURL = getEntryURL(
        journal.id,
        entryId,
        primaryViewState.selectedEntryView,
      );
      setLocation(entryURL);
    };

    const goToComment = (entryId: string, commentId: string) => {
      if (!journal) {
        showSnackbar(__("Entry no longer available"));
        return;
      }
      const commentURL = `${getEntryURL(
        journal.id,
        entryId,
        primaryViewState.selectedEntryView,
      )}?comment=${commentId}`;

      setLocation(commentURL);
    };

    const showMemberDetails = (userid: string) => {
      if (!journal) {
        showSnackbar(
          __("The journal related to this notification was not found"),
        );
        return;
      }
      viewStates.modalRouter.showSharedJournalMember(journal.id, userid);
    };

    const showSnackbar = (message: string) => {
      viewStates.snackbar.newMessage(message);
    };

    const setCommentExcerpt = async (
      commentId: string,
      entryId: string,
      journalId: string,
    ) => {
      const comment =
        await d1Classes.commentRepository.getCommentById(commentId);
      if (comment) {
        const commentPreview = getRTJPreview(
          JSON.parse(comment.content).contents,
        );
        if (!preview) {
          setPreview({
            type: "comment",
            excerpt: cleanPreview(commentPreview),
          });
        }
      } else {
        setEntryExcerpt(entryId, journalId);
      }
    };

    const setEntryExcerpt = async (entryId: string, journalId: string) => {
      const entry = await d1Classes.entryRepository.getEntryById({
        entryId,
        journalId,
      });
      if (entry) {
        const entryModel = d1Classes.entryStore.makeEntryModel(entry);
        const entryPreview = getEntryPreview(entryModel);
        if (!preview) {
          setPreview({ type: "entry", excerpt: cleanPreview(entryPreview) });
        }
      }
    };

    const cleanPreview = (preview: string) => {
      const stripped = sanitizeText(preview).trim();
      if (stripped.length > 30) {
        return `${stripped.substring(0, 30)}...`;
      } else {
        return stripped.substring(0, 30);
      }
    };

    const notificationDetails = switchOnRecordEvent<
      Notification,
      NotificationDetails | "notification_not_recognized"
    >(
      notification,
      {
        shared_journal_user_joined: (n) => {
          if (n.metadata.user_id === currentUser?.id) {
            return {
              message: __("is now available for you to read and add to"),
              userid: n.metadata.user_id,
              action: () => {
                goToJournal(n.metadata.journal_id);
                if (!shownSharedJournalsInfo) {
                  modalRouterViewState.sharedJournalFeatures("participant");
                }
              },
              iconName: "joined",
              title: __("Go to journal"),
              type: "journal",
            };
          } else {
            return {
              message: __("joined the journal"),
              userid: n.metadata.user_id,
              action: () => {
                showMemberDetails(n.metadata.user_id);
              },
              iconName: "joined",
              title: __("View member details"),
            };
          }
        },
        shared_journal_user_access_request: (n) => {
          return {
            message: __("requested access to the journal"),
            userid: n.metadata.named_user_id,
            action: () => {
              showMemberDetails(n.metadata.named_user_id);
            },
            title: __("View member details"),
          };
        },
        shared_journal_user_left: (n) => {
          return {
            message: __("left the journal"),
            userid: n.metadata.user_id,
            action: () => {
              showMemberDetails(n.metadata.user_id);
            },
            iconName: "left",
            title: __("View member details"),
          };
        },
        shared_journal_user_removed: (n) => {
          return {
            message: __("was removed from the journal"),
            userid: n.metadata.user_id,
            action: () => {
              showMemberDetails(n.metadata.user_id);
            },
            iconName: "left",
            title: __("View member details"),
          };
        },
        shared_journal_entry_added: (n) => {
          setEntryExcerpt(n.metadata.entry_id, n.metadata.journal_id);
          return {
            message:
              preview?.type === "entry"
                ? sprintf(__('posted "%s"'), preview.excerpt)
                : __("added an entry"),
            userid: n.metadata.user_id,
            iconName: "entry",
            action: () => goToEntry(n.metadata.entry_id),
            title: __("View entry"),
          };
        },
        shared_journal_entry_deleted: (n) => {
          return {
            message: __("moved an entry to the trash"),
            userid: n.metadata.user_id,
            action: () => {
              showSnackbar(__("Entry no longer available"));
            },
            iconName: "delete",
            title: __("Mark notification as read"),
          };
        },
        shared_journal_entry_updated: (n) => {
          setEntryExcerpt(n.metadata.entry_id, n.metadata.journal_id);
          return {
            message:
              preview?.type === "entry"
                ? sprintf(__('updated "%s"'), preview.excerpt)
                : __("updated an entry"),
            userid: n.metadata.user_id,
            action: () => goToEntry(n.metadata.entry_id),
            iconName: "entry",
            title: __("View entry"),
          };
        },
        shared_journal_entry_reaction: (n) => {
          setEntryExcerpt(n.metadata.entry_id, n.metadata.journal_id);
          const message = preview
            ? sprintf(
                __('reacted "%s" to your entry: "%s"'),
                reactionWords[n.metadata.named_user_reaction],
                preview.excerpt,
              )
            : sprintf(
                __('reacted "%s" to your entry'),
                reactionWords[n.metadata.named_user_reaction],
              );
          const additionalReactions = n.metadata.total_users_reacting - 1;
          return {
            message:
              additionalReactions > 0
                ? sprintf(
                    _n(
                      `and +%d other ${message}`,
                      `and +%d others ${message}`,
                      additionalReactions,
                    ),
                    additionalReactions,
                  )
                : message,
            userid: n.metadata.named_user_id,
            action: () => goToEntry(n.metadata.entry_id),
            iconName: n.metadata.named_user_reaction,
            title: __("View entry"),
          };
        },
        shared_journal_transfer_ownership_offer: (n) => {
          return {
            message: __("wants to transfer ownership of this journal to you"),
            userid: n.metadata.previous_owner_id,
            action: () => {
              showMemberDetails(n.metadata.previous_owner_id);
            },
            title: __("View member details"),
            iconName: "transferOffer",
          };
        },
        shared_journal_transfer_ownership_completed: (n) => {
          return {
            message: __("ownership of the journal was transferred"),
            userid: n.metadata.new_owner_id,
            action: () => {
              showMemberDetails(n.metadata.new_owner_id);
            },
            title: __("View member details"),
            iconName: "transferComplete",
          };
        },
        shared_journal_deleted: (n) => {
          return {
            userid: n.metadata.user_id,
            message: n.metadata.journal_name
              ? sprintf(__("deleted journal %s"), n.metadata.journal_name)
              : __("deleted a shared journal"),

            action: () => {
              showSnackbar(
                sprintf(
                  __('The journal "%s" was deleted'),
                  n.metadata.journal_name || __("(unknown name)"),
                ),
              );
            },
            title: __("Mark notification as read"),
            iconName: "delete",
          };
        },
        shared_journal_comment_added: (n) => {
          setCommentExcerpt(
            n.metadata.comment_id,
            n.metadata.entry_id,
            n.metadata.journal_id,
          );

          const isAuthor = n.metadata.user_id === primaryViewState.user?.id;

          let message = isAuthor
            ? __("commented on your entry")
            : __("commented on the entry");
          if (preview?.type === "comment") {
            message = sprintf(__('commented "%s"'), preview.excerpt);
          } else if (preview?.type === "entry") {
            message = sprintf(__(`${message}: "%s"`), preview.excerpt);
          }

          return {
            message,
            userid: n.metadata.user_id,
            action: () =>
              goToComment(n.metadata.entry_id, n.metadata.comment_id),
            title: __("View comment"),
            iconName: "comment",
          };
        },
        shared_journal_comment_reaction: (n) => {
          setCommentExcerpt(
            n.metadata.comment_id,
            n.metadata.entry_id,
            n.metadata.journal_id,
          );
          return {
            message:
              preview?.type === "comment"
                ? sprintf(__('liked your comment "%s"'), preview.excerpt)
                : __("liked your comment"),
            userid: n.metadata.named_user_id,
            action: () =>
              goToComment(n.metadata.entry_id, n.metadata.comment_id),
            title: __("View comment"),
            iconName: "like",
          };
        },
      },
      "notification_not_recognized",
    );

    if (notificationDetails === "notification_not_recognized") {
      Sentry.captureException(
        new Error(
          `Notification type ${notification.event} is not yet supported`,
        ),
      );
      return null;
    }

    const { userid, message, action, iconName, title, type } =
      notificationDetails;
    const user = useSharedJournalParticipant(userid);

    const isUnread = notification.read_date === -1;

    const [loading, setLoading] = useState(true);

    // If we have a user, we can stop showing the loading state
    // after a short delay. If we don't have a user, we'll show
    // the notification without the user assuming we aren't
    // going to get one.
    useEffect(() => {
      if (user) {
        setLoading(false);
      }

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }, [user?.user_id, user?.name]);

    return (
      <li
        key={notification.id}
        id={notification.id}
        sx={{
          position: "relative",
          pl: 2,
          "&&:before": {
            content: "''",
            position: "absolute",
            left: 0,
            width: 1,
            top: 0,
            bottom: 0,
            borderRadius: "0 2px 2px 0",
            bg: isUnread ? "dayOneBlue" : "transparent",
            zIndex: 1,
          },
        }}
        className={
          // We need both the "unread/read" and "loading/loaded" classes
          // so that we get proper rounding on groups of unread notifications
          // after load, and proper rounding on the groups of grey boxes when
          // loading, regardless of unread status.
          // These classes are used by CSS selectors in the parent, NotificationsModal.
          (notification.read_date === -1 ? "unread" : "read") +
          (loading ? " loading" : " loaded")
        }
      >
        <Button
          onClick={() => {
            if (isUnread) {
              d1Classes.notificationStore.markAsRead(notification.id);
            }
            action();
          }}
          title={title}
          aria-label={`${user?.name} ${message}. ${__("Click to")} ${title}`}
          sx={{
            display: "flex",
            width: "100%",
            my: 0,
            pt: 2,
            pr: 0,
            pb: 0,
            pl: 1,
            textAlign: "left",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: NOTIFICATION_ROW_HEIGHT,
            transition: "background-color 400ms",
            ":focus": {
              position: "relative",
            },
          }}
        >
          {!loading && (
            <>
              {/* Avatar */}
              <div
                sx={{
                  pl: 2,
                  position: "relative",
                  flexShrink: 0,
                  "& > svg, & .reaction-icon": {
                    border: "3px solid",
                    borderColor: "surface_light1_dark5",
                  },
                  "& > svg": {
                    width: "22px",
                    height: "22px",
                    position: "absolute",
                    bottom: "-4px",
                    right: "12px",
                    borderRadius: "50%",
                  },
                }}
              >
                <Avatar
                  user={user}
                  size={2}
                  sx={{
                    border: 0,
                    mr: 3,
                  }}
                />
                {iconName ? avatarIcons[iconName] : null}
              </div>
              {/* Two rows of text */}
              <div
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderBottom: isLast ? "0px" : "1px solid",
                  borderColor: "borderPrimary",
                  height: "100%",
                  flexGrow: 1,
                  pr: 2,
                }}
              >
                {/* Top row, notification description */}
                <Message
                  user={user}
                  journal={journal}
                  message={message}
                  type={type}
                />
                {/* Bottom row, metadata about the notification */}
                <div
                  sx={{
                    fontSize: "0.8rem",
                    mt: 1,
                    lineHeight: 2,
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {journal && <JournalName journal={journal} />}
                  <span sx={{ color: "textTertiary", flexShrink: 0 }}>
                    {journal && "- "}
                    {new Date(notification.created_date).toLocaleString(
                      undefined,
                      {
                        weekday: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      },
                    )}
                  </span>
                </div>
              </div>
            </>
          )}
        </Button>
      </li>
    );
  },
);

NotificationsItem.displayName = "NotificationsItem";
