import { MasterKey } from "@/crypto/types/MasterKey";
import { primaryViewState } from "@/view_state/ViewStates";

export async function generatePDF(masterKey: MasterKey) {
  const { PDFDocument } = await import("pdf-lib");
  const pdfUrl = "/assets/Day%20One%20Encryption%20Key%20Backup.pdf";
  const keyImageURL = "/assets/qr-key.png";
  const existingPdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
  const keyImageBytes = await fetch(keyImageURL).then((res) =>
    res.arrayBuffer(),
  );

  const QRCode = await import("qrcode");
  const email = primaryViewState.user?.email ?? "Unknown User";

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const page = pages[0];

  // Add the user's email address
  page.setFontSize(10);
  page.drawText(`${email}`, { x: 125, y: 468 });

  // Generate the QR code and add it to the page
  const qrCodeImageDataURL = await QRCode.toDataURL(masterKey.url);
  const qrCodeImage = await pdfDoc.embedPng(qrCodeImageDataURL);
  const qrCodeImageDims = qrCodeImage.scale(0.75);
  page.drawImage(qrCodeImage, {
    // center on the page
    x: page.getWidth() / 2 - qrCodeImageDims.width / 2,
    y: 70,
    width: qrCodeImageDims.width,
    height: qrCodeImageDims.height,
  });

  // Key that goes in the center of the QR code
  const qrCodeKey = await pdfDoc.embedPng(keyImageBytes);
  page.drawImage(qrCodeKey, {
    x: page.getWidth() / 2 - 25 / 2,
    y: 70 + qrCodeImageDims.height / 2 - 25 / 2,
    width: 25,
    height: 25,
  });

  // Add the plaintext Master Key
  page.setFontSize(10);
  page.drawText(masterKey.wholeString, {
    x: 125,
    y: 332,
  });

  // Save the PDF as a Blob
  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

  // Create a download link and trigger the download
  const link = document.createElement("a");
  link.href = URL.createObjectURL(pdfBlob);
  link.download = "Day One Master Key Backup.pdf";
  link.click();

  // Clean up the URL object
  URL.revokeObjectURL(link.href);
}
