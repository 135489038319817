import { useI18n } from "@wordpress/react-i18n";
import { PropsWithChildren } from "react";

import { Sentry } from "@/Sentry";
import { ErrorMessage } from "@/components/ErrorMessage";

export const ErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  const { __ } = useI18n();
  const getMessage = (error: unknown): string => {
    if (error instanceof Error) {
      return error.message;
    }
    console.error("Unknown error in error boundary:", error);
    return __("Unknown error");
  };
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div>
          <h3>{__("Something went wrong.")}</h3>
          <ErrorMessage message={getMessage(error)} />
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
