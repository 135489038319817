// import { type } from "@testing-library/user-event/dist/types/utility";
import {
  InnerBlocks,
  store as blockEditorStore,
  useBlockProps,
  useInnerBlocksProps,
} from "@wordpress/block-editor";
import {
  BlockInstance,
  Template,
  registerBlockType,
  unregisterBlockType,
} from "@wordpress/blocks";
import { useSelect } from "@wordpress/data";
import { useEffect, useMemo, useState } from "react";

import { d1Classes } from "@/D1Classes";
import {
  GALLERY_BLOCK_ID,
  GalleryBlockEditProps,
  IMAGE_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { useEditorPanelContext } from "@/data/hooks/EditorPanelProvider";
import { dayOneBlue } from "@/styles/theme";
import {
  MomentIds,
  MomentIdsWithModel,
  getMediaWidth,
  getMomentsWithGalleryData,
} from "@/utils/gallery";

const ALLOWED_BLOCKS = [IMAGE_BLOCK_ID, VIDEO_BLOCK_ID];

const GalleryBlock: React.FC<GalleryBlockEditProps> = ({
  clientId,
  attributes,
  isSelected,
}) => {
  const blockProps = useBlockProps();
  const innerBlocksProps = useInnerBlocksProps(blockProps, {
    allowedBlocks: ALLOWED_BLOCKS,
  });
  const { editorWidth } = useEditorPanelContext();

  const [moments, setMoments] = useState<MomentIdsWithModel[]>();
  const [innerBlocks, setInnerBlocks] = useState<Template[]>([]);

  const innerBlocksFromGutenberg: BlockInstance[] = useSelect(
    (select) => {
      // @ts-ignore getBlocks does exist, but typescript doesn't like it
      return select(blockEditorStore).getBlocks(clientId);
    },
    [attributes.ids],
  );

  const blockIdsFromGutenberg = useMemo(
    () =>
      innerBlocksFromGutenberg.map(
        (block) => block.attributes.clientId as string,
      ),
    [innerBlocksFromGutenberg],
  );

  const reorderMoments = (
    moments: MomentIdsWithModel[],
    orderIds: string[],
  ) => {
    const result = orderIds.reduce((acc, id) => {
      const index = moments.findIndex((moment) => moment.clientId === id);
      if (index !== -1) {
        acc.push(moments[index]);
      }
      return acc;
    }, [] as MomentIdsWithModel[]);
    return result;
  };

  // This useEffect is used when we have a change in the innerblocks from Gutenberg
  // We can then re-calculate the proper size for each item in the gallery
  // and set it appropriately.
  useEffect(() => {
    const orderedMoments =
      moments && blockIdsFromGutenberg
        ? reorderMoments(moments, blockIdsFromGutenberg)
        : moments;
    const allMedia = getMomentsWithGalleryData(
      orderedMoments,
      editorWidth,
    ).flat();
    setTimeout(() => {
      allMedia.forEach(({ aspectRatio, clientId: momentId, height }) => {
        const width = getMediaWidth(aspectRatio, height);
        const galleryBlock = document.getElementById(`block-${clientId}`);
        if (!galleryBlock) {
          return;
        }
        const mediaBlock = galleryBlock.querySelector(
          `[data-momentid="${momentId}"]`,
        ) as HTMLElement;
        if (mediaBlock) {
          mediaBlock.style.width = `${width}px`;
          mediaBlock.style.height = `${height}px`;
          const child = mediaBlock.childNodes[0] as HTMLElement;
          child.style.width = `${width}px`;
          child.style.height = `${height}px`;
        }
      });
    }, 0);
  }, [blockIdsFromGutenberg, editorWidth]);

  // This useEffect is used when we have a change in the attributes.ids so we set the
  // initial innerBlocks for the gallery.
  useEffect(() => {
    // grab moment from ids in order to get its height and width for calculating aspect ratio
    const getMomentsByIds = async (momentIds: MomentIds[]) => {
      const moments = await Promise.all(
        momentIds.map(async ({ journalId, entryId, clientId, type }) => {
          const moment = await d1Classes.momentStore.getMomentById(
            journalId,
            entryId,
            clientId,
          );
          return {
            journalId,
            entryId,
            clientId,
            type,
            moment,
          };
        }),
      );
      setMoments(moments);
      const rows = getMomentsWithGalleryData(moments, editorWidth);
      const newInnerBlocks = rows.flatMap((row) =>
        row.map(
          ({ aspectRatio, clientId, entryId, journalId, type, height }) => {
            return [
              type === "photo" ? IMAGE_BLOCK_ID : VIDEO_BLOCK_ID,
              {
                aspectRatio,
                clientId,
                entryId,
                journalId,
                galleryHeight: height,
              },
            ] as Template;
          },
        ),
      );
      setInnerBlocks(newInnerBlocks);
    };

    getMomentsByIds(attributes.ids);
  }, [attributes.ids]);

  return (
    <div
      {...innerBlocksProps}
      sx={{
        border: "3px solid",
        borderColor: isSelected ? dayOneBlue : "transparent",
        borderRadius: "3px",
        ".block-editor-inner-blocks .block-editor-block-list__layout": {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        },
      }}
    >
      <InnerBlocks
        templateInsertUpdatesSelection={false}
        //@ts-ignore these innerblocks are valid tempates but typescript doesn't like it
        template={innerBlocks}
        allowedBlocks={ALLOWED_BLOCKS}
      />
    </div>
  );
};

export const register = () => {
  if (!blockIsRegistered(GALLERY_BLOCK_ID)) {
    registerBlockType(GALLERY_BLOCK_ID, {
      edit: GalleryBlock,
      title: "Media Gallery",
      category: "media",
      textdomain: "default",
      description: "Day One Media Gallery Block",
      apiVersion: 2,
      attributes: {
        ids: "array",
      },
      supports: {
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(GALLERY_BLOCK_ID)) {
    return unregisterBlockType(GALLERY_BLOCK_ID);
  }
  return false;
};
