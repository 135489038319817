import { DOCryptoBasics } from "@/crypto/DOCryptoBasics";
import { toBase64 } from "@/crypto/utils";
import { UserModel } from "@/data/models/UserModel";
import { primaryViewState } from "@/view_state/ViewStates";

export const getCloudBackupFileName = (userArg?: UserModel) => {
  const user = userArg || primaryViewState.user;
  if (!user) {
    throw new Error("User not found, unable to create Google Drive filename");
  }
  return `Master Key - ${user.id}`;
};

export const getDerivedKey = async (userId: string) => {
  const saltData = `salt-${userId}`;
  const iterations = 100000;
  return DOCryptoBasics.Symmetric.Key.newFromUserId(
    userId,
    saltData,
    iterations,
  );
};

export const getAndFormatMasterKey = async (
  data: ArrayBuffer,
  userId: string,
) => {
  const key = await getDerivedKey(userId);
  const decrypted = await DOCryptoBasics.Symmetric.decryptD1(
    toBase64(new Uint8Array(data)),
    key,
  );
  return formatMasterKeyString(decrypted, userId);
};

const formatMasterKeyString = (decrypted: string, userId: string) => {
  const keyParts = ["D1", userId];
  keyParts.push(decrypted.substring(0, 6));

  for (let i = 6; i < 31; i += 5) {
    keyParts.push(decrypted.substring(i, i + 5));
  }
  return keyParts.join("-");
};
