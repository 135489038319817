import { decode } from "base64-arraybuffer";

import { d1Classes } from "@/D1Classes";
import { DOCryptoBasics } from "@/crypto/DOCryptoBasics";
import { UserModel } from "@/data/models/UserModel";
import { GoogleFile } from "@/hooks/google/constants";
import { getCloudBackupFileName, getDerivedKey } from "@/utils/masterkeyBackup";

export const deleteFileFromGoogleDrive = async (
  fileId: string,
  token: string,
) => {
  const result = await d1Classes.fetchWrapper.basicFetch(
    new URL(`https://www.googleapis.com/drive/v3/files/${fileId}`),
    {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
  if (result.ok) {
    return true;
  } else {
    return false;
  }
};

export const saveKeyToDrive = async (
  user: UserModel,
  masterKey: string,
  token: string,
) => {
  const key = await getDerivedKey(user.id);
  const masterKeyParts = masterKey.split("-");
  const cleanedKey = masterKeyParts.slice(2).join("");
  const encryptedMasterKeyString = await DOCryptoBasics.Symmetric.encryptToD1(
    cleanedKey,
    key,
  );
  const form = new FormData();
  const file = new Blob([decode(encryptedMasterKeyString)], {
    type: "application/octet-stream",
  });
  const metadata = {
    name: getCloudBackupFileName(user),
    mimeType: "application/octet-stream",
    parents: ["appDataFolder"],
  };
  form.append(
    "metadata",
    new Blob([JSON.stringify(metadata)], { type: "application/json" }),
  );
  form.append("file", file);

  return await d1Classes.fetchWrapper.basicFetch(
    new URL(
      "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
    ),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
      method: "POST",
      body: form,
    },
  );
};

export const getFileId = async (
  user: UserModel,
  token: string,
  fileName: string,
) => {
  const files = await getAllStoredFiles(user, token);
  if (!files) {
    return null;
  }
  const file = files.find((f) => f.name === fileName);
  if (!file) {
    return null;
  }
  return file.id;
};

export const getAllStoredFiles = async (user: UserModel, token: string) => {
  const url = new URL(
    "https://www.googleapis.com/drive/v3/files?spaces=appDataFolder",
  );

  const options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const filesResult = await d1Classes.fetchWrapper.basicFetch(url, options);
  if (filesResult.ok) {
    const json = await filesResult.json();
    const files = json.files as GoogleFile[];
    if (files.length > 0) {
      return files;
    } else {
      return null;
    }
  }
  return null;
};
