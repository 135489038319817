import { HeaderNode } from "types/rtj-format";

import {
  convertTextToMarkup,
  endsWithNewLine,
} from "@/components/Editor/rtj2gb/format-node";
import { takeUntil } from "@/components/Editor/utils/take-until";

type HeadingItem = {
  text: string;
  level: number;
};

export const convertHeadingNodesToBlockAttributes = (nodes: HeaderNode[]) => {
  const result = nodes.reduce<{
    skippedItems: number;
    blocks: HeadingItem[];
  }>(
    (acc, _, index, arr) => {
      const currentIndex = index + acc.skippedItems;
      const currentNode = arr[currentIndex];
      if (currentNode) {
        if (!endsWithNewLine(currentNode.text)) {
          const nodeWithSiblings = takeUntil(
            arr.slice(currentIndex),
            (node) =>
              currentNode.attributes?.line?.header !==
                node.attributes?.line?.header || endsWithNewLine(node.text),
          );

          const formattedTexts = nodeWithSiblings.map((node) =>
            convertTextToMarkup(node),
          );

          return {
            skippedItems: (acc.skippedItems += nodeWithSiblings.length - 1),
            blocks: [
              ...acc.blocks,
              {
                text: formattedTexts.join(""),
                level: currentNode.attributes?.line?.header,
              },
            ],
          };
        } else {
          return {
            ...acc,
            blocks: [
              ...acc.blocks,
              {
                text: convertTextToMarkup(currentNode),
                level: currentNode.attributes?.line?.header,
              },
            ],
          };
        }
      }

      return acc;
    },
    {
      skippedItems: 0,
      blocks: [],
    },
  );

  return result.blocks;
};
