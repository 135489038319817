export const DayOneLogo = () => (
  <svg
    sx={{
      ml: 4,
      color: "textPrimary",
    }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 348 32"
    width="131"
    height="12"
  >
    <path d="M0 0h348v32H0z" fill="none" />
    <path
      fill="currentcolor"
      d="M205.93 0c9.45 0 16.04 7.18 16.04 15.85v.09c0 8.67-6.68 15.93-16.13 15.93S189.8 24.7 189.8 16.02v-.08C189.8 7.26 196.48 0 205.93 0zm77.47.3c1.5 0 2.68 1.2 2.68 2.65v25.88c0 1.5-1.14 2.69-2.63 2.69h-.22c-1.28 0-2.07-.66-2.82-1.63L264.6 9.38v19.54a2.66 2.66 0 0 1-5.32 0V3.08c0-1.5 1.2-2.73 2.73-2.73h.57c1.32 0 2.07.66 2.81 1.59l15.39 19.94V2.95A2.65 2.65 0 0 1 283.4.3zM154.03.36c.57 0 1.14.53 1.14 1.1 0 .31-.18.62-.35.88L142.9 19.1v11.27c0 .62-.53 1.15-1.14 1.15-.62 0-1.15-.53-1.15-1.15V19.15l-12-16.95a1.46 1.46 0 0 1-.22-.7c0-.62.58-1.15 1.2-1.15.48 0 .78.27 1.05.66l11.16 16.07L153 1.01c.27-.35.53-.66 1.02-.66zM77.96.22c.79 0 1.23.44 1.54 1.1l13.1 28.44c.13.26.17.48.17.7 0 .57-.48 1.06-1.1 1.06-.53 0-.92-.4-1.14-.88l-3.6-7.93H68.76l-3.6 7.97c-.22.53-.57.84-1.1.84-.57 0-1.05-.44-1.05-.97 0-.18.04-.44.17-.7L76.33 1.32c.31-.66.75-1.1 1.54-1.1h.09zm267.4.3c1.32 0 2.42 1.1 2.42 2.43 0 1.36-1.1 2.42-2.42 2.42h-15.2v8.01h13.23c1.31 0 2.41 1.1 2.41 2.47a2.4 2.4 0 0 1-2.41 2.37h-13.23v8.28h15.42c1.32 0 2.42 1.1 2.42 2.42 0 1.37-1.1 2.42-2.42 2.42h-18.1a2.69 2.69 0 0 1-2.73-2.73V3.26a2.69 2.69 0 0 1 2.73-2.73h17.88zM10.24.53c9.67 0 16.35 6.66 16.35 15.33v.09c0 8.67-6.68 15.4-16.35 15.4h-9.1C.53 31.34 0 30.81 0 30.2V1.67C0 1.06.53.53 1.14.53h9.1zm0 2.12H2.29v26.59h7.95c8.57 0 13.98-5.86 13.98-13.2v-.1c0-7.3-5.41-13.29-13.98-13.29zm195.6 2.33c-6.1 0-10.37 4.9-10.37 10.88v.09c0 5.98 4.35 10.91 10.46 10.91 6.1 0 10.37-4.84 10.37-10.83v-.08c0-6-4.35-10.97-10.46-10.97zM77.87 2.82l-8.13 17.83h16.22L77.87 2.82z"
    />
  </svg>
);
