import { useEffect, useMemo } from "react";

export const useCreateObjectUrl = (blob: Blob | null | undefined) => {
  const objUrl = useMemo(() => {
    if (blob) {
      return URL.createObjectURL(blob);
    }

    return null;
  }, [blob]);

  useEffect(() => {
    return () => {
      objUrl && URL.revokeObjectURL(objUrl);
    };
  }, [blob]);

  return objUrl;
};
