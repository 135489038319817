import { Disabled } from "@wordpress/components";

import { dayOneBlue } from "@/styles/theme";

export const ResponsiveIframe: React.FC<{
  src: string;
  isSelected: boolean;
}> = ({ src, isSelected }) => {
  return (
    <div
      sx={{
        maxWidth: "100%",
        pt: "56.25%",
        position: "relative",
        width: "100%",
        my: 4,
        border: "3px solid",
        borderColor: isSelected ? dayOneBlue : "transparent",
        borderRadius: "3px",
      }}
    >
      <Disabled isDisabled={!isSelected} sx={{ "&": { position: "initial" } }}>
        <iframe
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
          }}
          aspect-ratio="16 / 9"
          src={src}
          width="100%"
          height="100%"
          allowFullScreen
          // @ts-ignore only supported in Chromium at this time
          credentialless=""
        ></iframe>
      </Disabled>
    </div>
  );
};
