export const ExternalLink: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54166 4.16699H6.16666C5.06209 4.16699 4.16666 5.06242 4.16666 6.16699V13.8337C4.16666 14.9382 5.06209 15.8337 6.16666 15.8337H13.8333C14.9379 15.8337 15.8333 14.9382 15.8333 13.8337V11.1346"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.4122 3.15546L17.0352 3.13458M17.0352 3.13458L16.9956 7.7388M17.0352 3.13458C15.071 5.09876 12.52 7.6498 10.6344 9.53542"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};
