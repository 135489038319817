import { Flex, FlexItem } from "@wordpress/components";
import { ColorPalette as WPColorPalette } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { AvatarInitials } from "@/components/Settings/Account/Avatar/AvatarInitials";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { userProfileColors } from "@/styles/UserProfileColors";
import { primaryViewState } from "@/view_state/ViewStates";

type EditColorProps = {
  handleClose: () => void;
};

const profileColors = Object.entries(userProfileColors).map(([name, color]) => {
  return { name, color };
});

const getHexColor = (color: string) => {
  const colorObj = profileColors.find((c) => c.name === color);
  return colorObj?.color || profileColors[0].color;
};

const getColorString = (color: string) => {
  const colorObj = profileColors.find((c) => c.color === color);
  return colorObj?.name || profileColors[0].name;
};

export const EditColor: React.FC<EditColorProps> = observer(
  ({ handleClose }) => {
    const user = primaryViewState.user;
    const initials = user?.initials || "";
    const [isDone, setIsDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [color, setColor] = useState(getHexColor(user?.profile_color || ""));

    useEffect(() => {
      if (user?.profile_color) {
        setColor(getHexColor(user.profile_color));
      }
    }, [user?.profile_color]);

    function doSave() {
      setIsSaving(true);
      d1Classes.userStore
        .updateProfileColor(getColorString(color))
        .then(() => {
          setIsDone(true);
          setIsSaving(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }

    return (
      <D1Modal
        title={__("Choose Color")}
        onRequestClose={handleClose}
        sx={{ width: "420px" }}
      >
        <EditSection>
          <FlexItem sx={{ flexDirection: "column", flexGrow: 1 }}>
            {!isDone && (
              <>
                <Flex>
                  <FlexItem sx={{ flex: "1 0 160px" }}>
                    <AvatarInitials initials={initials} color={color} />
                  </FlexItem>
                  <FlexItem
                    sx={{
                      "&& .components-circular-option-picker__option-wrapper::before":
                        {
                          display: "none",
                        },
                    }}
                  >
                    <WPColorPalette
                      colors={profileColors}
                      value={color}
                      onChange={(value) => {
                        if (value) {
                          setColor(value);
                        }
                      }}
                      disableCustomColors={true}
                      clearable={false}
                      sx={{
                        mb: 3,
                        "& .components-circular-option-picker__option::after": {
                          boxShadow: (theme) =>
                            `inset 0 0 0 1px ${theme.colors?.journalColorPickerBorder}`,
                        },
                        "& .components-circular-option-picker__option": {
                          borderRadius: "50% !important",
                          position: "relative",
                        },
                        "& .components-popover.components-tooltip": {
                          display: "none !important",
                        },
                      }}
                    />
                  </FlexItem>
                </Flex>
                <Flex>
                  <EditButtons
                    doSave={doSave}
                    isSaving={isSaving}
                    attributeChanged={
                      getHexColor(user?.profile_color || "") !== color
                    }
                    doCancel={handleClose}
                  />
                </Flex>
              </>
            )}
            {isDone && (
              <Done
                doClose={handleClose}
                message={__("Your profile color has been updated")}
              />
            )}
          </FlexItem>
        </EditSection>
      </D1Modal>
    );
  },
);

EditColor.displayName = "EditColor";
