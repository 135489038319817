import { Sentry } from "@/Sentry";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64, toHex } from "@/crypto/utils";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { ContentKeysRepository } from "@/data/repositories/ContentKeysRepository";
import {
  UnlockedUserPrivateKey,
  UserKeysRepository,
} from "@/data/repositories/UserKeysRepository";
import { UserRepository } from "@/data/repositories/UserRepository";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export class DecryptionService {
  isInitialized: boolean;

  constructor(
    private userRepository: UserRepository,
    private userKeysRepository: UserKeysRepository,
    private vaultRepository: VaultRepository,
    private contentKeyRepository: ContentKeysRepository,
  ) {
    this.isInitialized = false;
  }

  /**
   * Decrypts a blob with the user's private key.
   *
   * @param d1 Base64 encoded string of the encrypted blob (D1 format)
   * @returns Decrypted blob as an ArrayBuffer
   */
  async decryptV4ContentBlob(
    userPrivateKeys: UnlockedUserPrivateKey[],
    blob: string,
  ) {
    let contentPrivateKeyBytes = null;
    let contentPrivateKey = null;

    const blobUintArray = fromBase64(blob);

    const parsedD1 = await DOCrypto.D1.parse(blobUintArray);
    const lockedKeyInfo = parsedD1.lockedKeyInfo;

    const fingerPrint = lockedKeyInfo?.fingerprint
      ? toHex(lockedKeyInfo.fingerprint)
      : null;
    if (!fingerPrint) {
      return null;
    }
    const contentKey =
      await this.contentKeyRepository.getContentKeyByFingerprint(fingerPrint);

    if (contentKey) {
      for (let i = 0; i < userPrivateKeys.length; i++) {
        const userPrivateKey = userPrivateKeys[i];
        try {
          const { decrypted } = await DOCrypto.D1.decrypt(
            fromBase64(contentKey.encryptedPrivateKey),
            "Trying to decrypt Content Private Key",
            userPrivateKey.key,
          );
          if (decrypted) {
            contentPrivateKeyBytes = decrypted;
            break;
          }
        } catch (e) {
          if (i >= userPrivateKeys.length) {
            Sentry.captureException(
              new Error("Could not unlock v4 blob with any of the user keys"),
            );
          }
        }
      }

      if (!contentPrivateKeyBytes) {
        return null;
      }

      contentPrivateKey = await Asymmetric.Private.fromPEM(
        Utf8.fromBufferSource(contentPrivateKeyBytes!),
      );
    } else {
      const key = userPrivateKeys.find(
        (key) => key.fingerprint === fingerPrint,
      );
      if (key) {
        contentPrivateKey = key.key;
      }
    }
    if (!contentPrivateKey) {
      Sentry.captureException(
        new Error(
          "Could not find the content key or main key needed to unlock V4 Content blob",
        ),
      );
      return null;
    }

    const decryptedTemplate = await DOCrypto.D1.decrypt(
      blobUintArray,
      "Trying to decrypt V4 Blob",
      contentPrivateKey,
    );

    return JSON.parse(Utf8.fromBufferSource(decryptedTemplate.decrypted));
  }

  async decryptEntryBlob(
    d1: Uint8Array,
    debugContext: string,
    vaultRepository: VaultRepository,
  ) {
    return DOCrypto.EntryBlob.decrypt(d1, debugContext, vaultRepository);
  }

  get userPrivateKey() {
    return this.userKeysRepository.getMainPrivateKey();
  }

  async canPerformDecrypt() {
    const can = await this.userKeysRepository.canDecryptOrEncrypt();
    return can;
  }

  private async init(encryptedJournals: JournalDBRow[]) {
    const user = await this.userRepository.getActiveUser();

    if (user === null) {
      return;
    }

    const keys = await this.userKeysRepository.getAllUnlockedKeys();
    if (!this.isInitialized && (await this.canPerformDecrypt())) {
      await DOCrypto.JournalKey.unlockAndStoreAll(
        user.id,
        encryptedJournals,
        keys,
        this.vaultRepository,
      );
      this.isInitialized = true;
    }
  }

  async decryptJournalName(
    journalsWithVaults: JournalDBRow[],
    journalId: string,
    journalName: string,
  ) {
    await this.init(journalsWithVaults);
    const canPerformDecrypt = !!(await this.userPrivateKey);

    if (canPerformDecrypt) {
      try {
        const decryptedName = DOCrypto.Journal.decryptName(
          journalId,
          journalName,
          this.vaultRepository,
        );
        return decryptedName;
      } catch (e) {
        throw new Error(
          "Could not decrypt an encrypted journal, encryption key is incorrect.",
        );
      }
    }
    return null;
  }

  async decryptMedia(
    blob: Uint8Array,
    debugContext: string,
    vaultRepository: VaultRepository,
  ) {
    return DOCrypto.Media.decrypt(blob, debugContext, vaultRepository);
  }

  async decryptEntryD1(
    journalsWithVaults: JournalDBRow[],
    d1: Uint8Array,
    debugContext: string,
  ) {
    await this.init(journalsWithVaults);

    const canPerformDecrypt = !!(await this.userPrivateKey);

    if (canPerformDecrypt) {
      try {
        return this.decryptEntryBlob(d1, debugContext, this.vaultRepository);
      } catch (e) {
        if (e instanceof Error) {
          throw new Error(`Could not decrypt entry with error: ${e.message}`);
        }
      }
    }

    return null;
  }
}
