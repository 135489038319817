export const Heart: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      id="heart"
      viewBox="0 0 16 16"
      fill="#E74C3C"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.0001 13.6L7.16676 12.85C6.05565 11.8611 5.13899 11.0139 4.41676 10.3083C3.69454 9.60278 3.1251 8.975 2.70843 8.425C2.29176 7.875 2.00288 7.37222 1.84176 6.91667C1.68065 6.46111 1.6001 5.99444 1.6001 5.51667C1.6001 4.52778 1.93899 3.69444 2.61676 3.01667C3.29454 2.33889 4.12788 2 5.11676 2C5.66121 2 6.18899 2.11667 6.7001 2.35C7.21121 2.58333 7.64454 2.91111 8.0001 3.33333C8.35565 2.91111 8.78899 2.58333 9.3001 2.35C9.81121 2.11667 10.339 2 10.8834 2C11.8723 2 12.7057 2.33889 13.3834 3.01667C14.0612 3.69444 14.4001 4.52778 14.4001 5.51667C14.4001 5.99444 14.3223 6.45556 14.1668 6.9C14.0112 7.34444 13.7251 7.83889 13.3084 8.38333C12.8918 8.92778 12.3195 9.55833 11.5918 10.275C10.864 10.9917 9.93343 11.8611 8.8001 12.8833L8.0001 13.6Z" />
    </svg>
  );
};
