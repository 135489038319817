import { Icon } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { ActivityChoiceModal } from "@/components/Editor/components/MetadataModal/ActivityChoiceModal";
import { MetadataButton } from "@/components/Editor/components/MetadataModal/MetadataButton";
import { MetadataDivider } from "@/components/Editor/components/MetadataModal/MetadataDivider";
import { MetadataRow } from "@/components/Editor/components/MetadataModal/MetadataRow";
import { TagManagerModal } from "@/components/Editor/components/TagManagerModal";
import { DateTimePicker } from "@/components/Form/DateTimePicker";
import { Bookmark } from "@/components/icons/Bookmark";
import { Clock } from "@/components/icons/Clock";
import { EntryLink } from "@/components/icons/EntryLink";
import { HeartFavorite } from "@/components/icons/HeartFavorite";
import { Location } from "@/components/icons/Location";
import { MobilePhone } from "@/components/icons/MobilePhone";
import { Mountains } from "@/components/icons/Mountains";
import { Music } from "@/components/icons/Music";
import { Sneaker } from "@/components/icons/Sneaker";
import { Stats } from "@/components/icons/Stats";
import { Tag } from "@/components/icons/Tag";
import { MoonPhase } from "@/data/db/migrations/entry";
import { dateTimeToString, entryDateNumberToString } from "@/utils/date-helper";
import { formatDistance } from "@/utils/distance";
import { getActivityIcon, getMoonIcon, getWeatherIcon } from "@/utils/icons";
import { getLocationString } from "@/utils/location";
import { getTemperatureString } from "@/utils/temperature-helper";
import { ActiveEntryViewState } from "@/view_state/ActiveEntryViewState";
import {
  primaryViewState,
  userSettingsViewState,
} from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
  entryId: string;
  journalId: string;
  activeEntryViewState: ActiveEntryViewState;
};

export const MetadataModal: React.FC<Props> = observer(
  ({ handleClose, activeEntryViewState, entryId, journalId }) => {
    const { __ } = useI18n();
    const dateRef = useRef<HTMLDivElement>(null);
    const [showTagManager, setShowTagManager] = useState(false);
    const [showEditDate, setShowEditDate] = useState(false);
    const [showActivityChoice, setShowActivityChoice] = useState(false);
    const entry = activeEntryViewState.entryModel;

    const getMoonPhaseText = (phase: number, phaseCode: MoonPhase) => {
      let phaseText = "";
      switch (phaseCode) {
        case "new":
          phaseText = __("New Moon");
          break;
        case "full":
          phaseText = __("Full Moon");
          break;
        case "waxing-crescent":
          phaseText = __("Waxing Crescent Moon");
          break;
        case "first-quarter":
          phaseText = __("First Quarter Moon");
          break;
        case "last-quarter":
          phaseText = __("Last Quarter Moon");
          break;
        case "waning-crescent":
          phaseText = __("Waning Crescent Moon");
          break;
        case "waning-gibbous":
          phaseText = __("Waning Gibbous Moon");
          break;
        case "waxing-gibbous":
          phaseText = __("Waxing Gibbous Moon");
          break;
      }
      return `${phase}, ${phaseText}`;
    };

    const location = getLocationString(entry?.location);
    const showLocation = location || entry?.location?.placeName;
    const stats = `${activeEntryViewState.counts?.words || 0} ${__("Words")}, ${activeEntryViewState.counts?.characters || 0} ${__("Characters")}, ${activeEntryViewState.momentCount} ${__("Media")}`;
    const isEditable =
      !activeEntryViewState.isInSharedJournal ||
      entry?.creatorUserId === primaryViewState.user?.id;
    const creationDevice = entry?.clientMeta
      ? `${entry.clientMeta.creationDevice}, ${entry.clientMeta.creationDeviceType}, ${entry.clientMeta.creationOSName} ${entry.clientMeta.creationOSVersion}`
      : "";
    const unit =
      userSettingsViewState.temperatureUnit === "C" ? "metric" : "imperial";
    const altitude = entry?.location?.altitude
      ? formatDistance(entry?.location?.altitude, unit, __, true)
      : "";
    const moonPhase = entry?.weather?.moonPhase;
    const moonPhaseCode = entry?.weather?.moonPhaseCode;
    const moonPhaseIcon = moonPhaseCode ? getMoonIcon(moonPhaseCode) : null;
    const moonPhaseText =
      moonPhase && moonPhaseCode
        ? getMoonPhaseText(moonPhase, moonPhaseCode)
        : "";

    const temperature = entry
      ? getTemperatureString(
          entry.weather,
          userSettingsViewState.temperatureUnit,
        )
      : "";

    const weatherIcon = entry?.weather?.code
      ? getWeatherIcon(entry.weather.code)
      : null;

    const tagData = isEditable ? (
      <MetadataButton
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.buttonTap, {
            button_identifier: "entryMetadata_tagsCell",
          });
          setShowTagManager(true);
        }}
        text={__("Add...")}
      />
    ) : (
      ""
    );

    const activityData = isEditable ? (
      <MetadataButton
        onClick={() => {
          analytics.tracks.recordEvent(EVENT.buttonTap, {
            button_identifier: "entryMetadata_activityCell",
          });
          setShowActivityChoice(true);
        }}
        text={__("Add...")}
      />
    ) : (
      ""
    );

    const showActivity =
      isEditable || (entry?.activity !== undefined && entry?.activity !== "");

    useEffect(() => {
      analytics.tracks.recordEvent(EVENT.screenView, {
        screen: "entryMetadata",
      });
    }, []);

    return (
      <>
        <D1Modal
          title={__("Entry Info")}
          onRequestClose={handleClose}
          sx={{
            width: ["100%", "100%", "420px"],
            "& .components-modal__content": {
              pt: 2,
            },
          }}
        >
          {showLocation && (
            <MetadataRow
              icon={<Icon icon={Location} />}
              title={location}
              data={entry?.location?.placeName || ""}
            />
          )}

          {!!entry?.duration && (
            <MetadataRow
              icon={<Icon icon={Clock} />}
              title={__("Visit Duration")}
              data={`${entry?.duration}`}
            />
          )}

          {showLocation || !!entry?.duration ? <MetadataDivider /> : null}
          <MetadataRow
            icon={<Icon icon={Tag} />}
            title={__("Tags")}
            data={activeEntryViewState.tags.join(", ") || tagData}
            edit={
              isEditable
                ? () => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_tagsCell",
                    });
                    setShowTagManager(true);
                  }
                : undefined
            }
          />
          <MetadataRow
            icon={<Icon icon={Bookmark} />}
            title={__("Journal")}
            data={activeEntryViewState.selectedJournal?.name || ""}
          />
          <div ref={dateRef} />
          <MetadataRow
            icon={<Icon icon={Clock} />}
            title={__("Date")}
            data={entry ? entryDateNumberToString(entry) : ""}
            edit={
              isEditable
                ? () => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_dateCell",
                    });
                    setShowEditDate(true);
                  }
                : undefined
            }
          />
          <MetadataRow
            icon={<Icon icon={Clock} />}
            title={__("Last Edited")}
            data={
              entry
                ? dateTimeToString(new Date(entry.editDate), entry.timeZone)
                : ""
            }
          />
          {entry && entry.editingTime > 0 && (
            <MetadataRow
              icon={<Icon icon={Clock} />}
              title={__("Edit Duration")}
              data={`${Math.round(entry.editingTime)}${__("s")}`}
            />
          )}
          <MetadataDivider />
          {temperature && weatherIcon && (
            <MetadataRow
              icon={<Icon icon={weatherIcon} />}
              title={__("Weather")}
              data={temperature}
            />
          )}
          {moonPhaseText && moonPhaseIcon && (
            <MetadataRow
              icon={<Icon icon={moonPhaseIcon} />}
              title={__("Moon Phase")}
              data={moonPhaseText}
            />
          )}
          {creationDevice && (
            <MetadataRow
              icon={<Icon icon={MobilePhone} />}
              title={__("Entry Creation Device")}
              data={creationDevice}
            />
          )}

          <MetadataRow
            icon={<Icon icon={Stats} />}
            title={__("Stats")}
            data={stats}
          />
          {isEditable && (
            <MetadataRow
              icon={<Icon icon={HeartFavorite} />}
              title={__("Favorite")}
              data={
                <MetadataButton
                  onClick={() => {
                    analytics.tracks.recordEvent(EVENT.buttonTap, {
                      button_identifier: "entryMetadata_favoriteCell",
                    });
                    activeEntryViewState.toggleFavorite();
                  }}
                  text={
                    activeEntryViewState.entryModel?.isStarred
                      ? __("Unfavorite")
                      : __("Mark as favorite")
                  }
                />
              }
            />
          )}

          <MetadataRow
            icon={<Icon icon={EntryLink} />}
            title={__("Entry ID")}
            data={entryId}
          />
          {altitude && (
            <MetadataRow
              icon={<Icon icon={Mountains} />}
              title={__("Altitude")}
              data={altitude}
            />
          )}

          {showActivity && (
            <MetadataRow
              icon={<Icon icon={getActivityIcon(entry?.activity || "")} />}
              title={__("Activity")}
              data={entry?.activity || activityData}
              edit={
                isEditable
                  ? () => {
                      analytics.tracks.recordEvent(EVENT.buttonTap, {
                        button_identifier: "entryMetadata_activityCell",
                      });
                      setShowActivityChoice(true);
                    }
                  : undefined
              }
            />
          )}

          {entry?.steps && !entry.steps.ignore && (
            <MetadataRow
              icon={<Icon icon={Sneaker} />}
              title={__("Step Count")}
              data={`${entry.steps.stepCount}`}
            />
          )}

          {entry?.music && (
            <MetadataRow
              icon={<Icon icon={Music} />}
              title={__("Music")}
              data={`${entry.music.artist} - ${entry.music.track}`}
            />
          )}
        </D1Modal>
        {showTagManager && (
          <TagManagerModal
            entryTags={activeEntryViewState.tags}
            globalEntryID={{
              id: entryId,
              journal_id: journalId,
            }}
            onClose={() => setShowTagManager(false)}
            onChangeTags={activeEntryViewState.saveTagsOnly}
          />
        )}
        {showEditDate && entry && (
          <DateTimePicker
            initialDate={new Date(entryDateNumberToString(entry, "en-US"))}
            isAllDay={entry.isAllDay}
            changeDate={activeEntryViewState.changeDateHandler}
            anchorRef={dateRef}
            handleFocusOutside={() => setShowEditDate(false)}
            placement="right"
          />
        )}
        {showActivityChoice && entry && (
          <ActivityChoiceModal
            update={activeEntryViewState.updateActivity}
            handleClose={() => setShowActivityChoice(false)}
            entry={entry}
          />
        )}
      </>
    );
  },
);
