import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { cancelCircleFilled } from "@wordpress/icons";

const clearSearchOptions = ["text", "button", "hide"] as const;
export type ClearSearchOptionsType = (typeof clearSearchOptions)[number];

type Props = {
  showClear: ClearSearchOptionsType;
  handleClear: () => void;
};

export const SearchBoxClear: React.FC<Props> = ({ showClear, handleClear }) => {
  if (showClear === "button") {
    return (
      <Button
        sx={{
          position: "absolute",
          right: "30px",
          top: "10px",
          fill: "currentColor",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&.components-button.has-icon": {
            height: "auto",
            minWidth: "auto",
            color: "gray",
            p: 0,
          },
        }}
        icon={cancelCircleFilled}
        iconSize={26}
        label={__("Clear search")}
        onClick={handleClear}
      />
    );
  }

  if (showClear === "text") {
    return (
      <Button
        sx={{ color: "currentcolor", opacity: 0.7, fontSize: 1 }}
        onClick={handleClear}
      >
        {__("clear")}
      </Button>
    );
  }

  return null;
};
