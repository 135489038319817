export const Automotive: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0041 18.5029V20.0036C21.0041 20.5558 20.5559 21.004 20.0037 21.004H18.0029C17.4506 21.004 17.0024 20.5558 17.0024 20.0036V18.5029"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99825 18.5029V20.0036C6.99825 20.5558 6.55006 21.004 5.99783 21.004H3.997C3.44477 21.004 2.99658 20.5558 2.99658 20.0036V18.5029"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99658 18.5028V13.7439C2.99658 12.9475 3.31271 12.1842 3.87595 11.622L4.99742 10.4995H19.0032L20.1247 11.621C20.688 12.1842 21.0041 12.9475 21.0041 13.7439V18.5028H2.99658Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0042 14.5014L18.0029 14.7515"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99783 14.7515L2.99658 14.5014"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99854 18.503L8.99895 15.5017H15.0015L16.0019 18.503"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0043 9.49913L19.0034 9.99934"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99742 9.99934L2.99658 9.49913"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99756 10.4996V9.9934L5.83391 6.85509C6.12503 5.75963 7.11744 4.99731 8.24991 4.99731H15.8371C16.9946 4.99731 18.001 5.79165 18.2701 6.91811L19.0034 9.9934V10.4996"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
