import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { PromptList } from "@/components/PromptList";
import { EntryPromptKeys } from "@/data/repositories/EntryRepository";
import { DailyPrompt } from "@/data/repositories/PromptsAPI";
import { primaryViewState } from "@/view_state/ViewStates";

type Props = { onClose: () => void };

export type EntriesByPrompt = {
  [promptId: string]: EntryPromptKeys[];
};

export const RecentPromptsModal: React.FC<Props> = ({ onClose }) => {
  const { recentDailyPrompts, dailyPromptSettings } = primaryViewState;
  const [entriesByPromptId, setEntriesByPromptId] = useState<EntriesByPrompt>(
    {},
  );

  const { __ } = useI18n();

  const promptIds = recentDailyPrompts?.map((prompt: DailyPrompt) => prompt.id);

  const prompts = recentDailyPrompts ? [...recentDailyPrompts].reverse() : [];
  useEffect(() => {
    const convertEntries = (entries: EntryPromptKeys[]) => {
      const entriesByPromptId = entries.reduce(
        (acc, entry) => {
          const promptId = entry.promptId;
          if (!promptId) {
            return acc;
          }
          if (!acc[promptId]) {
            acc[promptId] = [];
          }
          acc[promptId].push(entry);
          return acc;
        },
        {} as Record<string, EntryPromptKeys[]>,
      );
      setEntriesByPromptId(entriesByPromptId);
    };
    const unsub = d1Classes.entryRepository.subToEntriesByPromptIds(
      promptIds,
      convertEntries,
    );
    return () => {
      unsub();
    };
  }, [promptIds.join(",")]);

  return (
    <D1Modal
      onRequestClose={onClose}
      title={__("Daily Prompts")}
      sx={{ maxWidth: "430px" }}
    >
      <div
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <PromptList
          prompts={prompts}
          type="daily"
          journalId={dailyPromptSettings.default_journal || ""}
          entriesByPromptId={entriesByPromptId}
          tags={dailyPromptSettings.tags}
          onAction={onClose}
        />
      </div>
    </D1Modal>
  );
};
