import { FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { Item } from "@/components/ItemGroup/Item";
import { AvatarMenu } from "@/components/Settings/Account/Avatar/AvatarMenu";
import { EditAvatar } from "@/components/Settings/Account/Avatar/EditAvatar";
import { EditColor } from "@/components/Settings/Account/Avatar/EditColor";
import { EditInitials } from "@/components/Settings/Account/Avatar/EditInitials";
import {
  getAvatarDetails,
  AvatarDetails,
  showAvatar,
} from "@/components/Settings/Account/Avatar/utils";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

type Props = {
  primaryViewState: PrimaryViewState;
  inMemberModal?: boolean;
};

export const Avatar: React.FC<Props> = observer(
  ({ primaryViewState, inMemberModal = false }) => {
    const user = primaryViewState.user;

    const [showEdit, setShowEdit] = useState(false);
    const [showChangeColor, setShowChangeColor] = useState(false);
    const [showEditInitials, setShowEditInitials] = useState(false);
    const [mainAvatarDetails, setMainAvatarDetails] =
      useState<AvatarDetails | null>(null);

    useEffect(() => {
      getAvatarDetails(user).then((avatarDetails) =>
        setMainAvatarDetails(avatarDetails),
      );
    }, [user?.avatar, user?.initials, user?.profile_color, user?.display_name]);

    function handleCloseEdit() {
      setShowEdit(false);
    }

    function handleCloseColor() {
      setShowChangeColor(false);
    }

    function handleCloseEditInitials() {
      setShowEditInitials(false);
    }

    return (
      <>
        {showEdit && <EditAvatar handleClose={handleCloseEdit} />}
        {showChangeColor && <EditColor handleClose={handleCloseColor} />}
        {showEditInitials && (
          <EditInitials handleClose={handleCloseEditInitials} />
        )}
        <Item sx={{ flexDirection: inMemberModal ? "column" : "row", gap: 2 }}>
          <FlexItem sx={{ flexGrow: 0, height: "60px", width: "60px" }}>
            {showAvatar(mainAvatarDetails, 60)}
          </FlexItem>
          {!inMemberModal && (
            <FlexItem css={{ flexGrow: 1, paddingLeft: 8 }}>
              <p
                sx={{
                  fontSize: 1,
                  color: "textPrimary",
                  height: "60px",
                  lineHeight: "60px",
                }}
              >
                {__("Profile image")}
              </p>
            </FlexItem>
          )}
          <FlexItem>
            <AvatarMenu
              showEditPhoto={setShowEdit}
              showChooseColor={setShowChangeColor}
              showEditInitials={setShowEditInitials}
            />
          </FlexItem>
        </Item>
      </>
    );
  },
);

Avatar.displayName = "Avatar";
