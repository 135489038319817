import { DOCrypto } from "@/crypto/DOCrypto";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export const decryptJournalName = (
  journalId: string,
  journalName: string,
  vaultRepository: VaultRepository,
) => {
  return DOCrypto.Journal.decryptD1(journalId, journalName, vaultRepository);
};
