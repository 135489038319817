import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
// some values to simulate multiple loading entries
const loadingWidths = [30, 50, 90];

export const SearchLoader: React.FC = () => {
  return (
    <div
      sx={{
        px: 5,
      }}
    >
      {loadingWidths.map((width) => (
        <LoadingMediaPlaceholder key={width} height={10} width={width} />
      ))}
    </div>
  );
};
