import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";

import { ClickableEntryListItem } from "@/components/Search/ClickableEntryListItem";
import { getSingleJournalURL } from "@/data/URLFunctions";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { useIsInSearch } from "@/hooks/useIsInSearch";
import { SearchResultWithDetails } from "@/view_state/SearchViewState";
import { primaryViewState } from "@/view_state/ViewStates";
interface Props {
  closeHandler: () => void;
  searchResults: SearchResultWithDetails[];
}

export const SearchResults: React.FC<Props> = observer(
  ({ closeHandler, searchResults }) => {
    const { SearchContext } = useIsInSearch();
    const { selectedEntryView } = primaryViewState;

    return (
      <SearchContext.Provider value={true}>
        {searchResults.map((result) => (
          <motion.div
            key={result.search_id}
            initial={{
              opacity: 0,
              y: `${result.offset}px`,
            }}
            animate={{
              opacity: 1,
              y: `${result.offset}px`,
            }}
            exit={{ opacity: 0, scale: 1 }}
            transition={{
              y: { type: "spring", stiffness: 290, damping: 25 },
              default: {
                duration: 0.2,
                transition: "easeInOut",
              },
            }}
            sx={{
              height: result.height,
              position: "absolute",
              y: `${result.offset}px`,
              width: "100%",
              px: 3,
            }}
          >
            <ClickableEntryListItem
              key={result.search_id}
              globalEntryId={result.globalId as GlobalEntryID}
              height={result.height}
              onClick={closeHandler}
              baseURL={getSingleJournalURL(
                result.globalId.journal_id,
                selectedEntryView,
              )}
            />
          </motion.div>
        ))}
      </SearchContext.Provider>
    );
  },
);
