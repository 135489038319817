import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";

type Props = {
  hasUnread: boolean;
};

export const MarkAllReadButton: React.FC<Props> = ({ hasUnread }) => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        backgroundColor: "surface_light1_dark5",
        height: "45px",
        borderTop: "1px solid",
        borderColor: "borderPrimary",
        lineHeight: "40px",
        textAlign: "right",
        pr: 4,
        "& .components-button": {
          color: "primary",
          ":disabled": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <Button
        variant="tertiary"
        onClick={() => d1Classes.notificationStore.markAllAsRead()}
        disabled={!hasUnread}
      >
        {__("Mark All Read")}
      </Button>
    </div>
  );
};
