import { Button, Spinner } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { LoadingModal } from "@/components/LoadingModal";
import { MediaDownloadResults } from "@/data/db/migrations/moment";
import { useExport } from "@/hooks/useExport";
import { useShowHideModal } from "@/hooks/useShowHideModal";
import { MediaDownloadStatus } from "@/utils/export";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  journalId: string;
  handleClose(): void;
  initialMediaDownloadStatus: MediaDownloadStatus;
};

export const DownloadMedia: React.FC<Props> = ({
  journalId,
  handleClose,
  initialMediaDownloadStatus,
}) => {
  const { __, _n } = useI18n();
  const [mediaDownloadStatus, setMediaDownloadStatus] =
    useState<MediaDownloadStatus>(initialMediaDownloadStatus);

  const { generateZip, cancel, setCancel } = useExport();

  const [failedMedia, setFailedMedia] = useState<
    MediaDownloadResults["details"][]
  >([]);

  const { showModal, hideModal, shouldShowModal } = useShowHideModal();

  const journal = primaryViewState.getJournalById(journalId);

  const showDownloadButton =
    mediaDownloadStatus === "NOT_STARTED" ||
    mediaDownloadStatus === "DOWNLOADING" ||
    (mediaDownloadStatus === "SOME_MISSING" && failedMedia.length === 0);

  const exportFinished = () => {
    handleClose();
    hideModal();
    viewStates.snackbar.newMessage(__("Journal exported successfully"));
  };

  const cancelExport = () => {
    handleClose();
    hideModal();
    viewStates.snackbar.newMessage(__("Export cancelled"));
  };

  const modalTitle = showDownloadButton
    ? __("Download Media?")
    : __("Continue Export");

  return (
    <>
      <D1Modal title={modalTitle} onRequestClose={handleClose}>
        <div
          sx={{
            display: "flex",
            maxWidth: "420px",
            flexDirection: "column",
            gap: 2,
            p: {
              lineHeight: "body",
            },
          }}
        >
          {showDownloadButton && (
            <>
              <p>
                {__(
                  "Some selected photos, videos, pdfs or audios are not currently stored on this device. Would you like to download them before continuing?",
                )}
              </p>
              <Button
                sx={{
                  "&& svg": {
                    mt: 0,
                  },
                }}
                variant="primary"
                disabled={mediaDownloadStatus === "DOWNLOADING"}
                onClick={() => {
                  setMediaDownloadStatus("DOWNLOADING");
                  d1Classes.journalStore
                    .downloadAllJournalMedia(journalId)
                    .then((results) => {
                      if (results.allSuccess) {
                        setMediaDownloadStatus("HAVE_ALL");
                      } else if (results.failed) {
                        setMediaDownloadStatus("SOME_MISSING");
                        setFailedMedia(results.failed);
                      }
                    });
                }}
              >
                {mediaDownloadStatus === "DOWNLOADING" ? (
                  <>
                    <Spinner /> {__("Downloading")}
                  </>
                ) : (
                  <>{__("Download now")}</>
                )}
              </Button>
            </>
          )}
          <Button
            disabled={mediaDownloadStatus === "DOWNLOADING"}
            variant="primary"
            onClick={async () => {
              if (!journal) {
                return;
              }
              const cancelFn = await generateZip(
                journal,
                exportFinished,
                showModal,
                cancelExport,
                true,
                failedMedia,
              );
              setCancel({ fn: cancelFn });
            }}
          >
            {__("Continue export")}
          </Button>
          {failedMedia.length > 0 && (
            <p>
              {sprintf(
                _n(
                  "%d media file was not able to be included in the export. Details will be in the export file.",
                  "%d media files were not able to be included in the export. Details will be in the export file.",
                  failedMedia.length,
                ),
                failedMedia.length,
              )}
            </p>
          )}
        </div>
      </D1Modal>
      {shouldShowModal && (
        <LoadingModal
          title={__("Exporting Journal")}
          handleClose={hideModal}
          action={cancel?.fn}
          actionLabel={__("Cancel Export")}
        />
      )}
    </>
  );
};
