import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { BackupButton } from "./BackupButton";

import { Sentry } from "@/Sentry";
import { GoogleLogoOneColor } from "@/components/icons/GoogleLogoOneColor";
import { useGoogleDrive } from "@/hooks/google/useGoogleDrive";
import {
  masterKeyViewState,
  primaryViewState,
  viewStates,
} from "@/view_state/ViewStates";

type Props = {
  onSave: () => void;
  masterKeyString: string;
};

export const GoogleDriveBackupButton: React.FC<Props> = observer(
  ({ onSave, masterKeyString }) => {
    const showSnackbar = (message: string) => {
      viewStates.snackbar.newMessage(message);
    };

    const {
      connectGoogleDrive,
      authorized: connectedGDrive,
      saveEncryptionKey,
      error: googleDriveError,
    } = useGoogleDrive(primaryViewState);

    useEffect(() => {
      if (googleDriveError) {
        const errorMessage = `Google Drive Error: ${googleDriveError}`;
        const error = new Error(errorMessage);
        Sentry.captureException(error);
      }
    }, [googleDriveError]);

    useEffect(() => {
      if (connectedGDrive) {
        const saveKey = async () => {
          try {
            masterKeyViewState.setWorkingWithGDrive(true);
            await saveEncryptionKey(masterKeyString);
            showSnackbar(__("Encryption key saved to Google Drive"));
            await onSave();
            masterKeyViewState.setHasSavedKeyToGDrive(true);
          } catch (error) {
            const errorMessage = `Failed to save encryption key to Google Drive: ${
              (error as Error).message
            }`;
            showSnackbar(__("Failed to save encryption key to Google Drive"));
            Sentry.captureException(new Error(errorMessage));
          } finally {
            masterKeyViewState.setWorkingWithGDrive(false);
          }
        };
        saveKey();
      }
    }, [connectedGDrive]);

    const { __ } = useI18n();

    return (
      <BackupButton
        position="top"
        backedUp={masterKeyViewState.hasSavedKeyToGDrive}
        disabled={masterKeyViewState.hasSavedKeyToGDrive}
        icon={<GoogleLogoOneColor />}
        onClick={() => {
          // after this completes, connectedGDrive will be true and trigger saveEncryptionKey
          connectGoogleDrive(__("Google Drive connection failed"));
        }}
        label={
          masterKeyViewState.hasSavedKeyToGDrive
            ? __("Saved to Google Drive")
            : !masterKeyViewState.workingWithGDrive
              ? __("Back up with Google Drive")
              : __("Backing up...")
        }
      >
        {masterKeyViewState.hasSavedKeyToGDrive
          ? __("Saved to Google Drive")
          : !masterKeyViewState.workingWithGDrive
            ? __("Back up with Google Drive")
            : __("Backing up...")}
      </BackupButton>
    );
  },
);
