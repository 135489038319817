import { observer } from "mobx-react-lite";

import { OpenInAppAction } from "@/pages/prefilledUrl/OpenInAppAction";
import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";
import { SaveEntry } from "@/pages/prefilledUrl/SaveEntry";
import { SaveTemplate } from "@/pages/prefilledUrl/SaveTemplate";
import { featureFlagsViewState } from "@/view_state/ViewStates";

type Props = {
  state: PrefilledEntryViewState;
  isMobile: boolean;
};

export const Action: React.FC<Props> = observer(({ state }) => {
  const { showSaveTemplate } = featureFlagsViewState;
  const allowSaveTemplate = showSaveTemplate && state.type === "entry";

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "center",
        alignItems: "center",
        gap: 2,
        width: "320px",
        maxWidth: "100%",
      }}
    >
      {state.needsSignInOrSync ? (
        <OpenInAppAction state={state} />
      ) : (
        <>
          <SaveEntry state={state} />
          {allowSaveTemplate && <SaveTemplate state={state} />}
        </>
      )}
    </div>
  );
});
