export const ShieldLock: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <path
        d="M36 64.4419C29.5116 62.6727 24.1395 58.8535 19.8837 52.9843C15.628 47.1151 13.5001 40.5535 13.5001 33.2997V16.0383L36 7.61523L58.4999 16.0383V33.2997C58.4999 40.5535 56.372 47.1151 52.1163 52.9843C47.8605 58.8535 42.4885 62.6727 36 64.4419ZM36 59.6997C41.2 58.0497 45.5 54.7497 48.9 49.7997C52.3 44.8497 54 39.3497 54 33.2997V19.1247L36 12.4036L18 19.1247V33.2997C18 39.3497 19.7 44.8497 23.1 49.7997C26.5 54.7497 30.8 58.0497 36 59.6997ZM30.0577 47.4804H41.9423C42.7106 47.4804 43.3546 47.2206 43.8743 46.7009C44.394 46.1812 44.6538 45.5372 44.6538 44.769V35.8843C44.6538 35.1161 44.394 34.4721 43.8743 33.9524C43.3546 33.4327 42.7106 33.1728 41.9423 33.1728H41.6827V30.1728C41.6827 28.5997 41.1336 27.2642 40.0355 26.1661C38.9375 25.0681 37.6019 24.519 36.0289 24.519C34.4558 24.519 33.1202 25.0681 32.0222 26.1661C30.9241 27.2642 30.375 28.5997 30.375 30.1728V33.1728H30.0577C29.2894 33.1728 28.6455 33.4327 28.1258 33.9524C27.6061 34.4721 27.3462 35.1161 27.3462 35.8843V44.769C27.3462 45.5372 27.6061 46.1812 28.1258 46.7009C28.6455 47.2206 29.2894 47.4804 30.0577 47.4804ZM33.0289 33.1728V30.1728C33.0289 29.3228 33.3164 28.6103 33.8914 28.0353C34.4664 27.4603 35.1789 27.1728 36.0289 27.1728C36.8789 27.1728 37.5914 27.4603 38.1664 28.0353C38.7414 28.6103 39.0289 29.3228 39.0289 30.1728V33.1728H33.0289Z"
        fill="currentColor"
      />
    </svg>
  );
};
