import { SearchResult } from "minisearch";
import { computed, makeAutoObservable, reaction, toJS } from "mobx";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Sentry } from "@/Sentry";
import {
  MusicFilterOptions,
  ActivityFilterOptions,
  CreationDeviceFilterOptions,
  MultipleFilterOptions,
  PlaceFilterOptions,
  PromptFilterOptions,
  TemplateFilterOptions,
  WeatherFilterOptions,
  MediaFilterOptions,
} from "@/components/Search/FilterPills";
import {
  ENTRY_LIST_ITEM_HEIGHT,
  SHARED_ENTRY_LIST_ITEM_HEIGHT,
} from "@/components/TimelineView/constants";
import { EntryDBRow, GlobalEntryID } from "@/data/db/migrations/entry";
import { JournalSyncInfo } from "@/data/db/migrations/journal_sync_info";
import { TagDBRow } from "@/data/db/migrations/tag";
import { JournalSyncStatus } from "@/data/repositories/SyncStateRepository";
import { SearchService } from "@/data/services/SearchService";
import { sanitizeText } from "@/utils/strings";
import { AtLeast } from "@/utils/types";
import { primaryViewState } from "@/view_state/ViewStates";
import {
  FavoriteEntry,
  FilterableValues,
  NO_FILTER,
  SearchWorkerOutgoingMessage,
  baseSearchOptions,
} from "@/worker/SearchWorkerTypes";

const MAX_RECENT_SEARCHES = 5;

export type PartialEntryDBRow = AtLeast<
  EntryDBRow,
  "body" | "date" | "journal_id" | "id"
>;

type JournalIdGroups = { [K in JournalSyncStatus]?: string[] };

type IndexStatus = "INITIAL" | "INDEXING" | "DONE" | "DOWNLOADING";

export type SearchResultWithDetails = SearchResult & {
  height: number;
  offset: number;
};

export class SearchViewState {
  searchService = new SearchService();
  searchString = "";
  focused: string | undefined = undefined;

  private _indexStatus = "INITIAL";
  private _searchResults: SearchResultWithDetails[] = [];
  private reactionDisposer?: () => void;
  private _searchHistory: string[] = [];
  private _filter: FilterableValues = NO_FILTER;

  tagCounts: { [key: string]: number } = {};
  favoriteEntries: FavoriteEntry[] = [];
  isSearching = false;
  filterDropdownOpen = false;
  contentHeight = 0;
  scrollTop = 0;
  prompts: PromptFilterOptions[] = [];
  templates: TemplateFilterOptions[] = [];
  creationDevices: CreationDeviceFilterOptions[] = [];
  weather: WeatherFilterOptions[] = [];
  music: MusicFilterOptions[] = [];
  placeNames: PlaceFilterOptions[] = [];
  activities: ActivityFilterOptions[] = [];

  media: MediaFilterOptions[] = [
    {
      media: "image",
      entryCount: 0,
    },
    {
      media: "video",
      entryCount: 0,
    },
    {
      media: "audio",
      entryCount: 0,
    },
    {
      media: "pdfAttachment",
      entryCount: 0,
    },
  ];

  constructor() {
    this.searchService.index();
    const searchWorker = this.searchService.getSearchWorker();
    if (searchWorker) {
      searchWorker.onmessage = (
        event: MessageEvent<SearchWorkerOutgoingMessage>,
      ) => {
        // Handle messages back from the search worker here
        switch (event.data.type) {
          case "indexEmpty":
            this.setIndexStatus("INITIAL");
            break;
          case "indexingStart":
            this.setIndexStatus("INDEXING");
            break;
          case "indexingDone":
            this.setIndexStatus("DONE");
            break;
          case "updateFilters":
            if (event.data.favoriteEntries) {
              this.favoriteEntries = event.data.favoriteEntries;
            }
            if (event.data.tagCounts) {
              this.tagCounts = event.data.tagCounts;
            }
            if (event.data.prompts) {
              this.prompts = event.data.prompts;
            }
            if (event.data.templates) {
              this.templates = event.data.templates;
            }
            if (event.data.creationDevices) {
              this.creationDevices = event.data.creationDevices;
            }
            if (event.data.weather) {
              this.weather = event.data.weather;
            }
            if (event.data.music) {
              this.music = event.data.music;
            }
            if (event.data.placeNames) {
              this.placeNames = event.data.placeNames;
            }
            if (event.data.activities) {
              this.activities = event.data.activities;
            }
            if (event.data.media) {
              this.media = event.data.media;
            }
            break;
          case "searchResults":
            if (event.data.results) {
              this.setSearchResults(event.data.results);
            }
            break;
        }
      };
    }
    makeAutoObservable(
      this,
      {
        moveUp: false,
        moveDown: false,
        fixScrollPosition: false,
        getIndexForSearchId: false,
        focusElement: false,
      },
      { autoBind: true },
    );

    // Ensure the search item is in view if the focused item changes
    reaction(
      () => this.focused,
      (focused) => {
        if (focused) {
          this.fixScrollPosition(focused);
        }
      },
      {
        name: "SearchViewState_watchFocused",
        fireImmediately: true,
      },
    );
  }

  setFocused = (searchID: string) => {
    this.focusElement(searchID);
    this.focused = searchID;
  };

  fixScrollPosition = (searchID: string) => {
    const isVisible = this.window.visibleSlice.find(
      (item) => item.search_id === searchID,
    );

    const index = this.getIndexForSearchId(searchID);

    const scrollPosition = this._searchResults[index].offset;

    if (
      !isVisible ||
      scrollPosition < this.scrollTop ||
      scrollPosition >
        this.scrollTop +
          this.contentHeight -
          this._searchResults[this._searchResults.length - 1].height
    ) {
      this.setScrollTop(scrollPosition);
      this.focusElement(searchID);
    }
  };

  focusElement = (searchID?: string) => {
    if (!searchID) {
      return;
    }
    const elementId = `search-item-${searchID}`;
    const element = document.querySelector(`#${elementId} a`) as HTMLElement;
    element?.focus();
  };

  setContentHeight(height: number) {
    this.contentHeight = height;
  }

  setScrollTop(scrollTop: number) {
    this.scrollTop = scrollTop;
  }

  // Computed Views
  get window() {
    const padding = 12;
    const skip = getElementsToSkip(
      this._searchResults,
      this.scrollTop,
      padding,
    );

    const minNumberToShow = Math.min(
      Math.ceil(this.contentHeight / ENTRY_LIST_ITEM_HEIGHT),
      this._searchResults.length,
    );
    const take = minNumberToShow + padding;

    const slice = this._searchResults.slice(
      skip,
      Math.min(skip + take, this._searchResults.length),
    );

    return {
      totalEntryCount: this._searchResults.length,
      fullHeightInPx: getFullHeightInPx(this._searchResults),
      visibleSlice: slice,
      entriesSkipped: skip,
    };
  }

  get isFilterOnBlankSearch() {
    return !this.isNoFilter && this.searchString === "";
  }

  get isBlankSearch() {
    return this.searchString === "";
  }

  get searchResults() {
    return this._searchResults;
  }

  setSearchResults(results: SearchResult[]) {
    this._searchResults = getSearchResultsWithDetails(results);
    if (this._searchResults.length > 0) {
      requestAnimationFrame(() => {
        this.focused = this._searchResults[0].id;
      });
    }
    this.isSearching = false;
  }

  async refreshIndex() {
    this.searchService.refreshIndex();
  }

  clearSearchResults() {
    this.setSearchResults([]);
  }

  setSearchString(value: string) {
    const sanitizedValue = sanitizeText(value);
    this.searchString = sanitizedValue;
  }

  async getTags() {
    return await d1Classes.db.tags.toArray();
  }

  get indexStatus() {
    return this._indexStatus;
  }

  setIndexStatus(status: IndexStatus) {
    this._indexStatus = status;
  }

  getEntryTags(tags: TagDBRow[], globalId: GlobalEntryID) {
    const tagsForEntry: string[] = [];
    for (const tag of tags) {
      if (
        tag.entry_id === globalId.id &&
        tag.journal_id === globalId.journal_id
      ) {
        tagsForEntry.push(tag.tag);
      }
    }
    return tagsForEntry;
  }

  get searchHistory() {
    return this._searchHistory;
  }

  saveSearchHistory(searchString: string) {
    if (searchString.trim() === "") return;

    // remove the search string if it already exists
    // and push it to the end of the array
    const searchStringIndex = this._searchHistory.findIndex(
      (search) => search.toLowerCase() === searchString.toLowerCase(),
    );

    if (searchStringIndex !== -1) {
      this._searchHistory.splice(searchStringIndex, 1);
    }

    this._searchHistory.push(searchString);
    // save only the last MAX_RECENT_SEARCHES search strings
    if (this.searchHistory.length > MAX_RECENT_SEARCHES) {
      this._searchHistory.shift();
    }
  }

  setFilter(filter: Partial<FilterableValues>) {
    this.isSearching = true;
    this._filter = { ...this._filter, ...filter };
    this.searchService.setFilter(toJS(this._filter));
  }

  resetFilter() {
    this._filter = NO_FILTER;
    this.searchService.setFilter(NO_FILTER);
  }

  setFilterDropdownOpen(isOpen: boolean) {
    this.filterDropdownOpen = isOpen;
  }

  get isNoFilter() {
    return JSON.stringify(this._filter) === JSON.stringify(NO_FILTER);
  }

  get filter() {
    return this._filter;
  }

  get filterCounter() {
    const counter = Object.keys(this._filter).filter((key) => {
      const value = this._filter[key as keyof FilterableValues];
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (typeof value === "boolean") {
        return value;
      }
      if (typeof value === "number") {
        if (key === "minDate" && this._filter.maxDate !== null) {
          return true;
        }
        // Since we only want to count Date once, we choose to go with minDate
        if (key === "maxDate") {
          return false;
        }
        if (value) {
          return value > 0;
        }
        return false;
      }
    }).length;
    return counter;
  }

  setFilterByType(
    type: MultipleFilterOptions["type"],
    value: string[],
    toggleAll?: boolean,
  ) {
    switch (type) {
      case "prompt":
        if (toggleAll) {
          if (this._filter.prompts.length === this.prompts.length) {
            this.setFilter({ prompts: [] });
          } else {
            const allPrompts = this.prompts.map((prompt) => prompt.prompt.id);
            this.setFilter({
              prompts: allPrompts,
            });
          }
        } else {
          this.setFilter({ prompts: value });
        }
        break;
      case "template":
        if (toggleAll) {
          if (this._filter.templates.length === this.templates.length) {
            this.setFilter({ templates: [] });
          } else {
            const allTemplates = this.templates.map(
              (template) => template.template.id,
            );
            this.setFilter({
              templates: allTemplates,
            });
          }
        } else {
          this.setFilter({ templates: value });
        }
        break;
      case "creationDevice":
        if (toggleAll) {
          if (
            this._filter.creationDevices.length === this.creationDevices.length
          ) {
            this.setFilter({ creationDevices: [] });
          } else {
            const allCreationDevices = this.creationDevices.map(
              (creationDevice) => creationDevice.deviceName,
            );
            this.setFilter({
              creationDevices: allCreationDevices,
            });
          }
        } else {
          this.setFilter({ creationDevices: value });
        }
        break;
      case "weather":
        if (toggleAll) {
          if (this._filter.weather.length === this.weather.length) {
            this.setFilter({ weather: [] });
          } else {
            const allWeather = this.weather.map(
              (weather) => weather.weather?.code ?? "",
            );
            this.setFilter({
              weather: allWeather,
            });
          }
        } else {
          this.setFilter({ weather: value });
        }
        break;
      case "music":
        if (toggleAll) {
          if (this._filter.music.length === this.music.length) {
            this.setFilter({ music: [] });
          } else {
            const allMusic = this.music.map(
              (music) => music.music?.artist ?? "",
            );
            this.setFilter({
              music: allMusic,
            });
          }
        } else {
          this.setFilter({ music: value });
        }
        break;
      case "place":
        if (toggleAll) {
          if (this._filter.placeNames.length === this.placeNames.length) {
            this.setFilter({ placeNames: [] });
          } else {
            const allPlaceNames = this.placeNames.map(
              (place) => place.place?.placeName ?? "",
            );
            this.setFilter({ placeNames: allPlaceNames });
          }
        } else {
          this.setFilter({ placeNames: value });
        }
        break;
      case "activity":
        if (toggleAll) {
          if (this._filter.activities.length === this.activities.length) {
            this.setFilter({ activities: [] });
          } else {
            const allActivities = this.activities.map(
              (activity) => activity.activity,
            );
            this.setFilter({ activities: allActivities });
          }
        } else {
          this.setFilter({ activities: value });
        }
        break;
      case "media":
        if (toggleAll) {
          if (this._filter.media.length === this.media.length) {
            this.setFilter({ media: [] });
          } else {
            const allMedia = this.media.map((media) => media.media);
            this.setFilter({ media: allMedia });
          }
        } else {
          this.setFilter({ media: value });
        }
        break;
    }
  }

  search(query: string) {
    this.setSearchString(query);
    this.isSearching = true;
    this.searchService.search(query === "" ? null : query, {
      ...baseSearchOptions,
    });
  }

  // Start methods for handling syncing journals

  async manageDownloadSyncableJournals() {
    const { visibleSyncableJournals: journals, getSyncStateForJournal } =
      primaryViewState;
    const journalSyncInfo = await this.getJournalSyncInfo();
    const journalsToAddToSync = journals.filter(
      (journal) =>
        !journalSyncInfo.some(
          (journalSyncInfo: JournalSyncInfo) =>
            journalSyncInfo.journal_id === journal.id,
        ),
    );

    const doSync = async () => {
      this.setIndexStatus("DOWNLOADING");
      const results = await Promise.allSettled(
        journalsToAddToSync.map((journal) => {
          return d1Classes.entryStore.addJournalToSyncJobs(journal.id);
        }),
      );

      // Handle the results
      results.forEach((result, index) => {
        if (result.status === "rejected") {
          const error = new Error(
            `Search - download all journals. Could not add journal to sync jobs ${journalsToAddToSync[index].id}`,
          );
          Sentry.captureException(error);
        }
      });
      d1MainThreadClasses.syncService.sync();
      this.startReaction();
    };
    const cancelDownload = () => {
      d1MainThreadClasses.syncService.stop();
      journalsToAddToSync.forEach((journal) => {
        const syncStatus = getSyncStateForJournal(journal.id);
        if (syncStatus === "WAITING_INITIAL_SYNC") {
          d1Classes.entryStore.removeJournalFromSyncJobs(journal.id);
        }
      });
      this.stopReaction();
    };
    return { doSync, cancelDownload };
  }

  startReaction() {
    this.reactionDisposer = reaction(
      () => this.journalSyncLookup,
      () => {
        if (!this.syncIsRunning) {
          this.refreshIndex();
          this.stopReaction();
        }
      },
    );
  }

  get syncIsRunning() {
    return computed(() => {
      const journalSyncing = this.getJournalIdsOfSyncStatus([
        "SYNCING",
        "WAITING_INITIAL_SYNC",
      ]);
      const journalSyncingCount = this.countAllJournalIds(journalSyncing);
      return journalSyncingCount > 0;
    }).get();
  }

  stopReaction() {
    if (this.reactionDisposer) {
      this.reactionDisposer();
      this.reactionDisposer = undefined;
    }
  }

  get journalSyncLookup() {
    return primaryViewState.journalSyncLookup;
  }

  async getJournalSyncInfo() {
    return Object.entries(this.journalSyncLookup).map(
      ([journalId, journalSyncStatus]) => ({
        journal_id: journalId,
        status: journalSyncStatus,
      }),
    );
  }

  get isPartial() {
    return computed(() => {
      const journalIdsSelectedForSync = this.getJournalIdsOfSyncStatus([
        "IDLE",
        "SYNCING",
      ]);
      const downloadedJournalCount = this.countAllJournalIds(
        journalIdsSelectedForSync,
      );
      const totalJournalCount = primaryViewState.visibleSyncableJournals.length;
      return downloadedJournalCount < totalJournalCount;
    }).get();
  }

  get journalsDownloading() {
    return computed(() => {
      const journalsWithStatus = this.getJournalIdsOfSyncStatus([
        "WAITING_INITIAL_SYNC",
      ]);
      return this.countAllJournalIds(journalsWithStatus);
    }).get();
  }

  private getJournalIdsOfSyncStatus(statuses: string[]): JournalIdGroups {
    return Object.entries(this.journalSyncLookup)
      .filter(([, journalSyncStatus]) => statuses.includes(journalSyncStatus))
      .reduce((acc: JournalIdGroups, [journalId, journalSyncStatus]) => {
        if (!acc[journalSyncStatus]) {
          acc[journalSyncStatus] = [];
        }
        acc[journalSyncStatus]!.push(journalId);
        return acc;
      }, {});
  }

  private countAllJournalIds(journalIdGroups: JournalIdGroups): number {
    return Object.values(journalIdGroups).reduce(
      (total, journalIdsArray) => total + journalIdsArray.length,
      0,
    );
  }

  // End methods for handling syncing journals

  getIndexForSearchId = (searchID: string) => {
    const idx = this._searchResults.findIndex((x) => searchID === x.search_id);
    if (idx === -1) {
      return 0;
    }
    return idx;
  };

  moveDown = () => {
    const next = this.focused ? this.getIndexForSearchId(this.focused) + 1 : 0;
    const nextIndex =
      next > this._searchResults.length - 1
        ? this._searchResults.length - 1
        : next;
    if (this._searchResults[nextIndex]) {
      this.setFocused(this._searchResults[nextIndex].id);
    } else {
      this.setFocused(this._searchResults[this._searchResults.length - 1].id);
    }
  };

  moveUp = () => {
    const prev = this.focused
      ? this.getIndexForSearchId(this.focused) - 1
      : this._searchResults.length - 1;
    const prevIndex = prev < 0 ? 0 : prev;
    if (this._searchResults[prevIndex]) {
      this.setFocused(this._searchResults[prevIndex].id);
    } else {
      this.setFocused(this._searchResults[0].id);
    }
  };
}

function getSearchResultsWithDetails(searchResults: SearchResult[]) {
  let offset = 0;

  return searchResults.map((item) => {
    const height = item.isShared
      ? SHARED_ENTRY_LIST_ITEM_HEIGHT
      : ENTRY_LIST_ITEM_HEIGHT;
    const result = {
      ...item,
      height,
      offset,
    };
    offset += result.height;
    return result;
  });
}

function getElementsToSkip(
  allElements: SearchResultWithDetails[],
  scrollTop: number,
  padding: number,
) {
  if (scrollTop === 0) {
    return 0;
  }
  for (let i = 0; i < allElements.length; i++) {
    if (allElements[i].offset + allElements[i].height > scrollTop) {
      return Math.max(0, i - padding);
    }
  }
  return 0;
}

function getFullHeightInPx(allElements: SearchResultWithDetails[]): number {
  if (allElements.length === 0) {
    return 0;
  }

  return (
    allElements[allElements.length - 1].offset +
    allElements[allElements.length - 1].height
  );
}
