import { BlockInstance } from "@wordpress/blocks";

import { rgbaToHex } from "./formatting";

import { TextNode } from "@/../types/rtj-format";

export const convertGBHeadingToRTJ = (block: BlockInstance) => {
  const headingNodes = new DOMParser()
    .parseFromString(block.attributes.content, "text/html")
    .querySelector("body")?.childNodes;

  const heading = parseHTMLHeading(
    headingNodes ? Array.from(headingNodes) : [],
    block.attributes.level,
  );

  if (heading.length > 0 && heading[heading.length - 1].text) {
    heading[heading.length - 1].text += "\n";
  }

  return heading.map((item: TextNode) => {
    item.text = item.text.replace(/\n\n$/g, "\n");
    return item;
  });
};

type HeadingState = {
  items: TextNode[];
  currentFormatting: {
    bold?: boolean;
    highlightedColor?: string;
    inlineCode?: boolean;
    italic?: boolean;
    linkURL?: string;
    strikethrough?: boolean;
  };
};

export const parseHTMLHeading = (
  htmlNodes: Node[],
  level: number,
  state: HeadingState = {
    items: [],
    currentFormatting: {},
  },
) => {
  htmlNodes.forEach((node) => {
    switch (node.nodeName) {
      case "A":
        state.currentFormatting.linkURL = (node as HTMLAnchorElement).href;
        break;
      case "CODE":
        state.currentFormatting.inlineCode = true;
        break;
      case "STRONG":
        state.currentFormatting.bold = true;
        break;
      case "EM":
        state.currentFormatting.italic = true;
        break;
      case "MARK": {
        const bgColor = (node as HTMLElement).style.getPropertyValue(
          "background-color",
        );
        state.currentFormatting.highlightedColor = rgbaToHex(bgColor);
        break;
      }
      case "S": {
        state.currentFormatting.strikethrough = true;
        break;
      }
      case "#text": {
        const text = node.textContent || "";
        state.items.push({
          attributes: {
            line: {
              header: level,
            },
            ...state.currentFormatting,
          },
          text,
        });
        state.currentFormatting = {};
        break;
      }
    }

    if (node.childNodes.length) {
      parseHTMLHeading(Array.from(node.childNodes), level, state);
    }
  });

  return state.items;
};
