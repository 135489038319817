import { startRegistration } from "@simplewebauthn/browser";
import { makeAutoObservable } from "mobx";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { UserModel } from "@/data/models/UserModel";
import { getClientMeta } from "@/data/utils/clientMeta";

export type PasskeyInfo = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
};

export class PasskeysManagerModalViewState {
  loading = true;
  infos: PasskeyInfo[] = [];
  failed = false;

  newKeyFormState = {
    name: "",
    sending: false,
    failed: false,
  };

  deleteKeyState = {
    sendingForId: null as string | null,
    failed: false,
  };

  constructor(
    private fetchWrapper: FetchWrapper,
    private user: UserModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.newKeyFormState.name = this.user.email
      ? this.user.email
      : this.user.display_name
        ? this.user.display_name
        : this.user.id;

    // Load up the browser name if we can and add it to the name
    // so that the user can tell which browser they registered the key with
    getClientMeta().then((meta) => {
      if (meta.browserName) {
        this.newKeyFormState.name += " " + meta.browserName;
      }
    });

    this.load();
  }

  *load(): any {
    try {
      const resp = yield this.fetchWrapper.fetchAPI(
        "/users/authenticators/webauthn",
        {
          method: "GET",
        },
        {
          referrer: true,
        },
      );
      const infos = yield resp.json();
      if (infos.created_at) infos.created_at = new Date(infos?.created_at);
      if (infos.updated_at) infos.updated_at = new Date(infos?.updated_at);
      this.infos = infos;
    } catch (e) {
      Sentry.captureException(e);
      this.failed = true;
    } finally {
      this.loading = false;
    }
  }

  setPasskeyNameInputValue(value: string) {
    this.newKeyFormState.name = value;
  }

  *makeNewPasskey(): any {
    this.newKeyFormState.sending = true;
    try {
      const resp = yield this.fetchWrapper.fetchAPI(
        "/users/authenticators/webauthn/init",
        {
          method: "POST",
          body: JSON.stringify({
            name: this.newKeyFormState.name,
          }),
        },
        {
          referrer: true,
        },
      );
      const initData = yield resp.json();
      const stuffToFinalize = yield startRegistration(initData);
      yield this.fetchWrapper.fetchAPI(
        "/users/authenticators/webauthn/confirm",
        {
          method: "POST",
          body: JSON.stringify(stuffToFinalize),
        },
        {
          referrer: true,
        },
      );
      yield this.load();
      this.newKeyFormState = {
        name: "",
        sending: false,
        failed: false,
      };
    } catch (e) {
      Sentry.captureException(e);
      this.newKeyFormState.failed = true;
    } finally {
      this.newKeyFormState.sending = false;
    }
  }

  *deleteKey(id: string): any {
    const confirmed = window.confirm(
      `Are you sure you want to delete the passkey ${
        this.infos.find((info) => info.id == id)?.name
      }?
  
  PLEASE NOTE: this will delete the passkey from the server, preventing you from logging back in with it. But it will not delete it from your browser. You will need to delete it from your browser's credential manager manually. 
		`,
    );
    if (confirmed) {
      try {
        this.deleteKeyState.sendingForId = id;
        yield this.fetchWrapper.fetchAPI(
          `/users/authenticators/webauthn/${id}`,
          {
            method: "DELETE",
          },
          {
            referrer: true,
          },
        );
        yield this.load();
        this.deleteKeyState = {
          sendingForId: null,
          failed: false,
        };
      } catch (e) {
        Sentry.captureException(e);
        this.deleteKeyState.failed = true;
      } finally {
        this.deleteKeyState.sendingForId = null;
      }
    }
  }
}
