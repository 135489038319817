import { JournalDBRow } from "@/data/db/migrations/journal";

type Props = {
  journal?: JournalDBRow;
};

export const JournalName: React.FC<Props> = ({ journal }) => {
  if (!journal) return <div />;
  return (
    <span
      sx={{
        color: journal.color,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        pr: 1,
      }}
    >
      {journal.name}
    </span>
  );
};
