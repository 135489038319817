import { ThemeUIStyleObject } from "@theme-ui/css";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { MarkdownPreview } from "@/components/EntryPreview/MarkdownPreview";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { EntryModel } from "@/data/models/EntryModel";
import { primaryViewState } from "@/view_state/ViewStates";

type EntryPreviewProps = {
  entry: EntryModel;
  entryText: string;
  entryJournal?: JournalDBRow;
  invertedText?: boolean;
};

export const styles: ThemeUIStyleObject = {
  display: "flex",
  width: "100%",
  minWidth: 0,
  alignItems: "center",
};

export const EntryPreview: React.FC<EntryPreviewProps> = observer(
  ({ entry, entryText, entryJournal, invertedText }) => {
    const { selectedGlobalEntryID, revealEntry } = primaryViewState;
    const selectedJournal = primaryViewState.selectedJournal;

    useEffect(() => {
      if (
        selectedGlobalEntryID?.id === entry.id &&
        (selectedJournal?.conceal == 1 || entryJournal?.conceal)
      ) {
        revealEntry(selectedGlobalEntryID);
      }
    }, [
      selectedGlobalEntryID?.id,
      selectedGlobalEntryID?.journal_id,
      selectedJournal?.conceal,
    ]);

    return (
      <div sx={styles}>
        <MarkdownPreview entryText={entryText} invertedText={invertedText} />
      </div>
    );
  },
);

EntryPreview.displayName = "EntryPreview";
