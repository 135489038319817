import { nanoid } from "nanoid";

import { ChecklistNode, ListNode } from "@/../types/rtj-format";
import { CheckListItem } from "@/components/Editor/blocks/checklist/CheckListItemBlock";
import {
  convertTextToMarkup,
  endsWithNewLine,
} from "@/components/Editor/rtj2gb/format-node";
import {
  ListItemBlockAttributes,
  createListItemBlock,
} from "@/components/Editor/rtj2gb/rtj2gb";
import { takeUntil } from "@/components/Editor/utils/take-until";

type ListMarkup = {
  indent: number;
  markup: string;
};

export const convertListToMarkup = (
  nodes: ListNode[],
  listMarkup: ListMarkup = {
    indent: 1,
    markup: "<li>",
  },
) => {
  return nodes.reduce((_, node, index, arr) => {
    const currentListTag =
      node.attributes.line.listStyle === "numbered" ? "ol" : "ul";
    const currentIndent = node.attributes.line.indentLevel;

    // if node contains line ending then close list item
    if (currentIndent > listMarkup.indent) {
      listMarkup.indent = currentIndent;
      listMarkup.markup += `<${currentListTag}><li>${convertTextToMarkup(
        node,
      )}`;

      if (endsWithNewLine(node.text)) {
        listMarkup.markup += "</li>";
      }
    } else if (currentIndent === listMarkup.indent) {
      if (
        listMarkup.markup.endsWith("</li>") ||
        listMarkup.markup.endsWith(`<${currentListTag}>`)
      ) {
        listMarkup.markup += "<li>";
      }

      listMarkup.markup += convertTextToMarkup(node);

      // first check if the next node will open a sublist
      if (
        arr[index + 1] &&
        arr[index + 1].attributes.line.indentLevel === currentIndent
      ) {
        if (endsWithNewLine(node.text)) {
          listMarkup.markup += "</li>";
        }
      }
    } else if (currentIndent < listMarkup.indent) {
      listMarkup.indent = currentIndent;
      listMarkup.markup += `</${currentListTag}></li>`;
      listMarkup.markup += `<li>${convertTextToMarkup(node)}`;

      if (endsWithNewLine(node.text)) {
        listMarkup.markup += "</li>";
      }
    }

    return listMarkup.markup.endsWith("</li>")
      ? listMarkup.markup
      : listMarkup.markup + "</li>";
  }, "");
};

export const convertChecklistNodesToBlockAttributes = (
  nodes: ChecklistNode[],
) => {
  const result = nodes.reduce<{
    skippedItems: number;
    checklistBlocks: CheckListItem[];
  }>(
    (acc, _, index, arr) => {
      const currentIndex = index + acc.skippedItems;
      const currentNode = arr[index + acc.skippedItems];
      if (currentNode) {
        if (!endsWithNewLine(currentNode.text)) {
          const nodeWithSiblings = takeUntil(arr.slice(currentIndex), (node) =>
            endsWithNewLine(node.text),
          );

          const formattedTexts = nodeWithSiblings.map((node) =>
            convertTextToMarkup(node),
          );

          return {
            skippedItems: (acc.skippedItems += nodeWithSiblings.length - 1),
            checklistBlocks: [
              ...acc.checklistBlocks,
              {
                checked: currentNode.attributes.line.checked,
                indent: currentNode.attributes.line.indentLevel,
                content: formattedTexts.join(""),
                identifier: currentNode.attributes.line.identifier || nanoid(),
              },
            ],
          };
        } else {
          return {
            ...acc,
            checklistBlocks: [
              ...acc.checklistBlocks,
              {
                checked: currentNode.attributes.line.checked,
                indent: currentNode.attributes.line.indentLevel,
                content: convertTextToMarkup(currentNode),
                identifier: currentNode.attributes.line.identifier || nanoid(),
              },
            ],
          };
        }
      }

      return acc;
    },
    {
      skippedItems: 0,
      checklistBlocks: [],
    },
  );

  return result.checklistBlocks;
};

export const convertNodesToListBlockAttributes = (nodes: ListNode[]) => {
  const result = nodes.reduce<{
    skippedItems: number;
    listBlocks: ListItemBlockAttributes[];
  }>(
    (acc, _, index, arr) => {
      const currentIndex = index + acc.skippedItems;
      const currentNode = arr[currentIndex];
      if (currentNode) {
        if (!endsWithNewLine(currentNode.text)) {
          const nodeWithSiblings = takeUntil(arr.slice(currentIndex), (node) =>
            endsWithNewLine(node.text),
          );

          const formattedTexts = nodeWithSiblings.map((node) =>
            convertTextToMarkup(node),
          );

          return {
            skippedItems: (acc.skippedItems += nodeWithSiblings.length - 1),
            listBlocks: [
              ...acc.listBlocks,
              {
                indentLevel: currentNode.attributes.line.indentLevel,
                block: createListItemBlock(formattedTexts.join("")),
                ordered: currentNode.attributes.line.listStyle === "numbered",
              },
            ],
          };
        } else {
          return {
            ...acc,
            listBlocks: [
              ...acc.listBlocks,
              {
                indentLevel: currentNode.attributes.line.indentLevel,
                block: createListItemBlock(convertTextToMarkup(currentNode)),
                ordered: currentNode.attributes.line.listStyle === "numbered",
              },
            ],
          };
        }
      }

      return acc;
    },
    {
      skippedItems: 0,
      listBlocks: [],
    },
  );

  return result.listBlocks;
};
