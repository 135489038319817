import { FlexItem, Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { Item } from "@/components/ItemGroup";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const EncryptionKey: React.FC = observer(() => {
  const storedMasterKeyString = primaryViewState.masterKeyString;
  const hasUserKeys = primaryViewState.userEncryptionKeyExists;
  const { __ } = useI18n();

  if (storedMasterKeyString) {
    return (
      <Item>
        <FlexItem>
          <p>{"•••••••••••••••••••••••"}</p>
        </FlexItem>
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <FlexItem>
            <EditLinkButton
              onClick={() => {
                viewStates.modalRouter.showKeyModal({
                  openedFrom: "settings",
                  isCreatingSharedJournal: false,
                });
              }}
              label={__("View and back up encryption key")}
              title={__("View / Back up")}
            />
          </FlexItem>
        </Flex>
      </Item>
    );
  } else if (hasUserKeys) {
    return (
      <Item>
        <FlexItem>
          <p>{__("Add your encryption key")}</p>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            onClick={() => {
              viewStates.modalRouter.showKeyModal({
                openedFrom: "settings",
                isCreatingSharedJournal: false,
              });
            }}
            label={__("Add encryption key")}
            title={__("Add")}
          />
        </FlexItem>
      </Item>
    );
  } else {
    return (
      <Item>
        <p
          sx={{
            fontSize: 1,
            color: "textSecondary",
          }}
        >
          {__("No encryption key")}
        </p>
        <EditLinkButton
          onClick={() =>
            viewStates.modalRouter.showKeyModal({
              openedFrom: "settings",
              agreedToCreateKey: true,
              isCreatingSharedJournal: false,
            })
          }
          title={__("Turn on encryption")}
          label={__("Turn on encryption")}
        />
      </Item>
    );
  }
});

EncryptionKey.displayName = "EncryptionKey";
