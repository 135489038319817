import { Button } from "@wordpress/components";
import { ToolbarButtonProps } from "@wordpress/components/build-types/toolbar/toolbar-button/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";

import { CONTEXT_FORMAT_TOOLBAR_SLOT_FILL } from "@/components/Editor/components/ContextFormattingToolbar";
import { ContextToolbarButton } from "@/components/Editor/components/ContextToolbarButton";
import { FORMAT_TOOLBAR_SLOT_FILL } from "@/components/Editor/components/FixedFormattingToolbar";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";

export const FormatToolbarButton: React.FC<
  WordPressComponentProps<ToolbarButtonProps, typeof Button, false>
> = (props) => {
  // Set up a Fill for each formatting toolbar (top bar and contextual bar)
  return (
    <>
      <FORMAT_TOOLBAR_SLOT_FILL.Fill>
        <FixedToolbarButton {...props} />
      </FORMAT_TOOLBAR_SLOT_FILL.Fill>
      <CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
        <ContextToolbarButton {...props} />
      </CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
    </>
  );
};
