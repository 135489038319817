interface Props {
  height: string;
  width: string;
  bg?: string;
}

export const MockContent: React.FC<Props> = ({
  height,
  width,
  bg = "surfaceHover",
}) => {
  return (
    <div
      sx={{
        height,
        width,
        bg,
      }}
    ></div>
  );
};
