import { useEffect, useRef } from "react";

import { MenuButton } from "@/components/Editor/components/MenuButton";
import { getTemplateGalleryIconName } from "@/components/Templates/icons";
import { fromBase64 } from "@/crypto/utils";
import {
  GalleryTemplate,
  SelectedTemplate,
  TemplateGalleryCategory,
  TemplateGalleryDBRow,
} from "@/data/db/migrations/template";

type CategoryProps = {
  category: TemplateGalleryCategory;
  handleClick: (selected: SelectedTemplate) => void;
  activeTemplate: GalleryTemplate;
  search: string | null;
  galleryTemplates: TemplateGalleryDBRow[];
};

export const TemplateGalleryCategoryList: React.FC<CategoryProps> = ({
  category,
  handleClick,
  activeTemplate,
  search,
  galleryTemplates,
}) => {
  const templateImages = useRef<Map<string, string>>(new Map());
  useEffect(() => {
    return () => {
      templateImages.current.forEach((image) => {
        URL.revokeObjectURL(image);
      });
    };
  }, []);

  const filtered =
    search === null
      ? galleryTemplates
      : galleryTemplates.filter((t) =>
          t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        );
  if (search && filtered.length === 0) {
    return null;
  }

  return (
    <div
      sx={{
        "& .is-active": {
          bg: "surfaceActive",
          color: "textPrimary",
        },
      }}
    >
      <h2
        sx={{
          textTransform: "uppercase",
          fontSize: 1,
          color: "textPrimary",
          mt: 2,
          mb: 3,
          px: 2,
        }}
      >
        {category.name}
      </h2>
      <p
        sx={{
          color: "textSecondary",
          lineHeight: 2,
          fontSize: 0,
          mb: 2,
          px: 2,
        }}
      >
        {category.description}
      </p>
      {filtered.map((template) => {
        let imageURL;
        if (template.image) {
          imageURL = templateImages.current.get(template.id);
          if (!imageURL) {
            const imageBytes = fromBase64(template.image);
            const blob = new Blob([imageBytes]);
            imageURL = URL.createObjectURL(blob);
          }
          templateImages.current.set(template.id, imageURL);
        }

        const icon = getTemplateGalleryIconName(template.symbol.name);
        return (
          <MenuButton
            key={template.id}
            onClick={() => {
              handleClick({ ...template, type: "gallery" });
            }}
            isActive={template.id === activeTemplate?.id}
          >
            <span
              sx={{
                flexShrink: 0,
                display: "inline-block",
                height: "24px",
                width: "24px",
                backgroundImage: `url(${imageURL})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "sm",
                mr: 2,
                "& img": {
                  mt: "5px",
                  width: "14px",
                  height: "14px",
                  filter: "invert(1)",
                },
              }}
            >
              <img src={`/assets/icons/${icon}.svg`} />
            </span>
            <span sx={{ textAlign: "left", fontSize: 1 }}>{template.name}</span>
          </MenuButton>
        );
      })}
    </div>
  );
};
