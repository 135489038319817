import { Sentry } from "@/Sentry";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { EntryWithFeedRecord } from "@/data/repositories/V2API";
import { DecryptionService } from "@/data/services/DecryptionService";
import { FeedRecordWithData } from "@/data/services/SyncFeedParser";
// Static module with methods for decoding / encoding entries for/from network transmission
export class EntryService {
  static async decryptEntries(
    chunks: FeedRecordWithData[],
    decryptionService: DecryptionService,
    journalsWithVaults: JournalDBRow[],
  ): Promise<PromiseSettledResult<EntryWithFeedRecord>[]> {
    const decryptedEntries = await Promise.allSettled(
      chunks.map(async (chunk) => {
        if (chunk.data instanceof Uint8Array) {
          const entry = await decryptionService.decryptEntryD1(
            journalsWithVaults,
            chunk.data,
            `Decrypting entry ${chunk.record.revision.journalId}:${chunk.record.revision.entryId} after feed pull`,
          );
          return { record: chunk.record, entry };
        } else if (chunk.data == null) {
          return {
            record: chunk.record,
            entry: null,
          };
        } else {
          let entryData;
          try {
            entryData = JSON.parse(chunk.data);
          } catch (e: any) {
            Sentry.captureException(
              `Invalid JSON for entry ${chunk.record.revision.journalId}:${chunk.record.revision.entryId} - ${e.message}`,
            );
            throw new Error(e);
          }
          let richTextJSON;
          try {
            richTextJSON = JSON.parse(
              entryData?.richTextJSON || `{ "contents": [] }`,
            );
          } catch (e: any) {
            Sentry.captureException(
              `Invalid rich text JSON for entry ${chunk.record.revision.journalId}:${chunk.record.revision.entryId} - ${e.message}`,
            );
            throw new Error(e);
          }

          return {
            record: chunk.record,
            entry: {
              ...entryData,
              richTextJSON: richTextJSON?.contents
                ? richTextJSON
                : { contents: [] },
            },
          };
        }
      }),
    );
    return decryptedEntries;
  }
}
