export const Instagram: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C14.7615 17 17 14.7614 17 12C17 9.23857 14.7615 7 12 7C9.23853 7 7 9.23857 7 12C7 14.7614 9.23853 17 12 17ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        fill="currentColor"
      />
      <path
        d="M17 6C16.4478 6 16 6.44771 16 7C16 7.55229 16.4478 8 17 8C17.5522 8 18 7.55229 18 7C18 6.44771 17.5522 6 17 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.68628 2 2 4.68629 2 8V16C2 19.3137 4.68628 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8ZM16 4H8C5.79089 4 4 5.79086 4 8V16C4 18.2091 5.79089 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4Z"
        fill="currentColor"
      />
    </svg>
  );
};
