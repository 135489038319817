import type { Theme, ThemeUIStyleObject } from "@theme-ui/core";

import { journalHasNoColorSet } from "@/data/models/JournalFns";

const style = (
  color?: string,
  orderedByEditDate = false,
  isNotSynced?: boolean,
) => {
  let styles: ThemeUIStyleObject = {
    width: 3,
    height: 3,
    margin: 1,
    flexShrink: "0",
    borderRadius: "xs",
    display: "inline-block",
    borderBottomColor: "lightShade",
    backgroundColor: isNotSynced
      ? journalHasNoColorSet
      : color || journalHasNoColorSet,
    "&&&": {
      border: (theme: Theme) => `1px solid  ${theme.colors?.lightShade}`,
    },
  };
  if (orderedByEditDate) {
    styles = {
      ...styles,
      position: "relative",
      "&:before": {
        content: `""`,
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "surface_light2_dark2",
        opacity: "0.6",
        clipPath: "polygon(50% 20%, 80% 80%, 20% 80%)",
      },
    };
  }

  return styles;
};

export const JournalBadge: React.FC<{
  color?: string;
  orderedByEditDate?: boolean;
  isNotSynced?: boolean;
  children?: React.ReactNode;
}> = ({ color, orderedByEditDate, isNotSynced, children }) => {
  return (
    <div sx={style(color, orderedByEditDate, isNotSynced)}>{children}</div>
  );
};
