import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { SelectControl } from "@/components/Form/SelectControl";
import { SelectItemWrapper } from "@/components/Settings/SelectItemWrapper";
import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { viewStates } from "@/view_state/ViewStates";

export const ColorMode: React.FC = observer(() => {
  const selectedTheme = viewStates.theme.selectedTheme;
  const { setTheme } = useColorMode();
  const { __ } = useI18n();

  return (
    <SelectItemWrapper sx={{ width: "100%", py: "10px" }}>
      <SelectControl
        label={__("Theme")}
        value={selectedTheme!}
        options={[
          { label: __("Light"), value: "light" },
          { label: __("Dark"), value: "dark" },
          { label: __("System"), value: "system" },
        ]}
        onChange={(value) => {
          value && setTheme(value);
        }}
      />
    </SelectItemWrapper>
  );
});
