import { __ } from "@wordpress/i18n";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { AvatarInitials } from "@/components/Settings/Account/Avatar/AvatarInitials";
import { UserModel } from "@/data/models/UserModel";

export type AvatarDetails = {
  type: "initials" | "image";
  initials?: string;
  color?: string;
  imageSrc?: string;
};

export const getAvatarDetails = async (
  user: UserModel | null,
): Promise<AvatarDetails | null> => {
  if (!user) return null;
  const initials = user.initials;
  const initialDetails = {
    type: "initials",
    initials,
    color: user.profile_color,
  } as AvatarDetails;
  if (user.avatar === "") {
    return initialDetails;
  }
  // We've got to make a request to the server to get back the S3-direct
  // URL for the avatar.
  const resp = await d1Classes.fetchWrapper.fetchAPI(
    `/v2/media/${user.avatar}`,
    {
      headers: { "x-manual-redirect": "true" },
    },
  );

  if (resp.status === 404) {
    return initialDetails;
  } else if (!resp.ok) {
    Sentry.captureException(
      new Error(`Failed to get avatar URL: ${resp.statusText} - ${resp.body}`),
    );
    return initialDetails;
  } else {
    const location = resp.headers.get("x-manual-redirect-location");
    if (!location) {
      Sentry.captureException(
        new Error(
          `Could not find avatar S3 url on redirect response: ${resp.headers}`,
        ),
      );
      return initialDetails;
    }
    return {
      type: "image",
      imageSrc: location,
    };
  }
};

export const showAvatar = (details: AvatarDetails | null, size = 80) => {
  if (!details) return <></>;
  if (details.type === "initials") {
    return (
      <AvatarInitials initials={details.initials || ""} color={details.color} />
    );
  } else {
    return (
      <img
        sx={{
          display: "inline-block",
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: "50%",
          overflow: "hidden",
        }}
        alt={__("Avatar Image")}
        crossOrigin="anonymous"
        src={details.imageSrc}
      />
    );
  }
};

// See https://wp.me/pdvTAy-3fu for information about initial text sizes.
export const getTextSize = (diameter: number) => {
  const padding = diameter / 20;
  return Math.floor(diameter / 2 - padding) + "px";
};
