import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation } from "wouter";

import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";
import { modalRouterViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  state: PrefilledEntryViewState;
};
export const SaveTemplate: React.FC<Props> = observer(({ state }) => {
  const { __ } = useI18n();
  const [, setLocation] = useLocation();
  const [saving, setSaving] = useState(false);
  const canEncrypt = viewStates.primary.isEncryptionReady;

  useEffect(() => {
    if (saving && canEncrypt) {
      setSaving(false);
      handleSaveTemplate();
    }
  }, [saving, canEncrypt]);

  const handleSaveTemplate = async () => {
    const templateId = await state.saveAsTemplate();
    await setLocation("/");
    modalRouterViewState.openTemplateManagement("myTemplates", templateId);
    viewStates.snackbar.newMessage(__("New template saved from Preview"));
  };

  const handleClick = () => {
    if (!canEncrypt) {
      setSaving(true);
      viewStates.modalRouter.showKeyModal({
        openedFrom: "prefilled_url",
        isCreatingSharedJournal: false,
      });
    } else {
      handleSaveTemplate();
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleClick}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      {__("Save to Templates")}
    </Button>
  );
});
