export const Audio: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 18.5V6.5H9.5V18.5H7.5ZM11.5 22.5V2.5H13.5V22.5H11.5ZM3.5 14.5V10.5H5.5V14.5H3.5ZM15.5 18.5V6.5H17.5V18.5H15.5ZM19.5 14.5V10.5H21.5V14.5H19.5Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};
