import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { DODexie } from "@/data/db/dexie_db";

export class KeyValueStore {
  constructor(private db: DODexie) {}

  async get<T>(key: string): Promise<T | undefined> {
    key = key.toLowerCase();
    const result = await this.db.kv.get(key);
    return result?.value;
  }

  async set<T>(key: string, value: T) {
    key = key.toLowerCase();
    await this.db.kv.put({ key, value });
  }

  async delete(key: string) {
    key = key.toLowerCase();
    await this.db.kv.delete(key);
  }

  subscribe<T>(key: string, callback: (value: T | undefined) => void) {
    const sub = liveQuery(() => {
      return this.get<T>(key);
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }
}
