import { debounce } from "@wordpress/compose";
import { makeAutoObservable } from "mobx";

import { Sentry } from "@/Sentry";

export class WindowHeightViewState {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.start();
  }

  private start() {
    // Look for the "main" element on the page and react
    // to changes in its size.
    window.addEventListener("load", () => {
      const elem = document.getElementById("dayoneapp");
      if (!elem) {
        Sentry.captureException(
          new Error(
            "Couldn't find element with id 'dayoneapp' to watch for size changes",
          ),
        );
        return;
      } else {
        // We pass in an anonymous function here because
        // if we pass a method directly to lodash's debounce,
        // the action won't show up in the MobX logger.
        const debounceSetHeight = debounce((h) => this.setHeight(h), 100);

        const resizeObserver = new ResizeObserver((elems) => {
          // We wrap it in requestAnimationFrame to avoid this error
          // ResizeObserver loop limit exceeded
          window.requestAnimationFrame(() => {
            if (!Array.isArray(elems) || !elems.length) {
              return;
            }
            // ContentBoxSize can have multiple values because
            // of fancy HTML attrs with multiple fragments. But
            // we're using a normal element here.
            // BlockSize is the abstract representation of height
            // that isn't dependent on left-to-right or top-to-bottom
            // contexts.
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize
            const height = elems[0]?.contentBoxSize[0]?.blockSize;
            // We're double checking that the height has changed, so that
            // we don't write unnecessary logs to the console if the height
            // is the same as it used to be.
            if (height && this.height != height) debounceSetHeight(height);
          });
        });
        resizeObserver.observe(elem);
      }
    });
  }

  height = -1;

  // Actions
  setHeight = (height: number) => {
    if (height == this.height) return;
    this.height = height;
  };
}
