import { Automotive } from "@/components/icons/activity/Automotive";
import { Bike } from "@/components/icons/activity/Bike";
import { Eating } from "@/components/icons/activity/Eating";
import { Plane } from "@/components/icons/activity/Plane";
import { Running } from "@/components/icons/activity/Running";
import { Stationary } from "@/components/icons/activity/Stationary";
import { Train } from "@/components/icons/activity/Train";
import { Walking } from "@/components/icons/activity/Walking";
import { FirstQuarter } from "@/components/icons/moon/FirstQuarter";
import { Full } from "@/components/icons/moon/Full";
import { LastQuarter } from "@/components/icons/moon/LastQuarter";
import { New } from "@/components/icons/moon/New";
import { WaningCrescent } from "@/components/icons/moon/WaningCrescent";
import { WaningGibbous } from "@/components/icons/moon/WaningGibbous";
import { WaxingCrescent } from "@/components/icons/moon/WaxingCrescent";
import { WaxingGibbous } from "@/components/icons/moon/WaxingGibbous";
import { Clear } from "@/components/icons/weather/Clear";
import { ClearNight } from "@/components/icons/weather/ClearNight";
import { Cloudy } from "@/components/icons/weather/Cloudy";
import { CloudyNight } from "@/components/icons/weather/CloudyNight";
import { Drizzle } from "@/components/icons/weather/Drizzle";
import { Dust } from "@/components/icons/weather/Dust";
import { Fair } from "@/components/icons/weather/Fair";
import { Flurries } from "@/components/icons/weather/Flurries";
import { Fog } from "@/components/icons/weather/Fog";
import { FogNight } from "@/components/icons/weather/FogNight";
import { FreezingRain } from "@/components/icons/weather/FreezingRain";
import { Frigid } from "@/components/icons/weather/Frigid";
import { Hail } from "@/components/icons/weather/Hail";
import { Hazy } from "@/components/icons/weather/Hazy";
import { HazyNight } from "@/components/icons/weather/HazyNight";
import { HeavyRain } from "@/components/icons/weather/HeavyRain";
import { Hurricane } from "@/components/icons/weather/Hurricane";
import { Lightning } from "@/components/icons/weather/Lightning";
import { MostlyCloudy } from "@/components/icons/weather/MostlyCloudy";
import { MostlyCloudyNight } from "@/components/icons/weather/MostlyCloudyNight";
import { PartlyCloudy } from "@/components/icons/weather/PartlyCloudy";
import { Rain } from "@/components/icons/weather/Rain";
import { RainNight } from "@/components/icons/weather/RainNight";
import { RainSnow } from "@/components/icons/weather/RainSnow";
import { RainWind } from "@/components/icons/weather/RainWind";
import { Showers } from "@/components/icons/weather/Showers";
import { Sleet } from "@/components/icons/weather/Sleet";
import { SleetNight } from "@/components/icons/weather/SleetNight";
import { SleetSnow } from "@/components/icons/weather/SleetSnow";
import { Smoke } from "@/components/icons/weather/Smoke";
import { Snow } from "@/components/icons/weather/Snow";
import { SnowNight } from "@/components/icons/weather/SnowNight";
import { SnowShowers } from "@/components/icons/weather/SnowShowers";
import { SnowWind } from "@/components/icons/weather/SnowWind";
import { Sunny } from "@/components/icons/weather/Sunny";
import { Thunderstorm } from "@/components/icons/weather/Thunderstorm";
import { ThunderstormNight } from "@/components/icons/weather/ThunderstormNight";
import { Tornado } from "@/components/icons/weather/Tornado";
import { TropicalStorm } from "@/components/icons/weather/TropicalStorm";
import { Wind } from "@/components/icons/weather/Wind";
import { Activity, MoonPhase, WeatherCode } from "@/data/db/migrations/entry";

export const getActivityIcon = (activity: Activity) => {
  switch (activity) {
    case "Stationary":
      return Stationary;
    case "Walking":
      return Walking;
    case "Running":
      return Running;
    case "Biking":
      return Bike;
    case "Eating":
      return Eating;
    case "Automotive":
      return Automotive;
    case "Flying":
      return Plane;
    case "Train":
      return Train;
    default:
      return Bike;
  }
};

export const getWeatherIcon = (code: WeatherCode) => {
  switch (code) {
    case "clear":
      return Clear;
    case "clear-night":
      return ClearNight;
    case "cloudy":
      return Cloudy;
    case "cloudy-night":
      return CloudyNight;
    case "drizzle":
      return Drizzle;
    case "dust":
      return Dust;
    case "fair":
      return Fair;
    case "flurries":
      return Flurries;
    case "fog":
      return Fog;
    case "fog-night":
      return FogNight;
    case "freezing-rain":
      return FreezingRain;
    case "frigid":
      return Frigid;
    case "hail":
      return Hail;
    case "hazy":
      return Hazy;
    case "hazy-night":
      return HazyNight;
    case "heavy-rain":
      return HeavyRain;
    case "hurricane":
      return Hurricane;
    case "lightning":
      return Lightning;
    case "mostly-cloudy":
      return MostlyCloudy;
    case "mostly-cloudy-night":
      return MostlyCloudyNight;
    case "partly-cloudy":
      return PartlyCloudy;
    case "rain":
      return Rain;
    case "rain-night":
      return RainNight;
    case "rain-snow":
      return RainSnow;
    case "rain-wind":
      return RainWind;
    case "showers":
      return Showers;
    case "sleet":
      return Sleet;
    case "sleet-night":
      return SleetNight;
    case "sleet-snow":
      return SleetSnow;
    case "smoke":
      return Smoke;
    case "snow":
      return Snow;
    case "snow-night":
      return SnowNight;
    case "snow-showers":
      return SnowShowers;
    case "snow-wind":
      return SnowWind;
    case "sunny":
      return Sunny;
    case "thunderstorm":
      return Thunderstorm;
    case "thunderstorm-night":
      return ThunderstormNight;
    case "tornado":
      return Tornado;
    case "tropical-storm":
      return TropicalStorm;
    case "wind":
      return Wind;
    default:
      return Clear;
  }
};

export const getMoonIcon = (phase: MoonPhase) => {
  switch (phase) {
    case "new":
      return New;
    case "full":
      return Full;
    case "waxing-crescent":
      return WaxingCrescent;
    case "first-quarter":
      return FirstQuarter;
    case "last-quarter":
      return LastQuarter;
    case "waning-crescent":
      return WaningCrescent;
    case "waning-gibbous":
      return WaningGibbous;
    case "waxing-gibbous":
      return WaxingGibbous;
    default:
      return null;
  }
};
