import { Flex, FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import {
  showAvatar,
  getAvatarDetails,
  AvatarDetails,
} from "@/components/Settings/Account/Avatar/utils";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { useCreateObjectUrl } from "@/hooks/useCreateObjectUrl";
import { primaryViewState } from "@/view_state/ViewStates";

type EditAvatarProps = {
  handleClose: () => void;
};

// This is the max file size set by the server.
const MAX_FILE_SIZE = 2097152;

type Status = {
  saving: boolean;
  done: boolean;
  error: string | null;
};

export const EditAvatar: React.FC<EditAvatarProps> = observer(
  ({ handleClose }) => {
    const user = primaryViewState.user;
    const userStore = d1Classes.userStore;

    const ref = useRef<HTMLInputElement>(null);

    const [status, setStatus] = useState<Status>({
      saving: false,
      done: false,
      error: null,
    });
    const { saving, done, error } = status;

    const updateStatus = (newStatus: Partial<Status>) => {
      setStatus((prevStatus) => ({ ...prevStatus, ...newStatus }));
    };
    const [newAvatarFile, setNewAvatarFile] = useState<File | null>(null);
    const [newAvatarPreview, setNewAvatarPreview] =
      useState<AvatarDetails | null>(null);

    const url = useCreateObjectUrl(newAvatarFile);

    useEffect(() => {
      if (url) {
        setNewAvatarPreview({
          type: "image",
          imageSrc: url,
        });
      } else {
        getAvatarDetails(user).then((details) => setNewAvatarPreview(details));
      }
    }, [url, user?.avatar, user?.profile_color, user?.display_name]);

    useEffect(() => {
      updateStatus({ saving: false });
    }, [user]);

    const doSave = async (e: React.MouseEvent) => {
      e.preventDefault();
      if (newAvatarFile && user) {
        updateStatus({ saving: true });
        try {
          await userStore.updateAvatar(newAvatarFile);
        } catch (error) {
          if (error instanceof Error) {
            updateStatus({ error: error.message });
          }
          // reset to initial state
          if (ref && ref.current) {
            ref.current.value = "";
          }
          setNewAvatarFile(null);
        }
        setNewAvatarFile(null);
        updateStatus({ saving: false, done: true });
      }
    };

    const doChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || !e.target.files.length) {
        return;
      }
      const file = e.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        updateStatus({
          error: __(
            "Profile image must be smaller than 2MB. Please choose a new image.",
          ),
        });

        return;
      } else {
        updateStatus({ error: null });
      }
      setNewAvatarFile(file);
    };

    return (
      <D1Modal
        title={__("Edit Avatar")}
        onRequestClose={handleClose}
        sx={{ width: "420px" }}
      >
        <EditSection>
          <FlexItem sx={{ flexDirection: "column", flexGrow: 1 }}>
            {!done && (
              <>
                <Flex sx={{ justifyContent: "flex-start" }}>
                  <div
                    sx={{
                      display: "inline-block",
                      width: "80px",
                      height: "80px",
                      overflow: "hidden",
                    }}
                  >
                    {showAvatar(newAvatarPreview)}
                  </div>
                  <label
                    sx={{
                      cursor: "pointer",
                      fontSize: 1,
                      color: "textPrimary",
                      marginLeft: 3,
                    }}
                    htmlFor="avatar"
                  >
                    {__("Choose New Profile Image")}
                  </label>
                  {error && <ErrorMessage message={error} />}

                  <input
                    sx={{
                      display: "none",
                    }}
                    type="file"
                    ref={ref}
                    accept="image/*"
                    name="avatar"
                    id="avatar"
                    onChange={doChange}
                  />
                </Flex>
                <Flex>
                  <EditButtons
                    doSave={doSave}
                    isSaving={saving}
                    attributeChanged={!!newAvatarFile}
                    doCancel={handleClose}
                  />
                </Flex>
              </>
            )}
            {done && (
              <Done
                doClose={handleClose}
                message={__("Your profile image has been updated")}
              />
            )}
          </FlexItem>
        </EditSection>
      </D1Modal>
    );
  },
);

EditAvatar.displayName = "EditAvatar";
