import { makeAutoObservable } from "mobx";

import { ApprovalItemViewState } from "./ApprovalItemViewState";

import { JournalDBRow, PendingApproval } from "@/data/db/migrations/journal";
import { JournalParticipantRepository } from "@/data/repositories/JournalParticipantRepository";
import { JournalStore } from "@/data/stores/JournalStore";

type userId = string;

const HOW_MANY_TO_SHOW = 3;

export class PendingApprovalViewState {
  private items: Record<userId, ApprovalItemViewState> = {};

  constructor(
    journalStore: JournalStore,
    private journalParticipantRepository: JournalParticipantRepository,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    journalStore.subscribeToAll(this.populateFromJournals);
  }

  /**
   * This will populate this.items when the journals change in the DB
   * which is usually happens when the user syncs with the server
   */
  async populateFromJournals(journals: JournalDBRow[]) {
    const dbApprovals = journals.flatMap(
      (journal) => journal.invite_list?.pending_approvals || [],
    );

    this.matchDBState(dbApprovals);
    await this.upsertParticipants(dbApprovals);
  }

  /**
   * Match our state with the pending approvals from the db by:
   * - REMOVE any approvals that aren't in the DB's list
   * - ADD any new approvals
   * - UPDATE any existing approvals preserving the UI state of the
   *   existing approvals so that they don't get reset
   */
  private matchDBState(dbApprovals: PendingApproval[]) {
    const newItems = dbApprovals.reduce(
      (userApprovalMap, pendingApproval) => {
        let item = this.get(pendingApproval);

        if (item) {
          item.approval = pendingApproval;
        } else {
          item = new ApprovalItemViewState(pendingApproval, this);
        }

        userApprovalMap[this.getId(pendingApproval)] = item;
        return userApprovalMap;
      },
      {} as Record<userId, ApprovalItemViewState>,
    );

    this.items = newItems;
  }

  /**
   * This will add any new participants to our local DB so that
   * we can display their name and avatar.
   */
  private async upsertParticipants(pendingApprovals: PendingApproval[]) {
    await this.journalParticipantRepository.bulkUpsertForJournalIfNotExists(
      pendingApprovals.map((approval) => {
        const user = approval.user_requesting_shared_journal_access;
        return {
          user_id: user.id,
          ...user,
        };
      }),
    );
  }

  private getId(pendingApproval: PendingApproval) {
    return pendingApproval.user_requesting_shared_journal_access.id;
  }

  get all() {
    return Object.values(this.items).sort((i) =>
      new Date(i.approval.created_at).getTime(),
    );
  }

  get = (pendingApproval: PendingApproval) => {
    return this.items[this.getId(pendingApproval)];
  };

  get count() {
    return this.all.filter((item) => item.isPending).length;
  }

  get firstFew() {
    return this.all.slice(0, HOW_MANY_TO_SHOW);
  }

  get hasMore() {
    return this.count > HOW_MANY_TO_SHOW;
  }

  remove(itemViewState: ApprovalItemViewState) {
    delete this.items[itemViewState.id];
  }
}
