import { useI18n } from "@wordpress/react-i18n";
import { useEffect } from "react";

import { BackupButton } from "./BackupButton";

import { AppleLogo } from "@/components/icons/AppleLogo";
import { useCloudKit } from "@/hooks/useCloudKit";
import { masterKeyViewState } from "@/view_state/ViewStates";

type Props = {
  onSave: () => void;
  masterKeyString: string;
};

export const CloudKitBackupButton: React.FC<Props> = ({
  masterKeyString,
  onSave,
}) => {
  const { __ } = useI18n();
  const { openiCloudLoginWindow, authorized, backupKey, loading } = useCloudKit(
    () => {},
    "backup",
  );

  useEffect(() => {
    backupKey(masterKeyString).then((isBackedUp) => {
      if (isBackedUp) {
        onSave();
      }
    });
  }, [authorized]);

  return (
    <BackupButton
      position="top"
      backedUp={masterKeyViewState.hasSavedKeyToCloudKit}
      disabled={masterKeyViewState.hasSavedKeyToCloudKit || loading}
      icon={
        <span sx={{ "& svg path": { fill: "textPrimary" } }}>
          <AppleLogo />
        </span>
      }
      onClick={openiCloudLoginWindow}
      label={
        masterKeyViewState.hasSavedKeyToCloudKit
          ? __("Saved to iCloud")
          : !masterKeyViewState.workingWithCloudKit
            ? __("Back up with iCloud")
            : __("Backing up...")
      }
    >
      {masterKeyViewState.hasSavedKeyToCloudKit
        ? __("Saved to iCloud")
        : !masterKeyViewState.workingWithCloudKit
          ? __("Back up with iCloud")
          : __("Backing up...")}
    </BackupButton>
  );
};
