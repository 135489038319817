import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Item, ItemGroup } from "@/components/ItemGroup";
import { ParticipantInfo } from "@/components/SharedJournals/ParticipantInfo";
import { JournalParticipant } from "@/data/repositories/V6API";
import { SharedJournalSettings } from "@/view_state/PrimaryViewState";

type Props = {
  participant: JournalParticipant;
  participantIsTransferee: boolean;
  journalSettings: SharedJournalSettings;
  error: string | null;
};

export const MemberInfo: React.FC<Props> = observer(
  ({ participant, participantIsTransferee, journalSettings, error }) => {
    const [reactions, setReactions] = useState(0);
    const [entries, setEntries] = useState(0);
    const { journal, isPendingTransfer } = journalSettings;
    const { __ } = useI18n();
    const role = participant?.role === "owner" ? __("Owner") : __("Member");
    const dateSince = participant
      ? new Date(participant.member_since).toLocaleDateString(undefined, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })
      : "";

    useEffect(() => {
      if (participant && journal) {
        d1Classes.reactionRepository
          .getReactionCountForJournalMember(journal.id, participant.id)
          .then((count) => setReactions(count));

        d1Classes.entryRepository
          .getEntryCountForJournalMember(journal.id, participant.id)
          .then((count) => setEntries(count));
      }
    }, [journal?.id, participant?.id]);
    if (!participant || !journalSettings?.journal) {
      return null;
    }

    return (
      <>
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
            gap: 0,
          }}
        >
          {error && <ErrorMessage sx={{ width: "100%" }} message={error} />}
          <ParticipantInfo
            userId={participant.id}
            direction="column"
            size={4}
            sx={{
              "& .participant-name": {
                fontSize: 4,
                fontWeight: "heading",
              },
            }}
          />
          <div sx={{ color: "textSecondary", fontSize: 1 }}>{role}</div>
        </Flex>

        <ItemGroup
          sx={{
            fontSize: 1,
            "& span:nth-of-type(2)": { color: "textSecondary" },
          }}
        >
          {isPendingTransfer && participantIsTransferee && (
            <Item>
              <span>{__("Ownership transfer in progress")}</span>
            </Item>
          )}
          <Item>
            <span>{__("Member since")}:</span> <span>{dateSince}</span>
          </Item>
          <Item>
            <span>{__("Entries")}:</span> <span>{entries}</span>
          </Item>
          <Item>
            <span>{__("Reactions")}:</span>
            <span>{reactions}</span>
          </Item>
        </ItemGroup>
      </>
    );
  },
);
