export const uintArrayConcat = (buffers: Uint8Array[]) => {
  const totalLength = buffers.reduce((acc, value) => acc + value.length, 0);
  const result = new Uint8Array(totalLength);

  let length = 0;
  for (const buffer of buffers) {
    result.set(buffer, length);
    length += buffer.length;
  }

  return result;
};
