import { decryptEntryD1 } from "@/crypto/DOCrypto/D1/decryptEntryD1";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export const decryptMedia = async (
  d1: Uint8Array,
  debugContext: string,
  vaultRepository: VaultRepository,
) => {
  const { decrypted } = await decryptEntryD1(d1, debugContext, vaultRepository);
  return decrypted;
};
