export const decryptAsymmetric = async (
  privateKey: CryptoKey,
  data: BufferSource,
  debugContext: string,
) => {
  const algo = {
    name: "RSA-OAEP",
  };
  try {
    const x = await crypto.subtle.decrypt(algo, privateKey, data);
    return x;
  } catch (e) {
    throw new Error(`Decryption failed. Context: ${debugContext}`);
  }
};
