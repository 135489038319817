import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance } from "@wordpress/blocks";
import { useSelect } from "@wordpress/data";
export const useBlockSelection = () => {
  return useSelect((select) => {
    const {
      // @ts-ignore - The type defs are out of date.
      getSelectedBlockClientIds,
      // @ts-ignore - The type defs are out of date.
      getPreviousBlockClientId,
      // @ts-ignore - The type defs are out of date.
      getNextBlockClientId,
      // @ts-ignore - The type defs are out of date.
      getBlocksByClientId,
      // @ts-ignore - The type defs are out of date.
      getBlockParents,
      // @ts-ignore - The type defs are out of date.
      getBlockName,
      // @ts-ignore - The type defs are out of date.
      getBlock,
    } = select(blockEditorStore);
    const selectedBlockClientIds = getSelectedBlockClientIds();

    const getBlockParent = (clientId: string) => {
      const parents = getBlockParents(clientId, true);
      return parents[0];
    };

    const getNestingLevel = (block: BlockInstance) => {
      const parents = getBlockParents(block.clientId, true);
      return parents.length;
    };

    const blocks = getBlocksByClientId(selectedBlockClientIds).filter(
      (block: BlockInstance) => !!block,
    ) as BlockInstance[];

    const firstBlock = blocks.length > 0 ? blocks[0] : null;
    const parentBlockClientId = firstBlock
      ? getBlockParent(firstBlock.clientId)
      : null;
    const parentBlock = parentBlockClientId
      ? getBlock(parentBlockClientId)
      : null;

    return {
      blockClientIds: selectedBlockClientIds,
      blocks,
      getPreviousBlockClientId,
      getSelectedBlockClientIds,
      getNextBlockClientId,
      getBlockParents,
      getBlockParent,
      getNestingLevel,
      getBlockName,
      getBlock,
      parentBlock,
    };
  }, []);
};
