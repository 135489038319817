import { Button } from "@wordpress/components";

interface Props {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  isActive?: boolean;
  isDestructive?: boolean;
  disabled?: boolean;
  title?: string;
}

export const MenuButton: React.FC<Props> = ({
  onClick,
  children,
  className,
  isActive = false,
  disabled = false,
  isDestructive = false,
  title,
}) => {
  return (
    <Button
      sx={{
        "&&.components-button": {
          fontSize: 1,
          px: 2,
          width: "100%",
          textWrap: "nowrap",
          color: isDestructive ? "red" : "textSecondary",
          ":hover": {
            bg: "surfaceHover",
            color: "textPrimary",
          },
          ":active": {
            bg: "surfaceActive",
            color: "textPrimary",
          },
        },
      }}
      className={`${className ? className : ""}${isActive ? "is-active" : ""}`}
      autoFocus={false}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
