import { __ } from "@wordpress/i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { useAuth } from "@/data/hooks/AuthProvider";
import { useNeedsAuthRefresh } from "@/data/hooks/userAccount/useNeedsAuthRefresh";

type State = {
  isDeleting: boolean;
  isDeleted: boolean;
  canDelete: boolean | null;
  reason: "auth" | "subscription" | "sharedJournal" | null;
  error: string;
  subscription: {
    name: string;
    source: string;
  } | null;
};

export const useDeleteAccount = () => {
  const { needsRefresh } = useNeedsAuthRefresh();
  const [accountDeletionState, setAccountDeletionState] = useState<State>({
    isDeleting: false,
    isDeleted: false,
    canDelete: null,
    reason: null,
    error: "",
    subscription: null,
  });
  const { doLogout } = useAuth();

  const canUserDeleteAccount = async () => {
    const subscription =
      await d1Classes.userRepository.getSubscriptionForActiveUser();
    if (
      subscription?.expirationDate &&
      subscription.expirationDate < Date.now() &&
      subscription?.subscriptionName !== "free" &&
      subscription?.source.toLowerCase() === "stripe"
    ) {
      setAccountDeletionState((state) => ({
        ...state,
        canDelete: false,
        reason: "subscription",
        subscription: {
          source: subscription.source,
          name: subscription.subscriptionName,
        },
      }));
      return;
    }
    const user = await d1Classes.userRepository.getActiveUser();
    const sharedJournals =
      await d1Classes.journalRepository.getSharedJournals();
    const ownsSharedJournals = sharedJournals.some(
      (j) => j.owner_id === user?.id,
    );
    if (ownsSharedJournals) {
      setAccountDeletionState((state) => ({
        ...state,
        canDelete: false,
        reason: "sharedJournal",
        subscription: subscription
          ? {
              source: subscription.source,
              name: subscription.subscriptionName,
            }
          : null,
      }));
      return;
    }
    if (needsRefresh) {
      setAccountDeletionState((state) => ({
        ...state,
        canDelete: false,
        reason: "auth",
        subscription: subscription
          ? {
              source: subscription.source,
              name: subscription.subscriptionName,
            }
          : null,
      }));
      return;
    }

    setAccountDeletionState((state) => ({
      ...state,
      canDelete: true,
      subscription: subscription
        ? {
            source: subscription.source,
            name: subscription.subscriptionName,
          }
        : null,
    }));
  };

  useEffect(() => {
    canUserDeleteAccount();
  }, [needsRefresh]);

  const deleteAccount = async () => {
    if (!accountDeletionState.canDelete) {
      return;
    }
    setAccountDeletionState((state) => ({ ...state, isDeleting: true }));
    const res = await d1Classes.fetchWrapper.fetchAPI("/v3/users/delete", {
      method: "DELETE",
    });
    if (res.status === 200) {
      await doLogout();
      setAccountDeletionState((state) => ({
        ...state,
        isDeleting: false,
        isDeleted: true,
      }));
    } else if (res.status === 400) {
      const response = await res.text();
      if (response === "Account is owner of an active shared journal.") {
        setAccountDeletionState((state) => ({
          ...state,
          isDeleting: false,
          isDeleted: false,
          error: __("Account is the owner of an active shared journal."),
        }));
      } else if (
        response === "Account still has an active stripe subscription."
      ) {
        setAccountDeletionState((state) => ({
          ...state,
          isDeleting: false,
          isDeleted: false,
          error: __("Account still has an active stripe subscription."),
        }));
      } else {
        setAccountDeletionState((state) => ({
          ...state,
          isDeleting: false,
          isDeleted: false,
          error: __("Unable to delete account. Please try again."),
        }));
      }
    } else {
      setAccountDeletionState((state) => ({
        ...state,
        isDeleting: false,
        isDeleted: false,
        error: __("Unable to delete account. Please try again."),
      }));
    }
  };

  return {
    deleteAccount,
    ...accountDeletionState,
  };
};
