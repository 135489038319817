import { useState, useEffect } from "react";

import { d1Classes } from "@/D1Classes";

type StripeInfo = {
  price: {
    unit_amount: number;
  };
  promotion_code: {
    coupon: {
      percent_off: number;
    };
  } | null;
  trial_days: number;
};

type PriceInfo = {
  price: number;
  discountPercentage: number;
  discountedPrice: number;
  trialPeriod: number;
};

const getPriceInfo = (stripeInfo?: StripeInfo): PriceInfo => {
  if (!stripeInfo) {
    return {
      price: 34.99,
      discountPercentage: 0,
      discountedPrice: 0,
      trialPeriod: 0,
    };
  }

  const { price: priceDetails, promotion_code, trial_days } = stripeInfo;
  // this is a little hacky but we are still definining how to best handle this
  // Stripe doesn't allow coupons with 0% off so we are using 0.01% off
  // for coupong that provide a longer free trial period

  const coupon = promotion_code?.coupon;
  const discountPercentage =
    coupon && coupon.percent_off > 1 ? coupon.percent_off : 0;
  const price = priceDetails.unit_amount / 100;
  let discountedPrice = price;
  if (discountPercentage > 0) {
    const discount = Math.round(
      (priceDetails.unit_amount * discountPercentage) / 100,
    );

    discountedPrice = (priceDetails.unit_amount - discount) / 100;
  }

  const trialPeriod = trial_days / 30;
  return {
    price,
    discountPercentage,
    discountedPrice: discountedPrice,
    trialPeriod,
  };
};

export const usePriceInfo = (code?: string) => {
  const [stripeInfo, setStripeInfo] = useState<StripeInfo>();
  const [fetchingInfo, setFetchingInfo] = useState(false);
  const [fetchPriceError, setFetchPriceError] = useState(false);

  useEffect(() => {
    setFetchingInfo(true);
    d1Classes.fetchWrapper
      .fetchAPI(
        `/subscription/stripe/configuration${
          code ? `?couponCode=${code}` : ""
        }`,
      )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setStripeInfo(data);
          });
        }
        if (!res.ok) {
          setFetchPriceError(true);
        }
      })
      .catch(() => setFetchPriceError(true))
      .finally(() => setFetchingInfo(false));
  }, []);

  return {
    priceInfo: getPriceInfo(stripeInfo),
    fetchingInfo,
    fetchPriceError,
  };
};
