import { AddReaction } from "@/components/icons/AddReaction";
import { Angry } from "@/components/icons/Angry";
import { Care } from "@/components/icons/Care";
import { Haha } from "@/components/icons/Haha";
import { Like } from "@/components/icons/Like";
import { Love } from "@/components/icons/Love";
import { Sad } from "@/components/icons/Sad";
import { Wow } from "@/components/icons/Wow";
import { ReactionDBRow, ReactionType } from "@/data/db/migrations/reaction";
import { AtLeast } from "@/utils/types";

export const reaction_values = [
  "like",
  "love",
  "care",
  "haha",
  "wow",
  "sad",
  "angry",
] as const;

export const getMappedReactions = () => {
  return reaction_values.reduce(
    (acc, reaction: ReactionType) => {
      acc[reaction] = <Reaction reaction={reaction} />;
      return acc;
    },
    {} as { [key in ReactionType]: JSX.Element },
  );
};

export type PartialReaction = AtLeast<ReactionDBRow, "reaction" | "user_id">;

type Props = {
  reaction: ReactionType | "add-reaction";
  color?: string;
};

export const Reaction: React.FC<Props> = ({ reaction, color }) => {
  switch (reaction) {
    case "like":
      return <Like color={color} />;
    case "love":
      return <Love />;
    case "haha":
      return <Haha />;
    case "wow":
      return <Wow />;
    case "sad":
      return <Sad />;
    case "angry":
      return <Angry />;
    case "care":
      return <Care />;
    case "add-reaction":
      return <AddReaction color={color} />;
  }
};

Reaction.displayName = "Reaction";
