import { format } from "date-fns";

import { KeyValueStore } from "@/data/db/KeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { DailyPromptRepository } from "@/data/repositories/DailyPromptRepository";
import {
  DailyPrompt,
  DailyPromptSettings,
} from "@/data/repositories/PromptsAPI";

export class DailyPromptStore {
  constructor(
    protected db: DODexie,
    private dailyPromptRepository: DailyPromptRepository,
    private keyValueStore: KeyValueStore,
  ) {}

  async setDailyPromptSettings() {
    const promptSettings =
      await this.dailyPromptRepository.fetchSettingsFromServer();
    if (promptSettings) {
      this.keyValueStore.set("daily-prompt-settings", promptSettings);
    }
  }

  async saveSettings(settings: DailyPromptSettings) {
    // Idealy this should only be done if the flag is on, but only fetching once so it's ok
    const result = await this.dailyPromptRepository.saveSettings(settings);

    if (result) {
      await this.keyValueStore.set("daily-prompt-settings", result);
      return true;
    }
    return false;
  }

  async getTodaysPrompt() {
    const today = format(new Date(), "yyyy-MM-dd");
    return await this.dailyPromptRepository.fetchPromptByDay(today);
  }

  async getPromptById(id: string) {
    return await this.dailyPromptRepository.fetchPromptById(id);
  }

  /**
   * Get a prompt from the database or fetch it from the server if it doesn't exist
   * If the prompt is fetched it is also saved to the database
   *
   * @param id
   * @returns
   */
  async getOrFetchPromptById(id: string) {
    const existingPrompt = await this.db.prompts.get(id);
    if (existingPrompt) {
      return existingPrompt;
    }
    const prompt = await this.getPromptById(id);
    if (prompt) {
      await this.db.prompts.put({
        id: prompt.id,
        content: prompt.content,
        deleted_at: "",
      });
    }
    return prompt;
  }

  async getRecentPrompts() {
    const date = new Date();
    date.setDate(date.getDate() - 9);

    const prompts = await this.dailyPromptRepository.fetchPromptsByDateRange(
      10,
      format(date, "yyyy-MM-dd"),
    );

    return prompts;
  }

  dismissPrompt(prompt: string) {
    this.keyValueStore.set("daily-prompt-dismissed", prompt);
  }

  async sync(): Promise<void> {
    // First check the date for the current prompt
    // we only want to fetch new prompts if it's a new day
    const dailyPrompt: DailyPrompt | undefined =
      await this.keyValueStore.get("daily-prompt");

    if (
      !dailyPrompt ||
      dailyPrompt.scheduled_date.substring(0, 10) !==
        format(new Date(), "yyyy-MM-dd")
    ) {
      const prompt = await this.getTodaysPrompt();
      // Since we fetch them together they will both be outdated
      if (prompt) {
        await this.keyValueStore.set("daily-prompt", prompt);
      }
      const prompts = await this.getRecentPrompts();

      if (prompts) {
        await this.keyValueStore.set("recent-daily-prompts", prompts);
      }
      // we have a new prompt so we can clear dismissed prompt
      await this.keyValueStore.delete("daily-prompt-dismissed");
    }

    await this.setDailyPromptSettings();
  }
}
