import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useRef } from "react";

import { D1Modal } from "@/components/D1Modal";
import { EditJournalMain } from "@/components/JournalSettings/EditJournalMain";
import { JournalStore } from "@/data/stores/JournalStore";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";

type Props = {
  handleClose: () => void;
  journalID: string;
  journalStore: JournalStore;
  primaryViewState: PrimaryViewState;
  snackbar: SnackbarViewState;
};

export const EditJournal: React.FC<Props> = observer(
  ({ handleClose, journalID, journalStore, primaryViewState, snackbar }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    return (
      <D1Modal
        ref={modalRef}
        hideHeader={true}
        title={__("Edit Journal")}
        sx={{
          width: ["100%", "100%", "420px"],
          height: ["calc(100dvh - 40px)", "calc(100dvh - 40px)", "600px"],
          maxHeight: "80%",
          "& .components-modal__content": {
            pt: 0,
          },
        }}
        onRequestClose={handleClose}
      >
        <EditJournalMain
          handleClose={handleClose}
          journalID={journalID}
          journalStore={journalStore}
          primaryViewState={primaryViewState}
          snackbar={snackbar}
          inModal={true}
          modalRef={modalRef}
        />
      </D1Modal>
    );
  },
);
