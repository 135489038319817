export const HeartFavorite: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4939 6.51134V6.51134C20.5045 7.52697 21.0486 8.91625 20.9965 10.348C20.9443 11.7798 20.3007 13.1259 19.2189 14.0653L12.6536 19.756C12.2786 20.0814 11.7213 20.0814 11.3463 19.756L4.78094 14.0654C3.6992 13.1259 3.05555 11.7799 3.00343 10.3481C2.9513 8.91628 3.49537 7.527 4.50594 6.51137V6.51137C6.40785 4.59965 9.46543 4.48754 11.5022 6.25484L11.9999 6.68624L12.4977 6.25481C14.5345 4.48753 17.592 4.59965 19.4939 6.51134Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
