import { ThemeUICSSObject } from "@theme-ui/css";

export const checklistStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-dayone-checklist-item": {
      "&.is-checked": {
        ".rich-text": {
          textDecoration: "line-through",
          color: "editorTextDisabled",
        },
      },
      display: "flex",
      marginBottom: 2,
      alignItems: "baseline",
      input: {
        width: 4,
      },
      ".rich-text": {
        color: "editorTextDefault",
        fontWeight: "body",
        fontSize: 2,
        flex: 1,
      },
    },
  },
};
