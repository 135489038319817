import { Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { ErrorMessage } from "@/components/ErrorMessage";
import { SelectControl } from "@/components/Form/SelectControl";
import { Item } from "@/components/ItemGroup";
import { Attribute } from "@/components/Settings/Attribute";
import { Button } from "@/components/Settings/Button";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { UserModel } from "@/data/models/UserModel";
import { SharedProfile as SharedProfileType } from "@/data/repositories/UserAPI";
import { UserStore } from "@/data/stores/UserStore";

type Props = {
  userStore: UserStore;
  user: UserModel | null;
};

type Status = {
  saving: boolean;
  done: boolean;
  errorMessage: string;
  editing: boolean;
};

export const SharedProfile: React.FC<Props> = observer(
  ({ userStore, user }: Props) => {
    const [sharedProfile, setSharedProfile] = useState<SharedProfileType>(
      user?.shared_profile || "full",
    );
    const [status, setStatus] = useState<Status>({
      saving: false,
      done: false,
      errorMessage: "",
      editing: false,
    });
    const { saving, done, errorMessage, editing } = status;

    const updateStatus = (newStatus: Partial<Status>) => {
      setStatus((prevStatus) => ({ ...prevStatus, ...newStatus }));
    };

    const { __ } = useI18n();

    const doSave = (e: React.MouseEvent) => {
      e.preventDefault();
      _save();
    };

    function _save() {
      const performUpdate = async () => {
        if (sharedProfile) {
          const updated = await userStore.updateSharedProfile(sharedProfile);
          if (updated) {
            setSharedProfile(updated.shared_profile || "full");
          } else {
            updateStatus({
              errorMessage: __(
                "Unable to update shared profile setting, please refresh and try again.",
              ),
            });
          }
        }
        updateStatus({ saving: false, done: true });
      };
      updateStatus({ saving: true, done: false, errorMessage: "" });
      performUpdate();
    }

    function handleClose() {
      updateStatus({ editing: false, done: false });
    }

    const sharedProfileLabels: { [key in SharedProfileType]: string } = {
      full: __("Full - Your name and avatar will be shared"),
      name: __("Name - Only your name will be shared"),
      anonymous: __("Anonymous - Neither your name or avatar will be shared"),
    };

    return (
      <>
        {editing && (
          <D1Modal
            title={__("Edit Shared Profile")}
            onRequestClose={handleClose}
            sx={{ width: "420px" }}
          >
            <EditSection>
              <FlexItem sx={{ flex: 1 }}>
                {errorMessage && <ErrorMessage message={errorMessage} />}
                {done && (
                  <Done
                    doClose={handleClose}
                    message={__("Shared profile setting has now been updated.")}
                  />
                )}
                {!done && (
                  <>
                    <Flex sx={{ mb: 2 }}>
                      <FlexBlock>
                        <SelectControl
                          label={__("Shared Profile")}
                          value={sharedProfile}
                          options={[
                            {
                              label: sharedProfileLabels.full,
                              value: "full",
                            },
                            {
                              label: sharedProfileLabels.name,
                              value: "name",
                            },
                            {
                              label: sharedProfileLabels.anonymous,
                              value: "anonymous",
                            },
                          ]}
                          onChange={(value) => {
                            if (value) {
                              setSharedProfile(value as SharedProfileType);
                            }
                          }}
                        />
                      </FlexBlock>
                    </Flex>
                    <Flex>
                      <EditButtons
                        isSaving={saving}
                        doSave={doSave}
                        doCancel={handleClose}
                        attributeChanged={
                          sharedProfile !== user?.shared_profile
                        }
                      />
                    </Flex>
                  </>
                )}
              </FlexItem>
            </EditSection>
          </D1Modal>
        )}
        <Item>
          <FlexItem>
            <Label>{__("Shared Profile")}</Label>
            <Attribute>
              {sharedProfileLabels[user?.shared_profile || "full"]}
            </Attribute>
          </FlexItem>
          <FlexItem sx={{ alignSelf: "flex-end" }}>
            <Button
              label={__("Edit Shared Profile")}
              onClick={() => updateStatus({ editing: !editing })}
            >
              {__("Edit")}
            </Button>
          </FlexItem>
        </Item>
      </>
    );
  },
);

SharedProfile.displayName = "SharedProfile";
