import { Flex, FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { TextControl } from "@/components/Form/TextControl";
import { getTextSize } from "@/components/Settings/Account/Avatar/utils";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { primaryViewState } from "@/view_state/ViewStates";

type EditInitialsProps = {
  handleClose: () => void;
};

export const EditInitials: React.FC<EditInitialsProps> = observer(
  ({ handleClose }) => {
    const user = primaryViewState.user;
    const [isDone, setIsDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [initials, setInitials] = useState(
      user?.initials.toLocaleUpperCase() || "",
    );

    useEffect(() => {
      if (user?.initials) {
        setInitials(user.initials);
      }
    }, [user?.initials]);

    function doSave() {
      setIsSaving(true);
      d1Classes.userStore
        .updateInitials(initials)
        .then(() => {
          setIsDone(true);
          setIsSaving(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    const bgColor = user?.profile_color
      ? user.profile_color.startsWith("#")
        ? user.profile_color
        : `var(--theme-ui-colors-${user.profile_color})`
      : "var(--theme-ui-colors-SharedJournalColor1)";

    const dimension = 80;
    const textSize = getTextSize(dimension);

    return (
      <D1Modal
        title={__("Edit Text")}
        onRequestClose={handleClose}
        sx={{ width: "420px" }}
      >
        <EditSection>
          <FlexItem sx={{ flexDirection: "column", flexGrow: 1 }}>
            {!isDone && (
              <>
                <Flex>
                  <FlexItem sx={{ flex: "1 0 160px" }}>
                    <div
                      sx={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                        height: dimension + "px",
                        width: dimension + "px",
                        lineHeight: dimension + "px",
                        borderRadius: "50%",
                        backgroundColor: bgColor,
                        "&&&& input": {
                          textAlign: "center",
                          color: "white",
                          fontSize: textSize,
                          height: dimension + "px",
                          border: "none",
                          borderRadius: "50% !important",
                          textTransform: "uppercase",
                        },
                      }}
                    >
                      <TextControl
                        autoFocus
                        value={initials}
                        maxLength={2}
                        onChange={(value) => {
                          setInitials(value.toLocaleUpperCase());
                        }}
                      />
                    </div>
                  </FlexItem>
                </Flex>
                <Flex>
                  <EditButtons
                    doSave={doSave}
                    isSaving={isSaving}
                    attributeChanged={user?.initials !== initials}
                    doCancel={handleClose}
                  />
                </Flex>
              </>
            )}
            {isDone && (
              <Done
                doClose={handleClose}
                message={__("Your profile text has been updated")}
              />
            )}
          </FlexItem>
        </EditSection>
      </D1Modal>
    );
  },
);

EditInitials.displayName = "EditInitials";
