import { SearchBox } from "@/components/SearchBox";

type Props = {
  searchInput: string | null;
  setSearchInput: (value: string | null) => void;
  loading: boolean;
  placeHolderText: string;
  onSubmitNullifyInput: boolean;
};

export const FilterSearchBox: React.FC<Props> = ({
  searchInput,
  setSearchInput,
  loading,
  placeHolderText,
  onSubmitNullifyInput,
}) => {
  return (
    <SearchBox
      showSearchIcon={false}
      sx={{
        "&&": {
          height: "40px",
          width: "100%",
          borderBottom: "none",
        },
        "&& form": {
          display: "flex",
          alignItems: "center",
          px: 2.5,
          height: "100%",
          py: 1,
        },
        // right close icon when input is not empty
        "&& .components-button": {
          position: "static",
          mx: 0,
          px: 0,
        },
        "&& .components-base-control": { flexGrow: 2 },
        "&& form input": {
          fontSize: 1,
          px: 2.5,
          height: "100%",
        },
      }}
      value={searchInput}
      setInputValue={setSearchInput}
      placeholder={placeHolderText}
      onSubmitNullifyInput={onSubmitNullifyInput}
      disabled={loading}
    />
  );
};
