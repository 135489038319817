import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance } from "@wordpress/blocks";
import { useDispatch } from "@wordpress/data";
import { quote } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";

import {
  HEADING_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  QUOTE_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import {
  createParagraphBlock,
  createQuoteBlock,
} from "@/components/Editor/rtj2gb/rtj2gb";
import { useReplaceSelectedBlocks } from "@/components/Editor/utils/replace-selected-blocks";

export const QuoteTransform = () => {
  const { __ } = useI18n();
  const { blocks, replaceSelectedBlocks } = useReplaceSelectedBlocks();
  const { parentBlock } = useBlockSelection();
  const { insertBlock, selectionChange, replaceBlock, replaceBlocks } =
    useDispatch(blockEditorStore);

  const canBeTransformed = [PARAGRAPH_BLOCK_ID, HEADING_BLOCK_ID];

  const isInQuoteBlock = parentBlock && parentBlock.name === QUOTE_BLOCK_ID;
  const allCanBeTransformedParagraphs =
    !isInQuoteBlock &&
    blocks.every((block) => canBeTransformed.includes(block.name));
  const areAllQuoteBlocks = blocks.every(
    (block) => block.name === QUOTE_BLOCK_ID,
  );

  const isDisabled =
    blocks.length === 0 ||
    (!isInQuoteBlock && !allCanBeTransformedParagraphs && !areAllQuoteBlocks);

  const convertToQuoteBlock = (blocks: BlockInstance[]) => {
    const convertedBlocks = blocks.map((block) => {
      return createParagraphBlock(block.attributes.content);
    });
    return [createQuoteBlock(convertedBlocks)];
  };

  const convertOrInsertQuote = () => {
    if (isDisabled) {
      return;
    }

    if (allCanBeTransformedParagraphs) {
      replaceSelectedBlocks(convertToQuoteBlock);
    } else if (isInQuoteBlock) {
      replaceBlock(parentBlock.clientId, parentBlock.innerBlocks);
    } else if (areAllQuoteBlocks) {
      const clientIds = blocks.map((block) => block.clientId);
      const innerBlocks = blocks
        .map((block) => block.innerBlocks)
        .flatMap((blocks) => blocks);
      replaceBlocks(clientIds, innerBlocks);
    } else {
      // Should never really get here just for safety
      const paragraphBlock = createParagraphBlock("");
      insertBlock(createQuoteBlock([paragraphBlock]));
      selectionChange(paragraphBlock.clientId, "content", 0, 0);
    }
  };

  return (
    <FixedToolbarButton
      disabled={isDisabled}
      onClick={convertOrInsertQuote}
      title={__("Quote")}
      icon={quote}
    />
  );
};
