import { d1Classes } from "@/D1Classes";

export class Cache {
  static instance: Cache;
  static sw: ServiceWorkerRegistration | null;
  static status: string;

  private isFreshTab = true;

  private constructor() {
    this.registerServiceWorker().then((r) => {
      Cache.sw = r || null;
      setTimeout(() => {
        this.isFreshTab = false;
      }, 1000 * 5); // We set this to false after 5 seconds since it's enough time to detect an update
    });
  }

  static init() {
    if (!this.instance) {
      this.instance = new Cache();
    }

    return this.instance;
  }

  private async registerServiceWorker() {
    if ("serviceWorker" in navigator) {
      try {
        const registration = await navigator.serviceWorker.register(
          "/cache.worker.js",
          {
            scope: "/",
          },
        );
        d1Classes.keyValueStore.set("new-version-available", "no");

        let refreshing = false;

        // detect controller change and refresh the page
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (!refreshing) {
            window.location.reload();
            refreshing = true;
          }
        });

        if (registration.waiting && this.isFreshTab) {
          Cache.update();
        }

        setInterval(
          () => {
            registration.update();
          },
          1000 * 60 * 60, // 1 hour
        );
        this.setStatus(registration);
        registration.addEventListener("updatefound", () => {
          if (registration.installing) {
            registration.installing.addEventListener("statechange", () => {
              if (registration.waiting && navigator.serviceWorker.controller) {
                this.setStatus(registration);
                if (this.isFreshTab) {
                  Cache.update();
                }
              }
            });
          }
        });

        return registration;
      } catch (error) {
        Cache.status = `Failed: ${error}`;
      }
    }
  }

  private setStatus(reg: ServiceWorkerRegistration) {
    if (reg) {
      if (reg.installing) {
        Cache.status = "installing";
      } else if (reg.waiting) {
        Cache.status = "waiting";
        d1Classes.keyValueStore.set("new-version-available", "yes");
      } else if (reg.active) {
        Cache.status = "active";
      }
    }
  }

  static update = async () => {
    await d1Classes.keyValueStore.set("new-version-available", "no");
    this.sw?.waiting?.postMessage({ type: "UPDATE" });
  };

  static unregister = async () => {
    navigator.serviceWorker.controller?.postMessage({
      type: "CLEAR_CACHE",
    });

    if (Cache.sw) {
      Cache.sw.unregister();
      Cache.sw = null;
      Cache.status = "";
    }
  };
}
