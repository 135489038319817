import { observer } from "mobx-react-lite";

import { ClickableEntryListItem } from "@/components/Search/ClickableEntryListItem";
import {
  ENTRY_LIST_ITEM_HEIGHT,
  SHARED_ENTRY_LIST_ITEM_HEIGHT,
} from "@/components/TimelineView/constants";
import { SearchViewState } from "@/view_state/SearchViewState";

type Props = {
  searchViewState: SearchViewState;
  closeHandler: () => void;
};

export const FavoriteEntries: React.FC<Props> = observer(
  ({ searchViewState, closeHandler }) => {
    const { favoriteEntries: favoriteEntries } = searchViewState;

    return favoriteEntries.map(({ globalId, isShared }) => (
      <ClickableEntryListItem
        key={`${globalId.id}-${globalId.journal_id}`}
        globalEntryId={globalId}
        height={
          isShared ? SHARED_ENTRY_LIST_ITEM_HEIGHT : ENTRY_LIST_ITEM_HEIGHT
        }
        onClick={closeHandler}
        baseURL={`/journals/${globalId.journal_id}`}
      />
    ));
  },
);
