export const strToColor = (str: string) => {
  let hue = 0;
  for (let i = 0; i < str.length; i++) {
    hue += str.charCodeAt(i);
  }

  return `hsl(${hue % 360}deg 76% 61%)`;
};

const _log = (name: string, ...args: any[]) => {
  const color = strToColor(name);
  console.debug(
    `%c[${name}]`,
    `color: white; background: ${color}; padding: 2px; border-radius: 2px;`,
    ...args,
  );
};

export function makeDebugLogger(name: string, enabled: boolean) {
  const start = performance.now();
  let lastTimestamp = start;
  return (...args: any[]) => {
    if (enabled) {
      const thisTimestamp = performance.now();
      const timeElapsedSinceStart = thisTimestamp - start;
      const timeElapsedSinceLast = thisTimestamp - lastTimestamp;
      lastTimestamp = thisTimestamp;
      const _args = [
        ...args,
        `[${timeElapsedSinceLast.toFixed(2)}ms ${timeElapsedSinceStart.toFixed(
          2,
        )}ms]`,
        new Date().toISOString(),
      ];
      _log(name, _args);
    }
  };
}

export function quickDebug(name: string, ...args: any[]) {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    _log(name, ...args);
  }
}

export function quickTrace(name: string, ...args: any[]) {
  if (process.env.NODE_ENV === "development") {
    _log(name, args);
    console.trace(name);
  }
}
