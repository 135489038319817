import { Transaction } from "dexie";

import { Asymmetric, Fingerprint, Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64, uintArrayConcat } from "@/crypto/utils";
import {
  AuthToken,
  deserializeAuthToken,
  serializeAuthToken,
} from "@/crypto/utils/authToken";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { EntryDBRow } from "@/data/db/migrations/entry";
import { NotificationDBRow } from "@/data/db/migrations/notification";
import { SyncStateDBRow } from "@/data/db/migrations/sync-state";
import { TemplateDBRow } from "@/data/db/migrations/template";
import {
  UserKeysDBRow,
  UserKeysDBRow_dbVersion13,
} from "@/data/db/migrations/user_keys";
import { isDevelopment } from "@/utils/environment";
import { tagsAsArray } from "@/utils/tags";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";

function abortMigration(messageToThrow: string) {
  alert(
    "We're so sorry. Something went wrong. Please log out and log in again.",
  );
  throw new Error(messageToThrow);
}

export async function runMigration59(tx: Transaction) {
  return tx
    .table("entries")
    .toCollection()
    .modify((entry) => {
      if (!("hide_all_entries" in entry)) {
        entry.hide_all_entries = 0;
      }
    });
}

export async function runMigration58(tx: Transaction) {
  return tx
    .table("journals")
    .toCollection()
    .modify((journal) => {
      if (journal.cover_photos) {
        delete journal.cover_photos;
      }
      if (!journal.cover_photo) {
        journal.cover_photo = null;
      }
    });
}

export async function runMigration57(tx: Transaction) {
  return tx
    .table("journals")
    .toCollection()
    .modify((journal) => {
      if (!journal.cover_photos) {
        journal.cover_photos = null;
      }
    });
}

export async function runMigration56(tx: Transaction) {
  return tx
    .table("entries")
    .toCollection()
    .modify((entry) => {
      delete entry.searchText;
    });
}

export async function runMigration55(db: DODexie) {
  const user = await db.users.get("me");
  if (!user) {
    return;
  }
  const newKey = Symmetric.Key.newFromUserId(user.id);
  const oldKeyBase64 = isDevelopment()
    ? "3g1/qTitB+crQc3kJCNr7vLky7Wcq1QuctDAjy/KtnE="
    : "smKivSQ/mUQiDtvmVQSrbgi3J/c0H3Ar9JT2ar+skbs=";
  const oldKey = Symmetric.Key.fromBuffer(fromBase64(oldKeyBase64));
  const decryptValue = async (value: ArrayBuffer) => {
    if (value.byteLength > 12) {
      const iv = value.slice(0, 12);
      const encrypted = value.slice(12, value.byteLength);
      return await Symmetric.decryptBufferAndIV(iv, await oldKey, encrypted);
    }
  };
  const encryptValue = async (value: Uint8Array) => {
    const { iv, encrypted } = await Symmetric.encryptToUint8ArrayWithIV(
      await newKey,
      value,
    );
    return uintArrayConcat([iv, encrypted]);
  };
  const keysToUpdate = [
    {
      key: "dayone-auth-token",
      serialize: serializeAuthToken,
      deserialize: deserializeAuthToken,
    },
    {
      key: "journal_key:",
      serialize: Asymmetric.Private.toUint8Array,
      deserialize: Asymmetric.Private.fromBuffer,
    },
    {
      key: "vault_key:",
      serialize: Symmetric.Key.toUintArray,
      deserialize: Symmetric.Key.fromBuffer,
    },
    {
      key: "master_key_string",
      serialize: Utf8.toUintArray,
      deserialize: Utf8.fromBufferSource,
    },
  ];

  const currentValues = await db.kv.toArray();
  for (const row of currentValues) {
    const keyToUpdate = keysToUpdate.find((k) => row.key.startsWith(k.key));
    if (keyToUpdate && row.value?.encrypted) {
      const decrypted = await decryptValue(row.value.data);
      if (decrypted) {
        const value = await keyToUpdate.deserialize(decrypted);
        //@ts-ignore
        const serialized = await keyToUpdate.serialize(value);
        const encrypted = await encryptValue(serialized);
        db.kv.put({
          key: row.key,
          value: { data: encrypted, encrypted: true },
        });
      }
    }
  }
}

export async function runMigration51(tx: Transaction) {
  await tx.table("notifications").clear();
  await tx.table("sync_states").delete("notificationsCursor");
}

export async function runMigration48(tx: Transaction) {
  const secureKeyValueStore = new SecureKeyValueStore(tx.db as DODexie);
  const fifteenMinutesAgo = Date.now() - 15 * 60 * 1000;
  const oldToken = await secureKeyValueStore.get(
    "dayone-auth-token",
    Utf8.fromBufferSource,
  );
  if (oldToken) {
    await secureKeyValueStore.set<AuthToken>(
      "dayone-auth-token",
      { token: oldToken, timestamp: fifteenMinutesAgo },
      serializeAuthToken,
    );
  }
}

export async function runMigration47(tx: Transaction) {
  await resyncJournals(tx.db as DODexie);
}

export async function runMigration45(db: DODexie) {
  await resyncJournals(db);
}

export async function runMigration43(tx: Transaction) {
  const objs = await tx.table("templatestemp").toArray();
  return await tx.table("templates").bulkAdd(objs);
}

export async function runMigration42(tx: Transaction) {
  const objs = await tx.table("templates").toArray();
  return await tx.table("templatestemp").bulkAdd(objs);
}

export async function runMigration40(db: DODexie) {
  await resyncJournals(db);
}

export async function runMigration39(tx: Transaction) {
  await tx.table("templates").clear();
  return tx.table("sync_states").delete("templatesCursor");
}

export async function runMigration38(tx: Transaction) {
  return tx.table("sync_states").delete("templatesCursor");
}

export async function runMigration34(tx: Transaction) {
  return tx
    .table("sync_states")
    .toCollection()
    .modify(async (row: SyncStateDBRow) => {
      if (row.id.endsWith(":commentsCursor")) {
        row.cursor = "";
      }
    });
}

export async function runMigration33(tx: Transaction) {
  return tx
    .table("notifications")
    .toCollection()
    .modify(async (row: NotificationDBRow) => {
      if (!row.deleted_date) {
        row.deleted_date = -1;
      }
    });
}

export async function runMigration32(db: DODexie) {
  await resyncJournals(db);
}

export function runMigration25(tx: Transaction) {
  // Encrypt the authentication token, master key journal keys, and vault keys, in the KV Store
  const secureKeyValueStore = new SecureKeyValueStore(tx.db as DODexie);

  return tx
    .table("kv")
    .toCollection()
    .modify(async (row: { key: string; value: any }) => {
      if (row.key === "dayone-auth-token") {
        await secureKeyValueStore.set(row.key, row.value, Utf8.toUintArray);
      }
      if (row.key === "master_key_string") {
        await secureKeyValueStore.set(row.key, row.value, Utf8.toUintArray);
      }
      if (row.key.startsWith("journal_key")) {
        await secureKeyValueStore.set(
          row.key,
          row.value,
          Asymmetric.Private.toUint8Array,
        );
      }
      if (row.key.startsWith("vault_key")) {
        await secureKeyValueStore.set(
          row.key,
          row.value,
          Symmetric.Key.toUintArray,
        );
      }
    });
}

export async function runMigration24(db: DODexie) {
  await resyncJournals(db);
}

export async function runMigration23(db: DODexie) {
  // Change the key value store to always store lowercase keys.
  const rows = await db.sync_states.toCollection().toArray();
  for (const row of rows) {
    if (row.id) {
      row.cursor = "";
      await db.sync_states.put({ ...row });
    }
  }
}

export async function runMigration22(db: DODexie) {
  await resyncJournals(db);
}

export async function runMigration21(db: DODexie) {
  // Get all the entries and for each entry insert the tags in the tags table
  const entries = await db.entries.toCollection().toArray();
  for (const entry of entries) {
    // @ts-ignore removed the tags property from the entry type
    if (entry.tags && entry.tags.length > 0) {
      // @ts-ignore removed the tags property from the entry type
      const tags = tagsAsArray(entry.tags).map((tag) => ({
        tag,
        journal_id: entry.journal_id,
        entry_id: entry.id,
      }));
      await db.tags.bulkPut(tags);
    }
  }
}

export async function runMigration19(db: DODexie) {
  // Change the key value store to always store lowercase keys.
  const rows = await db.kv.toCollection().toArray();
  for (const row of rows) {
    if (row.key !== row.key.toLowerCase()) {
      await db.kv.delete(row.key);
      await db.kv.put({ key: row.key.toLowerCase(), value: row.value });
    }
  }
}

export async function runMigration18(db: DODexie) {
  // reset templates, they might have deleted templates
  return await db.sync_states.delete("templatesCursor");
}

export async function runMigration17(db: DODexie) {
  // This cleans up some invalid data that was created by a bug in the app.
  // namely creating journal entries with the journal ID "All_Entries"
  return db.entries.toCollection().modify((entry: EntryDBRow) => {
    // NOTE: I hardcode AND use the variable just in case we decide to change the value of journalId_AllEntries
    // and someone has acidentially created some entries with that journal ID (it's invalid).
    if (
      entry.journal_id === journalId_AllEntries ||
      entry.journal_id === "All_Entries"
    )
      entry.is_deleted = 1;
  });
}

export async function runMigration16(db: DODexie) {
  // Change the tags in the database from a comma-separated string to an array of strings.
  return db.templates.toCollection().modify((template: TemplateDBRow) => {
    if (template.tags && typeof template.tags === "string") {
      (template.tags as string).length > 0
        ? (template.tags as string).split(",")
        : [];
    }
  });
}

export async function runMigration15(db: DODexie) {
  // Change the tags in the database from a comma-separated string to an array of strings.
  return db.entries.toCollection().modify((entry: EntryDBRow) => {
    // @ts-ignore removed the tags property from the entry type
    if (entry.tags && typeof entry.tags === "string") {
      // @ts-ignore removed the tags property from the entry type
      entry.tags = (entry.tags as string).split(",");
    }
  });
}

export async function runMigration14(db: DODexie) {
  // This migration is because we found out the user can have more than one key, so
  // we're changing the user_keys table to be a bit more general, and include extra
  // information we'll need for multi-key support.
  // Also, we used to only store a key here once it was unlocked. Now we're going to
  // store keys here both before and after locking.

  // Version 13 didn't store the public key on the user_keys table, so we need to
  // get it from the user table and put it here.
  const userPublicKeyPEM = await db.users.get("me").then((user) => {
    // The old user type had a public key on it. But the type doesn't have that field now
    // so we cheat with "any"
    return (user as any).public_key;
  });

  const oldMainKey = (await db.user_keys.get("me")) as unknown as
    | UserKeysDBRow_dbVersion13
    | undefined;
  // Abort the migration completely if there's no key to migrate.
  if (!oldMainKey) return;

  // Check that something about the assumptions this migration is making are true.
  // If not, abort the whole thing.
  if (oldMainKey.master_key_string == null)
    abortMigration(
      "Migration 14 expects a master key string to be present, but it's not.",
    );

  if (!oldMainKey) return;

  // Save the master key string to the KV store instead of the user keys table.
  await db.kv.put({
    key: "master_key_string",
    value: oldMainKey.master_key_string,
  });

  // If the user has a public key (which they for sure should if they have a key
  // user key and we've gotten this far in the migration).
  if (!userPublicKeyPEM)
    abortMigration("User needs to have a public key for migration to continue");

  const public_key = Asymmetric.PEM.toBytes(userPublicKeyPEM);
  const public_key_fingerprint = await Fingerprint.forKeyBytes(public_key);
  const private_key = oldMainKey.unlocked_private_key;

  const newRow: UserKeysDBRow = {
    local_id: "main",
    user_id: oldMainKey.user_id,
    private_key,
    public_key,
    public_key_fingerprint,
  };

  await db.user_keys.put(newRow);
  await db.user_keys.delete("me");
}

export function runMigration10(db: DODexie) {
  return db
    .table("users")
    .toCollection()
    .modify((user) => {
      user.journal_order = JSON.parse(user.journal_order) || [];
    });
}

export function runMigration9(db: DODexie) {
  return db
    .table("entries")
    .toCollection()
    .modify((entry) => {
      entry.is_draft = 0;
    });
}

export async function resyncJournals(db: DODexie) {
  // This will force a resync of journals so they can be updated to v6 fields for shared journals.
  //
  // NOTE that the "journalCursor" string at the time of writing was defined in:
  // src/data/repositories/SyncStateRepository.ts:43 -> SyncStateRepository.setJournalCursor
  //
  // Why hard code this string instead of importing a constant? Because if this string changes, we
  // might break our ability to upgrade old databases. So we hard code it here so that we can
  // change the constant in the future without breaking our ability to migrate.
  await db.sync_states.delete("journalsCursor");
}
