import { ThemeUICSSObject } from "@theme-ui/css";
import { Dropdown } from "@wordpress/components";
import { DropdownProps } from "@wordpress/components/build-types/dropdown/types";
import { useRef, useState } from "react";

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  sx?: ThemeUICSSObject;
};

export const D1Dropdown = (props: Props & DropdownProps) => {
  const { isOpen, sx, renderContent, renderToggle, onClose, ...dropdownProps } =
    props;
  const [dropDownOpen, setDropDownOpen] = useState(isOpen || false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    if (dropDownOpen) {
      onClose?.();
    }
    setDropDownOpen(!dropDownOpen);
  };

  const handleClose = () => {
    onClose?.();
    setDropDownOpen(false);
  };

  // Close the dropdown if the focus moves outside of the dropdown
  // This logic is based on the Dropdown component from WordPress
  // but modified to make sure dropdown is closed even when a "dialog" is clicked
  const closeIfFocusOutside = () => {
    if (!ref.current) {
      return;
    }
    const { ownerDocument } = ref.current;
    if (!ref.current.contains(ownerDocument.activeElement)) {
      handleClose();
    }
  };

  return (
    <Dropdown
      {...dropdownProps}
      sx={sx}
      popoverProps={{
        ...dropdownProps.popoverProps,
        onFocusOutside: closeIfFocusOutside,
      }}
      ref={ref}
      open={dropDownOpen}
      onClose={handleClose}
      renderToggle={() => {
        return renderToggle({
          isOpen: dropDownOpen,
          onToggle: toggleDropDown,
          onClose: handleClose,
        });
      }}
      renderContent={() =>
        renderContent({
          isOpen: dropDownOpen,
          onToggle: toggleDropDown,
          onClose: handleClose,
        })
      }
    />
  );
};
