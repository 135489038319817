import { useCallback } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { SyncService } from "@/data/services/SyncService";
import { UserKeysStore } from "@/data/stores/UserKeysStore";

export const useSaveEncryptionKey = (
  encryptionKeyEnterMethod: string,
  userKeyStore: UserKeysStore,
  syncService: SyncService,
) => {
  const validateAndStoreMasterKey = useCallback(
    async (key: string | undefined | null) => {
      if (!key) {
        return;
      }

      await userKeyStore.validateAndStoreMasterKey(key);
      await syncService.resyncJournals();
      analytics.tracks.recordEvent(EVENT.encryptionKeyEntered, {
        method: encryptionKeyEnterMethod,
      });
    },
    [userKeyStore, syncService, encryptionKeyEnterMethod],
  );

  return {
    validateAndStoreMasterKey,
  };
};
