import { gunzipSync } from "fflate";

import { Sentry } from "@/Sentry";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { D1 } from "@/crypto/types/D1";
import { toHex } from "@/crypto/utils";
import { VaultRepository } from "@/data/repositories/VaultRepository";

const maybeUngzip = (
  buffer: Uint8Array,
  d1: D1,
  prevRunCount = 0,
): Uint8Array => {
  const gzipHeader = "1f8b0800";
  const contentHasGzipHeader = toHex(buffer.slice(0, 4)) === gzipHeader;
  const shouldUngzip = contentHasGzipHeader;
  if (!shouldUngzip) return buffer;
  if (prevRunCount >= 3) {
    Sentry.captureException(
      new Error(
        "There's something messed up with this content. We've tried to UNGZIP it 3 times and it's still compressed. Giving up.",
      ),
    );
    return buffer;
  }

  const match = d1.binaryFormat;
  if (match < 2 && contentHasGzipHeader) {
    console.warn("Content is gzipped, but binary format is 0 or 1");
    return gunzipSync(buffer);
  }
  const unzipped = gunzipSync(buffer);
  // To guard against cases where we've gzipped multiple times, we'll check if the content is gzipped again
  return maybeUngzip(unzipped, d1, prevRunCount + 1);
};

export const decryptD1 = async (
  d1: Uint8Array,
  debugContext: string,
  privateKeyOrVaultRepository: VaultRepository | CryptoKey,
) => {
  const parsedD1 = await DOCrypto.D1.parse(d1);
  const lockedKeyInfo = parsedD1.lockedKeyInfo;
  if (lockedKeyInfo !== undefined) {
    const fingerprint = toHex(lockedKeyInfo.fingerprint);
    const privateKey =
      privateKeyOrVaultRepository instanceof VaultRepository
        ? await privateKeyOrVaultRepository.getJournalKey(fingerprint)
        : privateKeyOrVaultRepository;
    if (!privateKey) {
      throw new Error(
        `Private key not found while decrypting D1 ${debugContext}`,
      );
    }

    const contentKey = await Symmetric.Key.decrypt(
      privateKey,
      lockedKeyInfo.lockedKey,
      debugContext,
    );

    const decryptedButMaybeNotUngzipped = await Symmetric.decryptBufferAndIV(
      parsedD1.iv,
      contentKey,
      DOCrypto.D1.getBodyAsArrayBuffer(parsedD1),
    );

    const decrypted = maybeUngzip(decryptedButMaybeNotUngzipped, parsedD1);

    return { decrypted, parsedD1 };
  }
  throw new Error("Found No LockedKeyInfo When Decrypting D1");
};
