import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { UserSettingsRepository } from "@/data/repositories/UserSettingsRepository";

const GDPR_COUNTRIES = [
  // European Member Countries
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DE", // Germany
  "DK", // Denmark
  "EE", // Estonia
  "ES", // Spain
  "FI", // Finland
  "FR", // France
  "GR", // Greece
  "HR", // Croatia
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LT", // Lithuania
  "LU", // Luxembourg
  "LV", // Latvia
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SE", // Sweden
  "SI", // Slovenia
  "SK", // Slovakia
  "GB", // United Kingdom
  // Single Market Countries that GDPR applies to
  "CH", // Switzerland
  "IS", // Iceland
  "LI", // Liechtenstein
  "NO", // Norway
];

export class UsageStatisticsStore {
  constructor(
    private fetchWrapper: FetchWrapper,
    private userSettingsRepository: UserSettingsRepository,
  ) {}

  async get(): Promise<boolean> {
    const result = await this.userSettingsRepository.get_trackUsageStatistics();
    return result ?? true;
  }

  async isSet(): Promise<boolean> {
    const result = await this.userSettingsRepository.get_trackUsageStatistics();
    return result !== null;
  }

  async locationRequiresBanner(): Promise<boolean> {
    try {
      const response = await this.fetchWrapper.basicFetch(
        "https://public-api.wordpress.com/geo/",
        undefined,
      );
      if (response.ok) {
        try {
          const json = await response.json();
          const countryCode = json.country_short;
          const result = GDPR_COUNTRIES.includes(countryCode);
          const isSet = await this.isSet();
          if (!result && !isSet) {
            this.set(true);
          }
          return result;
        } catch (e: any) {
          Sentry.captureException(e);
          return false;
        }
      } else {
        Sentry.captureException(
          new Error(
            `https://public-api.wordpress.com/geo/ returned ${response.status}`,
          ),
        );
        return false;
      }
    } catch (e: any) {
      return false;
    }
  }

  async shouldShowBanner(): Promise<boolean> {
    const isSet = await this.isSet();
    if (isSet) {
      return false;
    }
    const locationRequiresBanner = await this.locationRequiresBanner();
    return locationRequiresBanner;
  }

  async set(value: boolean, isLoggedOut = false) {
    // We are storing the Logged out settings in a different key to merge properly on Login
    if (isLoggedOut) {
      await this.userSettingsRepository.saveLoggedOutSettings({
        track_usage_statistics: value,
      });
    } else {
      await this.userSettingsRepository.saveSettings({
        track_usage_statistics: value,
      });
    }
  }

  async delete() {
    await this.userSettingsRepository.saveSettings({
      track_usage_statistics: null,
    });
  }

  subscribe(callback: (value: boolean) => void) {
    const sub = liveQuery(() => {
      return this.get();
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => {
      sub.unsubscribe();
    };
  }
}
