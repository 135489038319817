import { liveQuery } from "dexie";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { DODexie } from "@/data/db/dexie_db";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";

type JournalPreset = {
  type: "preset";
  value: {
    id: string;
    name: string;
    description: string;
    long_description: string;
    color: string;
    icon_url: string;
    prompt_ids: string[];
    template_ids: string[];
    created_at: string;
    deleted_at: string;
    sort_idx: number;
  };
};

type PromptPreset = {
  type: "preset";
  value: { id: string; content: string; deleted_at: string };
};

type PresetBase = {
  id: string;
  action: "upsert" | "delete";
  cursor: string;
};

type Preset = PresetBase & (JournalPreset | PromptPreset);

export class PresetRepository {
  isSynchronizing: boolean;

  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
  ) {
    this.isSynchronizing = false;
  }

  async synchronize() {
    const cursor = await this.syncStateRepository.getPresetsCursor();

    const endpoint = `/journal-presets?cursor=${cursor}`;

    const res = await d1Classes.fetchWrapper.fetchAPI(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200 || res.status === 304) {
      let data: null | Preset[] = null;
      try {
        data = (await res.clone().json()) as Preset[];
      } catch (e) {
        throw Error(
          `Error parsing presets sync response, not JSON, actual response: ${await res.clone()
            .text}`,
          {
            cause: e,
          },
        );
      }
      if (data.length) {
        this.syncStateRepository.setPresetsCursor(data[data.length - 1].cursor);
      }

      const [presets, prompts] = data.reduce<
        [
          {
            toUpsert: JournalPreset["value"][];
            toDelete: JournalPreset["value"][];
          },
          {
            toUpsert: PromptPreset["value"][];
            toDelete: PromptPreset["value"][];
          },
        ]
      >(
        (acc, item) => {
          if (item.type === "preset") {
            if (item.action === "upsert") {
              acc[0].toUpsert.push(item.value as JournalPreset["value"]);
            } else {
              acc[0].toDelete.push(item.value as JournalPreset["value"]);
            }
          } else if (item.type === "prompt") {
            if (item.action === "upsert") {
              acc[1].toUpsert.push(item.value as PromptPreset["value"]);
            } else {
              acc[1].toDelete.push(item.value as PromptPreset["value"]);
            }
          }
          return acc;
        },
        [
          { toUpsert: [], toDelete: [] },
          { toUpsert: [], toDelete: [] },
        ],
      );
      if (presets.toUpsert.length) {
        await this.db.journal_presets.bulkPut(presets.toUpsert);
      }
      if (prompts.toUpsert.length) {
        await this.db.prompts.bulkPut(prompts.toUpsert);
      }
      if (presets.toDelete.length) {
        await this.db.journal_presets.bulkDelete(
          presets.toDelete.map((p: { id: string }) => p.id),
        );
      }
      if (prompts.toDelete.length) {
        await this.db.prompts.bulkDelete(
          prompts.toDelete.map((p: { id: string }) => p.id),
        );
      }
    }
  }

  subscribeToPresetCursor(callback: (cursor: string) => void) {
    const sub = liveQuery(async () => {
      return await this.syncStateRepository.getPresetsCursor();
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  async getPresetById(id: string) {
    return this.db.journal_presets.get(id);
  }

  async getPromptsByIds(iDs: string[]) {
    return this.db.prompts.where("id").anyOf(iDs).toArray();
  }
}
