import { Spinner } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { JournalStat } from "@/data/repositories/JournalRepository";
import { SyncViewState } from "@/view_state/SyncViewState";

type Props = {
  syncState: SyncViewState;
};

export const JournalSyncStats: React.FC<Props> = ({ syncState }) => {
  const [serverStats, setServerStats] = useState<JournalStat[] | null>(null);
  const [downloadedJournals, setDownloadedJournals] = useState(0);
  const [totalServerEntries, setTotalServerEntries] = useState(0);
  const [downloadedEntries, setDownloadedEntries] = useState<
    GlobalEntryID[] | null
  >(null);

  useEffect(() => {
    const unsub = d1Classes.entryRepository.subToAllIDs((ids) => {
      setDownloadedEntries(ids);
    });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    d1Classes.journalRepository.readAll().then((journals) => {
      setDownloadedJournals(journals.length);

      d1Classes.journalRepository.getServerSyncStats().then((stats) => {
        if (stats) {
          const filtered = stats.filter((s) => {
            return journals.find((j) => j.id === s.journal_id);
          });
          const total = filtered.reduce((acc: number, curr) => {
            return acc + curr.entry_count;
          }, 0);
          setTotalServerEntries(total);
          setServerStats(filtered);
        }
      });
    });
  }, [syncState.state]);

  return (
    <div sx={{ p: { fontSize: 1, lineHeight: "body" } }}>
      <p
        sx={{
          borderBottom: "1px solid",
          borderColor: "borderPrimary",
          my: 3,
        }}
      >
        {__("Sync Stats")}
      </p>
      <p>
        {__("Journals: On server:")} {serverStats?.length || "--"}:{" "}
        {__("Downloaded:")} {downloadedJournals}
      </p>
      <p>
        {__("Entries: On server:")} {totalServerEntries}: {__("Downloaded:")}{" "}
        {downloadedEntries !== null ? downloadedEntries.length : "--"}
      </p>
      {serverStats && (
        <table
          sx={{
            mt: 3,
            width: "100%",
            borderCollapse: "collapse",
            "td, th": {
              textAlign: "center",
              border: "1px solid",
              borderColor: "borderPrimary",
              fontSize: 1,
            },
          }}
        >
          <thead>
            <tr>
              <th>{__("Journal ID")}</th>
              <th>{__("Entries on server")}</th>
              <th>{__("Entries Downloaded")}</th>
            </tr>
          </thead>
          <tbody>
            {serverStats.map((stat) => {
              return (
                <tr key={stat.journal_id}>
                  <td>{stat.journal_id}</td>
                  <td>{stat.entry_count}</td>
                  <td>
                    {
                      downloadedEntries?.filter(
                        (entry) => entry.journal_id === stat.journal_id,
                      ).length
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {!serverStats && syncState.isOnline && <Spinner />}
    </div>
  );
};
