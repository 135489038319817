import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useRef, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Message } from "@/components/LoggedOut/Message";
import { getEncodedSymmetricKey } from "@/crypto/DOCrypto/utils";
import { useAuth } from "@/data/hooks/AuthProvider";
import { usePromise } from "@/hooks/d1_hooks";
import { useInterval } from "@/hooks/useInterval";
import { uuid } from "@/utils/uuid";

const INTERVAL_DELAY = 10000;

export const QRCode = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [key, setKey] = useState<CryptoKey | null>(null);
  const [url, setUrl] = useState("");
  const { __ } = useI18n();
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [nonce, setNonce] = useState(uuid());
  const [serverSecret, error, , setServerSecret] = usePromise(
    d1Classes.userStore.getQRLoginSecret(nonce),
  );
  const auth = useAuth();
  const [interval, setInterval] = useState<number | null>(null);

  const requestAuth = async () => {
    if (!key) return;
    try {
      const res = await auth.attemptQRLogin(nonce, serverSecret.secret, key);

      if (res === 404) {
        // request timed out, need to stop the interval
        // need to generate a new code
        setInterval(null);
        setNeedsRefresh(true);
      }
    } catch (e) {
      console.error("Catch in QRCode.requestAuth", e);
    }
  };

  const reset = () => {
    const newNonce = uuid();
    setNonce(newNonce);

    setServerSecret(d1Classes.userStore.getQRLoginSecret(newNonce));
    setNeedsRefresh(false);
  };

  useInterval(requestAuth, interval);

  const copyUrl = (e: React.MouseEvent) => {
    if (e.detail === 2) {
      e.stopPropagation();
      e.preventDefault();
      // Clear any selection caused by double-clicking
      window.getSelection()?.removeAllRanges();
      navigator.clipboard.writeText(url);
    }
  };

  useEffect(() => {
    const generateQRCode = async () => {
      const QRCode = await import("qrcode");
      const canvas = canvasRef.current;
      const [key, encodedKey] = await getEncodedSymmetricKey();
      setKey(key as CryptoKey);
      const url = `${window.location.protocol}//${window.location.host}/qr-login?token=${serverSecret?.secret}#${encodedKey}`;
      setUrl(url);
      QRCode.toCanvas(
        canvas,
        url,
        { errorCorrectionLevel: "H", margin: 0, width: 200 },
        (error) => {
          if (error) {
            console.error("Failed to generate QR code:", error);
          }
        },
      );
      setInterval(INTERVAL_DELAY);
    };
    if (serverSecret) {
      generateQRCode();
    }
  }, [serverSecret, nonce]);

  if (error) return <ErrorMessage message="what is wrong?" />;
  return (
    <div
      sx={{
        display: "flex",
        maxWidth: "350px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Message>
        {__(
          "Log in and add your encryption key in one step from from your mobile device.",
        )}
      </Message>
      {needsRefresh ? (
        <Button variant="primary" onClick={reset}>
          Reset QR Code
        </Button>
      ) : (
        <div onClick={copyUrl}>
          <canvas ref={canvasRef} />
        </div>
      )}
    </div>
  );
};
