import { Button } from "@wordpress/components";
import { moreHorizontal } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Dropdown } from "@/components/D1Dropdown";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { CommentDBRow } from "@/data/db/migrations/comment";
import { iconButtonHoverBackground } from "@/styles/mixins";

type Props = {
  comment: CommentDBRow;
  user_id: string;
  openEdit: () => void;
  isEditing: boolean;
  canUserDelete: boolean;
};

export const CommentMenu: React.FC<Props> = ({
  comment,
  user_id,
  openEdit,
  isEditing,
  canUserDelete,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { __ } = useI18n();

  const confirmDelete = async () => {
    await d1Classes.commentRepository.delete(comment.id);
    const journal = await d1Classes.journalRepository.getById(
      comment.journal_id,
    );
    analytics.tracks.recordEvent(EVENT.entryCommentDeleted, {
      shared_journal: journal?.is_shared || false,
      entry_id: comment.entry_id,
    });
    setShowConfirm(false);
  };

  const showEditButton = user_id === comment.author_id && !isEditing;

  return (
    <>
      <D1Dropdown
        renderToggle={({ isOpen, onToggle }) => (
          <Button
            onClick={onToggle}
            label={__("Comment Options Menu")}
            icon={moreHorizontal}
            sx={{
              mr: 3,
              color: "textPrimary",
              ...iconButtonHoverBackground,
            }}
            aria-expanded={isOpen}
          />
        )}
        sx={{
          "& .components-popover__content > div": {
            p: 0,
          },
          "& .components-popover__content": {
            borderWidth: 1,
            borderColor: "borderPrimary",
            borderRadius: "sm",
            boxShadow: "shadow2",
            padding: 0,
            marginTop: "-2",
            outline: "none",
          },
        }}
        renderContent={({ onToggle }) => (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "editorTextMuted",
              fontSize: 1,
              backgroundColor: "surface_light1_dark4",
              minWidth: "160px",
            }}
          >
            {(canUserDelete || showEditButton) && (
              <div sx={{ padding: 2.5, "& button": { mt: 1 } }}>
                {showEditButton && (
                  <MenuButton
                    onClick={() => {
                      openEdit();
                      onToggle();
                    }}
                  >
                    {__("Edit")}
                  </MenuButton>
                )}
                {canUserDelete && (
                  <MenuButton
                    onClick={() => {
                      setShowConfirm(!showConfirm);
                    }}
                  >
                    {__("Delete")}
                  </MenuButton>
                )}
              </div>
            )}
          </div>
        )}
      />
      {showConfirm && (
        <ConfirmDialog
          handleClose={() => setShowConfirm(false)}
          handleAction={confirmDelete}
          message={__("Are you sure you want to delete this comment?")}
          isDestructive={true}
        />
      )}
    </>
  );
};
