import { ThemeUICSSObject } from "@theme-ui/css";
import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { SyncSpinner } from "@/components/JournalSidebar/SyncSpinner";
import { modalRouterViewState, viewStates } from "@/view_state/ViewStates";
import { syncStates } from "@/worker/SyncWorkerTypes";

function SyncStateToLabel(state: string, outboxSize: number) {
  const { __, _n } = useI18n();
  switch (state) {
    case syncStates.ERROR:
      return __("There were problems pushing some of your data");
    case syncStates.DOWNLOADING:
      return __("Checking for new changes...");
    case syncStates.UPLOADING:
      return sprintf(
        _n(
          "Uploading... %d thing left",
          "Uploading... %d things left",
          outboxSize,
        ),
        outboxSize,
      );
    case syncStates.IDLE:
      return __("Sync is idle. Click to sync now");
  }
}

export const SyncStatusBadge = observer(() => {
  const { outboxSize, state: syncState, isOnline } = viewStates.sync;
  const { isLoggingOut } = viewStates.primary;

  const uploadReady = outboxSize > 0;
  const isSpinning =
    syncState === syncStates.UPLOADING || syncState === syncStates.DOWNLOADING;

  const beforeUnload = (e: BeforeUnloadEvent) => {
    d1MainThreadClasses.syncService.pushDelayed();
    let message = null;
    e.preventDefault();
    // NOTE: Modern browsers usually ignore this, however I'm putting it
    // because you have to provide something to make it work.
    message = "You have unsynced changes. Are you sure you want to leave?";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    // warns the user if they leave before things are synced
    removeEventListener("beforeunload", beforeUnload, { capture: true });
    if (outboxSize > 0 && !isLoggingOut) {
      addEventListener("beforeunload", beforeUnload, { capture: true });
    }
    return () =>
      removeEventListener("beforeunload", beforeUnload, { capture: true });
  }, [outboxSize, isLoggingOut]);

  return (
    <div
      sx={{
        pr: 1,
        height: 5,
        fontSize: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        color: "textSecondary",
        "--wp-admin-theme-color": (theme) => theme?.colors?.meterProgress,
      }}
    >
      <div
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            color: "textSecondary",
            p: 3,
            px: 3.5,
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "surfaceHover",
            },
          }}
          onClick={() => {
            if (syncState === syncStates.ERROR) {
              modalRouterViewState.showSyncDetails();
            }
            d1MainThreadClasses.syncService.sync();
            d1MainThreadClasses.syncService.pushDelayed();
          }}
          label={SyncStateToLabel(syncState, outboxSize)}
          tooltipPosition={"bottom center"}
          showTooltip
        >
          {outboxSize > 0 && (
            <OutboxSize
              outboxSize={outboxSize}
              offline={!isOnline}
            ></OutboxSize>
          )}
          <div
            sx={{
              position: "relative",
            }}
          >
            {isSpinning && <SyncSpinner />}
            <div
              sx={{
                transition: "transform 0.2s ease-in-out",
                transform: isSpinning ? "scale(0.5)" : "scale(1)",
              }}
            >
              {syncState === syncStates.ERROR && isOnline ? (
                <ErrorCircle />
              ) : !isOnline ? (
                <OfflineCloud />
              ) : (
                <>
                  {uploadReady || isSpinning ? <EmptyCloud /> : <CloudCheck />}
                </>
              )}
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
});

SyncStatusBadge.displayName = "SyncStatusBadge";

function OutboxSize({
  outboxSize,
  offline,
}: {
  outboxSize: number;
  offline: boolean;
}) {
  return (
    <span
      sx={{
        color: offline ? "textPrimaryLight" : "textPrimary",
        bg: offline ? "red" : "surface_light2_dark2",
        px: 2,
        py: 1,
        mr: 3,
        ml: -2.5,
        borderRadius: 3,
      }}
    >
      {outboxSize}
    </span>
  );
}

export const centerIconStyle: ThemeUICSSObject = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "textSecondary",
};

function EmptyCloud() {
  return (
    <svg
      width="24"
      viewBox="23 31 57 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={centerIconStyle}
    >
      <path
        d="M67.9086 45.4012C66.3655 37.7466 59.4899 32 51.2301 32C44.6722 32 38.9766 35.6387 36.1401 40.9637C29.3099 41.6737 24 47.3316 24 54.1875C24 61.5316 30.1041 67.5 37.6151 67.5H67.1144C73.3773 67.5 78.4602 62.53 78.4602 56.4062C78.4602 50.5487 73.8084 45.8006 67.9086 45.4012Z"
        fill="currentColor"
      />
    </svg>
  );
}

function CloudCheck() {
  return (
    <svg
      width="24"
      viewBox="22 30 57 38"
      xmlns="http://www.w3.org/2000/svg"
      sx={centerIconStyle}
    >
      <mask
        id="check"
        maskUnits="userSpaceOnUse"
        x="23"
        y="31"
        width="55"
        height="36"
      >
        <path
          d="M66.9086 44.4012C65.3655 36.7466 58.4899 31 50.2301 31C43.6722 31 37.9766 34.6387 35.1401 39.9637C28.3099 40.6737 23 46.3316 23 53.1875C23 60.5316 29.1041 66.5 36.6151 66.5H66.1144C72.3773 66.5 77.4602 61.53 77.4602 55.4062C77.4602 49.5487 72.8084 44.8006 66.9086 44.4012Z"
          fill="white"
        />
        <path
          d="M47.0755 53.2992L42.8504 49.0741L40 51.9245L47.0755 59L59.2251 46.8504L56.3747 44L47.0755 53.2992Z"
          fill="black"
        />
      </mask>
      <path
        d="M66.9086 44.4012C65.3655 36.7466 58.4899 31 50.2301 31C43.6722 31 37.9766 34.6387 35.1401 39.9637C28.3099 40.6737 23 46.3316 23 53.1875C23 60.5316 29.1041 66.5 36.6151 66.5H66.1144C72.3773 66.5 77.4602 61.53 77.4602 55.4062C77.4602 49.5487 72.8084 44.8006 66.9086 44.4012Z"
        fill="currentColor"
        mask="url(#check)"
      />
    </svg>
  );
}

function OfflineCloud({ size = 25 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="20 20 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={centerIconStyle}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77 56.75C77 50.81 72.3875 45.995 66.5375 45.59C65.0075 37.8275 58.19 32 50 32C47.0075 32 44.2175 32.81 41.7875 34.1825L45.14 37.535C46.6475 36.8825 48.2675 36.5 50 36.5C56.84 36.5 62.375 42.035 62.375 48.875V50H65.75C69.485 50 72.5 53.015 72.5 56.75C72.5 58.9775 71.42 60.9125 69.7775 62.15L72.95 65.3225C75.4025 63.2525 77 60.215 77 56.75ZM31.3475 33.26C30.47 34.1375 30.47 35.555 31.3475 36.4325L35.9825 41.0675H35.0375C27.6575 41.855 22.0775 48.5825 23.135 56.345C24.035 63.14 30.1775 68 36.995 68H62.8925L65.795 70.9025C66.6725 71.78 68.09 71.78 68.9675 70.9025C69.845 70.025 69.845 68.6075 68.9675 67.73L34.52 33.26C33.6425 32.3825 32.225 32.3825 31.3475 33.26ZM27.5 54.5C27.5 59.4725 31.5275 63.5 36.5 63.5H58.3925L40.3925 45.5H36.5C31.5275 45.5 27.5 49.5275 27.5 54.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

function ErrorCircle({ color = "currentColor", size = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      sx={centerIconStyle}
    >
      <circle fill="none" cx="12" cy="12" r="10" />
      <line x1="12" y1="8" x2="12" y2="12" />
      <line x1="12" y1="16" x2="12.01" y2="16" />
    </svg>
  );
}
