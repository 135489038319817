// 1
interface CanvasContext {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
}

export const createCanvas = (width: number, height: number): CanvasContext => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Could not get canvas 2d context");
  }
  canvas.width = width;
  canvas.height = height;
  return { canvas, context };
};

export const canvasToBlob = async (
  canvas: HTMLCanvasElement,
  fileType: string,
  quality = 1,
): Promise<Blob> => {
  return await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob: Blob | null) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Error, could not create blob."));
        }
      },
      fileType,
      quality,
    );
  });
};
