import { Button, Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { D1Modal } from "@/components/D1Modal";

type LoadingModalProps = {
  title: string;
  handleClose: () => void;
  action?: () => void;
  actionLabel?: string;
};

export const LoadingModal: React.FC<LoadingModalProps> = ({
  title,
  handleClose,
  action,
  actionLabel,
}) => {
  const { __ } = useI18n();
  return (
    <D1Modal
      isDismissible={false}
      shouldCloseOnEsc={false}
      sx={{
        width: ["100dvw", "100dvw", "420px"],
        "& .components-modal__content": {
          p: 6,
        },
      }}
      title={title}
      onRequestClose={handleClose}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <Spinner sx={{ width: 6, height: 6, margin: 0 }} />
        {action && (
          <Button variant="primary" onClick={action}>
            {actionLabel || __("Cancel")}
          </Button>
        )}
      </div>
    </D1Modal>
  );
};
