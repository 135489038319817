import { FlexItem, Icon } from "@wordpress/components";
import { __, _n } from "@wordpress/i18n";
import { chevronRight } from "@wordpress/icons";

import { GroupHeading, ItemButton, ItemGroup } from "@/components/ItemGroup";
import { ViewName } from "@/components/JournalSettings/JournalSettingsTypes";

type JournalSharingInfoButtonProps = {
  participantCount: number;
  setView: (view: ViewName) => void;
};
export const JournalSharingInfoButton: React.FC<
  JournalSharingInfoButtonProps
> = ({ participantCount, setView }) => {
  return (
    <>
      <GroupHeading>{__("Journal Sharing")}</GroupHeading>
      <ItemGroup>
        <ItemButton
          onClick={() => {
            setView("sharing");
          }}
          sx={{
            justifyContent: "space-between",
            minHeight: "48px",
          }}
        >
          <FlexItem>
            <p>
              {participantCount}{" "}
              {_n(__("Member"), __("Members"), participantCount)}
            </p>
          </FlexItem>
          <FlexItem sx={{ mt: 1 }}>
            <Icon icon={chevronRight} size={24} />
          </FlexItem>
        </ItemButton>
      </ItemGroup>
    </>
  );
};
