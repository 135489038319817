import { registerBlockType, unregisterBlockType } from "@wordpress/blocks";
import { useDispatch } from "@wordpress/data";
import { observer } from "mobx-react-lite";
import { useEffect, useLayoutEffect, useState } from "react";

import {
  PdfBlockEditProps,
  PDF_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { useUpload } from "@/components/Editor/hooks/mediaUpload";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { SyncablePDF } from "@/components/SyncablePDF";
import { mediaTransforms } from "@/utils/block-helper";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const PdfBlock: React.FC<PdfBlockEditProps> = observer(
  ({ attributes, setAttributes, isSelected, clientId: blockClientId }) => {
    const globalEntryID = primaryViewState.selectedGlobalEntryID;
    const [fileNotRegistered, setFileNotRegistered] = useState(false);
    const { removeBlock } = useDispatch("core/block-editor");

    const { uploadFile } = useUpload(
      globalEntryID,
      viewStates,
      attributes,
      setAttributes,
      setFileNotRegistered,
    );
    // Upload a temporary file on mount.
    useEffect(() => {
      uploadFile();
    }, []);

    useLayoutEffect(() => {
      if (fileNotRegistered) {
        removeBlock(blockClientId);
      }
    }, [fileNotRegistered]);

    return (
      <SyncablePDF
        journalId={attributes.journalId}
        entryId={attributes.entryId}
        clientId={attributes.clientId}
        isSelected={isSelected}
      />
    );
  },
);

const { pdfTransform: transforms } = mediaTransforms;

export const register = () => {
  if (!blockIsRegistered(PDF_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(PDF_BLOCK_ID, {
      edit: (props: PdfBlockEditProps) => <PdfBlock {...props} />,
      title: "PDF",
      category: "media",
      textdomain: "default",
      description: "Day One PDF Block",
      attributes: {
        journalId: "string",
        entryId: "string",
        clientId: "string",
        src: "string",
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
      transforms,
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(PDF_BLOCK_ID)) {
    return unregisterBlockType(PDF_BLOCK_ID);
  }
  return false;
};
