import { Button } from "@wordpress/components";
import { info } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";

export function HideJournalFromSync({
  onToggle,
  value,
}: {
  onToggle: (v: boolean) => void;
  value: boolean;
}) {
  const [expanded, setExpanded] = useState(false);
  const { __ } = useI18n();

  return (
    <div sx={{ "& label": { display: "flex" } }}>
      <ToggleSetting
        sx={{ height: "48px", ".components-flex-item": { mt: "-3px" } }}
        label={
          <>
            <span
              sx={{
                height: "36px",
                lineHeight: "36px",
                textAlign: "center",
                order: 1,
              }}
            >
              {__("Do Not Sync")}
            </span>
            <Button
              sx={{
                "&&": { borderRadius: "sm", ml: 1, order: 2 },
                "&&:hover": { color: "inherit" },
              }}
              icon={info}
              iconSize={24}
              label={__("What does this mean?")}
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
          </>
        }
        checked={value}
        onChange={() => {
          onToggle(!value);
        }}
      />

      {expanded && (
        <p
          aria-live="polite"
          sx={{
            fontSize: 0,
            lineHeight: 2,
            fontStyle: "italic",
            color: "textSecondary",
            px: 3,
            pt: 0,
            pb: 3,
          }}
        >
          {__(
            "Checking 'do not sync' will remove any downloaded data for this journal from this browser, and hide it from the sidebar. Un-checking it will allow the journal to be downloaded again.",
          )}
        </p>
      )}
    </div>
  );
}
