export const Music: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 17.7808V7.96378C8.5 7.54878 8.756 7.17678 9.144 7.02978L17.644 3.79178C18.299 3.54178 19 4.02578 19 4.72578V15.7748"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2678 14.0071C19.2441 14.9834 19.2441 16.5664 18.2678 17.5427C17.2915 18.519 15.7085 18.519 14.7322 17.5427C13.7559 16.5664 13.7559 14.9834 14.7322 14.0071C15.7085 13.0308 17.2915 13.0308 18.2678 14.0071"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76776 16.0071C8.74407 16.9834 8.74407 18.5664 7.76776 19.5427C6.79145 20.519 5.20854 20.519 4.23223 19.5427C3.25592 18.5664 3.25592 16.9834 4.23223 16.0071C5.20854 15.0308 6.79145 15.0308 7.76776 16.0071"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
