import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { SelectControl } from "@/components/Form/SelectControl";
import { SelectItemWrapper } from "@/components/Settings/SelectItemWrapper";
import { userSettingsViewState } from "@/view_state/ViewStates";

export const Temperature: React.FC = observer(() => {
  const { temperatureUnit } = userSettingsViewState;
  const { __ } = useI18n();

  return (
    <SelectItemWrapper sx={{ width: "100%", py: "10px" }}>
      <SelectControl
        label={__("Temperature Unit")}
        value={temperatureUnit}
        options={[
          { label: `°C (${__("Celsius")})`, value: "C" },
          { label: `°F (${__("Fahrenheit")})`, value: "F" },
        ]}
        onChange={(value) => {
          if (value === "C" || value === "F") {
            userSettingsViewState.saveSettings({ temperature_unit: value });
          }
        }}
      />
    </SelectItemWrapper>
  );
});

Temperature.displayName = "Temperature";
