export const headingFive = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.52 14.64C15 15 15.36 15.24 15.84 15.48C16.32 15.72 16.92 15.84 17.4 15.84C18 15.84 18.6 15.72 19.08 15.36C19.56 15 19.8 14.52 19.8 14.04C19.8 13.56 19.56 12.96 19.08 12.72C18.6 12.36 18 12.24 17.4 12.24H16.8C16.68 12.24 16.44 12.24 16.32 12.36L15.84 12.48L15.24 12.72L14.04 12L14.4 6H22.08V8.28H16.92L16.8 10.56C17.04 10.44 17.4 10.44 17.64 10.32C17.88 10.32 18.24 10.2 18.48 10.2C19.08 10.2 19.56 10.32 20.16 10.44C20.64 10.56 21.12 10.8 21.48 11.16C21.84 11.4 22.2 11.88 22.44 12.24C22.68 12.72 22.8 13.32 22.8 13.92C22.8 14.52 22.68 15.12 22.44 15.6C22.2 16.08 21.84 16.56 21.36 16.92C20.88 17.28 20.4 17.52 19.8 17.76C19.2 18 18.6 18.12 18 18.12C17.04 18.12 16.08 18 15.24 17.64C14.52 17.4 13.92 16.92 13.32 16.44C13.2 16.32 14.52 14.64 14.52 14.64ZM10.8 18H8.40001V13.2H3.60001V18H1.20001V6H3.60001V10.8H8.40001V6H10.8V18Z" />
    </svg>
  );
};
