import { journalColorList } from "@/components/JournalSettings/JournalColors";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { keyBy } from "@/utils/key-by";

export const journalHasNoColorSet = "gray";

export function journalIsActive(journal: JournalDBRow) {
  return journal.state === "active";
}

export function journalColorName(journal: JournalDBRow) {
  const color = journalColorList.find(
    (c) => c.color === journal.color?.toLocaleUpperCase(),
  );

  return color?.name || journal.color || journalHasNoColorSet;
}

export function journalColorNameFromColor(color: string) {
  const colorName = journalColorList.find(
    (c) => c.color === color?.toLocaleUpperCase(),
  );

  return colorName?.name || color || journalHasNoColorSet;
}

export function journalColorLabelFromJournal(journal: JournalDBRow) {
  const name = journalColorName(journal);
  const label = journalColorList.find((c) => c.name === name)?.label;
  return label;
}

export function journalColorLabelFromColor(color: string) {
  const name = journalColorNameFromColor(color);
  const label = journalColorList.find((c) => c.name === name)?.label;
  return label;
}

export function getEncryptedJournalCount(journals: JournalDBRow[]) {
  return journals.filter((j) => !!j.e2e).length;
}

function isVisibleJournal(journal: JournalDBRow) {
  return (journal.is_decrypted || !journal.e2e) && journalIsActive(journal);
}

export function dropEncryptedJournals(journals: JournalDBRow[]) {
  return journals.filter(isVisibleJournal);
}

export function separateVisibleJournals(journals: JournalDBRow[]) {
  return journals.reduce(
    (acc: { visible: JournalDBRow[]; hiddenCount: number }, j) => {
      if (isVisibleJournal(j)) {
        acc.visible.push(j);
      } else {
        acc.hiddenCount++;
      }
      return acc;
    },
    { visible: [], hiddenCount: 0 },
  );
}

export function orderJournals(
  journalOrder: string[],
  journals: JournalDBRow[],
): JournalDBRow[] {
  const journalsById = keyBy(journals, "id") as Record<string, JournalDBRow>;

  const ordered: JournalDBRow[] = [];
  journalOrder.forEach((journalId) => {
    const journal = journalsById[journalId];
    if (journal) {
      ordered.push(journal);
      delete journalsById[journalId];
    }
  });
  const unordered = Object.values(journalsById);
  return [...ordered, ...unordered];
}
