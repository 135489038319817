import { Global } from "@emotion/react";
import { ThemeUIStyleObject } from "@theme-ui/core";
import { ModernNormalize } from "emotion-modern-normalize";

import { useColorMode } from "@/styles/DayOneColorModeProvider";
import { themeCSS } from "@/styles/composeStyles";
import { theme } from "@/styles/theme";
import "@/styles/InterFont.css";
import "@/styles/NotoSerifFont.css";
import "@wordpress/components/build-style/style.css";

const resets: ThemeUIStyleObject = {
  ":root": {
    colorScheme: "light dark",
  },
  // Recent changes to Gutenberg have caused the following styles to take
  // precedence over some Block styles. In order to avoid this we are
  // ignoring any .wp-block element, which should cover all Editor Blocks.
  // See 59457-gh-WordPress/gutenberg and 3411-gh-Bloom/DayOne-Web for more context.
  "*:not(.wp-block), ::before, ::after": {
    borderWidth: 0,
    borderStyle: "solid",
  },

  ":focus-visible": {
    outlineColor: "primary",
  },

  'button, [type="button"], [type="reset"], [type="submit"]': {
    backgroundColor: "transparent",
    backgroundImage: "none",
  },
  "blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre": {
    m: 0,
  },
  fieldset: {
    m: 0,
    p: 0,
  },
  "ol, ul, menu": {
    m: 0,
    p: 0,
    listStyle: "none",
  },
  'button, [role="button"]': {
    cursor: "pointer",
  },
  ":disabled": {
    cursor: "default",
  },
  "img, video": {
    maxWidth: "100%",
    height: "auto",
  },
  "[hidden]": {
    display: "none",
  },
  legend: {
    p: 0,
  },
};

const global: ThemeUIStyleObject = {
  body: {
    minHeight: "100dvh",
  },
  html: {
    height: "100dvh",
  },
  "#app": {
    minHeight: "100dvh",
    height: "-webkit-fill-available",
    WebkitOverflowScrolling: "touch",
    display: "flex",
    flexDirection: "column",
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(127, 127, 127, 0.5) transparent",
    backgroundColor: "var(--theme-ui-colors-surface_light1_dark1)",
    color: "var(--theme-ui-colors-textPrimary)",
    "--wp-admin-theme-color": "var(--theme-ui-colors-primary)",
  },

  ".components-button.components-button": {
    color: "var(--theme-ui-colors-textPrimary)",
    borderRadius: "0.4rem",
  },

  ".components-popover__content": {
    backgroundColor: "var(--theme-ui-colors-surface_light1_dark4)",
    boxShadow: "shadow1",
    outline: "none",
    borderRadius: "sm",
  },

  "button.components-button.is-primary, .components-button.is-primary:hover:not(:disabled), .components-checkbox-control__input[type=checkbox]:checked, .components-button.is-primary:disabled":
    {
      color: "#fff !important",
      backgroundColor: "var(--theme-ui-colors-primary)",
    },

  ".components-button.is-primary:disabled": {
    color: "var(--theme-ui-colors-buttonTextDisabled) !important",
    backgroundColor: "var(--theme-ui-colors-surfaceActive)",
  },

  ".block-editor-block-list__empty-block-inserter .block-editor-inserter__toggle.components-button.has-icon, .block-editor-default-block-appender .block-editor-inserter__toggle.components-button.has-icon, .block-editor-block-list__insertion-point-inserter .block-editor-inserter__toggle.components-button.has-icon, .block-editor-block-list__block-popover-inserter .block-editor-inserter__toggle.components-button.has-icon":
    {
      color: "var(--theme-ui-colors-textPrimaryLight) !important",
      backgroundColor: "var(--theme-ui-colors-primary)",
    },

  ".block-editor-block-popover": {
    width: "100%",
  },

  ".components-button.is-primary:hover:not(:disabled)": {
    backgroundColor: "var(--theme-ui-colors-dayOneBlue)",
  },

  ".components-button.is-destructive, .components-button.is-primary.is-destructive":
    {
      bg: "red",
      color: "#fff !important",
      boxShadow: "inset 0 0 0 0 !important",
      transition: "all 0.2s ease-in-out",
    },

  ".components-button.is-destructive:focus:not(:disabled)": {
    boxShadow:
      "0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color) !important",
    outline: "3px solid transparent",
  },

  ".components-accessible-toolbar .components-button:focus:enabled, .components-toolbar .components-button:focus:enabled":
    {
      boxShadow:
        "0 0 0 var(--wp-admin-border-width-focus) var(--wp-admin-theme-color) !important",
    },

  ".components-button.is-destructive:hover:not(:disabled)": {
    bg: "redLighter",
  },

  ".components-button.is-secondary, .components-button.is-secondary:hover:not(:disabled)":
    {
      color: "var(--theme-ui-colors-primary)",
      border: "1px solid",
      borderColor: "var(--theme-ui-colors-primary)",
      boxShadow: "none",
    },

  ".components-button.is-secondary:disabled": {
    color: "var(--theme-ui-colors-buttonTextDisabled)",
    backgroundColor: "transparent",
    borderColor: "var(--theme-ui-colors-surfaceActive) !important",
  },

  ".components-checkbox-control__input[type=checkbox]:disabled": {
    cursor: "default",
  },

  ".components-modal__header": {
    borderBottomColor: "var(--theme-ui-colors-borderPrimary)",
  },

  "html, body, #app": {
    p: 0,
    m: 0,
    fontFamily:
      'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  },

  a: {
    color: "inherit",
    textDecoration: "none",
  },

  /* Prevent browser autofill styles being applied */
  "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus":
    {
      fontSize: "inherit",
      transition: "background-color 600000s 0s, color 600000s 0s !important",
    },

  /* Modal Animations */
  ".components-modal__frame": {
    position: "relative",
    webkitAnimation:
      "components-modal__appear-animation 0.25s cubic-bezier(.45,.59,.24,1.25) !important",
    animation:
      "components-modal__appear-animation 0.25s cubic-bezier(.45,.59,.24,1.25) !important",
    webkitAnimationFillMode: "forwards",
    animationFillMode: "forwards",
    "--wp-admin-theme-color": "var(--theme-ui-colors-primary)",
  },

  ".components-popover": {
    "--wp-admin-theme-color": "var(--theme-ui-colors-primary)",
  },

  "@-webkit-keyframes components-modal__appear-animation": {
    from: {
      opacity: "0",
      transform: "scale(1.25)",
    },
    to: {
      opacity: "1",
      transform: "scale(1)",
    },
  },
  "@keyframes components-modal__appear-animation": {
    from: {
      opacity: "0",
      transform: "scale(1.25)",
    },
    to: {
      opacity: "1",
      transform: "scale(1)",
    },
  },

  ".components-modal__screen-overlay": {
    webkitAnimation:
      "components-modal__screen-overlay-animation 0.5s cubic-bezier(.45,.59,.24,1.25) !important",
    animation:
      "components-modal__screen-overlay-animation 0.5s cubic-bezier(.45,.59,.24,1.25) !important",
    webkitAnimationFillMode: "forwards",
    animationFillMode: "forwards",
  },

  "@-webkit-keyframes components-modal__screen-overlay-animation": {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },
  "@keyframes components-modal__screen-overlay-animation": {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },

  ".components-notice.is-warning": {
    backgroundColor: "var(--theme-ui-colors-bgWarning)",
    color: "var(--theme-ui-colors-textPrimary)",
  },

  ".components-notice.is-success": {
    backgroundColor: "var(--theme-ui-colors-bgSuccess)",
    color: "var(--theme-ui-colors-textPrimary)",
  },

  ".components-notice.is-error": {
    backgroundColor: "var(--theme-ui-colors-bgError)",
    color: "var(--theme-ui-colors-textPrimary)",
  },

  ".components-animate__appear": {
    animation:
      "components-animate__appear-animation 0.25s cubic-bezier(0.05, 1.46, 0.57, 0.94) 0s",
  },

  // Form fields
  "#dayoneapp, .components-popover": {
    // Labels
    "label.components-base-control__label, label.components-input-control__label":
      {
        fontSize: "0.875rem",
        textTransform: "unset",
        fontWeight: "unset",
      },
    // Round corners on input fields
    ".components-input-control__container, .components-textarea-control__input, .components-text-control__input":
      {
        borderRadius: "2",
        boxShadow: "none",

        "&:focus": {
          borderColor: "var(--theme-ui-colors-primary)",
        },
      },
    ".components-checkbox-control__input[type=checkbox]": {
      // 3px
      borderRadius: "0.1875rem",
    },

    ".components-checkbox-control__input[type=checkbox][disabled]": {
      opacity: 0.5,
      cursor: "not-allowed",
      borderColor: "#ccc",
    },
    "&& .block-editor-link-control__search-item-header": {
      color: "var(--theme-ui-colors-textPrimary)",
      "& svg": {
        fill: "var(--theme-ui-colors-textPrimary)",
      },
    },
    "&& .block-editor-link-control__settings .components-checkbox-control__label":
      {
        color: "var(--theme-ui-colors-textSecondary)",
      },
  },

  // Fix a scroll bar issue after linking Google Account
  "#g_a11y_announcement": {
    height: 0,
  },

  // Link UI
  ".popover-slot .block-editor-link-control": {
    "& .components-base-control__label": {
      color: "var(--theme-ui-colors-textPrimary) !important",
    },
    "& .block-editor-link-control__settings": {
      display: "none",
    },
    "& input": {
      backgroundColor: "transparent",
      color: "var(--theme-ui-colors-textPrimary) !important",
      px: 2.5,
      py: 2,
      borderColor: "var(--theme-ui-colors-borderPrimary) !important",
    },
    "& input::placeholder": {
      color: "var(--theme-ui-colors-textSecondary) !important",
    },
    "& .block-editor-link-control__tools": {
      justifyContent: "flex-end",
      "& > button": {
        display: "none",
      },
    },
    "& .block-editor-link-control__search-results-wrapper": {
      display: "none",
    },
    "& .block-editor-link-control__search-results button": {
      display: "none",
    },
    "& .block-editor-link-control__search-item-icon": {
      display: "none",
    },
  },
};

export const GlobalStyles: React.FC = () => {
  // The color variables for the theme aren't available outside the ThemeProvider div
  // So the scrollbar colors need to be set like this in order to apply to the html element
  const { inDarkMode } = useColorMode();
  const scrollBarColors: ThemeUIStyleObject = {
    body: {
      backgroundColor: inDarkMode
        ? theme.colors?.modes?.dark.surface_light1_dark1
        : theme.colors?.surface_light1_dark1,
      colorScheme: inDarkMode ? "dark" : "light",
    },
  };

  return (
    <>
      <ModernNormalize />
      <Global styles={themeCSS([resets, global, scrollBarColors], theme)} />
    </>
  );
};
