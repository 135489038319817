// This is the file that constructs the search worker.
// Client code can import the worker instance from this file to send messages to it.

import Minisearch from "minisearch";

import { DODexie } from "@/data/db/dexie_db";
//@ts-ignore
import SearchWorker from "@/worker/SearchWorkerMain?worker";
import { FilterableValues } from "@/worker/SearchWorkerTypes";

export class SearchWorkerClient {
  private searchWorker: Worker | null = null;

  constructor() {
    if (process.env.NODE_ENV !== "test") {
      this.searchWorker = new SearchWorker();
    }
    if (typeof BroadcastChannel === "undefined") {
      this.searchWorker?.addEventListener("message", (event) => {
        if (event.data.type === "storagemutated") {
          DODexie.on("storagemutated").fire(event.data.updatedParts);
        }
      });
    }
  }

  getSearchWorker() {
    return this.searchWorker;
  }

  index() {
    this.searchWorker?.postMessage({ type: "index" });
  }

  refreshIndex() {
    this.searchWorker?.postMessage({ type: "refreshIndex" });
  }

  setFilter(filter: FilterableValues) {
    this.searchWorker?.postMessage({ type: "setFilter", filter });
  }

  search(query: string | null | typeof Minisearch.wildcard, options: any = {}) {
    this.searchWorker?.postMessage({ type: "search", query, options });
  }

  terminate() {
    this.searchWorker?.terminate();
  }
}
