import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance, switchToBlockType } from "@wordpress/blocks";
import { useDispatch } from "@wordpress/data";
import { ChecklistBlock, CoreListBlock } from "types/gutenberg-blocks";

import {
  PARAGRAPH_BLOCK_ID,
  CHECKLIST_BLOCK_ID,
  LIST_BLOCK_ID,
  LIST_ITEM_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import {
  createListBlock,
  createListItemBlock,
} from "@/components/Editor/rtj2gb/rtj2gb";
import { useCursor } from "@/components/Editor/utils/replace-selected-blocks";

export type ListType = "ordered" | "unordered" | "checklist";

export const getListTypeFromBlock = (
  block: CoreListBlock | ChecklistBlock,
): ListType | undefined => {
  if (block.name === LIST_BLOCK_ID && "ordered" in block.attributes) {
    return block.attributes.ordered ? "ordered" : "unordered";
  } else if (block.name === CHECKLIST_BLOCK_ID) {
    return "checklist";
  }

  return undefined;
};

// hooks for list transform
export const useListTransform = () => {
  const {
    blocks: selectedBlocks,
    blockClientIds: selectedBlockClientIds,
    getBlock,
    parentBlock,
  } = useBlockSelection();
  const {
    selectConvertedBlocks,
    saveCursorPosition,
    saveBlockOrder,
    selectLastInnerBlock,
    getNewBlockIds,
  } = useCursor();
  const { replaceBlocks } = useDispatch(blockEditorStore);

  function switchToParagraphBlocks(
    sourceBlocks: BlockInstance[] | BlockInstance,
    replaceClientIds: string[],
  ) {
    const sourceIsListBlock = parentBlock?.name === LIST_BLOCK_ID;

    const newBlocks = sourceIsListBlock
      ? switchToBlockType(parentBlock, PARAGRAPH_BLOCK_ID)
      : switchToBlockType(sourceBlocks, PARAGRAPH_BLOCK_ID);
    if (newBlocks) {
      if (sourceIsListBlock) {
        replaceBlocks(parentBlock.clientId, newBlocks);
      } else {
        replaceBlocks(replaceClientIds, newBlocks);
      }
      selectConvertedBlocks(newBlocks);
    }
  }

  function switchNonListToChecklistBlock(
    blocks: BlockInstance[],
    replaceClientIds: string[],
  ) {
    const newBlocks = switchToBlockType(blocks, CHECKLIST_BLOCK_ID);
    if (newBlocks) {
      replaceBlocks(replaceClientIds, newBlocks);
      selectConvertedBlocks(newBlocks);
    }
  }

  function switchListToChecklistBlock(sourceBlock: BlockInstance) {
    const newBlocks = switchToBlockType(sourceBlock, CHECKLIST_BLOCK_ID);
    if (newBlocks) {
      replaceBlocks(sourceBlock.clientId, newBlocks);
      selectConvertedBlocks(newBlocks);
    }
  }

  function replaceWithListBlock(
    parentBlock: BlockInstance,
    newListOrdered: boolean,
  ) {
    const { innerBlocks } = parentBlock;
    const newBlock = createListBlock(newListOrdered, innerBlocks);
    replaceBlocks(parentBlock.clientId, newBlock);
  }

  function switchToListBlock(
    sourceBlocks: BlockInstance[],
    newListOrdered: boolean,
    replaceClientIds: string[],
  ) {
    saveCursorPosition();
    const listItemBlocks = sourceBlocks.map((block) => {
      return createListItemBlock(
        block.attributes?.content || block.attributes?.item?.content || "",
      );
    });

    saveBlockOrder();

    if (listItemBlocks) {
      const newBlock = createListBlock(newListOrdered, listItemBlocks);
      replaceBlocks(replaceClientIds, newBlock);

      const newBlockIds = getNewBlockIds();
      const newListBlock = getBlock(newBlockIds[0]);
      selectLastInnerBlock(newListBlock);
    }
  }

  const convertBlocksToList = (listType: ListType) => {
    const newBlockType =
      listType === "checklist" ? CHECKLIST_BLOCK_ID : LIST_ITEM_BLOCK_ID;
    const newListOrdered = listType === "ordered";
    const NOT_LIST = "not-list-type";
    const firstBlock = selectedBlocks[0];
    // Check if we have the whole list selected instead of just a list item
    const isListBlockSelected = firstBlock?.name === LIST_BLOCK_ID;

    const sourceBlockKey = isListBlockSelected
      ? LIST_BLOCK_ID
      : `${
          firstBlock.name === LIST_ITEM_BLOCK_ID ||
          firstBlock.name === CHECKLIST_BLOCK_ID
            ? firstBlock.name
            : NOT_LIST
        }-${parentBlock?.attributes.ordered}`;

    const NON_LIST_TYPE = `${NOT_LIST}-undefined`;
    const clickedBlockKey = `${newBlockType}-${newListOrdered}`;

    switch (sourceBlockKey) {
      case NON_LIST_TYPE:
        switch (clickedBlockKey) {
          case `${LIST_ITEM_BLOCK_ID}-true`:
          case `${LIST_ITEM_BLOCK_ID}-false`:
            switchToListBlock(
              selectedBlocks,
              newListOrdered,
              selectedBlockClientIds,
            );
            break;
          case `${CHECKLIST_BLOCK_ID}-false`:
            switchNonListToChecklistBlock(
              selectedBlocks,
              selectedBlockClientIds,
            );
            break;
        }
        break;
      case `${LIST_ITEM_BLOCK_ID}-true`:
        switch (clickedBlockKey) {
          case `${LIST_ITEM_BLOCK_ID}-true`:
            switchToParagraphBlocks(selectedBlocks, selectedBlockClientIds);
            break;
          case `${LIST_ITEM_BLOCK_ID}-false`:
            replaceWithListBlock(parentBlock!, newListOrdered);
            break;
          case `${CHECKLIST_BLOCK_ID}-false`:
            switchListToChecklistBlock(parentBlock);
        }
        break;
      case `${LIST_ITEM_BLOCK_ID}-false`:
        switch (clickedBlockKey) {
          case `${LIST_ITEM_BLOCK_ID}-true`:
            replaceWithListBlock(parentBlock!, newListOrdered);
            break;
          case `${LIST_ITEM_BLOCK_ID}-false`:
            switchToParagraphBlocks(selectedBlocks, selectedBlockClientIds);
            break;
          case `${CHECKLIST_BLOCK_ID}-false`:
            switchListToChecklistBlock(parentBlock);
        }
        break;

      case `${CHECKLIST_BLOCK_ID}-undefined`:
        switch (clickedBlockKey) {
          case `${LIST_ITEM_BLOCK_ID}-true`:
          case `${LIST_ITEM_BLOCK_ID}-false`:
            switchToListBlock(
              selectedBlocks,
              newListOrdered,
              selectedBlockClientIds,
            );
            break;
          case `${CHECKLIST_BLOCK_ID}-false`:
            switchToParagraphBlocks(selectedBlocks, selectedBlockClientIds);
        }
        break;
      case LIST_BLOCK_ID:
        switch (clickedBlockKey) {
          case `${LIST_ITEM_BLOCK_ID}-true`:
          case `${LIST_ITEM_BLOCK_ID}-false`:
            replaceWithListBlock(firstBlock, newListOrdered);

            break;
          case `${CHECKLIST_BLOCK_ID}-false`:
            switchListToChecklistBlock(firstBlock);
            break;
        }
    }
  };

  const allBlocksAreSameType = (blocks: BlockInstance[]) => {
    const firstBlock = blocks.length > 0 ? blocks[0] : null;
    if (firstBlock) {
      return blocks.every(
        (block) =>
          block.name === firstBlock.name &&
          block.attributes.ordered === firstBlock.attributes.ordered,
      );
    }
    return false;
  };

  return {
    convertBlocksToList,
    allBlocksAreSameType,
    selectedBlocks,
    parentBlock,
  };
};
