export const Wow: React.FC = () => {
  return (
    <svg
      id="surprised"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="url(#paint0_linear_1139_60453)"
        stroke="#B67500"
      />
      <ellipse cx="17" cy="24" rx="4" ry="6" fill="#211603" />
      <ellipse
        cx="23.5"
        cy="14"
        rx="2.5"
        ry="4"
        fill="url(#paint1_linear_1139_60453)"
      />
      <ellipse
        cx="10.5"
        cy="14"
        rx="2.5"
        ry="4"
        fill="url(#paint2_linear_1139_60453)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8946 5.55301C13.1416 6.04698 12.9414 6.64766 12.4474 6.89465L7.44743 9.39465C6.95345 9.64164 6.35278 9.44141 6.10579 8.94743C5.8588 8.45345 6.05903 7.85278 6.55301 7.60579L11.553 5.10579C12.047 4.8588 12.6477 5.05903 12.8946 5.55301Z"
        fill="#64460F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1054 5.55301C20.8584 6.04698 21.0586 6.64766 21.5526 6.89465L26.5526 9.39465C27.0465 9.64164 27.6472 9.44141 27.8942 8.94743C28.1412 8.45345 27.941 7.85278 27.447 7.60579L22.447 5.10579C21.953 4.8588 21.3523 5.05903 21.1054 5.55301Z"
        fill="#64460F"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_60453"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF788" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_60453"
          x1="23.5"
          y1="10"
          x2="23.5"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_60453"
          x1="10.5"
          y1="10"
          x2="10.5"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
      </defs>
    </svg>
  );
};
