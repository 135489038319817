import { EntryDBRow } from "@/data/db/migrations/entry";

export const EntryFeatureFlag = {
  MultipleAttachments: 0x0001,
  NonJpeg: 0x0002,
  Audio: 0x0008,
  RichTextJSON: 0x0010,
  Video: 0x0020,
  Sketch: 0x0040,
  PDF: 0x0080,
  HighlightedText: 0x0100,
  IsPinned: 0x0200,
  EntryType: 0x0800,
  ExtendedEmbedTypes: 0x1000,
} as const;

export type EntryFeatureFlagType =
  (typeof EntryFeatureFlag)[keyof typeof EntryFeatureFlag];

export interface MomentFeatureChecks {
  nonJpeg: boolean;
  audio: boolean;
  video: boolean;
  pdf: boolean;
  sketch: boolean;
  multipleAttachments: boolean;
}

/**
 * This function calculates the feature flags for an entry based on the entry and other moment related checks.
 * We are using a Left Shift operator to calculate the decimal value of the feature flags
 * and then converting it to a hexadecimal value.
 *
 * See Fieldguide page for deatils on how we calculate the feature flags
 * https://fieldguide.automattic.com/day-one-engineering-docs/entry-feature-flags/
 *
 * 0x0001 multiple attachments
 * 0x0002 gif or png photos
 * 0x0008 audio
 * 0x0010 rich text json
 * 0x0020 video
 * 0x0040 sketch
 * 0x0080 PDF
 * 0x0100 highlighted text
 * 0x0200 isPinned
 * 0x0800 entryType field
 * 0x1000 Extended Embed Types • Contact • Location • Podcast • Song • Step Count • Workout
 */
export const calculateFeatureFlags = (
  entry: EntryDBRow,
  momentFeatureChecks: MomentFeatureChecks,
) => {
  let updatedFeatures = 0;

  // 0x0001 multiple attachments
  if (momentFeatureChecks.multipleAttachments) {
    updatedFeatures += 1;
  }

  // 0x0002 gif or png photos
  if (momentFeatureChecks.nonJpeg) {
    updatedFeatures += 1 << 1;
  }
  // 0x0008 audio
  if (momentFeatureChecks.audio) {
    updatedFeatures += 1 << 3;
  }

  // 0x0010 rich text json
  updatedFeatures += 1 << 4;

  // 0x0020 video
  if (momentFeatureChecks.video) {
    updatedFeatures += 1 << 5;
  }

  // 0x0040 drawing/sketch
  if (momentFeatureChecks.sketch) {
    updatedFeatures += 1 << 6;
  }

  // 0x0080 PDF
  if (momentFeatureChecks.pdf) {
    updatedFeatures += 1 << 7;
  }

  // 0x0100 highlighted text
  if (entry.rich_text_json?.match(`\\"highlightedColor\\":`)) {
    updatedFeatures += 1 << 8;
  }

  // 0x0200 isPinned
  if (entry.is_pinned) {
    updatedFeatures += 1 << 9;
  }
  // 0x0800 entryType field
  if (typeof entry.entry_type !== "undefined") {
    updatedFeatures += 1 << 11;
  }

  // 0x1000 Extended Embed Types • Contact • Location • Podcast • Song • Step Count • Workout
  if (
    entry.rich_text_json?.match(
      `\\"type\\":\\"contact|location|podcast|song|motionActivity|workout\\"`,
    )
  ) {
    updatedFeatures += 1 << 12;
  }

  return updatedFeatures.toString(16);
};

export const hasFeature = (
  featureFlags: string,
  flag: EntryFeatureFlagType,
) => {
  const flagValue = parseInt(featureFlags, 16);
  return (flagValue & flag) === flag;
};
