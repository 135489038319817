import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Item } from "@/components/ItemGroup";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";

export const Support = () => {
  const { __ } = useI18n();
  const buttonLabel = __("Contact support");
  return (
    <Item>
      <FlexItem>
        <p>{__("Day One Support")}</p>
      </FlexItem>
      <FlexItem>
        <EditLinkButton
          onClick={() => {
            analytics.tracks.recordEvent(EVENT.buttonTap, {
              button_identifier: "settings_support_clicked",
            });
            window.open("/support", "_blank", "noreferrer,noopener");
          }}
          label={buttonLabel}
          title={buttonLabel}
        />
      </FlexItem>
    </Item>
  );
};
