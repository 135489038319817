import * as Base64Arraybuffer from "base64-arraybuffer";

import { d1Classes } from "@/D1Classes";
import { FetchWrapper } from "@/api/FetchWrapper";
import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { fromBase64, toBase64 } from "@/crypto/utils";
import { JournalDBRow } from "@/data/db/migrations/journal";
import {
  KeyGenerator,
  KeyStore,
} from "@/data/repositories/SharedJournalsRepository/KeyGenerator";
import { InviteLink } from "@/data/repositories/V6API";

type InviteKey = {
  cryptoKey: CryptoKey;
  uint8Array: Uint8Array;
  encrypted: string;
};

export type GenerateInviteLinkHTTPBody = {
  name: string;
  description: string;
  encrypted_invitation_key: string;
  owner_public_key_hmac: string;
  encrypted_invitation_key_signature_by_owner: string;
};

export class InviteLinkGenerator extends KeyGenerator {
  constructor(
    protected journal: JournalDBRow,
    protected userKeysStore: KeyStore = d1Classes.userKeysStore,
    protected fetchWrapper: FetchWrapper = d1Classes.fetchWrapper,
  ) {
    super(userKeysStore);
  }

  // 👇 NOTE: this is the main entrypoint into this class
  async generate() {
    this.checkJournalSharing();

    const inviteKey = await this.generateInvitationKey();
    const link = await this.generateServerLink(inviteKey);
    return this.appendInviteKey(link, inviteKey);
  }

  private checkJournalSharing() {
    if (this.journal.is_shared === false) {
      throw new Error("Journal is not shared");
    }
  }

  private async generateInvitationKey(): Promise<InviteKey> {
    const cryptoKey = await Symmetric.Key.new();
    const uint8Array = await Symmetric.Key.toUintArray(cryptoKey);
    const encrypted = await this.createEncryptedInvitationKey(uint8Array);

    return { cryptoKey, uint8Array, encrypted };
  }

  private async createEncryptedInvitationKey(invitationKey: Uint8Array) {
    const publicKey = await this.getPublicKey();
    const encrypted_invitation_key = Base64Arraybuffer.encode(
      await Asymmetric.Public.encrypt(publicKey, invitationKey),
    );
    return encrypted_invitation_key;
  }

  private async generateOwnerPublicKeyHmac(invitationKey: Uint8Array) {
    const publicKey = await this.getPublicKey();
    const owner_public_key_hmac = await Symmetric.generateHMAC({
      body: await Asymmetric.Public.toPEM(publicKey),
      secret: invitationKey,
    });
    return owner_public_key_hmac;
  }

  private async generateSignature(encrypted_invitation_key: string) {
    const userPrivateKey = await this.getPrivateKey();
    const encrypted_invitation_key_signature_by_owner =
      await Asymmetric.Private.signArrayBuffer({
        userPrivateKey,
        buffer: fromBase64(encrypted_invitation_key),
      });
    return encrypted_invitation_key_signature_by_owner;
  }

  private async createRequestBody({
    inviteKey,
    ownerPublicKeyHMAC: owner_public_key_hmac,
    inviteKeySignature: encrypted_invitation_key_signature_by_owner,
  }: {
    inviteKey: InviteKey;
    ownerPublicKeyHMAC: string;
    inviteKeySignature: string;
  }): Promise<GenerateInviteLinkHTTPBody> {
    return {
      name: await Symmetric.encryptToD1(this.journal.name, inviteKey.cryptoKey),
      description: await Symmetric.encryptToD1(
        this.journal.description,
        inviteKey.cryptoKey,
      ),
      encrypted_invitation_key: inviteKey.encrypted,
      owner_public_key_hmac,
      encrypted_invitation_key_signature_by_owner,
    };
  }

  private async generateServerLink(inviteKey: InviteKey): Promise<InviteLink> {
    const body = await this.createRequestBody({
      inviteKey,
      ownerPublicKeyHMAC: await this.generateOwnerPublicKeyHmac(
        inviteKey.uint8Array,
      ),
      inviteKeySignature: await this.generateSignature(inviteKey.encrypted),
    });

    return await this.generateServerLinkHTTP(body);
  }

  protected async generateServerLinkHTTP(
    body: GenerateInviteLinkHTTPBody,
  ): Promise<InviteLink> {
    const resp = await this.fetchWrapper.fetchAPI(
      `/shares/${this.journal.id}/invite`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      },
    );
    if (resp.status !== 200) throw new Error("Failed to create invite link");
    return (await resp.json()) as InviteLink;
  }

  private async appendInviteKey(data: InviteLink, invitationKey: InviteKey) {
    let path = data.link;
    if (path.includes("/accept-journal-invite")) {
      path = `${path}#${toBase64(invitationKey.uint8Array)}`;
    } else {
      throw new Error(
        "Invalid link format handed back, can't add invitationKey",
      );
    }
    data.link = path;
    return data;
  }
}
