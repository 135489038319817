export const headingThree = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.52 14.64C15 15 15.48 15.24 15.96 15.48C16.44 15.72 17.04 15.84 17.64 15.84C18.24 15.84 18.84 15.72 19.32 15.48C19.68 15.36 19.92 14.88 19.92 14.52C19.92 14.28 19.92 14.04 19.8 13.8C19.68 13.56 19.44 13.44 19.2 13.32C18.84 13.2 18.36 13.08 18 12.96C17.4 12.84 16.8 12.84 16.2 12.84V10.92C17.04 11.04 18 10.8 18.84 10.44C19.32 10.2 19.56 9.84 19.56 9.36C19.56 9 19.44 8.64 19.08 8.4C18.72 8.16 18.24 8.04 17.76 8.04C17.28 8.04 16.8 8.16 16.44 8.4C15.96 8.64 15.6 8.88 15.12 9.12L13.68 7.44C14.28 6.96 15 6.6 15.6 6.36C16.2 6.12 17.04 6 17.76 6C18.36 6 18.96 6.12 19.68 6.24C20.16 6.36 20.64 6.6 21.12 6.84C21.48 7.08 21.84 7.44 22.08 7.8C22.32 8.16 22.44 8.64 22.44 9.12C22.44 9.72 22.2 10.2 21.84 10.68C21.36 11.16 20.76 11.52 20.04 11.76V11.88C20.76 12 21.48 12.36 21.96 12.84C22.44 13.32 22.8 13.92 22.8 14.64C22.8 15.12 22.68 15.6 22.44 16.08C22.2 16.56 21.84 16.92 21.36 17.16C20.88 17.52 20.28 17.64 19.8 17.76C19.2 17.88 18.6 18 17.88 18C16.92 18 15.96 17.88 15.12 17.52C14.4 17.28 13.8 16.8 13.2 16.32L14.52 14.64ZM8.40001 10.8H3.60001V6H1.20001V18H3.60001V13.2H8.40001V18H10.8V6H8.40001V10.8Z" />
    </svg>
  );
};
