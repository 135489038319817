// Take items from the array from left to right until a condition is reached.
export const takeUntil = <T>(
  arr: T[],
  callback: (item: T, index: number, array: T[]) => boolean,
) => {
  let index = 0;

  while (index < arr.length && !callback(arr[index], index, arr)) {
    index += 1;
  }

  return arr.slice(0, index + 1);
};
