import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

const icon = (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.54999 11.9C4.26124 11.9 4.01405 11.7972 3.80843 11.5915C3.6028 11.3859 3.49999 11.1387 3.49999 10.85V3.84998H2.79999V2.79998H5.59999V2.09998H8.39999V2.79998H11.2V3.84998H10.5V10.8436C10.5 11.1395 10.3972 11.3896 10.1915 11.5937C9.98592 11.7979 9.73874 11.9 9.44999 11.9H4.54999ZM5.59999 9.79997H6.64999V4.89998H5.59999V9.79997ZM7.34999 9.79997H8.39999V4.89998H7.34999V9.79997Z"
      fill="currentColor"
    />
  </svg>
);

export const DeleteIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon={icon}
      color={IconColors.red.color}
      bgColor={IconColors.red.bgColor}
      size={14}
    />
  );
};
