import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

type Props = {
  direction: "left" | "right";
  name: string;
};

export const JournalSettingsSlideContent: React.FC<
  PropsWithChildren<Props>
> = ({ name, direction, children }) => {
  const styles =
    direction === "right"
      ? {
          initial: { opacity: 0, x: "-100%" },
          animate: { opacity: 1, x: "0" },
          exit: { opacity: 0, x: "-100%" },
        }
      : {
          initial: { opacity: 0, x: "100%" },
          animate: { opacity: 1, x: "0" },
          exit: { opacity: 0, x: "100%" },
        };
  return (
    <motion.div
      key={name}
      initial={styles.initial}
      animate={styles.animate}
      exit={styles.exit}
      transition={{ duration: 0.2, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};
