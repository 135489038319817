import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useRef, useState } from "react";
import { useLocation } from "wouter";

import { GroupHeading, Item } from "@/components/ItemGroup";
import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { ResultModal } from "@/components/Settings/Import/ResultModal";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { getSingleJournalURL } from "@/data/URLFunctions";
import { ImportJSONResult, importJSONZip } from "@/utils/import";
import {
  modalRouterViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

export const Import: React.FC = () => {
  const [importResult, setImportResult] = useState<
    ImportJSONResult | null | { status: "importing" }
  >(null);
  const [hasFile, setHasFile] = useState(false);
  const { __ } = useI18n();
  const [, setLocation] = useLocation();

  const ref = useRef<HTMLInputElement>(null);

  const handleImport = async () => {
    if (!ref.current) {
      return;
    }
    const file = ref.current.files?.[0];
    if (!file) {
      return;
    }
    setImportResult({ status: "importing" });
    const result = await importJSONZip(
      file,
      primaryViewState.isEncryptionReady,
      primaryViewState.journals,
    );
    setImportResult(result);
    if (result.status === "success") {
      ref.current.value = "";
      setHasFile(false);
    }
  };

  const handleFileChange = () => {
    setHasFile(!!ref.current?.files?.length);
  };

  return (
    <>
      <SettingsPanel header={<span>{__("Import")}</span>}>
        <GroupHeading id="import_json">
          {__("Import JSON Zip File")}
        </GroupHeading>
        <ItemGroup>
          <Item
            sx={{
              flexDirection: "column",
              "& p, & div": { width: "100%", mb: 2, lineHeight: "body" },
              "& input": { width: "100%" },
            }}
          >
            <p>Currently only will import files without media</p>
            <div>
              <input
                ref={ref}
                type="file"
                id="import_json_file"
                name="file"
                accept="application/zip"
                onChange={handleFileChange}
              />
              <Button
                sx={{ mt: 3 }}
                variant="primary"
                onClick={handleImport}
                disabled={!hasFile || importResult?.status === "importing"}
              >
                {__("Import")}
              </Button>
            </div>
          </Item>
        </ItemGroup>
      </SettingsPanel>
      {importResult && importResult.status === "success" && (
        <ResultModal
          title={__("Import Success")}
          handleClose={() => setImportResult(null)}
          successButton={
            importResult.journalId && (
              <Button
                variant="primary"
                onClick={() => {
                  const url = importResult.journalId
                    ? getSingleJournalURL(
                        importResult.journalId,
                        primaryViewState.selectedEntryView,
                      )
                    : null;
                  if (url) {
                    setImportResult(null);
                    setHasFile(false);
                    modalRouterViewState.close();
                    setLocation(url);
                  }
                }}
              >
                {__("Open Journal")}
              </Button>
            )
          }
        >
          <p>{__("Imported successfully")}</p>
        </ResultModal>
      )}
      {importResult && importResult.status === "warning" && (
        <ResultModal
          title={__("Some entries did not import")}
          handleClose={() => setImportResult(null)}
        >
          {importResult.error?.details && (
            <>
              <p sx={{ lineHeight: "body" }}>
                {__(
                  "The following entries in the export file failed to import:",
                )}
              </p>
              <ul sx={{ lineHeight: "body", listStyle: "disc", ml: 3, mt: 3 }}>
                {importResult.error.details.map((detail) => (
                  <li key={detail}>{detail}</li>
                ))}
              </ul>
            </>
          )}
        </ResultModal>
      )}
      {importResult && importResult.status === "error" && (
        <ResultModal
          title={__("An error occured while importing")}
          handleClose={() => setImportResult(null)}
        >
          {importResult.error?.type === "JOURNAL_SAVE_FAILED" && (
            <p>{__("Failed to create a new journal")}</p>
          )}
          {importResult.error?.type === "INVALID_ZIP_FILE" && (
            <p>{__("Unable to process the provided file.")}</p>
          )}
          {importResult.error?.type === "JSON_FILE_NOT_FOUND" && (
            <p>{__("Unable to find a JSON file.")}</p>
          )}
          {importResult.error?.type === "INVALID_JSON" && (
            <p>{__("Unable to read the contents of the file.")}</p>
          )}
        </ResultModal>
      )}
    </>
  );
};
