import { Button } from "@wordpress/components";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { ReactionSummary } from "@/components/SharedJournals/ReactionSummary";
import { ReactionsModal } from "@/components/SharedJournals/ReactionsModal";
import { ReactionDBRow } from "@/data/db/migrations/reaction";
import { AtLeast } from "@/utils/types";

export type PartialReaction = AtLeast<ReactionDBRow, "reaction" | "user_id">;

type Props = {
  reactions: PartialReaction[];
  size?: "small" | "medium" | "large";
  journalColor?: string;
  journalId: string;
};

export const Reactions: React.FC<Props> = observer(
  ({ reactions, journalColor, journalId }) => {
    const [showReactionModal, setShowReactionModal] = useState(false);
    if (reactions.length === 0) {
      return <div />;
    }

    return (
      <>
        <Button
          onClick={() => {
            analytics.tracks.recordEvent(EVENT.buttonTap, {
              button_identifier: "entryView_reactions",
            });
            setShowReactionModal(true);
          }}
          sx={{
            "&.components-button.is-secondary": {
              border: "none",
              padding: 0,
              borderRadius: "20px",
            },
          }}
        >
          <ReactionSummary reactions={reactions} color={journalColor} />
        </Button>
        {showReactionModal && (
          <ReactionsModal
            reactions={reactions}
            handleClose={() => setShowReactionModal(false)}
            journalColor={journalColor}
            journalId={journalId}
          />
        )}
      </>
    );
  },
);

Reactions.displayName = "Reactions";
