import { useI18n } from "@wordpress/react-i18n";

import { JournalDBRow } from "@/data/db/migrations/journal";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";

type Props = {
  user?: JournalParticipantDBRow;
  journal?: JournalDBRow;
  message: string;
  type?: string;
};

export const Message: React.FC<Props> = ({
  user,
  journal,
  message,
  type = "user",
}) => {
  const { __ } = useI18n();
  const name =
    type === "user"
      ? user?.name
        ? user.name
        : __("Unknown User")
      : journal?.name
        ? journal.name
        : __("Unknown Journal");
  return (
    <div
      sx={{
        fontSize: 1,
        lineHeight: 2,
        textAlign: "left",
        display: "-webkit-box",
        WebkitLineClamp: `${2}`,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
      }}
    >
      <strong>{name}</strong> {message}
    </div>
  );
};
