import { unregisterBlockType, registerBlockType } from "@wordpress/blocks";

import {
  LOCATION_BLOCK_ID,
  LocationBlockEditProps,
} from "@/components/Editor/blocks/constants";
import { SuggestionWrapper } from "@/components/Editor/blocks/suggestions/SuggestionWrapper";
import { blockIsRegistered } from "@/components/Editor/utils/register-blocks";
import { Location } from "@/components/icons/suggestions/Location";

const formatLatitude = (latitude: number) => {
  return `${Math.abs(latitude).toFixed(2)}°${latitude > 0 ? "N" : "S"} `;
};

const formatLongitude = (longitude: number) => {
  return `${Math.abs(longitude).toFixed(2)}°${longitude > 0 ? "E" : "W"}`;
};

export const LocationBlock: React.FC<LocationBlockEditProps> = ({
  attributes,
  clientId,
  isSelected,
}) => {
  const maybeTitle = attributes.placeName || "";
  const body = attributes.city || "";
  const latLong = `${formatLatitude(attributes.latitude)}, ${formatLongitude(
    attributes.longitude,
  )}`;
  let title = "";
  if (maybeTitle) {
    title = maybeTitle;
  } else if (body) {
    title = body;
  } else {
    title = latLong;
  }

  return (
    <SuggestionWrapper
      title={title}
      body={body}
      fallBackIcon={<Location />}
      clientId={clientId}
      isSelected={isSelected}
    />
  );
};

export const register = () => {
  if (!blockIsRegistered(LOCATION_BLOCK_ID)) {
    // @ts-ignore - seems types are not inline with code here.
    registerBlockType(LOCATION_BLOCK_ID, {
      edit: (props: LocationBlockEditProps) => {
        return <LocationBlock {...props} />;
      },
      title: "Location",
      category: "media",
      textdomain: "default",
      description: "Day One Location Block",
      attributes: {
        type: "string",
        identifier: "string",
        city: "string",
        placeName: "string",
        latitude: "number",
        longitude: "number",
        date: "string",
        source: "string",
      },
      supports: {
        lock: false,
        html: false,
        inserter: false,
      },
    });
  }
};

export const unregister = () => {
  if (blockIsRegistered(LOCATION_BLOCK_ID)) {
    return unregisterBlockType(LOCATION_BLOCK_ID);
  }
  return false;
};
