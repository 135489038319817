import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

type Props = {
  title?: string | React.ReactNode;
  label: string;
  onClick: (e: React.MouseEvent) => void | (() => void);
  disabled?: boolean;
  className?: string;
};
export const EditLinkButton: React.FC<Props> = ({
  title,
  label,
  onClick,
  disabled = false,
  className,
}) => {
  const { __ } = useI18n();
  return (
    <Button
      className={className}
      sx={{
        textDecoration: "none !important",
        fontSize: 1,
        "&&": { color: "primaryButtonText" },
        "&&:hover": {
          color: "primaryButtonText",
          opacity: "0.6",
        },
      }}
      variant="link"
      label={label}
      onClick={onClick}
      disabled={disabled}
    >
      {title || __("Edit")}
    </Button>
  );
};
