import { ThemeUICSSObject } from "@theme-ui/css";

export const quoteStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-quote": {
      fontSize: 2,
      lineHeight: 3,
      fontWeight: "body",
      color: "editorTextSecondary",
      borderColor: "editorBorderDefault",
      marginBottom: 0,
      marginLeft: "8px",
    },
  },
};
