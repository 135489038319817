import {
  NotificationIcon,
  IconColors,
} from "@/components/Notifications/NotificationIcon";

const icon = (
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.7125 4.52505L7.475 2.28755L8.0875 1.67505C8.29583 1.46672 8.54375 1.36255 8.83125 1.36255C9.11875 1.36255 9.36667 1.46672 9.575 1.67505L10.325 2.42505C10.525 2.62505 10.625 2.87297 10.625 3.1688C10.625 3.46463 10.525 3.71255 10.325 3.91255L9.7125 4.52505ZM8.975 5.26255L3.7375 10.5H1.5V8.26255L6.7375 3.02505L8.975 5.26255Z"
      fill="currentColor"
    />
  </svg>
);

export const EntryIcon: React.FC = () => {
  return (
    <NotificationIcon
      icon={icon}
      color={IconColors.blue.color}
      bgColor={IconColors.blue.bgColor}
      size={12}
    />
  );
};
