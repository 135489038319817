import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { EmptySearchHistoryAndFavoritesNavigation } from "@/components/Search/EmptySearchHistoryAndFavoritesNavigation";
import { FavoriteEntries } from "@/components/Search/FavoriteEntries";
import { RecentSearches } from "@/components/Search/RecentSearches";
import { SearchHistoryNavigationTitle } from "@/components/Search/SearchHistoryNavigationTitle";
import { searchViewState } from "@/view_state/ViewStates";

type Props = {
  onClose: () => void;
  contentHeight: number;
};

export const SearchHistoryAndFavoritesNavigation: React.FC<Props> = observer(
  ({ onClose, contentHeight }) => {
    const { __ } = useI18n();
    const { searchHistory, favoriteEntries: favoriteEntryGlobalIds } =
      searchViewState;

    if (searchHistory.length === 0 && favoriteEntryGlobalIds.length === 0) {
      return <EmptySearchHistoryAndFavoritesNavigation />;
    }

    return (
      <div
        sx={{
          height: contentHeight,
          overflowY: "auto",
          px: 3,
        }}
      >
        {searchHistory.length > 0 && (
          <div sx={{ px: 3 }}>
            <div sx={{ py: 2 }}>
              <SearchHistoryNavigationTitle title={__("Recent searches")} />
            </div>
            <div>
              <RecentSearches />
            </div>
          </div>
        )}
        {favoriteEntryGlobalIds.length > 0 && (
          <div sx={{ pt: 3 }}>
            <div sx={{ px: 3, pb: 2 }}>
              <SearchHistoryNavigationTitle title={__("Favorites")} />
            </div>
            <FavoriteEntries
              searchViewState={searchViewState}
              closeHandler={onClose}
            />
          </div>
        )}
      </div>
    );
  },
);
