export const DayOneLogo: React.FC<{ filled?: boolean }> = ({ filled }) => {
  return !filled ? (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_27_7208)">
        <rect width="61" height="61" rx="4.92377" fill="#44c0fe" />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="none"
          d="M16.0022 0H44.9977V36.8049C44.9977 38.9063 42.815 40.2963 40.9107 39.4075L30.9323 34.7501C30.6004 34.5952 30.2168 34.5961 29.8857 34.7527L20.1018 39.3773C18.1965 40.278 16.0022 38.8881 16.0022 36.7806V0Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2759 0H44.7243V37.0069C44.7243 38.8288 42.8319 40.0338 41.1811 39.2632L30.9402 34.4827C30.6045 34.326 30.2164 34.3269 29.8815 34.4853L19.83 39.237C18.1782 40.0179 16.2759 38.813 16.2759 36.9859V0Z"
          fill="white"
        />
      </g>
      <rect
        x="0.324468"
        y="0.324468"
        width="60.3511"
        height="60.3511"
        rx="11.6755"
        stroke="black"
        strokeOpacity="0.05"
        strokeWidth="0.648936"
      />
      <defs>
        <clipPath id="clip0_27_7208">
          <rect width="61" height="61" rx="12" fill="#44c0fe" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6881 29.3188C31.6881 30.6276 30.6271 31.6884 29.3186 31.6884H4.70428C3.39555 31.6884 2.33478 30.6273 2.33478 29.3188V4.70452C2.33478 3.39579 3.39578 2.33501 4.70428 2.33501H10.0068V20.8844C10.0068 21.5478 10.5031 21.8564 11.1153 21.5725L16.4131 19.1177C16.7196 18.9758 17.2162 18.9749 17.5233 19.1154L22.9053 21.5781C23.5191 21.8589 24.0161 21.5483 24.0161 20.8844V2.33501H29.3186C30.6273 2.33501 31.6881 3.39603 31.6881 4.70452V29.3188ZM29.3186 0H4.70428C2.11036 0 0 2.11061 0 4.70452V29.3188C0 31.9128 2.11036 34.0231 4.70428 34.0231H29.3186C31.9125 34.0231 34.0231 31.9128 34.0231 29.3188V4.70452C34.0231 2.11061 31.9125 0 29.3186 0Z"
        fill="#44C0FF"
      />
    </svg>
  );
};
