import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { Previewer } from "@/components/Editor/Previewer";
import { HEADER_HEIGHT } from "@/components/LoggedOut/HeaderBar";
import { ModalRouterComponent } from "@/layouts/ModalRouterComponent";
import { Action } from "@/pages/prefilledUrl/Action";
import { ErrorMessage } from "@/pages/prefilledUrl/ErrorMessage";
import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";

type Props = {
  state: PrefilledEntryViewState;
  isMobile: boolean;
};

export const EntryPreview: React.FC<Props> = ({ state, isMobile }) => {
  const { __ } = useI18n();
  return (
    <Flex
      sx={{
        width: "100%",
        maxWidth: "800px",
        p: isMobile ? 4 : 8,
        pt: 0,
        alignSelf: "center",
        justifyContent: "auto",
        borderRadius: isMobile ? 0 : 3,
        height: isMobile ? `calc(100dvh - ${HEADER_HEIGHT})` : "auto",

        "& > *": {
          flex: "1 1 auto",
          textAlign: isMobile ? "left" : "center",
        },
      }}
      direction="column"
      align="center"
    >
      {/* Error message! */}
      {state.error && <ErrorMessage />}
      <div
        sx={{
          overflowY: "auto",
          alignSelf: "normal",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <h2 sx={{ mb: 2 }}>{__("Entry Preview")}</h2>
        <p sx={{ mb: 4 }}>
          {__("Would you like to make a new entry with the following content?")}
        </p>
        <div
          sx={{
            p: 0,
            borderRadius: 3,
            bg: "surface_light1_dark3",
            textAlign: "left",
          }}
        >
          <Previewer blocks={state.blocks} />
        </div>
      </div>
      {/* Buttons! */}
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
          width: "100%",
          mt: 2,
          mb: 4,
          "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            px: isMobile ? 4 : 8,
            top: "-86px",
            left: 0,
            right: 0,
            height: "70px",
            bg: "surface_light2_dark1",
            background: (theme) =>
              `linear-gradient(0deg, ${theme?.colors?.surface_light1_dark1 ?? "transparent"}, transparent)`,
          },
        }}
      >
        <Action state={state} isMobile={isMobile} />
      </div>
      <ModalRouterComponent />
    </Flex>
  );
};
