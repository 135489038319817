export const Mountains: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 21L15.3833 8.53125C15.2099 8.20436 14.8701 8 14.5 8C14.1299 8 13.7901 8.20436 13.6167 8.53125L7 21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0802 13.6135L16.29 14.6135"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 13.6135L16.2902 14.6135"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7097 14.6135L14.4999 13.6135"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9197 13.6135L12.7098 14.6135"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 21H1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43506 4.51856C9.35978 4.61468 10.0468 5.4188 9.99748 6.34719C9.94813 7.27558 9.1797 8.00233 8.25 7.99988H4.5C3.67157 7.99988 3 7.32831 3 6.49988C3 5.67145 3.67157 4.99988 4.5 4.99988C4.49826 3.98965 5.25139 3.13732 6.25414 3.01467C7.2569 2.89202 8.19325 3.5377 8.43506 4.51856Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.95966 15.4228L8.378 12.5214C8.20283 12.2 7.86604 12 7.5 12C7.13396 12 6.79717 12.2 6.622 12.5214L2 21.0001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
