import { liveQuery } from "dexie";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { Asymmetric, Fingerprint, Symmetric } from "@/crypto/DOCryptoBasics";
import { AsymmetricKeys } from "@/crypto/DOCryptoBasics/asymmetric/generateNewKeyPair";
import { MasterKey } from "@/crypto/types/MasterKey";
import { Utf8 } from "@/crypto/utf8";
import { toBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import {
  LockedUserKeysDBRow,
  UnlockedUserKeysDBRow,
  UserKeysDBRow,
} from "@/data/db/migrations/user_keys";
import { UserKeyResult } from "@/data/repositories/UserAPI";
import { UserRepository } from "@/data/repositories/UserRepository";
import { uuid } from "@/utils/uuid";

// This is where we saved the user's key (Eg: Google Drive, PDF file, etc)
export type LocationOfKeyInput =
  //--- Ones under here are ones we defined ---
  | "pdfDownloadWeb"
  | "manualBackup"
  // Old location we were saving to Google drive from web
  | "driveWeb";

export type LocationOfKey =
  | LocationOfKeyInput
  // these where both found in the Apple code here
  // https://github.com/bloom/DayOne-Apple/blob/7e226bf6296082b482486687d00d2057fc7ff14a/core/DOCore/DOCore/DOWebUser.swift#L15-L17
  | "cloudkit"
  | "drive"
  // There's no validation on the server so technically it could be anything
  | string;

export function isUserKeysDBRowLocked(
  row: UserKeysDBRow,
): row is LockedUserKeysDBRow {
  return "lockedD1" in row.private_key;
}

export function isUserKeysDBRowUnlocked(
  row: UserKeysDBRow,
): row is UnlockedUserKeysDBRow {
  return row.private_key instanceof Uint8Array;
}

export type UnlockedUserPrivateKey = {
  fingerprint: string;
  key: CryptoKey;
};

export type UserKeysFromServer = {
  mainKey: UserKeyResult | null;
  otherKeys: UserKeyResult[];
};

export type SaveUserKeyParams = Omit<
  UserKeysDBRow,
  "public_key_fingerprint" | "local_id"
> & { local_id: null | string };
export type SaveMainUserKeyParams = Omit<SaveUserKeyParams, "local_id">;

export class UserKeysRepository {
  private cacheResults = true;
  private cache = new Map<string, CryptoKey>();

  constructor(
    protected db: DODexie,
    private userRepository: UserRepository,
  ) {}

  async getMainKey() {
    // The user may have multiple private keys because of historical bugs
    // on other platforms. We should prefer the key we get from `/api/users/key`
    // and we store that one in the local database with the local_id "main".
    return this.getKeyByLocalId("main");
  }

  async getKeyByLocalId(local_id: string) {
    const result = await this.db.user_keys.get(local_id);
    return result;
  }

  async getMainPrivateKey(): Promise<CryptoKey | null> {
    const main_private_cache_name = "main_private_key";
    const cached = this.cache.get(main_private_cache_name);
    if (this.cacheResults && cached) {
      return cached;
    }
    const row = await this.getMainKey();
    if (row && isUserKeysDBRowUnlocked(row)) {
      const imported = await Asymmetric.Private.fromRawBytes(row.private_key);
      this.cacheResults && this.cache.set(main_private_cache_name, imported);
      return imported;
    }
    return null;
  }

  async getMainPublicKey(): Promise<CryptoKey | null> {
    const main_public_cache_name = "main_public_key";
    const cached = this.cache.get(main_public_cache_name);
    if (this.cacheResults && cached) {
      return cached;
    }
    const row = await this.getMainKey();
    if (row) {
      const imported = await Asymmetric.Public.fromBytes(row.public_key);
      this.cacheResults && this.cache.set(main_public_cache_name, imported);
      return imported;
    }
    return null;
  }

  async getAllKeys(): Promise<UserKeysDBRow[]> {
    return this.db.user_keys.toArray();
  }

  async getAllUnlockedKeys(): Promise<UnlockedUserPrivateKey[]> {
    const rows = await this.db.user_keys.toArray();
    return Promise.all(
      rows
        .filter((x) => isUserKeysDBRowUnlocked(x))
        .map(async (row) => {
          if (!isUserKeysDBRowUnlocked(row))
            throw new Error("Missing filter for unlocked keys");
          return {
            fingerprint: row.public_key_fingerprint,
            key: await Asymmetric.Private.fromRawBytes(row.private_key),
          };
        }),
    );
  }

  async saveMainKey(data: SaveMainUserKeyParams) {
    // There's a 'main' key that we use to lock up new grants,
    // but there's also the possibility of multiple keys for historical reasons.
    // We'll mark the main key with the local_id "main" so we can find it easily later.
    return this.saveKey({ ...data, local_id: "main" });
  }

  async saveKey(data: SaveUserKeyParams) {
    const fingerprint = await Fingerprint.forKeyBytes(data.public_key);
    await this.db.user_keys.put({
      ...data,
      local_id: data.local_id || fingerprint,
      public_key_fingerprint: fingerprint,
    });
  }

  async fetchMainKeyFromServer() {
    const result = await d1Classes.fetchWrapper.fetchAPI(
      "/users/key",
      undefined,
      {
        expectedStatusCodes: [404],
      },
    );
    if (result.status === 404) {
      return null;
    } else if (result && result.status >= 200 && result.status <= 300) {
      return (await result.json()) as UserKeyResult;
    } else {
      Sentry.captureException(
        new Error(
          `Failed to fetch user key - Status: ${
            result.status
          } - Text: ${await result.text()}`,
        ),
      );
      return null;
    }
  }

  async putMainKeyOnServer(
    masterKey: MasterKey,
    keyPair: AsymmetricKeys,
    // This is where the user's key is stored the key (Eg: Google Drive, PDF file, etc)
    locationOfKey: LocationOfKey,
  ) {
    if (process.env.NODE_ENV === "test") {
      return;
    }
    const nonce = Utf8.toUintArray(uuid());
    const signature = await Asymmetric.Private.signArrayBuffer({
      buffer: nonce,
      userPrivateKey: keyPair.keyPair.privateKey,
    });

    const body: {
      nonce: string; // A random thing you signed, in this case a UUID
      signature: string; // nonce signed with the private key
      publicKey: string;
      encryptedPrivateKey: string; // Private key encrypted using the Master Key
    } = {
      nonce: toBase64(nonce),
      signature,
      publicKey: keyPair.publicKeyPEM,
      encryptedPrivateKey: await Symmetric.encryptToD1(
        keyPair.privateKeyPEM,
        await masterKey.derivedKey,
      ),
    };

    const result = await d1Classes.fetchWrapper.fetchAPI("/users/key", {
      method: "PUT",
      body: JSON.stringify(body),
    });

    if (result && result.status >= 200 && result.status <= 300) {
      await this.saveMainKey({
        user_id: masterKey.userId,
        public_key: await Asymmetric.Public.toUint8Array(
          keyPair.keyPair.publicKey,
        ),
        private_key: await Asymmetric.Private.toUint8Array(
          keyPair.keyPair.privateKey,
        ),
      });
      await this.saveLocationOfKey(locationOfKey);

      return (await result.json()) as UserKeyResult;
    } else {
      Sentry.captureException(
        new Error(
          `Failed to put user key - Status: ${
            result.status
          } - Text: ${await result.text()}`,
        ),
      );
      return null;
    }
  }
  async saveLocationOfKey(locationOfKey: LocationOfKey) {
    const response = await d1Classes.fetchWrapper.fetchAPI(
      "/users/master_key_storage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body:
          // needs to be wrapped in quotes
          JSON.stringify(locationOfKey),
      },
    );

    if (response.ok) {
      const user = await this.userRepository.getActiveUser();
      if (user) {
        const currentLocations = user.master_key_storage;
        if (!currentLocations.includes(locationOfKey)) {
          currentLocations.push(locationOfKey);
        }
        this.userRepository.updateMasterKeyStorageLocation(currentLocations);
      }
      // plain text response
      return await response.text();
    } else {
      throw new Error(
        `Failed to post location of key - Status: ${
          response.status
        } - Text: ${await response.text()}`,
      );
    }
  }

  async removeLocationOfKey(locationOfKey: LocationOfKey) {
    const response = await d1Classes.fetchWrapper.fetchAPI(
      "/users/master_key_storage",
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body:
          // needs to be wrapped in quotes
          JSON.stringify(locationOfKey),
      },
    );

    if (response.ok) {
      const user = await this.userRepository.getActiveUser();
      if (user) {
        const currentLocations = user.master_key_storage.filter(
          (location) => location !== locationOfKey,
        );
        this.userRepository.updateMasterKeyStorageLocation(currentLocations);
      }
      // plain text response
      return await response.text();
    } else {
      throw new Error(
        `Failed to remove location of key - Status: ${
          response.status
        } - Text: ${await response.text()}`,
      );
    }
  }

  async canDecryptOrEncrypt() {
    const mainKey = await this.getMainKey();
    return !mainKey ? false : isUserKeysDBRowUnlocked(mainKey);
  }

  subscribetoActiveKey(callback: (activeKey: UserKeysDBRow) => void) {
    const sub = liveQuery(async () => {
      return this.db.user_keys.where("local_id").equals("main").toArray();
    }).subscribe((keys) => {
      if (keys.length > 0) callback(keys[0]);
    });
    return () => sub.unsubscribe();
  }
}
