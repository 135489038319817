import { Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { TextareaControl } from "@/components/Form/TextareaControl";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { JournalDBRow } from "@/data/db/migrations/journal";

type EditJournalDescProps = {
  currentJournal: JournalDBRow;
  handleClose: () => void;
  setJournal: (journal: JournalDBRow) => void;
};
export const EditJournalDescription: React.FC<EditJournalDescProps> = ({
  currentJournal,
  handleClose,
  setJournal,
}) => {
  const [description, setDescription] = useState(currentJournal.description);
  return (
    <D1Modal
      title={__("Edit Journal Description")}
      onRequestClose={handleClose}
      sx={{ width: "420px" }}
    >
      <EditSection>
        <FlexItem sx={{ flex: 1 }}>
          <Label htmlFor="description">{__("Journal description")}</Label>
          <Flex sx={{ mb: 2 }}>
            <FlexBlock>
              <TextareaControl
                onChange={(value) => {
                  setDescription(value);
                }}
                value={description}
                autoFocus
              />
            </FlexBlock>
          </Flex>
          <Flex>
            <EditButtons
              isSaving={false}
              doSave={() => {
                setJournal({ ...currentJournal, description });
                handleClose();
              }}
              attributeChanged={description !== currentJournal.description}
              doCancel={handleClose}
            />
          </Flex>
        </FlexItem>
      </EditSection>
    </D1Modal>
  );
};
