import { __ } from "@wordpress/i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { NoPreview } from "@/components/Editor/blocks/preview/NoPreview";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { fromBase64 } from "@/crypto/utils";
import { useElementSize } from "@/hooks/useElementSize";
import { dayOneBlue } from "@/styles/theme";

type CustomEmbedProps = {
  url: string;
  type: string;
  id: string;
  isSelected: boolean;
};

type EmbedDetails = {
  image?: string | null;
  title: string;
  description: string;
  imageURL?: string;
};

export const CustomEmbed: React.FC<CustomEmbedProps> = ({
  url,
  type,
  id,
  isSelected,
}) => {
  const [details, setDetails] = useState<EmbedDetails | null | "no-preview">(
    null,
  );
  const { width } = useElementSize("entry-editor");
  const isMobile = width < 480;

  useEffect(() => {
    try {
      d1Classes.msApiClient.getCustomEmbedInfo
        .query({ type, id })
        .then((result) => {
          if (result) {
            if (result.image) {
              const imageBytes = fromBase64(result.image);
              const blob = new Blob([imageBytes]);
              const imageURL = URL.createObjectURL(blob);
              setDetails({ ...result, imageURL });
            } else {
              setDetails(result);
            }
          } else {
            setDetails("no-preview");
          }
        })
        .catch(() => {
          setDetails("no-preview");
        });
    } catch (e) {
      setDetails("no-preview");
    }
    return () => {
      if (details !== "no-preview" && details?.imageURL) {
        URL.revokeObjectURL(details?.imageURL);
      }
    };
  }, [url, type, id]);

  if (details === null) {
    return <LoadingMediaPlaceholder height={150} />;
  } else if (details === "no-preview") {
    return <NoPreview url={url} />;
  }
  return (
    <div
      sx={{
        mt: 5,
        border: "3px solid",
        borderColor: isSelected ? dayOneBlue : "transparent",
        borderRadius: "3px",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          backgroundColor: "surface_light3_dark3",
          color: "textPrimary",
          p: 2,
          gap: 2,
        }}
      >
        {details.imageURL && (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "block",
              flexShrink: 0,
              maxWidth: isMobile ? "100%" : "50%",
            }}
          >
            <img
              crossOrigin="anonymous"
              src={details.imageURL}
              alt={details.description}
              sx={{
                maxHeight: "250px",
                display: "block",
              }}
            />
          </a>
        )}
        <div sx={{ flexGrow: 0, "& p": { fontSize: 1, lineHeight: "body" } }}>
          <h2 sx={{ mb: 2, wordBreak: "break-word" }}>{details.title}</h2>
          <p
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {details.description}
          </p>
          <p sx={{ mt: 2 }}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "primary", textDecoration: "underline" }}
            >
              {__("Visit link")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
