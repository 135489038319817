import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import {
  AUTH_TOKEN_EXPIRY_TIME,
  AuthToken,
  deserializeAuthToken,
} from "@/crypto/utils/authToken";

export const useNeedsAuthRefresh = () => {
  const [needsRefresh, setNeedsRefresh] = useState<boolean | null>(null);
  const [tokenTimestamp, setTokenTimestamp] = useState<number | null>(null);

  useEffect(() => {
    const unsub = d1Classes.secureKeyValueStore.subscribe<AuthToken>(
      "dayone-auth-token",
      (token) => {
        if (token) {
          setTokenTimestamp(token.timestamp);
          d1Classes.userRepository
            .authTokenNeedsRefresh()
            .then(setNeedsRefresh);
        }
      },
      deserializeAuthToken,
    );
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (!tokenTimestamp) return;
    const expiryTime = tokenTimestamp + AUTH_TOKEN_EXPIRY_TIME;
    const difference = expiryTime - Date.now();
    const delay = difference > 0 ? difference : 0;
    const timer = setTimeout(() => {
      d1Classes.userRepository.authTokenNeedsRefresh().then(setNeedsRefresh);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [tokenTimestamp]);

  return { needsRefresh };
};
