import { Button } from "@wordpress/components";
type Props = {
  text: string;
  onClick: () => void;
};

export const MetadataButton: React.FC<Props> = ({ text, onClick }) => {
  return (
    <Button
      sx={{
        "&&": {
          textDecoration: "none",
          fontSize: 1,
          color: "textSecondary",
        },
      }}
      variant="link"
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
