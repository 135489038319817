import { decryptD1 } from "@/crypto/DOCrypto/D1/decryptD1";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export const decryptEntryD1 = async (
  d1: Uint8Array,
  debugContext: string,
  vaultRepository: VaultRepository,
) => {
  return decryptD1(d1, debugContext, vaultRepository);
};
