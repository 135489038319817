import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";

export const decryptPrivateKey = async (
  unlockingKey: CryptoKey,
  base64PrivateKeyD1: string,
) => {
  const d1 = await DOCrypto.D1.parse(fromBase64(base64PrivateKeyD1));
  const iv = d1.iv;
  const pem = await Symmetric.decryptBufferAndIV(
    iv,
    unlockingKey,
    DOCrypto.D1.getBodyAsArrayBuffer(d1),
  );
  return Asymmetric.Private.fromPEM(Utf8.fromBufferSource(pem));
};
