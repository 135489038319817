import { Flex } from "@wordpress/components";

export const EditSection: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Flex
      sx={{
        mt: 0,
        mb: 0,
      }}
    >
      {children}
    </Flex>
  );
};
