import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";

type Props = {
  userId: string | null;
  direction?: "row" | "column";
  size?: string;
};

export const ParticipantName: React.FC<Props> = ({
  userId,
  size = "small",
}) => {
  const user = useSharedJournalParticipant(userId, false);

  if (!user) {
    return null;
  }

  return (
    <span
      sx={{
        fontSize: size === "small" ? 0 : 1,
        whiteSpace: "nowrap",
        fontWeight: "heading",
      }}
    >
      {user?.name}
    </span>
  );
};
