import { __experimentalDivider as Divider } from "@wordpress/components";
import { __ } from "@wordpress/i18n";

export const OrSeparator: React.FC = () => {
  return (
    <div
      sx={{
        fontWeight: "light",
        display: "flex",
        alignItems: "center",
        mb: 4,
        fontSize: 1,
        textTransform: "uppercase",
        hr: {
          color: "borderPrimary",
          flexGrow: 1,
        },
      }}
    >
      <Divider />
      <div sx={{ px: 3 }}>{__("Or")}</div>
      <Divider />
    </div>
  );
};
