import { store as blockEditorStore } from "@wordpress/block-editor";
import { BlockInstance, createBlock } from "@wordpress/blocks";
import { useDispatch, useSelect } from "@wordpress/data";
import { useRef } from "react";

import { useBlockSelection } from "@/components/Editor/hooks/blockSelection";
import { getBlockContent } from "@/components/Editor/utils/block-content";

/**
 * This is a simple swapping function that lets you easily swap one block for another.
 */
const swapBlocksFn =
  (name: string, extraAttributes?: Record<string, unknown>) =>
  (blocks: BlockInstance[]) =>
    blocks.map((block) => {
      const transformed = {
        ...block,
        content: getBlockContent(block),
        ...(extraAttributes || {}),
      };
      return createBlock(name, transformed);
    });

/**
 * Replaces the selected blocks while preserving the selection both single line and multi-line.
 */
export const useReplaceSelectedBlocks = () => {
  const { blocks, blockClientIds } = useBlockSelection();
  const { replaceBlocks } = useDispatch("core/block-editor");
  const { saveCursorPosition, selectCursorAgain } = useCursor();

  async function replaceSelectedBlocks(
    transformer: (blocks: BlockInstance[]) => BlockInstance[],
  ) {
    const convertedBlocks: BlockInstance[] = transformer(blocks);
    saveCursorPosition();
    await replaceBlocks(blockClientIds, convertedBlocks);
    selectCursorAgain(convertedBlocks);
  }

  return {
    swapBlocksFn,
    replaceSelectedBlocks,
    blocks,
    blockClientIds,
  };
};

export interface CursorPosition {
  selectionStart: any;
  selectionEnd: any;
  hasMultiSelection: boolean;
}

export const useCursor = () => {
  const {
    getSelectionStart,
    getSelectionEnd,
    hasMultiSelection: _hasMultiSelection,
  } = useSelect<any>(blockEditorStore, []);
  const { selectionChange, multiSelect } = useDispatch("core/block-editor");
  const getBlockOrder = useSelect(
    // @ts-ignore - The type defs are out of date.
    (select) => select(blockEditorStore).getBlockOrder,
    [],
  );
  const cursorRef = useRef<CursorPosition>();
  const blockOrder = useRef<string[]>([]);

  const saveCursorPosition = () => {
    const selectionStart = getSelectionStart();
    const selectionEnd = getSelectionEnd();
    const hasMultiSelection = _hasMultiSelection();
    cursorRef.current = { selectionStart, selectionEnd, hasMultiSelection };

    return { selectionStart, selectionEnd, hasMultiSelection };
  };

  const selectConvertedBlocks = (convertedBlocks: BlockInstance[]) => {
    multiSelect(
      convertedBlocks[0].clientId,
      convertedBlocks[convertedBlocks.length - 1].clientId,
    );
  };

  const selectCursorAgain = (convertedBlocks: BlockInstance[]) => {
    if (cursorRef.current) {
      const { selectionStart, selectionEnd, hasMultiSelection } =
        cursorRef.current;
      if (!hasMultiSelection) {
        // default of replacing a block will put cursor at the front
        // of a block. We have to do this to actually preserve the
        // selection after it actually changes.
        let blockToSelect = convertedBlocks[0];

        // When the selected block has inner blocks, we select the first one
        if (blockToSelect.innerBlocks?.length) {
          blockToSelect = blockToSelect.innerBlocks[0];
        }
        selectionChange(
          blockToSelect.clientId,
          selectionEnd.attributeKey,
          selectionEnd.clientId === selectionStart.clientId
            ? selectionStart.offset
            : selectionEnd.offset,
          selectionEnd.offset,
        );
      } else {
        selectConvertedBlocks(convertedBlocks);
      }
    } else {
      throw new Error("Please saveCursorPosition before selectCursorAgain");
    }
  };

  // saveBlockOrder and getNewBlockIds are helpful to find new blocks
  // after a replaceBlocks call.
  // use it like this:
  // saveBlockOrder();
  // replaceBlocks(...);
  // const newBlockIds = getNewBlockIds();
  const saveBlockOrder = () => {
    blockOrder.current = getBlockOrder();
  };

  const getNewBlockIds = () => {
    if (blockOrder.current.length === 0)
      throw new Error("Please saveBlockOrder before getNewBlockIds");

    const newBlockOrder: string[] = getBlockOrder();
    const newBlockIds = newBlockOrder.filter(
      (id) => !blockOrder.current.includes(id),
    );
    return newBlockIds;
  };

  const selectLastInnerBlock = (block: BlockInstance) => {
    const { innerBlocks } = block;
    const lastInnerBlock = innerBlocks[innerBlocks.length - 1];
    const offset = lastInnerBlock.attributes?.content?.replace(
      /<[^>]*>/g,
      "",
    ).length;
    selectionChange(lastInnerBlock.clientId, "content", offset, offset);
  };

  return {
    saveCursorPosition,
    selectCursorAgain,
    selectConvertedBlocks,
    saveBlockOrder,
    getNewBlockIds,
    selectLastInnerBlock,
  };
};
