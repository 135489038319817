export const ThunderstormNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0781 8.689C16.5161 8.689 15.9861 8.805 15.5231 9.036C15.1761 6.753 13.1911 5 10.8091 5C8.21209 5 6.09509 7.084 6.02909 9.665C4.47409 9.863 3.26709 11.187 3.26709 12.824C3.26609 14.577 4.68909 16 6.44209 16H17.0781C19.0961 16 20.7341 14.362 20.7341 12.344C20.7341 10.326 19.0961 8.689 17.0781 8.689V8.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2003 23L13.2003 21H10.8003L11.8003 19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 19L16.5 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 19L6 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0498 5.01402C12.3018 2.77002 14.1858 1.02002 16.4998 1.02002C16.4998 3.50202 18.5148 5.51402 20.9998 5.51402C20.9998 7.01702 20.2538 8.34002 19.1188 9.15502"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
