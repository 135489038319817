export const Pin: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      id="pin"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.4 7.99844L12 9.59844V10.7984H8.6V14.5984L8 15.1984L7.4 14.5984V10.7984H4V9.59844L5.6 7.99844V3.59844H4.8V2.39844H11.2V3.59844H10.4V7.99844Z" />
    </svg>
  );
};
