import {
  ColorModeProvider,
  useColorMode as useThemeUIColorMode,
} from "@theme-ui/color-modes";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import React, { createContext } from "react";

import { viewStates } from "@/view_state/ViewStates";

type ColorModeValues = "light" | "dark" | "system";

type ColorModeContext = {
  colorMode: ColorModeValues | null;
  // True if the user has explicitly set the color mode to black
  // regardless of system appearance, or if the user has
  // chosen "system" and the system is dark.
  inDarkMode: boolean;
  rotateTheme: (mode: ColorModeValues) => void;
  setTheme: (mode: string) => void;
};

const brokenDefaultContext: ColorModeContext = {
  colorMode: "system",
  inDarkMode: false,
  rotateTheme: () => {
    throw new Error("The ColorMode context was used without a provider");
  },
  setTheme: () => {
    throw new Error("The ColorMode context was used without a provider");
  },
};

const ColorMode = createContext<ColorModeContext>(brokenDefaultContext);

const DayOneProvider: React.FC<{ children: React.ReactNode }> = observer(
  ({ children }) => {
    const { selectedTheme, selectTheme } = viewStates.theme;
    const [colorMode, setColorMode] = useThemeUIColorMode();

    // @see theme-ui: https://github.com/system-ui/theme-ui/blob/9c697774fd959104ea831e2031ec6f764d3d6ac7/packages/color-modes/src/index.tsx#L68
    const DARK_QUERY = "(prefers-color-scheme: dark)";
    const LIGHT_QUERY = "(prefers-color-scheme: light)";

    const getPreferredColorScheme = (): "dark" | "light" | null => {
      if (typeof window !== "undefined" && window.matchMedia) {
        if (window.matchMedia(DARK_QUERY).matches) {
          return "dark";
        }
        if (window.matchMedia(LIGHT_QUERY).matches) {
          return "light";
        }
      }
      return null;
    };

    useEffect(() => {
      const setPreferredColorScheme = () => {
        if (selectedTheme !== "system") {
          return; // if we aren't using the system theme, ignore this change
        }

        const preferredColorScheme = getPreferredColorScheme();
        setColorMode(preferredColorScheme || "light");
      };

      // @see theme-ui: https://github.com/system-ui/theme-ui/blob/9c697774fd959104ea831e2031ec6f764d3d6ac7/packages/color-modes/src/index.tsx#L132
      const darkMQL = window.matchMedia(DARK_QUERY);
      darkMQL.addEventListener("change", setPreferredColorScheme);

      // if viewstate selectedTheme and theme-ui colorMode are out of sync, fix it
      // (this can happen if theme-ui loses its state in localStorage)
      if (
        selectedTheme &&
        selectedTheme !== colorMode &&
        "system" !== selectedTheme
      ) {
        setColorMode(selectedTheme || "light");
      } else if (selectedTheme && "system" === selectedTheme) {
        const preferredColorScheme = getPreferredColorScheme();
        if (preferredColorScheme !== null) {
          setColorMode(preferredColorScheme);
        }
      }

      if (!selectedTheme) {
        selectTheme(colorMode);
      }

      return () => {
        darkMQL.removeEventListener("change", setPreferredColorScheme);
      };
    }, [colorMode, selectedTheme]);

    const setTheme = (mode: string) => {
      if (
        mode === selectedTheme ||
        !["dark", "light", "system"].includes(mode)
      ) {
        return;
      }

      selectTheme(mode);
    };

    // rotating themes overrides (skips) the system theme
    const rotateTheme = (mode: ColorModeValues) => {
      const currentMode =
        mode === "system" ? getPreferredColorScheme() || "light" : mode;
      const modeRotate: { dark: string; light: string } | any = {
        dark: "light",
        light: "dark",
      };
      const newTheme = modeRotate[currentMode];
      selectTheme(newTheme);
    };

    const value = {
      colorMode: selectedTheme,
      inDarkMode:
        selectedTheme === "system"
          ? colorMode === "dark"
          : selectedTheme === "dark",
      rotateTheme,
      setTheme,
    };

    return <ColorMode.Provider value={value}>{children}</ColorMode.Provider>;
  },
);

DayOneProvider.displayName = "DayOneProvider";

export const DayOneColorModeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ColorModeProvider>
      <DayOneProvider>{children}</DayOneProvider>
    </ColorModeProvider>
  );
};

export const useColorMode = () => {
  const context = useContext(ColorMode);
  if (context === undefined) {
    throw new Error("useColorMode must be used within a ColorModeProvider");
  }
  return context;
};
