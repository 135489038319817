import { TagDBRow } from "@/data/db/migrations/tag";

export const tagsAsArray = (tags: string[] | string): string[] => {
  if (typeof tags === "string") {
    return tags.length > 0 ? tags.split(",") : [];
  }
  return tags;
};

export const uniqueTagId = (t: TagDBRow) =>
  `${t.tag}/${t.journal_id}/${t.entry_id}`;
