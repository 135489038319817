import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { Item } from "@/components/ItemGroup";
import { AccountDeletionModal } from "@/components/Settings/Account/AccountDeletionModal";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";

export const DeleteAccount: React.FC = () => {
  const { __ } = useI18n();
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <>
      <Item>
        <FlexItem>
          <p sx={{ color: "journalRed" }}>
            {__("Permanently delete your Day One account.")}
          </p>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={__("Permanently Delete Account")}
            onClick={() => setConfirmDelete(true)}
            title={__("Delete")}
          />
        </FlexItem>
      </Item>
      {confirmDelete && (
        <AccountDeletionModal onRequestClose={() => setConfirmDelete(false)} />
      )}
    </>
  );
};
