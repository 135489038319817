import { canvasToBlob, createCanvas } from "@/utils/canvas-helpers";

export const loadFileArrayBuffer = (file: File) => {
  return new Promise<ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as ArrayBuffer | null);
    };

    reader.onerror = () => {
      reject(new Error("Error, could not load file array buffer."));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = () =>
      reject(new Error(`Error, could not load image: ${src}`));
    img.src = src;
  });
};

export const convertSVGToPNG = async (file: File) => {
  const url = URL.createObjectURL(file);

  // Load image and handle errors
  try {
    const loadedImage = await loadImage(url);
    const { width, height } = loadedImage;
    const { canvas, context } = createCanvas(width, height);

    context.drawImage(loadedImage, 0, 0);
    const blob = await canvasToBlob(canvas, "image/png");

    const pngFile = new File([blob], "converted-image.png", {
      type: "image/png",
    });

    return pngFile;
  } catch (error) {
    throw new Error("Error, could not convert SVG to PNG.");
  } finally {
    URL.revokeObjectURL(url);
  }
};

export const getImageDimensionsFromArrayBuffer = (
  fileType: string,
  buffer: ArrayBuffer,
) => {
  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const arr = new Uint8Array(buffer);
    const blob = new Blob([arr], { type: fileType });
    const imgUrl = URL.createObjectURL(blob);

    const img = new Image();

    img.onload = () => {
      resolve({ height: img.height, width: img.width });
    };

    img.onerror = () => {
      reject(new Error("Error, could not load image."));
    };

    img.src = imgUrl;
  });
};

export const getBase64FromArrayBuffer = (buffer: ArrayBuffer | Uint8Array) => {
  return new Promise<string>((resolve, reject) => {
    const blob = new Blob([buffer]);

    const reader = new FileReader();

    reader.onload = () => {
      // get the raw base 64 without the data header
      resolve((reader.result as string).split(";base64,")[1]);
    };

    reader.onerror = () => {
      reject("Error, could not get base64 from buffer");
    };

    reader.readAsDataURL(blob);
  });
};

export const getThumbnailDimension = (
  origWidth: number,
  origHeight: number,
) => {
  const aspect = origWidth / origHeight;
  // Max dimension for thumb is 500 in either direction
  const max = 500;
  let newWidth;
  let newHeight;
  if (origWidth > origHeight) {
    newWidth = max;
    newHeight = Math.round(newWidth / aspect);
  } else {
    newHeight = max;
    newWidth = Math.round(newHeight * aspect);
  }
  return { newWidth, newHeight };
};
