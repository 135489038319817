import { useI18n } from "@wordpress/react-i18n";

import { EditLinkButton } from "@/components/Settings/EditLinkButton";

type MetadataRowProps = {
  icon: JSX.Element | null;
  title: string;
  data?: string | JSX.Element;
  edit?: () => void;
};

export const MetadataRow: React.FC<MetadataRowProps> = ({
  icon,
  title,
  data,
  edit,
}) => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        my: 3,
        fontSize: 1,
      }}
    >
      <div
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          mr: 2,
          "& svg path": { stroke: "textPrimary" },
        }}
      >
        {icon}
      </div>
      <div
        sx={{ display: "flex", flexDirection: "column", flexGrow: 1, gap: 1 }}
      >
        <div sx={{ color: "textPrimary" }}>{title}</div>
        <div sx={{ color: "textSecondary" }}>{data}</div>
      </div>
      <div>
        {edit !== undefined && (
          <EditLinkButton label={__("Edit")} onClick={edit} />
        )}
      </div>
    </div>
  );
};
