export const Sneaker: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00098 15.5H21.9988"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00098 11.502V16.002C2.00098 17.1065 2.89641 18.002 4.00098 18.002H20.001C21.1055 18.002 22.001 17.1065 22.001 16.002V15.5749C22.001 15.1981 21.9133 14.8265 21.7447 14.4895L21.5152 14.0304C21.1888 13.3776 20.5935 12.9001 19.8855 12.7231L17.4245 12.1078C17.1454 12.0381 16.8847 11.9089 16.6602 11.7293L12.2749 8.22108C12.0976 8.07923 11.8773 8.00195 11.6502 8.00195H10.619C10.2403 8.00195 9.894 8.21596 9.72461 8.55474L8.7774 10.4492C8.608 10.788 8.26174 11.002 7.88297 11.002H5.50098C5.18622 11.002 4.88983 10.8538 4.70098 10.602V10.602C4.31359 10.0854 3.63915 9.87476 3.02663 10.0789C2.41412 10.2831 2.00098 10.8563 2.00098 11.502Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
