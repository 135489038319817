import { Item, ItemGroup } from "@/components/ItemGroup";

export const AppVersion: React.FC = () => {
  return (
    <>
      <ItemGroup addMargin={true}>
        <Item>
          <p>Version: {process.env.APP_VERSION}</p>
        </Item>
      </ItemGroup>
    </>
  );
};
