import { liveQuery } from "dexie";

import { FetchWrapper } from "@/api/FetchWrapper";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { fromBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import {
  NotificationDBRow,
  APINotification,
  JournalDeletedNotification,
  ReactionNotification,
  JoinRequestNotification,
} from "@/data/db/migrations/notification";
import { JournalRepository } from "@/data/repositories/JournalRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { uuid } from "@/utils/uuid";

export class NotificationRepository {
  constructor(
    protected db: DODexie,
    private fetch: FetchWrapper,
    private syncStateRepository: SyncStateRepository,
    private userKeysStore: UserKeysStore,
    private journalRepository: JournalRepository,
  ) {}

  async sync() {
    const cursor = await this.syncStateRepository.getNotificationsCursor();
    const res = await this.fetch.fetchAPI(
      `/users/notifications/feed?cursor=${cursor}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (res.ok) {
      const data = await res.json();
      this.syncStateRepository.setNotificationsCursor(data.cursor);
      if (data.notifications.length > 0) {
        await this.insertNotificationsFromAPI(data.notifications);
      }
    }
  }

  private async insertNotificationsFromAPI(
    apiNotifications: APINotification[],
  ) {
    const notifications =
      await this.processNotificationsFromAPI(apiNotifications);
    await this.db.notifications.bulkPut(notifications);
  }

  private async processNotificationsFromAPI(
    apiNotifications: APINotification[],
  ) {
    const notifications: NotificationDBRow[] = [];
    for (const n of apiNotifications) {
      const notification = await this.maybeDecryptJournalName(n);

      // The shared_journal_user_access_request is the only notification currently that doesn't have a journal_id field
      // in their metadata. This maps the named_journal_id to the correct field to make it easier to process.
      let metadata = notification.metadata;
      if (notification.event === "shared_journal_user_access_request") {
        const startingMetadata =
          notification.metadata as JoinRequestNotification<"shared_journal_user_access_request">["metadata"];
        metadata = {
          ...startingMetadata,
          journal_id: startingMetadata.named_journal_id,
        };
      }

      notifications.push({
        id: notification.notification_id,
        recipient_id: notification.recipient_id,
        event: notification.event,
        metadata,
        created_date: Date.parse(notification.created_date),
        read_date: notification.read_date
          ? Date.parse(notification.read_date)
          : -1,
        seen_date: notification.seen_date
          ? Date.parse(notification.seen_date)
          : -1,
        deleted_date: notification.deleted_date
          ? Date.parse(notification.deleted_date)
          : -1,
      } as NotificationDBRow);
    }
    return notifications;
  }

  private async maybeDecryptJournalName(n: APINotification) {
    if (n.event === "shared_journal_deleted") {
      const metadata =
        n.metadata as JournalDeletedNotification<"shared_journal_deleted">["metadata"];
      const encrypted_journal_name = metadata.journal_name;
      const encrypted_vault_key = metadata.encrypted_vault_key;
      const mainPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
      const decryptedKey = mainPrivateKey
        ? await Symmetric.Key.decrypt(
            mainPrivateKey,
            fromBase64(encrypted_vault_key),
            "[shared_journal_deleted] error decrypting key",
          )
        : null;
      const journalName = decryptedKey
        ? await Symmetric.decryptD1(encrypted_journal_name, decryptedKey)
        : "";

      metadata.journal_name = journalName;
      n.metadata = metadata;
    }
    return n;
  }

  async getUnseenCount() {
    return await this.db.notifications.where("seen_date").equals(-1).count();
  }

  subscribeToUnseenCount(callback: (count: number) => void) {
    const stream = liveQuery(async () => {
      const unseen = await this.db.notifications
        .where("seen_date")
        .equals(-1)
        .count();
      return unseen;
    }).subscribe((unseen) => callback(unseen));
    return () => stream.unsubscribe();
  }

  async getNotifications(filtered = true) {
    const notifications = await this.db.notifications
      .where("deleted_date")
      .equals(-1)
      .reverse()
      .sortBy("created_date");

    if (!filtered) {
      return notifications.slice(0, 100);
    } else {
      const journals = await this.journalRepository.readAllInDB();
      const result = notifications
        .filter((n) =>
          journals.find((j) => {
            return (
              j.id === n.metadata.journal_id ||
              n.event === "shared_journal_deleted"
            );
          }),
        )
        .slice(0, 100);

      return result;
    }
  }

  subscribeToNotifications(
    callback: (notifications: NotificationDBRow[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const notifictions = await this.getNotifications();
      return notifictions;
    }).subscribe((notifictions) => callback(notifictions));
    return () => stream.unsubscribe();
  }

  private async getUnseenIds() {
    const ids = await this.db.notifications
      .where("seen_date")
      .equals(-1)
      .toArray();
    return ids.map((n) => n.id);
  }

  private async getUnreadIds() {
    const ids = await this.db.notifications
      .where("read_date")
      .equals(-1)
      .toArray();
    return ids.map((n) => n.id);
  }

  async markAllAsSeen() {
    const ids = await this.getUnseenIds();
    this.fetch.fetchAPI(`/users/notifications/seen`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notification_ids: ids }),
    });

    await this.db.notifications
      .where("seen_date")
      .equals(-1)
      .modify({ seen_date: Date.now() });
  }

  async markAsRead(ids: string[] | string) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    this.fetch.fetchAPI(`/users/notifications/read`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ notification_ids: ids }),
    });

    await this.db.notifications
      .where("id")
      .anyOf(ids)
      .modify({ read_date: Date.now() });
  }

  async markAllAsRead() {
    const ids = await this.getUnreadIds();
    this.markAsRead(ids);

    await this.db.notifications
      .where("read_date")
      .equals(-1)
      .modify({ read_date: Date.now() });
  }

  async test_makeTestEntryNotification(
    journal_id: string,
    entry_id: string,
  ): Promise<ReactionNotification<"shared_journal_entry_reaction">> {
    const x: NotificationDBRow = {
      id: uuid(),
      recipient_id: uuid(),
      event: "shared_journal_entry_reaction",
      metadata: {
        named_user_id: uuid(),
        journal_id,
        entry_id,
        named_user_reaction: "like",
        total_users_reacting: 1,
      },
      created_date: Date.now(),
      read_date: -1,
      seen_date: -1,
      deleted_date: -1,
    };
    await this.db.notifications.add(x);
    return x;
  }
}
