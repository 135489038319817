export const SnowShowers: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.233 6.689C16.653 6.689 16.108 6.805 15.631 7.036C15.273 4.753 13.227 3 10.773 3C8.097 3 5.915 5.084 5.847 7.665C4.244 7.863 3 9.186 3 10.824C3 12.577 4.466 14 6.273 14H17.233C19.313 14 21 12.362 21 10.344C21 8.326 19.313 6.689 17.233 6.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 17V20.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 17V18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 17V20.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16 17V18.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.00488 22.9852C7.00188 22.9852 6.99988 22.9872 6.99988 22.9902C6.99988 22.9932 7.00188 22.9952 7.00488 22.9952C7.00788 22.9952 7.00988 22.9932 7.00988 22.9902C7.00988 22.9872 7.00788 22.9852 7.00488 22.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0049 20.9852C10.0019 20.9852 9.99988 20.9872 9.99988 20.9902C9.99988 20.9932 10.0019 20.9952 10.0049 20.9952C10.0079 20.9952 10.0099 20.9932 10.0099 20.9902C10.0099 20.9872 10.0079 20.9852 10.0049 20.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0049 22.9852C13.0019 22.9852 12.9999 22.9872 12.9999 22.9902C12.9999 22.9932 13.0019 22.9952 13.0049 22.9952C13.0079 22.9952 13.0099 22.9932 13.0099 22.9902C13.0099 22.9872 13.0079 22.9852 13.0049 22.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0049 20.9852C16.0019 20.9852 15.9999 20.9872 15.9999 20.9902C15.9999 20.9932 16.0019 20.9952 16.0049 20.9952C16.0079 20.9952 16.0099 20.9932 16.0099 20.9902C16.0099 20.9872 16.0079 20.9852 16.0049 20.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
