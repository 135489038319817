export const Attribute: React.FC<React.ComponentPropsWithoutRef<"span">> = (
  props,
) => {
  return (
    <span
      sx={{
        fontSize: 0,
        color: "textPrimary",
      }}
      {...props}
    />
  );
};
