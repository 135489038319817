import { isEmail } from "@wordpress/url";
import { useState } from "react";

import { useAuth } from "@/data/hooks/AuthProvider";

type Creds = {
  email: string;
  password: string;
  isInvalid: boolean;
};

export const useLogin = () => {
  const [creds, setCreds] = useState<Creds>({
    email: "",
    password: "",
    isInvalid: false,
  });
  const credsValid =
    !!creds.email &&
    !!creds.password &&
    creds.password.length > 5 &&
    isEmail(creds.email);
  const auth = useAuth();

  const validateEmail = (creds: Creds) => {
    if (creds.email !== "" && !isEmail(creds.email) && !creds.isInvalid) {
      setCreds({ ...creds, isInvalid: true });
    } else if (
      (creds.email === "" || isEmail(creds.email)) &&
      creds.isInvalid
    ) {
      setCreds({ ...creds, isInvalid: false });
    }
  };

  return {
    auth,
    creds,
    setCreds,
    credsValid,
    validateEmail,
  };
};
