import { RTJNode } from "types/rtj-format";

import { isAnyTextNode } from "@/components/Editor/rtj2gb/rtj-type-checks";

export const HASHTAG_REGEX =
  /(?:^|\s|\(|#|﹟|＃)(?:#|﹟|＃)(\d*[\p{Letter}]+[\d\p{Letter}\p{Mark}_]*)/gmu;

export const HASHTAG_HIGHLIGHT_COLOR = "var(--theme-ui-colors-red)";

export const getTagsFromContent = (content: RTJNode[]) => {
  const entryText = content.reduce((acc, value) => {
    if (isAnyTextNode(value)) {
      acc += value.text;
    }
    return acc;
  }, "");

  const tags = [];
  let match;
  HASHTAG_REGEX.lastIndex = 0;

  while ((match = HASHTAG_REGEX.exec(entryText)) !== null) {
    tags.push(match[1]);
  }
  return tags;
};
