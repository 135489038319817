import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { DODexie } from "@/data/db/dexie_db";
import { CommentDBRow } from "@/data/db/migrations/comment";
import {
  CommentReactionDBRow,
  ReactionType,
} from "@/data/db/migrations/reaction";
import { uuid } from "@/utils/uuid";

export class CommentReactionRepository {
  constructor(
    protected db: DODexie,
    private fetch: FetchWrapper,
  ) {}

  async syncReactionsForComment(
    journalId: string,
    entryId: string,
    commentId: string,
  ) {
    const res = await this.fetch.fetchAPI(
      `/shares/${journalId}/entries/${entryId}/comments/${commentId}/reactions`,
    );
    if (res.status === 200) {
      const reactions = await res.json();
      await this.bulkAddForComment(reactions);
    } else {
      Sentry.captureException(
        new Error(`Unable to sync reactions for comment ${commentId}`),
      );
    }
  }

  async bulkAddForComment(reactions: CommentReactionDBRow[]) {
    return await this.db.comment_reactions.bulkPut(reactions);
  }

  async getReactionsForComment(
    journalId: string,
    entryId: string,
    commentId: string,
  ) {
    const reactions = await this.db.comment_reactions
      .where(["journal_id", "entry_id", "comment_id"])
      .equals([journalId, entryId, commentId])
      .toArray();
    return reactions;
  }

  async getReactionCountForJournalMember(journalId: string, userId: string) {
    const reactions = await this.db.comment_reactions
      .where(["journal_id", "user_id"])
      .equals([journalId, userId])
      .count();
    return reactions;
  }

  async addReaction(
    journalId: string,
    entryId: string,
    commentId: string,
    reaction: ReactionType,
  ) {
    const res = await this.fetch.fetchAPI(
      `/shares/${journalId}/entries/${entryId}/comments/${commentId}/reaction`,
      {
        method: "put",
        body: JSON.stringify({ reaction }),
      },
    );
    if (res.status === 200) {
      const body = await res.json();
      this.db.comment_reactions.put({
        id: body.id,
        journal_id: journalId,
        entry_id: entryId,
        comment_id: commentId,
        user_id: body.user_id,
        reaction: body.reaction,
        timestamp: body.timestamp,
      } as CommentReactionDBRow);
    } else {
      Sentry.captureException(
        new Error(`Unable to add/change reaction to comment ${commentId}`),
      );
    }
  }

  async removeReaction(
    journalId: string,
    entryId: string,
    commentId: string,
    userID: string,
  ) {
    const res = await this.fetch.fetchAPI(
      `/shares/${journalId}/entries/${entryId}/comments/${commentId}/reaction`,
      {
        method: "delete",
      },
    );
    if (res.status === 200) {
      return await this.db.comment_reactions
        .where({
          journal_id: journalId,
          entry_id: entryId,
          comment_id: commentId,
          user_id: userID,
        })
        .delete();
    }
  }

  subscribeToReactionsByComment(
    journalId: string,
    entryId: string,
    commentId: string,
    callback: (reactions: CommentReactionDBRow[]) => void,
  ) {
    const stream = liveQuery(async () => {
      const reactions = await this.db.comment_reactions
        .where(["journal_id", "entry_id", "comment_id"])
        .equals([journalId, entryId, commentId])
        .toArray();
      return reactions;
    }).subscribe((reactions) => callback(reactions));
    return () => stream.unsubscribe();
  }

  subscribeToReactionCountByComment(
    journalId: string,
    entryId: string,
    commentId: string,
    callback: (count: number) => void,
  ) {
    const stream = liveQuery(async () => {
      return this.getReactionCountForComment(journalId, entryId, commentId);
    }).subscribe((count) => callback(count));
    return () => stream.unsubscribe();
  }

  async removeLocalReactionsForJournals(journalIds: string[]) {
    await this.db.comment_reactions
      .where("journal_id")
      .anyOf(journalIds)
      .delete();
  }

  async getReactionCountForComment(
    journalId: string,
    entryId: string,
    commentId: string,
  ) {
    const reactions = await this.db.comment_reactions
      .where(["journal_id", "entry_id", "comment_id"])
      .equals([journalId, entryId, commentId])
      .count();
    return reactions;
  }

  async bulkUpdate(
    comments: CommentDBRow[],
    reactions: CommentReactionDBRow[],
  ) {
    await this.bulkDelete(comments);
    await this.db.comment_reactions.bulkPut(reactions);
  }

  async bulkDelete(comments: CommentDBRow[]) {
    return await this.db.comment_reactions
      .where(["journal_id", "entry_id", "comment_id"])
      .anyOf(comments.map((c) => [c.journal_id, c.entry_id, c.id]))
      .delete();
  }

  async test_makeTestCommentReaction(
    journalId: string,
    entryId: string,
    commentId: string,
  ): Promise<CommentReactionDBRow> {
    const x: CommentReactionDBRow = {
      id: uuid(),
      journal_id: journalId,
      entry_id: entryId,
      comment_id: commentId,
      user_id: uuid(),
      reaction: "like",
    };
    await this.db.comment_reactions.put(x);
    return x;
  }
}
