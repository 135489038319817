import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { browserSupportsWebAuthnAutofill } from "@simplewebauthn/browser";
import { startAuthentication as startPasskeyAuthAndWaitForUserInteraction } from "@simplewebauthn/browser";
import { PublicKeyCredentialRequestOptionsJSON } from "@simplewebauthn/types";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { UserLoginResponse, UserLoginService } from "@/data/UserLoginService";

type ServerInfo = {
  id: string;
  configuration: PublicKeyCredentialRequestOptionsJSON;
};

export class PasskeyLoginViewState {
  private serverInfo: ServerInfo | null = null;

  constructor(
    private fetchWrapper: FetchWrapper,
    private finishLogin: (response: UserLoginResponse) => any,
    private userLoginService: UserLoginService,
  ) {
    this.setupPasskeysByRequestingChallenge().catch((e) => {
      Sentry.captureException(e);
    });
  }

  async setupPasskeysByRequestingChallenge() {
    if (
      (!browserSupportsWebAuthn() && !browserSupportsWebAuthnAutofill()) ||
      // localhost isn't a valid referrer specified on the server
      // passkeys won't work in local development
      window.location.hostname === "localhost"
    ) {
      return;
    }
    const resp = await this.fetchWrapper.fetchAPI(
      "/v2/users/login/webauthn",
      undefined,
      {
        referrer: true,
      },
    );
    this.serverInfo = (await resp.json()) as ServerInfo;
  }

  async doPasskeyLogin() {
    if (!this.serverInfo) {
      Sentry.captureException(
        new Error("Tried passkey login without server info ready"),
      );
      return;
    }
    const cred = await startPasskeyAuthAndWaitForUserInteraction(
      this.serverInfo.configuration,
    );

    const resp = await this.userLoginService.logInWithPasskeys(
      this.serverInfo.id,
      cred,
    );
    this.finishLogin(resp);
  }
}
