export const SnowNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0049 17.9799C17.0019 17.9799 16.9999 17.9819 16.9999 17.9849C16.9999 17.9879 17.0019 17.9899 17.0049 17.9899C17.0079 17.9899 17.0099 17.9879 17.0099 17.9849C17.0099 17.9819 17.0079 17.9799 17.0049 17.9799"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0049 17.9852C11.0019 17.9852 10.9999 17.9872 10.9999 17.9902C10.9999 17.9932 11.0019 17.9952 11.0049 17.9952C11.0079 17.9952 11.0099 17.9932 11.0099 17.9902C11.0099 17.9872 11.0079 17.9852 11.0049 17.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00488 17.9799C5.00188 17.9799 4.99988 17.9819 4.99988 17.9849C4.99988 17.9879 5.00188 17.9899 5.00488 17.9899C5.00788 17.9899 5.00988 17.9879 5.00988 17.9849C5.00988 17.9819 5.00788 17.9799 5.00488 17.9799"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00488 20.9901C8.00188 20.9901 7.99988 20.9921 7.99988 20.9951C7.99988 20.9981 8.00188 21.0001 8.00488 21.0001C8.00788 21.0001 8.00988 20.9981 8.00988 20.9951C8.00988 20.9921 8.00788 20.9901 8.00488 20.9901"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 20.9852C13.997 20.9852 13.995 20.9872 13.995 20.9902C13.995 20.9932 13.997 20.9952 14 20.9952C14.003 20.9952 14.005 20.9932 14.005 20.9902C14.005 20.9872 14.003 20.9852 14 20.9852"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9711 8.77625C15.4931 8.77625 15.0431 8.87425 14.6491 9.07125C14.3541 7.12825 12.6661 5.63525 10.6401 5.63525C8.4311 5.63525 6.6311 7.40925 6.5741 9.60625C5.2521 9.77525 4.2251 10.9013 4.2251 12.2963C4.2241 13.7893 5.4331 15.0003 6.9251 15.0003H15.9701C17.6861 15.0003 19.0791 13.6063 19.0791 11.8883C19.0791 10.1703 17.6861 8.77625 15.9711 8.77625Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4663 5.72802C11.7003 3.64502 13.4493 2.02002 15.5973 2.02002C15.5973 4.32402 17.4683 6.19302 19.7753 6.19302C19.7753 7.58802 19.0823 8.81702 18.0293 9.57402"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
