import { useEffect } from "react";

// This helps allow us to scroll to a specified element id on the page if it exists.
// We wait for the element to be rendered and then scroll to it.
export const useScrollIntoView = (elementId?: string | null) => {
  useEffect(() => {
    let ret = () => {};

    if (elementId) {
      const observer = new MutationObserver(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView();
          observer.disconnect();
        }
        ret = () => observer.disconnect();
      });
      observer.observe(document, { subtree: true, childList: true });
    }

    return () => {
      ret();
    };
  }, [elementId]);
};
