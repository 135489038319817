import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { RetrieveEncryptionKeyFromApple } from "@/components/Settings/Account/MasterKeyModal/RetrieveEncryptionKeyFromApple";
import { RetrieveEncryptionKeyFromGoogle } from "@/components/Settings/Account/MasterKeyModal/RetrieveEncryptionKeyFromGoogle";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { EncryptionModalOpenMethods } from "@/layouts/ModalRoutes";
import { masterKeyViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
  isCreatingSharedJournal: boolean;
  openedFrom: EncryptionModalOpenMethods;
};

export const RetrieveEncryptionKeyModal: React.FC<Props> = observer(
  ({ handleClose, isCreatingSharedJournal, openedFrom }) => {
    const { __ } = useI18n();
    const { modalRouter } = viewStates;

    return (
      <D1Modal
        title={__("Retrieve Encryption Key")}
        onRequestClose={handleClose}
        sx={{ width: 550, p: 3 }}
      >
        <p
          sx={{
            fontSize: 2,
            fontWeight: "medium",
            lineHeight: "body",
            mb: 3,
            color: "textPrimary",
          }}
        >
          {openedFrom === "prefilled_url" &&
            __(
              "Your encryption key is necessary to save and access your templates. ",
            )}
          {__(
            "Retrieve your encryption key to unlock your end-to-end encrypted data on this device.",
          )}
        </p>
        <div sx={{ "& > div": { mb: 3 } }}>
          {masterKeyViewState.hasSavedKeyToGDrive && (
            <RetrieveEncryptionKeyFromGoogle handleClose={handleClose} />
          )}
          {masterKeyViewState.hasSavedKeyToCloudKit && (
            <span sx={{ "& svg path": { fill: "textPrimary" } }}>
              <RetrieveEncryptionKeyFromApple handleClose={handleClose} />
            </span>
          )}
        </div>
        <p
          sx={{
            fontSize: 1,
            lineHeight: "body",
            mb: 3,
            mt: 3,
            color: "textSecondary",
            maxWidth: "90%",
            mx: "auto",
          }}
        >
          {__(
            "It looks like your encryption key was backed up to these services. Sign in and we'll retrieve it for you.",
          )}
        </p>
        <Flex sx={{ justifyContent: "center", mt: 5 }}>
          <EditLinkButton
            label={__("Enter code manually")}
            title={__("Enter code manually")}
            disabled={masterKeyViewState.workingWithGDrive}
            onClick={() =>
              modalRouter.showKeyModal({
                openedFrom: "retrieve_encryption_key",
                isCreatingSharedJournal,
                denyRetrieve: true,
              })
            }
          />
        </Flex>
      </D1Modal>
    );
  },
);
