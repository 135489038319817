export const Tornado: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3638 3.879C21.8788 5.051 21.8788 6.95 18.3638 8.122C14.8488 9.294 9.15076 9.294 5.63576 8.122C2.12076 6.95 2.12076 5.051 5.63576 3.879C9.15076 2.707 14.8488 2.707 18.3638 3.879"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 11.5C17.902 12.399 15.155 13 12 13C8.845 13 6.098 12.399 4.5 11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 16C16.722 16.599 14.524 17 12 17C9.476 17 7.278 16.599 6 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20.5C15.148 20.8 13.683 21 12 21C10.317 21 8.852 20.8 8 20.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
