import { observer } from "mobx-react-lite";

import { styles } from "@/components/EntryPreview";
import { MarkdownPreview } from "@/components/EntryPreview/MarkdownPreview";
import { EntryModel } from "@/data/models/EntryModel";

type EntryPreviewProps = {
  entry: EntryModel;
  entryText: string;
};

export const EntryPreview: React.FC<EntryPreviewProps> = observer(
  ({ entryText }) => {
    return (
      <div sx={styles}>
        <MarkdownPreview
          entryText={entryText}
          invertedText={false}
          withHighlights
        />
      </div>
    );
  },
);

EntryPreview.displayName = "EntryPreview";
