export const useFilePicker = (accept: string | string[], multiple: boolean) => {
  return {
    openFilePicker: () => {
      return new Promise<File[]>((res, rej) => {
        try {
          openFileDialog(accept, multiple, (f: FileList | null) => {
            if (f) {
              res(Array.from(f));
            }
          });
        } catch (error) {
          rej(new Error("Could not open file dialog"));
        }
      });
    },
  };
};

// Creating a file dialog outside of React because it doesn't need to be
// part of rendered React component tree or controlled in any way. Its sole
// purpose is to initiate the file open dialog and return files back to the
// consuming component.
const openFileDialog = (
  accept: string | string[],
  multiple: boolean,
  callback: (files: FileList | null) => void,
) => {
  const input = document.createElement("input");

  const acceptString = Array.isArray(accept) ? accept.join(",") : accept;

  input.style.display = "none";
  input.type = "file";
  input.accept = acceptString;
  input.multiple = multiple;

  input.addEventListener("change", function () {
    callback(this.files);
    document.body.removeChild(input);
  });

  document.body.appendChild(input);

  input.dispatchEvent(new MouseEvent("click"));
};
