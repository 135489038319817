export const TropicalStorm: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.9999 20.6603C18.5767 19.7499 19.8118 18.475 20.6602 17.0034"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.4998 18.0623C18.8479 16.1293 19.995 11.8482 18.062 8.50017C16.129 5.15212 11.8479 4.00499 8.49983 5.93799C5.15179 7.87098 4.00466 12.1521 5.93766 15.5002"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.965 15.8638C13.0989 16.4355 15.2922 15.1692 15.864 13.0353C16.4358 10.9015 15.1694 8.70812 13.0356 8.13635C10.9017 7.56458 8.70837 8.83091 8.1366 10.9648"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
