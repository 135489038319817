import { useI18n } from "@wordpress/react-i18n";

import { getTextSize } from "@/components/Settings/Account/Avatar/utils";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { useCreateObjectUrl } from "@/hooks/useCreateObjectUrl";
import { getInitials } from "@/utils/strings";

type Props = {
  user?: JournalParticipantDBRow;
  size: AvatarSizes;
  className?: string;
  addBorder?: boolean;
};

// These values where taken from the figma file
// and may not all be used at this moment but will be
// used in the future
export const avatarSizes = [28, 32, 40, 48, 96] as const;
export type AvatarSizes = 0 | 1 | 2 | 3 | 4;

export const Avatar: React.FC<Props> = ({
  user,
  size,
  className,
  addBorder = false,
}) => {
  const diameter = avatarSizes[size] || avatarSizes[0];
  const imgSrc = useCreateObjectUrl(
    user?.avatarImage ? new Blob([user.avatarImage]) : null,
  );
  const dimension = diameter + "px";
  const textSize = getTextSize(diameter);

  const { __ } = useI18n();

  if (!imgSrc || !user) {
    const userInitials = user ? user.initials || getInitials(user.name) : "";
    return (
      <div
        aria-hidden="true"
        className={className}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "medium",
          flexShrink: 0,
          fontSize: textSize,
          width: dimension,
          height: dimension,
          color: "white",
          borderRadius: "50%",
          border: addBorder && "2px solid",
          borderColor: "surface_light1_dark3",
          backgroundColor: user?.profile_color || "surfaceActive",
        }}
      >
        {userInitials || (
          <img
            sx={{ borderRadius: "50%" }}
            src="/assets/user-placeholder.svg"
            alt={__("Unknown User")}
          />
        )}
      </div>
    );
  }

  return (
    <span
      sx={{
        display: "inline-block",
        width: dimension,
        height: dimension,
        flexShrink: 0,
        borderRadius: "50%",
        border: addBorder && "2px solid",
        borderColor: "surface_light1_dark3",
      }}
      className={className}
    >
      <img
        crossOrigin="anonymous"
        alt={__("Avatar")}
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
        }}
        src={imgSrc}
      ></img>
    </span>
  );
};
