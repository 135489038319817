export const FEATURE_FLAG_NAMES = [
  "readContentsAsMarkdown",
  "prefilledEntryURLs",
  "enablePasskeys",
  "showProfileSharingSetting",
  "showServerSideMove",
  "enableElectron",
  "showSaveTemplate",
  "showImportExport",
  "showEncryptedIndicator",
] as const;

export type ValidFeatureFlagKey = (typeof FEATURE_FLAG_NAMES)[number];
