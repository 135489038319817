import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";

export const decryptSymmetricKey = async (
  unlockingKey: CryptoKey,
  lockedKeyBytes: Uint8Array,
  debugContext: string,
) => {
  return await Symmetric.Key.fromBuffer(
    await Asymmetric.Private.decrypt(
      unlockingKey,
      lockedKeyBytes,
      debugContext,
    ),
  );
};
