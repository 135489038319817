import { Button, FlexItem, Icon } from "@wordpress/components";
import { TokenItem } from "@wordpress/components/build-types/form-token-field/types";
import { chevronLeft, chevronRightSmall } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { TagField } from "@/components/Form/TagField";
import { GroupHeading, Item, ItemButton } from "@/components/ItemGroup";
import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { JournalPicker } from "@/components/JournalPicker";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { View } from "@/components/Settings/Journals";
import { SelectItemWrapper } from "@/components/Settings/SelectItemWrapper";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { journalColorName } from "@/data/models/JournalFns";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

export const DailyPrompt: React.FC = observer(() => {
  const journals = primaryViewState.journals;
  const dailyPromptSettings = primaryViewState.dailyPromptSettings;
  const { isMobile } = useDevice();
  const [tags, setTags] = useState<string[]>(dailyPromptSettings.tags);
  const [selectedJournal, setSelectedJournal] = useState<JournalDBRow | null>();

  const { __ } = useI18n();

  const [view, setView] = useState<View>({
    primary: "list",
    secondary: null,
    journalID: null,
  });

  const journalColor = selectedJournal
    ? journalColorName(selectedJournal)
    : "textSecondary";

  const changeView = (view: View) => {
    setView(view);
  };

  useEffect(() => {
    if (tags.join("") === dailyPromptSettings.tags.join("")) {
      return;
    }
    handleSave("tags", tags);
  }, [tags.join("")]);

  useEffect(() => {
    analytics.tracks.recordEvent(EVENT.screenView, {
      screen: "settings_dailyPrompts",
    });
  }, []);

  useEffect(() => {
    if (dailyPromptSettings.default_journal) {
      const journal = journals.find(
        (journal) => journal.id === dailyPromptSettings.default_journal,
      );
      setSelectedJournal(journal);
    } else {
      setSelectedJournal(null);
    }
  }, [dailyPromptSettings.default_journal]);

  const handleSave = async (field: string, value: any) => {
    const success = await d1Classes.dailyPromptStore.saveSettings({
      ...dailyPromptSettings,
      [field]: value,
    });
    if (success) {
      viewStates.snackbar.newMessage(__("Daily Prompt settings saved"));
    } else {
      viewStates.snackbar.newMessage(
        __("There was an error saving your settings"),
      );
    }
  };

  return (
    <AnimatePresence initial={false}>
      {view.primary === "list" && (
        <SettingsPanel header={<span>{__("Daily Prompts")}</span>}>
          <JournalSettingsSlideContent name="list" direction="right">
            <ItemGroup addMargin={true}>
              <ToggleSetting
                label={__("Show Daily Prompt")}
                checked={dailyPromptSettings.show_daily_prompts}
                onChange={() => {
                  handleSave(
                    "show_daily_prompts",
                    !dailyPromptSettings.show_daily_prompts,
                  );
                }}
              />
              <Item>
                <FlexItem
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  <p>{__("Journal")}</p>
                </FlexItem>
                <Button
                  sx={{
                    overflow: "hidden",
                    pr: 0,
                    "&&": {
                      color: "textTertiary",
                    },
                    svg: {
                      flexShrink: 0,
                    },
                  }}
                  label={__("Edit Daily Prompt Journal")}
                  onClick={() => {
                    changeView({
                      primary: "edit",
                      secondary: null,
                      journalID: null,
                    });
                  }}
                >
                  <span
                    sx={{
                      textWrap: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      "&&&": {
                        color: journalColor,
                      },
                    }}
                  >
                    {selectedJournal?.name || __("(Optional)")}
                  </span>
                  <Icon icon={chevronRightSmall} />
                </Button>
              </Item>
              <SelectItemWrapper sx={{ py: 1 }}>
                <TagField
                  sx={{
                    "& .components-form-token-field": {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                      gap: 3,
                    },
                  }}
                  label={__("Tags")}
                  tags={tags}
                  width={isMobile ? "200px" : "300px"}
                  showSuggestions={true}
                  handleOnChange={(tokens: (string | TokenItem)[]) => {
                    const tags = tokens.map((tag) => {
                      if (typeof tag === "string") {
                        return tag;
                      } else {
                        return tag.value;
                      }
                    });
                    setTags(tags);
                  }}
                />
              </SelectItemWrapper>
            </ItemGroup>
          </JournalSettingsSlideContent>
        </SettingsPanel>
      )}
      {view.primary === "edit" && (
        <SettingsPanel
          header={
            <ItemButton
              onClick={() => {
                changeView({
                  primary: "list",
                  secondary: null,
                  journalID: null,
                });
              }}
              label={__("Back to Daily Prompts")}
            >
              <Icon icon={chevronLeft} size={24} />
              {__("Daily Prompts")}
            </ItemButton>
          }
        >
          <JournalSettingsSlideContent name="edit" direction="left">
            <FlexItem sx={{ mb: 2 }}>
              <GroupHeading>{__("Select the default Journal")}</GroupHeading>
            </FlexItem>
            <JournalPicker
              onChange={(value) => {
                handleSave("default_journal", value);
                changeView({
                  primary: "list",
                  secondary: null,
                  journalID: null,
                });
              }}
              journals={journals}
              onNoneOptionClick={() => {
                handleSave("default_journal", null);
                changeView({
                  primary: "list",
                  secondary: null,
                  journalID: null,
                });
              }}
            />
          </JournalSettingsSlideContent>
        </SettingsPanel>
      )}
    </AnimatePresence>
  );
});
