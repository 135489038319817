export const AppleLogo: React.FC = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7188 3.03179C10.0977 3.80483 9.05469 4.38462 8.22266 4.38462C8.12891 4.38462 8.03516 4.37254 7.97656 4.36046C7.96484 4.31214 7.94141 4.1672 7.94141 4.02225C7.94141 3.03179 8.42188 2.06548 8.94922 1.44946C9.61719 0.640178 10.7305 0.0362365 11.6562 0C11.6797 0.108709 11.6914 0.241577 11.6914 0.374444C11.6914 1.35283 11.2812 2.33121 10.7188 3.03179ZM6.64332 18.6399C6.22571 18.8248 5.82995 19 5.29297 19C4.14453 19 3.34766 17.9129 2.43359 16.5842C1.36719 15.014 0.5 12.5861 0.5 10.2912C0.5 6.59504 2.83203 4.63827 5.12891 4.63827C5.7993 4.63827 6.41185 4.89164 6.95653 5.11693C7.39253 5.29728 7.78504 5.45963 8.12891 5.45963C8.42717 5.45963 8.79887 5.30823 9.2321 5.13177C9.83719 4.88531 10.5623 4.58996 11.375 4.58996C11.8906 4.58996 13.7773 4.63827 15.0195 6.47425C15.0124 6.47996 14.9972 6.49029 14.9751 6.50535C14.6695 6.71332 13.0391 7.82269 13.0391 10.0979C13.0391 12.9123 15.418 13.9148 15.5 13.939C15.4979 13.9444 15.493 13.9599 15.4853 13.9845C15.4071 14.234 15.0343 15.4233 14.2344 16.6325C13.4492 17.7921 12.6172 18.9758 11.375 18.9758C10.7628 18.9758 10.3726 18.7978 9.96985 18.6139C9.53999 18.4177 9.09569 18.2149 8.35156 18.2149C7.60343 18.2149 7.10975 18.4334 6.64332 18.6399Z"
        fill="black"
      />
    </svg>
  );
};
