import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64, toBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { CryptoKeysV4, SyncableV4 } from "@/data/repositories/Syncables";
import { UserKeysRepository } from "@/data/repositories/UserKeysRepository";
import { UserRepository } from "@/data/repositories/UserRepository";

export class ContentKeysRepository {
  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
    private userRepository: UserRepository,
    private userKeysRepository: UserKeysRepository,
    private fetchWrapper: FetchWrapper,
  ) {}

  async putCryptoKeyOnServer(cryptoKey: CryptoKeysV4) {
    if (process.env.NODE_ENV === "test") {
      return;
    }
    const body = {
      client_id: "cryptoKeys",
      kind: "contentKeys",
      user_edit_date: new Date().toISOString(),
      blob: toBase64(Utf8.toUintArray(JSON.stringify(cryptoKey))),
    };

    const userId = (await this.userRepository.getActiveUser())?.id;
    if (!userId) {
      return;
    }
    const result = await this.fetchWrapper.fetchAPI(
      "/v4/sync/named/cryptoKeys",
      {
        method: "POST",
        body: JSON.stringify(body),
      },
    );
    if (result.ok) {
      const json = (await result.json()) as SyncableV4;
      const cryptoKeys = JSON.parse(
        Utf8.fromBufferSource(fromBase64(json.blob)),
      ) as CryptoKeysV4;
      const mostRecentContentKey = cryptoKeys.contentKeys.find(
        (key) => key.fingerprint === cryptoKeys.activeContentKeyFingerprint,
      );
      return mostRecentContentKey;
    } else {
      Sentry.captureException(
        new Error(
          `Failed to put cryptoKey - Status: ${
            result.status
          } - Text: ${await result.text()}`,
        ),
      );
      return;
    }
  }

  // There's a named syncable in the V4 API
  // that contains a user key (may or may not
  // be the same key from the users api).
  // It also has a bunch of content keys that the
  // client uses to encrypt templates and other
  // non-entry syncable content.
  async fetchV4APICryptoKeysFromServer(userId?: string) {
    // First see if there have been any changes to the content keys.
    // If there hasn't been no need to fetch the cryptoKeys object
    // We check this first because it has a cursor we can store
    const canDecryptOrEncrypt =
      await this.userKeysRepository.canDecryptOrEncrypt();
    if (!canDecryptOrEncrypt) {
      return;
    }
    const cursor = await this.syncStateRepository.getContentKeysCursor();
    const result = await this.fetchWrapper.fetchAPI(
      `/v4/sync/changes/contentKeys?cursor=${cursor}`,
    );
    if (result.ok) {
      const jsonBody: {
        changes: SyncableV4[];
        cursor: string;
      } = await result.json();

      this.syncStateRepository.setContentKeysCursor(jsonBody.cursor);
      if (jsonBody.changes.length === 0 && cursor !== "") {
        return;
      }
    }

    // If there have been changes, get the full cryptoKeys object and store it
    const res = await this.fetchWrapper.fetchAPI(
      `/v4/sync/named/cryptoKeys`,
      undefined,
      {
        expectedStatusCodes: [404],
      },
    );
    let cryptoKeys = null;
    if (res.ok) {
      const json = (await res.json()) as SyncableV4;
      cryptoKeys = JSON.parse(
        Utf8.fromBufferSource(fromBase64(json.blob)),
      ) as CryptoKeysV4;

      await this.saveContentKeys(cryptoKeys, userId);
    } else if (res.status === 404) {
      cryptoKeys = await d1Classes.userKeysStore.createContentKey();
    }
    return cryptoKeys;
  }

  async getAllContentKeys() {
    await this.fetchV4APICryptoKeysFromServer();
    const contentKeys = await this.db.content_keys.toArray();
    return contentKeys;
  }

  async getContentKeyByFingerprint(fingerprint: string) {
    const contentKey = await this.db.content_keys.get(fingerprint);
    return contentKey;
  }

  async saveContentKeys(cryptoKeys: CryptoKeysV4, userId?: string) {
    const contentKeys = cryptoKeys.contentKeys;
    const activeContentKeyFingerprint = cryptoKeys.activeContentKeyFingerprint;
    await d1Classes.keyValueStore.set(
      "activeContentKeyFingerprint",
      activeContentKeyFingerprint,
    );
    await this.db.content_keys.bulkPut(contentKeys);
    if (!userId) {
      userId = (await this.userRepository.getActiveUser())?.id;
    }
    if (userId) {
      await d1Classes.userKeysRepository.saveKey({
        local_id: "v4",
        private_key: { lockedD1: cryptoKeys.userKey.encryptedPrivateKey },
        public_key: Asymmetric.PEM.toBytes(cryptoKeys.userKey.publicKey),
        user_id: userId,
      });
    }
  }

  async getActiveContentKeyFingerprint() {
    return d1Classes.keyValueStore.get<string>("activeContentKeyFingerprint");
  }
}
