import { Flex, FlexItem } from "@wordpress/components";
import { AnimatePresence } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import { ControllableScrollContainer } from "@/components/ControllableScrollContainer";
import { D1Modal } from "@/components/D1Modal";
import { FilterPills } from "@/components/Search/FilterPills";
import { SearchKeyboardNavigation } from "@/components/Search/KeyboardNavigation";
import { SearchBar } from "@/components/Search/SearchBar";
import { SearchHistoryAndFavoritesNavigation } from "@/components/Search/SearchHistoryAndFavoritesNavigation";
import { SearchLoader } from "@/components/Search/SearchLoader";
import { SearchResults } from "@/components/Search/SearchResults";
import { SearchResultsStatus } from "@/components/Search/SearchResultsStatus";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { useElementRefSize } from "@/hooks/useElementRefSize";
import { useIsInSearch } from "@/hooks/useIsInSearch";
import { searchViewState } from "@/view_state/ViewStates";

type SearchParams = {
  onClose: () => void;
};

const HORIZONTAL_PADDING = { pl: [2, 5], pr: [2, 4] };

const Search: React.FC<SearchParams> = observer(({ onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [, setScrollContainerNode] = useState<null | HTMLElement>(null);
  const { isMobile } = useDevice();

  const {
    searchResults,
    searchString,
    indexStatus,
    isNoFilter,
    isFilterOnBlankSearch,
    isSearching,
    setContentHeight,
    window,
    scrollTop,
    setScrollTop,
  } = searchViewState;
  const { SearchContext } = useIsInSearch();

  // this is the section we want to scroll
  const contentClass = "components-modal__content";
  const { height: modalHeight, width: contentWidth } = useElementRefSize(
    modalRef,
    contentClass,
  );
  // This is made up of the height of the modal minus the modal header height with the search bar,
  // the search filters height, the search results info bar height, and some padding.
  const contentHeight = modalHeight - 61 - 64 - 46 - 24;

  useEffect(() => {
    setContentHeight(contentHeight);
  }, [modalHeight]);

  const { fullHeightInPx: totalHeight, visibleSlice } = window;

  const done = indexStatus === "DONE";
  const downloadingAll = indexStatus === "DOWNLOADING";
  const noSearchString = searchString === "";

  const isLoading = ["INITIAL", "INDEXING"].includes(indexStatus);
  const hasResultStatus = done || downloadingAll;
  const hasSearchResults = done && searchResults.length > 0;
  const filterOnBlankSearch = done && isFilterOnBlankSearch;
  const showSearchHistory = done && noSearchString && isNoFilter;
  const showSearchResultStatus =
    hasResultStatus && (!noSearchString || filterOnBlankSearch);
  const showSearchResults =
    !isSearching &&
    !showSearchHistory &&
    (hasSearchResults || filterOnBlankSearch) &&
    visibleSlice.length > 0;

  return (
    <D1Modal
      ref={modalRef}
      sx={{
        bg: "surface_light1_dark3",
        "&&": {
          marginTop: isMobile && 0,
          borderRadius: isMobile && 0,
          width: ["100%", "100%", "700px"],
          height: ["100%", "100%", "900px"],
          ".components-modal__content": { p: 0, mt: 0, overflow: "hidden" },
          ".components-modal__content button.components-button": {
            p: 0,
          },
        },
      }}
      onRequestClose={onClose}
      hideHeader
    >
      <SearchContext.Provider value={true}>
        <SearchBar />
        <FilterPills />
        <div
          sx={{
            pt: 1,
            height: `calc(${contentHeight}px - 100px)`, // ~ the height of the SearchBar+FilterPills
          }}
        >
          <Flex direction="column" align="top">
            <FlexItem>
              {isLoading && <SearchLoader />}
              {showSearchResultStatus && (
                <div sx={{ ...HORIZONTAL_PADDING }}>
                  <SearchResultsStatus />
                </div>
              )}
              <div>
                {showSearchResults && (
                  <ControllableScrollContainer
                    scrollTop={scrollTop}
                    onScrollTop={setScrollTop}
                    onNodeChange={setScrollContainerNode}
                    height={contentHeight}
                    sx={{
                      width: ["100%", "100%", `${contentWidth}px`],
                      overflowX: "hidden",
                      overflowY: "auto",
                      height: `${contentHeight}px`,
                    }}
                  >
                    <SearchKeyboardNavigation>
                      <div
                        id="search-list"
                        sx={{
                          width: "100%",
                          height: `${`${totalHeight}`}px`,
                          position: "relative",
                        }}
                      >
                        <AnimatePresence>
                          <SearchResults
                            closeHandler={onClose}
                            searchResults={hasSearchResults ? visibleSlice : []}
                          />
                        </AnimatePresence>
                      </div>
                    </SearchKeyboardNavigation>
                  </ControllableScrollContainer>
                )}
                {showSearchHistory && (
                  <SearchHistoryAndFavoritesNavigation
                    onClose={onClose}
                    contentHeight={contentHeight + 64}
                  />
                )}
              </div>
            </FlexItem>
          </Flex>
        </div>
      </SearchContext.Provider>
    </D1Modal>
  );
});

export default Search;
