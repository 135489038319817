import { Flex, Icon, __experimentalText as Text } from "@wordpress/components";
import { lock } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";

import { ItemGroup } from "@/components/ItemGroup";
import { JournalBadge } from "@/components/JournalSidebar/JournalBadge";
import { Shared } from "@/components/icons/Shared";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { featureFlagsViewState } from "@/view_state/ViewStates";

interface Props {
  onChange: (id: string) => void;
  onNoneOptionClick?: () => void;
  journals: JournalDBRow[];
}

export const JournalPicker: React.FC<Props> = ({
  journals,
  onChange,
  onNoneOptionClick,
}) => {
  const { __ } = useI18n();
  return (
    <>
      {onNoneOptionClick && (
        <ItemGroup
          sx={{
            overflow: "hidden",
            width: "100%",
          }}
        >
          <JournalItemView text={__("None")} handleClick={onNoneOptionClick} />
        </ItemGroup>
      )}
      <ItemGroup
        addMargin
        sx={{
          overflow: "hidden",
          width: "100%",
        }}
      >
        {journals.map((journal) => {
          return (
            <JournalItemView
              key={journal.id}
              text={journal.name}
              journal={journal}
              handleClick={() => onChange(journal.id)}
            />
          );
        })}
      </ItemGroup>
    </>
  );
};

type JournalItemViewProps = {
  text: string;
  journal?: JournalDBRow;
  handleClick: () => void;
};

export const JournalItemView: React.FC<JournalItemViewProps> = ({
  text,
  journal,
  handleClick,
}) => {
  const showEncryptedIndicator =
    !!journal?.e2e && featureFlagsViewState.showEncryptedIndicator;
  return (
    <Flex
      onClick={handleClick}
      role="button"
      sx={{
        "&&": {
          px: 2,
          height: "48px",
          borderBottom: "1px solid",
          borderBottomColor: "borderPrimary",
          ":hover": {
            bg: "surfaceHover",
          },
          "&:last-child, & > *": {
            borderBottom: "0",
          },
          "& svg": { flexShrink: 0 },
        },
      }}
    >
      {journal && (
        <JournalBadge
          color={journal.color}
          orderedByEditDate={journal.sort_method === "editDate"}
        />
      )}
      <Text
        sx={{
          textWrap: "nowrap",
          ml: 2,
          color: "textSecondary",
          flexGrow: 1,
          cursor: "pointer",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {text}
      </Text>
      {showEncryptedIndicator && (
        <Icon
          sx={{ flexShrink: 0, color: "textSecondary" }}
          icon={lock}
          size={12}
        />
      )}
      {journal?.is_shared && <Shared sx={{ width: "16px", height: "16px" }} />}
    </Flex>
  );
};
