import { TemplateDBRow } from "@/data/db/migrations/template";
import { TemplateRepository } from "@/data/repositories/TemplateRepository";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { UserStore } from "@/data/stores/UserStore";

export class TemplateStore {
  constructor(
    private userStore: UserStore,
    private templateRepository: TemplateRepository,
    private userKeysStore: UserKeysStore,
  ) {}

  async sync() {
    const user = await this.userStore.getActiveUser();
    if (!user) {
      return;
    }
    const userPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
    if (!userPrivateKey) {
      return;
    }

    await this.templateRepository.synchronize();
  }

  getTemplates() {
    return this.templateRepository.getTemplates();
  }

  subscribeToAll(callback: (journals: TemplateDBRow[]) => void) {
    return this.templateRepository.subscribeToAll(callback);
  }
}
