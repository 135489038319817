import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

import type { MSApiRouter } from "@ms/MSApiRouterType";

import { AuthToken, deserializeAuthToken } from "@/crypto/utils/authToken";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";

export const makeMSApiClient = (secureKVStore: SecureKeyValueStore) => {
  return createTRPCProxyClient<MSApiRouter>({
    links: [
      httpBatchLink({
        url: "/trpc",
        async headers() {
          const authToken = (
            await secureKVStore.get<AuthToken>(
              "dayone-auth-token",
              deserializeAuthToken,
            )
          )?.token;
          return {
            Authorization: authToken ? `Bearer ${authToken}` : undefined,
          };
        },
      }),
    ],
  });
};

export type MSApiClient = ReturnType<typeof makeMSApiClient>;
export type MSApiInputs = inferRouterInputs<MSApiRouter>;
export type MSApiOutputs = inferRouterOutputs<MSApiRouter>;
