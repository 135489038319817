import { useState, useEffect, RefObject } from "react";

type ElementSize = {
  width: number;
  height: number;
};

export const useElementSize = (
  element: string | RefObject<HTMLElement>,
  useBoundingClientRect = true,
) => {
  const sizeFromElem = (elem: Element) => {
    if (useBoundingClientRect) {
      const { width, height } = elem.getBoundingClientRect();
      return { width, height };
    }
    return { width: elem.clientWidth, height: elem.clientHeight };
  };

  const [size, setSize] = useState<ElementSize>(() => {
    const elem =
      typeof element === "string"
        ? document.getElementById(element) ||
          document.getElementsByClassName(element)[0]
        : element.current;
    const size = elem ? sizeFromElem(elem) : { width: 0, height: 0 };
    return size;
  });

  useEffect(() => {
    const el =
      typeof element === "string"
        ? document.getElementById(element) ||
          document.getElementsByClassName(element)[0]
        : element.current;
    if (!el) {
      return;
    }

    function handleResize(el: HTMLElement | Element) {
      setSize(sizeFromElem(el));
    }

    const resizeObserver = new ResizeObserver(() => {
      // We wrap it in requestAnimationFrame to avoid this error
      // ResizeObserver loop limit exceeded
      window.requestAnimationFrame(() => {
        handleResize(el);
      });
    });
    resizeObserver.observe(el);

    return () => resizeObserver.disconnect();
  }, []);
  return size;
};
