import { Flex, FlexItem, Icon } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { chevronRight } from "@wordpress/icons";
import { useState } from "react";

import { GroupHeading } from "@/components/ItemGroup/GroupHeading";
import { Item } from "@/components/ItemGroup/Item";
import { ItemButton } from "@/components/ItemGroup/ItemButton";
import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { EditJournalColor } from "@/components/JournalSettings/EditJournalColor";
import { EditJournalDescription } from "@/components/JournalSettings/EditJournalDescription";
import { EditJournalName } from "@/components/JournalSettings/EditJournalName";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { ViewProps } from "@/components/JournalSettings/JournalSettingsTypes";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { journalColorLabelFromColor } from "@/data/models/JournalFns";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

type StartProps = ViewProps & {
  primaryViewState: PrimaryViewState;
};

export const JournalSettingsStartView: React.FC<StartProps> = ({
  journal,
  setView,
  setJournal,
  primaryViewState,
  isEditing,
  direction,
}) => {
  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editColor, setEditColor] = useState(false);
  return (
    <div sx={{ overflow: "hidden" }}>
      <JournalSettingsSlideContent name="start" direction={direction}>
        {editName && (
          <EditJournalName
            currentJournal={journal}
            setJournal={setJournal}
            handleClose={() => {
              setEditName(false);
            }}
          />
        )}
        {editDescription && (
          <EditJournalDescription
            currentJournal={journal}
            setJournal={setJournal}
            handleClose={() => {
              setEditDescription(false);
            }}
          />
        )}
        {editColor && (
          <EditJournalColor
            currentJournal={journal}
            setJournal={setJournal}
            handleClose={() => {
              setEditColor(false);
            }}
            primaryViewState={primaryViewState}
          />
        )}
        <GroupHeading>{__("Journal name")}</GroupHeading>
        <ItemGroup>
          <Item>
            <FlexItem sx={{ wordWrap: "break-word" }}>
              <p>{journal.name}</p>
            </FlexItem>
            <FlexItem sx={{ flexShrink: 0 }}>
              <EditLinkButton
                label={__("Edit name")}
                onClick={() => {
                  setEditName(true);
                }}
              />
            </FlexItem>
          </Item>
        </ItemGroup>
        <GroupHeading>{__("Description")}</GroupHeading>
        <ItemGroup>
          <Item>
            <FlexItem sx={{ wordWrap: "break-word" }}>
              <p>{journal.description}</p>
            </FlexItem>
            <FlexItem sx={{ flexShrink: 0 }}>
              <EditLinkButton
                label={__("Edit description")}
                onClick={() => {
                  setEditDescription(true);
                }}
              />
            </FlexItem>
          </Item>
        </ItemGroup>
        <GroupHeading>{__("Color")}</GroupHeading>
        <ItemGroup>
          <Item>
            <FlexItem>
              <Flex>
                <span
                  sx={{
                    borderRadius: "rounded",
                    display: "block",
                    width: "24px",
                    height: "24px",
                    backgroundColor: journal.color,
                    border: "1px solid",
                    borderColor: "borderPrimary",
                  }}
                ></span>
                <p>{journalColorLabelFromColor(journal.color)}</p>
              </Flex>
            </FlexItem>
            <FlexItem>
              <EditLinkButton
                label={__("Edit email")}
                onClick={() => {
                  setEditColor(true);
                }}
              />
            </FlexItem>
          </Item>
        </ItemGroup>
        {!isEditing && (
          <ItemGroup addMargin={true}>
            <ItemButton
              onClick={() => {
                setView("advanced");
              }}
              sx={{ justifyContent: "space-between", minHeight: "48px" }}
            >
              <FlexItem>
                <p>{__("Advanced")}</p>
              </FlexItem>
              <FlexItem sx={{ mt: 1 }}>
                <Icon icon={chevronRight} size={24} />
              </FlexItem>
            </ItemButton>
          </ItemGroup>
        )}
      </JournalSettingsSlideContent>
    </div>
  );
};
