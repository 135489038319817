//@ts-ignore
import { link as WPLink } from "@wordpress/format-library/build-module/link";
import {
  applyFormat,
  isCollapsed,
  registerFormatType,
  RichTextValue,
} from "@wordpress/rich-text";
import { isURL } from "@wordpress/url";

import { formatIsRegistered } from "@/components/Editor/formats/utils";

const NAME = WPLink.name;

const autoLink = (value: RichTextValue) => {
  if (!isCollapsed(value)) {
    return value;
  }
  const words = value.text.split(/(\s+)/);
  let pos = 0;
  let newValue = value;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (isURL(word) && /^https?:/.test(word)) {
      const initialStart = value.start;
      const initialEnd = value.end;
      const start = value.text.indexOf(word, pos);
      const end = start + word.length;
      const characterAfterURL = value.text[end];
      if (
        characterAfterURL &&
        /\s/.test(characterAfterURL) &&
        !value.formats[start]?.find((type) => type === NAME)
      ) {
        newValue = applyFormat(
          { ...value, start, end },
          {
            type: NAME,
            // @ts-ignore
            attributes: { url: word },
          },
        );
        newValue.start = initialStart;
        newValue.end = initialEnd ? initialEnd + 1 : 0;
      }
    }
    pos += word.length;
  }
  return newValue;
};

const link = {
  ...WPLink,
  __unstableInputRule: (value: RichTextValue) => autoLink(value),
};

export const register = () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, link);
  }
};
