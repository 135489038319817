import { Button, CheckboxControl } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { Icon, lock } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { hexToRGBA } from "@/components/Editor/gb2rtj/formatting";
import {
  CenteredContent,
  JournalFilterOptions,
} from "@/components/Search/FilterPills";
import { journalHasNoColorSet } from "@/data/models/JournalFns";
import { iconButtonHoverBackground } from "@/styles/mixins";
type Props = {
  journalFilterOptions: JournalFilterOptions[];
  filterJournalIds: string[];
  toggleJournalFilter: (journalId: string) => void;
  hiddenJournalCount: number;
};

export const JournalOptions: React.FC<Props> = ({
  journalFilterOptions,
  filterJournalIds,
  toggleJournalFilter,
  hiddenJournalCount,
}) => {
  const { __, _n } = useI18n();
  if (journalFilterOptions.length === 0) {
    return (
      <CenteredContent>
        <div sx={{ px: 3, pt: 4, textAlign: "center", color: "textPrimary" }}>
          {__("No journals found.")}
        </div>
      </CenteredContent>
    );
  }
  return (
    <>
      {journalFilterOptions.map((journalFilterOption) => {
        const { journalId, name, entryCount, color, hasSynced } =
          journalFilterOption;

        const isSelected = filterJournalIds.includes(journalId || "");
        return (
          <div
            key={journalId}
            sx={{
              mx: 1.5,
              "& .components-button:not(:disabled, [aria-disabled=true]):active":
                {
                  color: "textPrimary",
                },
            }}
          >
            <Button
              sx={{
                "&:hover": {
                  bg: "surfaceHover",
                },
                py: 0,
                mx: 0,
                px: 0,
                display: "flex",
                color: "textPrimary",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 0,
                borderRadius: 3,
                gap: 4,
                opacity: hasSynced ? 1 : 0.5,
                height: "36px",
                "& .components-base-control__field": {
                  mb: 0,
                },
                ...iconButtonHoverBackground,
              }}
              key={journalId}
              onClick={() => {
                if (journalId) {
                  toggleJournalFilter(journalId);
                  analytics.tracks.recordEvent(
                    isSelected
                      ? EVENT.searchFilterRemoved
                      : EVENT.searchFilterApplied,
                    {
                      filter_type: "journal",
                    },
                  );
                  if (!hasSynced) {
                    d1Classes.entryStore
                      .addJournalToSyncJobs(journalId)
                      // If we just added a journal to the sync jobs, we should
                      // trigger sync right away.
                      .then(
                        (enabled) =>
                          enabled && d1MainThreadClasses.syncService.sync(),
                      );
                  }
                }
              }}
            >
              <div
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                <CheckboxControl
                  sx={{
                    pl: 1,
                    ml: 2,
                    pt: 0,
                    flexShrink: 0,
                    flexGrow: 0,
                    "&& .components-checkbox-control__input[type=checkbox]:focus":
                      {
                        boxShadow: "none",
                      },
                    "&& input.components-checkbox-control__input[type=checkbox]":
                      {
                        backgroundColor: hasSynced
                          ? hexToRGBA(color || "", 0.8)
                          : journalHasNoColorSet,
                        border: hasSynced
                          ? `1px solid ${color}`
                          : "1px solid transparent",
                      },
                  }}
                  checked={isSelected}
                  onChange={() => {}}
                />
                <div
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ml: -2,
                    flexGrow: 1,
                    textAlign: "left",
                  }}
                >
                  {name}
                </div>
              </div>
              <span sx={{ color: "textSecondary", pr: 3 }}>{entryCount}</span>
            </Button>
          </div>
        );
      })}
      {hiddenJournalCount > 0 && (
        <div sx={{ mx: 1, overflow: "hidden" }}>
          <div
            sx={{
              p: 0,
              ml: 3,
              mr: 0,
              display: "flex",
              color: "textPrimary",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              height: "40px",
              fontSize: 1,
              borderRadius: 3,
              gap: 4,
              svg: {
                fill: "textPrimary",
              },
            }}
          >
            <Icon icon={lock} size={16} />
            <div sx={{ flexGrow: 1, fontSize: 1 }}>
              {sprintf(
                _n(
                  "%d Locked journal",
                  "%d Locked journals",
                  hiddenJournalCount,
                ),
                hiddenJournalCount,
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
