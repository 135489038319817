import { ApproveSharedJournalRequest } from "./SharedJournalsRepository/ApproveSharedJournalRequest";
import { InviteLinkGenerator } from "./SharedJournalsRepository/InviteLinkGenerator";

import { d1Classes } from "@/D1Classes";
import { FetchWrapper } from "@/api/FetchWrapper";
import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import { JournalDBRow, PendingApproval } from "@/data/db/migrations/journal";
import { UserModel } from "@/data/models/UserModel";
import { declineSharedJournalRequest } from "@/data/repositories/SharedJournalsRepository/DeclineSharedJournalRequest";
import { JournalTransfer } from "@/data/repositories/SharedJournalsRepository/JournalTransfer";
import { OwnershipTransfer } from "@/data/repositories/V6API";
export class SharedJournalRepository {
  constructor(
    protected db: DODexie,
    private fetchWrapper: FetchWrapper,
  ) {}

  async approveRequest(
    {
      user_requesting_shared_journal_access: userRequestingAccess,
    }: PendingApproval,
    journal: JournalDBRow,
  ) {
    await new ApproveSharedJournalRequest().approveAndSendToServer(
      userRequestingAccess,
      journal,
    );
  }

  async declineRequest(props: { userId: string; journalId: string }) {
    await declineSharedJournalRequest(props, this.fetchWrapper);
  }

  getPendingTransfersForJournal(
    journal: JournalDBRow,
    user: UserModel,
  ): OwnershipTransfer[] {
    const { ownership_transfers } = journal;

    return ownership_transfers
      ? ownership_transfers.filter(
          (transfer) =>
            transfer.status === "pending" && transfer.new_owner_id === user.id,
        )
      : [];
  }

  async participantIsPremium(journal: JournalDBRow, participantId: string) {
    const journalTransfer = this.getJournalTransfer(journal);
    return await journalTransfer.participantIsPremium(participantId);
  }

  async acceptOwnershipTransfers(journals: JournalDBRow[], user: UserModel) {
    journals.forEach((journal) => {
      const pendingTransfers = this.getPendingTransfersForJournal(
        journal,
        user,
      );
      pendingTransfers.forEach(async (transfer: OwnershipTransfer) => {
        const journalTransfer = this.getJournalTransfer(journal);
        const accepted = await journalTransfer.accept(transfer);
        if (accepted) {
          await this.updateLocalJournalPendingTransfer(
            transfer,
            journal,
            "in_progress",
          );
        }
      });
    });
  }

  private async updateLocalJournalPendingTransfer(
    transfer: OwnershipTransfer,
    journal: JournalDBRow,
    status: OwnershipTransfer["status"],
  ) {
    transfer.status = status;
    const updatedTransfers = journal.ownership_transfers.map((t) =>
      t.previous_owner_id === transfer.previous_owner_id &&
      t.new_owner_id === transfer.new_owner_id &&
      t.status === "pending"
        ? transfer
        : t,
    );
    journal.ownership_transfers = updatedTransfers;
    await d1Classes.journalRepository.updateLocalJournal(journal);
  }

  async generateInviteLink(journal: JournalDBRow) {
    return await new InviteLinkGenerator(journal).generate();
  }

  /**
   * Invitation (Accept): https://mc.stg.dayone.app/docs/#tag/shared-journals/operation/request-access
   *
   * @param inviteCode the invitation code used to join the shared journal and encrypt information
   * @param inviteEncryptionKey the invitation code used to join the shared journal and encrypt information
   * @param ownerPublicKeyPEM the owner's public key
   * @returns { error: string } | { error: false } if the request was successful
   */
  async requestAccess({
    inviteCode,
    inviteEncryptionKey,
    ownerPublicKeyPEM,
  }: {
    inviteCode: string;
    inviteEncryptionKey: string;
    ownerPublicKeyPEM: string;
  }): Promise<{ error: string } | { error: false }> {
    // calculate the HMAC of the participant's public key
    // which is Participant's public key signed via HMAC
    // with the invitation key.
    const privateKey = await d1Classes.userKeysStore.getMainUserPrivateKey();
    if (!privateKey) return { error: "No private key found" };

    const publicKey = await d1Classes.userKeysStore.getMainUserPublicKey();
    if (!publicKey) return { error: "No public key found" };

    const participant_public_key_hmac = await Symmetric.generateHMAC({
      body: await Asymmetric.Public.toPEM(publicKey),
      secret: fromBase64(inviteEncryptionKey),
    });

    // Signature of the owner's public key produced by the participant. Signed with the participant's private key.
    const owner_public_key_signature_by_participant =
      await Asymmetric.Private.signArrayBuffer({
        userPrivateKey: privateKey,
        buffer: Utf8.toUintArray(ownerPublicKeyPEM),
      });

    const resp = await requestAccessAPI(
      inviteCode,
      participant_public_key_hmac,
      owner_public_key_signature_by_participant,
    );

    if (!resp.ok) {
      // return body of error response
      return { error: await resp.clone().text() };
    } else {
      return { error: false };
    }
  }

  async removeFromJournal(journalId: string, userId: string) {
    const result = await d1Classes.fetchWrapper.fetchAPI(
      `/shares/${journalId}/participants/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      },
    );

    if (result.status !== 200) {
      return false;
    }
    return true;
  }

  async cancelSharedJournalTransfer(journal: JournalDBRow) {
    const journalTransfer = this.getJournalTransfer(journal);
    const result = await journalTransfer.cancel(journal.id);
    return result;
  }

  async initiateSharedJournalTransfer(journal: JournalDBRow, userId: string) {
    const journalTransfer = this.getJournalTransfer(journal);
    const result = await journalTransfer.offer(userId);
    return result;
  }

  getJournalTransfer(journal: JournalDBRow) {
    return new JournalTransfer(journal, this.fetchWrapper);
  }
}

// Request access to join a Shared Journal using an invitation token
async function requestAccessAPI(
  inviteCode: string,
  participant_public_key_hmac: string,
  owner_public_key_signature_by_participant: string,
) {
  const body = {
    token: inviteCode,
    participant_public_key_hmac,
    owner_public_key_signature_by_participant,
  };

  // API docs on shared endpoint: https://dayone.app/docs/#tag/shared-journals/operation/create
  return await d1Classes.fetchWrapper.fetchAPI("/shares/request-access", {
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
}
