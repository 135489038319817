type FormProps = {
  children: React.ReactNode;
  onSubmit?: (e: React.FormEvent) => void;
  className?: string;
};

export const FormCard: React.FC<FormProps> = ({
  children,
  onSubmit,
  className,
}) => {
  return (
    <form className={className} aria-label="form" onSubmit={onSubmit}>
      <div
        sx={{
          m: "auto",
          width: "400px",
          maxWidth: "100%",
          pb: 1,
          pt: 2,
          pl: 4,
          pr: 4,
          "& p": {
            lineHeight: "body",
          },
        }}
      >
        {children}
      </div>
    </form>
  );
};
