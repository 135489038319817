import { FormTokenField } from "@wordpress/components";
import { TokenItem } from "@wordpress/components/build-types/form-token-field/types";

import { primaryViewState } from "@/view_state/ViewStates";

type TagFieldProps = {
  label: string;
  tags: string[];
  handleOnChange: (tokens: (string | TokenItem)[]) => void;
  width?: string;
  className?: string;
  showSuggestions?: boolean;
};

export const TagField: React.FC<TagFieldProps> = ({
  label,
  tags,
  handleOnChange,
  width,
  showSuggestions = false,
  className,
}) => {
  const suggestions = showSuggestions ? primaryViewState.getUniqueTags() : [];

  return (
    <div
      className={className}
      sx={{
        "&& input": {
          borderRadius: "md",
        },
        "&& label": {
          textTransform: "capitalize",
          fontSize: 13,
          color: "textPrimary",
          fontWeight: "unset",
        },
        "&& .components-form-token-field__input-container": {
          width,
          border: "1px solid",
          borderColor: "borderPrimary",
          borderRadius: "sm",
        },
        "&& .components-form-token-field__input-container > div": {
          width: width || "100%",
        },
        "&& .components-form-token-field__token": {
          backgroundColor: "surfaceActive",
          borderRadius: "sm",
        },
        "&& .components-form-token-field__token button": {
          borderRadius: "sm",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          backgroundColor: "transparent",
        },
        "&& .components-form-token-field__token-text": {
          px: 1,
          borderRadius: "sm",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          color: "textPrimary",
          backgroundColor: "transparent",
        },
        "&& .components-form-token-field__token:hover": {
          "button, .components-form-token-field__token-text": {
            backgroundColor: "surfaceHover",
            color: "textPrimary",
          },
        },
        "& .components-form-token-field__help": {
          display: "none",
        },
        "&& .components-form-token-field div.components-spacer": {
          display: "none",
        },
      }}
    >
      <FormTokenField
        value={tags}
        label={label}
        tokenizeOnSpace={true}
        tokenizeOnBlur={true}
        onChange={handleOnChange}
        suggestions={suggestions}
        sx={{
          backgroundColor: "transparent",
          borderColor: "borderPrimary",
          borderRadius: "sm",
          position: "relative",
          "&&& .components-form-token-field__input": {
            color: "textPrimary",
            py: 2,
            px: 2.5,
            m: 0,
          },
          "&&& .components-form-token-field__suggestions-list": {
            position: "absolute",
            border: "1px solid",
            borderColor: "borderPrimary",
            backgroundColor: "surface_light1_dark5",
          },
        }}
      />
    </div>
  );
};
