export const Checklist = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M227.77-221.925 100.001-349.693l41.769-41.768 85 85 170-170 41.768 42.768L227.77-221.925Zm0-304.614L100.001-654.307l41.769-41.768 85 85 170-170 41.768 42.768L227.77-526.539Zm292.615 228.845v-59.998h339.998v59.998H520.385Zm0-304.614v-59.998h339.998v59.998H520.385Z" />
    </svg>
  );
};
