import { Flex, FlexItem, FlexBlock } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { ENTER, ESCAPE } from "@/components/Editor/utils/keycodes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { Item } from "@/components/ItemGroup";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { primaryViewState } from "@/view_state/ViewStates";

export const Password: React.FC = observer(() => {
  const user = primaryViewState.user;
  const userStore = d1Classes.userStore;

  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const hasPassword = user!.credentials.includes("Password");
  const { __ } = useI18n();

  function handleCancelClose() {
    setError("");
    setShowEdit(false);
    setCurrentPassword(null);
    setNewPassword(null);
    setConfirmPassword(null);
    setIsDone(false);
  }

  function _save() {
    if (!user) {
      handleCancelClose();
      return;
    }

    if (
      hasPassword &&
      ((currentPassword && currentPassword.length < 5) || !currentPassword)
    ) {
      setError(__("You must supply your current password."));
      return;
    }

    if (!newPassword) {
      setError(__("The new password must be set"));
      return;
    }

    if (newPassword.length < 5) {
      setError(__("The new password must be at least 5 characters long"));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError(__("The new password and confirm password fields must match."));
      return;
    }

    const performUpdate = async () => {
      if (hasPassword) {
        try {
          const result = await userStore.changePassword(
            currentPassword!,
            newPassword,
          );
          if (result.success) {
            setIsDone(true);
            setError("");
          } else {
            setError(
              `${__("Could not change your password at this time.")} ${
                result.error
              }`,
            );
          }
        } catch (e) {
          setError(__("Could not change your password at this time."));
        }
      } else {
        try {
          const result = await userStore.addPassword(newPassword);
          if (result.success) {
            setIsDone(true);
            setError("");
          } else {
            setError(
              `${__("Could not add a password at this time.")} ${result.error}`,
            );
          }
        } catch (e) {
          setError(__("Could not add a password at this time."));
        }
      }

      setIsSaving(false);
    };

    setIsSaving(true);
    performUpdate();
  }

  const doSave = (e: React.MouseEvent) => {
    e.preventDefault();
    _save();
  };

  function handleKeyUp(e: React.KeyboardEvent) {
    switch (e.key) {
      case ENTER:
        _save();
        break;
      case ESCAPE:
        handleCancelClose();
        break;
      default:
        break;
    }
  }

  const buttonLabel = hasPassword ? __("Change Password") : __("Add Password");

  return (
    <>
      {showEdit && (
        <D1Modal
          title={buttonLabel}
          onRequestClose={handleCancelClose}
          sx={{ width: "420px" }}
        >
          <EditSection>
            <FlexItem sx={{ flex: 1 }}>
              {error && <ErrorMessage message={error} />}
              {isDone && (
                <Done
                  doClose={handleCancelClose}
                  message={__("Your password has been updated")}
                />
              )}
              {!isDone && (
                <>
                  {hasPassword && (
                    <Flex sx={{ mb: 2 }}>
                      <FlexBlock>
                        <Label htmlFor="password">
                          {__("Current Password")}
                        </Label>
                        <TextControl
                          name="current-password"
                          id="current-password"
                          type="password"
                          onKeyUp={handleKeyUp}
                          onChange={(value) => {
                            setCurrentPassword(value);
                          }}
                          value={currentPassword || ""}
                          autoFocus
                        />
                      </FlexBlock>
                    </Flex>
                  )}
                  <Flex sx={{ mb: 2 }}>
                    <FlexBlock>
                      <Label htmlFor="password">{__("New Password")}</Label>
                      <TextControl
                        name="new-password"
                        id="new-password"
                        type="password"
                        onKeyUp={handleKeyUp}
                        onChange={(value) => {
                          setNewPassword(value);
                        }}
                        value={newPassword || ""}
                        autoFocus={!hasPassword}
                      />
                    </FlexBlock>
                  </Flex>
                  <Flex sx={{ mb: 2 }}>
                    <FlexBlock>
                      <Label htmlFor="password">
                        {__("Confirm New Password")}
                      </Label>
                      <TextControl
                        name="confirm-password"
                        id="confirm-password"
                        type="password"
                        onKeyUp={handleKeyUp}
                        onChange={(value) => {
                          setConfirmPassword(value);
                        }}
                        value={confirmPassword || ""}
                      />
                    </FlexBlock>
                  </Flex>
                  <Flex>
                    <EditButtons
                      doSave={doSave}
                      attributeChanged={true}
                      isSaving={isSaving}
                      doCancel={handleCancelClose}
                    />
                  </Flex>
                </>
              )}
            </FlexItem>
          </EditSection>
        </D1Modal>
      )}

      <Item>
        <FlexItem>
          <EditLinkButton
            onClick={() => setShowEdit(!showEdit)}
            title={buttonLabel}
            label={buttonLabel}
          />
        </FlexItem>
      </Item>
    </>
  );
});

Password.displayName = "Password";
