import { makeAutoObservable, reaction } from "mobx";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import {
  ActiveModalRoutes,
  EncryptionModalOpenMethods,
  ModalRoute,
  settingsTab,
  settingsTabs,
  templatesTab,
  templatesTabs,
} from "@/layouts/ModalRoutes";
import { switchOnRecordName, switchOnUntrustedValue } from "@/utils/switching";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

// A class that represents the state of the main modal. It reacts to URL changes,
// and can be used to update the URL programmatically.
export class ModalRouterViewState {
  active: ActiveModalRoutes = {
    1: null,
    2: null,
    3: null,
  };

  constructor(private primaryViewState: PrimaryViewState) {
    // Set up this class for MobX reactivity
    makeAutoObservable(this, {}, { autoBind: true });
    // React to window.location changes
    window.addEventListener("popstate", () => {
      this.reactToURLChange();
    });
    // React to initial window.location
    this.reactToURLChange();

    // Whenever the route changes, update the URL
    reaction(
      () => [this.active, this.active[1], this.active[2], this.active[3]],
      () => this.updateURL(),
    );
  }

  setDialogueRoute(route: ModalRoute) {
    const current = this.active[route?.layer];
    if (JSON.stringify(current) === JSON.stringify(route)) {
      return;
    } else {
      if (route.layer === 1) {
        this.active[1] = route;
      } else if (route.layer === 2) {
        this.active[2] = route;
      } else if (route.layer === 3) {
        this.active[3] = route;
      }
    }
  }

  // Shortcuts
  openSettings(tab?: settingsTab, element?: string) {
    this.setDialogueRoute({
      name: "settings",
      layer: 1,
      tab: tab ?? "account",
      element,
    });
  }

  openTemplateManagement(tab?: templatesTab, template_id?: string) {
    this.setDialogueRoute({
      tab: tab ?? "gallery",
      template_id,
      name: "template_management",
      layer: 1,
    });
  }

  close() {
    this.closeLayer2();
    this.closeLayer3();
    this.active[1] = null;
  }

  closeLayer2() {
    if (this.active[2]?.name === "premium_upgrade") {
      analytics.tracks.recordEvent(EVENT.upgradeModalDismissed, {});
    }
    this.active[2] = null;
  }

  closeLayer3() {
    this.active[3] = null;
  }

  editJournal(journalId: string) {
    this.setDialogueRoute({
      name: "edit_journal",
      layer: 2,
      journal_id: journalId,
    });
  }

  newJournal() {
    this.setDialogueRoute({
      layer: 1,
      name: "new_journal",
    });
  }

  newSharedJournal() {
    this.setDialogueRoute({
      name: "new_shared_journal",
      layer: 1,
    });

    // If the user's key isn't entered yet, and they're navigating to the shared journal form,
    // also open the key modal.
    if (!this.primaryViewState.masterKeyString) {
      this.showKeyModal({
        isCreatingSharedJournal: true,
        openedFrom: "new_shared_journal",
      });
    }
  }

  showKeyModal({
    openedFrom,
    isCreatingSharedJournal,
    agreedToCreateKey = false,
    denyRetrieve = false,
  }: {
    openedFrom: EncryptionModalOpenMethods;
    isCreatingSharedJournal: boolean;
    agreedToCreateKey?: boolean;
    denyRetrieve?: boolean;
  }) {
    this.setDialogueRoute({
      layer: 2,
      name: "e2ee_masterkey",
      opened_from: openedFrom,
      is_creating_shared_journal: isCreatingSharedJournal,
      agreed_to_create_key: agreedToCreateKey,
      deny_retrieve: denyRetrieve,
    });
  }

  showPremiumUpgradeModal(method: string, type?: string) {
    analytics.tracks.recordEvent(EVENT.upgradeModalShown, {
      method,
    });
    this.setDialogueRoute({
      name: "premium_upgrade",
      layer: 2,
      type,
    });
  }

  openAddEntryToJournal(journalId?: string) {
    this.setDialogueRoute({
      name: "add_entry_to_journal",
      layer: 1,
      journal_id: journalId || "",
    });
  }

  openSupportForm() {
    this.setDialogueRoute({
      name: "support_form",
      layer: 1,
    });
  }

  openNotifications() {
    this.setDialogueRoute({
      name: "notifications",
      layer: 1,
    });
  }

  openSharedJournalSettings(journalId: string) {
    this.setDialogueRoute({
      name: "shared_journal_settings",
      layer: 1,
      journal_id: journalId,
    });
  }

  showSharedJournalMember(journalId: string, userId: string) {
    this.setDialogueRoute({
      name: "shared_journal_member",
      layer: 3,
      journal_id: journalId,
      user_id: userId,
    });
  }

  showPasskeysManager() {
    this.setDialogueRoute({
      name: "passkeys_manager",
      layer: 2,
    });
  }

  showSharedJournalInvite = (journalId: string) => {
    this.setDialogueRoute({
      name: "shared_journal_invite",
      layer: 3,
      journal_id: journalId,
    });
  };

  showPendingApprovals() {
    this.setDialogueRoute({
      name: "pending_approvals",
      layer: 3,
    });
  }

  showSharedJournalsInfo(openNewOnClose = true) {
    this.setDialogueRoute({
      name: "show_shared_journals_info",
      openNewOnClose,
      layer: 1,
    });
  }

  showSearch() {
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "timeline_searchButton",
    });
    this.setDialogueRoute({
      name: "show_search",
      layer: 1,
    });
  }

  showAdvancedSearch() {
    analytics.tracks.recordEvent(EVENT.buttonTap, {
      button_identifier: "search_advanced_filter",
    });
    this.setDialogueRoute({
      name: "show_advanced_search",
      layer: 1,
    });
  }

  showRecentPrompts() {
    this.setDialogueRoute({
      name: "show_recent_prompts",
      layer: 1,
    });
  }

  showSyncDetails() {
    this.setDialogueRoute({
      name: "sync_details",
      layer: 1,
    });
  }

  sharedJournalFeatures(user: string) {
    this.setDialogueRoute({
      layer: 3,
      name: "shared_journal_features",
      user,
    });
  }

  showEntryMetadata(journalId: string, entryId: string) {
    this.setDialogueRoute({
      layer: 1,
      name: "entry_metadata",
      journal_id: journalId,
      entry_id: entryId,
    });
  }

  showExport(journalId: string) {
    this.setDialogueRoute({
      layer: 3,
      name: "export",
      journal_id: journalId,
    });
  }

  /* Given the current state of the modal, update the URL. This is called whenever the modal state changes. */
  private updateURL() {
    const searchParams = new URLSearchParams(window.location.search);
    // Remove any parts from the URL starting with "m1_" or "m2_"
    Array.from(searchParams.keys()).forEach((key) => {
      if (
        key.startsWith("m1_") ||
        key.startsWith("m2_") ||
        key.startsWith("m3_")
      ) {
        searchParams.delete(key);
      }
    });
    // For the currently active route layers, add the appropriate query string parameters
    for (const [layer, route] of Object.entries(this.active)) {
      if (route === null) {
        continue;
      }
      switchOnRecordName<ModalRoute, void | null>(
        route,
        {
          new_journal: () => {
            searchParams.set(`m${layer}_name`, "new_journal");
          },
          new_shared_journal: () => {
            searchParams.set(`m${layer}_name`, "new_shared_journal");
          },
          edit_journal: (route) => {
            searchParams.set(`m${layer}_name`, "edit_journal");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
            this.primaryViewState.selectSettingsJournal(route.journal_id);
          },
          settings: (route) => {
            searchParams.set(`m${layer}_name`, "settings");
            searchParams.set(`m${layer}_tab`, route.tab);
            if (route.element) {
              searchParams.set(`m${layer}_element`, route.element);
            }
          },
          premium_upgrade: (route) => {
            searchParams.set(`m${layer}_name`, "premium_upgrade");
            searchParams.set(`m${layer}_type`, route.type || "");
          },

          support_form: () => {
            searchParams.set(`m${layer}_name`, "support_form");
          },
          notifications: () => {
            searchParams.set(`m${layer}_name`, "notifications");
          },
          add_entry_to_journal: (route) => {
            searchParams.set(`m${layer}_name`, "add_entry_to_journal");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
          },
          shared_journal_settings: (route) => {
            searchParams.set(`m${layer}_name`, "shared_journal_settings");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
          },
          shared_journal_member: (route) => {
            searchParams.set(`m${layer}_name`, "shared_journal_member");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
            searchParams.set(`m${layer}_user_id`, route.user_id);
          },
          passkeys_manager: () => {
            searchParams.set(`m${layer}_name`, "passkeys_manager");
          },

          e2ee_masterkey: (route) => {
            searchParams.set(`m${layer}_name`, "e2ee_masterkey");

            searchParams.set(`m${layer}_opened_from`, route.opened_from);

            if (route.is_creating_shared_journal) {
              searchParams.set(
                `m${layer}_is_creating_shared_journal`,
                route.is_creating_shared_journal ? "true" : "false",
              );
            }

            if (route.agreed_to_create_key) {
              searchParams.set(
                `m${layer}_agreed_to_create_key`,
                route.agreed_to_create_key ? "true" : "false",
              );
            }

            if (route.deny_retrieve) {
              searchParams.set(`m${layer}_deny_retrieve`, "true");
            }
          },

          shared_journal_invite: () => {
            searchParams.set(`m${layer}_name`, "shared_journal_invite");
          },
          template_management: (route) => {
            searchParams.set(`m${layer}_name`, "template_management");
            searchParams.set(`m${layer}_tab`, route.tab);
            searchParams.set(`m${layer}_template_id`, route.template_id || "");
          },
          pending_approvals: () => {
            searchParams.set(`m${layer}_name`, "pending_approvals");
          },
          show_shared_journals_info: () => {
            searchParams.set(`m${layer}_name`, "show_shared_journals_info");
          },
          show_search: () => {
            searchParams.set(`m${layer}_name`, "show_search");
          },
          show_advanced_search: () => {
            searchParams.set(`m${layer}_name`, "show_advanced_search");
          },
          show_recent_prompts: () => {
            searchParams.set(`m${layer}_name`, "show_recent_prompts");
          },
          sync_details: () => {
            searchParams.set(`m${layer}_name`, "sync_details");
          },
          shared_journal_features: (route) => {
            searchParams.set(`m${layer}_name`, "shared_journal_features");
            searchParams.set(`m${layer}_user`, route.user);
          },
          entry_metadata: (route) => {
            searchParams.set(`m${layer}_name`, "entry_metadata");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
            searchParams.set(`m${layer}_entry_id`, route.entry_id);
          },
          export: (route) => {
            searchParams.set(`m${layer}_name`, "export");
            searchParams.set(`m${layer}_journal_id`, route.journal_id);
          },
        },
        null,
      );
    }
    const url = new URL(window.location.href);
    url.search = searchParams.toString();
    // Special case here to handle the old "/settings" route. We want to support users visiting /settings
    // but if they do any navigation from there, we want to update their URL to the correct query-based URL.
    // So we just write the path back to the root.
    // We do the same for the "/shared-journals-intro" route as well.
    if (
      url.pathname === "/settings" ||
      url.pathname === "/shared-journals-intro"
    ) {
      url.pathname = "/";
    }
    const string = url.toString();
    if (string !== window.location.href) {
      window.history.pushState({}, "", url.toString());
    }
  }

  /* Update our in-memory route state to match the URL. This is called whenever the URL changes. */
  private reactToURLChange() {
    // For each layer, check if there is a route for that layer in the URL. If so, parse it and set it. If not, set it to null.
    for (const layer of [1, 2, 3] as const) {
      const searchParams = new URLSearchParams(window.location.search);
      const name = searchParams.get(`m${layer}_name`);
      const element = searchParams.get(`m${layer}_element`) ?? undefined;
      if (name === null) {
        this.active[layer] = null;
        continue;
      }

      switchOnUntrustedValue<ModalRoute["name"], void>(name, {
        edit_journal: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          if (journal_id === null) {
            return;
          }
          this.active[2] = {
            name: "edit_journal",
            layer: 2,
            journal_id,
          };
        },
        add_entry_to_journal: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          if (journal_id === null) {
            return;
          }
          this.active[1] = {
            name: "add_entry_to_journal",
            layer: 1,
            journal_id,
          };
        },
        new_journal: () => {
          this.active[1] = {
            name: "new_journal",
            layer: 1,
          };
        },
        new_shared_journal: () => {
          this.active[1] = {
            name: "new_shared_journal",
            layer: 1,
          };
        },
        settings: () => {
          let tab = searchParams.get(`m${layer}_tab`) ?? "account";
          if (!settingsTabs.includes(tab as settingsTab)) {
            tab = "account";
          }
          this.active[1] = {
            name: "settings",
            layer: 1,
            tab: tab as settingsTab,
            element,
          };
        },
        premium_upgrade: () => {
          const type = searchParams.get(`m${layer}_type`) ?? "";
          this.active[2] = {
            layer: 2,
            name: "premium_upgrade",
            type,
          };
        },

        support_form: () => {
          this.active[1] = {
            name: "support_form",
            layer: 1,
          };
        },
        notifications: () => {
          this.active[1] = {
            name: "notifications",
            layer: 1,
          };
        },
        shared_journal_settings: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          if (journal_id === null) {
            return;
          }
          this.active[1] = {
            name: "shared_journal_settings",
            layer: 1,
            journal_id: journal_id,
          };
        },
        shared_journal_member: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          const user_id = searchParams.get(`m${layer}_user_id`);
          if (journal_id === null || user_id === null) {
            return;
          }

          this.active[3] = {
            name: "shared_journal_member",
            layer: 3,
            journal_id: journal_id,
            user_id: user_id,
          };
        },
        passkeys_manager: () => {
          this.active[2] = {
            name: "passkeys_manager",
            layer: 2,
          };
        },

        e2ee_masterkey: () => {
          const opened_from = (searchParams.get(`m${layer}_opened_from`) ||
            "settings") as EncryptionModalOpenMethods;
          const is_creating_shared_journal = searchParams.get(
            `m${layer}_is_creating_shared_journal`,
          );

          const agreed_to_create_key = searchParams.get(
            `m${layer}_agreed_to_create_key`,
          );

          const deny_retrieve = searchParams.get(`m${layer}_deny_retrieve`);

          this.active[2] = {
            layer: 2,
            name: "e2ee_masterkey",
            opened_from: opened_from,
            is_creating_shared_journal: is_creating_shared_journal === "true",
            agreed_to_create_key: agreed_to_create_key === "true",
            deny_retrieve: deny_retrieve === "true",
          };
        },

        shared_journal_invite: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          this.active[3] = {
            journal_id: journal_id ?? "",
            name: "shared_journal_invite",
            layer: 3,
          };
        },

        template_management: () => {
          let tab = searchParams.get(`m${layer}_tab`) ?? "gallery";
          const template_id = searchParams.get(`m${layer}_template_id`);
          if (!templatesTabs.includes(tab as templatesTab)) {
            tab = "gallery";
          }

          this.active[1] = {
            name: "template_management",
            layer: 1,
            tab: tab as templatesTab,
            template_id: template_id ?? undefined,
          };
        },

        pending_approvals: () => {
          this.active[3] = {
            name: "pending_approvals",
            layer: 3,
          };
        },

        show_shared_journals_info: () => {
          const openNewOnClose =
            searchParams.get(`m${layer}_openNewOnClose`) ?? true;
          this.active[1] = {
            name: "show_shared_journals_info",
            openNewOnClose: Boolean(openNewOnClose),
            layer: 1,
          };
        },

        show_search: () => {
          this.active[1] = {
            name: "show_search",
            layer: 1,
          };
        },

        show_advanced_search: () => {
          this.active[1] = {
            name: "show_advanced_search",
            layer: 1,
          };
        },

        show_recent_prompts: () => {
          this.active[1] = {
            name: "show_recent_prompts",
            layer: 1,
          };
        },
        sync_details: () => {
          this.active[1] = {
            name: "sync_details",
            layer: 1,
          };
        },
        shared_journal_features: () => {
          const user = searchParams.get(`m${layer}_user`) ?? "participant";
          this.active[3] = {
            name: "shared_journal_features",
            layer: 3,
            user,
          };
        },
        entry_metadata: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          const entry_id = searchParams.get(`m${layer}_entry_id`);
          if (journal_id === null || entry_id === null) {
            return;
          }
          this.active[1] = {
            name: "entry_metadata",
            layer: 1,
            journal_id,
            entry_id,
          };
        },
        export: () => {
          const journal_id = searchParams.get(`m${layer}_journal_id`);
          if (journal_id === null) {
            return;
          }
          this.active[3] = {
            name: "export",
            layer: 3,
            journal_id,
          };
        },
        default: () => {
          this.active[layer] = null;
        },
      });
    }

    // Check to see if the path is "/settings". That used to be supported, so we'll keep supporting it.
    if (window.location.pathname === "/settings") {
      this.active[1] = {
        name: "settings",
        layer: 1,
        tab: "account",
      };
    }

    // Check to see if the path is "/shared-journals-intro".
    if (window.location.pathname === "/shared-journals-intro") {
      this.active[1] = {
        name: "show_shared_journals_info",
        openNewOnClose: false,
        layer: 1,
      };
    }
  }
}
