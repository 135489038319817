export const Activity: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.05 3.25C18.05 4.35457 17.1546 5.25 16.05 5.25C14.9454 5.25 14.05 4.35457 14.05 3.25C14.05 2.14543 14.9454 1.25 16.05 1.25C17.1546 1.25 18.05 2.14543 18.05 3.25Z"
        fill="currentColor"
      />
      <path
        d="M19.55 6.425C19.8 5.925 20.4 5.75 20.9 6C21.4 6.275 21.575 6.875 21.3 7.35L19.925 9.975C19.8 10.225 19.575 10.4 19.3 10.475C19.025 10.55 18.725 10.5 18.5 10.35L15.6 8.525L13.05 13.275L15.475 15.525C15.7 15.725 15.825 16 15.8 16.3L15.55 21.8C15.525 22.325 15.075 22.75 14.55 22.75H14.5C13.95 22.725 13.525 22.25 13.575 21.7L13.8 16.675L10.925 14L9.17499 17.225C8.99999 17.55 8.67499 17.75 8.29999 17.75H3.54999C2.99999 17.75 2.54999 17.3 2.54999 16.75C2.54999 16.2 2.99999 15.75 3.54999 15.75H7.69999L12.125 7.5H10.65L9.29999 9.975C9.12499 10.3 8.77499 10.5 8.42499 10.5C8.27499 10.5 8.09999 10.45 7.94999 10.375C7.47499 10.125 7.27499 9.5 7.54999 9.025L9.17499 6.025C9.34999 5.7 9.67499 5.5 10.05 5.5H14.3C14.5 5.5 14.675 5.55 14.825 5.65L18.675 8.075L19.55 6.425Z"
        fill="currentColor"
      />
    </svg>
  );
};
