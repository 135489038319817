import { Spinner } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { PasskeysManagerModalViewState } from "@/components/Settings/Account/PasskeysManagerModalViewState";
import { Button } from "@/components/Settings/Button";
import { useClassInstance } from "@/hooks/d1_hooks";
import { viewStates } from "@/view_state/ViewStates";

export const PasskeysManagerModal = observer(
  ({ onClose }: { onClose: () => void }) => {
    const { __ } = useI18n();
    const state = useClassInstance(
      () =>
        new PasskeysManagerModalViewState(
          d1Classes.fetchWrapper,
          viewStates.primary.user!,
        ),
    );

    let body = null;
    if (state.loading) {
      body = <Spinner />;
    } else if (state.failed) {
      body = <div>{__("Failed to load passkeys")}</div>;
    } else {
      const details =
        state.infos.length == 0 ? (
          <div>{__("No passkeys found")}</div>
        ) : (
          <table>
            <thead>
              <tr sx={{ textAlign: "left" }}>
                <th>{__("Name")}</th>
                <th>{__("Date Added")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {state.infos.map((info) => (
                <tr key={info.id}>
                  <td sx={{ pt: 2, pr: 4 }}>{info.name}</td>
                  <td sx={{ pt: 2, pr: 8 }}>
                    {new Date(info.created_at).toLocaleDateString()}
                  </td>
                  <td sx={{ pt: 2 }}>
                    <Button
                      variant="tertiary"
                      isDestructive={true}
                      onClick={() => state.deleteKey(info.id)}
                      disabled={!!state.deleteKeyState.sendingForId}
                    >
                      {state.deleteKeyState.sendingForId == info.id ? (
                        <Spinner />
                      ) : (
                        __("Delete")
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      body = <div>{details}</div>;
    }

    const newPasskeyForm = (
      <div>
        <form
          sx={{ display: "flex", alignItems: "center" }}
          onSubmit={(e) => {
            e.preventDefault();
            state.makeNewPasskey();
          }}
        >
          <Button variant="secondary" type="submit">
            {state.newKeyFormState.sending ? (
              <Spinner />
            ) : (
              __("Add New Passkey")
            )}
          </Button>
        </form>
        {state.newKeyFormState.failed ? (
          <p sx={{ mt: 2, color: "red" }}>
            {__("New passkey creation failed")}
          </p>
        ) : null}
        {state.deleteKeyState.failed ? (
          <p sx={{ mt: 2, color: "red" }}>{__("Failed to delete passkey")}</p>
        ) : null}
      </div>
    );

    return (
      <D1Modal onRequestClose={onClose}>
        <div
          sx={{
            pl: 4,
            pr: 4,
          }}
        >
          {body}
          <div sx={{ mt: 4 }}>{newPasskeyForm}</div>
        </div>
      </D1Modal>
    );
  },
);
