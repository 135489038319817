import { __, sprintf } from "@wordpress/i18n";

import { ValidURLs } from "@/components/Editor/blocks/preview/PreviewBlock";
import { dayOneBlue } from "@/styles/theme";

type UnsuportedProps = {
  urlType?: ValidURLs;
  url?: string;
  isSelected: boolean;
};
export const UnsupportedPreview: React.FC<UnsuportedProps> = ({
  url,
  urlType,
  isSelected,
}) => {
  const service = url ? url : urlType?.name;
  return (
    <div
      sx={{
        border: "3px solid",
        borderColor: isSelected ? dayOneBlue : "transparent",
        borderRadius: "3px",
        bg: "surface_light3_dark3",
        color: "textPrimary",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        my: 3,
        fontSize: 0,
        p: 5,
        "& p": {
          mb: 2,
          lineHeight: 2,
        },
      }}
    >
      <p>
        {sprintf(
          __(
            "Embeded previews for %s are not yet supported on the Day One web client.",
          ),
          service,
        )}
      </p>
      <p>{__("They will appear when viewed in your Day One Apple Apps.")}</p>
      {url ? (
        <p>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </p>
      ) : null}
    </div>
  );
};
