export const surfaceColors = {
  light1: "#FFFFFF",
  light2: "#F3F5F7",
  light3: "#E8EBEE",

  lightHover: "rgba(0, 54, 102, 0.05)",
  lightActive: "rgba(0, 54, 102, 0.1)",
  lightDrag: "rgba(0, 100, 102, 0.06)",

  dark1: "#111314",
  dark2: "#202324",
  dark3: "#252829",
  dark4: "#313435",
  dark5: "#36393A",

  darkToolbar: "#181A1B",

  darkHover: "rgba(255, 255, 255, 0.06)",
  darkActive: "rgba(255, 255, 255, 0.12)",
  darkDrag: "rgba(255, 255, 255, 0.08)",
};

export const surfaceShadows = {
  shadow1: "0px 1px 3px rgba(0, 0, 0, 0.1)",
  shadow2: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  shadow3: "0px 3px 30px rgba(25, 30, 35, 0.2);",
};
