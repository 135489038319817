export const takeWhile = <T>(array: T[], predicate?: (node: T) => boolean) => {
  if (array === null || array.length === 0) {
    return [];
  }
  if (typeof predicate !== "function") {
    return array;
  }
  let index = -1;
  while (++index < array.length && predicate(array[index])) {
    // Do nothing just increasing the index
  }

  return array.slice(0, index);
};
