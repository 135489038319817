import { startAuthentication } from "@simplewebauthn/browser";

import { Sentry } from "@/Sentry";
import { Analytics } from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Cache } from "@/cache";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { AuthToken, serializeAuthToken } from "@/crypto/utils/authToken";
import { LoginAPICaller } from "@/data/api_callers/LoginAPICaller";
import { DOLocalStorage } from "@/data/db/DOLocalStorage";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { UserRepository } from "@/data/repositories/UserRepository";
import { SyncService } from "@/data/services/SyncService";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { UserStore } from "@/data/stores/UserStore";
import { deleteIndexedDB } from "@/pages/logout/utils";
import {
  CHANNEL_EVENT_LOGOUT,
  getDefaultBroadcastChannel,
} from "@/utils/broadcastChannel";

export type UserLoginResponse =
  | "ok"
  | { error: true; status: number; source: "apple" | "google" | null };

export class UserLoginService {
  constructor(
    private mainServerAPIRepo: LoginAPICaller,
    private userStore: UserStore,
    private userRepository: UserRepository,
    private secureKeyValueStore: SecureKeyValueStore,
    private analytics: Analytics,
    private syncService: SyncService,
    private localStorageWrapper: DOLocalStorage,
    private sentry: Sentry,
    private db: DODexie,
    private userKeysStore: UserKeysStore,
  ) {}

  private async finishLogin(
    res: Response,
    tracksEventSource: string,
    masterKey?: { masterKeyString?: string; key?: CryptoKey },
  ): Promise<UserLoginResponse> {
    if (res.status === 200) {
      const { token, user, payload: encryptedKey } = await res.json();

      // First, set the key that's needed for the secure key value store to work:
      await this.secureKeyValueStore.setSeedForSymmetricKey(user.id);
      if (masterKey?.masterKeyString) {
        await this.userKeysStore.validateAndStoreMasterKey(
          masterKey.masterKeyString,
        );
      } else if (masterKey?.key && encryptedKey) {
        const masterKeyString = await Symmetric.decryptD1(
          encryptedKey,
          masterKey.key,
        );
        await this.userKeysStore.validateAndStoreMasterKey(masterKeyString);
      }

      // Then set the auth token in the secure key value store.
      // We want to do this before we set the active user in the user store
      // So that things which monitor changes to the user can assume the
      // auth token is available when the user is set.
      // We tried saving both in a Dexie transaction, but it failed becuase
      // The Dexie transactions don't like waiting for non-dexie async work to happen,
      // and the secure key value store does async work with the Web Crypto API.
      // So for now, we settle for setting the auth token first, then the user.
      const reauth = !!(await this.userRepository.getAuthToken());
      await this.secureKeyValueStore.set<AuthToken>(
        "dayone-auth-token",
        {
          token,
          timestamp: Date.now(),
        },
        serializeAuthToken,
      );

      await this.userStore.setActiveUser(user);

      if (reauth) {
        return "ok";
      }

      // so we initialize analytics with UserModel instead of User correctly
      // there is no ts error when passing a User because of TS types everything from fetch response as any
      // so things looks fine but actually no
      const activeUserModel = await this.userStore.getActiveUser();
      this.analytics.initialize(activeUserModel);

      // At this point, we haven't loaded the users settings from the server to
      // see if they want to opt out of event collection. We'll do that later, but
      // the login event is innocuous enough that we think it should be fine to send
      // before we know the user's preferences.
      this.analytics.tracks.recordEvent(EVENT.userSignIn, {
        source: tracksEventSource,
      });

      await this.userStore.finishLogin(user);
      this.syncService.sync();

      return "ok";
    } else {
      return {
        error: true,
        status: res.status,
        source:
          tracksEventSource === "apple"
            ? "apple"
            : tracksEventSource === "google"
              ? "google"
              : null,
      };
    }
  }

  async loginWithEmail(
    email: string,
    password: string,
    masterKeyString?: string,
  ) {
    const res = await this.mainServerAPIRepo.loginWithPasssword(
      email,
      password,
    );
    return this.finishLogin(res, "password", { masterKeyString });
  }

  async loginWithQR(nonce: string, secret: string, key: CryptoKey) {
    const res = await this.mainServerAPIRepo.loginWithQR(nonce, secret);

    if (res.status === 200) {
      return this.finishLogin(res, "qr-code", { key });
    } else {
      return res.status;
    }
  }

  async loginWithApple(token: string) {
    const res = await this.mainServerAPIRepo.loginWithApple(token);
    return this.finishLogin(res, "apple");
  }

  async loginWithGoogle(token: string) {
    const res = await this.mainServerAPIRepo.loginWithGoogle(token);
    return this.finishLogin(res, "google");
  }

  async logInWithExistingCloudkitUser(token: string) {
    const res =
      await this.mainServerAPIRepo.loginWithExistingCloudkitUser(token);
    return this.finishLogin(res, "cloudkit");
  }

  async logInWithPasskeys(
    id: string,
    cred: Awaited<ReturnType<typeof startAuthentication>>,
  ) {
    const res = await this.mainServerAPIRepo.loginWithPasskey(id, cred);
    return this.finishLogin(res, "webauthn");
  }

  async logOut() {
    // Broadcast logout to other tabs
    const channel = getDefaultBroadcastChannel();
    this.sentry.setUser(null);
    this.analytics.tracks.recordEvent(EVENT.userSignOut, {});
    try {
      // Clean up anything temporarily stored in localStorage
      this.localStorageWrapper.shutDownAndClear();

      // Clear cache and unregister service worker
      Cache.unregister();

      // Stop syncing
      this.syncService.stop();
      this.syncService.terminate();

      // Clear cookies and invalidate auth token
      await this.mainServerAPIRepo.logout();

      // Clear IndexedDB
      await this.db.delete();

      channel?.postMessage({
        type: CHANNEL_EVENT_LOGOUT,
      });
    } catch (e) {
      Sentry.captureException(e);
      this.analytics.tracks.recordEvent(EVENT.userSignOut, { force: true });
      deleteIndexedDB();
    }
  }
}
