import { createContext, useContext, useEffect, useState } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";

type Device = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const queries: Record<keyof Device, string> = {
  isMobile: "(max-width: 768px)",
  isTablet: "(min-width: 769px) and (max-width: 1023px)",
  isDesktop: "(min-width: 1024px)",
};

type LayoutContext = {
  device: Device;
  toggleJournals: () => void;
  showSidebar: boolean;
  isFocusMode: boolean;
  toggleFocusMode: () => void;
};

const LayoutContext = createContext<Partial<LayoutContext>>({});

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [queryMatch, setQueryMatch] = useState<Device>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });
  const [sidebarEnable, setSidebarEnable] = useState({
    enabledDesktop: true,
    enabledNotDesktop: false,
  });

  const [focusMode, setFocusMode] = useState(false);
  const toggleFocusMode = () => {
    if (!focusMode) {
      analytics.tracks.recordEvent(EVENT.focusModeOn, {});
    }
    setFocusMode((fm) => !fm);
  };

  const showSidebar = queryMatch.isDesktop
    ? sidebarEnable.enabledDesktop
    : sidebarEnable.enabledNotDesktop;

  const toggleJournals = () => {
    if (queryMatch.isDesktop) {
      setSidebarEnable({
        enabledDesktop: !sidebarEnable.enabledDesktop,
        enabledNotDesktop: sidebarEnable.enabledNotDesktop,
      });
    } else {
      setSidebarEnable({
        enabledDesktop: sidebarEnable.enabledDesktop,
        enabledNotDesktop: !sidebarEnable.enabledNotDesktop,
      });
    }
  };

  useEffect(() => {
    const mediaQueryLists: Partial<Record<keyof Device, MediaQueryList>> = {};
    const keys = Object.keys(queries) as (keyof Device)[];
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce<Device>(
        (acc, media) => {
          acc[media] = !!mediaQueryLists[media]?.matches;
          return acc;
        },
        {
          isMobile: false,
          isTablet: false,
          isDesktop: false,
        },
      );
      setQueryMatch(updatedMatches);
    };

    if (window?.matchMedia) {
      const matches: Partial<Device> = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media]?.matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches as Device);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          if (typeof mediaQueryLists[media]?.addEventListener === "function") {
            mediaQueryLists[media]?.addEventListener(
              "change",
              handleQueryListener,
            );
          } else if (
            typeof mediaQueryLists[media]?.addListener === "function"
          ) {
            mediaQueryLists[media]?.addListener(handleQueryListener);
          }
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            if (
              typeof mediaQueryLists[media]?.removeEventListener === "function"
            ) {
              mediaQueryLists[media]?.removeEventListener(
                "change",
                handleQueryListener,
              );
            } else if (
              typeof mediaQueryLists[media]?.removeListener === "function"
            ) {
              mediaQueryLists[media]?.removeListener(handleQueryListener);
            }
          }
        });
      }
    };
  }, []);

  const layoutState = {
    device: queryMatch,
    toggleJournals,
    showSidebar,
    isFocusMode: focusMode && !queryMatch.isMobile,
    toggleFocusMode,
  };

  return (
    <LayoutContext.Provider value={layoutState}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useDevice = () => {
  const { device } = useContext(LayoutContext);
  if (device === undefined) {
    throw new Error("useDevice must be used within a LayoutProvider");
  }
  return device;
};

export const useSidebar = () => {
  const { toggleJournals, showSidebar } = useContext(LayoutContext);
  if (toggleJournals === undefined || showSidebar === undefined) {
    throw new Error("useSidebar must be used within a LayoutProvider");
  }
  return { toggleJournals, showSidebar };
};

export const useFocusMode = () => {
  const { isFocusMode, toggleFocusMode } = useContext(LayoutContext);
  if (isFocusMode === undefined || toggleFocusMode === undefined) {
    throw new Error("useFocusMode must be used within a LayoutProvider");
  }
  return { isFocusMode, toggleFocusMode };
};
