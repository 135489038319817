import { BlockInstance } from "@wordpress/blocks";
import { useI18n } from "@wordpress/react-i18n";

import {
  HEADING_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  QUOTE_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { ContextToolbarButton } from "@/components/Editor/components/ContextToolbarButton";
import { FixedToolbarButton } from "@/components/Editor/components/FixedToolbarButton";
import { headingFive } from "@/components/Editor/icons/headingFive";
import { headingFour } from "@/components/Editor/icons/headingFour";
import { headingOne } from "@/components/Editor/icons/headingOne";
import { headingSix } from "@/components/Editor/icons/headingSix";
import { headingThree } from "@/components/Editor/icons/headingThree";
import { headingTwo } from "@/components/Editor/icons/headingTwo";
import { useListTransform } from "@/components/Editor/transforms/useListTransform";
import { useReplaceSelectedBlocks } from "@/components/Editor/utils/replace-selected-blocks";

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type HeadingTransformProps = {
  headingLevel: HeadingLevel;
  isContextMenu: boolean;
};

const icons = [
  headingOne,
  headingTwo,
  headingThree,
  headingFour,
  headingFive,
  headingSix,
];

export const allBlocksCanBeTransformed = (blocks: BlockInstance[]) => {
  return !blocks.some(
    (block) => ![PARAGRAPH_BLOCK_ID, HEADING_BLOCK_ID].includes(block.name),
  );
};

const allBlocksHaveCurrentHeading = (
  headingLevel: HeadingLevel,
  blocks: BlockInstance[],
) => {
  // This logic is reversed from `every()` because that way
  // it can stop as soon as it's invalidated
  return !blocks.some((block) => {
    return !(
      block.name === HEADING_BLOCK_ID &&
      block?.attributes?.level === headingLevel
    );
  });
};

export const getHeadingIconByLevel = (level: HeadingLevel) => icons[level - 1];

export const HeadingTransform: React.FC<HeadingTransformProps> = ({
  headingLevel,
  isContextMenu,
}) => {
  const { __ } = useI18n();
  const { replaceSelectedBlocks, blocks, swapBlocksFn } =
    useReplaceSelectedBlocks();
  const { parentBlock } = useListTransform();

  const Button = isContextMenu ? ContextToolbarButton : FixedToolbarButton;

  const isDisabled =
    !allBlocksCanBeTransformed(blocks) ||
    (parentBlock && parentBlock.name === QUOTE_BLOCK_ID);

  const HeadingIcon = getHeadingIconByLevel(headingLevel);

  return (
    <Button
      icon={<HeadingIcon />}
      disabled={isDisabled}
      sx={{
        "&&": {
          bg:
            blocks.length > 0 &&
            allBlocksHaveCurrentHeading(headingLevel, blocks)
              ? "surface_light3_dark3"
              : "transparent",
          "&:hover": {
            bg: "surfaceHover",
          },
          color: headingLevel === 3 ? "red" : undefined,
        },
      }}
      title={__(`Heading ${headingLevel}`)}
      onClick={() => {
        if (allBlocksCanBeTransformed(blocks)) {
          replaceSelectedBlocks(
            allBlocksHaveCurrentHeading(headingLevel, blocks)
              ? swapBlocksFn(PARAGRAPH_BLOCK_ID)
              : swapBlocksFn(HEADING_BLOCK_ID, { level: headingLevel }),
          );
        }
      }}
      isActive={false}
    />
  );
};
