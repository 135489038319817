import { useI18n } from "@wordpress/react-i18n";

import { ItemGroup } from "@/components/ItemGroup";
import { Avatar } from "@/components/Settings/Account/Avatar/Avatar";
import { DisplayName } from "@/components/Settings/Account/DisplayName";
import { primaryViewState } from "@/view_state/ViewStates";

export const EditProfile: React.FC = () => {
  const { __ } = useI18n();
  return (
    <div>
      <Avatar primaryViewState={primaryViewState} inMemberModal={true} />
      <ItemGroup addMargin>
        <DisplayName />
      </ItemGroup>
      <p sx={{ fontSize: 0, color: "textSecondary", p: 2, lineHeight: "body" }}>
        {__(
          "Your profile will be visible to other members of any shared journals you are a part of.",
        )}
      </p>
    </div>
  );
};
