import {
  ChecklistNode,
  TextNode,
  TextNodeAttributes,
} from "@/../types/rtj-format";
import { ZERO_WIDTH_NO_BREAK_SPACE } from "@/components/Editor/rtj2gb/text2paragraph";

export const UNICODE_LINE_SEPARATOR = "\u2028";

export const endsWithNewLine = (text: string) => {
  return text.endsWith("\n") || text.endsWith(UNICODE_LINE_SEPARATOR);
};

export const isNewLine = (text: string) => {
  return text === "\n" || text === UNICODE_LINE_SEPARATOR;
};

export const convertChecklistNodesToHTML = (nodes: ChecklistNode[]) => {
  let nodeHTML = "";
  let currentIndentLevel = 0;
  nodes.forEach((node) => {
    while (node.attributes.line.indentLevel > currentIndentLevel) {
      nodeHTML += "<ul>";
      currentIndentLevel++;
    }
    while (node.attributes.line.indentLevel < currentIndentLevel) {
      nodeHTML += "</ul>";
      currentIndentLevel--;
    }

    const checked = node.attributes.line.checked ? "checked" : "";
    nodeHTML += "<li class='task-list-item'>";
    nodeHTML += "<input type='checkbox' disabled " + checked + " />";
    nodeHTML += "<span> " + node.text + "</span>";
    nodeHTML += "</li>";
  });

  // close any open tags
  while (currentIndentLevel > 0) {
    nodeHTML += "</ul>";
    currentIndentLevel--;
  }
  return nodeHTML;
};

export const convertTextToMarkup = (node: TextNode) => {
  let taggedText = node.text;

  if (taggedText === "") {
    taggedText = ZERO_WIDTH_NO_BREAK_SPACE;
  }
  taggedText = taggedText.replace(/\n$/, "");

  const attributes = node?.attributes;

  if (attributes) {
    const appliedStyles = (
      Object.keys(attributes) as (keyof TextNodeAttributes)[]
    ).filter((key) => !!attributes[key]) as (keyof TextNodeAttributes)[];

    for (const appliedStyle of appliedStyles) {
      switch (appliedStyle) {
        case "inlineCode":
          taggedText = `<code>${taggedText}</code>`;
          break;
        case "highlightedColor":
          taggedText = `<mark style="background-color: #${node?.attributes?.highlightedColor};">${taggedText}</mark>`;
          break;
        case "bold":
          taggedText = `<strong>${taggedText}</strong>`;
          break;
        case "italic":
          taggedText = `<em>${taggedText}</em>`;
          break;
        case "strikethrough":
          taggedText = `<s>${taggedText}</s>`;
          break;
        case "linkURL":
          taggedText = `<a href="${node?.attributes?.linkURL}">${taggedText}</a>`;
          break;
        case "autolink":
          // If the node already has a linkURL, don't try to autolink it.
          if (node?.attributes?.linkURL) {
            break;
          }
          if (
            taggedText.startsWith("http") ||
            taggedText.startsWith("mailto:") ||
            taggedText.startsWith("dayone:")
          ) {
            taggedText = `<a href="${taggedText}">${taggedText}</a>`;
          } else {
            taggedText = `<a href="https://${taggedText}">${taggedText}</a>`;
          }

          break;
      }
    }
  }

  return taggedText;
};
