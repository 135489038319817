import { Button as WpButton } from "@wordpress/components";
import {
  ButtonProps,
  DeprecatedButtonProps,
} from "@wordpress/components/build-types/button/types";

export type { ButtonProps };

export const Button: React.FC<ButtonProps & DeprecatedButtonProps> = (
  props,
) => {
  return (
    <WpButton
      sx={{
        height: "29px",
        "&&": {
          color: "primary",
        },
      }}
      {...props}
    />
  );
};
