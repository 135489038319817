import { Notification } from "@/data/db/migrations/notification";
import { NotificationRepository } from "@/data/repositories/NotificationRepository";

export class NotificationStore {
  constructor(private notificationRepository: NotificationRepository) {}

  async sync() {
    await this.notificationRepository.sync();
  }

  async getUnseenCount() {
    return await this.notificationRepository.getUnseenCount();
  }

  subscribeToUnseenCount(callback: (count: number) => void) {
    return this.notificationRepository.subscribeToUnseenCount(callback);
  }

  getNotifications(filtered = true) {
    return this.notificationRepository.getNotifications(filtered);
  }

  subscribeToNotifications(callback: (notifications: Notification[]) => void) {
    return this.notificationRepository.subscribeToNotifications(callback);
  }

  markAllAsSeen() {
    return this.notificationRepository.markAllAsSeen();
  }

  markAsRead(ids: string[] | string) {
    return this.notificationRepository.markAsRead(ids);
  }

  markAllAsRead() {
    return this.notificationRepository.markAllAsRead();
  }
}
