import { useBlockProps } from "@wordpress/block-editor";
import { Disabled } from "@wordpress/components";

import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { useMoment } from "@/data/hooks/moments";
import { useCreateObjectUrl } from "@/hooks/useCreateObjectUrl";
import { dayOneBlue } from "@/styles/theme";

export type BlobPDFProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  isSelected: boolean;
};

// Display a syncable pdf by MD5
export const SyncablePDF: React.FC<BlobPDFProps> = ({
  clientId,
  journalId,
  entryId,
  isSelected,
}) => {
  const { blob, isLoading } = useMoment(journalId, entryId, clientId);
  const url = useCreateObjectUrl(blob);
  const _url = url === null ? undefined : url;

  const blockProps = useBlockProps({
    style: {
      border: "3px solid",
      borderColor: isSelected ? dayOneBlue : "transparent",
      borderRadius: "3px",
    },
  });

  if (isLoading) {
    return <LoadingMediaPlaceholder />;
  }

  return (
    <Disabled isDisabled={!isSelected}>
      <div {...blockProps}>
        <object
          data={_url}
          sx={{
            width: "100%",
            minHeight: "50vh",
          }}
        />
      </div>
    </Disabled>
  );
};
