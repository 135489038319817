import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";

import { ButtonItem, ItemGroup } from "@/components/ItemGroup";
import { modalRouterViewState } from "@/view_state/ViewStates";

export const SyncDetails: React.FC = observer(() => {
  return (
    <ItemGroup addMargin={true}>
      <ButtonItem
        sx={{ height: "40px" }}
        onClick={() => {
          modalRouterViewState.showSyncDetails();
        }}
      >
        <p>{__("View Sync Details")}</p>
      </ButtonItem>
    </ItemGroup>
  );
});
