type JobConfig = {
  interval: number;
};

type Job = (() => Promise<unknown>) | (() => void);

// A simple class to run a job on an interval with the ability to pause
export class AsyncJob {
  protected config: JobConfig;
  protected job: Job;
  protected jobRunning = false;
  protected jobStarted = false;
  protected timerId: ReturnType<typeof setTimeout> | null = null;

  constructor(fn: Job, config: JobConfig) {
    this.job = fn;
    this.config = config;
  }

  start() {
    if (!this.jobStarted) {
      this.jobStarted = true;
      const jobResult = this.job();

      if (jobResult) {
        jobResult.then(() => {
          this.jobRunning = true;
          this.run();
        });
      } else {
        this.jobRunning = true;
        this.run();
      }
    }
  }

  stop() {
    if (this.timerId) clearTimeout(this.timerId);
    this.jobRunning = false;
    this.jobStarted = false;
  }

  protected run() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.timerId = setTimeout(() => {
      if (this.jobRunning) {
        const jobResult = this.job();

        if (jobResult) {
          jobResult.then(() => this.run());
        } else {
          this.run();
        }
      } else {
        this.run();
      }
    }, this.config.interval);
  }

  restart() {
    this.stop();
    this.start();
  }

  get isRunning() {
    return this.jobRunning;
  }
}
