import { Utf8 } from "@/crypto/utf8";

/**
 * derive a secret key from a master key.
 */
export const deriveSymmetricKeyFromMaster = (
  salt: string,
  keyToken: CryptoKey,
) => {
  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: Utf8.toUintArray(salt),
      iterations: 100000,
      hash: {
        name: "SHA-256",
      },
    },
    keyToken,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["decrypt", "encrypt"],
  );
};
