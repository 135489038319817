export const Smoke: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0351 19.184C18.0351 17.68 16.9291 16.465 15.5051 16.283C15.4441 13.914 13.5051 12 11.1261 12C8.94513 12 7.12613 13.61 6.80813 15.706C6.38413 15.493 5.89913 15.387 5.38413 15.387C3.53613 15.387 2.03613 16.89 2.03613 18.744"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4351 6.5188C10.1933 5.53794 9.2569 4.89226 8.25414 5.01491C7.25139 5.13757 6.49826 5.9899 6.5 7.00012C5.67157 7.00012 5 7.6717 5 8.50012C5 9.32855 5.67157 10.0001 6.5 10.0001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2502 13.6998C19.769 13.6998 21.0002 12.4686 21.0002 10.9498C20.9977 9.5732 19.9746 8.41198 18.6093 8.23599C18.0922 6.7081 16.544 5.78012 14.9527 6.04424C13.3614 6.30836 12.196 7.68676 12.2002 9.29979"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
