import { useCallback, useEffect, useState } from "react";

import { Sentry } from "@/Sentry";
import { useExternalScript } from "@/hooks/useExternalScript";

export const GOOGLE_IDENTITY_SERVICES_URL =
  "https://accounts.google.com/gsi/client";

// Defaults to the client ID from getting auth key from Google Drive
const CLIENT_ID = process.env.VITE_GOOGLE_OAUTH_CLIENT_ID || "";

interface Props {
  client_id?: string;
}

export const useGoogleLogin = (props?: Props) => {
  const client_id = props?.client_id || CLIENT_ID;
  const gsiState = useExternalScript(GOOGLE_IDENTITY_SERVICES_URL);
  const [token, setToken] = useState("");
  const [googleLibraryLoaded, setGoogleLibraryLoaded] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const handleCredentialResponse = (
    response: google.accounts.id.CredentialResponse,
  ) => {
    setToken(JSON.stringify({ token: response.credential }));
  };

  const initGoogleLibrary = () => {
    window.google?.accounts.id.initialize({
      client_id,
      callback: handleCredentialResponse,
    });
    setGoogleLibraryLoaded(true);
  };

  const processOauthToken = useCallback(
    (
      scope: string,
      action: (token: google.accounts.oauth2.TokenResponse) => void,
      connectErrorMessage: string,
    ) => {
      if (!googleLibraryLoaded) {
        return;
      }

      window.google.accounts.oauth2
        .initTokenClient({
          client_id,
          scope: scope,
          callback: async (response: google.accounts.oauth2.TokenResponse) => {
            // await is needed here
            await action(response);
            setAuthorized(true);
          },
          error_callback: (
            response: google.accounts.oauth2.ClientConfigError,
          ) => {
            // Don't send error if user closes the pop up
            if (response.message !== "Popup window closed") {
              Sentry.captureException(
                new Error(`${connectErrorMessage} - ${response.message}`),
              );
            }
          },
        })
        .requestAccessToken();
    },
    [googleLibraryLoaded],
  );

  const processOauthCode = useCallback(
    (
      scope: string,
      action: (token: string) => void,
      connectErrorMessage: string,
    ) => {
      if (!googleLibraryLoaded) {
        return;
      }

      window.google.accounts.oauth2
        .initCodeClient({
          client_id,
          scope: scope,
          callback: async (response: google.accounts.oauth2.CodeResponse) => {
            // await is needed here
            await action(response.code);
            setAuthorized(true);
          },
          error_callback: (
            response: google.accounts.oauth2.ClientConfigError,
          ) => {
            Sentry.captureException(
              new Error(`${connectErrorMessage} - ${response.message}`),
            );
          },
        })
        .requestCode();
    },
    [googleLibraryLoaded],
  );

  window.onGoogleLibraryLoad = () => {
    initGoogleLibrary();
  };

  useEffect(() => {
    switch (gsiState) {
      case "ready":
        initGoogleLibrary();
        break;
      case "loaded": {
        const googleGSILibraryIsLoaded =
          window.google && window.google.accounts && window.google.accounts.id;
        if (googleGSILibraryIsLoaded) {
          setGoogleLibraryLoaded(true);
        }
        break;
      }
      default:
        break;
    }
  }, [gsiState]);

  return {
    googleLibraryLoaded,
    token,
    processOauthToken,
    processOauthCode,
    authorized,
  };
};
