import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import React from "react";

import { Button } from "@/components/Settings/Button";
import { SaveButton } from "@/components/Settings/SaveButton";

type EditButtonProps = {
  isSaving: boolean;
  disabled?: boolean;
  doSave: (e: React.MouseEvent) => void;
  doCancel: () => void;
  attributeChanged: boolean;
};

export const EditButtons: React.FC<EditButtonProps> = ({
  isSaving,
  disabled,
  doSave,
  doCancel,
  attributeChanged,
}) => {
  const { __ } = useI18n();
  return (
    <>
      <div sx={{ flex: 1 }} />
      <FlexItem>
        <Button
          sx={{
            boxShadow: "inset 0 0 0 1px",
            borderColor: "borderPrimary",
            "&:hover": {
              color: "inherit !important",
            },
          }}
          onClick={doCancel}
        >
          {__("Cancel")}
        </Button>
      </FlexItem>
      <FlexItem>
        <SaveButton
          doSave={doSave}
          disabled={disabled}
          attributeChanged={attributeChanged}
          isSaving={isSaving}
        />
      </FlexItem>
    </>
  );
};
