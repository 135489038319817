import { observer } from "mobx-react-lite";
import { RTJNode } from "types/rtj-format";

import { d1Classes } from "@/D1Classes";
import { isCheckListNode } from "@/components/Editor/rtj2gb/rtj-type-checks";
import { CheckListSummary } from "@/components/EntryPreview/CheckListItems";
import { EntryTags } from "@/components/EntryPreview/EntryTags";
import { Heart } from "@/components/icons/Heart";
import { Pin } from "@/components/icons/Pin";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { EntryModel } from "@/data/models/EntryModel";
import { journalColorName } from "@/data/models/JournalFns";
import { useSubscription } from "@/hooks/d1_hooks";
import { useIsInSearch } from "@/hooks/useIsInSearch";
import { getRelativeTime, parseTimeZone } from "@/utils/date-helper";
import { displayTemperature } from "@/utils/temperature-helper";
import {
  primaryViewState,
  userSettingsViewState,
} from "@/view_state/ViewStates";

type EntryFooterProps = {
  entry: EntryModel;
  entryJournal?: JournalDBRow;
  invertedText?: boolean;
  relativeTime?: boolean;
};

export const getCheckListItems = (richTextJSON: RTJNode[]) => {
  const checkListItems = richTextJSON.reduce(
    (acc, curr) => {
      if (isCheckListNode(curr) && curr.text.endsWith("\n")) {
        acc.total += 1;
        if (curr.attributes.line.checked) {
          acc.checked += 1;
        }
      }
      return acc;
    },
    { total: 0, checked: 0 },
  );
  return checkListItems;
};

export const EntryFooter: React.FC<EntryFooterProps> = observer(
  ({ entry, entryJournal, invertedText, relativeTime }) => {
    const { user, selectedJournal } = primaryViewState;

    const { temperatureUnit } = userSettingsViewState;
    const hasSelectedJournal = !!selectedJournal;
    let journal = selectedJournal;
    if (!hasSelectedJournal && entryJournal) {
      journal = entryJournal;
    }

    const tags = useSubscription<string[]>(
      (cb) =>
        d1Classes.tagRepository.subscribeToTagsByEntry(
          cb,
          entry.journalId,
          entry.id,
        ),
      [entry.id, entry.journalId],
    );

    const journalName = !hasSelectedJournal
      ? journal?.is_decrypted
        ? journal?.name
        : journal?.id
      : "";

    let timeField: string;
    if (
      (!entryJournal?.sort_method ||
        entryJournal?.sort_method === "entryDate" ||
        !hasSelectedJournal) &&
      !relativeTime
    ) {
      timeField = new Date(entry.date).toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        timeZone: parseTimeZone(entry.timeZone),
      });
    } else {
      const date = new Date(entry.editDate);
      timeField = getRelativeTime(date);
    }

    const showEntryDate =
      (entryJournal?.sort_method === "entryDate" || !hasSelectedJournal) &&
      !entry.isAllDay;
    const showEditDate =
      !!hasSelectedJournal && entryJournal?.sort_method === "editDate";
    const showTime = showEntryDate || showEditDate;

    const location = entry.location;
    const placeName = location?.placeName ? `${location.placeName}` : "";
    const is_starred = entry.isStarred && entry.creatorUserId === user?.id;
    const is_pinned = entry.isPinned;
    const weather = entry.weather;

    const checkListItems = getCheckListItems(entry.richTextJSON.contents);
    const hasCheckListItems = checkListItems.total > 0;
    const showTags = !!tags && tags.length > 0;

    const journalColor = journal ? journalColorName(journal) : null;
    const tagColor = invertedText ? "textSecondaryInverse" : journalColor;

    const temperature = weather?.tempCelsius
      ? `${displayTemperature(weather.tempCelsius, temperatureUnit)} ${
          weather.description || ""
        }`
      : "";
    const { isInSearch } = useIsInSearch();

    return (
      <div
        // @ts-ignore This attribute is provided by a polyfill https://github.com/WICG/inert
        inert={isInSearch ? undefined : "true"}
        className="entry-footer"
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
          color: invertedText ? "textSecondaryInverse" : "textSecondary",
          fontSize: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          pr: 0,
          "& span": {
            ":not(:first-of-type)::before": {
              content: "'·'",
              color: invertedText ? "textSecondaryInverse" : "textSecondary",
              px: 1,
            },
          },
        }}
      >
        {journalName && (
          <span
            sx={{
              color: invertedText ? "textPrimaryInverse" : journalColor,
              fontWeight: "medium",
            }}
          >
            {journalName}
          </span>
        )}
        {is_pinned && (
          <span
            sx={{
              display: "inline-flex",
            }}
          >
            <Pin />
          </span>
        )}
        {is_starred && (
          <span
            sx={{
              display: "inline-flex",
              "&& svg": {
                fill: invertedText ? "textPrimaryInverse" : "red",
              },
            }}
          >
            <Heart />
          </span>
        )}
        {showTime && <span>{timeField}</span>}
        {hasCheckListItems && (
          <CheckListSummary checkListItems={checkListItems} />
        )}
        {showTags && <EntryTags tags={tags} color={tagColor} />}
        {placeName && <span>{placeName}</span>}

        {temperature && <span>{temperature}</span>}
      </div>
    );
  },
);
