import { __experimentalZStack as ZStack } from "@wordpress/components";

import { Reaction } from "@/components/SharedJournals/Reaction";
import { PartialReaction } from "@/components/SharedJournals/Reactions";
import { ReactionType } from "@/data/db/migrations/reaction";

function getTopReactions(reactions: PartialReaction[], n = 2): ReactionType[] {
  // Count the occurrences of each reaction
  type ReactionCounts = { [key in ReactionType]: number };
  const reactionCounts = reactions.reduce<ReactionCounts>((acc, r) => {
    const reaction = r.reaction;
    if (!acc[reaction]) {
      acc[reaction] = 1;
    } else {
      acc[reaction]++;
    }
    return acc;
  }, {} as ReactionCounts);

  // Convert the reactionCounts object to an array of [reaction, count] pairs
  const reactionsArray: [string, number][] = Object.entries(reactionCounts);

  // Sort the reactions array by count in descending order
  reactionsArray.sort((a, b) => b[1] - a[1]);

  // Get the top two unique reactions
  const topTwoReactions = reactionsArray.slice(0, n).map((item) => item[0]);

  return topTwoReactions as ReactionType[];
}

type Props = {
  reactions: PartialReaction[];
  bgColor?: string;
  color?: string;
  invertedText?: boolean;
};
export const ReactionSummary: React.FC<Props> = ({
  reactions,
  bgColor = "surface_light1_dark1",
  color,
  invertedText = false,
}) => {
  if (reactions.length === 0) {
    return null;
  }

  const topTwoReactions = getTopReactions(reactions);

  return (
    <ZStack
      id="reaction-summary"
      offset={14}
      sx={{
        width: topTwoReactions.length === 1 ? "25px" : "45px",
        height: "18px",
      }}
      isLayered
      isReversed
    >
      {topTwoReactions.map((reaction) => {
        return (
          <span
            key={reaction}
            sx={{
              "& svg": {
                height: "16px",
                width: "16px",
                borderRadius: "0 50% 50% 0",
                backgroundColor: "transparent",
                boxShadow: (theme) =>
                  // The hover state is handled by the parent component EntryListItem
                  // selector: ":hover #reaction-summary svg"
                  `0 0 0 2px ${
                    theme?.colors?.[bgColor] || bgColor
                  }, 0 0 0 2px ${theme?.colors?.surface_light1_dark1}`,
              },
            }}
          >
            <Reaction reaction={reaction} color={color} />
          </span>
        );
      })}
      {reactions.length > 2 && (
        <span
          key="count"
          sx={{
            fontSize: 0,
            fontWeight: "medium",
            color: invertedText ? "textSecondaryInverted" : "textSecondary",
            pl: 2,
            height: "16px",
            width: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {reactions.length}
        </span>
      )}
    </ZStack>
  );
};
