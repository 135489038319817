import { __experimentalText as Text } from "@wordpress/components";

import { parseTimeZone } from "@/utils/date-helper";

type EntryDateBadgeProps = {
  date: number;
  timeZone: string;
  entryId: string;
  showDateBadge?: boolean;
};

export const EntryDateBadge: React.FC<EntryDateBadgeProps> = ({
  date,
  timeZone,
  entryId,
  showDateBadge = true,
}) => {
  let parsed = new Date(date);

  parsed = new Date(date);
  return (
    <div
      id={`entry-date-badge-${entryId}`}
      sx={{
        width: 7,
        height: 7,
        flex: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        alignSelf: "center",
        mr: 1,
        ml: 1,
        my: 0,
      }}
    >
      {showDateBadge && (
        <>
          <Text
            align="center"
            isBlock
            size={10}
            upperCase
            sx={{
              color: "inherit",
              lineHeight: "1em",
              marginBottom: 1,
              fontWeight: "heading",
            }}
          >
            {parsed.toLocaleDateString(undefined, {
              weekday: "short",
            })}
          </Text>
          <Text
            align="center"
            isBlock
            size="16"
            sx={{
              color: "inherit",
              lineHeight: "1em",
              fontWeight: "heading",
            }}
          >
            {parsed.toLocaleDateString(undefined, {
              day: "numeric",
              timeZone: parseTimeZone(timeZone),
            })}
          </Text>
        </>
      )}
    </div>
  );
};
