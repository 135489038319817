import { ThemeUICSSObject } from "@theme-ui/css";

const dividerStyle: ThemeUICSSObject = {
  backgroundColor: "borderPrimary",
  height: 3.5,
  width: "1px",
  marginRight: 2,
  flexShrink: 0,
};

type Props = {
  className?: string;
};

export const FormattingMenuDivider: React.FC<Props> = ({ className }) => {
  return <span sx={dividerStyle} className={className} />;
};
