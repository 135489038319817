import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { JournalDBRow } from "@/data/db/migrations/journal";
import {
  KeyGenerator,
  KeyStore,
} from "@/data/repositories/SharedJournalsRepository/KeyGenerator";
import {
  JournalParticipant,
  OwnershipTransfer,
} from "@/data/repositories/V6API";

interface ParticipantSubscription {
  participant_id: string;
  subscription: string;
}

export interface JournalTransferInfo {
  journal: JournalDBRow;
  transfer: OwnershipTransfer;
}
export class JournalTransfer extends KeyGenerator {
  journalIdsTransferredToMe: string[] = [];

  constructor(
    protected journal: JournalDBRow,
    protected fetchWrapper: FetchWrapper,
    protected userKeysStore: KeyStore = d1Classes.userKeysStore,
  ) {
    super(userKeysStore);
  }

  async cancel(journalId: string) {
    const response = await this.fetchWrapper.fetchAPI(
      `/shares/${journalId}/transfer`,
      {
        method: "DELETE",
      },
    );
    if (response.status !== 200) throw new Error("Failed to cancel transfer");
    return true;
  }

  async offer(userId: string) {
    const participant = this.findParticipant(userId);
    const { invitation } = participant;
    const {
      participant_public_key_signature_by_inviting_owner:
        new_owner_public_key_signature_by_previous_owner,
    } = invitation;
    const body = {
      user_id: userId,
      new_owner_public_key_signature_by_previous_owner,
    };
    const response = await this.fetchWrapper.fetchAPI(
      `/shares/${this.journal.id}/transfer`,
      this.getRequestInit(body),
    );
    if (response.status !== 200) throw new Error("Failed to initiate transfer");
    return true;
  }

  async accept(transfer: OwnershipTransfer) {
    const { previous_owner_public_key } = transfer;
    const previous_owner_public_key_signature_by_new_owner =
      await this.signPublicKey(previous_owner_public_key);
    const body = {
      previous_owner_public_key_signature_by_new_owner,
    };
    const response = await this.fetchWrapper.fetchAPI(
      `/shares/${this.journal.id}/transfer/confirm`,
      this.getRequestInit(body),
    );
    if (response.status !== 200) {
      Sentry.captureException(
        new Error(
          `Failed to accept transfer: ${response.statusText} - ${response.body}`,
        ),
      );
      return false;
    }
    return true;
  }

  private async getParticipantsSubscription(): Promise<
    ParticipantSubscription[]
  > {
    const response = await this.fetchWrapper.fetchAPI(
      `/shares/${this.journal.id}/participants/subscription`,
    );
    if (response.status !== 200) {
      Sentry.captureException(
        new Error(
          `Failed to get participants subscription for journal id ${this.journal.id}: ${response.statusText} - ${response.body}`,
        ),
      );
      return [];
    }

    const participantsSubscription = await response.json();
    return participantsSubscription;
  }

  async participantIsPremium(participantId: JournalParticipant["id"]) {
    const participantsSubscription = await this.getParticipantsSubscription();
    if (!participantsSubscription) return false;
    const participantSubscription = participantsSubscription.find(
      (participantSubscription) =>
        participantSubscription.participant_id === participantId,
    );
    if (!participantSubscription) return false;
    return participantSubscription.subscription === "premium";
  }

  getRequestInit(body: any): RequestInit {
    const result = {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-Type": "application/json" },
    };
    return result;
  }

  getPendingTransfers(userId: string) {
    return this.journal.ownership_transfers.filter(
      (transfer) =>
        transfer.status === "pending" && transfer.new_owner_id === userId,
    );
  }

  findParticipant(userId: string) {
    const participant = this.journal.participants.find(
      (participant) => participant.id === userId,
    );
    if (!participant) throw new Error("No participant found");
    return participant;
  }
}
