import { liveQuery } from "dexie";

import { SyncStateRepository } from "./SyncStateRepository";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Utf8 } from "@/crypto/utf8";
import { toBase64 } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";
import { TemplateDBRow } from "@/data/db/migrations/template";
import { Outbox } from "@/data/models/Outbox";
import { OutboxResult, TemplateSendable } from "@/data/models/OutboxTypes";
import { UserRepository } from "@/data/repositories/UserRepository";
import { DecryptionService } from "@/data/services/DecryptionService";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { uuid } from "@/utils/uuid";

type Template = {
  title: string;
  order: number;
  richText: string;
  tags?: string[];
  journalSyncID?: string;
  journalName?: string;
  id: string;
  client_id: string;
  galleryTemplateID?: string;
  defaultMedia?: string;
};

export class TemplateRepository {
  isSynchronizing: boolean;

  constructor(
    protected db: DODexie,
    private decryptionService: DecryptionService,
    private syncStateRepository: SyncStateRepository,
    private userKeysStore: UserKeysStore,
    private outbox: Outbox,
    private fetch: FetchWrapper,
    private userRepository: UserRepository,
  ) {
    this.isSynchronizing = false;
  }

  async synchronize(): Promise<Template[]> {
    const cursor = await this.syncStateRepository.getTemplateCursor();

    const res = await this.fetch.fetchAPI(
      `/v4/sync/changes/template?cursor=${cursor}`,
    );
    if (res.ok) {
      const jsonBody: {
        changes: {
          blob: string | undefined;
          deletion_requested: string | undefined;
          client_id: string;
          id: string;
        }[];
        cursor: string;
      } = await res.json();

      this.syncStateRepository.setTemplateCursor(jsonBody.cursor);

      const userPrivateKeys = await this.userKeysStore.getAllUserPrivateKeys();
      const decryptedTemplatePromises = await Promise.allSettled(
        jsonBody.changes
          // Syncables marked for deletion will have a null blob
          .filter((x) => x.blob)
          .map(async (template) => {
            const decrypted =
              (await this.decryptionService.decryptV4ContentBlob(
                userPrivateKeys,
                template.blob!, // We check that the blob exists in the filter above
              )) as Template;
            if (!decrypted) {
              Sentry.captureException(
                new Error(
                  `Could not decrypt template with client_id ${template.client_id}.`,
                ),
              );
            }
            decrypted.id = template.id;
            decrypted.client_id = template.client_id;

            await this.insertNew(decrypted);
            return decrypted;
          }),
      );

      const decryptedTemplates = decryptedTemplatePromises.reduce(
        (acc: Template[], p: PromiseSettledResult<Template>) => {
          if (p.status === "fulfilled") {
            acc.push(p.value);
          }
          return acc;
        },
        [],
      );

      const deletedTemplates = jsonBody.changes
        .filter((x) => x.deletion_requested)
        .map((x) => x.client_id);
      await this.removeDeleted(deletedTemplates);

      return decryptedTemplates;
    }
    return [];
  }

  async insertNew(template: Template) {
    const {
      id,
      title,
      order,
      richText,
      journalSyncID,
      journalName,
      tags,
      client_id,
      galleryTemplateID,
      defaultMedia,
    } = template;
    await this.db.templates.put({
      clientId: client_id,
      id,
      title,
      order,
      richText,
      journalSyncID: journalSyncID || "",
      journalName: journalName || "",
      tags: tags || [],
      galleryTemplateID: galleryTemplateID || undefined,
      defaultMedia: defaultMedia || undefined,
    });

    return this.getById(client_id);
  }

  async removeDeleted(ids: string[]) {
    // don't try to delete on first sync since we are not inserting deleted templates
    const hasTemplates = await this.db.templates.count();
    if (!hasTemplates) {
      return;
    }
    await this.db.templates.bulkDelete(ids);
  }

  async getById(templateId: string) {
    return this.db.templates.get(templateId);
  }

  async getTemplates() {
    const templates = await this.db.templates.toArray();
    return templates.sort((a, b) => {
      return b.order - a.order;
    });
  }

  subscribeToAll(callback: (journal: TemplateDBRow[]) => void) {
    const sub = liveQuery(() => {
      return this.getTemplates();
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  async createLocalTemplate(template: Template) {
    const id = `local:${uuid()}`;
    const local = {
      order: template.order,
      richText: template.richText,
      tags: template.tags || [],
      title: template.title,
      journalName: template.journalName || "",
      journalSyncID: template.journalSyncID || "",
      galleryTemplateID: template.galleryTemplateID,
      defaultMedia: template.defaultMedia,
      id,
      clientId: template.client_id,
    } as TemplateDBRow;
    await this.db.templates.put(local);
    const sendable = {
      id: template.client_id,
      type: "Template",
      action: "CREATE",
      templateId: template.client_id,
    } as TemplateSendable;
    this.outbox.add(sendable);
    return;
  }

  async updateLocalTemplate(id: string, template: Omit<Template, "id">) {
    await this.db.templates.update(id, template);
    const sendable = {
      id,
      templateId: id,
      type: "Template",
      action: "UPDATE",
    } as TemplateSendable;
    this.outbox.add(sendable);
  }

  private async encryptionChecks(outboxItem: TemplateSendable) {
    const template = await this.db.templates.get(outboxItem.templateId);
    if (!template) {
      throw new Error(
        `Template ${outboxItem.templateId} not found in local DB to edit`,
      );
    }
    const mostRecentContentKey =
      await this.userKeysStore.getMostRecentContentKey();
    if (!mostRecentContentKey) {
      throw new Error(`Can't encrypt template no recent content key`);
    }
    const user = await this.userRepository.getActiveUser();
    if (!user) {
      throw new Error(`Can't encrypt template no active user`);
    }
    return { user, template, mostRecentContentKey };
  }

  async updateTemplateFromLocal(
    outboxItem: TemplateSendable,
  ): Promise<OutboxResult> {
    const { user, template, mostRecentContentKey } =
      await this.encryptionChecks(outboxItem);

    const toEncrypt = {
      title: template.title,
      order: template.order,
      richText: template.richText,
      tags: template.tags,
      journalSyncID: template.journalSyncID,
      journalName: template.journalName,
      galleryTemplateID: template.galleryTemplateID,
      defaultMedia: template.defaultMedia,
    };

    const encrypted = await DOCrypto.Template.encrypt(
      Utf8.toUintArray(JSON.stringify(toEncrypt)),
      mostRecentContentKey.fingerprint,
      mostRecentContentKey.publicKey,
      1,
    );

    const res = await this.fetch.fetchAPI(`/v4/sync/${template.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: template.clientId,
        blob: toBase64(encrypted),
        kind: "template",
        owner_id: user.id,
        creator_id: user.id,
        user_edit_date: new Date().toISOString(),
      }),
    });
    if (res.ok) {
      await this.synchronize();
      return { result: "success" };
    } else {
      return {
        result: "failed",
        message: `Failed to update template ${template.id} on server. Status: ${res.status}`,
      };
    }
  }

  async pushTemplateToServerFromLocal(
    outboxItem: TemplateSendable,
  ): Promise<OutboxResult> {
    const { user, template, mostRecentContentKey } =
      await this.encryptionChecks(outboxItem);
    const toEncrypt = {
      title: template.title,
      order: template.order,
      richText: template.richText,
      tags: template.tags,
      journalSyncID: template.journalSyncID,
      journalName: template.journalName,
      galleryTemplateID: template.galleryTemplateID,
      defaultMedia: template.defaultMedia,
    };

    const encrypted = await DOCrypto.Template.encrypt(
      Utf8.toUintArray(JSON.stringify(toEncrypt)),
      mostRecentContentKey.fingerprint,
      mostRecentContentKey.publicKey,
      1,
    );

    const date = new Date().toISOString();

    const res = await this.fetch.postJson(`/v4/sync`, {
      client_id: template.clientId,
      blob: toBase64(encrypted),
      kind: "template",
      owner_id: user.id,
      creator_id: user.id,
      sync_date: date,
      user_edit_date: date,
    });
    if (res.ok) {
      await this.synchronize();
      return { result: "success" };
    } else {
      return {
        result: "failed",
        message: `Failed to push template id ${template.clientId} to server`,
      };
    }
  }

  async deleteTemplate(id: string) {
    await this.db.templates.delete(id);
    const sendable = {
      id,
      templateId: id,
      type: "Template",
      action: "DELETE",
    } as TemplateSendable;
    this.outbox.add(sendable);
  }

  async deleteTemplateFromServer(
    outboxItem: TemplateSendable,
  ): Promise<OutboxResult> {
    const res = await this.fetch.fetchAPI(
      `/v4/sync/named/${outboxItem.templateId}`,
      {
        method: "DELETE",
      },
    );
    if (!res.ok) {
      return {
        result: "failed",
        message: `Failed to delete template ${outboxItem.templateId} from server: Status ${res.status}`,
      };
    } else {
      return { result: "success" };
    }
  }
}
