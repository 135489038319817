import { DOCrypto } from "@/crypto/DOCrypto";
import { Utf8 } from "@/crypto/utf8";
import { EntryBlob, Key } from "@/data/repositories/Syncables";

export const encryptEntryBlob = async (
  entry: EntryBlob,
  journalKey: Key,
  format: 1 | 2,
) => {
  const needsEncrypted = Utf8.toUintArray(JSON.stringify(entry));
  return DOCrypto.JournalKey.encrypt(needsEncrypted, journalKey, format);
};
