import { Flex, FlexItem, FlexBlock } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { ENTER, ESCAPE } from "@/components/Editor/utils/keycodes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { Item } from "@/components/ItemGroup";
import { Done } from "@/components/Settings/Done";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { primaryViewState } from "@/view_state/ViewStates";

export const DisplayName: React.FC = observer(() => {
  const user = primaryViewState.user;
  const userStore = d1Classes.userStore;

  const [inputDisplayName, setInputDisplayName] = useState<string | null>(null);

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { __ } = useI18n();
  function handleClose() {
    setShowEdit(false);
    setIsDone(false);
  }

  function handleCancel() {
    setInputDisplayName(user!.display_name || null);
    handleClose();
  }

  function _save() {
    if (!inputDisplayName || !user) {
      handleCancel();
      return;
    }

    const performUpdate = async () => {
      try {
        await userStore.updateDisplayName(inputDisplayName);
        handleClose();
      } catch (e) {
        setError(__("Could not update display name at this time."));
      }

      setIsSaving(false);
    };
    setIsSaving(true);
    performUpdate();
  }

  const doSave = (e: React.MouseEvent) => {
    e.preventDefault();
    _save();
  };

  function handleKeyUp(e: React.KeyboardEvent) {
    switch (e.key) {
      case ENTER:
        _save();
        break;
      case ESCAPE:
        handleClose();
        break;
      default:
        break;
    }
  }

  function handleRequestClose() {
    setInputDisplayName(user!.display_name || null);
    handleClose();
  }

  return (
    <>
      {showEdit && (
        <D1Modal
          title={__("Edit Display Name")}
          onRequestClose={handleRequestClose}
          sx={{ width: "420px" }}
        >
          <EditSection>
            <FlexItem sx={{ flex: 1 }}>
              {!isDone && <Label htmlFor="name">{__("Name")}</Label>}
              {error && <ErrorMessage message={error} />}
              {isDone && (
                <Done
                  doClose={handleClose}
                  message={__("Your name has been updated")}
                />
              )}
              {!isDone && (
                <>
                  <Flex sx={{ mb: 2 }}>
                    <FlexBlock>
                      <TextControl
                        name="name"
                        id="name"
                        onKeyUp={handleKeyUp}
                        onChange={(value) => {
                          setInputDisplayName(value);
                        }}
                        value={
                          inputDisplayName === null
                            ? user?.display_name || ""
                            : inputDisplayName
                        }
                        autoFocus
                      />
                    </FlexBlock>
                  </Flex>
                  <Flex>
                    <EditButtons
                      doSave={doSave}
                      attributeChanged={inputDisplayName !== user?.display_name}
                      isSaving={isSaving}
                      doCancel={handleCancel}
                    />
                  </Flex>
                </>
              )}
            </FlexItem>
          </EditSection>
        </D1Modal>
      )}

      <Item>
        <FlexItem
          sx={{
            maxWidth: "calc(100% - 40px)",
            "& p": {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
        >
          <p>{user?.display_name || __("Name")}</p>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={__("Edit display name")}
            onClick={() => setShowEdit(!showEdit)}
          />
        </FlexItem>
      </Item>
    </>
  );
});

DisplayName.displayName = "DisplayName";
