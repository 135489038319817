import { FlexItem } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { Item } from "@/components/ItemGroup";
import { UserModel } from "@/data/models/UserModel";

type Props = {
  user: UserModel | null;
};

export const DayOneID: React.FC<Props> = observer(({ user }: Props) => {
  return (
    <Item>
      <FlexItem>
        <p>{user && user.id}</p>
      </FlexItem>
    </Item>
  );
});

DayOneID.displayName = "DayOneID";
