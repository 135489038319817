import { textColor } from "@wordpress/icons";
import {
  ExtendedFormatConfiguration,
  registerFormatType,
  toggleFormat,
} from "@wordpress/rich-text";

import { CONTEXT_FORMAT_TOOLBAR_SLOT_FILL } from "@/components/Editor/components/ContextFormattingToolbar";
import { ContextToolbarButton } from "@/components/Editor/components/ContextToolbarButton";
import {
  formatIsRegistered,
  matchFormattingTags,
} from "@/components/Editor/formats/utils";

const NAME = "dayone/highlight";
const title = "Highlight";
const tagName = "mark";
const TAGS = ["=="];

const highlightColor = "#ffc107cc";
const style = "background-color:" + highlightColor;

export const highlight: ExtendedFormatConfiguration = {
  title,
  tagName,
  className: null,
  __unstableInputRule: (value) => matchFormattingTags(value, TAGS, NAME, style),
  edit({ value, activeAttributes, isActive, onChange, onFocus }) {
    return (
      <CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
        <ContextToolbarButton
          icon={textColor}
          title={title}
          onClick={() => {
            if (!isActive) {
              activeAttributes.style = style;
            }

            onChange(
              toggleFormat(value, {
                type: NAME,
                // @ts-ignore
                attributes: activeAttributes,
              }),
            );
            onFocus();
          }}
          isActive={isActive}
        />
      </CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Fill>
    );
  },
  inactive() {
    return <ContextToolbarButton icon={textColor} title={title} disabled />;
  },
};

export const register = () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, highlight);
  }
};
