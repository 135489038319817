export const Book = () => {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.37109 12.5508C1.18164 12.5508 0.572266 11.9473 0.572266 10.7402V1.83398C0.572266 0.609375 1.17578 -0.00585938 2.38867 -0.00585938H8.61133C9.82422 -0.00585938 10.4277 0.615234 10.4277 1.83398V8.55469C10.4277 9.14062 10.1582 9.42773 9.93555 9.65039C9.20898 10.3828 9.36133 11.3379 10.0996 11.6309C10.2871 11.6953 10.4277 11.877 10.4277 12.082C10.4277 12.3457 10.2168 12.5508 9.95898 12.5508H2.37109ZM8.56445 0.9375H2.97461V8.92383H8.97461C9.30273 8.92383 9.48438 8.74219 9.48438 8.42578V1.85156C9.48438 1.26562 9.17383 0.9375 8.56445 0.9375ZM1.51562 9.16406C1.71484 9.04102 1.9375 8.96484 2.20703 8.93555V0.955078C1.75 1.03125 1.51562 1.3418 1.51562 1.85156V9.16406ZM2.42383 11.6074H8.77539C8.46484 11.1035 8.41797 10.459 8.69336 9.84961C8.63477 9.86133 8.56445 9.86719 8.48828 9.86719H2.47656C1.89062 9.86719 1.51562 10.207 1.51562 10.7344C1.51562 11.291 1.83203 11.6074 2.42383 11.6074Z" />
    </svg>
  );
};
