export const Running: React.FC = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.05 2.25C16.05 3.35457 15.1546 4.25 14.05 4.25C12.9455 4.25 12.05 3.35457 12.05 2.25C12.05 1.14543 12.9455 0.25 14.05 0.25C15.1546 0.25 16.05 1.14543 16.05 2.25Z"
        fill="currentColor"
      />
      <path
        d="M17.55 5.425C17.8 4.925 18.4 4.75 18.9 5C19.4 5.275 19.575 5.875 19.3 6.35L17.925 8.975C17.8 9.225 17.575 9.4 17.3 9.475C17.025 9.55 16.725 9.5 16.5 9.35L13.6 7.525L11.05 12.275L13.475 14.525C13.7 14.725 13.825 15 13.8 15.3L13.55 20.8C13.525 21.325 13.075 21.75 12.55 21.75H12.5C11.95 21.725 11.525 21.25 11.575 20.7L11.8 15.675L8.92505 13L7.17505 16.225C7.00005 16.55 6.67505 16.75 6.30005 16.75H1.55005C1.00005 16.75 0.550049 16.3 0.550049 15.75C0.550049 15.2 1.00005 14.75 1.55005 14.75H5.70005L10.125 6.5H8.65005L7.30005 8.975C7.12505 9.3 6.77505 9.5 6.42505 9.5C6.27505 9.5 6.10005 9.45 5.95005 9.375C5.47505 9.125 5.27505 8.5 5.55005 8.025L7.17505 5.025C7.35005 4.7 7.67505 4.5 8.05005 4.5H12.3C12.5 4.5 12.675 4.55 12.825 4.65L16.675 7.075L17.55 5.425Z"
        fill="currentColor"
      />
    </svg>
  );
};
