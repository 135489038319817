import { keyframes } from "@emotion/react";
import { ThemeUICSSObject } from "@theme-ui/css";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useEffect, useState } from "react";

import { MAX_ROW_HEIGHT } from "@/utils/gallery";

const shimmerAnimation = {
  animationDuration: "2s",
  animationFillMode: "forwards",
  animationIterationCount: "infinite",
  animationName: keyframes({
    from: { backgroundPosition: "-1200px 0" },
    to: { backgroundPosition: "1200px 0" },
  }).toString(),
  animationTimingFunction: "linear",
  background: "loadingBackground",
  backgroundSize: "1200px 100%",
};

type LoadingMediaPlaceholderProps = {
  height?: number;
  width?: number;
  styles?: ThemeUICSSObject;
};

export const LoadingMediaPlaceholder: React.FC<
  LoadingMediaPlaceholderProps
> = ({ width = 600, height = 50, styles }) => {
  const [objectDimension, setObjectDimension] =
    useState<LoadingMediaPlaceholderProps>({
      width: 0,
      height: 0,
    });
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const _width = ref.current.offsetWidth;
      // so that shimmer height same as eventual image height
      const _height = styles?.aspectRatio
        ? Math.min(MAX_ROW_HEIGHT, _width / Number(styles.aspectRatio))
        : width
          ? (_width / width) * height
          : 300;
      setObjectDimension({ width: _width, height: _height });
    }
  }, [ref.current]);

  return (
    <AnimatePresence>
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeOut", duration: 2 }}
        exit={{ opacity: 0 }}
        className="loading-image-placeholder"
        data-testid="loading-image-placeholder"
        sx={{
          backgroundColor: "editorBgSecondary",
          height: `${objectDimension.height}px`,
          width: `${
            !!objectDimension.width && objectDimension.width > 0
              ? `${objectDimension.width}px`
              : width
          }`,
          maxWidth: "100%",
          my: "14px",
          ...shimmerAnimation,
          ...styles,
        }}
      />
    </AnimatePresence>
  );
};
