import { Grant } from "../Syncables";

import { d1Classes } from "@/D1Classes";
import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import {
  JournalDBRow,
  UserRequestingSharedJournalAccess,
} from "@/data/db/migrations/journal";

export class ApproveSharedJournalRequest {
  constructor(
    private sendToServer: typeof sendHTTP = sendHTTP,

    private userKeysStore: {
      getMainUserPrivateKey(): Promise<CryptoKey | null>;
    } = d1Classes.userKeysStore,

    private vaultRepository: {
      getVaultKey(journalId: string): Promise<CryptoKey | null>;
    } = d1Classes.vaultRepository,
  ) {}

  /**
   * This will approve a request to join a shared journal.
   *
   * To do this we create a grant for the joining user
   * and then send that to the server.
   *
   * Grants contain the vault key used to decrypt journals
   **/
  public async approveAndSendToServer(
    joiningUser: UserRequestingSharedJournalAccess,
    journal: JournalDBRow,
  ) {
    await this.sendToServer(
      journal.id,
      this.makeBody(
        joiningUser,
        await this.makeGrantForJoiningUser(joiningUser, journal),
        await this.signJoiningUserPublicKey(joiningUser),
      ),
    );
  }

  /* Private Methods 👇 */

  // A Grant is essentially a copy of the
  // key that encrypts journals, we make a new one
  // so the user joining our journal can decrypt things
  private async makeGrantForJoiningUser(
    joiningUser: UserRequestingSharedJournalAccess,
    journal: JournalDBRow,
  ) {
    return await DOCrypto.Grant.make(
      joiningUser.id,
      await this.joinerPublicKey(joiningUser),
      await this.lookupJournalVaultKey(journal),
    );
  }

  private async joinerPublicKey(
    joiningUser: UserRequestingSharedJournalAccess,
  ) {
    return await Asymmetric.Public.fromPEM(joiningUser.public_key);
  }

  private async lookupJournalVaultKey(journal: JournalDBRow) {
    const vaultKey = await this.vaultRepository.getVaultKey(journal.id);
    if (!vaultKey)
      throw new Error(
        `Could not find any vault key for ${journal.id}, was unable to approve shared journal request`,
      );
    return vaultKey;
  }

  // this signature is verifies that the owner was
  // the one approved the joining user access.
  private async signJoiningUserPublicKey(
    joiningUser: UserRequestingSharedJournalAccess,
  ) {
    const ownerPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
    if (!ownerPrivateKey)
      throw new Error(
        "You can't approve this shared journal request because there's no encryption key entered (owner private key)",
      );

    return await Asymmetric.Private.signArrayBuffer({
      userPrivateKey: ownerPrivateKey,
      // This is a bit funky, we're actually signing the PEM as UTF-8
      // bytes rather than the bytes of the private key itself
      // but that's what the server expects
      buffer: Utf8.toUintArray(joiningUser.public_key),
    });
  }

  /**
   * This formats the data so it matches what the server expects
   * in a typesafe way.
   */
  private makeBody(
    joiningUser: UserRequestingSharedJournalAccess,
    newJournalGrant: Grant,
    participant_public_key_signature_by_owner: string,
  ): ApproveRequestHTTPBody {
    return {
      user_id: joiningUser.id,
      participant_public_key_signature_by_owner,
      grant: newJournalGrant,
    };
  }
}

export interface ApproveRequestHTTPBody {
  user_id: string;
  participant_public_key_signature_by_owner: string;
  grant: Grant;
}

async function sendHTTP(journalId: string, body: ApproveRequestHTTPBody) {
  const resp = await d1Classes.fetchWrapper.fetchAPI(
    `/shares/${journalId}/grant`,
    { method: "PUT", body: JSON.stringify(body) },
  );

  if (!resp.ok) {
    throw new Error(await resp.clone().text());
  }
}
