import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Sentry } from "@/Sentry";
import { ItemGroup } from "@/components/ItemGroup";
import { ButtonItem } from "@/components/ItemGroup/ButtonItem";
import { GoogleLogo } from "@/components/icons/GoogleLogo";
import { useGoogleDrive } from "@/hooks/google/useGoogleDrive";
import { useSaveEncryptionKey } from "@/hooks/useSaveEncryptionKey";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

interface Props {
  handleClose: () => void;
}

export const RetrieveEncryptionKeyFromGoogle: React.FC<Props> = observer(
  ({ handleClose }) => {
    const {
      masterKey: {
        workingWithGDrive,
        setWorkingWithGDrive,
        setHasSavedKeyToGDrive,
      },
    } = viewStates;

    const { connectGoogleDrive, authorized, readEncryptionKey } =
      useGoogleDrive(primaryViewState);
    const onConnectPressed = () => {
      const connectErrorMesssage = "Google Drive connection failed";
      try {
        connectGoogleDrive(connectErrorMesssage);
      } catch (error) {
        const errorMessage = `Google Drive Error: ${(error as Error).message}`;
        Sentry.captureException(new Error(errorMessage));
      }
    };

    const { __ } = useI18n();
    const { userKeysStore } = d1Classes;
    const { validateAndStoreMasterKey } = useSaveEncryptionKey(
      "google-drive",
      userKeysStore,
      d1MainThreadClasses.syncService,
    );

    const showSnackbar = (message: string) => {
      viewStates.snackbar.newMessage(message);
    };

    useEffect(() => {
      const _addEncryptionKey = async () => {
        try {
          const masterKeyString = await readEncryptionKey();
          if (masterKeyString) {
            await validateAndStoreMasterKey(masterKeyString);
            // since we managed to retrieve the key, the user has saved it to Google Drive
            setHasSavedKeyToGDrive(true);
            showSnackbar(__("Your encryption key was successfully added."));
            handleClose();
          } else {
            showSnackbar(
              __("Failed to retrieve your encryption key from Google Drive"),
            );
          }
        } catch (error) {
          showSnackbar(__("Failed to add encryption key"));
          const errorMessage = `Failed to add encryption key: ${
            (error as Error).message
          }`;
          Sentry.captureException(new Error(errorMessage));
        }
      };

      const addEncryptionKey = async () => {
        if (authorized) {
          setWorkingWithGDrive(true);
          showSnackbar(__("Retrieving your encryption key from Google Drive"));
          try {
            await _addEncryptionKey();
          } finally {
            setWorkingWithGDrive(false);
          }
        }
      };
      addEncryptionKey();
    }, [authorized]);
    return (
      <ItemGroup>
        <ButtonItem
          key={"Sign in to Google"}
          onClick={onConnectPressed}
          disabled={workingWithGDrive}
        >
          <Flex
            sx={{
              justifyContent: "flex-start",
              gap: 3,
              flexDirection: "row",
            }}
          >
            <GoogleLogo />
            <span
              sx={{
                fontSize: 1,
                lineHeight: "1.5rem",
                whiteSpace: "nowrap",
              }}
            >
              {__("Sign in with Google")}
            </span>
          </Flex>
        </ButtonItem>
      </ItemGroup>
    );
  },
);
