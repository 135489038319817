import { CheckboxControl } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";

import { Item, ItemGroup } from "@/components/ItemGroup";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import {
  FEATURE_FLAG_NAMES,
  ValidFeatureFlagKey,
} from "@/components/Settings/constants";
import { featureFlagsViewState } from "@/view_state/ViewStates";

export const FeatureFlags: React.FC = observer(() => {
  return (
    <SettingsPanel header={<span>{__("Feature Flags")}</span>}>
      <ItemGroup addMargin={true}>
        {FEATURE_FLAG_NAMES.map((flagName) => {
          return (
            <Item key={flagName}>
              <CheckboxControl
                label={flagName}
                checked={
                  featureFlagsViewState.getFlagByName(
                    flagName as ValidFeatureFlagKey,
                  )?.enabled || false
                }
                onChange={(value) => {
                  featureFlagsViewState.setFeatureFlag(
                    flagName as ValidFeatureFlagKey,
                    value,
                  );
                }}
              />
            </Item>
          );
        })}
      </ItemGroup>
    </SettingsPanel>
  );
});

FeatureFlags.displayName = "FeatureFlags";
