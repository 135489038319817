export const headingSix = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8 18H8.40001V13.2H3.60001V18H1.20001V6H3.60001V10.8H8.40001V6H10.8V18ZM21.12 9C20.88 8.76 20.52 8.52 20.16 8.4C19.44 8.16 18.6 8.16 17.88 8.4C17.52 8.52 17.16 8.76 16.92 9L16.2 10.08C15.96 10.68 15.96 11.16 15.96 11.76C16.44 11.4 16.92 11.04 17.4 10.8C17.88 10.56 18.36 10.44 18.96 10.44C19.44 10.44 19.92 10.44 20.4 10.68C20.88 10.8 21.24 11.04 21.6 11.4C21.96 11.76 22.2 12.12 22.44 12.48C22.68 12.96 22.8 13.44 22.8 14.04C22.8 14.64 22.68 15.12 22.44 15.72C22.2 16.2 21.84 16.56 21.48 16.92C21 17.28 20.52 17.52 20.04 17.64C18.84 18 17.64 18 16.44 17.64C15.84 17.4 15.24 17.04 14.76 16.56C14.28 16.08 13.8 15.48 13.56 14.76C13.32 14.04 13.2 13.2 13.2 12.24C13.2 11.28 13.32 10.32 13.68 9.48C13.92 8.76 14.4 8.04 14.88 7.56C15.36 7.08 15.96 6.72 16.56 6.48C17.28 6.12 17.88 6 18.6 6C19.44 6 20.28 6.12 21 6.36C21.6 6.6 22.2 6.96 22.68 7.32C22.68 7.44 21.12 9 21.12 9ZM18.24 15.96C18.48 15.96 18.72 15.96 18.96 15.84C19.2 15.84 19.44 15.72 19.56 15.6C19.68 15.48 19.92 15.24 20.04 15C20.16 14.76 20.16 14.4 20.16 14.16C20.16 13.68 20.04 13.2 19.68 12.84C19.32 12.6 18.84 12.48 18.36 12.48C18 12.48 17.52 12.6 17.16 12.72C16.68 12.96 16.32 13.2 15.96 13.56C15.96 13.92 16.08 14.4 16.32 14.76C16.44 15 16.68 15.24 16.8 15.48C17.04 15.6 17.16 15.84 17.4 15.84C17.64 15.96 18 16.08 18.24 15.96Z" />
    </svg>
  );
};
