import { Toolbar, ToolbarGroup, createSlotFill } from "@wordpress/components";
import { observer } from "mobx-react-lite";

import { useBlockSelection } from "../hooks/blockSelection";

import { CODE_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { HeadingTransform } from "@/components/Editor/transforms/HeadingTransform";
import { ListTransform } from "@/components/Editor/transforms/ListTransform";
import { toolbarViewState } from "@/view_state/ViewStates";

export const CONTEXT_FORMAT_TOOLBAR_SLOT_FILL = createSlotFill(
  "dayone/context-format-toolbar",
);

const contextToolbarStyles = {
  background: "contextToolbarBg",
  border: "none",
  alignItems: "center",
  borderRadius: "sm",
  paddingRight: 1,
  height: 5,
};

const toolbarGroupStyle = {
  borderRight: "1px solid",
  //  This color does not change on dark mode change, and is not in the theme for now.
  borderColor: "rgba(255, 255, 255, 0.3)",
  height: 5,
  minHeight: 5,
  background: "none",
};

export const ContextFormattingToolbar = observer(() => {
  const { getSelectedBlockClientIds, getBlockName } = useBlockSelection();
  // if any of the selected blocks are CodeBlock, don't show the toolbar
  const selectedBlockClientIds = getSelectedBlockClientIds();
  const hasCodeBlock = selectedBlockClientIds.some((clientId: string) => {
    const blockName = getBlockName(clientId);
    return blockName === CODE_BLOCK_ID;
  });

  if (hasCodeBlock || toolbarViewState.isHeadingTransformDropdownOpen) {
    return null;
  }

  return (
    <Toolbar sx={contextToolbarStyles} label="Options">
      {/* Rich text formatting options  */}
      <ToolbarGroup sx={toolbarGroupStyle}>
        <CONTEXT_FORMAT_TOOLBAR_SLOT_FILL.Slot />
      </ToolbarGroup>
      {/* Heading transforms */}
      <ToolbarGroup sx={toolbarGroupStyle}>
        <HeadingTransform isContextMenu headingLevel={1} />
        <HeadingTransform isContextMenu headingLevel={2} />
        <HeadingTransform isContextMenu headingLevel={3} />
      </ToolbarGroup>
      {/* List transforms */}
      <ToolbarGroup sx={toolbarGroupStyle}>
        <ListTransform isContextMenu listType="unordered" />
        <ListTransform isContextMenu listType="ordered" />
        <ListTransform isContextMenu listType="checklist" />
      </ToolbarGroup>
    </Toolbar>
  );
});

ContextFormattingToolbar.displayName = "ContextFormattingToolbar";
