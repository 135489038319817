import { __ } from "@wordpress/i18n";
import { useCallback, useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { CLIENT_ID, SCOPES_GOOGLE_DRIVE } from "@/hooks/google/constants";
import { maybeMigrate } from "@/hooks/google/migrateDriveKeyBackup";
import { useGoogleLogin } from "@/hooks/google/useGoogleLogin";
import {
  deleteFileFromGoogleDrive,
  getAllStoredFiles,
  getFileId,
  saveKeyToDrive,
} from "@/hooks/google/utils";
import {
  getAndFormatMasterKey,
  getCloudBackupFileName,
} from "@/utils/masterkeyBackup";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { masterKeyViewState } from "@/view_state/ViewStates";

export const useGoogleDrive = (primaryViewState: PrimaryViewState) => {
  const { processOauthToken, authorized } = useGoogleLogin();
  const { user } = primaryViewState;
  if (!user) {
    throw new Error("User not found");
  }

  const [error, setError] = useState(!CLIENT_ID ? "CLIENT_ID not found." : "");
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    masterKeyViewState.setHasSavedKeyToGDrive(
      user.master_key_storage.includes("drive"),
    );
    setLoading(false);
  }, []);

  const readEncryptionKey = async () => {
    const user = primaryViewState.user;
    if (!user || !token) {
      return null;
    }

    const files = await getAllStoredFiles(user, token);

    if (files) {
      const fileName = getCloudBackupFileName(user);
      const file = files.find((f) => f.name === fileName);
      if (!file) {
        // If we can't find the file, check if we have an old file to migrate
        const keyFromOldLocation = await maybeMigrate(files, user, token);
        if (!keyFromOldLocation) {
          masterKeyViewState.setHasSavedKeyToGDrive(false);
        }
        if (keyFromOldLocation) {
          await saveEncryptionKey(keyFromOldLocation);
          return keyFromOldLocation;
        }

        setError(__("Master key not found on Google Drive."));
        return null;
      }
      const fileResult = await d1Classes.fetchWrapper.basicFetch(
        new URL(
          `https://www.googleapis.com/drive/v3/files/${file?.id}?alt=media`,
        ),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      );
      if (fileResult.ok) {
        const data = await fileResult.arrayBuffer();
        return getAndFormatMasterKey(data, user.id);
      }
    } else {
      setError(__("Failed to read master key from Google Drive."));
      return null;
    }
  };

  const saveEncryptionKey = async (masterKeyString: string) => {
    if (!user || !token) {
      return;
    }
    if (!masterKeyString) {
      setError("Master key not found.");
      return;
    }

    const result = await saveKeyToDrive(user, masterKeyString, token);
    if (!result.ok) {
      setError(__("Failed to save master key to Google Drive."));
    }
  };

  const connectGoogleDrive = useCallback(
    (errorMessage: string) => {
      processOauthToken(
        SCOPES_GOOGLE_DRIVE,
        finishGoogleDriveConnection,
        errorMessage,
      );
    },
    [processOauthToken],
  );

  const finishGoogleDriveConnection = (
    token: google.accounts.oauth2.TokenResponse,
  ) => {
    if (token) {
      setToken(token.access_token);
    } else {
      setError(__("Failed to connect to Google Drive."));
    }
  };

  const deleteFile = async () => {
    if (!token || !user) {
      return false;
    }
    const fileName = getCloudBackupFileName(user);
    const fileId = await getFileId(user, token, fileName);
    if (!fileId) {
      return false;
    }
    const deleted = await deleteFileFromGoogleDrive(fileId, token);
    if (deleted) {
      masterKeyViewState.setHasSavedKeyToGDrive(false);
    }
    return deleted;
  };

  return {
    loading,
    readEncryptionKey,
    saveEncryptionKey,
    error,
    connectGoogleDrive,
    authorized,
    deleteFile,
  };
};
