// This is the file that constructs the sync worker.
// Client code can import the worker instance from this file to send messages to it.

import { DODexie } from "@/data/db/dexie_db";
//@ts-ignore
import SyncWorker from "@/worker/SyncWorkerMain?worker";
import {
  workerMessage_runPush,
  workerMessage_runSync,
  workerMessage_stopSync,
} from "@/worker/SyncWorkerTypes";

export class SyncWorkerClient {
  private syncWorker: Worker | null = null;

  constructor() {
    if (process.env.NODE_ENV !== "test") {
      this.syncWorker = new SyncWorker();
    }
    if (typeof BroadcastChannel === "undefined") {
      this.syncWorker?.addEventListener("message", (event) => {
        if (event.data.type === "storagemutated") {
          DODexie.on("storagemutated").fire(event.data.updatedParts);
        }
      });
    }

    window.addEventListener("online", () => {
      this.sync();
    });
  }

  sync() {
    this.syncWorker?.postMessage(workerMessage_runSync);
  }

  stop() {
    this.syncWorker?.postMessage(workerMessage_stopSync);
  }

  push() {
    this.syncWorker?.postMessage(workerMessage_runPush);
  }

  terminate() {
    this.syncWorker?.terminate();
  }
}
