import { RefObject, useLayoutEffect, useState } from "react";

export const useElementRefSize = (
  elementRef: RefObject<HTMLElement>,
  contentClass: string,
) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const updateSize = () => {
    const contentDiv = elementRef.current?.querySelector(`.${contentClass}`);
    if (contentDiv) {
      setSize({
        width: contentDiv.clientWidth,
        height: contentDiv.clientHeight,
      });
    }
  };

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return size;
};
