import {
  ExtendedFormatConfiguration,
  registerFormatType,
  applyFormat,
  RichTextValue,
  RichTextFormat,
} from "@wordpress/rich-text";

import {
  formatHashTagsText,
  formatIsRegistered,
} from "@/components/Editor/formats/utils";
import { HASHTAG_HIGHLIGHT_COLOR } from "@/data/repositories/TagParser";

const NAME = "dayone/hashtag";
const title = "Hashtag";
const tagName = "data";
const className = "do-hashtag";

const style = "color:" + HASHTAG_HIGHLIGHT_COLOR;

function applyHashtag(
  record: RichTextValue,
  hashtags: { start: number; end: number; tag: string }[] = [],
): RichTextValue {
  hashtags.forEach((hashtag) => {
    let { start, end } = hashtag;

    // This should never be the case, but for safety always have the lower number be the start of the range
    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    if (start > record.text.length) {
      start = record.text.length;
    }

    if (end > record.text.length) {
      end = record.text.length;
    }

    record = applyFormat(
      record,
      {
        type: NAME,
        // @ts-ignore
        attributes: {
          style: style || "",
          value: hashtag.tag,
          class: className,
        },
      },
      start,
      end,
    );
  });
  return record;
}

const hashtag = (
  createTagsFromHashtags: boolean,
): ExtendedFormatConfiguration => ({
  title,
  tagName,
  className: null,
  // This allows us to apply formatting before the editable DOM is created. The formatting will automatically be removed when saving
  // We use it here to add formatting to hashtags entered into the content of the editor
  // It runs anytime the content of a rich text component changes
  // In our other custom formats we use the __unstableInputRule
  // It doesn't work well here as it only runs on input and not if you remove content
  __experimentalCreatePrepareEditableTree() {
    if (!createTagsFromHashtags) {
      return (formats) => formats;
    }
    return (formats, text: string) => {
      // We aren't really using the replacments variable, just defining to keep the types happy
      const replacements: readonly (RichTextFormat[] | undefined)[] = [];
      let record = { formats, text, replacements };
      const hashFormats = formatHashTagsText(text);
      // @ts-ignore
      record = applyHashtag(record, hashFormats);
      return record.formats;
    };
  },
  edit() {
    return null;
  },
});

export const register = (createTagsFromHashtags: boolean) => () => {
  if (!formatIsRegistered(NAME)) {
    registerFormatType(NAME, hashtag(createTagsFromHashtags));
  }
};
