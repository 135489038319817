export const Contact: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10C15 11.933 13.6569 13.5 12 13.5C10.3431 13.5 9 11.933 9 10C9 8.067 10.3431 6.5 12 6.5C13.6569 6.5 15 8.067 15 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 4C7.58172 4 4 7.58172 4 12C4 13.7486 4.56101 15.3662 5.51285 16.6826C6.55374 15.4025 9.06608 14.5 12 14.5C14.9339 14.5 17.4463 15.4025 18.4871 16.6826C19.439 15.3662 20 13.7486 20 12C20 7.58172 16.4183 4 12 4Z"
        fill="currentColor"
      />
    </svg>
  );
};
