export const Bike: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19.0031"
        cy="15.0012"
        r="4.00167"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99805 7.49826H9.9993"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99731 15.0015H10.4996L17.0023 7.99854L8.99898 9.99937L4.99731 15.0015Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4996 15.0012L7.99854 7.49805"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0031 15.0013L16.5021 5.99756H14.5012"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99884 15.0012C9.00108 16.8718 7.70857 18.495 5.88495 18.9118C4.06133 19.3285 2.19203 18.4279 1.38149 16.742C0.570957 15.0561 1.03494 13.0337 2.4993 11.8697C3.96367 10.7056 6.03862 10.7099 7.49821 11.8799"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
