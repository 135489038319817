import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { UserStore } from "@/data/stores/UserStore";
import { isDeepEqual } from "@/utils/is-equal";

// The user settings are stored persisted on the server,
// but we primarily interact with them through the local IndexedDB.
// This repo will load settings from the server if none are found locally,
// otherwise it will use the local settings.
// When saving, it'll first save to the server, then to the local DB.
export type FeatureFlag = { name: string; enabled: boolean };

export type UserSettings = {
  track_usage_statistics?: boolean | null;
  auto_title_first_line?: boolean | null;
  create_tags_from_hashtags?: boolean | null;
  temperature_unit?: "C" | "F" | null;
  theme?: "light" | "dark" | "system" | null;
  developer_mode?: boolean | null;
  shown_shared_journals_info?: boolean | null;
  show_app_download_banner?: boolean | null;
  feature_flags: FeatureFlag[];
};

export type ServerUserSettings = {
  web: string; // This is the JSON stringified UserSettings object
  analytics_opt_out: boolean; // This also contains ios, android and mac settings, but we don't use those
  cursor: string;
};

export class UserSettingsRepository {
  key = "user-settings";
  loggedOutSettingsKey = "tmp-user-settings";
  private loaded = false;

  constructor(
    private userStore: UserStore,
    private kvStore: KeyValueStore,
    private syncStateRepository: SyncStateRepository,
    private fetchWrapper: FetchWrapper,
  ) {
    // This avoids running initialization code in Worker threads
    if (typeof window === "undefined") {
      return;
    }
    // On Login it is possible the active user is set before the auth token.
    // We need to wait for the auth token before loading the settings.
    this.userStore.subscribeToAuthToken(async (token) => {
      // We check for the active user here, just to be sure.
      const user = await this.userStore.getActiveUser();

      // After the user logs in, automatically load their settings
      if (!this.loaded && !(process.env.NODE_ENV == "test") && user && token) {
        this.loaded = true;

        let settings = await this.fetchSettings();
        const tempSettings = await this.kvStore.get<Partial<UserSettings>>(
          this.loggedOutSettingsKey,
        );

        // This is done to persist the settings that were saved while the user was logged out - usage statistics tracking for ex.
        if (tempSettings) {
          settings = { ...settings, ...(tempSettings as UserSettings) };
          settings = await this.saveSettings(settings);
          await this.kvStore.delete(this.loggedOutSettingsKey);
        }
        await this.kvStore.set(this.key, settings);
        this.loaded = true;
      }
    });
  }

  private async fetchSettings() {
    try {
      const storedCursor =
        await this.syncStateRepository.getUserSettingsCursor();
      const res = await this.fetchWrapper.fetchAPI(
        `/user-settings` + (storedCursor ? `?cursor=${storedCursor}` : ""),
        {},
        { expectedStatusCodes: [200, 304] },
      );
      // If the server returns a 304, it means we have the latest settings stored locally
      if (res.status === 304) {
        return await this.loadSettings();
      } else if (res.ok) {
        const json: ServerUserSettings = await res.json();
        const { web, cursor } = json;
        if (web) {
          await this.syncStateRepository.setUserSettingsCursor(cursor);
          return JSON.parse(web) as UserSettings;
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    return null;
  }

  async loadSettings(): Promise<UserSettings | null> {
    // First check to see if settings are saved in the key value store under this.key
    const settings = await this.kvStore.get<UserSettings | null>(this.key);
    return settings ?? null;
  }

  async get_trackUsageStatistics(): Promise<boolean | null> {
    const settings = await this.loadSettings();
    return settings?.track_usage_statistics ?? null;
  }

  async saveLoggedOutSettings(settings: Partial<UserSettings>): Promise<void> {
    await this.kvStore.set(this.loggedOutSettingsKey, settings);
  }

  private mergeFeatureFlags(
    currentSettingsFlags: FeatureFlag[] = [],
    settingsFlags: FeatureFlag[] = [],
  ) {
    const flagMap = new Map();
    [...currentSettingsFlags, ...settingsFlags].forEach((flag) => {
      flagMap.set(flag.name, flag.enabled);
    });
    return Array.from(flagMap).map(([name, enabled]) => ({ name, enabled }));
  }

  // This function should be flexibile enough to allow the caller to pass in the whole object,
  // or just parts. Anything left undefined will remain unchanged on the back-end. Anything set
  // to null will be deleted on the back-end. At least, that's how I expect it to work.
  // Your milage may vary 🤣
  async saveSettings(settings: Partial<UserSettings>): Promise<UserSettings> {
    const user = await this.userStore.getActiveUser();
    const currentSettings = await this.loadSettings();
    let merged = { ...defaultUserSettings, ...settings };
    if (currentSettings) {
      merged = {
        ...currentSettings,
        ...settings,
        feature_flags: this.mergeFeatureFlags(
          currentSettings.feature_flags,
          settings.feature_flags,
        ),
      };
    }
    if (user && !isDeepEqual(currentSettings, merged)) {
      try {
        const last_cursor =
          await this.syncStateRepository.getUserSettingsCursor();
        const res = await this.fetchWrapper.postJson(
          "/user-settings",
          {
            // We store "track_usage_statistics" inside the "web" object, so we just need to make sure
            // to map it to the correct key when sending it to the server
            analytics_opt_out:
              merged?.track_usage_statistics === true ? false : true,
            web: JSON.stringify(merged),
            ...(last_cursor && { last_cursor }),
          },
          { expectedStatusCodes: [200, 409] },
        );
        // If the server returns a 409, it means the settings have changed since we last fetched them, so we apply those changes
        if (res.ok || res.status === 409) {
          const json: ServerUserSettings = await res.json();
          const { web, cursor } = json;
          await this.syncStateRepository.setUserSettingsCursor(cursor);
          merged = JSON.parse(web);
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    await this.kvStore.set(this.key, merged);
    return merged;
  }

  // Just a convenience function to make it easier to set a single feature flag
  async setFeatureFlag(key: string, value: boolean) {
    return this.saveSettings({
      feature_flags: [{ name: key, enabled: value }],
    });
  }

  subscribe(callback: (settings: UserSettings | undefined) => void) {
    this.kvStore.subscribe(this.key, callback);
  }

  sync = async () => {
    const settings = await this.fetchSettings();
    if (!settings) return;

    return await this.saveSettings(settings);
  };
}

export const defaultUserSettings: UserSettings = {
  auto_title_first_line: true,
  track_usage_statistics: null,
  create_tags_from_hashtags: true,
  temperature_unit: null,
  developer_mode: false,
  shown_shared_journals_info: false,
  show_app_download_banner: false,
  feature_flags: [],
  theme: "system",
};
