const roundToTwo = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const formatDistance = (
  distance: number,
  units: "metric" | "imperial",
  translate: (key: string) => string,
  round = false,
) => {
  if (units === "metric") {
    if (distance < 1000) {
      if (round) {
        return `${Math.round(distance).toLocaleString("en-US")} ${translate("meters")}`;
      } else {
        return `${distance.toLocaleString("en-US")} ${translate("meters")}`;
      }
    } else {
      return `${roundToTwo(distance / 1000).toLocaleString("en-US")} ${translate(
        "km",
      )}`;
    }
  }
  if (distance < 1000) {
    return `${roundToTwo(distance * 3.28084).toLocaleString("en-US")} ${translate(
      "feet",
    )}`;
  } else {
    return `${roundToTwo(distance * 0.000621371).toLocaleString("en-US")} ${translate(
      "miles",
    )}`;
  }
};
