import { makeAutoObservable } from "mobx";

import { ValidFeatureFlagKey } from "@/components/Settings/constants";
import {
  FeatureFlag,
  UserSettingsRepository,
} from "@/data/repositories/UserSettingsRepository";
import { isDeepEqual } from "@/utils/is-equal";

export class FeatureFlagsViewState {
  allFlags: FeatureFlag[] = [];

  constructor(private userSettingsRepo: UserSettingsRepository) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.startSubscriptions();
  }

  startSubscriptions() {
    this.userSettingsRepo.subscribe((settings) => {
      if (settings) {
        this.gotNewFeatureFlags(settings.feature_flags);
      }
    });
  }

  public setFeatureFlag(name: ValidFeatureFlagKey, enabled: boolean) {
    this.userSettingsRepo.setFeatureFlag(name, enabled);
  }

  private gotNewFeatureFlags(featureFlags: FeatureFlag[]) {
    if (!isDeepEqual(this.allFlags, featureFlags)) {
      this.allFlags = featureFlags;
    }
  }

  private getFeatureFlag(name: ValidFeatureFlagKey) {
    const featureFlag = this.allFlags.find((f) => f.name === name);
    if (featureFlag) {
      return featureFlag.enabled;
    }
    return false;
  }

  getFlagByName(name: ValidFeatureFlagKey) {
    return this.allFlags.find((f) => f.name === name);
  }

  // Please make specific actions for each feature flag you want to
  // use below for the sake of clean logs and performance.

  get readContentsAsMarkdown() {
    return this.getFeatureFlag("readContentsAsMarkdown");
  }

  get prefilledEntryURLs() {
    return this.getFeatureFlag("prefilledEntryURLs");
  }

  get showProfileSharingSetting() {
    return this.getFeatureFlag("showProfileSharingSetting");
  }

  get enablePasskeys() {
    return this.getFeatureFlag("enablePasskeys");
  }

  get showServerSideMove() {
    return this.getFeatureFlag("showServerSideMove");
  }

  get showSaveTemplate() {
    return this.getFeatureFlag("showSaveTemplate");
  }
  get enableElectron() {
    return this.getFeatureFlag("enableElectron");
  }
  get showImportExport() {
    return this.getFeatureFlag("showImportExport");
  }

  get showEncryptedIndicator() {
    return this.getFeatureFlag("showEncryptedIndicator");
  }
}
