/**
 * Makes a URL with query parameters. Ignores null parameters.
 *
 * Example:
 *    constructUrlWithQueryParams("/blah", {a: "b", c: "d", z: null}) => "/blah?a=b&c=d"
 *
 * @param path Base URL
 * @param params Query parameters as an object
 * @returns string URL with query parameters
 */
export const constructUrlWithQueryParams = (
  path: string,
  params: Record<string, string | null>,
) => {
  const queryParams = Object.fromEntries(
    Object.entries(params).filter(
      ([, value]) => value !== null && value !== undefined,
    ),
  ) as Record<string, string>;
  return path + "?" + new URLSearchParams(queryParams);
};
