export const Haha: React.FC = () => {
  return (
    <svg
      id="haha"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="url(#paint0_linear_1139_60439)"
        stroke="#B67500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.19146 7.9119C7.51629 7.46525 8.14171 7.3665 8.58836 7.69133L14.0884 11.6913C14.4095 11.9249 14.562 12.3263 14.477 12.7141C14.392 13.102 14.0856 13.4028 13.6963 13.4807L6.19631 14.9807C5.65475 15.089 5.12792 14.7377 5.01961 14.1962C4.9113 13.6546 5.26252 13.1278 5.80408 13.0195L11.0669 11.9669L7.41202 9.30881C6.96537 8.98397 6.86662 8.35855 7.19146 7.9119ZM26.8089 7.9119C27.1338 8.35855 27.035 8.98397 26.5884 9.30881L22.9335 11.9669L28.1963 13.0195C28.7379 13.1278 29.0891 13.6546 28.9808 14.1962C28.8725 14.7377 28.3456 15.089 27.8041 14.9807L20.3041 13.4807C19.9147 13.4028 19.6083 13.102 19.5234 12.7141C19.4384 12.3263 19.5909 11.9249 19.912 11.6913L25.412 7.69133C25.8587 7.3665 26.4841 7.46525 26.8089 7.9119Z"
        fill="#211603"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 17C5 23.6274 10.3726 29 17 29C23.6274 29 29 23.6274 29 17H5Z"
        fill="#211603"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9448 23.7182C25.1807 21.5201 21.3909 20 17.0002 20C12.6096 20 8.81975 21.5201 7.05566 23.7182C9.21292 26.9052 12.862 29 17.0002 29C21.1385 29 24.7876 26.9052 26.9448 23.7182Z"
        fill="#E74C3C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_60439"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF788" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
