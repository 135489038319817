import { Flex, FlexItem } from "@wordpress/components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { encryptMasterKey } from "@/crypto/DOCrypto/utils";
import { LoggedOut } from "@/layouts/LoggedOut";
import { setDocumentTitle } from "@/utils/set-document-title";
import { primaryViewState } from "@/view_state/ViewStates";

const CompleteQRLogin = observer(() => {
  setDocumentTitle("Complete Login");
  const [message, setMessage] = useState("");
  const searchParams = new URLSearchParams(document.location.search);
  const secret = searchParams.get("token") ?? "";
  const key = document.location.hash.split("#")[1];
  const masterKey = primaryViewState.masterKeyString;

  useEffect(() => {
    if (!secret || !key) return;
    const sendData = async () => {
      const payload = masterKey ? await encryptMasterKey(masterKey, key) : null;
      const res = await d1Classes.fetchWrapper.fetchAPI("/v2/users/login/qr", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ secret, payload }),
      });
      if (res) {
        setMessage("Login successful");
      }
    };
    sendData();
  }, [key, secret, masterKey]);

  return (
    <LoggedOut>
      <div
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Flex sx={{ justifyContent: "center", mt: 3 }}>
          {!message ? <p>Completing Login.</p> : <FlexItem>{message}</FlexItem>}
        </Flex>
      </div>
    </LoggedOut>
  );
});

export default CompleteQRLogin;
