import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import { DODexie } from "@/data/db/dexie_db";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { UserModel } from "@/data/models/UserModel";
import { isDeepEqual } from "@/utils/is-equal";

export class JournalParticipantRepository {
  constructor(
    protected db: DODexie,
    private fetch: FetchWrapper,
  ) {}

  async bulkUpsertForJournal(participants: JournalParticipantDBRow[]) {
    return await this.db.participants.bulkPut(participants);
  }

  /**
   * This method is used to upsert participants for a journal if they don't already exist.
   * This is useful when you want to avoid a re-render but also want to make sure that
   * the participants are in the database.
   */
  async bulkUpsertForJournalIfNotExists(
    participants: JournalParticipantDBRow[],
  ) {
    await Promise.all(
      participants.map(async (participant) => {
        const existingParticipant = await this.db.participants.get(
          participant.user_id,
        );

        if (
          !existingParticipant ||
          !isDeepEqual(existingParticipant, participant)
        ) {
          await this.db.participants.put(participant);
        }
      }),
    );
  }

  async getAvatarForParticipant(avatarId: string, token?: string) {
    const headers: HeadersInit = { "x-manual-redirect": "true" };

    if (token) {
      // create a header to add to the fetch request to get the avatar
      headers["Invite-Token"] = token;
    }
    const resp = await this.fetch.fetchAPI(`/v2/media/${avatarId}`, {
      headers,
    });

    if (resp.status === 404) {
      return null;
    } else if (!resp.ok) {
      Sentry.captureException(
        new Error(
          `Failed to get avatar URL: ${resp.statusText} - ${resp.body}`,
        ),
      );
      return null;
    } else {
      const imgURL = resp.headers.get("x-manual-redirect-location");

      if (!imgURL) {
        Sentry.captureException(
          new Error(
            `Could not find avatar S3 url on redirect response: ${resp.headers}`,
          ),
        );
        return null;
      }
      const res = await fetch(imgURL);
      const blob = await res.blob();
      return blob;
    }
  }

  async getAvatarURLForParticipant(
    userId: string,
    avatarId: string,
    token?: string,
  ) {
    const avatarImageBlob = await this.getAvatarForParticipant(avatarId, token);

    if (!avatarImageBlob) {
      return;
    }
    const buffer = await avatarImageBlob.arrayBuffer();
    const avatarImage = new Uint8Array(buffer);
    await this.db.participants.update(userId, {
      avatarImage,
    });
    return avatarImage;
  }

  async getUserInfo(userId: string, withAvatar = true, token?: string) {
    let user = await this.db.participants.get(userId);
    if (!user) return;

    if (withAvatar && user.avatar && !user.avatarImage) {
      user = await this.decorateUserWithAvatar(user, token);
    }
    return user;
  }

  async updateParticipant(user: UserModel) {
    const participant = await this.db.participants.get(user.id);

    if (participant) {
      if (!user.avatar || user.avatar !== participant.avatar) {
        delete participant.avatarImage;
      }
      await this.db.participants.put({
        ...participant,
        name: user.display_name || "",
        avatar: user.avatar,
        initials: user.initials,
        profile_color: user.profile_color,
      });
    }
  }

  async decorateUserWithAvatar(user: JournalParticipantDBRow, token?: string) {
    const avatarURL = await this.getAvatarURLForParticipant(
      user.user_id,
      user.avatar,
      token,
    );
    if (!avatarURL) return user;
    user.avatarImage = avatarURL;
    await this.db.participants.put(user);
    return user;
  }

  subscribeToUserInfo(
    userId: string,
    withAvatar = true,
    callback: (user: JournalParticipantDBRow | undefined) => void,
    token?: string,
  ) {
    const sub = liveQuery(() => this.db.participants.get(userId)).subscribe(
      async (user) => {
        if (!user) {
          callback(undefined);
          return;
        }
        if (withAvatar && user.avatar && !user.avatarImage) {
          user = await this.decorateUserWithAvatar(user, token);
        }
        callback(user);
      },
    );

    return () => {
      sub.unsubscribe();
    };
  }
}
