import { DOCrypto } from "@/crypto/DOCrypto";
import { Asymmetric, DOCryptoBasics } from "@/crypto/DOCryptoBasics";
import { MasterKey, generateUserMasterKey } from "@/crypto/types/MasterKey";
import { Utf8 } from "@/crypto/utf8";
import { toBase64, uintArrayConcat } from "@/crypto/utils";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import {
  UnlockedUserKeysDBRow,
  UserKeysDBRow,
} from "@/data/db/migrations/user_keys";
import { ContentKeysRepository } from "@/data/repositories/ContentKeysRepository";
import { CryptoKeysV4 } from "@/data/repositories/Syncables";
import {
  isUserKeysDBRowLocked,
  LocationOfKey,
  UserKeysRepository,
} from "@/data/repositories/UserKeysRepository";

export class UserKeysStore {
  constructor(
    private keysRepository: UserKeysRepository,
    private kvStore: SecureKeyValueStore,
    private contentKeysRepository: ContentKeysRepository,
  ) {}

  async pull(userId: string) {
    const mainKey = await this.keysRepository.getMainKey();
    if (!mainKey) {
      const result = await this.keysRepository.fetchMainKeyFromServer();
      if (result) {
        await this.keysRepository.saveMainKey({
          private_key: { lockedD1: result.encryptedPrivateKey },
          public_key: Asymmetric.PEM.toBytes(result.publicKey),
          user_id: userId,
        });
      }
    }

    // There might be a different key in the v4 API.
    const v4Key = await this.keysRepository.getKeyByLocalId("v4");
    if (!v4Key) {
      await this.contentKeysRepository.fetchV4APICryptoKeysFromServer(userId);
    }

    await this.unlockAllKeys();
  }

  private async unlockAllKeys(maybeMasterKey?: MasterKey) {
    let masterKey: MasterKey | undefined = maybeMasterKey;
    if (!masterKey) {
      const mkString = await this.getMasterKeyString();
      masterKey = mkString ? new MasterKey(mkString) : undefined;
    }
    // We don't have a master key, so we can't unlock anything
    if (!masterKey) return;

    // Decrypt all the user keys
    const allKeyRows = await this.keysRepository.getAllKeys();
    for (const row of allKeyRows) {
      if (isUserKeysDBRowLocked(row)) {
        const decrypted = await masterKey.unlockUserKey(
          row.user_id,
          row.private_key.lockedD1,
        );
        const bytes = await Asymmetric.Private.toUint8Array(decrypted);
        const newRow: UnlockedUserKeysDBRow = {
          ...row,
          private_key: bytes,
        };
        await this.keysRepository.saveKey(newRow);
      }
    }
  }

  async getMostRecentContentKey() {
    const activeContentKeyFingerprint =
      await this.contentKeysRepository.getActiveContentKeyFingerprint();
    if (activeContentKeyFingerprint) {
      return this.contentKeysRepository.getContentKeyByFingerprint(
        activeContentKeyFingerprint,
      );
    } else {
      const cryptoKeys =
        await this.contentKeysRepository.fetchV4APICryptoKeysFromServer();
      if (cryptoKeys) {
        const activeContentKeyFingerprint =
          cryptoKeys.activeContentKeyFingerprint;
        const mostRecentContentKey = cryptoKeys.contentKeys.find((key) => {
          return key.fingerprint === activeContentKeyFingerprint;
        });
        return mostRecentContentKey;
      }
    }
    return null;
  }

  async createContentKey() {
    // Get user key information
    const mkString = await this.getMasterKeyString();
    if (!mkString) {
      return null;
    }
    const masterKey = new MasterKey(mkString);
    const derivedKey = await masterKey.derivedKey;
    const userPublicKey = await this.getMainUserPublicKey();
    const userPrivateKey = await this.getMainUserPrivateKey();
    if (!userPublicKey || !userPrivateKey) {
      return null;
    }
    const userPublicKeyPEM =
      await DOCryptoBasics.Asymmetric.Public.toPEM(userPublicKey);

    const userPublicKeyFingerprint =
      await DOCryptoBasics.Fingerprint.forPEM(userPublicKeyPEM);

    const userPrivateKeyPEM =
      await DOCryptoBasics.Asymmetric.Private.toPEM(userPrivateKey);

    const userEncryptedPrivateKey = await DOCryptoBasics.Symmetric.encryptToD1(
      userPrivateKeyPEM,
      derivedKey,
    );

    // Make new content key
    const newKeyPair = await DOCryptoBasics.Asymmetric.generateNewPair();

    const {
      publicKeyPEM: contentKeyPublicPEM,
      privateKeyPEM: contentKeyPrivatePEM,
    } = newKeyPair;

    const contentKeyFingerprint =
      await DOCryptoBasics.Fingerprint.forPEM(contentKeyPublicPEM);

    const lockedContentKey = await DOCrypto.D1.encryptWithLockedKey(
      Utf8.toUintArray(contentKeyPrivatePEM),
      userPublicKeyFingerprint,
      userPublicKeyPEM,
      1,
    );

    const dataToSign = uintArrayConcat([
      // See https://github.com/bloom/DayOne-Apple/blob/fe95266936656eebb0c65e7add7e554ca68aa823/core/DOCore/DOCore/DOWebEncryptionTypes.swift#L102
      Utf8.toUintArray(contentKeyPublicPEM),
      lockedContentKey,
    ]);

    const signature = await DOCryptoBasics.Asymmetric.Private.signArrayBuffer({
      userPrivateKey: userPrivateKey,
      buffer: dataToSign,
    });

    // Build cryptoKeys object

    const cryptoKey: CryptoKeysV4 = {
      userKey: {
        fingerprint: userPublicKeyFingerprint,
        publicKey: userPublicKeyPEM,
        encryptedPrivateKey: userEncryptedPrivateKey,
      },
      contentKeys: [
        {
          publicKey: contentKeyPublicPEM,
          signature: signature,
          encryptedPrivateKey: toBase64(lockedContentKey),
          fingerprint: contentKeyFingerprint,
        },
      ],
      activeContentKeyFingerprint: contentKeyFingerprint,
    };
    this.contentKeysRepository.putCryptoKeyOnServer(cryptoKey);
    await this.contentKeysRepository.saveContentKeys(cryptoKey);
    return cryptoKey;
  }

  async generateMasterKey(userId: string) {
    // generate the master key and the key pair
    const masterKey = generateUserMasterKey(userId);
    return masterKey;
  }

  async saveMasterKey(masterKey: MasterKey, locationOfKey: LocationOfKey) {
    const keys = await Asymmetric.generateNewPair();
    await this.keysRepository.putMainKeyOnServer(
      masterKey,
      keys,
      locationOfKey,
    );

    // we persist the keys AFTER we try to put them on the server in case the server fails
    await this.validateAndStoreMasterKey(masterKey.wholeString);
    await this.unlockAllKeys();
  }

  async saveMasterKeyBackupLocation(locationOfKey: LocationOfKey) {
    await this.keysRepository.saveLocationOfKey(locationOfKey);
  }

  async validateAndStoreMasterKey(masterKeyString: string) {
    // Set up a new MasterKey object to validate the key
    const masterKey = new MasterKey(masterKeyString);
    await this.unlockAllKeys(masterKey);
    await this.kvStore.set(
      "master_key_string",
      masterKeyString,
      Utf8.toUintArray,
    );
  }

  subscribeToMasterKeyString(
    callback: (masterKeyString: string | undefined) => void,
  ) {
    return this.kvStore.subscribe(
      "master_key_string",
      callback,
      Utf8.fromBufferSource,
    );
  }

  subscribeToActiveKey(
    callback: (activeKey: UserKeysDBRow | undefined) => void,
  ) {
    return this.keysRepository.subscribetoActiveKey(callback);
  }

  async getActiveKeys() {
    return this.keysRepository.getMainKey();
  }

  async getMasterKeyString(): Promise<string | undefined> {
    return this.kvStore.get("master_key_string", Utf8.fromBufferSource);
  }

  async getMainUserPrivateKey() {
    const userPrivateKey = await this.keysRepository.getMainPrivateKey();
    return userPrivateKey;
  }

  async getMainUserPublicKey() {
    return await this.keysRepository.getMainPublicKey();
  }

  async getAllUserPrivateKeys() {
    return this.keysRepository.getAllUnlockedKeys();
  }

  get privateKey() {
    return this.keysRepository.getMainPrivateKey();
  }

  async canDecryptOrEncrypt() {
    return this.keysRepository.canDecryptOrEncrypt();
  }
}
