import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { Avatar } from "@/components/SharedJournals/Avatar";
import { journalColorName } from "@/data/models/JournalFns";
import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";
import { viewStates } from "@/view_state/ViewStates";

type Props = {
  creatorId: string;
};

export const SHARED_ENTRY_INFO_HEIGHT = "72";

export const EntryAuthorInfo: React.FC<Props> = ({ creatorId }) => {
  const { __ } = useI18n();
  const globalEntryID = viewStates.primary.selectedGlobalEntryID;
  const entryJournal = viewStates.primary.getJournalById(
    globalEntryID?.journal_id,
  );
  const user = useSharedJournalParticipant(creatorId);

  if (!user || !entryJournal?.is_shared) {
    return null;
  }

  return (
    <Flex
      id="creator-info"
      sx={{
        justifyContent: "flex-start",
        gap: 3,
        pl: 0,
        pt: 0,
        pb: 5,
      }}
    >
      <button
        aria-label={__("View user")}
        sx={{ p: 0, flexShrink: 0 }}
        onClick={() => {
          viewStates.modalRouter.showSharedJournalMember(
            globalEntryID?.journal_id || "",
            user.user_id,
          );
        }}
      >
        <Avatar user={user} size={2} />
      </button>
      <div
        sx={{
          fontSize: 1,
          lineHeight: 3,
          overflow: "hidden",
        }}
      >
        <div sx={{ fontWeight: "heading" }}>{user?.name}</div>
        <div
          sx={{
            color: entryJournal ? journalColorName(entryJournal) : null,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {entryJournal?.name}
        </div>
      </div>
    </Flex>
  );
};
