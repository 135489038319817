import { IndexableType } from "dexie";
import { SearchOptions, SearchResult } from "minisearch";

import {
  ActivityFilterOptions,
  CreationDeviceFilterOptions,
  PlaceFilterOptions,
  PromptFilterOptions,
  TemplateFilterOptions,
  WeatherFilterOptions,
  MusicFilterOptions,
  MediaFilterOptions,
} from "@/components/Search/FilterPills";
import {
  Activity,
  EntryDBRow,
  GlobalEntryID,
  Weather,
} from "@/data/db/migrations/entry";
import { TemplateDBRow } from "@/data/db/migrations/template";
import { Prompt } from "@/data/repositories/PromptsAPI";
import { EntryMusic } from "@/data/repositories/V2API";

export interface SearchWorkerIncomingMessage {
  type: "index" | "search" | "setFilter" | "refreshIndex";
  query?: string | null;
  options?: any;
  filter?: FilterableValues;
}

export interface SearchWorkerEntriesUpdatedMessage {
  type: "updating" | "creating" | "deleting";
  changes: {
    entry: EntryDBRow;
    mods?: Partial<EntryDBRow>;
    keys?: IndexableType;
  };
}

export interface SearchWorkerOutgoingMessage {
  type:
    | "updateFilters"
    | "indexEmpty"
    | "indexingStart"
    | "indexingDone"
    | "searchResults";
  favoriteEntries?: FavoriteEntry[];
  tagCounts?: { [key: string]: number };
  prompts?: PromptFilterOptions[];
  templates?: TemplateFilterOptions[];
  creationDevices?: CreationDeviceFilterOptions[];
  weather?: WeatherFilterOptions[];
  music?: MusicFilterOptions[];
  placeNames?: PlaceFilterOptions[];
  activities?: ActivityFilterOptions[];
  results?: SearchResult[];
  media?: MediaFilterOptions[];
}

export interface FilterableValues {
  journalIds: GlobalEntryID["journal_id"][];
  // we use string[] instead of EntryDBRow["tags"] which is string[]|string to simplify the filter logic
  entryTags: string[];
  minDate: number | null;
  maxDate: number | null;
  placeNames: string[];
  prompts: string[];
  templates: string[];
  creationDevices: string[];
  weather: string[];
  music: string[];
  activities: string[];
  isStarred: boolean | null;
  hasCheckbox: boolean | null;
  media: string[];
}

export type IndexDocument = {
  search_id: string;
  globalId: GlobalEntryID;
  date: number;
  entryTags: string[];
  entryLocation: EntryDBRow["location"];
  body: string;
  isStarred: boolean;
  hasCheckbox: boolean;
  isShared: boolean;
  prompt: Prompt;
  template: TemplateDBRow;
  creationDevice: string;
  weather: Weather;
  music: EntryMusic;
  activity: Activity;
  media: string[];
};

// type - contains GlobalEntryId, isShared
export type FavoriteEntry = {
  globalId: GlobalEntryID;
  isShared: boolean;
};

export type ReturnedResults = {
  globalId: GlobalEntryID;
  entryTags: string[];
  date: EntryDBRow["date"];
  entryLocation: EntryDBRow["location"];
  prompt: Prompt | null;
  template: TemplateDBRow | null;
  creationDevice: string;
  weather: Weather;
  music: EntryMusic;
  activity: Activity;
  isStarred: boolean;
  hasCheckbox: boolean;
  media: string[];
};

export type IndexStatus = "INITIAL" | "INDEXING" | "DONE" | "DOWNLOADING";

export const NO_FILTER: FilterableValues = {
  journalIds: [],
  entryTags: [],
  minDate: null,
  maxDate: null,
  placeNames: [],
  prompts: [],
  templates: [],
  creationDevices: [],
  weather: [],
  music: [],
  activities: [],
  isStarred: null,
  hasCheckbox: null,
  media: [],
};

export const baseSearchOptions: SearchOptions = {
  prefix: true,
  fields: ["body"],
  combineWith: "AND",
};
