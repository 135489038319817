import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { uintArrayConcat } from "@/crypto/utils";
import { DODexie } from "@/data/db/dexie_db";

export class SecureKeyValueStore {
  _symmetricKey: Promise<CryptoKey> | null = null;
  constructor(private db: DODexie) {}

  async get<T>(
    key: string,
    deserialize: (value: BufferSource) => T | Promise<T>,
  ): Promise<T | undefined> {
    key = key.toLowerCase();
    const result = await this.db.kv.get(key);
    if (result && result.value.encrypted) {
      const value = await this.decryptValue(result.value.data);
      return await deserialize(value);
    }
  }

  async set<T>(
    key: string,
    value: T,
    serialize: (value: T) => Promise<Uint8Array> | Uint8Array,
  ) {
    key = key.toLowerCase();
    const encrypted = await this.encryptValue(await serialize(value));
    await this.db.kv.put({ key, value: { data: encrypted, encrypted: true } });
  }

  subscribe<T>(
    key: string,
    callback: (value: T | undefined) => void,
    deserialize: (value: BufferSource) => T | Promise<T>,
  ) {
    const sub = liveQuery(() => {
      return this.get<T>(key, deserialize);
    }).subscribe(callback, (err) => {
      console.error(err);
      Sentry.captureException(
        new Error(
          `[Secure Store] Failed while subscribing to changes to the secure KV value ${key}`,
        ),
      );
    });
    return () => {
      sub.unsubscribe();
    };
  }

  async setSeedForSymmetricKey(userId: string) {
    // Store the user-id in the kv store so we can derive the symmetric key from it later
    // This won't stop anyone who knows what they're doing. But it's an extra layer of
    // protection against casual snooping.
    // I'm on-purpose not calling the key "seed" or anything like that to make it less obvious
    // what it's for, in case an attacker just got a data dump and no access to the web source.
    // I know, I know, all they'd have to do is load up dayone.me and look at the source. 🤦‍♂️
    // We do this instead of getting the ID directly off of the "me" object, so that we can
    // Save the user's auth token in the secure store before we save the user object.
    // We do that, in turn, because some parts of the app react to the presence of the user object,
    // assuming that the auth token is available when the user object is set.
    // By allowing the seed for the secure store to be set before the user object is set,
    // we can ensure that the auth token is available by the time the user object is set.
    await this.db.kv.put({ key: "user-id", value: { data: userId } });
  }

  private async getSeedForSymmetricKey(): Promise<string | undefined> {
    // Check to see if the seed is saved in the kv store as "user-id".
    // See note above about why we do this instead of getting the ID directly off of the "me" object.
    const valueFromUserId = (await this.db.kv.get("user-id"))?.value;
    if (valueFromUserId && valueFromUserId.data) {
      return valueFromUserId.data;
    } else {
      // For backwards compatibility, check the "me" user for the ID when the "user-id" key isn't found.
      // This handles users who haven't logged out and back in since the seed was changed to be stored in the kv store.
      const me = await this.db.users.get("me");
      if (me) {
        // If we found the seed on the user object, put it in the kv store for next time.
        await this.setSeedForSymmetricKey(me.id);
        return me.id;
      } else {
        throw new Error("No seed for secure KV store found");
      }
    }
  }

  private async encryptValue(value: Uint8Array) {
    const key = await this.getSymmetricKey();
    if (!key) throw new Error("No symmetric key found");
    const { iv, encrypted } = await Symmetric.encryptToUint8ArrayWithIV(
      key,
      value,
    );
    return uintArrayConcat([iv, encrypted]);
  }

  private async decryptValue(value: ArrayBuffer) {
    if (value.byteLength > 12) {
      const iv = value.slice(0, 12);
      const encrypted = value.slice(12, value.byteLength);
      return await Symmetric.decryptBufferAndIV(
        iv,
        await this.getSymmetricKey(),
        encrypted,
      );
    } else {
      throw new Error("Secure store value is not valid");
    }
  }

  private async getSymmetricKey() {
    if (this._symmetricKey === null) {
      const seed = await this.getSeedForSymmetricKey();
      if (!seed) {
        throw new Error("No seed for secure KV store found");
      }
      const newKey = Symmetric.Key.newFromUserId(seed);
      this._symmetricKey = newKey;
      return newKey;
    }
    return this._symmetricKey;
  }
}
