import { Icon, dragHandle } from "@wordpress/icons";
import { DragControls } from "framer-motion";

type ReorderIconProps = {
  dragControls: DragControls;
};

const ReorderIcon: React.FC<ReorderIconProps> = ({ dragControls }) => {
  return (
    <Icon
      icon={dragHandle}
      sx={{
        fill: "textPrimary",
        cursor: "grab",
      }}
      size={16}
      onPointerDown={(event) => dragControls.start(event)}
    />
  );
};

export default ReorderIcon;
