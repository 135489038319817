import { __ } from "@wordpress/i18n";

import { ItemGroup } from "@/components/ItemGroup/ItemGroup";
import { ColorMode } from "@/components/Settings/Appearance/ColorMode";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";

export const Appearance: React.FC = () => {
  return (
    <SettingsPanel header={<span>{__("Appearance")}</span>}>
      <ItemGroup addMargin={true}>
        <ColorMode />
      </ItemGroup>
    </SettingsPanel>
  );
};
