export const journalColorValues = {
  dayOneBlue: "#44C0FF",
  dayOneBlueLight: "#7AD2FF",
  dayOneBlueDark: "#0087CC",

  honey: "#FFC107",
  honeyLight: "#FFD65C",
  honeyDark: "#A87E00",

  // Blue
  journalBlue: "#3398DB",
  journalBlueLight: "#5AACE2",
  journalBlueDark: "#1E75AE",

  fire: "#FF983B",
  fireLight: "#FFB470",
  fireDark: "#CC6100",

  // Deep blue
  charcoal: "#333B40",
  charcoalLight: "#778892",
  charcoalDark: "#292F33",

  hotPink: "#E91E63",
  hotPinkLight: "#F06A98",
  hotPinkDark: "#BA124B",

  aqua: "#16D6D9",
  aquaLight: "#38E8EB",
  aquaDark: "#0E8E90",

  watermelon: "#FF7EB0",
  watermelonLight: "#FFA3C7",
  watermelonDark: "#EB005B",

  pewter: "#95A5A6",
  pewterLight: "#AFBBBB",
  pewterDark: "#66797A",

  lavender: "#C27BD2",
  lavenderLight: "#D19CDD",
  lavenderDark: "#A741BE",

  stone: "#607D8B",
  stoneLight: "#809BA7",
  stoneDark: "#4B626D",

  magenta: "#CF2F89",
  magentaLight: "#DD69AA",
  magentaDark: "#A6266E",

  green: "#2DCC71",
  greenLight: "#64DD98",
  greenDark: "#1F8E4F",

  salmon: "#FF6E6B",
  salmonLight: "#FF9694",
  salmonDark: "#E50500",

  tortilla: "#C9A286",
  tortillaLight: "#D6B9A3",
  tortillaDark: "#9F6C47",

  journalRed: "#E74C3C",
  journalRedLight: "#EE7E72",
  journalRedDark: "#BF2717",

  lime: "#CCDB18",
  limeLight: "#DEEB47",
  limeDark: "#858E10",

  iris: "#6A6DCD",
  irisLight: "#8C8FD9",
  irisDark: "#4C4FC3",
};
