import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Z_INDEX_SNACKBAR, dayOneBlue } from "@/styles/theme";
import {
  modalRouterViewState,
  primaryViewState,
} from "@/view_state/ViewStates";

export const UsageStatisticsBanner = observer(() => {
  const [showBanner, setShowBanner] = useState(false);

  const isLoggedIn = !!primaryViewState.user;

  useEffect(
    () =>
      d1Classes.usageStatisticsStore.subscribe(async () => {
        await d1Classes.usageStatisticsStore.shouldShowBanner().then((show) => {
          setShowBanner(show);
        });
      }),
    [],
  );

  // The banner will only be shown if we don't have an option set to allow or disallow usage statistics,
  // or if they are in a specific country which requires it.
  // We default to allow usage statistics so once they close this banner for the first time we set the option to true.
  // That way we have the option set and won't show the banner the next time whether they explicitly choose one or not.
  if (!showBanner) {
    return null;
  }

  return (
    <div
      sx={{
        position: "fixed",
        bottom: 0,
        zIndex: Z_INDEX_SNACKBAR,
        backgroundColor: "surface_light3_dark3",
        color: "textPrimary",
        width: "100%",
        py: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "shadow2",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          px: 4,
          "& p": {
            fontSize: 1,
            lineHeight: "1.5rem",
            maxWidth: "720px",
          },
        }}
      >
        <p>
          {__(
            "We collect information on how you interact with the app to optimize Day One's performance. If you prefer we not, you can stop sharing these usage statistics with us any time in Settings.",
          )}{" "}
          <a
            href="https://dayoneapp.com/privacy-policy/"
            target="_blank"
            sx={{ color: dayOneBlue, textDecoration: "underline" }}
          >
            {__("Learn More")}
          </a>
        </p>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            "& button": {
              maxWidth: "200px",
              height: "auto",
              justifyContent: "center",
            },
          }}
        >
          {isLoggedIn && (
            <Button
              variant="secondary"
              onClick={() => {
                d1Classes.usageStatisticsStore.set(true);
                setShowBanner(false);
                modalRouterViewState.openSettings("advanced");
              }}
            >
              {__("Settings")}
            </Button>
          )}
          {!isLoggedIn && (
            <Button
              variant="secondary"
              onClick={() => {
                d1Classes.usageStatisticsStore.set(false, true);
                setShowBanner(false);
              }}
            >
              {__("Don't Allow")}
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => {
              d1Classes.usageStatisticsStore.set(true, !isLoggedIn);
              setShowBanner(false);
            }}
          >
            {isLoggedIn ? __("Ok") : __("Allow")}
          </Button>
        </div>
      </div>
    </div>
  );
});

UsageStatisticsBanner.displayName = "UsageStatisticsModal";
