export type ImageErrorStates = "NOT_FOUND" | "LOW_RES" | "NO_ERROR";

export const calculateDimensions = (
  imageWidth: number,
  imageHeight: number,
  screenWidth: number,
  screenHeight: number,
) => {
  const marginValue = 30;
  screenWidth -= marginValue;
  screenHeight -= marginValue;
  // Calculate the aspect ratios
  const imageRatio = imageWidth / imageHeight;
  const screenRatio = screenWidth / screenHeight;

  let finalWidth, finalHeight;

  // If imageRatio > screenRatio, the image width will be equal to the screen width
  // and the height will be calculated based on the image's aspect ratio.
  if (imageRatio > screenRatio) {
    finalWidth = screenWidth;
    finalHeight = screenWidth / imageRatio;
  }
  // If imageRatio <= screenRatio, the image height will be equal to the screen height
  // and the width will be calculated based on the image's aspect ratio.
  else {
    finalHeight = screenHeight;
    finalWidth = screenHeight * imageRatio;
  }

  return { width: finalWidth, height: finalHeight };
};
