// A little local storage wrapper
// In particular, wrapping lthe local storage API allow us to block writes when we want
// to clear data and log out.

export class DOLocalStorage {
  private isShutDown = false;

  get<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (value == null) {
      return null;
    }
    return JSON.parse(value);
  }

  set<T>(key: string, value: T) {
    if (!this.isShutDown) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  remove(key: string) {
    if (!this.isShutDown) {
      localStorage.removeItem(key);
    }
  }

  shutDownAndClear() {
    this.isShutDown = true;
    localStorage.clear();
  }
}

export const localStorageWrapper = new DOLocalStorage();
