import {
  Icon,
  FlexItem,
  __experimentalDivider as Divider,
} from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { check, close } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { D1Modal } from "@/components/D1Modal";
import {
  InstagramConnection,
  getInstagramConnectionURL,
  openConfirmationWindow,
  EXPIRED_TOKEN_PREFIX,
} from "@/components/Settings/Account/Instagram";
import { Button } from "@/components/Settings/Button";
import { EditSection } from "@/components/Settings/EditSection";

type ManageProps = {
  connections: InstagramConnection[];
  handleClose: () => void;
};

export const InstagramManage: React.FC<ManageProps> = ({
  connections,
  handleClose,
}) => {
  const [unlink, setUnlink] = useState<string | null>(null);
  const [authURL, setAuthURL] = useState<string | undefined>(undefined);
  const { __ } = useI18n();

  useEffect(() => {
    getInstagramConnectionURL().then((url) => {
      setAuthURL(url);
    });
  }, []);

  const unlinkInstagram = async () => {
    const res = await d1Classes.fetchWrapper.fetchAPI(
      `/instagram/unlinkInsta?id=${unlink}`,
      {
        method: "DELETE",
      },
    );
    if (res.ok) {
      setUnlink(null);
      d1Classes.keyValueStore.set("instagram-updated", "yes");
    }
  };

  return (
    <D1Modal
      title={__("Manage Instagram Connections")}
      onRequestClose={handleClose}
      sx={{ width: "420px" }}
    >
      {unlink && (
        <ConfirmDialog
          title={__("Confirm unlinking Instagram account")}
          isDestructive={true}
          handleAction={unlinkInstagram}
          message={sprintf(
            __(
              "Are you sure you want to unlink Instagram: @%s? Your journal will still exist, but will no longer be updated automatically.",
            ),
            connections.find((connection) => connection.instaUserId === unlink)
              ?.username,
          )}
          handleClose={() => {
            setUnlink(null);
          }}
        />
      )}
      {connections?.map((connection) => {
        return (
          <>
            <EditSection key={connection.instaUserId}>
              <FlexItem
                sx={{
                  flex: 1,
                  fontSize: 1,
                }}
              >
                <p sx={{ marginBottom: 2 }}>
                  <a
                    sx={{ textDecoration: "underline" }}
                    href={`https://instagram.com/${connection.username}`}
                    target="_blank"
                  >
                    @{connection.username}
                  </a>
                </p>
                <p sx={{ lineHeight: "30px" }}>
                  {connection.postCount}{" "}
                  {connection.postCount === 1 ? __("post") : __("posts")}
                </p>
              </FlexItem>
              <FlexItem
                sx={{
                  fontSize: 1,
                  textAlign: "right",
                  "& svg": {
                    fill: "textPrimary",
                    position: "relative",
                    top: "4px",
                  },
                }}
              >
                {connection.accessToken.startsWith(EXPIRED_TOKEN_PREFIX) ? (
                  <>
                    <p sx={{ paddingRight: "12px", marginBottom: 2 }}>
                      <Icon icon={close} size={18} />
                      {__("Expired")}
                    </p>
                    <p sx={{ lineHeight: "30px" }}>
                      <Button
                        onClick={async () => {
                          const url =
                            authURL || (await getInstagramConnectionURL());
                          openConfirmationWindow(url);
                        }}
                      >
                        {__("Reconnect")}
                      </Button>
                    </p>
                  </>
                ) : (
                  <>
                    <p sx={{ paddingRight: "12px", marginBottom: 2 }}>
                      <Icon icon={check} size={18} />
                      {__("Working")}
                    </p>
                    <p sx={{ lineHeight: "30px" }}>
                      <Button onClick={() => setUnlink(connection.instaUserId)}>
                        {__("Unlink")}
                      </Button>
                    </p>
                  </>
                )}
              </FlexItem>
            </EditSection>
            <Divider
              sx={{
                mx: 6,
                my: 2,
                color: "borderPrimary",
              }}
            />
          </>
        );
      })}
      <EditSection key="add-new">
        <FlexItem
          sx={{
            flex: 1,
            fontSize: 1,
          }}
        >
          <p>{__("Add another Instagram account")}</p>
        </FlexItem>
        <FlexItem
          sx={{
            fontSize: 1,
            textAlign: "right",
            "& svg": {
              fill: "textPrimary",
              position: "relative",
              top: "4px",
            },
          }}
        >
          <p>
            <Button
              onClick={async () => {
                const url = authURL || (await getInstagramConnectionURL());
                openConfirmationWindow(url);
              }}
            >
              {__("Connect")}
            </Button>
          </p>
        </FlexItem>
      </EditSection>
    </D1Modal>
  );
};
