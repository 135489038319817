export async function newSymmetricKeyFromUserId(
  userID: string,
  givenSalt?: string,
  iterations = 10_000, // used by old Google Drive backup key
) {
  const encoder = new TextEncoder();
  const buffer = encoder.encode(`D0-${userID}`);

  const salt = givenSalt
    ? encoder.encode(givenSalt)
    : encoder.encode(`salty-${userID}-saline`); // used by old Google Drive backup key

  const importedKey = await crypto.subtle.importKey(
    "raw",
    buffer,
    { name: "PBKDF2" },
    false,
    ["deriveKey"],
  );

  // Use PBKDF2 to derive a key from the password.
  const derivedKey = await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt,
      iterations,
      hash: "SHA-256",
    },
    importedKey,
    { name: "AES-GCM", length: 256 },
    false,
    ["encrypt", "decrypt"],
  );

  return derivedKey;
}
