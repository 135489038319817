import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { NOTIFICATION_ROW_HEIGHT } from "@/components/Notifications/NotificationsItem";
import { Avatar } from "@/components/SharedJournals/Avatar";
import { JournalDBRow, PendingApproval } from "@/data/db/migrations/journal";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";
import { ApprovalItemViewState } from "@/view_state/ApprovalItemViewState";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type NotificationDisplayProps = {
  dimmed: boolean;
  journal: JournalDBRow | undefined;
  approval: PendingApproval;
  children: React.ReactNode;
  message: string;
};

export const PendingApprovalView = observer(
  ({ item }: { item: ApprovalItemViewState }) => {
    const { __ } = useI18n();
    const approval = item.approval;
    const journal = primaryViewState.getJournalById(approval.journal_id);
    const id = approval.user_requesting_shared_journal_access.id;
    const user = useSharedJournalParticipant(id, true);
    const approveRequest = async () => {
      if (!journal) return;
      await item.approve(journal);
      analytics.tracks.recordEvent(EVENT.buttonTap, {
        button_identifier: "journalParticipationRequest_approve",
      });
    };

    useEffect(() => {
      if (item.status === "APPROVED") {
        viewStates.snackbar.newMessage(
          sprintf(
            __("%s is now a member of your journal %s"),
            user?.name,
            journal?.name,
          ),
        );
      }
    }, [item.status]);

    return (
      <PendingApprovalDisplay
        dimmed={item.status === "DECLINED"}
        journal={journal}
        message={__(`wants to join ${journal?.name || "your journal"}`)}
        approval={approval}
      >
        {item.status !== "DECLINED" ? (
          <div
            sx={{
              display: "flex",
              justifyContent: "stretch",
              gap: 2,
              mt: 2,
              width: "100%",
              "&& > button": {
                flexGrow: 1,
                justifyContent: "center",
              },
            }}
          >
            <>
              <Button
                disabled={item.actionTaken}
                sx={{
                  "&.components-button": {
                    border: "none",
                    bg: "surfaceHover",
                    color: "primary",
                  },
                  "&.components-button:hover": {
                    color: "dayOneBlue",
                  },
                }}
                onClick={() => {
                  analytics.tracks.recordEvent(EVENT.buttonTap, {
                    button_identifier: "journalParticipationRequest_deny",
                  });
                  item.decline();
                }}
                aria-label={
                  item.status === "DECLINING"
                    ? sprintf(
                        __("Declining %s"),
                        approval.user_requesting_shared_journal_access.name,
                      )
                    : sprintf(
                        __("Decline %s"),
                        approval.user_requesting_shared_journal_access.name,
                      )
                }
              >
                {
                  // If the user is in the process of approving
                  // the request, show a different label.
                  item.status === "DECLINING"
                    ? __("Declining...")
                    : __("Decline")
                }
              </Button>

              <Button
                disabled={!journal || item.actionTaken}
                variant="primary"
                onClick={approveRequest}
                aria-label={
                  item.status === "APPROVING"
                    ? sprintf(
                        __("Approving %s"),
                        approval.user_requesting_shared_journal_access.name,
                      )
                    : item.status === "APPROVED"
                      ? sprintf(
                          __("%s approved"),
                          approval.user_requesting_shared_journal_access.name,
                        )
                      : sprintf(
                          __("Approve %s"),
                          approval.user_requesting_shared_journal_access.name,
                        )
                }
              >
                {item.status === "APPROVING"
                  ? "Approving..."
                  : item.status === "APPROVED"
                    ? "Approved"
                    : "Approve"}
              </Button>
            </>
          </div>
        ) : (
          <div
            sx={{
              fontSize: 0,
              mt: 2,
              mb: 4,
            }}
          >
            {__("You declined this request.")}
          </div>
        )}
        {item.status === "ERROR" && (
          <div
            sx={{
              mt: -3,
              textAlign: "center",
              width: "100%",
              color: "red",
              fontSize: 0,
            }}
          >
            {__("Error with request:")}
            {item.errorMessage}
          </div>
        )}
      </PendingApprovalDisplay>
    );
  },
);

const PendingApprovalDisplay = observer(
  ({
    approval,
    journal,
    children,
    dimmed,
    message,
  }: NotificationDisplayProps) => {
    const id = approval.user_requesting_shared_journal_access.id;
    const user = useSharedJournalParticipant(id, true);
    const loading = !user;
    const date = approval.created_at;

    return (
      <li
        id={id}
        className={
          // We need both the "unread/read" and "loading/loaded" classes
          // so that we get proper rounding on groups of unread notifications
          // after load, and proper rounding on the groups of grey boxes when
          // loading, regardless of unread status.
          // These classes are used by CSS selectors in the parent, NotificationsModal.
          ["read", loading ? " loading" : " loaded"].join(" ")
        }
        sx={{
          // hide the fact that this is a list item
          listStyle: "none",
          mx: 0,
          width: "100%",
          px: 2,
          py: 2,
          // if the notification is dimmed, make it transparent
          opacity: dimmed ? 0.5 : 1,
          transition: "opacity 400ms",
        }}
      >
        <OuterWrapper
          backgroundColor={loading ? "surface_light2_dark2" : "transparent"}
        >
          {!loading ? (
            <>
              <AvatarDisplay user={user} />
              {/* Two rows of text */}
              <div
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                {/* Top row, notification description */}
                <div
                  sx={{
                    fontSize: 1,
                    lineHeight: 2,
                    textAlign: "left",
                    wordBreak: "break-word",
                    display: "-webkit-box",
                    WebkitLineClamp: `${2}`,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden  ",
                  }}
                >
                  <strong>{user.name}</strong> {message}
                </div>
                {/* Bottom row, metadata about the notification */}
                <div sx={{ fontSize: "0.8rem", mt: 1, display: "flex" }}>
                  {journal && (
                    <>
                      <span
                        sx={{
                          color: journal.color,
                          maxWidth: "13em",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "inline-block",
                        }}
                      >
                        {journal.name}
                      </span>{" "}
                      -{" "}
                    </>
                  )}
                  <span sx={{ color: "textTertiary" }}>
                    {new Date(date).toLocaleString(undefined, {
                      weekday: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </span>
                </div>
                {children}
              </div>
            </>
          ) : (
            "Loading..."
          )}
        </OuterWrapper>
      </li>
    );
  },
);

function AvatarDisplay({ user }: { user: JournalParticipantDBRow }) {
  return (
    <div
      sx={{
        mt: 1,
        position: "relative",
        flexShrink: 0,
        "& > svg, & .reaction-icon": {
          border: "3px solid",
          borderColor: "surface_light1_dark5",
        },
        "& > svg": {
          width: "22px",
          height: "22px",
          position: "absolute",
          bottom: "-4px",
          right: "12px",
          borderRadius: "50%",
        },
      }}
    >
      <Avatar
        user={user}
        size={2}
        sx={{
          border: 0,
          mr: 3,
        }}
      />
    </div>
  );
}

function OuterWrapper({
  children,
  backgroundColor,
}: {
  children: React.ReactNode;
  backgroundColor: string;
}) {
  return (
    <div
      sx={{
        display: "flex",
        width: "100%",
        transition: "all 400ms",
        my: 0,
        textAlign: "left",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        minHeight: NOTIFICATION_ROW_HEIGHT,
        height: "auto",
        backgroundColor: backgroundColor,
        ":focus": {
          position: "relative",
        },
      }}
    >
      {children}
    </div>
  );
}
