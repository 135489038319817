import { select } from "@wordpress/data";
import { applyFormat, remove, RichTextValue } from "@wordpress/rich-text";

import { HASHTAG_REGEX } from "@/data/repositories/TagParser";

export const formatIsRegistered = (formatName: string) => {
  // @ts-ignore @todo not sure if this type can be updated
  const registeredFormats = select("core/rich-text").getFormatTypes();
  return !!registeredFormats.find(({ name }: any) => name === formatName);
};

// Quick check the text for the necessary characters.
const isTagMatching = (start: number, text: string, tag: string) => {
  const charactersBefore = text.slice(start - (tag.length + 1), start - 1);
  return charactersBefore === tag;
};

const isEndingCharacter = (start: number, text: string) => {
  const lastCharacter = text.slice(start - 1, start);
  return [" ", "."].includes(lastCharacter);
};

const isValidTag = (startIndex: number, endIndex: number, text: string) => {
  if (startIndex < 0 || endIndex < 0) {
    return false;
  }
  if (startIndex === 0) {
    return true;
  }
  if (text[startIndex - 1] === " ") {
    return true;
  }
};

const parseTag = (start: number, text: string, tag: string) => {
  let textBefore = text.slice(0, start - (tag.length + 1));
  let startIndex = textBefore.lastIndexOf(tag);
  // if this one is not valid try to look back we need the last one that is
  // either preceded by a space or is at the beginning of the line.
  while (startIndex > 0 && textBefore[startIndex - 1] !== " ") {
    textBefore = textBefore.slice(0, startIndex - tag.length);
    startIndex = textBefore.lastIndexOf(tag);
  }

  const invalid = { startIndex: -1, endIndex: -1 };

  // there's no previous tag present (i.e. this is the opening tag)
  if (startIndex === -1) {
    return invalid;
  }

  // if we've "opened" bold formatting with a double asterisk or double underscore, don't match italics
  if (
    ["*", "_"].includes(tag) &&
    textBefore.lastIndexOf(tag + tag) !== -1 &&
    startIndex == textBefore.lastIndexOf(tag + tag) + 1
  ) {
    return invalid;
  }

  const endIndex = start - tag.length - 1;

  // there's no content between the tags
  if (startIndex === endIndex - tag.length) {
    return invalid;
  }

  return { startIndex, endIndex };
};

export const matchFormattingTags = (
  value: RichTextValue,
  tags: string[],
  formatName: string,
  styles?: string,
) => {
  const { start, text } = value;

  if (!start) {
    return value;
  }

  // We want to parse the tag only if followed by a space or full stop.
  if (!isEndingCharacter(start, text)) {
    return value;
  }

  tags.every((tag) => {
    if (!isTagMatching(start, text, tag)) {
      return true; // try to match further tags
    }

    const { startIndex, endIndex } = parseTag(start, text, tag);

    if (isValidTag(startIndex, endIndex, text)) {
      value = applyFormat(
        value,
        // @ts-ignore
        { type: formatName, attributes: { style: styles || "" } },
        startIndex,
        endIndex,
      );
      value = remove(value, startIndex, startIndex + tag.length);
      value = remove(value, endIndex - tag.length, endIndex);
      value.start = value.text.length;
      value.end = value.text.length + 1;
    }

    return false; // don't try to match any other tags
  });

  return value;
};

export const formatHashTagsText = (text: string) => {
  const tags = [];
  let match;
  // We were running into cases where on the second call we weren't getting the expected match
  // Because it was starting part way through the string.
  // Now before we call the exec we reset the last index to 0 so we always start at the beginning.
  HASHTAG_REGEX.lastIndex = 0;
  while ((match = HASHTAG_REGEX.exec(text)) !== null) {
    const tag = {
      start: match.index,
      end: match.index + match[0].length,
      tag: match[0],
    };
    tags.push(tag);
  }

  return tags;
};
