import { DODexie } from "@/data/db/dexie_db";
import { EntryRepository } from "@/data/repositories/EntryRepository";

export const addJournalHooks = (
  db: DODexie,
  entryRepository: EntryRepository,
) => {
  db.table("journals").hook("updating", (mods, keys) => {
    if (
      "hide_all_entries" in mods &&
      typeof mods.hide_all_entries === "number"
    ) {
      entryRepository.updateEntriesHideAllEntriesStatus(
        mods.hide_all_entries,
        keys as string,
      );
    }
  });
};
