import { Notice } from "@wordpress/components";

type ErrorMessageProps = {
  message: string;
  className?: string;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  className,
}) => {
  return (
    <div
      className={className}
      sx={{
        display: "flex",
        alignItems: "flex-start",
        color: "#000",
        "& .components-notice": { width: "100%" },
        mb: 3,
      }}
    >
      <Notice status="error" isDismissible={false} sx={{ mx: "0px" }}>
        <h3>Error</h3>
        <p>{message}</p>
      </Notice>
    </div>
  );
};
