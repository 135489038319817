import { __experimentalItemGroup as WPItemGroup } from "@wordpress/components/";

type Props = {
  children: React.ReactNode;
  className?: string;
  isBordered?: boolean;
  isSeparated?: boolean;
  addMargin?: boolean;
};

export const ItemGroup: React.FC<Props> = ({
  children,
  className,
  isBordered = true,
  isSeparated = true,
  addMargin = false,
}) => {
  return (
    <WPItemGroup
      isBordered={isBordered}
      isSeparated={isSeparated}
      sx={{
        borderRadius: "lg",
        borderColor: "borderPrimary",
        mt: addMargin ? 4 : 0,
      }}
      className={className}
    >
      {children}
    </WPItemGroup>
  );
};
