import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { PrefilledEntryViewState } from "@/pages/prefilledUrl/PrefilledEntryViewState";

type Props = {
  state: PrefilledEntryViewState;
};
export const SaveEntry: React.FC<Props> = ({ state }) => {
  const { __ } = useI18n();
  return (
    <Button
      variant="primary"
      onClick={state.makeNewEntry}
      sx={{ width: "100%", justifyContent: "center" }}
    >
      {__("Create New Entry")}
    </Button>
  );
};
