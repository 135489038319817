import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { PendingApprovalList } from "@/components/Notifications/PendingApprovalList";
import { viewStates } from "@/view_state/ViewStates";

export const PendingApprovalModal = observer(
  ({ handleClose }: { handleClose: () => void }) => {
    const { __ } = useI18n();
    const pendingApprovals = viewStates.pendingApprovals.all;

    return (
      <D1Modal
        title={__("Journal requests")}
        onRequestClose={handleClose}
        sx={{ width: 390 }}
      >
        <PendingApprovalList
          inModal={true}
          pendingApprovals={pendingApprovals}
        />
      </D1Modal>
    );
  },
);
