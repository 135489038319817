import { FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Item } from "@/components/ItemGroup/Item";
import { GoogleConnect } from "@/components/Settings/Account/GoogleConnect";
import { Attribute } from "@/components/Settings/Attribute";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { Label } from "@/components/Settings/Label";
import { GoogleLogo } from "@/components/icons/GoogleLogo";
import { primaryViewState } from "@/view_state/ViewStates";

export const Google: React.FC = observer(() => {
  const user = primaryViewState.user;
  const userStore = d1Classes.userStore;

  const [showEdit, setShowEdit] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const hasGoogle = user!.credentials.includes("Google ID");
  const { __ } = useI18n();

  function handleClose() {
    setShowEdit(false);
  }

  const buttonLabel = hasGoogle ? __("Unlink") : __("Link Account");
  const buttonLabelAria = hasGoogle
    ? __("Unlink Google Account")
    : __("Link Google Account");

  const unlinkAccount = async () => {
    const res = await d1Classes.fetchWrapper.fetchAPI(
      "/users/google-auth/unlink",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (res.ok) {
      userStore.sync();
      setShowUnlink(false);
      setError(null);
    } else {
      setShowUnlink(false);
      setError(__("Error unlinking Google account. Please try again."));
    }
  };

  return (
    <>
      {showEdit && (
        <GoogleConnect title={buttonLabel} handleClose={handleClose} />
      )}

      {showUnlink && (
        <ConfirmDialog
          handleClose={() => setShowUnlink(false)}
          message={__("Are you sure you want to unlink your Google account?")}
          isDestructive={true}
          handleAction={unlinkAccount}
        />
      )}

      <Item>
        <FlexItem sx={{ flexGrow: 0, marginRight: 1 }}>
          <GoogleLogo />
        </FlexItem>
        <FlexItem
          sx={{
            flexGrow: 1,
            ml: 2,
            "& svg": {
              top: "4px",
              left: "-4px",
              position: "relative",
              fill: "textSecondary",
            },
          }}
        >
          <Label>{__("Google")}</Label>
          <Attribute
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {error || __("Sign in with your Google account")}
          </Attribute>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={buttonLabelAria}
            onClick={
              hasGoogle
                ? () => {
                    setShowUnlink(!showUnlink);
                  }
                : () => setShowEdit(!showEdit)
            }
            title={buttonLabel}
          />
        </FlexItem>
      </Item>
    </>
  );
});

Google.displayName = "Google";
