import { Utf8 } from "@/crypto/utf8";

export type AuthToken = {
  token: string;
  timestamp: number;
};

export const AUTH_TOKEN_EXPIRY_TIME = 15 * 60 * 1000; // 15 minutes

export const serializeAuthToken = (token: AuthToken) => {
  const tokenString = JSON.stringify(token);
  return Utf8.toUintArray(tokenString);
};

export const deserializeAuthToken = (token: BufferSource) => {
  const tokenString = Utf8.fromBufferSource(token);
  let json;
  try {
    json = JSON.parse(tokenString) as AuthToken;
  } catch (e) {
    json = { token: tokenString, timestamp: 0 };
  }
  return json;
};
