export const Plane: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1998 6.59995L16.8998 9.89995L18.6998 18.8C18.7998 19.1 18.6998 19.5 18.3998 19.7L17.6998 20.4C17.1998 20.9 16.2998 20.7 16.0998 20.1L13.4998 13.4L10.0998 16.8L10.2998 19C10.2998 19.3 10.1998 19.6 9.9998 19.8L8.8998 20.9L6.7998 17.4L3.2998 15.3L4.2998 14C4.4998 13.8 4.7998 13.7 5.0998 13.7L7.2998 13.9L10.6998 10.5L3.8998 7.89995C3.2998 7.59995 3.0998 6.79995 3.5998 6.29995L4.2998 5.59995C4.4998 5.39995 4.8998 5.29995 5.1998 5.29995L14.0998 7.09995L17.3998 3.79995C18.1998 2.99995 19.3998 2.99995 20.1998 3.79995V3.79995C20.9998 4.59995 20.9998 5.79995 20.1998 6.59995V6.59995Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
