import xss from "xss";

import { hasUnicode, stringSlice, stringToArray } from "@/utils/unicode";

const maxInitials = 2;

export type Direction = "forward" | "backward";
export const BACKWARD: Direction = "backward";
export const FORWARD: Direction = "forward";

export const getInitials = (name: string): string => {
  // Split the name into tokens using space as the separator
  const tokens = name?.trim().split(/\s+/);

  if (tokens.length === 1) {
    // If there's only one word, just take the first N characters
    return tokens[0].substring(0, maxInitials).toLocaleUpperCase();
  } else {
    // Extract the first character from the first word
    const firstInitial = tokens[0].charAt(0);

    // Extract the first character from the last word
    const lastInitial = tokens[tokens.length - 1].charAt(0);

    return (firstInitial + lastInitial).toLocaleUpperCase();
  }
};

// truncates at character boundary
export function truncateString(
  longString: string,
  maxLength: number,
  omission = "...",
) {
  if (typeof longString !== "string") {
    return "";
  }
  const stringLength = stringSplit(longString, "").length;

  if (stringLength <= maxLength) {
    return longString;
  }
  maxLength = maxLength - omission.length;

  const length = Math.max(maxLength, 0);
  const truncated = stringSplit(longString, "").slice(0, length).join("");

  return truncated + omission;
}

export const highlightMarkedTerms = (text: string, backgroundColor: string) => {
  const regex = /<mark>(.*?)<\/mark>/gi;
  return text.replace(regex, (match, content) => {
    return `<mark style="background-color: ${backgroundColor};">${content}</mark>`;
  });
};

export const sanitizeText = (text: string) => {
  return xss(text, {
    onIgnoreTag: () => " ",
    whiteList: {},
  });
};

export function reverseString(str: string) {
  // Using special string split method to correctly handle multi-length characters
  return stringSplit(str, "").reverse().join("");
}

export function getWords(text: string) {
  return text
    .trim()
    .split(/\s+/)
    .map((word) => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
}

export function findWordBoundary(
  text: string,
  start: number,
  count = 1,
  direction: Direction = "forward",
): number {
  if (direction === FORWARD) {
    let index = start;
    for (let i = 0; i < count; i++) {
      const nextBoundary = text.slice(index).search(/\s/);
      if (nextBoundary === -1) {
        return text.length;
      }
      index += nextBoundary + 1;
    }
    return index;
  } else if (direction === BACKWARD) {
    const reversedText = reverseString(text);
    const reversedStart = text.length - start;
    return (
      text.length -
      findWordBoundary(reversedText, reversedStart, count, FORWARD)
    );
  }
  return -1;
}

// Based on lodash's split method but uses the newer Intl.Segmenter if available instead
const stringSplit = (string: string, separator: string, limit?: number) => {
  limit = !limit ? 4294967295 : limit >>> 0;
  if (!limit) {
    return [];
  }
  if (string && !separator) {
    if (Intl.Segmenter !== undefined) {
      const stringAsArray = Array.from(
        new Intl.Segmenter().segment(string),
      ).map(({ segment }) => segment);
      return stringAsArray.slice(0, limit);
    } else if (hasUnicode(string)) {
      return stringSlice(stringToArray(string), 0, limit);
    }
  }
  return string.split(separator, limit);
};
