import { PropsWithChildren } from "react";

export const TEMPLATE_MODAL_FOOTER_HEIGHT = 53;

export const TemplateModalFooter: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <footer
      sx={{
        borderTop: "1px solid",
        borderTopColor: "borderPrimary",
        width: "100%",
        position: "sticky",
        height: `${TEMPLATE_MODAL_FOOTER_HEIGHT}px`,
        display: "flex",
        px: 3,
        py: 2,
        gap: 2,
        justifyContent: "flex-end",
      }}
    >
      {children}
    </footer>
  );
};
