import { ThemeUICSSObject } from "@theme-ui/css";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Thumbnail } from "@/components/EntryPreview/Thumbnail";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { EntryModel } from "@/data/models/EntryModel";
import { EntryPreviewMediaItem } from "@/data/stores/EntryStore";
import { makeDebugLogger } from "@/utils/debugLog";
import { CALENDAR_VIEW } from "@/view_state/PrimaryViewState";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

// Set the second argument to true to enable debug logging.
const debugLog = makeDebugLogger("EntryMedia.ts", false);

type LAYOUT_TYPE = "calendar" | "carousel" | "grid" | number;

export const getLayout = (
  thumbnailAmount: number,
  view: string,
  isMediaOnly = false,
): LAYOUT_TYPE => {
  if (view === CALENDAR_VIEW) {
    return "calendar";
  }
  if (isMediaOnly && thumbnailAmount >= 1) {
    return "carousel";
  } else if (thumbnailAmount >= 4) {
    return "grid";
  } else {
    return thumbnailAmount;
  }
};

type EntryMediaProps = {
  entry: EntryModel;
  entryJournal: JournalDBRow;
  mediaOnly?: boolean;
  bgColor?: string;
  isSelected?: boolean;
  className?: string;
  mediaAmount?: number;
};

export const getStyles = (
  layout: LAYOUT_TYPE,
  rest: number,
  bgColor = "surface_light1_dark1",
): ThemeUICSSObject => {
  const overlay: ThemeUICSSObject =
    rest > 0
      ? {
          "& div:last-of-type": {
            position: "relative",
          },
          ...(layout !== "carousel" && {
            "& div:last-of-type:after": {
              position: "absolute",
              content: `"+${rest}"`,
              inset: "0",
              display: "grid",
              placeItems: "center",
              color: "white",
              borderRadius: "sm",
              backgroundColor: (theme) => theme?.colors?.bgSkrim,
            },
          }),
          ...(layout === "carousel" && {
            "&:after": {
              content: `"+${rest}"`,
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // this is a small hack to make sure the overlay is not on top of the parent border on focus
              right: "2px",
              width: "50px",
              height: "60px",
              color: (theme) => theme.colors?.text,
              background: (theme) =>
                `linear-gradient(270deg, ${theme?.colors?.[bgColor] || bgColor} 70%, rgba(255, 255, 255, 0.00) 100%)`,
            },
          }),
        }
      : {};

  if (layout === "calendar") {
    return {
      maxWidth: "auto",
      display: "flex",
      gap: 1,
      "& div": { width: "80px", height: "80px" },
      ...overlay,
    };
  }
  if (layout === "grid") {
    return {
      display: "grid",
      gridTemplateColumns: "39px 39px",
      gridRow: "auto auto",
      gridColumnGap: "2px",
      gridRowGap: "2px",
      "& div": { width: "39px", height: "39px" },
      ...overlay,
    };
  }
  if (layout === "carousel") {
    return {
      display: "flex",
      gap: 1,
      alignItems: "center",
      minWidth: "60px",
      height: "60px",
      "& div": { width: "60px", height: "60px" },
      ...overlay,
    };
  } else {
    if (layout === 2) {
      return {
        display: "flex",
        flexWrap: "wrap",
        gap: "2px",
        "& div": { flexBasis: "100%", height: "39px" },
      };
    }
    if (layout === 3) {
      return {
        display: "flex",
        gap: "2px",
        flexWrap: "wrap",
        "& div": { flexBasis: "39px", height: "39px" },
        "& div:first-of-type": { flexBasis: "100%" },
      };
    }
  }

  return {
    // default to 1 thumbnail
    "& div": { width: "80px", height: "80px" },
  };
};

export const EntryMedia: React.FC<EntryMediaProps> = observer(
  ({
    entry,
    entryJournal,
    mediaOnly,
    bgColor,
    isSelected,
    className,
    mediaAmount = 5,
  }) => {
    const [organizedMoments, setOrganizedMoments] = useState<
      EntryPreviewMediaItem[] | null
    >(null);
    const [totalMediaCount, setTotalMediaCount] = useState(0);
    const { readContentsAsMarkdown } = viewStates.featureFlags;

    // This is the number of moments to show in the entry preview
    const momentAmount = mediaOnly ? mediaAmount : 4;

    useEffect(() => {
      d1Classes.entryStore
        .getOrderedMomentsAndSuggestions(entry, readContentsAsMarkdown)
        .then((objects) => {
          // We count the total number of moments before we splice the array
          setTotalMediaCount(objects.length);
          setOrganizedMoments(objects.splice(0, momentAmount));
        });
    }, [entry.entryContents]);

    const { selectedEntryView } = primaryViewState;
    const layout = getLayout(totalMediaCount, selectedEntryView, mediaOnly);

    // We calculate the number of extra moments to show in the entry preview
    // Adding 1 to the rest because we count the moment behind the overlay
    const rest = Math.max(totalMediaCount - momentAmount + 1, 0);
    const styles = getStyles(layout, rest, bgColor);

    if (totalMediaCount === 0) {
      return <></>;
    }

    debugLog("organizedMoments", organizedMoments);
    return (
      <div
        className={`entry-media-list ${className}`}
        sx={{
          minWidth: "80px",
          height: "80px",
          my: layout === "calendar" ? 2 : 0,
          ...styles,
        }}
      >
        {organizedMoments?.map((m) => {
          return (
            <Thumbnail
              key={m.id}
              moment={m.moment}
              type={m.type}
              journalColor={entryJournal?.color}
              invertedColor={isSelected}
            />
          );
        })}
      </div>
    );
  },
);

EntryMedia.displayName = "EntryMedia";
