import { Location } from "@/data/db/migrations/entry";

export const getLocationString = (location?: Location) => {
  const locationStr = [
    location?.localityName,
    location?.administrativeArea,
    location?.country,
  ]
    .filter((x) => x)
    .join(", ");

  return locationStr;
};
