import { Flex, FlexBlock, FlexItem } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useState } from "react";

import { D1Modal } from "@/components/D1Modal";
import { ENTER } from "@/components/Editor/utils/keycodes";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { Item } from "@/components/ItemGroup";
import { Reauth } from "@/components/Settings/Account/Reauth";
import { EditButtons } from "@/components/Settings/EditButtons";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { EditSection } from "@/components/Settings/EditSection";
import { Label } from "@/components/Settings/Label";
import { useNeedsAuthRefresh } from "@/data/hooks/userAccount/useNeedsAuthRefresh";
import { UserModel } from "@/data/models/UserModel";
import { UserStore } from "@/data/stores/UserStore";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";

type Props = {
  userStore: UserStore;
  user: UserModel | null;
  snackbar: SnackbarViewState;
};

export const Email: React.FC<Props> = ({
  userStore,
  user,
  snackbar,
}: Props) => {
  const [inputEmail, setInputEmail] = useState<string | null>(
    user?.email || null,
  );
  const inputHasChanged = inputEmail !== user?.email;
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showReauth, setShowReauth] = useState(false);

  const { __ } = useI18n();
  const { needsRefresh } = useNeedsAuthRefresh();

  const doSave = (e: React.MouseEvent) => {
    e.preventDefault();
    _save();
  };

  function _save() {
    const performUpdate = async () => {
      if (user && inputEmail && inputHasChanged) {
        await userStore.updateEmail(user, inputEmail);
        snackbar.newMessage(
          __("Email change requested! Check your email to confirm the change."),
        );
        handleClose();
      }
      setInputEmail(user?.email || null);
      setIsSaving(false);
    };
    setIsSaving(true);
    setError("");
    performUpdate();
  }

  function handleClose() {
    setShowEdit(false);
    setShowReauth(false);
  }

  function handleKeyUp(e: React.KeyboardEvent) {
    if (e.key === ENTER) {
      _save();
    }
  }

  return (
    <>
      {showEdit && !needsRefresh && (
        <D1Modal
          title={__("Edit Email")}
          onRequestClose={handleClose}
          sx={{ width: "420px" }}
        >
          <div sx={{ pr: 0 }}>
            <EditSection>
              <FlexItem sx={{ flex: 1 }}>
                <Label htmlFor="email">{__("Email")}</Label>
                {error && <ErrorMessage message={error} />}
                <Flex sx={{ mb: 2 }}>
                  <FlexBlock>
                    <TextControl
                      name="email"
                      id="email"
                      onKeyUp={handleKeyUp}
                      onChange={(value) => {
                        setInputEmail(value.trim());
                      }}
                      value={inputEmail ?? (user?.email || "")}
                      autoFocus
                    />
                  </FlexBlock>
                </Flex>
                <Flex>
                  <EditButtons
                    isSaving={isSaving}
                    doSave={doSave}
                    doCancel={handleClose}
                    attributeChanged={inputHasChanged}
                  />
                </Flex>
              </FlexItem>
            </EditSection>
          </div>
        </D1Modal>
      )}
      <Item>
        <FlexItem
          sx={{
            maxWidth: "calc(100% - 40px)",
            "& p": {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
        >
          <p>{user?.email}</p>
        </FlexItem>
        <FlexItem>
          <EditLinkButton
            label={__("Edit email")}
            onClick={() => {
              if (needsRefresh) {
                setShowReauth(true);
              } else {
                setShowEdit(!showEdit);
              }
            }}
          />
        </FlexItem>
      </Item>
      {showReauth && needsRefresh && (
        <D1Modal
          title={__("Login needed")}
          onRequestClose={() => setShowReauth(false)}
          sx={{ maxWidth: "500px" }}
        >
          <Reauth />
        </D1Modal>
      )}
    </>
  );
};
