import { ThemeUICSSObject } from "@theme-ui/css";

export const separatorStyles: ThemeUICSSObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    "hr.wp-block-separator": {
      width: "100%",
      my: 3,
      pt: 3,
      borderBottomColor: "textTertiary",
    },
  },
};
