import { DOCrypto } from "@/crypto/DOCrypto";
import { Grant } from "@/data/repositories/Syncables";
import { UnlockedUserPrivateKey } from "@/data/repositories/UserKeysRepository";

export const vaultKeyFromGrant = async (
  userPrivateKeys: UnlockedUserPrivateKey[],
  grant: Grant,
  journalId: string,
) => {
  const userPrivateKey = userPrivateKeys.find(
    (key) => key.fingerprint === grant.user_fingerprint,
  );
  if (!userPrivateKey) {
    throw new Error(
      `Could not find a private key for user ${
        grant.user_id
      } with fingerprint expected by grant ${
        grant.user_fingerprint
      }. Available keys: ${JSON.stringify(
        userPrivateKeys.map((k) => k.fingerprint),
      )}`,
    );
  }

  const keyData = await DOCrypto.Grant.decryptWithUserKey(
    userPrivateKey.key,
    grant.encrypted_vault_key,
    `decrypting vault key for journal ${journalId}`,
  );

  return crypto.subtle.importKey(
    "raw",
    new Uint8Array(keyData),
    { name: "AES-GCM", length: 256 },
    true,
    ["decrypt", "encrypt"],
  );
};
