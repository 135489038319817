import { ThemeUICSSObject } from "@theme-ui/css";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { PendingApprovalList } from "./PendingApprovalList";

import { d1Classes } from "@/D1Classes";
import { D1Modal } from "@/components/D1Modal";
import { MarkAllReadButton } from "@/components/Notifications/MarkAllReadButton";
import { NotificationsItem } from "@/components/Notifications/NotificationsItem";
import { Notification } from "@/data/db/migrations/notification";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  handleClose: () => void;
};

export const notificationsTitle: ThemeUICSSObject = {
  my: 2,
  mx: 4,
  fontSize: 1,
  lineHeight: 2,
  color: "textSecondary",
  fontWeight: "heading",
};

export const NotificationsModal: React.FC<Props> = observer(
  ({ handleClose }) => {
    const { __ } = useI18n();

    const notifications = primaryViewState.notifications;
    const pendingApprovals = viewStates.pendingApprovals.firstFew;

    useEffect(() => {
      d1Classes.notificationStore.markAllAsSeen();
    }, []);

    const closeModal = () => {
      const modal = document.querySelector(".notifications-modal");
      if (modal) {
        modal.classList.add("closing");
        modal.addEventListener("animationend", () => {
          handleClose();
        });
      }
    };

    const hasUnread = notifications.some((n) => n.read_date === -1);

    // Map the notifications by month so we can display them in monthed groups
    const notificationMonthsMap = notifications.reduce((map, notification) => {
      const date = new Date(notification.created_date);
      const month = date.getMonth();
      const year = date.getFullYear();
      const key = `${year}-${month}`;
      const currentValues = map.get(key) || [];
      return map.set(key, [...currentValues, notification]);
    }, new Map<string, Notification[]>());

    return (
      <D1Modal
        className="notifications-modal"
        title={__("Notifications")}
        onRequestClose={() => closeModal()}
        noLeftPadding
        sx={{
          width: ["100%", "100%", "384px"],
          maxHeight: "100dvh",
          height: "100dvh",
          borderRadius: 0,
          ml: 0,
          animation:
            "notifications-appear-animation 0.3s ease-in-out !important",

          "@media only screen and (max-width: 768px)": {
            animation:
              "notifications-appear-mobile-animation 0.3s ease-in-out !important",
          },

          "@keyframes notifications-appear-mobile-animation": {
            from: {
              opacity: "0",
              transform: "translate3d(0, 100%, 0);",
            },
            to: {
              opacity: "1",
              transform: "translate3d(0, 0, 0)",
            },
          },

          "@keyframes notifications-close-mobile-animation": {
            from: {
              opacity: "1",
              transform: "translate3d(0, 0, 0);",
            },
            to: {
              opacity: "0",
              transform: "translate3d(0, 100%, 0)",
            },
          },

          "@keyframes notifications-appear-animation": {
            from: {
              opacity: "0",
              transform: "translate3d(-100%, 0, 0);",
            },
            to: {
              opacity: "1",
              transform: "translate3d(0, 0, 0)",
            },
          },

          "@keyframes notifications-close-animation": {
            from: {
              opacity: "1",
              transform: "translate3d(0, 0, 0);",
            },
            to: {
              opacity: "0",
              transform: "translate3d(-100%, 0, 0)",
            },
          },
          "&.closing": {
            opacity: 0,
            animation:
              "notifications-close-animation 0.3s ease-in-out !important",
            "@media only screen and (max-width: 768px)": {
              animation:
                "notifications-close-mobile-animation 0.3s ease-in-out !important",
            },
          },
          ".components-modal__content": {
            pt: 0,
            pr: 0,
            pb: 0,
            overflow: "hidden",
          },
        }}
      >
        <div
          sx={{
            pt: 2,
            overflowY: "scroll",
            // Full viewport height minus the modal header and the mark all read button
            height: "calc(100dvh - 45px - 60px)",
          }}
        >
          {notifications.length === 0 && pendingApprovals.length === 0 && (
            <div sx={{ pl: 4, pt: 3, mb: 5 }}>
              {__("No notifications found")}
            </div>
          )}
          <ul
            sx={{
              pb: 2,
              "li.date-title": {
                ...notificationsTitle,
              },
            }}
          >
            <PendingApprovalList
              inModal={false}
              pendingApprovals={pendingApprovals}
            ></PendingApprovalList>

            {[...notificationMonthsMap.values()].map((notificationsForDate) => {
              const dateTitle = notificationsForDate.length ? (
                <li
                  key={new Date(notificationsForDate[0].created_date).getTime()}
                  className="date-title"
                >
                  {new Date(
                    notificationsForDate[0].created_date,
                  ).toLocaleDateString(undefined, {
                    month: "long",
                    year: "numeric",
                  })}
                </li>
              ) : null;
              const notifications = notificationsForDate.map(
                (notification, i) => (
                  <NotificationsItem
                    notification={notification}
                    key={notification.id}
                    isLast={i === notificationsForDate.length - 1}
                  />
                ),
              );
              return [dateTitle, ...notifications];
            })}
          </ul>
        </div>
        <MarkAllReadButton hasUnread={hasUnread} />
      </D1Modal>
    );
  },
);

NotificationsModal.displayName = "NotificationsModal";
