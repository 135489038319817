import { BlockInstance, createBlock } from "@wordpress/blocks";

import { TextNode } from "@/../types/rtj-format";
import { PARAGRAPH_BLOCK_ID } from "@/components/Editor/blocks/constants";
import { convertTextToMarkup } from "@/components/Editor/rtj2gb/format-node";

export const ZERO_WIDTH_NO_BREAK_SPACE = "&#xFEFF;";

// The end goal is to take anywhere there is a newline in a text
// node and add all the content before it to its own paragraph block
export const plainTextNodesToParagraphBlocks = (
  nodes: TextNode[],
): BlockInstance[] => {
  const blocks = [];
  let buffer = [];
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const splitNodes = node.text
      .split(/(\n)/)
      // Here we end up with the nodes looking like this
      // ["One", "\n", "two", "\n", "buckle my shoe", "\n"]
      .reduce<string[]>((acc, node) => {
        // If a node is only a new line we can take it and add it to the previous node
        if (node === "\n") {
          const lastItem = acc[acc.length - 1];
          const updatedNode = lastItem
            ? lastItem + "\n"
            : // Add ZERO_WIDTH_NO_BREAK_SPACE so it isn't empty
              ZERO_WIDTH_NO_BREAK_SPACE + "\n";

          return [...acc.slice(0, acc.length - 1), updatedNode];
        }
        return [...acc, node];
      }, [])
      // If there are any blank nodes left we can disgard them
      // This leaves the content looking like this
      // ["One\n", "two\n", "buckle my shoe\n"]
      .filter((t) => t !== "");

    for (const n of splitNodes) {
      // Add each node to a temporary buffer including the attributes if there are any
      if (node.attributes) {
        buffer.push({ attributes: { ...node.attributes }, text: n });
      } else {
        buffer.push({ text: n });
      }
      // If the node ends with a newline, is empty, or the last node
      // we create a new paragraph block to return with everything in the buffer
      if (n.endsWith("\n") || n.length < 1 || i === nodes.length - 1) {
        blocks.push(
          createBlock(PARAGRAPH_BLOCK_ID, {
            ...buffer[0].attributes?.line,
            ...buffer[0].attributes,
            content: buffer
              .map((node) => {
                return convertTextToMarkup(node);
              })
              .join(""),
          }),
        );
        buffer = [];
      }
    }
  }
  return blocks;
};
