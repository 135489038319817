import { SelectControl as WpSelectControl } from "@wordpress/components";
import { SelectControlProps } from "@wordpress/components/build-types/select-control/types";
import { WordPressComponentProps } from "@wordpress/components/ui/context/wordpress-component";
import { useEffect, useState } from "react";

import { isWindows } from "@/utils/environment";

export const SelectControl: React.FC<
  WordPressComponentProps<SelectControlProps, "select", false>
> = (props) => {
  const [windows, setWindows] = useState(false);

  const selectColor = windows ? "#000" : "textPrimary";
  useEffect(() => {
    isWindows().then((result) => {
      setWindows(result);
    });
  }, []);

  return (
    <div
      sx={{
        "&& label": {
          color: "textPrimary",
        },
        "&&&& .components-input-control__backdrop": {
          borderColor: "borderPrimary",
        },
        ".components-base-control__field": {
          mb: 0,
        },
        ".components-select-control": {
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          ".components-flex-item": {
            flexGrow: 1,
          },
        },
        "& .components-input-control__container": {
          justifyContent: "flex-end",
          flexGrow: 1,
          backgroundColor: `${windows ? "#fff" : "transparent"}`,
          "& > .components-input-control__suffix svg path": {
            fill: `${selectColor}`,
            color: `${selectColor}`,
          },
        },
        "&&& .components-select-control__input": {
          backgroundColor: "transparent",
          color: `${selectColor}`,
        },
      }}
    >
      <WpSelectControl {...props} />
    </div>
  );
};
