import { Button } from "@wordpress/components";
import { Icon } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";

import { D1Dropdown } from "@/components/D1Dropdown";
import { ExternalLink } from "@/components/Editor/icons/ExternalLink";
import { MomentModel } from "@/data/models/MomentModel";
import { dateToString } from "@/utils/date-helper";

type FailedMediaProps = { icon: JSX.Element; moment: MomentModel | null };

export const FailedMedia: React.FC<FailedMediaProps> = ({ icon, moment }) => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        bg: "surface_light3_dark3",
        color: "textPrimary",
        height: "300px",
        width: "100%",
        maxWidth: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        my: "14px",
        fontSize: 0,
      }}
    >
      <div>
        <Icon
          sx={{
            fill: "textPrimary",
            flex: "1",
            margin: "auto",
            display: "block",
          }}
          icon={icon}
          size={24}
        />
        <div>
          {__("File Not Found.")}
          <D1Dropdown
            className="missing-media-info"
            renderToggle={({ isOpen, onToggle }) => (
              <Button
                sx={{
                  "&&&": {
                    color: "primary",
                  },
                  p: 1,
                }}
                onClick={onToggle}
                aria-expanded={isOpen}
              >
                {__("Details.")}
              </Button>
            )}
            popoverProps={{ placement: "bottom-end" }}
            renderContent={() => (
              <div
                sx={{
                  px: 3,
                  minWidth: "400px",
                }}
              >
                <MissingMediaInfo moment={moment} />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export const MissingMediaInfo: React.FC<{ moment: MomentModel | null }> = ({
  moment,
}) => {
  const { __ } = useI18n();
  return (
    <div
      sx={{
        "&  ol": {
          width: "100%",
          "& li": {
            py: 1,
            color: "textSecondary",
          },
        },
        lineHeight: "body",
        fontSize: "0.825rem",
        m: 0,
      }}
    >
      {!moment ? (
        "No media information available. (no moment found in local database)"
      ) : (
        <ol>
          <li>
            {__("Device")}: {moment?.metadata.creation_device || "(unknown)"}
          </li>
          <li>
            {__("Type")}: {moment?.metadata.content_type}
          </li>
          <li>
            {__("Uploaded")}:{" "}
            {moment?.metadata.date !== 0
              ? dateToString(new Date(moment?.metadata.date))
              : __("(no date provided)")}
          </li>
          <li>
            {__("Entry Id")}: <span> {moment.entryId} </span>
            <a
              sx={{ color: "textSecondary" }}
              href={`dayone://view?entryId=${moment?.entryId}`}
            >
              <Icon
                sx={{
                  ml: 1,
                  fill: "textSecondary",
                }}
                icon={<ExternalLink />}
                size={10}
              />
            </a>
          </li>
          <details
            sx={{
              color: "textSecondary",
              "&& > summary": {
                listStyleType: "none",
                display: "flex",
                alignItems: "start",
              },
              "&& > summary::-webkit-details-marker": {
                display: "none",
              },
              "&& > summary::before": {
                display: "inline-block",
                height: "4px",
                width: "8px",
                mr: 2,
                mt: "-.1rem",
              },
              "&&:not([open]) > summary::before": {
                content: "url('/assets/arrow_drop_down.svg')",
              },
              "&&[open] > summary::before": {
                content: "url('/assets/arrow_up.svg')",
              },
            }}
          >
            <summary
              sx={{
                color: "textSecondary",
                ml: 1,
                py: 1,
                cursor: "pointer",
                fontWeight: 600,
              }}
            >
              {__("Advanced Details")}
            </summary>

            <li>
              Moment ID: <span> {moment.id} </span>
            </li>
            <li>
              MD5 body: <span> {moment.md5_body} </span>
            </li>
            <li>
              MD5 envelope: <span>{JSON.stringify(moment.md5_envelope)}</span>
            </li>
          </details>
          <li
            sx={{
              borderTop: "1px solid",
              borderColor: "borderPrimary",
              mt: 2,
              mx: -4,
              pb: "0 !important",
              px: 1,
            }}
          >
            <a
              target="_blank"
              rel="noopener"
              href="https://dayoneapp.com/guides/troubleshooting/data-loss-and-recovery-options/"
              sx={{
                display: "flex",
                alignItems: "center",
                py: 2,
                px: 3,
                transition: "background-color 0.2s ease",
                borderRadius: 2,
                color: "textPrimary",
                "&:hover": {
                  bg: "surface_light3_dark3",
                },
              }}
            >
              <Icon icon={<ExternalLink />} size={16} />
              <span
                sx={{
                  ml: 2,
                }}
              >
                {__("Open Help Guide")}
              </span>
            </a>
          </li>
        </ol>
      )}
    </div>
  );
};
