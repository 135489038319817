export const Wind: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11H10.061C10.829 11 11.597 10.707 12.182 10.121C13.354 8.94902 13.354 7.05002 12.182 5.87802C11.01 4.70602 9.111 4.70602 7.939 5.87802"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15H11.665C12.305 15 12.945 15.244 13.433 15.732C14.409 16.708 14.409 18.291 13.433 19.268C12.457 20.245 10.874 20.244 9.897 19.268"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 13.0002H18.657C19.257 13.0002 19.856 12.7712 20.314 12.3142C21.229 11.3992 21.229 9.91521 20.314 9.00021C19.399 8.08521 17.915 8.08521 17 9.00021"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
