import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { D1Modal } from "@/components/D1Modal";
import { UpgradeCTA } from "@/components/PremiumUpgrade/UpgradeCTA";
import { SharedJournals } from "@/components/icons/SharedJournals";
import { useDevice } from "@/data/hooks/LayoutProvider";

type ModalProps = {
  onClose: () => void;
};

export const SharedJournalsUpgradeModal = ({ onClose }: ModalProps) => {
  const { isMobile } = useDevice();
  const { __ } = useI18n();
  return (
    <D1Modal
      title=""
      onRequestClose={onClose}
      hideHeader
      sx={{
        width: "420px",
        maxWidth: "100%",
        height: isMobile ? "auto" : "482px",
        maxHeight: "100%",
        "& .components-modal__content, & .components-modal__content::before": {
          p: "0",
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: !isMobile ? "400px" : "auto",
          position: "relative",
          px: 6,
          py: 3,
          "& svg": { color: "primary" },
        }}
      >
        <SharedJournals />
        <h1
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            lineHeight: "32px",
            m: 2,
          }}
        >
          {__("Shared Journals")}
        </h1>
        <p sx={{ fontSize: 1, mb: 3 }}>
          {__(
            "Shared journals are a private space for your closest friends and family to connect and share memories.",
          )}
        </p>
        <p sx={{ color: "textSecondary", fontSize: 1 }}>
          {__(
            "A premium membership is required to create a new shared journal.",
          )}
        </p>
        <UpgradeCTA
          type="shared_journals"
          color="primaryPurple"
          withPricingDetails
        />
      </Flex>
    </D1Modal>
  );
};
