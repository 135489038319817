import { Theme } from "@theme-ui/core";
import { css, ThemeUIStyleObject } from "@theme-ui/css";

// Used with CSS property this allows themed style composition. See https://github.com/system-ui/theme-ui/issues/740#issue-577065927
export const themeCSS = (
  styles: ThemeUIStyleObject[] | ThemeUIStyleObject,
  theme: Theme,
) => {
  const flattenedStyles = Array.isArray(styles)
    ? styles.flat(Infinity)
    : [styles];

  return flattenedStyles.map((s) => css(s)(theme));
};
