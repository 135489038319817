import { store as blockEditorStore } from "@wordpress/block-editor";
import { cloneBlock, createBlock } from "@wordpress/blocks";
import { useDispatch, useSelect } from "@wordpress/data";

import {
  CHECKLIST_ITEM_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
} from "@/components/Editor/blocks/constants";

export default function useMerge(clientId: string) {
  const {
    getPreviousBlockClientId,
    getNextBlockClientId,
    getBlock,
    getSelectionStart,
    getSelectionEnd,
  } = useSelect(blockEditorStore, [clientId]);
  const {
    replaceBlocks,
    selectionChange,
    removeBlocks,
    updateBlockAttributes,
  } = useDispatch(blockEditorStore);

  return (forward: boolean) => {
    const currentBlock = getBlock(clientId);

    // Get the current cursor selection
    const selectionStart = getSelectionStart();
    const selectionEnd = getSelectionEnd();

    if (forward) {
      const nextBlockClientId = getNextBlockClientId(clientId);
      const nextBlock = getBlock(nextBlockClientId);
      const newContent =
        currentBlock.attributes.content + nextBlock?.attributes.content;

      const clonedBlock = cloneBlock({
        ...currentBlock,
        attributes: {
          ...currentBlock.attributes,
          content: newContent,
        },
      });
      replaceBlocks([clientId, nextBlockClientId], [clonedBlock]);
      selectionChange(
        clonedBlock.clientId,
        "content",
        selectionStart.offset,
        selectionEnd.offset,
      );
      return;
    } else {
      const prevBlockClientId = getPreviousBlockClientId(clientId);
      const prevBlock = getBlock(prevBlockClientId);
      const prevContentLength = prevBlock?.attributes.content?.length;

      const currentIndent = currentBlock.attributes.indent;

      // If it is a top level block, replace with paragraph
      if (currentIndent === 1 && prevBlock?.name !== CHECKLIST_ITEM_BLOCK_ID) {
        replaceBlocks(clientId, [
          createBlock(PARAGRAPH_BLOCK_ID, {
            content: currentBlock.attributes.content,
          }),
        ]);
        return;
      }

      if (currentIndent > 1) {
        updateBlockAttributes([clientId], { indent: currentIndent - 1 });
        return;
      }

      const noText = !currentBlock.attributes.content.trim().length;
      //   If current block has no content, remove it and move selection to previous block
      if (noText) {
        selectionChange(
          prevBlockClientId,
          "content",
          prevContentLength,
          prevContentLength,
        );
        removeBlocks(clientId);
        return;
      }

      const newContent =
        prevBlock?.attributes.content + currentBlock.attributes.content;
      const clonedBlock = cloneBlock({
        ...prevBlock,
        attributes: {
          ...prevBlock.attributes,
          content: newContent,
          indent: prevBlock.attributes.indent,
        },
      });
      replaceBlocks([clientId, prevBlockClientId], [clonedBlock]);
      selectionChange(
        clonedBlock.clientId,
        "content",
        prevContentLength,
        prevContentLength,
      );
      return;
    }
  };
}
