import { Button } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { SyncSpinner } from "@/components/JournalSidebar/SyncSpinner";
import {
  useDownloadAllJournals,
  Stage,
  Phase,
} from "@/hooks/useDownloadAllJournals";
import { searchViewState } from "@/view_state/ViewStates";
import { syncViewState } from "@/view_state/ViewStates";

export const DownloadingIndicator: React.FC = observer(() => {
  const { downloadState, setDownloadState, setSyncState } =
    useDownloadAllJournals(
      {
        stage: "RESYNC_JOURNALS",
        phase: "START",
      },
      "IDLE",
    );
  const { __, _n } = useI18n();
  const { journalsDownloading } = searchViewState;

  const { state: syncState } = syncViewState;

  const { stage, phase } = downloadState;

  const handleCancelDownloads = () => {
    if (stage === "DOWNLOAD_ALL_JOURNALS" || stage === "RESYNC_JOURNALS") {
      setDownloadState({ stage, phase: "CANCEL" });
    }
  };

  // syncState will drive downloadState transitions in useDownloadAllJournals
  useEffect(() => {
    setSyncState(syncState);
  }, [syncState, setSyncState]);

  const getMessage = (
    phase: Phase,
    stage: Stage,
    journalsDownloading: number,
  ) => {
    if (phase === "CANCELLING") {
      return __("Cancelling downloads...");
    } else if (stage === "DOWNLOAD_ALL_JOURNALS" && phase === "DOWNLOADING") {
      const journalsDownloadCount = Math.max(journalsDownloading, 1);
      return sprintf(
        _n(
          "Downloading %d journal.",
          "Downloading %d journals.",
          journalsDownloadCount,
        ),
        journalsDownloadCount,
      );
    } else {
      return __("Downloading journals...");
    }
  };

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        pr: 1,
        fontSize: 1,
        color: "buttonTextDisabled",
      }}
    >
      <div>
        <span
          sx={{
            position: "relative",
            pl: 6,
          }}
        >
          <SyncSpinner />
        </span>
        <p
          sx={{
            display: "inline-block",
            verticalAlign: "middle",
            pl: 1,
          }}
        >
          {getMessage(phase, stage, journalsDownloading)}
        </p>
      </div>
      <Button
        onClick={handleCancelDownloads}
        label={__("Cancel downloads")}
        sx={{
          fontSize: 1,
          variant: "tertiary",
          mr: 2,
          textDecoration: "underline",
          opacity: 0.65,
        }}
      >
        {__("Cancel")}
      </Button>
    </div>
  );
});
