import { useI18n } from "@wordpress/react-i18n";

export const ErrorMessage: React.FC = () => {
  const { __ } = useI18n();
  return (
    <div>
      <p sx={{ textAlign: "center", color: "red", mt: -2, mb: 2 }}>
        {__("Sorry, we couldn't create a new entry. Please try again later.")}
      </p>
    </div>
  );
};
