import { GlobalEntryID } from "@/data/db/migrations/entry";
import {
  CommentReactionDBRow,
  ReactionDBRow,
  ReactionType,
} from "@/data/db/migrations/reaction";
import { CommentReactionRepository } from "@/data/repositories/CommentReactionRepository";
import { ReactionRepository } from "@/data/repositories/ReactionRepository";

export type TagCount = [string, number];

export class ReactionStore {
  constructor(
    private reactionRepository: ReactionRepository,
    private commentReactionRepository: CommentReactionRepository,
  ) {}

  async addReaction(globalEntryID: GlobalEntryID, reaction: ReactionType) {
    return await this.reactionRepository.addReaction(globalEntryID, reaction);
  }

  async addCommentReaction(
    journalId: string,
    entryId: string,
    commentId: string,
    reaction: ReactionType,
  ) {
    return await this.commentReactionRepository.addReaction(
      journalId,
      entryId,
      commentId,
      reaction,
    );
  }

  async removeReaction(globalEntryID: GlobalEntryID, userId: string) {
    return await this.reactionRepository.removeReaction(globalEntryID, userId);
  }

  async removeCommentReaction(
    journalId: string,
    entryId: string,
    commentId: string,
    userId: string,
  ) {
    return await this.commentReactionRepository.removeReaction(
      journalId,
      entryId,
      commentId,
      userId,
    );
  }

  async getReactionCountForComment(
    journalId: string,
    entryId: string,
    commentId: string,
  ) {
    return await this.commentReactionRepository.getReactionCountForComment(
      journalId,
      entryId,
      commentId,
    );
  }

  async syncReactionsForComment(
    journalId: string,
    entryId: string,
    commentId: string,
  ) {
    return await this.commentReactionRepository.syncReactionsForComment(
      journalId,
      entryId,
      commentId,
    );
  }

  subscribeToReactionByComment(
    journalId: string,
    entryId: string,
    commentId: string,
    callback: (reactions: CommentReactionDBRow[]) => void,
  ) {
    return this.commentReactionRepository.subscribeToReactionsByComment(
      journalId,
      entryId,
      commentId,
      callback,
    );
  }

  subscribeToReactionCountByComment(
    journalId: string,
    entryId: string,
    commentId: string,
    callback: (count: number) => void,
  ) {
    return this.commentReactionRepository.subscribeToReactionCountByComment(
      journalId,
      entryId,
      commentId,
      callback,
    );
  }

  subscribeToReactionsByEntry(
    callback: (reactions: ReactionDBRow[]) => void,
    journalId: string,
    entryId: string,
  ) {
    return this.reactionRepository.subscribeToReactionsByEntry(
      callback,
      journalId,
      entryId,
    );
  }
}
