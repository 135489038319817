import { d1Classes } from "@/D1Classes";
import { DOCryptoBasics, Symmetric } from "@/crypto/DOCryptoBasics";
import { UserModel } from "@/data/models/UserModel";
import { GoogleFile } from "@/hooks/google/constants";
import {
  deleteFileFromGoogleDrive,
  saveKeyToDrive,
} from "@/hooks/google/utils";
import { primaryViewState } from "@/view_state/ViewStates";

export const getOldBackupFileName = (userArg?: UserModel) => {
  const user = userArg || primaryViewState.user;
  if (!user) {
    throw new Error("User not found, unable to create filename");
  }
  return `${user.id}.config.json`;
};

export const maybeMigrate = async (
  files: GoogleFile[],
  user: UserModel,
  token: string,
) => {
  const oldFileName = getOldBackupFileName(user);
  const oldFile = files.find((file) => file.name === oldFileName);
  if (!oldFile) {
    return null;
  }

  const options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const fileResult = await d1Classes.fetchWrapper.basicFetch(
    new URL(
      `https://www.googleapis.com/drive/v3/files/${oldFile.id}?alt=media`,
    ),
    options,
  );
  if (fileResult.ok) {
    const json = await fileResult.json();
    const encryptionKeyString = json.encryptionKey;
    const symmetricKey = await getDerivedKey(user);
    const decryptedMasterKey = await Symmetric.decryptD1(
      encryptionKeyString,
      symmetricKey,
    );
    const result = await saveKeyToDrive(user, decryptedMasterKey, token);
    if (result.ok) {
      cleanUpAfterMigration(oldFile.id, token);
    }

    return decryptedMasterKey;
  }

  return null;
};

const getDerivedKey = async (user: UserModel) => {
  return DOCryptoBasics.Symmetric.Key.newFromUserId(user.id);
};

const cleanUpAfterMigration = async (fileId: string, token: string) => {
  deleteFileFromGoogleDrive(fileId, token);
  await d1Classes.userKeysRepository.removeLocationOfKey("driveWeb");
  await d1Classes.userKeysRepository.saveLocationOfKey("drive");
};
