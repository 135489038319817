import { BlockInstance } from "@wordpress/blocks";

import { RTJNode } from "@/../types/rtj-format";

export const convertCodeBlockToRTJNode = (block: BlockInstance): RTJNode => {
  return {
    attributes: {
      line: {
        codeBlock: true,
      },
    },
    text: (block.attributes.content || "") + "\n",
  };
};
