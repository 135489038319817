import { Snackbar } from "@wordpress/components";
import { NoticeAction } from "@wordpress/components/build-types/notice/types";
import { makeAutoObservable } from "mobx";

type Snackbar = {
  message: string;
  actions?: NoticeAction[];
};

export class SnackbarViewState {
  snackbars: Snackbar[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  dismissSnackbar = (snackbar: Snackbar) => {
    const index = this.snackbars.indexOf(snackbar);
    if (index >= 0) {
      this.snackbars.splice(index, 1);
    }
  };

  newMessage = (message: string, actions: NoticeAction[] = []) => {
    if (!this.snackbars.some((s) => s.message === message)) {
      this.snackbars.push({ message, actions });
    }
  };
}
