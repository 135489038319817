export const Cloudy: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.789 10.4209C4.697 10.4209 3 12.1179 3 14.2109C3 16.3039 4.697 17.9999 6.789 17.9999H17.21C19.303 17.9999 21 16.3029 21 14.2109C21 12.1189 19.303 10.4219 17.211 10.4219C17.211 7.54394 14.878 5.21094 12 5.21094C9.122 5.21094 6.789 7.54294 6.789 10.4209Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
