import { useI18n } from "@wordpress/react-i18n";

import { ButtonItem } from "@/components/ItemGroup";
import { useAuth } from "@/data/hooks/AuthProvider";

export const SignOut: React.FC = () => {
  const { doLogout } = useAuth();
  const { __ } = useI18n();

  return (
    <ButtonItem
      sx={{
        "&& button": { color: "journalRed", lineHeight: 4 },
      }}
      onClick={doLogout}
      isDestructive={true}
      id="logout"
    >
      {__("Log Out")}
    </ButtonItem>
  );
};
