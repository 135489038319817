declare class BarcodeDetector {
  constructor(options: { formats: string[] });
  detect(image: HTMLImageElement): Promise<Array<{ rawValue: string }>>;
}

export const handleQRCodeDrop = (e: React.DragEvent<HTMLDivElement>) => {
  e.preventDefault();
  const file = e.dataTransfer.files[0];
  const uriList = e.dataTransfer.getData("text/uri-list");

  const processImage = async (imageData: string) => {
    const image = new Image();
    image.src = imageData;
    await new Promise((resolve) => (image.onload = resolve));

    if ("BarcodeDetector" in window) {
      const barcodeDetector = new BarcodeDetector({
        formats: ["qr_code"],
      });
      try {
        const barcodes = await barcodeDetector.detect(image);
        if (barcodes.length > 0) {
          // We've got a value from a QR code! Let's see if it's a login link:
          const v = barcodes[0].rawValue;
          if (v.includes("/test/login")) {
            // It is! Let's pull the path and query params and redirect to that URL:
            const url = new URL(v);
            const path = url.pathname;
            const query = url.searchParams;
            window.location.href = `${path}?${query}`;
          }
        }
      } catch (error) {
        console.error(
          "Test user login failure: Barcode detection failed:",
          error,
        );
      }
    } else {
      console.log(
        "Test user login failure: Barcode Detection API is not supported in this browser.",
      );
    }
  };

  if (file && file.type.startsWith("image/")) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const imageData = event.target?.result as string;
      processImage(imageData);
    };
    reader.readAsDataURL(file);
  } else if (uriList) {
    // Assuming the URI is a base64 encoded image
    if (uriList.startsWith("data:image")) {
      processImage(uriList);
    } else {
      console.error("Unsupported URI format");
    }
  }
};
