import { Button } from "@wordpress/components";

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
  label?: string;
  id?: string;
  disabled?: boolean;
  isDestructive?: boolean;
};

export const ItemButton: React.FC<Props> = ({
  className,
  children,
  onClick,
  label,
  id,
  disabled = false,
  isDestructive = false,
}) => {
  return (
    <Button
      disabled={disabled}
      label={label}
      className={className}
      id={id}
      sx={{
        width: "100%",
        height: "100%",
        "&&:hover": {
          color: isDestructive ? "journalRed" : "inherit",
          opacity: 0.6,
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
