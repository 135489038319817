import { __experimentalItem as WPItem } from "@wordpress/components/";

import { ItemButton } from "@/components/ItemGroup/ItemButton";

type Props = {
  children: React.ReactNode;
  label?: string;
  onClick: () => void;
  className?: string;
  id?: string;
  disabled?: boolean;
  isDestructive?: boolean;
};

export const ButtonItem: React.FC<Props> = ({
  children,
  onClick,
  label,
  className,
  id,
  disabled = false,
  isDestructive = false,
}) => {
  return (
    <WPItem
      className={className}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "48px",
        "&.components-item": { p: 1 },
        "&&": {
          borderColor: "borderPrimary",
        },
      }}
    >
      <ItemButton
        id={id}
        onClick={onClick}
        sx={{ px: 3 }}
        label={label}
        disabled={disabled}
        isDestructive={isDestructive}
      >
        {children}
      </ItemButton>
    </WPItem>
  );
};
