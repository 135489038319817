import { fromBase64 } from "@/crypto/utils";

export const pemToBytes = (pem: string) => {
  return fromBase64(
    pem
      .replace(/\n/g, "")
      .replace(new RegExp("-----BEGIN (PUBLIC|PRIVATE) KEY-----"), "")
      .replace(new RegExp("-----END (PUBLIC|PRIVATE) KEY-----"), ""),
  );
};
