import * as SentryLib from "@sentry/react";

export type Sentry = typeof SentryLib;

export const Sentry: Sentry = {
  ...SentryLib,
  captureException(exn, hint) {
    console.error(exn, hint);
    return SentryLib.captureException(exn, hint);
  },
};
