import { addHours } from "date-fns";

import { JournalRepository } from "@/data/repositories/JournalRepository";
import { EntryStore } from "@/data/stores/EntryStore";
import { UserStore } from "@/data/stores/UserStore";
import { CalendarViewState } from "@/view_state/CalendarViewState";
import {
  CALENDAR_VIEW,
  PrimaryViewState,
  TIMELINE_VIEW,
  MEDIA_VIEW,
  ViewType,
  HOME_VIEW,
} from "@/view_state/PrimaryViewState";
import { journalId_AllEntries } from "@/view_state/PrimaryViewState";

type URLParams =
  | {
      journalId?: string;
      entryId?: string;
      date?: string;
      promptId?: string;
    }
  | undefined
  | null;

export const isAllJournals = (path: string) =>
  path.startsWith("/all/") ||
  path === "/all" ||
  path === "/" ||
  path === "/settings" ||
  path.startsWith("/shared-journals-info") ||
  path === "/calendar" ||
  path === "/calendar/" ||
  path.startsWith("/calendar/all") ||
  path === "/media/" ||
  path.startsWith("/media/all");

// This function runs both when the page changes the URL in
// response to a user action like a button click or a link click,
// and when the app is initially loaded by the user navigating to
// a URL directly.
// It's in charge of taking the user to the correct location within
// the app, whether that be settings, or a specific entry within a
// journal. It should properly load journals that need to be downloaded,
// or redirect the user to an error page when that's not possible.
export async function reactToURLChange(
  path: string,
  urlParams: URLParams,
  pathRequiresAuth: boolean,
  userStore: UserStore,
  journalRepository: JournalRepository,
  entryStore: EntryStore,
  triggerSync: () => any,
  setLocation: (path: string) => void,
  primaryViewState: PrimaryViewState,
  calendarViewState: CalendarViewState,
  gotoErrorPage: (path: string) => void,
) {
  const urlIsValid = await redirectToValidURL(
    path,
    urlParams,
    pathRequiresAuth,
    userStore,
    journalRepository,
    entryStore,
    setLocation,
    primaryViewState,
  );
  if (urlIsValid) {
    setStateBasedOnURL(
      path,
      urlParams,
      journalRepository,
      entryStore,
      triggerSync,
      primaryViewState,
      calendarViewState,
      gotoErrorPage,
    );
  }
}

// Intended to be used in reactToURLChange
// It validates if the URL is valid and if it is not
// we will redirect to the correct URL. This will update
// the path which will cause reactToURLChange to run again.
// It is only exported for testing purposes
export async function redirectToValidURL(
  path: string,
  urlParams: URLParams,
  pathRequiresAuth: boolean,
  userStore: UserStore,
  journalRepository: JournalRepository,
  entryStore: EntryStore,
  setLocation: (path: string) => void,
  primaryViewState: PrimaryViewState,
) {
  const isAuthenticated = (await userStore.getActiveUser()) !== null;

  const requiresLogin = pathRequiresAuth && isAuthenticated === false;
  const isAllJournalsPath = isAllJournals(path);

  if (requiresLogin) {
    if (isAllJournalsPath) {
      setLocation("/signup");
    } else {
      setLocation("/signup?next=" + encodeURIComponent(window.location.href));
    }
    return false;
  }

  if (isAllJournalsPath && primaryViewState.totalJournalCount === 1) {
    if (primaryViewState.journals[0]?.is_decrypted) {
      const firstJournal = primaryViewState.journals[0];
      setLocation(
        getSingleJournalURL(
          firstJournal.id,
          primaryViewState.selectedEntryView,
        ),
      );
    } else {
      setLocation("/");
    }
    return false;
  }
  const result = await verifyParams(
    journalRepository,
    entryStore,
    urlParams?.journalId,
    urlParams?.entryId,
  );
  const journalIsValid = result.journal === "VALID";
  const entryIsValid = result.entry === "VALID";
  const journalId = journalIsValid ? urlParams?.journalId || null : null;
  const entryId = entryIsValid ? urlParams?.entryId || null : null;
  if (
    !isAllJournalsPath &&
    journalId &&
    entryId &&
    !primaryViewState.userCanNavigateToJournal(journalId)
  ) {
    setLocation(
      getEntryURL(journalId, entryId, primaryViewState.selectedEntryView, true),
    );
    return false;
  }

  return true;
}

// Intended to be used in reactToURLChange
// It should run only on a valid path so it can set the state
// in the app appropariately based on the URL.
// It is only exported for testing purposes
export async function setStateBasedOnURL(
  path: string,
  urlParams: URLParams,
  journalRepository: JournalRepository,
  entryStore: EntryStore,
  triggerSync: () => any,
  primaryViewState: PrimaryViewState,
  calendarViewState: CalendarViewState,
  gotoErrorPage: (path: string) => void,
) {
  if (path.startsWith("/calendar")) {
    setupCalendarView(
      primaryViewState,
      urlParams,
      calendarViewState,
      entryStore,
    );
  } else if (path.startsWith("/media")) {
    primaryViewState.selectEntryView(MEDIA_VIEW);
  } else if (path.startsWith("/home")) {
    primaryViewState.selectEntryView(HOME_VIEW);
  } else if (
    path.startsWith("/journal-not-found") ||
    path.startsWith("/journal-encrypted") ||
    path.startsWith("/entry-not-found")
  ) {
    // Leave selected entry view as it is
  } else {
    primaryViewState.selectEntryView(TIMELINE_VIEW);
  }

  if (!urlParams || (!urlParams.journalId && !urlParams.promptId)) {
    primaryViewState.selectJournal(journalId_AllEntries);
    return;
  }

  const result = await verifyParams(
    journalRepository,
    entryStore,
    urlParams.journalId,
    urlParams.entryId,
    urlParams.promptId,
  );
  const journalIsValid = result.journal === "VALID";
  const entryIsValid = result.entry === "VALID";
  const promptIsValid = result.prompt === "VALID";

  const journalId = journalIsValid ? urlParams.journalId || null : null;
  const entryId = entryIsValid ? urlParams.entryId || null : null;
  const promptId = promptIsValid ? urlParams.promptId || null : null;

  // Make sure the current journal is in the sync jobs.
  if (urlParams.journalId && journalIsValid) {
    const enabled = await entryStore.addJournalToSyncJobs(urlParams.journalId);
    // If we just added a journal to the sync jobs, we should
    // trigger sync right away.
    if (enabled) triggerSync();
  }

  if (journalId && entryId) {
    const isAllJournalsPath = isAllJournals(path);
    primaryViewState.selectEntry(
      { journal_id: journalId, id: entryId },
      isAllJournalsPath,
    );
  } else if (promptId) {
    primaryViewState.selectPrompt(journalId || journalId_AllEntries, promptId);
  } else if (journalId) {
    primaryViewState.selectJournal(journalId);
  }

  if (result.journal === "ENCRYPTED") {
    const entryPart = urlParams?.entryId ? `&eid=${urlParams?.entryId}` : "";
    gotoErrorPage(`/journal-encrypted?jid=${urlParams?.journalId}${entryPart}`);
    return;
  } else if (result.journal === "NOT_FOUND") {
    gotoErrorPage(`/journal-not-found?jid=${urlParams?.journalId}`);
    return;
  } else if (result.entry === "NOT_FOUND") {
    gotoErrorPage(
      `/entry-not-found?jid=${urlParams?.journalId}&eid=${urlParams?.entryId}`,
    );
    return;
  }
}

type ValidEntryStates = "NOT_SELECTED" | "NOT_FOUND" | "VALID";
type ValidJournalStates = ValidEntryStates | "ENCRYPTED";
type ValidPromptStates = "NOT_SELECTED" | "NOT_FOUND" | "VALID";
type ValidParameterStates = {
  journal: ValidJournalStates;
  entry: ValidEntryStates;
  prompt: ValidPromptStates;
};

const isValidJournalId = async (
  journalRepository: JournalRepository,
  journalId: string,
): Promise<ValidJournalStates> => {
  const journal = await journalRepository.getById(journalId);
  const syncable = await journalRepository.isSyncable(journalId);
  if (!syncable) {
    return "NOT_FOUND";
  }
  if (journal) {
    if (journal.is_decrypted === 0) {
      return "ENCRYPTED";
    }
  } else {
    return "NOT_FOUND";
  }
  return "VALID";
};

const isValidEntryId = async (
  entryStore: EntryStore,
  journalId: string,
  entryId: string,
) => {
  const entry = await entryStore.getEntryById(journalId, entryId);
  if (entry) {
    return true;
  } else {
    return false;
  }
};

const verifyParams = async (
  journalRepository: JournalRepository,
  entryStore: EntryStore,
  journalId?: string,
  entryId?: string,
  promptId?: string,
): Promise<ValidParameterStates> => {
  const result = {
    journal: "NOT_SELECTED",
    entry: "NOT_SELECTED",
    prompt: "NOT_SELECTED",
  } as ValidParameterStates;
  if (journalId) {
    result.journal = await isValidJournalId(journalRepository, journalId);
    if (entryId) {
      const validEntry = await isValidEntryId(entryStore, journalId, entryId);
      if (!validEntry) {
        result.entry = "NOT_FOUND";
      } else {
        result.entry = "VALID";
      }
    }
  }
  if (promptId) {
    result.prompt = "VALID";
  }
  return result;
};

const setupCalendarView = async (
  primaryViewState: PrimaryViewState,
  urlParams: URLParams,
  calendarViewState: CalendarViewState,
  entryStore: EntryStore,
) => {
  primaryViewState.selectEntryView(CALENDAR_VIEW);
  if (urlParams && urlParams.date) {
    const date = new Date(urlParams.date);
    const timeZoneOffset = date.getTimezoneOffset() / 60;
    const normalizedDate = addHours(date, timeZoneOffset);
    calendarViewState.setSelectedDate(normalizedDate.toISOString());
    calendarViewState.setDateSetFromURL(true);
  } else {
    calendarViewState.setDateSetFromURL(false);
  }
  if (urlParams && urlParams.journalId && urlParams.entryId) {
    const entry = await entryStore.getEntryById(
      urlParams.journalId,
      urlParams.entryId,
    );
    if (entry) {
      calendarViewState.setSelectedDate(new Date(entry.date).toISOString());
    }
  }
};

export const getSingleJournalURL = (
  journalId: string,
  selectedView: ViewType,
) => {
  return getJournalURL(journalId, selectedView);
};

export const getJournalURL = (
  journalId: string,
  selectedView: ViewType,
  allEntries = false,
) => {
  if (allEntries) {
    if (selectedView === CALENDAR_VIEW) {
      return `/calendar/all/${journalId}`;
    } else if (selectedView === MEDIA_VIEW) {
      return `/media/all/${journalId}`;
    } else {
      return `/all/${journalId}`;
    }
  } else {
    if (selectedView === CALENDAR_VIEW) {
      return `/calendar/${journalId}`;
    } else if (selectedView === MEDIA_VIEW) {
      return `/media/${journalId}`;
    } else {
      return `/journals/${journalId}`;
    }
  }
};

export const getEntryURL = (
  journalId: string,
  entryId: string,
  selectedView: ViewType,
  allEntries = false,
) => {
  const journalURL = getJournalURL(journalId, selectedView, allEntries);
  return `${journalURL}/${entryId}`;
};

export const getAllEntriesURL = (selectedView: ViewType) => {
  if (selectedView === CALENDAR_VIEW) {
    return "/calendar/all";
  } else if (selectedView === MEDIA_VIEW) {
    return "/media/all";
  } else if (selectedView === HOME_VIEW) {
    return "/home/all";
  } else {
    return "/all";
  }
};

export const getBaseURL = (
  journalId: string | null,
  selectedView: ViewType,
) => {
  if (!journalId || journalId === journalId_AllEntries) {
    return getAllEntriesURL(selectedView);
  } else {
    return getSingleJournalURL(journalId, selectedView);
  }
};

export const maybeAddNextToURL = (path: "login" | "signup") => {
  const nextURLFromQuery = getRedirectURL();
  return nextURLFromQuery
    ? `/${path}?next=${encodeURIComponent(nextURLFromQuery)}`
    : `/${path}`;
};

export const getRedirectURL = () => {
  return new URLSearchParams(window.location.search).get("next");
};

export type NextLocal = {
  url: string;
  timestamp: number;
};
