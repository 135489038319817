import { mkIV } from "./mkIV";

export const encryptSymmetric = async (key: CryptoKey, data: BufferSource) => {
  const iv = mkIV();
  const algo = {
    name: "AES-GCM",
    iv: iv,
  };
  const encrypted = new Uint8Array(
    await crypto.subtle.encrypt(algo, key, data),
  );
  return { iv, encrypted };
};
