export const Location: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.9C15.2822 11.4367 17 9.41896 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.41896 8.71776 11.4367 11 11.9V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V11.9ZM12 5.5C12 6.32843 11.3284 7 10.5 7C9.67157 7 9 6.32843 9 5.5C9 4.67157 9.67157 4 10.5 4C11.3284 4 12 4.67157 12 5.5Z"
        fill="currentColor"
      />
      <path
        d="M8.91169 17.6267C8.32572 17.9196 8.09142 18.2156 8.02526 18.3819C7.98848 18.4744 7.96683 18.5968 8.15069 18.8188C8.36714 19.08 8.81696 19.389 9.53903 19.6302C10.2459 19.8664 11.1039 20 12 20C12.8961 20 13.7542 19.8664 14.461 19.6302C15.1831 19.389 15.6329 19.08 15.8493 18.8188C16.0332 18.5968 16.0115 18.4744 15.9748 18.3819C15.9086 18.2156 15.6743 17.9196 15.0883 17.6267L15.9828 15.8378C16.7953 16.2441 17.5134 16.839 17.8331 17.6426C18.1822 18.5201 17.9625 19.403 17.3895 20.0947C16.8491 20.747 16.0172 21.219 15.0947 21.5271C14.1571 21.8404 13.0817 22 12 22C10.9183 22 9.84294 21.8404 8.90529 21.5271C7.98287 21.219 7.15097 20.747 6.61054 20.0947C6.03753 19.403 5.81784 18.5201 6.16691 17.6426C6.48661 16.839 7.20472 16.2441 8.01727 15.8378L8.91169 17.6267Z"
        fill="currentColor"
      />
    </svg>
  );
};
