export const Shared = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 19C2 16.8 3.8 15 6 15H10C12.2 15 14 16.8 14 19"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5001 6.00015C11.9001 7.40015 11.9001 9.60015 10.5001 10.9002C9.10005 12.2002 6.90005 12.3002 5.60005 10.9002C4.30005 9.50015 4.20005 7.40015 5.50005 6.00015C6.80005 4.60015 9.10005 4.70015 10.5001 6.00015Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 14H19C20.7 14 22 15.3 22 17"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3 6.70039C20.3 7.70039 20.3 9.30039 19.3 10.2004C18.3 11.1004 16.7 11.2004 15.8 10.2004C14.9 9.20039 14.8 7.60039 15.8 6.70039C16.7 5.80039 18.3 5.80039 19.3 6.70039Z"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
