import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { Icon, chevronLeft } from "@wordpress/icons";
import { PropsWithChildren } from "react";

import { useDevice } from "@/data/hooks/LayoutProvider";

export const TEMPLATE_MODAL_HEADER_HEIGHT = 60;

type Props = {
  setMobileView: (view: "editor" | "list") => void;
};

export const TemplateModalHeader: React.FC<PropsWithChildren<Props>> = ({
  children,
  setMobileView,
}) => {
  const { isMobile } = useDevice();
  return (
    <header
      sx={{
        height: `${TEMPLATE_MODAL_HEADER_HEIGHT}px`,
        borderBottom: "1px solid",
        borderColor: "borderPrimary",
        position: "sticky",
        top: 0,
        lineHeight: `${TEMPLATE_MODAL_HEADER_HEIGHT}px`,
        px: isMobile ? 3 : 4,
        fontWeight: "bold",
        "&& button": {
          ml: 2,
          height: "60px",
        },
        "&& button.back-button": {
          ml: 0,
        },
      }}
    >
      <div
        sx={{
          display: "flex",
        }}
      >
        {isMobile && (
          <Button
            className="back-button"
            onClick={() => {
              setMobileView("list");
            }}
            label={__("Back to template list")}
          >
            <Icon icon={chevronLeft} size={24} />
          </Button>
        )}
        {children}
      </div>
    </header>
  );
};
