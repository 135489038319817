import { DODexie } from "@/data/db/dexie_db";
import { MomentDBRow } from "@/data/db/migrations/moment";
import { SearchWorkerEntriesUpdatedMessage } from "@/worker/SearchWorkerTypes";

export const hasMediaTypes = (
  moments: MomentDBRow[],
): {
  hasPhoto: boolean;
  hasAudio: boolean;
  hasVideo: boolean;
  hasPdf: boolean;
} => {
  const defaultValue = {
    hasPhoto: false,
    hasAudio: false,
    hasVideo: false,
    hasPdf: false,
  };

  return moments.reduce(
    (
      acc: {
        hasPhoto: boolean;
        hasAudio: boolean;
        hasVideo: boolean;
        hasPdf: boolean;
      },
      moment: MomentDBRow,
    ) => {
      if (moment.type === "image") {
        acc.hasPhoto = true;
      } else if (moment.type === "audio") {
        acc.hasAudio = true;
      } else if (moment.type === "video") {
        acc.hasVideo = true;
      } else if (moment.type === "pdfAttachment") {
        acc.hasPdf = true;
      }
      return acc;
    },
    defaultValue,
  );
};

export const postSearchWorkerEntryUpdate = (
  channel: BroadcastChannel,
  message: SearchWorkerEntriesUpdatedMessage,
) => {
  channel.postMessage(message);
};

export const addSearchWorkerHooks = (db: DODexie) => {
  if (typeof BroadcastChannel !== "undefined") {
    const channel = new BroadcastChannel("search-update");
    db.table("entries").hook("creating", (keys, obj) => {
      postSearchWorkerEntryUpdate(channel, {
        type: "creating",
        changes: { entry: obj, keys },
      });
    });
    db.table("entries").hook("updating", (mods, keys, obj) => {
      postSearchWorkerEntryUpdate(channel, {
        type: "updating",
        changes: { mods, entry: obj, keys },
      });
    });
    db.table("entries").hook("deleting", (keys, obj) => {
      postSearchWorkerEntryUpdate(channel, {
        type: "deleting",
        changes: { entry: obj, keys },
      });
    });
  }
};

// rtj is a stringified RTJ object
export const getSearchTextFromRTJ = (rtj: string, body: string) => {
  if (!rtj) {
    return body;
  }
  const parsedRtj = JSON.parse(rtj);
  const { contents } = parsedRtj;
  let text = "";
  if (contents.length > 0) {
    for (const content of contents) {
      if (content.text) {
        text += content.text.trim() + " ";
      }
    }
    text.trimEnd();
  }
  if (text === "" && contents.length > 0) {
    text = " ";
  }
  return text !== "" ? text : body;
};
