import { useEffect, useRef, useState } from "react";

import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { searchViewState } from "@/view_state/ViewStates";

type DOWNLOAD_PHASE =
  | "IDLE"
  | "START"
  | "DOWNLOADING"
  | "CANCEL"
  | "CANCELLING"
  | "CANCELLED";
type BUTTON_PHASE = "IDLE" | "CLICKED";

export type Stage = "BUTTON" | "RESYNC_JOURNALS" | "DOWNLOAD_ALL_JOURNALS";
export type Phase = DOWNLOAD_PHASE | BUTTON_PHASE;
type SyncState = DOWNLOAD_PHASE | "UPLOADING" | "ERROR" | "INITIAL";

type DOWNLOAD_ALL_JOURNALS_STATE =
  | { stage: "BUTTON"; phase: BUTTON_PHASE }
  | { stage: "RESYNC_JOURNALS"; phase: DOWNLOAD_PHASE }
  | { stage: "DOWNLOAD_ALL_JOURNALS"; phase: DOWNLOAD_PHASE };

type ActionStage = "RESYNC_JOURNALS" | "DOWNLOAD_ALL_JOURNALS";
type ActionPhase = "START" | "CANCELLING" | "CANCELLED";

/**
 * `transitionMap` is a state machine that defines the transitions between different stages and phases of the download process.
 *
 * Example 1:
 * BUTTON: {
 *   CLICKED: {
 *     syncState: "IDLE",
 *     nextStage: "RESYNC_JOURNALS",
 *     nextPhase: "START",
 *   },
 * }
 * This represents the state after a button has been clicked.
 * When syncState is or changes to IDLE, it start the "RESYNC_JOURNALS" stage.
 *
 * Example 2:
 * RESYNC_JOURNALS: {
 *   START: {
 *     syncState: "DOWNLOADING",
 *     nextStage: "RESYNC_JOURNALS",
 *     nextPhase: "DOWNLOADING",
 *   },
 * }
 * This represents the state when the "RESYNC_JOURNALS" stage starts.
 * When syncState changes to "DOWNLOADING", it transitions to "DOWNLOADING" phase of the "RESYNC_JOURNALS" stage.
 */
const transitionMap: {
  [key in Stage]: {
    [key in Phase]?: {
      nextStage?: Stage;
      nextPhase?: Phase;
      syncState?: SyncState;
    };
  };
} = {
  BUTTON: {
    CLICKED: {
      syncState: "IDLE",
      nextStage: "RESYNC_JOURNALS",
      nextPhase: "START",
    },
  },
  RESYNC_JOURNALS: {
    START: {
      syncState: "DOWNLOADING",
      nextStage: "RESYNC_JOURNALS",
      nextPhase: "DOWNLOADING",
    },
    DOWNLOADING: {
      syncState: "IDLE",
      nextStage: "DOWNLOAD_ALL_JOURNALS",
      nextPhase: "START",
    },
    CANCEL: {
      syncState: "DOWNLOADING",
      nextStage: "RESYNC_JOURNALS",
      nextPhase: "CANCELLING",
    },
    CANCELLING: {
      syncState: "IDLE",
      nextStage: "RESYNC_JOURNALS",
      nextPhase: "CANCELLED",
    },
    CANCELLED: { syncState: "IDLE", nextStage: "BUTTON", nextPhase: "IDLE" },
  },
  DOWNLOAD_ALL_JOURNALS: {
    START: {
      syncState: "DOWNLOADING",
      nextStage: "DOWNLOAD_ALL_JOURNALS",
      nextPhase: "DOWNLOADING",
    },
    DOWNLOADING: { syncState: "IDLE", nextStage: "BUTTON", nextPhase: "IDLE" },
    CANCEL: {
      syncState: "DOWNLOADING",
      nextStage: "DOWNLOAD_ALL_JOURNALS",
      nextPhase: "CANCELLING",
    },
    CANCELLING: {
      syncState: "IDLE",
      nextStage: "DOWNLOAD_ALL_JOURNALS",
      nextPhase: "CANCELLED",
    },
    CANCELLED: { syncState: "IDLE", nextStage: "BUTTON", nextPhase: "IDLE" },
  },
};

export const handleStateTransition = (
  currentStage: Stage,
  currentPhase: Phase,
  syncState: SyncState,
): DOWNLOAD_ALL_JOURNALS_STATE | null => {
  const stageTransitions =
    transitionMap[currentStage as keyof typeof transitionMap];
  if (stageTransitions) {
    const phaseTransition =
      stageTransitions[currentPhase as keyof typeof stageTransitions];
    if (
      phaseTransition &&
      (!phaseTransition.syncState || phaseTransition.syncState === syncState)
    ) {
      const nextStage = phaseTransition?.nextStage || currentStage;
      const nextPhase = phaseTransition?.nextPhase || currentPhase;

      switch (nextStage) {
        case "BUTTON":
          return { stage: nextStage, phase: nextPhase as BUTTON_PHASE };
        case "RESYNC_JOURNALS":
        case "DOWNLOAD_ALL_JOURNALS":
          return { stage: nextStage, phase: nextPhase as DOWNLOAD_PHASE };
        default:
          return null;
      }
    }
  }
  return null;
};

export const useDownloadAllJournals = (
  initialDownloadState: DOWNLOAD_ALL_JOURNALS_STATE,
  initialSyncState: SyncState = "INITIAL",
) => {
  const { manageDownloadSyncableJournals, setIndexStatus, refreshIndex } =
    searchViewState;
  // we cannot observe syncState within a hook, so we export setSyncState
  // and let component observe it, and pass it back to this hook
  // syncState drives the transition
  const [syncState, setSyncState] = useState<SyncState>(initialSyncState);

  const [downloadState, setDownloadState] =
    useState<DOWNLOAD_ALL_JOURNALS_STATE>(initialDownloadState);

  const cancelDownloadRef = useRef(() => {});

  const downloadAllJournals = async () => {
    const { doSync, cancelDownload } = await manageDownloadSyncableJournals();
    cancelDownloadRef.current = cancelDownload;
    await doSync();
  };

  const { stage, phase } = downloadState;

  const downloadStarted = stage !== "BUTTON";

  const resyncJournals = async () => {
    setIndexStatus("DOWNLOADING");
    await d1MainThreadClasses.syncService.resyncJournals();
  };

  const stopSync = () => {
    d1MainThreadClasses.syncService.stop();
  };

  const setIndexDone = () => {
    setIndexStatus("DONE");
  };

  function isActionStage(stage: Stage): stage is ActionStage {
    return ["RESYNC_JOURNALS", "DOWNLOAD_ALL_JOURNALS"].includes(stage);
  }

  function isActionPhase(phase: Phase): phase is ActionPhase {
    return ["START", "CANCELLING", "CANCELLED"].includes(phase);
  }

  useEffect(() => {
    const nextState = handleStateTransition(stage, phase, syncState);

    if (nextState) {
      setDownloadState(nextState);

      const actions = {
        RESYNC_JOURNALS: {
          START: resyncJournals,
          CANCELLING: stopSync,
          CANCELLED: setIndexDone,
        },
        DOWNLOAD_ALL_JOURNALS: {
          START: downloadAllJournals,
          CANCELLING: () => cancelDownloadRef.current(),
          CANCELLED: refreshIndex,
        },
      };

      if (isActionStage(nextState.stage) && isActionPhase(nextState.phase)) {
        const action = actions[nextState.stage][nextState.phase];
        if (action) {
          action();
        }
      }
    }
  }, [syncState, stage, phase]);

  return {
    setDownloadState,
    downloadState,
    cancelDownloadRef,
    setSyncState,
    downloadStarted,
  };
};
