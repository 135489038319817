import { liveQuery } from "dexie";
import { toJS } from "mobx";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { DOCrypto } from "@/crypto/DOCrypto";
import { WrappedVault } from "@/crypto/DOCrypto/JournalVault/makeJournalVault";
import { Asymmetric } from "@/crypto/DOCryptoBasics";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { JournalCoverDBRow } from "@/data/db/migrations/journal_cover";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { VaultDBRow } from "@/data/db/migrations/vault";
import { UserModel } from "@/data/models/UserModel";
import { JournalParticipantRepository } from "@/data/repositories/JournalParticipantRepository";
import { JournalStatsRepository } from "@/data/repositories/JournalStatsRepository";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { UnlockedUserPrivateKey } from "@/data/repositories/UserKeysRepository";
import {
  Journal,
  JournalCover,
  JournalParticipant,
} from "@/data/repositories/V6API";
import { VaultRepository } from "@/data/repositories/VaultRepository";
import { DecryptionService } from "@/data/services/DecryptionService";
import { UserKeysStore } from "@/data/stores/UserKeysStore";
import { syncStates } from "@/worker/SyncWorkerTypes";

export type JournalWithDecryptState = Journal & {
  is_decrypted: boolean;
  stat_video_count?: number;
  stat_image_count?: number;
  stat_audio_count?: number;
  stat_entry_count?: number;
};

export type JournalStat = {
  journal_id: string;
  entry_count: number;
  image_count: number;
  video_count: number;
  audio_count: number;
};

export class JournalRepository {
  isSynchronizing: boolean;

  constructor(
    protected db: DODexie,
    private syncStateRepository: SyncStateRepository,
    private vaultRepository: VaultRepository,
    private decryptionService: DecryptionService,
    private kv: KeyValueStore,
    private journalParticipantRepository: JournalParticipantRepository,
    private userKeysStore: UserKeysStore,
    private journalStatsRepository: JournalStatsRepository,
  ) {
    this.isSynchronizing = false;
  }

  subscribeToJournal(
    journalId: string,
    callback: (journal: JournalDBRow | undefined) => void,
  ) {
    const sub = liveQuery(async () => {
      const journal = await this.db.journals
        .where("id")
        .equals(journalId)
        .first();
      return journal;
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  subscribeToAll(callback: (journal: JournalDBRow[]) => void) {
    const sub = liveQuery(() => {
      return this.db.journals.toArray();
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  async getJournalsWithValidVaults() {
    const vaults = await this.vaultRepository.readAll();

    // filter down journals that have valid vaults
    const journalsWithVaults = (await this.db.journals.toArray()).filter(
      (journal) => vaults.findIndex((v) => v.journal_id === journal.id) !== -1,
    );
    return journalsWithVaults;
  }

  async getServerSyncStats() {
    return this.getJournalStats();
  }

  private async getJournalStats() {
    await this.syncStateRepository.setStatSyncStatus(syncStates.DOWNLOADING);
    const res = await d1Classes.fetchWrapper.fetchAPI(
      "/v6/sync/journals/stats",
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (res.status === 200 || res.status === 304) {
      const stats = (await res.json()) as JournalStat[];
      await this.syncStateRepository.setStatSyncStatus(syncStates.IDLE);
      return stats;
    }
    await this.syncStateRepository.setStatSyncStatus(syncStates.ERROR);
    return null;
  }

  private async shouldForceRecheckOfAllJournals() {
    // We shipped code that would fail while decrypting journals
    // because of problems with user keys. Our code would set
    // the journal name to "Could not decrypt name", and then
    // set is_decrypted to true anyway.

    // We've fixed the problem with the keys, and now we want to re-check
    // those journals.
    const journals = await this.db.journals.toArray();
    if (journals.find((j) => j.name === "Could not decrypt name")) {
      return true;
    }
  }

  async synchronize(
    user: UserModel,
    userPrivateKeys: UnlockedUserPrivateKey[],
  ): Promise<{
    sharedJournals: Journal[];
    deletedJournals: Journal[];
    isNewUser: boolean;
    hasPresets: boolean;
  }> {
    let cursor = await this.syncStateRepository.getJournalCursor();
    if (await this.shouldForceRecheckOfAllJournals()) {
      cursor = "";
    }
    let isNewUser = false;

    const endpoint = `/v6/sync/journals?cursor=${cursor}`;

    const res = await d1Classes.fetchWrapper.fetchAPI(endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const canPerformDecrypt = await this.decryptionService.canPerformDecrypt();

    if (res.status === 200 || res.status === 304) {
      let data = null;
      try {
        data = (await res.clone().json()) as Journal[];
      } catch (e) {
        throw Error(
          `Error parsing journal sync response, not JSON, actual response: ${await res.clone()
            .text}`,
          {
            cause: e,
          },
        );
      }
      if (data.length) {
        this.syncStateRepository.setJournalCursor(data[data.length - 1].cursor);
      } else if (cursor === "") {
        // if we get an empty journal array and the cursor is not set yet,
        // this is a new user
        isNewUser = true;
      }

      const {
        activeJournals,
        deletedJournals,
        vaults,
        sharedJournals,
        hasPresets,
      } = data.reduce<{
        activeJournals: Journal[];
        deletedJournals: Journal[];
        vaults: VaultDBRow[];
        sharedJournals: Journal[];
        hasPresets: boolean;
      }>(
        (acc, journal) => {
          const isActive = this.isActive(journal.state);
          if (isActive) {
            acc.activeJournals.push(journal);
          }
          if (journal.state === "deleted") {
            acc.deletedJournals.push(journal);
          }
          if (isActive && journal.encryption !== "plaintext") {
            acc.vaults.push({
              journal_id: journal.id,
              vault_json: JSON.stringify(journal.encryption),
            });
          }
          if (isActive && journal.is_shared) {
            acc.sharedJournals.push(journal);
          }
          if (isActive && journal.preset_id) {
            acc.hasPresets = true;
          }
          return acc;
        },
        {
          activeJournals: [],
          deletedJournals: [],
          vaults: [],
          sharedJournals: [],
          hasPresets: false,
        },
      );

      // update vaults
      await this.vaultRepository.upsertVaults(vaults);

      if (activeJournals.length) {
        await this.insertAll(
          user,
          canPerformDecrypt,
          userPrivateKeys,
          activeJournals,
        );
      }

      const participants: JournalParticipantDBRow[] = [];
      sharedJournals.forEach((journal) => {
        journal.participants.forEach((participant: JournalParticipant) => {
          participants.push({
            user_id: participant.id,
            name: participant.name,
            avatar: participant.avatar,
            profile_color: participant.profile_color,
            initials: participant.initials,
          });
        });
      });
      const uniqueParticipants = participants.reduce(
        (acc: JournalParticipantDBRow[], participant) => {
          if (!acc.find((p) => p.user_id === participant.user_id)) {
            acc.push(participant);
          }
          return acc;
        },
        [],
      );
      // update participants
      await this.journalParticipantRepository.bulkUpsertForJournal(
        uniqueParticipants,
      );

      await this.journalStatsRepository.updateAllStats();

      // Rotate keys for journals that need it
      const journalsNeedingKeyRotation = activeJournals.filter(
        (journal) => journal.should_rotate_keys,
      );

      await this.rotateJournalKeys(
        user,
        userPrivateKeys,
        journalsNeedingKeyRotation,
      );

      return { sharedJournals, deletedJournals, isNewUser, hasPresets };
    } else {
      throw new Error(`Error syncing journals: ${res.status}`);
    }
  }

  subscribeToJournalCursor(callback: (cursor: string) => void) {
    const sub = liveQuery(async () => {
      return await this.syncStateRepository.getJournalCursor();
    }).subscribe(callback, (err) => {
      Sentry.captureException(err);
    });
    return () => {
      sub.unsubscribe();
    };
  }

  private async rotateJournalKeys(
    user: UserModel,
    userPrivateKeys: UnlockedUserPrivateKey[],
    journals: Journal[],
  ) {
    const userPrivateKey = await this.userKeysStore.getMainUserPrivateKey();
    if (!userPrivateKey) {
      return;
    }
    await Promise.all(
      journals.map(async (journal) => {
        const vault = await this.vaultRepository.getVaultByJournalId(
          journal.id,
        );
        if (!vault) {
          return;
        }
        const grant = vault.vault.grants.find((g) => g.user_id === user.id);
        if (!grant) {
          return;
        }
        const vaultKey = await DOCrypto.Grant.getVaultKey(
          userPrivateKeys,
          grant,
          journal.id,
        );
        const newJournalKey = await DOCrypto.JournalKey.make(
          vaultKey,
          user,
          userPrivateKey,
        );
        vault.vault.keys = [newJournalKey, ...vault.vault.keys];
        await this.vaultRepository.upsertVaults([
          {
            journal_id: journal.id,
            vault_json: JSON.stringify(vault),
          },
        ]);
        const journalData = await this.getById(journal.id);
        if (journalData) {
          await this.updateJournal(journalData);
        }
      }),
    );
  }

  isActive(journalState: JournalDBRow["state"]) {
    return journalState === "active" || journalState === "being_transferred";
  }

  async removeLocalJournals(journalIds: string[]) {
    await this.db.journals.where("id").anyOf(journalIds).delete();
  }

  getVaults() {
    return this.vaultRepository.readAll();
  }

  getVaultsByJournalId(journalId: string) {
    return this.vaultRepository.getVaultByJournalId(journalId);
  }

  async decryptAndCacheJournalKeys(
    user: UserModel,
    userPrivateKeys: UnlockedUserPrivateKey[],
    journal: Journal,
  ) {
    if (journal.encryption === "plaintext") {
      return;
    }

    const grant = journal.encryption.vault.grants.find(
      (grant) => grant.user_id === user.id,
    );
    if (grant) {
      const vaultKey = await DOCrypto.Grant.getVaultKey(
        userPrivateKeys,
        grant,
        journal.id,
      );

      this.vaultRepository.cacheVaultKey(journal.id, vaultKey);
    }
  }

  async decryptJournalNameDescription(journal: Journal) {
    const journalsWithVaults = await this.getJournalsWithValidVaults();
    const journalName = await this.decryptionService.decryptJournalName(
      journalsWithVaults,
      journal.id,
      journal.name,
    );

    const journalDescription = journal.description_v2
      ? await this.decryptionService.decryptJournalName(
          journalsWithVaults,
          journal.id,
          journal.description_v2,
        )
      : "";

    return { journalName, journalDescription };
  }

  async insertAll(
    user: UserModel,
    canPerformDecrypt: boolean,
    userPrivateKeys: UnlockedUserPrivateKey[],
    journals: Journal[],
  ) {
    if (user && canPerformDecrypt) {
      await DOCrypto.JournalKey.unlockAndStoreAll(
        user.id,
        journals,
        userPrivateKeys,
        this.vaultRepository,
      );
    }

    for (const journal of journals) {
      try {
        if (journal.encryption === "plaintext") {
          await this.insertNew({ ...journal, is_decrypted: true });
        } else {
          const { journalName, journalDescription } =
            await this.decryptJournalNameDescription(journal);

          await this.insertNew({
            ...journal,
            name: journalName === null ? journal.name : journalName,
            description_v2:
              journalDescription === null
                ? journal.description_v2
                : journalDescription,
            is_decrypted: journalName !== null,
          });
        }
      } catch (e) {
        Sentry.captureException(
          new Error(
            `Failed while inserting journal from server ${journal.id}. Continuing with others. Error: ${e}`,
          ),
        );
      }
    }
  }

  async newJournal(
    journal: JournalDBRow,
    user: UserModel,
    userPublicKey: CryptoKey | null,
    userPrivateKey: CryptoKey | null,
  ): Promise<JournalDBRow> {
    const unencryptedName = journal.name;
    const unencryptedDescription = journal.description;
    let key;
    let encryption;
    let journalName = unencryptedName;
    let journalDescription = unencryptedDescription;

    if (journal.e2e && userPrivateKey && userPublicKey) {
      const result = await DOCrypto.JournalVault.make(
        user,
        userPublicKey,
        userPrivateKey,
      );
      const vault = result?.vault || "plaintext";
      key = result?.key || null;
      encryption = vault;
      journalName = key
        ? await DOCrypto.D1.encryptNoLockedKey(journal.name, key)
        : journal.name;
      journalDescription = key
        ? await DOCrypto.D1.encryptNoLockedKey(journal.description, key)
        : journal.description;
    } else {
      encryption = "plaintext";
    }

    const result = await createJournalAPI(
      journal,
      journalName,
      journalDescription,
      encryption,
      userPublicKey,
      userPrivateKey,
    );

    if (result.status === 200) {
      const journalResult: Journal = await result.json();

      let description = journalResult.description_v2;
      if (key && journalResult.encryption !== "plaintext") {
        await this.vaultRepository.upsertVaults([
          {
            journal_id: journalResult.id,
            vault_json: JSON.stringify(journalResult.encryption.vault),
          },
        ]);

        this.vaultRepository.cacheVaultKey(journalResult.id, key);
        journalResult.name = unencryptedName;
        description = unencryptedDescription;
      }

      await this.db.journals.put({
        id: journalResult.id,
        kind: journalResult.kind,
        name: journalResult.name,
        description: description ?? "",
        color: journalResult.color,
        owner_id: journalResult.owner_id,
        created_at: journalResult.created_at,
        sort_method: journalResult.sort_method,
        hide_all_entries: journalResult.hide_all_entries ? 1 : 0,
        hide_on_this_day: journalResult.hide_on_this_day ? 1 : 0,
        hide_today_view: journalResult.hide_today_view ? 1 : 0,
        conceal: journalResult.conceal ? 1 : 0,
        deletion_requested: journalResult.deletion_requested ? 1 : 0,
        e2e: journalResult.encryption === "plaintext" ? 0 : 1,
        is_decrypted:
          journalResult.encryption === "plaintext" ? 1 : key ? 1 : 0,
        state: journalResult.state,
        template_id: journalResult.template_id || "",
        comments_disabled: journalResult.comments_disabled ? 1 : 0,
        hide_streaks: journalResult.hide_streaks ? 1 : 0,
        add_location_to_new_entries: journalResult.add_location_to_new_entries,

        // shared journals details
        invite_list: journalResult.invite_list || {
          active_invites: [],
          pending_approvals: [],
        },
        participants: journalResult.participants,
        is_read_only: journalResult.is_read_only,
        is_being_transferred: journalResult.is_being_transferred,
        should_rotate_keys: journalResult.should_rotate_keys,
        is_shared: journalResult.is_shared,
        revoked_at: journalResult.revoked_at,
        ownership_transfers: journalResult.ownership_transfers,
        max_participants: journalResult.max_participants,
        cover_photo: journalResult.cover_photo || null,
        preset_id: journalResult.preset_id || null,
      });

      const createdJournal = await this.getById(journalResult.id);
      if (!createdJournal) {
        throw new Error(
          "Local DB failed to store journal after insert, this should never happen",
        );
      }

      return createdJournal;
    } else {
      throw new Error("Failed to create journal on the server");
    }
  }

  async insertNew(journal: JournalWithDecryptState) {
    const {
      id,
      kind,
      name,
      color,
      description_v2,
      owner_id,
      created_at,
      deletion_requested,
      encryption,
      is_decrypted,
      state,
      sort_method,
      hide_all_entries,
      hide_on_this_day,
      hide_today_view,
      hide_streaks,
      add_location_to_new_entries,
      conceal,
      template_id,
      comments_disabled,
      invite_list,
      participants,
      is_read_only,
      is_being_transferred,
      should_rotate_keys,
      revoked_at,
      is_shared,
      ownership_transfers,
      max_participants,
      cover_photo,
      preset_id,
    } = journal;

    // There is a hook in journalHooks.ts that will update entries
    // when hide_all_entries is updated
    await this.db.transaction(
      "rw",
      [this.db.journals, this.db.entries],
      async () => {
        await this.db.journals.put({
          id,
          kind,
          name,
          description: description_v2 || "",
          color,
          owner_id,
          created_at,
          sort_method,
          hide_all_entries: hide_all_entries ? 1 : 0,
          hide_on_this_day: hide_on_this_day ? 1 : 0,
          hide_today_view: hide_today_view ? 1 : 0,
          hide_streaks: hide_streaks ? 1 : 0,
          add_location_to_new_entries,
          conceal: conceal ? 1 : 0,
          deletion_requested: deletion_requested ? 1 : 0,
          e2e: encryption === "plaintext" ? 0 : 1,
          is_decrypted: is_decrypted ? 1 : 0,
          state,
          template_id: template_id || "",
          comments_disabled: comments_disabled ? 1 : 0,
          invite_list: invite_list || {
            active_invites: [],
            pending_approvals: [],
          },
          participants: participants || [],
          is_read_only,
          is_being_transferred,
          should_rotate_keys,
          revoked_at,
          is_shared,
          ownership_transfers: ownership_transfers || [],
          max_participants: max_participants,
          cover_photo: cover_photo || null,
          preset_id: preset_id || null,
        });
      },
    );

    return this.getById(journal.id);
  }

  private async updateJournalName(journalId: string, journalName: string) {
    return this.db.journals.update(journalId, {
      name: journalName,
    });
  }

  private async encryptJournalIfNeeded(data: JournalDBRow) {
    if (data.e2e) {
      const vaultKey = await this.vaultRepository.getVaultKey(data.id);
      const name = vaultKey
        ? await DOCrypto.D1.encryptNoLockedKey(data.name, vaultKey)
        : data.name;
      const description = vaultKey
        ? await DOCrypto.D1.encryptNoLockedKey(data.description, vaultKey)
        : data.description;
      const encryption = await this.vaultRepository.getVaultByJournalId(
        data.id,
      );

      return { name, description, encryption };
    }

    return {
      name: data.name,
      description: data.description,
      encryption: "plaintext",
    };
  }

  async updateJournalWithCover(
    journalId: string,
    coverPhotoInfo: JournalCoverDBRow | null,
  ) {
    const currentJournal = await this.getById(journalId);
    if (!currentJournal) {
      return;
    }
    const url = `/v4/sync/journals/${journalId}`;
    let coverPhoto: JournalCover | null = null;

    const { name, description, encryption } =
      await this.encryptJournalIfNeeded(currentJournal);

    const toSync = {
      id: currentJournal.id,
      name: name,
      color: currentJournal.color,
      description_v2: description,
      sort_method: currentJournal.sort_method,
      kind: "standard",
      hide_all_entries: !!currentJournal.hide_all_entries,
      hide_on_this_day: !!currentJournal.hide_on_this_day,
      hide_today_view: !!currentJournal.hide_today_view,
      hide_streaks: false,
      conceal: !!currentJournal.conceal,
      encryption,
      template_id: currentJournal.template_id,
      comments_disabled: !!currentJournal.comments_disabled,
      is_shared: currentJournal.is_shared,
      ownership_transfers: currentJournal.ownership_transfers,
    } as Partial<Journal>;

    const result = await d1Classes.fetchWrapper.fetchAPI(url, {
      body: JSON.stringify({
        ...toSync,
        cover_photo: coverPhotoInfo
          ? {
              client_id: coverPhotoInfo.moment_id,
              content_type: coverPhotoInfo.content_type,
              moment_type: coverPhotoInfo.moment_type,
            }
          : null,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });
    if (coverPhotoInfo) {
      coverPhoto = {
        kind: coverPhotoInfo.moment_type,
        client_id: coverPhotoInfo.moment_id,
        md5: coverPhotoInfo.md5,
        owner_id: currentJournal.owner_id,
        content_type: coverPhotoInfo.content_type,
        e2e: !!currentJournal.e2e,
      };
    }

    if (result.status === 200) {
      this.updateLocalJournal({
        ...currentJournal,
        cover_photo: coverPhoto,
      });
      return true;
    } else {
      return false;
    }
  }

  async updateJournal(journalData: JournalDBRow) {
    const currentJournal = await this.getById(journalData.id);

    // Switching from unencrypted to encrypted will need
    // to generate vaults and grants
    if (!currentJournal?.e2e && !!journalData.e2e) {
      throw new Error(
        "Cannot change a journal from unencrypted to encrypted, this isn't supported yet",
      );
    }

    const toPersist = {
      id: journalData.id,
      name: journalData.name,
      description: journalData.description,
      sort_method: journalData.sort_method,
      hide_all_entries: journalData.hide_all_entries,
      hide_on_this_day: journalData.hide_on_this_day,
      hide_today_view: journalData.hide_today_view,
      conceal: journalData.conceal,
      color: journalData.color,
      e2e: journalData.e2e,
      template_id: journalData.template_id,
      comments_disabled: journalData.comments_disabled,
      is_decrypted: 1,
      is_shared: journalData.is_shared,
      is_read_only: journalData.is_read_only,
      cover_photo: toJS(journalData.cover_photo) || [],
    } as JournalDBRow;

    // There is a hook in journalHooks.ts that will update entries
    // when hide_all_entries is updated
    await this.db.transaction(
      "rw",
      [this.db.journals, this.db.entries],
      async () => {
        await this.db.journals.update(journalData.id, toPersist);
      },
    );

    const { name, description, encryption } =
      await this.encryptJournalIfNeeded(journalData);

    const url = `/v3/sync/journals/${journalData.id}`;
    const toSync = {
      id: journalData.id,
      name,
      color: journalData.color,
      description_v2: description,
      sort_method: journalData.sort_method,
      kind: "standard",
      hide_all_entries: !!journalData.hide_all_entries,
      hide_on_this_day: !!journalData.hide_on_this_day,
      hide_today_view: !!journalData.hide_today_view,
      hide_streaks: false,
      conceal: !!journalData.conceal,
      encryption,
      template_id: journalData.template_id,
      comments_disabled: !!journalData.comments_disabled,
      is_shared: journalData.is_shared,
      ownership_transfers: journalData.ownership_transfers,
      preset_id: journalData.preset_id,
    } as Partial<Journal>;

    await d1Classes.fetchWrapper.fetchAPI(url, {
      body: JSON.stringify(toSync),
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
    });

    return this.getById(journalData.id);
  }

  async updateLocalJournal(journalData: JournalDBRow) {
    const {
      id,
      kind,
      name,
      description,
      color,
      owner_id,
      created_at,
      deletion_requested,
      e2e,
      sort_method,
      hide_all_entries,
      hide_on_this_day,
      hide_today_view,
      hide_streaks,
      add_location_to_new_entries,
      conceal,
      state,
      template_id,
      invite_list, //assuming these will be copied by reference
      participants,
      should_rotate_keys,
      is_read_only,
      is_being_transferred,
      is_shared,
      revoked_at,
      ownership_transfers,
      max_participants,
      comments_disabled,
      cover_photo,
      preset_id,
    } = journalData;

    const toPersist: JournalDBRow = {
      id,
      kind,
      name,
      description,
      color,
      owner_id,
      created_at,
      deletion_requested,
      e2e,
      is_decrypted: 1,
      sort_method,
      hide_all_entries,
      hide_on_this_day,
      hide_today_view,
      hide_streaks,
      add_location_to_new_entries,
      conceal,
      state,
      template_id,
      comments_disabled,
      invite_list: invite_list,
      participants: participants,
      should_rotate_keys,
      is_read_only,
      is_being_transferred,
      is_shared,
      revoked_at,
      ownership_transfers: ownership_transfers,
      max_participants,
      cover_photo,
      preset_id,
    };

    // There is a hook in journalHooks.ts that will update entries
    // when hide_all_entries is updated
    await this.db.transaction(
      "rw",
      [this.db.journals, this.db.entries],
      async () => {
        await this.db.journals.update(id, toPersist);
      },
    );
  }
  async updateCommentsDisabled(journalId: string, disabled: number) {
    const result = await d1Classes.fetchWrapper.fetchAPI(
      `/journals/${journalId}/comments/config`,
      {
        body: JSON.stringify({
          disabled: !!disabled,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
      },
    );

    if (result.status !== 200) {
      return false;
    }

    await this.db.journals.update(journalId, { comments_disabled: disabled });
    return true;
  }

  async deleteJournal(journalId: string) {
    const result = await d1Classes.fetchWrapper.fetchAPI(
      `/sync/journals/${journalId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "DELETE",
      },
    );

    if (result.status !== 200) {
      return false;
    }

    await this.db.journals.delete(journalId);
    return true;
  }

  async getActiveJournals() {
    return (await this.db.journals.toArray()).filter(
      (j) => j.deletion_requested === 0 && this.isActive(j.state),
    );
  }

  async getSharedJournals() {
    return (await this.getActiveJournals()).filter((j) => j.is_shared);
  }

  async readAllInDB() {
    return this.getActiveJournals();
  }

  async readAll() {
    const existingJournals = await this.getActiveJournals();

    const journalsNeedingDecryption = existingJournals.filter(
      (journal) => !journal.is_decrypted,
    );

    if (await this.decryptionService.canPerformDecrypt()) {
      for (const journal of journalsNeedingDecryption) {
        const journalsWithVaults = await this.getJournalsWithValidVaults();
        const journalName = await this.decryptionService.decryptJournalName(
          journalsWithVaults,
          journal.id,
          journal.name,
        );

        if (journalName) {
          await this.updateJournalName(journal.id, journalName);
        }
      }
    }

    return this.getActiveJournals();
  }

  async getById(journalId: string) {
    return this.db.journals.get(journalId);
  }

  async isJournalE2EE(journalId: string) {
    const j = await this.db.journals.get(journalId);
    return j && j.e2e;
  }

  async isSharedJournal(journalId: string) {
    const j = await this.db.journals.get(journalId);
    return j && j.is_shared;
  }

  async getUnsyncableJournalIds() {
    const unsyncables = (await this.kv.get<string[]>("unsyncables")) || [];
    return unsyncables;
  }

  async makeUnsyncable(journalId: string) {
    const unsyncables = (await this.kv.get<string[]>("unsyncables")) || [];
    if (!unsyncables.includes(journalId)) {
      unsyncables.push(journalId);
      await this.kv.set("unsyncables", unsyncables);
    }
  }

  async makeSyncable(journalId: string) {
    const unsyncables = (await this.kv.get<string[]>("unsyncables")) || [];
    if (unsyncables.includes(journalId)) {
      unsyncables.splice(unsyncables.indexOf(journalId), 1);
      await this.kv.set("unsyncables", unsyncables);
    }
  }

  async isSyncable(journalId: string) {
    const unsyncables = (await this.kv.get<string[]>("unsyncables")) || [];
    return !unsyncables.includes(journalId);
  }

  async subToUnsyncableJournalIds(
    cb: (unsyncables: string[] | undefined) => void,
  ) {
    return this.kv.subscribe("unsyncables", cb);
  }
}

async function createJournalAPI(
  journal: JournalDBRow,
  journalName: string,
  journalDescription: string,
  encryption: string | WrappedVault,

  userPublicKey: CryptoKey | null,
  userPrivateKey: CryptoKey | null,
) {
  const createOwnerPublicKeySignatureByOwner = async () => {
    // Only shared journals with encryption need a signature
    if (!journal.is_shared || journal.e2e === 0) return null;

    if (userPrivateKey === null || userPublicKey === null) {
      throw new Error(
        "User private key or public key is null, can't create signature",
      );
    }

    return await Asymmetric.Private.signPublicKey({
      userPrivateKey,
      userPublicKey,
    });
  };

  const journalObj = {
    name: journalName,
    color: journal.color,
    description_v2: journalDescription,
    sort_method: journal.sort_method,
    kind: "standard",
    hide_all_entries: !!journal.hide_all_entries,
    hide_on_this_day: !!journal.hide_on_this_day,
    hide_today_view: !!journal.hide_today_view,
    hide_streaks: false,
    conceal: !!journal.conceal,
    encryption,
    template_id: journal.template_id,
    comments_disabled: !!journal.comments_disabled,
    owner_public_key_signature_by_owner:
      await createOwnerPublicKeySignatureByOwner(),
    preset_id: journal.preset_id || null,
  };

  // Shared journals are created with a different endpoint than regular journals
  // to preserve old clients being able to use the old endpoint and
  // for other reasons listed here: https://dayonep2.wordpress.com/2022/11/21/shared-journals-meeting-notes-nov-18-2022/
  //
  // API docs on shared endpoint: https://stg.dayone.app/docs/#tag/shared-journals/operation/create
  const url = journal.is_shared ? "/shares" : "/v3/sync/journals";
  return await d1Classes.fetchWrapper.fetchAPI(url, {
    body: JSON.stringify(journalObj),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
}
