export const headingFour = () => {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8 18H8.40001V13.2H3.60001V18H1.20001V6H3.60001V10.8H8.40001V6H10.8V18ZM22.8 15.6H21.6V18H19.2V15.6H13.2V13.2L18 6H21.6V13.2H22.8V15.6ZM19.2 13.2V8.4L15.84 13.2H19.2Z" />
    </svg>
  );
};
