import { Button, Flex } from "@wordpress/components";
import { sprintf } from "@wordpress/i18n";
import { plus } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Modal } from "@/components/D1Modal";
import { CheckboxControl } from "@/components/Form/CheckboxControl";
import { SearchBox } from "@/components/SearchBox";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { TagCount } from "@/data/stores/TagStore";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type TagManagerProps = {
  entryTags: string[];
  globalEntryID: GlobalEntryID;
  onClose: () => void;
  onChangeTags: () => void;
};

const getCurrentTags = (allTags: TagCount[], entryTags: string[]) => {
  const tags = allTags.reduce(
    (acc, tag) => {
      if (entryTags.includes(tag[0])) {
        acc.currentTags.push(tag);
      } else {
        acc.otherTags.push(tag);
      }
      return acc;
    },
    {
      currentTags: [],
      otherTags: [],
    } as { currentTags: TagCount[]; otherTags: TagCount[] },
  );
  return tags;
};

export const TagManagerModal: React.FC<TagManagerProps> = observer(
  ({ globalEntryID, onChangeTags, onClose }) => {
    const { __ } = useI18n();
    const [newTagInput, setNewTagInput] = useState<null | string>(null);
    const [showAll, setShowAll] = useState(true);
    const tagStore = d1Classes.tagStore;
    const entryTags = viewStates.activeEntry.tags;
    const entryHashTags = viewStates.activeEntry.hashtags;

    const singleJournal = showAll ? undefined : globalEntryID.journal_id;
    const searchableTags = primaryViewState.getTagCounts(singleJournal);

    const { currentTags, otherTags } = getCurrentTags(
      searchableTags,
      entryTags,
    );

    const handleAddTag = (tag: string) => {
      tagStore.addForEntry(tag, globalEntryID);
      onChangeTags();
      analytics.tracks.recordEvent(EVENT.tagAdded, {
        entry_id: globalEntryID.id,
      });
    };

    const handleRemoveTag = (tag: string) => {
      tagStore.removeForEntry(tag, globalEntryID);
      onChangeTags();
      analytics.tracks.recordEvent(EVENT.tagRemoved, {
        entry_id: globalEntryID.id,
      });
    };

    // tabs + search
    const constantHeaderHeight = 48 + 53;
    // If we are adding a new tag add that input height as well
    const headerHeight = newTagInput
      ? constantHeaderHeight + 48
      : constantHeaderHeight;

    return (
      <D1Modal
        sx={{
          width: "420px",
          maxWidth: "100%",
          "& .components-modal__content": {
            p: 0,
            // Full screen for mobile minus top margin or a fixed size for larger screens
            height: ["calc(100dvh - 40px)", "420px"],
            overflow: "hidden",
          },
          "& .components-modal__header": { borderBottom: "none" },
        }}
        title={__("Manage tags")}
        onRequestClose={() => onClose()}
        shouldCloseOnClickOutside={true}
      >
        <Flex
          sx={{
            flexDirection: "column",
            gap: 0,
          }}
        >
          <TagFilter showAll={showAll} toggleShowAll={setShowAll} />
          <SearchBox
            value={newTagInput}
            setInputValue={setNewTagInput}
            onSubmit={handleAddTag}
            onSubmitNullifyInput={true}
            placeholder={__("Search or create tag")}
            sx={{
              borderTop: "1px solid",
              borderColor: "borderPrimary",
              "&&& span": {
                left: "30px",
              },
              "&&& .components-text-control__input": {
                pl: "4rem",
              },
            }}
          />

          {newTagInput &&
            !Object.keys(searchableTags).some((t) => t === newTagInput) && (
              <Button
                sx={{
                  height: "48px",
                  fontSize: "1rem",
                  "&&.components-button.has-icon.has-text": {
                    width: "95%",
                    color: "dayOneBlue",
                    pl: 4,
                    alignSelf: "baseline",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },
                  "&&.components-button.has-icon.has-text svg": {
                    mr: 3,
                    flexShrink: 0,
                  },
                }}
                icon={plus}
                onClick={() => {
                  handleAddTag(newTagInput);
                  setNewTagInput(null);
                }}
              >
                {sprintf(__('Create tag "%s"'), newTagInput)}
              </Button>
            )}
        </Flex>
        <div
          sx={{
            width: "100%",
            pb: 3,
            overflowY: "scroll",
            // Height of modal minus filters, search box, and padding
            // The height of the search box changes searching
            // Full screen for mobile minus top margin or the fixed size for larger screens
            height: newTagInput
              ? [
                  `calc(100dvh - ${headerHeight}px - 40px)`,
                  `calc(420px - ${headerHeight}px)`,
                ]
              : [
                  `calc(100dvh - ${headerHeight}px - 40px)`,
                  `calc(420px - ${constantHeaderHeight}px)`,
                ],
          }}
        >
          <TagList
            tags={currentTags}
            hashtags={entryHashTags}
            onClick={handleRemoveTag}
            inputValue={newTagInput}
            added={true}
            key="currentTags"
          />
          <TagList
            tags={otherTags}
            hashtags={entryHashTags}
            onClick={handleAddTag}
            inputValue={newTagInput}
            key="otherTags"
          />
        </div>
      </D1Modal>
    );
  },
);

TagManagerModal.displayName = "TagManagerModal";

const TagList: React.FC<{
  tags: TagCount[];
  hashtags: string[];
  onClick: (tag: string) => void;
  inputValue: string | null;
  added?: boolean;
}> = ({ tags, hashtags, onClick, inputValue, added }) => {
  return (
    <>
      {tags
        .filter(([t]) =>
          t.toLocaleLowerCase().includes(inputValue?.toLocaleLowerCase() || ""),
        )
        .map(([tag, count]) => (
          <TagRow
            key={tag}
            isHashtag={hashtags.includes(tag)}
            tag={tag}
            added={added}
            count={count}
            onClick={() => onClick(tag)}
          />
        ))}
    </>
  );
};

const TagRow: React.FC<{
  tag: string;
  isHashtag: boolean;
  count: number;
  added?: boolean;
  onClick: () => void;
}> = ({ tag, isHashtag, count, added, onClick }) => {
  const { __ } = useI18n();
  return (
    <div sx={{ pl: 2, pr: 1, py: 1 }}>
      <Button
        sx={{
          "&:hover": {
            bg: "surfaceHover",
          },
          py: 3,
          px: 3,
          display: "flex",
          color: "textPrimary",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          height: "48px",
          fontSize: 16,
          borderRadius: 3,
          gap: 4,
          "& .components-base-control__field": {
            mb: 0,
          },
        }}
        label={added ? __("Remove Tag") : __("Add Tag")}
        onClick={isHashtag ? () => {} : onClick}
        disabled={isHashtag}
        title={isHashtag ? __("Hashtags cannot be removed") : tag}
      >
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
            overflow: "hidden",
          }}
        >
          <div sx={{ ml: 2 }}>
            <CheckboxControl
              checked={added}
              disabled={isHashtag}
              onChange={() => {}}
            />
          </div>
          <div
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {tag}
          </div>
        </div>
        <span sx={{ color: "textSecondary", mr: 2 }}>{count}</span>
      </Button>
    </div>
  );
};

const TagFilter = ({
  showAll,
  toggleShowAll,
}: {
  showAll: boolean;
  toggleShowAll: (value: boolean) => void;
}) => {
  const { __ } = useI18n();

  return (
    <div
      sx={{
        display: "flex",
        fontSize: 2,
        fontWeight: "heading",
        height: "48px",
        alignItems: "baseline",
        alignSelf: "stretch",
        ml: 3,
        "& button": {
          color: "textPrimary",
          height: "100%",
          borderRadius: 0,
          px: 3,
        },
        "& button:disabled": {
          borderRadius: 0,
          borderBottom: "4px solid",
          opacity: 1,
        },
        "& button:not(:disabled):hover": {
          backgroundColor: "surfaceHover",
        },
      }}
    >
      <Button
        disabled={showAll}
        onClick={() => toggleShowAll(true)}
        label={__("Show tags for All Journals")}
      >
        {__("All Journals")}
      </Button>
      <Button
        disabled={!showAll}
        onClick={() => toggleShowAll(false)}
        label={__("Show tags for Current Journal")}
      >
        {__("Current Journal")}
      </Button>
    </div>
  );
};
