import { useI18n } from "@wordpress/react-i18n";
import React from "react";

import { Button } from "@/components/Settings/Button";

type SaveButtonProps = {
  isSaving: boolean;
  disabled?: boolean;
  doSave: (e: React.MouseEvent) => void;
  attributeChanged: boolean;
};

export const SaveButton: React.FC<SaveButtonProps> = ({
  isSaving,
  disabled,
  doSave,
  attributeChanged,
}) => {
  const { __ } = useI18n();
  if (disabled) {
    return (
      <Button disabled variant="primary">
        {__("Save")}
      </Button>
    );
  }
  if (isSaving) {
    return (
      <Button disabled variant="primary">
        {__("Saving...")}
      </Button>
    );
  }
  return attributeChanged ? (
    <Button variant="primary" onClick={doSave}>
      {__("Done")}
    </Button>
  ) : (
    <Button disabled variant="primary">
      {__("Done")}
    </Button>
  );
};
