import { observer } from "mobx-react-lite";

import { CheckboxChecked } from "@/components/icons/CheckboxChecked";

type Props = {
  checkListItems: { total: number; checked: number };
};

export const CheckListSummary: React.FC<Props> = observer(
  ({ checkListItems }) => {
    return (
      <span
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: 0,
        }}
      >
        <CheckboxChecked />{" "}
        {`${checkListItems.checked}/${checkListItems.total}`}
      </span>
    );
  },
);

CheckListSummary.displayName = "CheckListSummary";
