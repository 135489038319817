import { sprintf } from "@wordpress/i18n";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { resultsSx } from "@/components/Search/SearchResultsStatus";
import { searchViewState } from "@/view_state/ViewStates";

export const SearchResultsSummary: React.FC = observer(() => {
  const { _n } = useI18n();
  const { searchResults } = searchViewState;
  const searchResultsLength = searchResults.length;
  return (
    <div sx={{ ...resultsSx, "&&": { ...resultsSx["&&"], pl: 0 } }}>
      <p>
        {sprintf(
          _n("Found %d result.", "Found %d results.", searchResultsLength),
          searchResultsLength,
        )}
      </p>
    </div>
  );
});
