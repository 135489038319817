import { useI18n } from "@wordpress/react-i18n";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { JournalDBRow } from "@/data/db/migrations/journal";

type Props = {
  journal: JournalDBRow;
  setJournal: (journal: JournalDBRow) => void;
  trackOnChange: (toggle: string, value: number) => void;
};

export const ToggleShowInAllEntries: React.FC<Props> = ({
  journal,
  setJournal,
  trackOnChange,
}) => {
  const { __ } = useI18n();
  return (
    <ToggleSetting
      label={__("Show in All Entries")}
      checked={!journal.hide_all_entries}
      onChange={() => {
        setJournal({
          ...journal,
          hide_all_entries: journal.hide_all_entries ? 0 : 1,
        });
        trackOnChange("hideInAllEntries", journal.hide_all_entries ? 0 : 1);
      }}
    />
  );
};
