import { Button } from "@wordpress/components";
import { Icon, calendar } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";

import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TextControl } from "@/components/Form/TextControl";
import { DateRangeSelector } from "@/components/Search/DateRangeSelector";
import { Pill } from "@/components/Search/Pill";
import { SearchViewState } from "@/view_state/SearchViewState";

type Props = {
  searchViewState: SearchViewState;
  isInModal?: boolean;
};

export const formatTimestampForInput = (timestamp: number | null) => {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp);
  return format(date, "yyyy-MM-dd");
};

// Expects a date in the format YYYY-MM-DD as the input
export const getDateFromInput = (input: string, isEnd = false) => {
  // This shouldn't really happen, but adding just in case
  const dateInputRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateInputRegex.test(input)) {
    throw new Error("Invalid date format");
  }

  const newDate = new Date(input);
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
  if (isEnd) {
    newDate.setHours(23, 59, 59, 999);
  }
  return newDate;
};

export const DateFilter: React.FC<Props> = observer(
  ({ searchViewState, isInModal }) => {
    const { __ } = useI18n();
    const { minDate, maxDate } = searchViewState.filter;

    const [startDate, setStartDate] = useState<string>(
      formatTimestampForInput(minDate),
    );
    const [endDate, setEndDate] = useState<string>(
      formatTimestampForInput(maxDate),
    );
    const [error, setError] = useState<string | null>(null);

    const clearDates = () => {
      analytics.tracks.recordEvent(EVENT.searchFilterRemoved, {
        filter_type: "date",
      });
      setStartDate("");
      setEndDate("");
      const startInput = document.getElementById("start-date");
      if (startInput) {
        startInput.focus();
      }
    };

    useEffect(() => {
      if (
        startDate &&
        endDate &&
        !minDate &&
        !maxDate &&
        searchViewState.isNoFilter
      ) {
        clearDates();
      }
    }, [minDate, maxDate, searchViewState.isNoFilter]);

    useEffect(() => {
      if (error) {
        setError(null);
      }
      if (startDate && endDate) {
        analytics.tracks.recordEvent(EVENT.searchFilterApplied, {
          filter_type: "date",
        });
        searchViewState.setFilter({
          minDate: getDateFromInput(startDate).getTime(),
          maxDate: getDateFromInput(endDate, true).getTime(),
        });
      }
      if (!startDate && !endDate && (minDate || maxDate)) {
        searchViewState.setFilter({
          minDate: null,
          maxDate: null,
        });
      }
    }, [startDate, endDate]);

    let text = isInModal ? __("All") : __("Date");
    if (startDate && endDate) {
      const sameDates = startDate === endDate;
      text = sameDates ? startDate : `${startDate} - ${endDate}`;
    } else if (startDate) {
      text = startDate;
    } else if (endDate) {
      text = endDate;
    }

    return (
      <D1Dropdown
        focusOnMount
        popoverProps={{
          placement: "bottom-start",
          variant: "primary",
        }}
        onClose={() => {
          searchViewState.setFilterDropdownOpen(false);
        }}
        renderToggle={({ isOpen, onToggle }) => (
          <Pill
            name="date"
            icon={
              isInModal ? null : (
                <Icon icon={calendar} sx={{ width: "16px", height: "16px" }} />
              )
            }
            isActive={!!startDate && !!endDate}
            aria-expanded={isOpen}
            text={text}
            fullWidth={isInModal}
            onClear={clearDates}
            onClick={() => {
              searchViewState.setFilterDropdownOpen(true);
              onToggle();
              if (!isOpen) {
                analytics.tracks.recordEvent(EVENT.buttonTap, {
                  button_identifier: "searchFilter_filterRow_date",
                });
              }
            }}
          />
        )}
        renderContent={() => (
          <div sx={{ mx: -2, mb: -2 }}>
            <div
              sx={{
                mx: 2,
                display: "flex",
                gap: 2,
                "& label": {
                  pl: 2,
                  color: "textPrimary",
                  fontSize: 0,
                  lineHeight: 2,
                },
              }}
            >
              <TextControl
                id="start-date"
                label={__("Start Date")}
                value={startDate}
                onChange={(value) => {
                  if (endDate && new Date(value) > new Date(endDate)) {
                    setError(__("Start date cannot be after end date"));
                  } else {
                    setStartDate(value);
                  }
                }}
                type="date"
              />
              <TextControl
                label={__("End Date")}
                value={endDate}
                onChange={(value) => {
                  if (startDate && new Date(value) < new Date(startDate)) {
                    setError(__("End date cannot be before start date"));
                  } else {
                    setEndDate(value);
                  }
                }}
                type="date"
              />
            </div>
            {error && <ErrorMessage sx={{ mx: 2, mb: 2 }} message={error} />}
            <div
              sx={{
                mx: 4,
                mt: 2,
                mb: 4,
                minHeight: "260px",
                color: "textPrimary",
              }}
            >
              <DateRangeSelector
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
            <div
              sx={{
                borderTop: "1px solid",
                borderColor: "borderPrimary",
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                sx={{ "&&": { color: "textSecondary" } }}
                onClick={clearDates}
                disabled={!startDate && !endDate}
              >
                {__("Clear")}
              </Button>
            </div>
          </div>
        )}
      />
    );
  },
);
