import { __ } from "@wordpress/i18n";
import { useRef } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { Button } from "@/components/Settings/Button";
import { CommentDBRow } from "@/data/db/migrations/comment";
import { decodeRichTextJson } from "@/utils/rtj";

type Props = {
  comment: CommentDBRow;
  closeEdit: () => void;
};

export const EditComment: React.FC<Props> = ({ comment, closeEdit }) => {
  const commentRef = useRef<HTMLDivElement>(null);

  const rtj = decodeRichTextJson(comment.content);
  const content = rtj.contents.length > 0 ? rtj.contents[0].text : "";

  const updateComment = async () => {
    if (!commentRef.current) {
      return;
    }
    const newContent = commentRef.current.innerText.trimEnd() || "";
    if (newContent.trim().length === 0 || newContent === content) {
      closeEdit();
      return;
    }
    await d1Classes.commentRepository.updateLocalComment(comment, newContent);
    const journal = await d1Classes.journalRepository.getById(
      comment.journal_id,
    );
    analytics.tracks.recordEvent(EVENT.entryCommentEdited, {
      shared_journal: journal?.is_shared || false,
      entry_id: comment.entry_id,
    });
    closeEdit();
  };

  return (
    <>
      <div
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <div
          ref={commentRef}
          aria-label={__("Edit comment")}
          contentEditable="true"
          suppressContentEditableWarning={true}
          sx={{
            color: "textPrimary",
            width: "100%",
            borderRadius: 2,
            backgroundColor: "surface_light2_dark2",
            border: "1px solid",
            borderColor: "borderPrimary",
            p: 2,
          }}
        >
          {content}
        </div>
      </div>
      <div sx={{ button: { mr: 2, mt: 1 } }}>
        <Button variant="secondary" onClick={() => updateComment()}>
          {__("Save")}
        </Button>
        <Button variant="tertiary" onClick={() => closeEdit()}>
          {__("Cancel")}
        </Button>
      </div>
    </>
  );
};
