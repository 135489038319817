export const CloudyNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.847 14.483C17.573 13.328 18 11.965 18 10.5C18 6.358 14.643 3 10.5 3C6.357 3 3 6.358 3 10.5C3 12.818 4.053 14.89 5.705 16.265"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.651 14.383C17.136 14.383 16.651 14.489 16.227 14.701C15.909 12.608 14.091 11 11.909 11C9.53 11 7.591 12.911 7.53 15.277C6.106 15.459 5 16.672 5 18.174C4.999 19.782 6.302 21.086 7.908 21.086H17.65C19.498 21.086 20.998 19.585 20.998 17.734C20.999 15.884 19.499 14.383 17.651 14.383V14.383Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
