import { Button } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Item, ItemGroup } from "@/components/ItemGroup";
import { useGoogleDrive } from "@/hooks/google/useGoogleDrive";
import {
  viewStates,
  primaryViewState,
  masterKeyViewState,
} from "@/view_state/ViewStates";

type DeleteStatus = "DELETING" | "DELETED" | "ERROR" | "NOT_STARTED";

export const ClearGoogleDrive: React.FC = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { deleteFile, connectGoogleDrive, authorized } =
    useGoogleDrive(primaryViewState);
  const [deletedGoogleDriveFile, setDeletedGoogleDriveFile] =
    useState<DeleteStatus>("NOT_STARTED");
  const { __ } = useI18n();
  const deleteGoogleDriveFile = () => {
    connectGoogleDrive("Can't connect to Google Drive");
    setDeletedGoogleDriveFile("DELETING");
  };

  const confirmDelete = async () => {
    deleteGoogleDriveFile();
    setShowConfirm(false);
  };
  useEffect(() => {
    if (authorized && deletedGoogleDriveFile === "DELETING") {
      deleteFile().then((result) => {
        if (result) {
          setDeletedGoogleDriveFile("DELETED");
          viewStates.snackbar.newMessage("Google Drive file deleted");
          d1Classes.userKeysRepository.removeLocationOfKey("drive");
          if (!masterKeyViewState.hasKeyBackedUp) {
            viewStates.modalRouter.showKeyModal({
              openedFrom: "settings-advanced",
              isCreatingSharedJournal: false,
            });
          }
        } else {
          viewStates.snackbar.newMessage("Failed to delete Google Drive file");
        }
      });
    }
  }, [authorized, deletedGoogleDriveFile]);
  if (!masterKeyViewState.hasSavedKeyToGDrive) {
    return null;
  }

  return (
    <ItemGroup addMargin={true}>
      <Item>
        <p sx={{ fontSize: 1, flex: 2 }}>
          {__("Clear Encryption Key from Google Drive")}
        </p>
        <Button
          isDestructive={true}
          disabled={
            deletedGoogleDriveFile === "DELETING" ||
            deletedGoogleDriveFile === "DELETED"
          }
          onClick={() => setShowConfirm(true)}
          sx={{ display: "block" }}
        >
          {deletedGoogleDriveFile === "DELETING" && "Deleting..."}
          {deletedGoogleDriveFile === "DELETED" && "Deleted"}
          {deletedGoogleDriveFile === "NOT_STARTED" && "Delete"}
        </Button>
      </Item>
      {showConfirm && (
        <ConfirmDialog
          handleClose={() => setShowConfirm(false)}
          handleAction={confirmDelete}
          message={__(
            "Are you sure you want to clear your key from Goolge Drive? This action cannot be undone.",
          )}
          isDestructive={true}
        />
      )}
    </ItemGroup>
  );
};
