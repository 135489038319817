import { makeAutoObservable, reaction } from "mobx";

import { MomentID } from "@/data/db/migrations/moment";
import { MomentRepository } from "@/data/repositories/MomentRepository";
import { isDeepEqual } from "@/utils/is-equal";
import {
  journalId_AllEntries,
  PrimaryViewState,
} from "@/view_state/PrimaryViewState";
import { WindowHeightViewState } from "@/view_state/WindowHeightViewState";

export const HEIGHT_PER_MEDIA_ROW = 170;
export type FilterMediaTypes =
  | "all"
  | "image"
  | "video"
  | "audio"
  | "pdfAttachment";

export class MediaViewState {
  constructor(
    private momentRepository: MomentRepository,
    private windowHeight: WindowHeightViewState,
    private primaryViewState: PrimaryViewState,
  ) {
    makeAutoObservable(
      this,
      {
        moveNext: false,
        movePrev: false,
        moveUp: false,
        moveDown: false,
        cancelIdsSubscription: false,
        fixScrollPosition: false,
        getPositionForMomentId: false,
        getIndexForMomentId: false,
        focusElement: false,
      },
      { autoBind: true },
    );

    // Ensure the media is in view if the focused item changes
    reaction(
      () => this.focused,
      (focused) => {
        if (focused) {
          this.fixScrollPosition(focused);
        }
      },
      {
        name: "MediaViewState_watchFocused",
        fireImmediately: true,
      },
    );

    reaction(
      () => this.primaryViewState.selectedJournal,
      (selectedJournal) => {
        const journalId = selectedJournal?.id || journalId_AllEntries;

        this.loadNewJournal(journalId, this.filteredType);
      },
      { name: "MediaViewState_watchSelectedJournal", fireImmediately: true },
    );

    reaction(
      () => this.primaryViewState.journalsInAllEntries,
      () => {
        this.loadNewJournal(
          this.journalId || journalId_AllEntries,
          this.filteredType,
        );
      },
      {
        name: "MediaViewState_watchJournalsInAllEntries",
        fireImmediately: true,
      },
    );

    reaction(
      () => this.filteredType,
      (filteredType) => {
        const journalId =
          this.primaryViewState.selectedJournal?.id || journalId_AllEntries;

        this.loadNewJournal(journalId, filteredType);
      },
      { name: "MediaViewState_watchFilteredType", fireImmediately: true },
    );
  }

  cancelIdsSubscription: null | (() => void) = null;
  allMomentIds: MomentID[] = [];
  journalId: string | undefined = undefined;
  filteredType: FilterMediaTypes = "all";

  scrollTop = 0;
  loading = true;
  focused: MomentID | undefined = undefined;

  // Actions

  setFocused = (momentID: MomentID) => {
    this.focusElement(momentID);
    this.focused = momentID;
  };

  setScrollTop = (scrollTop: number) => {
    this.scrollTop = scrollTop;
  };

  setFilteredType = (filteredType: FilterMediaTypes) => {
    this.filteredType = filteredType;
  };

  private loadNewJournal(journalId: string, filteredType: FilterMediaTypes) {
    this.loading = true;
    this.journalId = journalId;
    this.allMomentIds = [];
    this.scrollTop = 0;
    this.cancelIdsSubscription?.();
    // Set up a new subscription!

    this.cancelIdsSubscription = this.momentRepository.subToAllIDs(
      (ids) => this.gotNewMomentIds(journalId, ids),
      filteredType,
      journalId,
      this.primaryViewState.journalsInAllEntries,
    );
  }

  // This is very similar to the gotNewEntryIds function in TimelineViewState
  // There are explanations there about some of the things we are doing here.
  private gotNewMomentIds(journalId: string | undefined, ids: MomentID[]) {
    if (journalId != this.journalId) {
      return;
    }
    if (this.loading) {
      this.fixScrollPosition(this.focused || ids[0]);
    }
    this.loading = false;
    if (ids.length > 1 && isDeepEqual(ids, this.allMomentIds)) {
      return;
    }
    this.allMomentIds = ids;
    if (!this.focused && ids.length) {
      this.setFocused(ids[0]);
    }
  }

  // Computed Views
  get window() {
    const minNumberToShow = Math.min(
      Math.ceil(this.windowHeight.height / HEIGHT_PER_MEDIA_ROW) * 2,
      this.allMomentIds.length,
    );
    const skip = Math.max(
      0,
      Math.floor(this.scrollTop / HEIGHT_PER_MEDIA_ROW) * 2 - minNumberToShow,
    );
    const take = minNumberToShow + minNumberToShow;
    const slice = this.allMomentIds.slice(
      skip,
      Math.min(skip + take, this.allMomentIds.length),
    );

    return {
      totalMomentCount: this.allMomentIds.length,
      fullHeightInPx:
        Math.ceil(this.allMomentIds.length / 2) * HEIGHT_PER_MEDIA_ROW,
      visibleSlice: slice,
      entriesSkipped: skip,
    };
  }

  // Utility functions
  getPositionForMomentId = (momentID: MomentID) => {
    const idx = this.allMomentIds.findIndex((x) => isDeepEqual(x, momentID));
    if (idx === -1) {
      return 0;
    }

    return Math.max(0, Math.floor(idx / 2)) * HEIGHT_PER_MEDIA_ROW;
  };

  fixScrollPosition = (momentID: MomentID) => {
    const isVisible = this.window.visibleSlice.find((media) =>
      isDeepEqual(media, momentID),
    );

    const scrollPosition = this.getPositionForMomentId(momentID);

    if (
      !isVisible ||
      scrollPosition < this.scrollTop ||
      scrollPosition >
        this.scrollTop + this.windowHeight.height - HEIGHT_PER_MEDIA_ROW
    ) {
      this.setScrollTop(scrollPosition);
      this.focusElement(momentID);
    }
  };

  getIndexForMomentId = (momentID: MomentID) => {
    const idx = this.allMomentIds.findIndex((x) => isDeepEqual(x, momentID));
    if (idx === -1) {
      return 0;
    }
    return idx;
  };

  movePrev = () => {
    const prev = this.focused ? this.getIndexForMomentId(this.focused) - 1 : 0;
    if (this.allMomentIds[prev]) {
      this.setFocused(this.allMomentIds[prev]);
      return this.allMomentIds[prev];
    }
  };

  moveNext = () => {
    const next = this.focused ? this.getIndexForMomentId(this.focused) + 1 : 0;
    if (this.allMomentIds[next]) {
      this.setFocused(this.allMomentIds[next]);
      return this.allMomentIds[next];
    }
  };

  moveDown = () => {
    const next = this.focused
      ? this.getIndexForMomentId(this.focused) + 2
      : this.allMomentIds.length - 1;
    const nextIndex =
      next > this.allMomentIds.length - 1 ? this.allMomentIds.length - 1 : next;
    if (this.allMomentIds[nextIndex]) {
      this.setFocused(this.allMomentIds[nextIndex]);
      return this.allMomentIds[nextIndex];
    } else {
      this.setFocused(this.allMomentIds[this.allMomentIds.length - 1]);
      return this.allMomentIds[this.allMomentIds.length - 1];
    }
  };

  moveUp = () => {
    const prev = this.focused ? this.getIndexForMomentId(this.focused) - 2 : 0;
    if (this.allMomentIds[prev]) {
      this.setFocused(this.allMomentIds[prev]);
      return this.allMomentIds[prev];
    } else {
      this.setFocused(this.allMomentIds[0]);
      return this.allMomentIds[0];
    }
  };

  focusElement = (momentID?: MomentID) => {
    if (!momentID) {
      return;
    }
    const elementId = `media-${momentID.id}-${momentID.journal_id}-${momentID.entry_id}`;
    const element = document.getElementById(elementId);
    element?.focus();
  };
}
