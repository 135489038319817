export const Angry: React.FC = () => {
  return (
    <svg
      id="angry"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="url(#paint0_linear_1139_60474)"
        stroke="url(#paint1_linear_1139_60474)"
      />
      <mask
        id="mask0_1139_60474"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="32"
        height="32"
      >
        <circle cx="17" cy="17" r="16" fill="url(#paint2_linear_1139_60474)" />
      </mask>
      <g mask="url(#mask0_1139_60474)">
        <path
          d="M20 26C20 28.2091 18.6569 28 17 28C15.3431 28 14 28.2091 14 26C14 23.7909 15.3431 22 17 22C18.6569 22 20 23.7909 20 26Z"
          fill="#211603"
        />
      </g>
      <circle
        cx="23.5"
        cy="17.5"
        r="2.5"
        fill="url(#paint3_linear_1139_60474)"
      />
      <circle
        cx="10.5"
        cy="17.5"
        r="2.5"
        fill="url(#paint4_linear_1139_60474)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.10535 13.553C6.85836 14.047 7.05859 14.6477 7.55257 14.8946L12.5526 17.3946C13.0465 17.6416 13.6472 17.4414 13.8942 16.9474C14.1412 16.4535 13.941 15.8528 13.447 15.6058L8.44699 13.1058C7.95302 12.8588 7.35234 13.059 7.10535 13.553Z"
        fill="#533A0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8946 13.553C27.1416 14.047 26.9414 14.6477 26.4474 14.8946L21.4474 17.3946C20.9535 17.6416 20.3528 17.4414 20.1058 16.9474C19.8588 16.4535 20.059 15.8528 20.553 15.6058L25.553 13.1058C26.047 12.8588 26.6477 13.059 26.8946 13.553Z"
        fill="#533A0C"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1139_60474"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E74C3C" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1139_60474"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1240E" />
          <stop offset="1" stopColor="#B67500" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1139_60474"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E74C3C" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1139_60474"
          x1="23.5"
          y1="15"
          x2="23.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1139_60474"
          x1="10.5"
          y1="15"
          x2="10.5"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
      </defs>
    </svg>
  );
};
