import { d1Classes } from "@/D1Classes";

export async function declineSharedJournalRequest(
  { userId, journalId }: { userId: string; journalId: string },
  fetchWrapper = d1Classes.fetchWrapper,
) {
  const searchParams = new URLSearchParams();
  searchParams.append("user_id", userId);
  searchParams.append("journal_id", journalId);
  const url = `/shares/decline-access?${searchParams.toString()}`;

  const resp = await fetchWrapper.fetchAPI(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
  if (resp.status !== 200) throw new Error(`${await resp.text()}`);
}
