import { Asymmetric } from "@/crypto/DOCryptoBasics";

export interface AsymmetricKeys {
  publicKeyPEM: string;
  privateKeyPEM: string;
  keyPair: CryptoKeyPair;
}

export const generateNewKeyPair: () => Promise<AsymmetricKeys> = async () => {
  const newKeyPair = await crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-1" },
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    },
    true,
    ["encrypt", "decrypt"],
  );

  return {
    publicKeyPEM: await Asymmetric.Public.toPEM(newKeyPair.publicKey),
    privateKeyPEM: await Asymmetric.Private.toPEM(newKeyPair.privateKey),
    keyPair: newKeyPair,
  };
};
