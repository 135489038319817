export const Care: React.FC = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="url(#paint0_linear_101_86)"
        stroke="#B67500"
      />
      <mask
        id="mask0_101_86"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="32"
        height="32"
      >
        <circle cx="17" cy="17" r="16" fill="url(#paint1_linear_101_86)" />
      </mask>
      <g mask="url(#mask0_101_86)">
        <g filter="url(#filter0_f_101_86)">
          <ellipse cx="7" cy="20.5" rx="4" ry="2.5" fill="#E74C3C" />
        </g>
        <g filter="url(#filter1_f_101_86)">
          <ellipse cx="27" cy="20.5" rx="4" ry="2.5" fill="#E74C3C" />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8838 22.8831C22.1422 23.3712 21.9559 23.9764 21.4678 24.2347C20.1044 24.9562 18.5639 25.3333 17 25.3333C15.4361 25.3333 13.8956 24.9562 12.5322 24.2347C12.0441 23.9764 11.8578 23.3712 12.1161 22.8831C12.3745 22.3949 12.9796 22.2087 13.4678 22.467C14.5367 23.0327 15.7553 23.3333 17 23.3333C18.2447 23.3333 19.4633 23.0327 20.5322 22.467C21.0204 22.2087 21.6255 22.3949 21.8838 22.8831Z"
        fill="#211603"
      />
      <path
        d="M14 16C14 18.2091 12.5 16 10.5 16C8.5 16 7 18.2091 7 16C7 13.7909 9.11929 13 10.5 13C11.8807 13 14 13.7909 14 16Z"
        fill="url(#paint2_linear_101_86)"
      />
      <path
        d="M27 16C27 18.2091 25.5 16 23.5 16C21.5 16 20 18.2091 20 16C20 13.7909 22.1193 13 23.5 13C24.8807 13 27 13.7909 27 16Z"
        fill="url(#paint3_linear_101_86)"
      />
      <path
        d="M6.16647 11.3725L6.5 11.6711L6.83353 11.3725L7.39728 10.8677L7.39729 10.8677L7.39966 10.8656C8.90128 9.50366 10.1116 8.30817 11.026 7.28054C11.9805 6.20786 12.5 5.07132 12.5 3.87858C12.5 2.93384 12.1744 2.11945 11.5203 1.47044C10.8675 0.822785 10.0538 0.5 9.1125 0.5C8.55445 0.5 8.02209 0.631249 7.52155 0.890127C7.16672 1.07365 6.83225 1.3323 6.516 1.65624C6.21216 1.32853 5.87256 1.06819 5.49487 0.88457C4.97465 0.631656 4.43739 0.5 3.8875 0.5C2.94624 0.5 2.13246 0.822785 1.47971 1.47044C0.825588 2.11945 0.5 2.93384 0.5 3.87858C0.5 5.07132 1.01947 6.20786 1.97397 7.28054C2.88839 8.30817 4.09872 9.50366 5.60034 10.8656L5.60272 10.8677L6.16647 11.3725Z"
        fill="#E74C3C"
        stroke="#9D1103"
      />
      <path
        d="M28.1706 33.3762L28.5 33.6645L28.8294 33.3762L29.2906 32.9724L29.2906 32.9724L29.293 32.9703C30.523 31.8795 31.516 30.9206 32.2673 30.0951C33.0589 29.2252 33.5 28.292 33.5 27.3029C33.5 26.5182 33.2222 25.8395 32.6675 25.3014C32.1149 24.7653 31.427 24.5 30.6375 24.5C30.1681 24.5 29.7196 24.608 29.2981 24.8212C29.0215 24.9611 28.7608 25.1531 28.5145 25.3898C28.2745 25.1495 28.008 24.9559 27.7134 24.8158C27.2772 24.6084 26.8255 24.5 26.3625 24.5C25.573 24.5 24.8851 24.7653 24.3325 25.3014C23.7778 25.8395 23.5 26.5182 23.5 27.3029C23.5 28.292 23.9411 29.2252 24.7327 30.0951C25.484 30.9206 26.477 31.8795 27.707 32.9703L27.707 32.9703L27.7094 32.9724L28.1706 33.3762Z"
        fill="#E74C3C"
        stroke="#9D1103"
      />
      <defs>
        <filter
          id="filter0_f_101_86"
          x="-3"
          y="12"
          width="20"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_101_86"
          />
        </filter>
        <filter
          id="filter1_f_101_86"
          x="17"
          y="12"
          width="20"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_101_86"
          />
        </filter>
        <linearGradient
          id="paint0_linear_101_86"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF788" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_101_86"
          x1="17"
          y1="1"
          x2="17"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCF788" />
          <stop offset="1" stopColor="#EF9E0B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_101_86"
          x1="10.5"
          y1="11"
          x2="10.5"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_101_86"
          x1="23.5"
          y1="11"
          x2="23.5"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64460F" />
          <stop offset="1" stopColor="#211603" />
        </linearGradient>
      </defs>
    </svg>
  );
};
