import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  DailyPrompt,
  DailyPromptSettings,
} from "@/data/repositories/PromptsAPI";

export const defaultDailyPromptSettings = {
  default_journal: null,
  tags: [],
  show_daily_prompts: true,
};

export class DailyPromptRepository {
  constructor(private fetch: FetchWrapper) {}

  async fetchSettingsFromServer(): Promise<DailyPromptSettings | null> {
    const res = await this.fetch.fetchAPI(
      "/daily-prompts/user/config",
      undefined,
      {
        expectedStatusCodes: [404],
      },
    );

    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else if (res.status === 404) {
      return defaultDailyPromptSettings;
    } else {
      Sentry.captureException(
        new Error(
          `Error synchronizing config: ${res.status}. Res: ${JSON.stringify(
            res,
          )}`,
        ),
      );
    }

    return null;
  }

  async saveSettings(
    settings: DailyPromptSettings,
  ): Promise<DailyPromptSettings | null> {
    const res = await this.fetch.fetchAPI("/daily-prompts/user/config", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    });

    if (res.status === 200) {
      const json = await res.json();

      return json;
    } else {
      Sentry.captureException(
        new Error(
          `Error synchronizing config: ${res.status}. Res: ${JSON.stringify(
            res,
          )}`,
        ),
      );
    }
    return null;
  }

  async fetchPromptByDay(day: string): Promise<DailyPrompt | null> {
    const res = await this.fetch.fetchAPI(`/daily-prompts/date/${day}`);
    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else {
      Sentry.captureException(
        new Error(
          `Error fetching prompt for  day ${day}. Status: ${res.status}`,
        ),
      );
    }
    return null;
  }

  async fetchPromptById(id: string): Promise<DailyPrompt | null> {
    const res = await this.fetch.fetchAPI(`/daily-prompts/${id}`);
    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else {
      Sentry.captureException(
        new Error(`Failed to fetch daily prompt ${id}. Status: ${res.status} `),
      );
    }
    return null;
  }

  async fetchPromptsByDateRange(
    count: number,
    date: string,
  ): Promise<DailyPrompt[] | null> {
    const res = await this.fetch.fetchAPI(
      `/daily-prompts/?fetch_count=${count}&after_date=${date}`,
    );
    if (res.status === 200) {
      const json = await res.json();
      return json;
    } else {
      Sentry.captureException(new Error("Error fetching prompt by day"));
    }
    return null;
  }
}
