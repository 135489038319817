import { ThemeUIStyleObject } from "@theme-ui/css";

const LineDivider: React.FC<{
  styles?: ThemeUIStyleObject;
}> = ({ styles }) => {
  return (
    <span
      sx={{
        height: "1px",
        bg: "borderPrimary",
        position: "relative",
        my: "4px",
        mx: 2.5,
        display: "block",
        ...styles,
      }}
    />
  );
};

export default LineDivider;
