import { VaultDBRow } from "../db/migrations/vault";

import { Grant, Key } from "./Syncables";

import { Asymmetric, Symmetric } from "@/crypto/DOCryptoBasics";
import { SecureKeyValueStore } from "@/data/db/SecureKeyValueStore";
import { DODexie } from "@/data/db/dexie_db";

export type InflatedVault = {
  journal_id: string;
  vault: {
    grants: Grant[];
    keys: Key[];
    vault_key_fingerprint: string;
  };
};

export class VaultRepository {
  // We have multiple caches here. We cache decrypted journal keys
  // in IndexedDB so we don't have to decrypt them over and over.
  // And then we also cache them in memory upon retrieval or
  // set so that fetches don't add load to the DB.
  // Keys are immutable, so we can cache them forever.
  // But the cache needs to live in IndexedDB so that values set
  // by one thread can be read by another thread. If we only cached
  // in memory, then the main thread wouldn't be able to read keys
  // that the worker had already unlocked.
  // The keys that are stored here are
  // vault keys by journal ID, and journal keys by fingerprint.
  inMemoryKeyCache: Map<string, CryptoKey> = new Map();

  constructor(
    private db: DODexie,
    private kv: SecureKeyValueStore,
  ) {}

  async upsertVaults(vaults: VaultDBRow[]) {
    if (vaults.length) {
      await this.db.vaults.bulkPut(vaults);
    }
  }

  async readAll() {
    const vaults = await this.db.vaults.toArray();

    return vaults.map((v) => {
      return {
        journal_id: v.journal_id,
        ...JSON.parse(v.vault_json),
      };
    }) as InflatedVault[];
  }

  async getVaultByJournalId(journalId: string) {
    const vault = await this.db.vaults.get(journalId);

    if (vault) {
      return {
        journal_id: vault.journal_id,
        ...JSON.parse(vault.vault_json),
      } as InflatedVault;
    } else {
      return null;
    }
  }

  // Journal keys are cached by fingerprint, not journal ID.
  async cacheJournalKey(fingerprint: string, key: CryptoKey) {
    const id = `journal_key:${fingerprint}`;
    await this.kv.set(id, key, Asymmetric.Private.toUint8Array);
    this.inMemoryKeyCache.set(id, key);
  }
  async getJournalKey(fingerprint: string) {
    const id = `journal_key:${fingerprint}`;
    const memCachedKey = this.inMemoryKeyCache.get(id);
    if (memCachedKey) {
      return memCachedKey;
    }
    const dbCryptoKey = await this.kv.get(id, Asymmetric.Private.fromBuffer);
    if (dbCryptoKey && dbCryptoKey instanceof CryptoKey) {
      this.inMemoryKeyCache.set(id, dbCryptoKey);
      return dbCryptoKey;
    }
    return null;
  }
  // Vault keys are cached by journal ID, not fingerprint.
  async cacheVaultKey(journalId: string, key: CryptoKey) {
    const id = `vault_key:${journalId}`;
    await this.kv.set(id, key, Symmetric.Key.toUintArray);
    this.inMemoryKeyCache.set(id, key);
  }
  async getVaultKey(journalId: string) {
    const id = `vault_key:${journalId}`;
    const memCachedKey = this.inMemoryKeyCache.get(id);
    if (memCachedKey) {
      return memCachedKey;
    }
    const dbCryptoKey = await this.kv.get(id, Symmetric.Key.fromBuffer);
    if (dbCryptoKey && dbCryptoKey instanceof CryptoKey) {
      this.inMemoryKeyCache.set(id, dbCryptoKey);
      return dbCryptoKey;
    }
    return null;
  }
}
