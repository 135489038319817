import { PropsWithChildren } from "react";

import { HeaderBar } from "@/components/LoggedOut/HeaderBar";
import { LayoutProvider } from "@/data/hooks/LayoutProvider";

type Props = {
  background?: string;
  noBorder?: boolean;
  showIconLogo?: boolean;
};
export const LoggedOut: React.FC<PropsWithChildren<Props>> = ({
  background = "surface_light2_dark2",
  children,
  noBorder = true,
  showIconLogo,
}) => {
  return (
    <LayoutProvider>
      <div
        sx={{
          backgroundColor: background,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <HeaderBar
          noBorder={noBorder}
          background={background}
          showIconLogo={showIconLogo}
        />
        {children}
      </div>
    </LayoutProvider>
  );
};
