import { DOCrypto } from "@/crypto/DOCrypto";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export const decryptComment = async (
  comment: string,
  debugContext: string,
  vaultRepository: VaultRepository,
) => {
  const d1 = fromBase64(comment);
  const { decrypted } = await DOCrypto.D1.decrypt(
    d1,
    debugContext,
    vaultRepository,
  );
  return Utf8.fromBufferSource(decrypted);
};
