import { observer } from "mobx-react-lite";

import { DownloadingIndicator } from "@/components/Search/DownloadingIndicator";
import { PartialResultsIndicator } from "@/components/Search/PartialResultsIndicator";
import { SearchResultsSummary } from "@/components/Search/SearchResultsSummary";
import { searchViewState } from "@/view_state/ViewStates";
export const resultsSx = {
  "&&": { pl: 2, py: 0, fontSize: 1, color: "textSecondary" },
};

export const SearchResultsStatus: React.FC = observer(() => {
  const { isPartial, indexStatus, journalsDownloading } = searchViewState;

  return (
    <div
      sx={{
        "&&": {
          py: 2,
          my: 2,
          backgroundColor:
            isPartial || journalsDownloading ? "surface_light2_dark2" : "none",
        },
      }}
    >
      {(indexStatus === "INDEXING" || indexStatus === "DOWNLOADING") && (
        <DownloadingIndicator />
      )}
      {indexStatus === "DONE" &&
        (isPartial ? <PartialResultsIndicator /> : <SearchResultsSummary />)}
    </div>
  );
});
