export const ignoreErrors = [
  // Ignore failed update of service worker messages, not much we can do for these.
  /Failed to update a ServiceWorker for scope (.)*: An unknown error occurred when fetching the script./,
  // Large images from s3 trigger this, basically saying the payload was too large or too slow. This is expected
  // for media coming from s3.
  "Large HTTP payload",
  "ResizeObserver loop completed with undelivered notifications.",
];

export const tracePropagationTargets = [
  "localhost",
  /^https:\/\/dayone\.me/,
  /^https:\/\/my\.dayone\.app/,
  /^\//,
];
