import { IconProps } from "@/components/icons/types";

export const VisibilityOff: React.FC<IconProps> = ({ size = 24, color }) => {
  return (
    <svg
      height={size}
      width={size}
      sx={{ fill: color }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.775 12.975 14.65 11.85q.225-1.25-.712-2.237Q13 8.625 11.65 8.85l-1.125-1.125q.35-.15.7-.225.35-.075.775-.075 1.7 0 2.887 1.188Q16.075 9.8 16.075 11.5q0 .425-.075.787-.075.363-.225.688Zm3.175 3.1-1.1-1.025q.95-.725 1.688-1.588.737-.862 1.262-1.962-1.25-2.525-3.588-4.013Q14.875 6 12 6q-.725 0-1.425.1-.7.1-1.375.3L8.025 5.225q.95-.375 1.938-.55Q10.95 4.5 12 4.5q3.525 0 6.35 1.938 2.825 1.937 4.1 5.062-.55 1.35-1.425 2.512-.875 1.163-2.075 2.063Zm.8 5.8-4.025-4.025q-.775.3-1.712.475-.938.175-2.013.175-3.525 0-6.338-1.938Q2.85 14.625 1.55 11.5q.55-1.325 1.425-2.475Q3.85 7.875 4.9 7.05l-2.775-2.8 1.05-1.075 17.65 17.65ZM5.95 8.1q-.8.625-1.537 1.513Q3.675 10.5 3.2 11.5q1.25 2.525 3.587 4.012Q9.125 17 12 17q.675 0 1.35-.113.675-.112 1.15-.237l-1.25-1.3q-.275.1-.6.162-.325.063-.65.063-1.7 0-2.887-1.188Q7.925 13.2 7.925 11.5q0-.3.063-.638.062-.337.162-.612Zm7.575 2.625Zm-3.3 1.65Z" />
    </svg>
  );
};
