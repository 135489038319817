import { Symmetric } from "@/crypto/DOCryptoBasics";
import { VaultRepository } from "@/data/repositories/VaultRepository";

export const decryptJournalD1 = async (
  journalId: string,
  encryptedJournalD1: string,
  vaultRepository: VaultRepository,
) => {
  const vaultKey = await vaultRepository.getVaultKey(journalId);
  if (vaultKey) {
    return Symmetric.decryptD1(encryptedJournalD1, vaultKey);
  } else {
    return "Could not decrypt name";
  }
};
