type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label: React.FC<LabelProps> = (props) => {
  return (
    <label
      sx={{
        fontSize: 1,
        color: "textPrimary",
        pb: 1,
        alignItems: "flex-start",
        display: "flex",
      }}
      {...props}
    />
  );
};
