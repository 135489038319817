import { Icon } from "@wordpress/components";
import { SuggestionTypes } from "types/rtj-format";

import { Activity } from "@/components/icons/suggestions/Activity";
import { Contact } from "@/components/icons/suggestions/Contact";
import { Location } from "@/components/icons/suggestions/Location";
import { Podcast } from "@/components/icons/suggestions/Podcast";
import { Song } from "@/components/icons/suggestions/Song";
import { Workout } from "@/components/icons/suggestions/Workout";

const getIconForSuggestion = (suggestionType: SuggestionTypes) => {
  if (suggestionType === "contact") {
    return Contact;
  }
  if (suggestionType === "song") {
    return Song;
  }
  if (suggestionType === "motionActivity") {
    return Activity;
  }
  if (suggestionType === "workout") {
    return Workout;
  }
  if (suggestionType === "location") {
    return Location;
  }
  if (suggestionType === "podcast") {
    return Podcast;
  }

  return Activity;
};

type Props = {
  type: SuggestionTypes;
};

export const SuggestionThumbnail: React.FC<Props> = ({ type }) => {
  return (
    <div
      className="entry-media entry-media-thumbnail"
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "sm",
        background: "surface_light2_dark2",
        color: "textPrimary",
        flexShrink: 0,
      }}
    >
      <Icon icon={getIconForSuggestion(type)} size={40} />
    </div>
  );
};
