import * as Base64Arraybuffer from "base64-arraybuffer";

import { Asymmetric, Fingerprint, Symmetric } from "@/crypto/DOCryptoBasics";
import { toHex } from "@/crypto/utils";
import { Grant } from "@/data/repositories/Syncables";

export const makeNewGrant = async (
  userId: string,
  userPublicKey: CryptoKey,
  vaultKey: CryptoKey,
): Promise<Grant> => {
  const newJournalVaultKeyBytes = await Symmetric.Key.toUintArray(vaultKey);

  const encryptedKey = await Asymmetric.Public.encrypt(
    userPublicKey,
    newJournalVaultKeyBytes,
  );
  const digest = await crypto.subtle.digest("SHA-256", newJournalVaultKeyBytes);

  const newGrant: Grant = {
    user_id: userId,
    // Refer to: https://github.com/bloom/DayOne-Apple/blob/fe95266936656eebb0c65e7add7e554ca68aa823/core/DOCore/DOCore/DOWebEncryptionTypes.swift#L136
    user_fingerprint: await Fingerprint.forPublicKey(userPublicKey),
    vault_key_fingerprint: toHex(new Uint8Array(digest)).toLowerCase(),
    encrypted_vault_key: Base64Arraybuffer.encode(encryptedKey),
  };
  return newGrant;
};
