export const Workout: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7C5 5.89543 5.89543 5 7 5H8C9.10457 5 10 5.89543 10 7V11H14V7C14 5.89543 14.8954 5 16 5H17C18.1046 5 19 5.89543 19 7H20C21.1046 7 22 7.89543 22 9V11H23C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13H22V15C22 16.1046 21.1046 17 20 17H19C19 18.1046 18.1046 19 17 19H16C14.8954 19 14 18.1046 14 17V13H10V17C10 18.1046 9.10457 19 8 19H7C5.89543 19 5 18.1046 5 17H4C2.89543 17 2 16.1046 2 15V13H1C0.447715 13 0 12.5523 0 12C0 11.4477 0.447715 11 1 11H2V9C2 7.89543 2.89543 7 4 7L5 7ZM5 15H4V9H5V15ZM7 17V7L8 7V17H7ZM20 15H19V9H20V15ZM17 7V17H16V7L17 7Z"
        fill="currentColor"
      />
    </svg>
  );
};
