import {
  addDays,
  addMonths,
  endOfDay,
  lastDayOfMonth,
  isAfter,
  isBefore,
} from "date-fns";
import { makeAutoObservable, reaction } from "mobx";

import {
  EntryKeys,
  EntryRepository,
} from "@/data/repositories/EntryRepository";
import { isDeepEqual } from "@/utils/is-equal";
import {
  journalId_AllEntries,
  PrimaryViewState,
} from "@/view_state/PrimaryViewState";
import { WindowHeightViewState } from "@/view_state/WindowHeightViewState";

export type CalendarViewMonth = {
  month: number;
  year: number;
};

export const HEIGHT_PER_CALENDAR = 332;

export class CalendarViewState {
  constructor(
    private entryRepository: EntryRepository,
    private windowHeight: WindowHeightViewState,
    private primaryViewState: PrimaryViewState,
  ) {
    makeAutoObservable(
      this,
      {
        getIndexForCalendarId: false,
        getPositionForCalendarId: false,
        cancelEntryKeysSubscription: false,
        fixScrollPosition: false,
        moveNextDay: false,
        isDateValid: false,
        movePreviousDay: false,
        moveNextWeek: false,
        movePreviousWeek: false,
        moveNextMonth: false,
        movePreviousMonth: false,
        moveFirstMonth: false,
        moveLastMonth: false,
      },
      { autoBind: true },
    );

    // Ensure the calendar is in view if the selected date changes
    reaction(
      () => this.selectedDate,
      (selectedDate) => {
        this.fixScrollPosition(selectedDate);
        this.focusedDate = selectedDate;
      },
      { name: "CalendarViewState_watchSelectedDate", fireImmediately: true },
    );

    // Ensure the calendar is in view if the focused date changes
    reaction(
      () => this.focusedDate,
      (focusedDate) => {
        this.fixScrollPosition(focusedDate);
      },
      {
        name: "CalendarViewState_watchFocusedDate",
        fireImmediately: true,
      },
    );

    reaction(
      () => this.primaryViewState.selectedJournal,
      (selectedJournal) => {
        const journalId = selectedJournal?.id || journalId_AllEntries;
        if (this.journalId == journalId) {
          return;
        }
        this.loadNewCalendars(journalId);
      },
      { name: "CalendarViewState_watchSelectedJournal", fireImmediately: true },
    );

    reaction(
      () => this.allCalendarMonths,
      () => {
        this.fixScrollPosition(this.selectedDate);
      },
      { name: "CalendarViewState_watchAllCalendars", fireImmediately: true },
    );

    // If the current focused date is no longer visible in the list of calendars then
    // update the focused date to be in the first visible calendar
    reaction(
      () => this.window.visibleSlice,
      () => {
        if (this.window.visibleSlice.length === 0) {
          return;
        }
        if (
          isAfter(this.focusedDate, this.lastVisibleDate) ||
          isBefore(this.focusedDate, this.firstVisibleDate)
        ) {
          this.setFocusedDate(this.firstVisibleDate);
        }
      },
      { name: "CalendarViewState_watchVisibleSlice", fireImmediately: true },
    );
  }

  focusedDate = new Date();
  cancelEntryKeysSubscription: null | (() => void) = null;
  entryKeys: EntryKeys[] = [];
  journalId: string | undefined = undefined;
  scrollTop = 0;
  loading = true;
  selectedDate = new Date();
  currentCalendarMonth = {
    month: this.selectedDate.getMonth(),
    year: this.selectedDate.getFullYear(),
  };
  dateSetFromURL = false;

  // Actions
  setScrollTop = (scrollTop: number) => {
    this.scrollTop = scrollTop;
  };

  setDateSetFromURL = (isSet: boolean) => {
    this.dateSetFromURL = isSet;
  };

  setSelectedDate = (date: string | null) => {
    this.selectedDate = date ? new Date(date) : new Date();
  };

  setFocusedDate = (date: Date) => {
    const elementId = `date-${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    const element = document.getElementById(elementId);
    element?.focus();
    this.focusedDate = date;
  };

  private async loadNewCalendars(journalId: string) {
    this.loading = true;
    this.journalId = journalId;
    this.cancelEntryKeysSubscription?.();
    // Set up a new subscription!
    this.cancelEntryKeysSubscription =
      this.entryRepository.subToCalendarEntryKeys(
        journalId,
        (keys: EntryKeys[]) => this.gotNewEntryKeys(keys),
      );
  }

  private async gotNewEntryKeys(newKeys: EntryKeys[]) {
    if (isDeepEqual(this.entryKeys.sort(), newKeys.sort())) {
      this.loading = false;
      return;
    }
    this.entryKeys = newKeys;
    // If the loading state is set to true we are changing journals and we should reset the scroll postion
    // If it is false we're receiving a passive update from the database and should leave the scroll position untouched.
    if (this.loading) {
      this.fixScrollPosition(this.selectedDate);
    }
    this.loading = false;
  }

  get allCalendarMonths() {
    const calendarList = [];
    const now = new Date();
    const firstEntryKey: EntryKeys = this.entryKeys[0];
    const lastEntryKey: EntryKeys = this.entryKeys[this.entryKeys.length - 1];
    let firstEntryDate = firstEntryKey ? new Date(firstEntryKey.date) : now;
    let lastEntryDate = lastEntryKey ? new Date(lastEntryKey.date) : now;
    if (isBefore(this.selectedDate, firstEntryDate)) {
      firstEntryDate = this.selectedDate;
    }
    if (isAfter(this.selectedDate, lastEntryDate)) {
      lastEntryDate = this.selectedDate;
    }
    const firstCalendarDate = isBefore(now, firstEntryDate)
      ? addMonths(now, -2)
      : addMonths(firstEntryDate, -2);
    const lastCalendarDate = isAfter(now, lastEntryDate)
      ? addMonths(now, 2)
      : addMonths(lastEntryDate, 2);
    const numberOfMonths =
      (lastCalendarDate.getFullYear() - firstCalendarDate.getFullYear()) * 12 +
      (lastCalendarDate.getMonth() - firstCalendarDate.getMonth());

    for (let i = 0; i <= numberOfMonths; i++) {
      const calendarDate = addMonths(firstCalendarDate, i);
      calendarList.push({
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth(),
      });
    }
    return calendarList;
  }

  // Computed Views
  get window() {
    const skip = Math.max(0, Math.floor(this.scrollTop / HEIGHT_PER_CALENDAR));
    const take = Math.ceil(this.windowHeight.height / HEIGHT_PER_CALENDAR);
    const slice = this.allCalendarMonths.slice(
      skip,
      Math.min(skip + take, this.allCalendarMonths.length),
    );

    return {
      totalCalendarCount: this.allCalendarMonths.length,
      fullHeightInPx: this.allCalendarMonths.length * HEIGHT_PER_CALENDAR,
      visibleSlice: slice,
      entriesSkipped: skip,
    };
  }

  get firstCalendarMonth() {
    return (
      this.allCalendarMonths[0] || {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth(),
      }
    );
  }
  get lastCalendarMonth() {
    return (
      this.allCalendarMonths[this.allCalendarMonths.length - 1] || {
        year: this.selectedDate.getFullYear(),
        month: this.selectedDate.getMonth(),
      }
    );
  }

  get maxCalendarDate() {
    return endOfDay(
      lastDayOfMonth(
        new Date(this.lastCalendarMonth.year, this.lastCalendarMonth.month, 1),
      ),
    );
  }

  get minCalendarDate() {
    return new Date(
      this.firstCalendarMonth.year,
      this.firstCalendarMonth.month,
      1,
    );
  }

  get firstVisibleDate() {
    const firstVisibleMonth = this.window.visibleSlice[0] || {
      year: this.selectedDate.getFullYear(),
      month: this.selectedDate.getMonth(),
    };
    return new Date(firstVisibleMonth.year, firstVisibleMonth.month, 1);
  }

  get lastVisibleDate() {
    const lastVisibleMonth = this.window.visibleSlice[
      this.window.visibleSlice.length - 1
    ] || {
      year: this.selectedDate.getFullYear(),
      month: this.selectedDate.getMonth(),
    };
    return lastDayOfMonth(
      new Date(lastVisibleMonth.year, lastVisibleMonth.month, 1),
    );
  }

  // Utility functions, these should be set to "false"
  // in the makeAutoObservable call above.
  getIndexForCalendarId = (
    year: number | undefined,
    month: number | undefined,
  ) => {
    const idx = this.allCalendarMonths.findIndex(
      (x) => x.year === year && x.month === month,
    );
    return idx === -1 ? 0 : idx;
  };

  getPositionForCalendarId = (
    year: number | undefined,
    month: number | undefined,
  ) => {
    const idx = this.allCalendarMonths.findIndex(
      (x) => x.year === year && x.month === month,
    );
    if (idx === -1) {
      return 0;
    }
    return idx * HEIGHT_PER_CALENDAR;
  };

  fixScrollPosition = (date: Date) => {
    const selectedCalendarMonth = {
      month: date.getMonth(),
      year: date.getFullYear(),
    };
    this.currentCalendarMonth = selectedCalendarMonth;
    const calendarIsVisible = this.window.visibleSlice.find(
      (calendarMonth) =>
        calendarMonth.month === selectedCalendarMonth.month &&
        calendarMonth.year === selectedCalendarMonth.year,
    );
    const selectedIndex = this.getIndexForCalendarId(
      selectedCalendarMonth.year,
      selectedCalendarMonth.month,
    );
    const calendarScrollPosition = selectedIndex * HEIGHT_PER_CALENDAR;

    if (
      !calendarIsVisible ||
      calendarScrollPosition < this.scrollTop ||
      calendarScrollPosition >
        this.scrollTop + this.windowHeight.height - HEIGHT_PER_CALENDAR
    ) {
      this.setScrollTop(calendarScrollPosition);
    }
  };

  moveNextDay = () => {
    const nextDay = addDays(this.focusedDate, 1);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  movePreviousDay = () => {
    const nextDay = addDays(this.focusedDate, -1);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  moveNextWeek = () => {
    const nextDay = addDays(this.focusedDate, 7);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  movePreviousWeek = () => {
    const nextDay = addDays(this.focusedDate, -7);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  moveNextMonth = () => {
    const nextDay = addMonths(this.focusedDate, 1);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  movePreviousMonth = () => {
    const nextDay = addMonths(this.focusedDate, -1);
    if (this.isDateValid(nextDay)) {
      this.setFocusedDate(nextDay);
      return nextDay;
    }
  };

  moveFirstMonth = () => {
    this.setFocusedDate(this.minCalendarDate);
    return this.minCalendarDate;
  };

  moveLastMonth = () => {
    this.setFocusedDate(this.maxCalendarDate);
    return this.maxCalendarDate;
  };

  isDateValid = (date: Date) => {
    if (date >= this.minCalendarDate && date <= this.maxCalendarDate) {
      return true;
    } else {
      return false;
    }
  };
}
