export const MetadataDivider: React.FC = () => {
  return (
    <div
      sx={{
        borderBottom: "1px solid",
        borderColor: "borderPrimary",
        mx: "-2rem",
        my: 1,
        height: "3px",
      }}
    />
  );
};
