export const LargeKey: React.FC = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 40.846C19.6577 40.846 18.5144 40.3739 17.5702 39.4297C16.626 38.4855 16.1539 37.3422 16.1539 35.9999C16.1539 34.6576 16.626 33.5144 17.5702 32.5702C18.5144 31.626 19.6577 31.1539 21 31.1539C22.3423 31.1539 23.4855 31.626 24.4297 32.5702C25.3739 33.5144 25.846 34.6576 25.846 35.9999C25.846 37.3422 25.3739 38.4855 24.4297 39.4297C23.4855 40.3739 22.3423 40.846 21 40.846ZM21 52.4999C16.4231 52.4999 12.5289 50.8941 9.31737 47.6825C6.10587 44.471 4.50012 40.5768 4.50012 35.9999C4.50012 31.423 6.10587 27.5288 9.31737 24.3173C12.5289 21.1058 16.4231 19.5 21 19.5C24.2153 19.5 27.123 20.3298 29.723 21.9895C32.323 23.6491 34.3076 25.8193 35.6768 28.5H62.6826L70.1825 35.9999L58.6442 47.4806L53.0769 43.2979L47.3654 47.5382L41.5097 43.4999H35.6768C34.3076 46.1614 32.323 48.3267 29.723 49.996C27.123 51.6652 24.2153 52.4999 21 52.4999ZM21 47.9999C23.8769 47.9999 26.3683 47.1307 28.474 45.3923C30.5798 43.6538 31.9538 41.523 32.5961 38.9999H42.9231L47.2443 41.9884L53.1058 37.7019L58.2 41.596L63.7962 35.9999L60.7962 32.9999H32.5961C31.9538 30.4769 30.5798 28.3461 28.474 26.6076C26.3683 24.8692 23.8769 23.9999 21 23.9999C17.7 23.9999 14.875 25.1749 12.525 27.5249C10.175 29.8749 8.99997 32.6999 8.99997 35.9999C8.99997 39.2999 10.175 42.1249 12.525 44.4749C14.875 46.8249 17.7 47.9999 21 47.9999Z"
        fill="currentColor"
      />
    </svg>
  );
};
