import { Button, __experimentalText as Text } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n/";
import { observer } from "mobx-react-lite";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { D1Dropdown } from "@/components/D1Dropdown";
import {
  Reaction,
  reaction_values,
} from "@/components/SharedJournals/Reaction";
import { ReactionType } from "@/data/db/migrations/reaction";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  journalColor?: string;
};

export const Reactor: React.FC<Props> = observer(({ journalColor }) => {
  const user = primaryViewState.user;
  const state = viewStates.activeEntry;
  const { __ } = useI18n();
  const currentUserReaction = state.reactions.find(
    (r) => r.user_id === user?.id,
  );

  const addReaction = async (reaction: ReactionType) => {
    const event = currentUserReaction?.reaction
      ? EVENT.entryReactionEdited
      : EVENT.entryReactionAdded;
    const globalID = state.entryModel?.globalID;
    if (globalID) {
      analytics.tracks.recordEvent(event, {
        type: reaction,
        entry_id: globalID.id,
      });
      d1Classes.reactionStore.addReaction(globalID, reaction);
    }
  };

  const removeReaction = () => {
    const globalID = state.entryModel?.globalID;
    if (globalID && user) {
      analytics.tracks.recordEvent(EVENT.entryReactionDeleted, {
        entry_id: globalID.id,
      });
      d1Classes.reactionStore.removeReaction(globalID, user?.id);
    }
  };

  const reactionToShow = currentUserReaction?.reaction
    ? currentUserReaction.reaction === "like"
      ? "add-reaction"
      : currentUserReaction.reaction
    : undefined;

  return (
    <D1Dropdown
      popoverProps={{ placement: "top-start" }}
      sx={{
        "&.reactions-contaner": { minWidth: "35px" },
      }}
      renderToggle={({ isOpen, onToggle }) => (
        <Button
          variant="tertiary"
          onClick={onToggle}
          aria-expanded={isOpen}
          label={
            currentUserReaction
              ? currentUserReaction.reaction
              : __("Add Reaction")
          }
          sx={{
            "&.components-button.is-tertiary": {
              display: "flex",
              gap: 2,
              fontSize: 3,
              fontWeight: "normal",
              textTransform: "capitalize",
              color: "textSecondary",
            },
            "&.components-button.is-tertiary svg": {
              color: "textTertiary",
              width: "24px",
              height: "24px",
            },
            "& svg#like circle": {
              fill: "surface_light1_dark1",
              stroke: "surface_light1_dark1",
            },
          }}
        >
          {reactionToShow ? (
            <Reaction reaction={reactionToShow} color={journalColor} />
          ) : (
            <Reaction reaction="add-reaction" />
          )}
          <Text sx={{ color: "textSecondary" }}>
            {currentUserReaction?.reaction || __("Like")}
          </Text>
        </Button>
      )}
      renderContent={() => (
        <div
          sx={{
            display: "flex",
          }}
        >
          {reaction_values.map((reaction: ReactionType) => {
            const action =
              currentUserReaction?.reaction === reaction
                ? removeReaction
                : addReaction;
            return (
              <Button
                sx={{ width: "35px" }}
                key={reaction}
                label={reaction}
                onClick={() => action(reaction)}
                variant="tertiary"
                icon={() => (
                  <Reaction reaction={reaction} color={journalColor} />
                )}
              />
            );
          })}
        </div>
      )}
    />
  );
});

Reactor.displayName = "Reactor";
