import { store as blockEditorStore } from "@wordpress/block-editor";
import { useRefEffect } from "@wordpress/compose";
import { useSelect, useDispatch } from "@wordpress/data";
import { useRef } from "@wordpress/element";

import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_UP,
} from "@/components/Editor/utils/keycodes";

// Handle arrow keys to move between blocks
export default function useArrowNav(clientId: string) {
  const propsRef = useRef(clientId);

  const { selectionChange } = useDispatch(blockEditorStore);
  const {
    getBlock,
    getNextBlockClientId,
    getSelectionEnd,
    getPreviousBlockClientId,
  } = useSelect(blockEditorStore, [clientId]);

  return useRefEffect((element) => {
    const onKeyDown = (event: KeyboardEvent) => {
      const { key, shiftKey, altKey, metaKey, ctrlKey } = event;
      if (
        event.defaultPrevented ||
        (key !== ARROW_UP && key !== ARROW_DOWN && key !== ARROW_LEFT) ||
        // Only override when no modifiers are pressed.
        shiftKey ||
        altKey ||
        metaKey ||
        ctrlKey
      ) {
        return;
      }
      const clientId = propsRef.current;

      const prevBlockClientId = getPreviousBlockClientId(clientId);
      const prevBlock = getBlock(prevBlockClientId);
      const prevContentLength = prevBlock?.attributes.content?.length ?? 0;

      const nextBlockClientId = getNextBlockClientId(clientId);
      const nextBlock = getBlock(nextBlockClientId);
      const nextContentLength = nextBlock?.attributes.content?.length ?? 0;

      const selectionEnd = getSelectionEnd();
      let selOffset = selectionEnd?.offset ?? 0;
      let navDirection = key === ARROW_DOWN ? "down" : "up";
      if (key === ARROW_LEFT) {
        if (selOffset === 0) {
          navDirection = "up";
          selOffset = prevContentLength;
        } else {
          return;
        }
      }
      event.preventDefault();

      const newSelection =
        navDirection === "down" ? nextBlockClientId : prevBlockClientId;
      if (navDirection === "up") {
        selOffset = Math.min(selOffset, prevContentLength);
      } else {
        selOffset = Math.min(selOffset, nextContentLength);
      }
      selectionChange(newSelection, "content", selOffset, selOffset);
    };

    element.addEventListener("keydown", onKeyDown as EventListener);
    return () => {
      element.removeEventListener("keydown", onKeyDown as EventListener);
    };
  }, []);
}
