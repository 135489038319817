import { gunzipSync } from "fflate";
import { makeAutoObservable } from "mobx";
import { MouseEvent } from "react";
import { RTJNode } from "types/rtj-format";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { getRTJContentFromPrompt } from "@/components/DailyPrompts/utils";
import { convertBlocksToMarkdown } from "@/components/Editor/gb2rtj/gb2md";
import { convertRTJToBlocks } from "@/components/Editor/rtj2gb/rtj2gb";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";
import { JournalDBRow } from "@/data/db/migrations/journal";
import { getClientMeta } from "@/data/utils/clientMeta";
import { createRichTextJsonString, isTextNode } from "@/utils/rtj";
import { mkEntryID, uuid } from "@/utils/uuid";
import { Blocks } from "@/view_state/ActiveEntryViewState";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";

// This is a mobx view state that only needs to be alive if this route is mounted. I'm
// not putting it on viewStates because it'll be constructed or destructed every time
// the route is mounted or unmounted. But I wanted cleaner code than I'd get if I
// put the logic in the component.

export class PrefilledEntryViewState {
  rtj: RTJNode[] = [];
  prefilledId: string | null = null;
  error = false;
  primaryViewState: PrimaryViewState;
  type: "entry" | "prompt" | "empty" = "entry";
  promptId: string | null = null;
  prompt: string | null = null;
  appLink: string;

  constructor(primaryViewState: PrimaryViewState) {
    makeAutoObservable(this, { recordEvent: false }, { autoBind: true });

    const params = new URLSearchParams(window.location.search);
    const content = params.get("content");
    const id = params.get("id");
    this.promptId = params.get("promptId");
    this.primaryViewState = primaryViewState;
    this.appLink = "";

    if (content) {
      const decoded = decodeURIComponent(content);
      const base64 = fromBase64(decoded);
      const ungzipped = gunzipSync(base64);
      const utf8 = Utf8.fromBufferSource(ungzipped);
      const json = JSON.parse(utf8);
      if (json.contents) {
        this.gotRtj(json.contents);
      } else {
        this.gotRtj(json);
      }
      if (id) {
        this.prefilledId = id;
      }
      this.setAppLink();
      this.recordEvent(EVENT.prefilledEntryViewed);
    } else if (this.promptId) {
      this.type = "prompt";
      this.gotPrompt(this.promptId);
      this.recordEvent(EVENT.prefilledPromptViewed);
    } else {
      this.type = "empty";
      this.setAppLink();
    }
  }

  gotRtj(rtj: RTJNode[]) {
    this.rtj = rtj;
  }

  gotPrompt(promptId: string) {
    const fetchPrompt = async () => {
      const prompt = await d1Classes.dailyPromptStore.getPromptById(promptId);

      if (prompt) {
        this.prompt = prompt.content;
        this.rtj = getRTJContentFromPrompt(prompt.content);
        this.setAppLink();
      }
    };
    fetchPrompt();
  }

  get blocks(): Blocks {
    return convertRTJToBlocks(this.rtj, "nojournal", "noentry");
  }

  get markdown() {
    const md = convertBlocksToMarkdown(this.blocks);
    // Preprocess the markdown a bit to preserve newlines by adding hacky zero-width spaces at the end of each line.
    const processedMarkdown = md.split("\n").join("\u200b\n");
    return processedMarkdown;
  }

  get needsSignInOrSync() {
    const isSignedIn = this.primaryViewState.user !== null;
    const hasJournal = this.primaryViewState.journals.length > 0;
    if (!isSignedIn || !hasJournal) {
      return true;
    } else {
      return null;
    }
  }

  getPromptJournal() {
    // if the prompt has a default journal return that otherwise return the first journal

    const promptJournal =
      this.primaryViewState.dailyPromptSettings.default_journal;
    if (
      promptJournal &&
      this.primaryViewState.journals.find((j) => j.id === promptJournal)
    ) {
      return promptJournal;
    }
    return null;
  }

  getDefaultJournalId() {
    let journalId = null;
    if (this.type === "prompt") {
      journalId = this.getPromptJournal();
    }

    if (!journalId) {
      return this.primaryViewState.journals.length
        ? this.primaryViewState.journals[0].id
        : null;
    }
    return journalId;
  }

  get journals() {
    return this.primaryViewState.journals.filter((j: JournalDBRow) => {
      return j.is_decrypted == 1 && !j.is_read_only;
    });
  }

  setAppLink() {
    // There are old app links already working that look like this:
    // dayone://post?entry=<markdown>
    // Make one of those.
    // For prompts, we need to use this:
    // iOS + Mac: dayone://new/daily-prompt?promptId=
    if (this.type === "entry") {
      this.appLink = `dayone://post?entry=${encodeURIComponent(this.markdown)}`;
    } else if (this.type === "prompt") {
      getClientMeta().then((meta) => {
        if (meta.creationOSName.toLocaleLowerCase() === "android") {
          this.appLink = `dayone://post?entry=${encodeURIComponent(this.markdown)}`;
        } else {
          this.appLink = `dayone://new/daily-prompt?promptId=${this.promptId}`;
        }
      });
    } else {
      this.appLink = "dayone://post";
    }
  }

  gotError() {
    this.error = true;
  }

  recordEvent(name: EVENT, method?: string) {
    let details: { [key: string]: string } = {};
    if (this.prefilledId) {
      details = { id: this.prefilledId };
    }
    if (method) {
      details.method = method;
    }
    analytics.tracks.recordEvent(name, details);
  }

  async openInApp(event: MouseEvent<HTMLAnchorElement> | undefined) {
    event?.preventDefault();
    this.recordEvent(EVENT.prefilledEntryUsedForApp);
    console.log("Opening in app", this.appLink);
    window.location.href = this.appLink;
  }

  async makeNewEntry() {
    this.error = false;
    const targetJournalId = this.getDefaultJournalId();

    try {
      if (!targetJournalId) {
        throw new Error("No journal selected");
      }

      this.recordEvent(EVENT.prefilledEntryUsedForWeb);
      this.recordEvent(EVENT.entryCreate, "prefilled_url");

      const newId = mkEntryID();
      const promptOptions =
        this.type === "prompt"
          ? {
              promptId: this.prompt || undefined,
              tags: this.primaryViewState.dailyPromptSettings.tags || [],
            }
          : {};
      const richTextJson = this.rtj;
      await d1Classes.entryStore.createNewEntry(targetJournalId, new Date(), {
        useEntryId: newId,
        prefill: { markdown: this.markdown, richTextJson, ...promptOptions },
      });
      // Navigate to the new entry's URL
      window.location.href = `/journals/${targetJournalId}/${newId}`;
    } catch (err: any) {
      Sentry.captureException(err);
      this.gotError();
    }
  }

  getTemplateTitle = (rtj: RTJNode[]) => {
    const titleNode = rtj.find(
      (node) => isTextNode(node) && node.attributes?.line?.header === 1,
    );
    if (titleNode && isTextNode(titleNode)) {
      return titleNode.text;
    }
    return "New Template";
  };

  async saveAsTemplate() {
    const tID = uuid();

    await d1Classes.templateRepository.createLocalTemplate({
      id: "",
      title: this.getTemplateTitle(this.rtj),
      richText: createRichTextJsonString(this.rtj),
      order: Date.now(),
      tags: [],
      journalName: "",
      journalSyncID: "",
      client_id: tID,
    });
    analytics.tracks.recordEvent(EVENT.templateAdded, {});
    return tID;
  }
}
