import { useEffect, useState } from "react";

type State = "idle" | "loading" | "loaded" | "ready" | "error";

export const useExternalScript = (url: string, crossOrigin = false) => {
  const [state, setState] = useState<State>(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }
    const scriptPreviouslyLoaded = document.querySelector(
      `script[src="${url}"]`,
    );
    if (scriptPreviouslyLoaded) {
      setState("loaded");
      return;
    }

    const handleScript = (e: Event) => {
      setState(e.type === "load" ? "ready" : "error");
    };

    function handleScriptError() {
      setState("error");
    }
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    if (crossOrigin) {
      script.crossOrigin = "";
    }

    document.body.appendChild(script);

    script.addEventListener("load", handleScript);
    script.addEventListener("error", handleScriptError);

    return () => {
      script.removeEventListener("load", handleScript);
      script.removeEventListener("error", handleScriptError);
      document.body.removeChild(script);
    };
  }, [url]);

  return state;
};
