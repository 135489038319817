import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { d1Classes } from "@/D1Classes";
import { getEntryPreview } from "@/components/EditorPanel/GutenbergEntryTools";
import { EntryPreview } from "@/components/EntryPreview";
import { ConcealedEntry } from "@/components/EntryPreview/ConcealedEntry";
import { EntryFooter } from "@/components/EntryPreview/EntryFooter";
import { EntryMedia } from "@/components/EntryPreview/EntryMedia";
import { SharedEntryInfoPreview } from "@/components/SharedJournals/SharedEntryInfoPreview";
import { EntryDateBadge } from "@/components/TimelineView/EntryDateBadge";
import { MaybeLink } from "@/components/TimelineView/MaybeLink";
import { ENTRY_LIST_ITEM_HEIGHT } from "@/components/TimelineView/constants";
import { GlobalEntryID } from "@/data/db/migrations/entry";
import { ReactionDBRow } from "@/data/db/migrations/reaction";
import { useSubscription } from "@/hooks/d1_hooks";
import { primaryViewState } from "@/view_state/ViewStates";

type EntryListItemProps = {
  globalEntryID: GlobalEntryID;
  height?: number;
  link?: boolean;
  baseURL?: string;
  showDateBadge?: boolean;
  addDate?: boolean;
};

export const GAP = 0;

export const EntryListItem: React.FC<EntryListItemProps> = observer(
  ({
    globalEntryID,
    height = ENTRY_LIST_ITEM_HEIGHT,
    link = true,
    baseURL = null,
    showDateBadge,
    addDate = true,
  }) => {
    const { id: thisEntryId, journal_id: thisJournalId } = globalEntryID;
    const {
      selectedGlobalEntryID,
      revealEntry,
      isEntryRevealed,
      getJournalById,
      selectedJournal,
    } = primaryViewState;
    const entry = useSubscription(
      d1Classes.entryStore.subToEntry.bind(d1Classes.entryStore, globalEntryID),
      [globalEntryID.journal_id, globalEntryID.id],
    );
    const entryJournal = getJournalById(thisJournalId);
    const entryText = entry ? getEntryPreview(entry) : "";
    const hasText = entryText.length > 0;

    const reactions = useSubscription<ReactionDBRow[]>(
      (cb) =>
        d1Classes.reactionRepository.subscribeToReactionsByEntry(
          cb,
          thisJournalId,
          thisEntryId,
        ),
      [thisEntryId, thisJournalId],
    );

    useEffect(() => {
      if (
        selectedGlobalEntryID?.id === thisEntryId &&
        (selectedJournal?.conceal == 1 || entryJournal?.conceal)
      ) {
        revealEntry(selectedGlobalEntryID);
      }
    }, [
      selectedGlobalEntryID?.id,
      selectedGlobalEntryID?.journal_id,
      selectedJournal?.conceal,
    ]);

    const _isEntryRevealed = isEntryRevealed(globalEntryID);

    const conceal = !entryJournal?.conceal ? false : !_isEntryRevealed;

    const isSelected =
      thisEntryId === selectedGlobalEntryID?.id &&
      thisJournalId === selectedGlobalEntryID?.journal_id;

    const invertedText = isSelected;

    const getBackgroundColor = () => {
      if (primaryViewState.selectedJournal) {
        return primaryViewState.selectedJournal.color;
      } else {
        return "primary";
      }
    };

    const journalColor = selectedJournal
      ? selectedJournal.color
      : "surfaceActive";
    const is_unread = !!entry?.unread_marker_id;

    let bgColor = "surface_light1_dark1";
    if (isSelected) {
      bgColor = getBackgroundColor();
    }

    baseURL =
      baseURL ??
      (selectedJournal
        ? `/journals/${selectedJournal.id}`
        : `/all/${thisJournalId}`);

    return (
      <MaybeLink
        baseURL={baseURL}
        entry={entry}
        height={height}
        link={link}
        selectedEntryId={selectedGlobalEntryID}
        bgColor={bgColor}
        journalColor={journalColor}
        is_unread={is_unread}
        invertedText={invertedText}
      >
        {entry ? (
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              py: 2.5,
            }}
          >
            <div
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                pr: 2,
                pl: !addDate ? 2 : 0,
                height: entryJournal?.is_shared
                  ? "100%"
                  : ENTRY_LIST_ITEM_HEIGHT,
              }}
            >
              {addDate && (
                <EntryDateBadge
                  entryId={entry.id}
                  date={entry.date}
                  timeZone={entry.timeZone}
                  showDateBadge={showDateBadge}
                />
              )}
              {!conceal ? (
                <>
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                      overflow: "hidden",
                      pr: 3,
                      pl: addDate ? 0 : 2,
                      gap: 1,

                      ...(!hasText && {
                        pr: 0,
                        justifyContent: "flex-end",
                        height: "100%",
                      }),
                    }}
                  >
                    {hasText ? (
                      <EntryPreview
                        entry={entry}
                        entryText={entryText}
                        entryJournal={entryJournal}
                        invertedText={invertedText}
                      />
                    ) : (
                      <EntryMedia
                        entry={entry}
                        bgColor={bgColor}
                        entryJournal={entryJournal!}
                        isSelected={isSelected}
                        mediaOnly
                      />
                    )}
                    <EntryFooter
                      entry={entry}
                      entryJournal={entryJournal}
                      invertedText={invertedText}
                    />
                  </div>
                  {hasText && (
                    <EntryMedia
                      entry={entry}
                      bgColor={bgColor}
                      entryJournal={entryJournal!}
                      isSelected={isSelected}
                    />
                  )}
                </>
              ) : (
                <ConcealedEntry showDate={addDate} />
              )}
            </div>
            {entryJournal?.is_shared && !conceal && (
              <SharedEntryInfoPreview
                sx={{ ml: addDate ? 8 : 3 }}
                creatorId={entry.creatorUserId}
                reactions={reactions || []}
                bgColor={bgColor}
                invertedText={invertedText}
                color={selectedJournal?.color}
              />
            )}
          </div>
        ) : null}
      </MaybeLink>
    );
  },
);
