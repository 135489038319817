import { Flex } from "@wordpress/components";

import { Avatar, AvatarSizes } from "@/components/SharedJournals/Avatar";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";
import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";

type Props = {
  userId: string | null;
  className?: string;
  direction?: "row" | "column";
  size?: AvatarSizes;
  participantInfo?: JournalParticipantDBRow;
  noMarginBottom?: boolean;
};

export const ParticipantInfo: React.FC<Props> = ({
  userId,
  className,
  direction = "row",
  size = 1,
  noMarginBottom = false,
}) => {
  const user = useSharedJournalParticipant(userId);

  if (!user) {
    return null;
  }

  return (
    <Flex
      className={className}
      sx={{
        mb: noMarginBottom ? 0 : 1,
        justifyContent: "flex-start",
        gap: 3,
        flexDirection: direction,
      }}
    >
      <Avatar user={user} size={size} />
      <span
        className="participant-name"
        sx={{
          fontSize: 1,
          lineHeight: "1.5rem",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {user?.name}
      </span>
    </Flex>
  );
};
