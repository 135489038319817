/**
 * Loads a script from a URL, finds the object that it binds to the window object, and returns it.
 * You'll want to specify <T> yourself, so that you get type help.
 * You'll have to find the nameOnWindow yourself. It's whatever the library decides
 * to bind to the window object.
 * @param urlToLoad The URL of the script to load.
 * @param nameOnWindow The name that your librarby binds to the window object.
 * @returns A promise that resolves to the instance of the object that the script binds to the window object.
 */
export function loadWindowScript<T>(
  urlToLoad: string,
  nameOnWindow: string,
): Promise<T> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = urlToLoad;
    script.onload = () => {
      const instance: T | undefined = (globalThis as any)[nameOnWindow];
      if (instance) {
        resolve(instance);
      } else {
        reject(
          new Error(
            `Script did not bind to window object with name "${nameOnWindow}"`,
          ),
        );
      }
    };
    script.onerror = (event) => {
      reject(
        new Error(
          `Failed to load script from URL "${urlToLoad}" ${event.toString()}`,
        ),
      );
    };
    document.head.appendChild(script);
  });
}
