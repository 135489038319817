import { debounce } from "@wordpress/compose";
import { __ } from "@wordpress/i18n";
import { observer } from "mobx-react-lite";
import {
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SearchBox } from "@/components/SearchBox";
import { searchViewState } from "@/view_state/ViewStates";

const DEBOUNCE_DURATION = 500;

export const SearchBar: React.FC = observer(() => {
  const { searchString, search, saveSearchHistory, indexStatus, filter } =
    searchViewState;
  const [searchInput, setSearchInput] = useState<string | null>(searchString);
  const searchInputRef = useRef(searchInput);
  searchInputRef.current = searchInput;

  const updateSearch = useCallback(
    debounce((_searchString: string) => {
      startTransition(() => {
        search(_searchString);
        return;
      });
    }, DEBOUNCE_DURATION),
    [search],
  );

  const ready = indexStatus === "DONE";

  const saveSearchStringToHistory = useCallback(() => {
    saveSearchHistory(searchInputRef.current || "");
  }, []);

  useEffect(() => {
    return saveSearchStringToHistory;
  }, []);

  useEffect(() => {
    if (ready) {
      updateSearch(searchInput || "");
    }
  }, [searchInput, ready, updateSearch, filter]);

  // this useEffect is required for when searchString is changed
  // from Recent Searches
  // click on Recent Search ->  setSearchInput
  useEffect(() => {
    setSearchInput(searchString || "");
  }, [searchString]);

  const searchBoxWidth = "calc(100% - 36px - 0.5rem)"; // minus the width of the close button and right padding

  return (
    <div
      sx={{
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "borderPrimary",
        position: "relative",
      }}
    >
      <SearchBox
        sx={{
          "&&": { height: "60px", width: searchBoxWidth, borderBottom: "none" },
          "&& form": {
            display: "flex",
            alignItems: "center",
            height: "60px",
            pl: 3,
          },
          // left search icon
          "&& form span": {
            position: "static",
            pl: [0, 0, 3],
          },
          // right close icon when input is not empty
          "&&&  button.components-button": {
            position: "static",
            px: 1,
          },
          "&& .components-base-control": { flexGrow: 2 },
          "&& form input": { fontSize: 2, pl: 2 },
        }}
        value={searchInput}
        setInputValue={setSearchInput}
        placeholder={
          indexStatus === "DONE"
            ? __("Search Entries")
            : __("Loading entries...")
        }
        onSubmitNullifyInput={false}
        disabled={!ready}
        showClear="text"
        shouldFocus={true}
      />
    </div>
  );
});
