import { BlockInstance } from "@wordpress/blocks";

import {
  LIST_BLOCK_ID,
  PULL_QUOTE_BLOCK_ID,
  QUOTE_BLOCK_ID,
} from "@/components/Editor/blocks/constants";

export const getBlockContent = (block: BlockInstance) => {
  if (block.name === LIST_BLOCK_ID) {
    return block.attributes.values;
  }

  if (block.name === QUOTE_BLOCK_ID || block.name === PULL_QUOTE_BLOCK_ID) {
    return block.attributes.value;
  }

  return (block as BlockInstance).attributes.content;
};
