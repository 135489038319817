import { StylePropertyValue, useThemeUI } from "@theme-ui/core";

import { checklistStyles } from "@/components/Editor/theme/checklist";
import { codeStyles } from "@/components/Editor/theme/code";
import { headerStyles } from "@/components/Editor/theme/headers";
import { listStyles } from "@/components/Editor/theme/list";
import { mediaStyles } from "@/components/Editor/theme/media";
import { paragraphStyles } from "@/components/Editor/theme/paragraph";
import { quoteStyles } from "@/components/Editor/theme/quote";
import { separatorStyles } from "@/components/Editor/theme/separator";
import { themeCSS } from "@/styles/composeStyles";

const themeContainerStyles: StylePropertyValue<string | number> = {
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
};

export const EditorThemeWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { theme } = useThemeUI();
  return (
    <div
      css={themeCSS(
        [
          themeContainerStyles,
          quoteStyles,
          mediaStyles,
          headerStyles,
          paragraphStyles,
          listStyles,
          codeStyles,
          checklistStyles,
          separatorStyles,
        ],
        theme,
      )}
    >
      {children}
    </div>
  );
};
