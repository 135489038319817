import { BlockEditorProvider } from "@wordpress/block-editor";
import { Popover, SlotFillProvider } from "@wordpress/components";
// @ts-ignore - no types for this exist yet.
import { ShortcutProvider } from "@wordpress/keyboard-shortcuts";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { EditorWritingFlow } from "@/components/Editor/EditorWritingFlow";
import { registerCompleters } from "@/components/Editor/autocomplete";
import {
  ContextToolbar,
  CONTEXT_TOOLBAR_SLOT_FILL,
} from "@/components/Editor/components/ContextToolbar";
import {
  FixedFormattingToolbar,
  TOOLBAR_HEIGHT,
} from "@/components/Editor/components/FixedFormattingToolbar";
import { registerFormatTypes } from "@/components/Editor/formats/register-format-types";
import { EditorThemeWrapper } from "@/components/Editor/theme/EditorThemeWrapper";
import { SLOT_NAME } from "@/components/Editor/utils/editor-popover-slot-name";
import {
  registerBlocks,
  registerMediaBlocks,
  unRegisterMediaBlocks,
} from "@/components/Editor/utils/register-blocks";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { Blocks } from "@/view_state/ActiveEntryViewState";
import { ViewStates } from "@/view_state/ViewStates";

import "@/styles/gutenberg.css";

// Don't register blocks in tests in this file, since they're registered in
// the test runner setup file
if (process.env.NODE_ENV !== "test") {
  registerBlocks();
}

type EditorProps = {
  content: Blocks;
  onChange: (changes: Blocks) => void;
  viewStates: ViewStates;
  height: number;
  footer?: React.ReactNode;
};

export const TemplateEditor: React.FC<EditorProps> = observer(
  ({ content, onChange, viewStates, height, footer = <></> }) => {
    registerCompleters();
    registerFormatTypes(false);

    useEffect(() => {
      unRegisterMediaBlocks();
      return () => {
        registerMediaBlocks();
      };
    }, []);

    const { isMobile } = useDevice();

    return (
      <EditorThemeWrapper>
        <SlotFillProvider>
          {/* @ts-ignore - unstable APIs aren't in the types */}
          <Popover.__unstableSlotNameProvider value={SLOT_NAME}>
            {/* @ts-ignore */}
            <Popover.Slot />
            {/* @ts-ignore */}
          </Popover.__unstableSlotNameProvider>
          <BlockEditorProvider
            settings={{ bodyPlaceholder: "", hasFixedToolbar: true }}
            value={content}
            onInput={(updatedBlocks) => {
              onChange(updatedBlocks);
            }}
            onChange={(updatedBlocks) => {
              onChange(updatedBlocks);
            }}
          >
            <CONTEXT_TOOLBAR_SLOT_FILL.Slot />
            {/* Provide a separate named slot for the context menu.
          It must be within BLockEditorProvider for useSelect hook to work */}
            <FixedFormattingToolbar
              globalEntryID={null}
              viewStates={viewStates}
              disableMediaUpload={true}
            />
            {!isMobile && <ContextToolbar />}

            <div
              sx={{
                height: `calc(${height}px - ${TOOLBAR_HEIGHT})`,
                overflow: "hidden",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Shortcut provider produces a div we need to style */}
              <ShortcutProvider style={{ display: "flex", flexGrow: 1 }}>
                <EditorWritingFlow
                  globalEntryID={null}
                  editorId="template-editor"
                />
              </ShortcutProvider>
            </div>
          </BlockEditorProvider>
        </SlotFillProvider>
        {footer}
      </EditorThemeWrapper>
    );
  },
);
