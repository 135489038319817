import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { GenerateKeyComponent } from "./GenerateKeyComponent";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { D1Modal } from "@/components/D1Modal";
import { BackupWarningModal } from "@/components/Settings/Account/MasterKeyModal/BackupWarningModal";
import { MasterKeyToQRCode } from "@/components/Settings/Account/MasterKeyModal/MasterKeyToQRCode";
import { MasterKey } from "@/crypto/types/MasterKey";
import { LocationOfKey } from "@/data/repositories/UserKeysRepository";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  onClose: () => void;
  agreedToCreateKey: boolean;
  setSavingMasterKey: (savingMasterKey: boolean) => void;
  isCreatingSharedJournal: boolean;
};

export type CloseKeyState = "TURN_OFF_ENCRYPTION" | "MANUALLY_SAVED" | null;

export const MasterKeyManage = observer(
  ({
    setSavingMasterKey,
    onClose: closeModal,
    agreedToCreateKey,
    isCreatingSharedJournal,
  }: Props) => {
    const storedMasterKeyString = primaryViewState.masterKeyString;

    const [masterKey, setMasterKey] = useState<MasterKey | null>(
      storedMasterKeyString ? new MasterKey(storedMasterKeyString) : null,
    );
    const [showWarning, setShowWarning] = useState(false);

    const { __ } = useI18n();

    // sync with local state if it gets updated
    useEffect(() => {
      if (storedMasterKeyString) {
        setMasterKey(new MasterKey(storedMasterKeyString));
      }
    }, [storedMasterKeyString]);

    const title = masterKey ? __("Encryption Key") : "";

    const onClose = (keySaveState: CloseKeyState = null) => {
      if (!keySaveState && !primaryViewState.user?.master_key_storage?.length) {
        setShowWarning(true);
      } else {
        closeModal();
      }
    };

    const onSaveHandler = async (locationOfKey: LocationOfKey) => {
      if (!masterKey) {
        return;
      }

      setSavingMasterKey(true); // Block the enter key modal immediately

      try {
        if (!storedMasterKeyString) {
          await d1Classes.userKeysStore.saveMasterKey(masterKey, locationOfKey);
          if (process.env.NODE_ENV !== "test") {
            await d1Classes.userStore.sync();
          }

          viewStates.snackbar.newMessage(__("Encryption is enabled"));
        } else {
          await d1Classes.userKeysStore.saveMasterKeyBackupLocation(
            locationOfKey,
          );
        }
      } catch (e) {
        const error = new Error("Failed to save master key");
        Sentry.captureException(error);
        throw error;
      } finally {
        setSavingMasterKey(false); // Unblock the enter key modal after operation
      }
    };

    return (
      <>
        <D1Modal
          title={title}
          onRequestClose={() => {
            if (!masterKey) {
              onClose("TURN_OFF_ENCRYPTION");
            } else {
              onClose(null);
            }
          }}
          sx={{
            width: "100%",
            maxWidth: "420px",
            height: "unset !important",
            maxHeight: "95%",

            borderRadius: "9px !important",
            mx: "auto",
            mt: ["auto"],
            mb: ["auto"],
            "& .components-modal__header": {
              borderBottomWidth: title ? "1px" : "0",
            },
            "& .components-modal__content": {
              my: 0,
              mx: 0,
              pb: 4,
              pt: title ? 0 : 5,
              mt: title ? 9 : 0,
              px: [3, 5],
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              minWidth: "300px",
              "& button": {
                justifyContent: "center",
              },
            }}
          >
            {masterKey ? (
              <MasterKeyToQRCode onSave={onSaveHandler} masterKey={masterKey} />
            ) : (
              <GenerateKeyComponent
                isCreatingSharedJournal={isCreatingSharedJournal}
                agreedToCreateKey={agreedToCreateKey}
                closeModal={(state) => {
                  onClose(state);
                }}
                generateMasterKey={async () => {
                  const user = primaryViewState.user;
                  if (!user) {
                    Sentry.captureException(
                      new Error("MasterKeyModal: User is not logged in"),
                    );
                    return;
                  }

                  const masterKey =
                    await d1Classes.userKeysStore.generateMasterKey(user.id);
                  setMasterKey(masterKey);
                }}
              />
            )}
          </div>
        </D1Modal>
        {showWarning && (
          <BackupWarningModal
            onClose={() => {
              setShowWarning(false);
            }}
            onAlreadySaved={() => {
              onSaveHandler("manualBackup");
              setShowWarning(false);
              onClose("MANUALLY_SAVED");
            }}
          />
        )}
      </>
    );
  },
);
