import { DOCrypto } from "@/crypto/DOCrypto";
import { Symmetric } from "@/crypto/DOCryptoBasics";
import { Utf8 } from "@/crypto/utf8";
import { fromBase64 } from "@/crypto/utils";

export const decryptSymmetricD1 = async (
  encryptedValue: string,
  key: CryptoKey,
): Promise<string> => {
  try {
    const parsedValue = await DOCrypto.D1.parse(fromBase64(encryptedValue));
    const decryptedName = await Symmetric.decryptBufferAndIV(
      parsedValue.iv,
      key,
      DOCrypto.D1.getBodyAsArrayBuffer(parsedValue),
    );
    return Utf8.fromBufferSource(decryptedName);
  } catch (e) {
    console.warn("Couldn't decrypt, maybe not encrypted?");
    console.warn("Value:", encryptedValue);
    console.error("Error:", e);
    console.trace();
    // Return in case not encrypted
    return encryptedValue;
  }
};
