export const MostlyCloudy: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38364 14.387C8.89864 14.387 9.38364 14.493 9.80764 14.706C10.1256 12.61 11.9446 11 14.1256 11C16.5046 11 18.4436 12.914 18.5046 15.283C19.9286 15.465 21.0346 16.68 21.0346 18.184C21.0346 19.794 19.7316 21.1 18.1256 21.1H8.38364C6.53564 21.1 5.03564 19.596 5.03564 17.744C5.03564 15.89 6.53564 14.387 8.38364 14.387Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.96 2.7999V1.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0598 4.91L16.6198 4.34"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.95986 10H3.75986"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.30984 4.34L5.86984 4.91"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7929 7.172C13.0689 6.448 12.0689 6 10.9639 6C8.75487 6 6.96387 7.791 6.96387 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
