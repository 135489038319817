import { ThemeUIStyleObject } from "@theme-ui/css";

export const listStyles: ThemeUIStyleObject = {
  // Scope everything to the block list layout
  ".block-editor-block-list__layout": {
    ".wp-block-list": {
      paddingLeft: 4,
      fontSize: 2,
      color: "editorTextDefault",
      lineHeight: 3,
      fontWeight: "body",
    },
  },
};
