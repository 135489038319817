interface Props {
  title: string;
}

export const SearchHistoryNavigationTitle: React.FC<Props> = ({ title }) => {
  return (
    <div
      sx={{
        opacity: 0.7,
        color: "textPrimary",
        lineHeight: "body",
        fontSize: 1,
        fontWeight: "body",
      }}
    >
      {title}
    </div>
  );
};
