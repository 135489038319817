import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";

import { D1Modal } from "@/components/D1Modal";
import { Button } from "@/components/Settings/Button";
import { SharedJournals } from "@/components/icons/SharedJournals";
import { useDevice } from "@/data/hooks/LayoutProvider";
import {
  primaryViewState,
  userSettingsViewState,
  viewStates,
} from "@/view_state/ViewStates";

type ModalProps = {
  onClose: () => void;
  openNewOnClose?: boolean;
};

export const SharedJournalsInfoModal = observer(
  ({ onClose, openNewOnClose = true }: ModalProps) => {
    const { isMobile } = useDevice();
    const { __ } = useI18n();
    const hasCreatedUserKeys = primaryViewState.userEncryptionKeyExists;
    const isEncryptionReady = primaryViewState.isEncryptionReady;
    const needsKey = !hasCreatedUserKeys || !isEncryptionReady;
    const handleClose = () => {
      onClose();
      userSettingsViewState.saveSettings({ shown_shared_journals_info: true });
      if (openNewOnClose) {
        viewStates.modalRouter.newSharedJournal();
      }
    };

    let buttonText = __("Get Started");
    if (!hasCreatedUserKeys) {
      buttonText = __("Create Keys");
    } else if (!isEncryptionReady) {
      buttonText = __("Enter Encryption Key");
    }

    return (
      <D1Modal
        title=""
        onRequestClose={handleClose}
        hideHeader
        sx={{
          width: "420px",
          maxWidth: "100%",
          height: isMobile ? "auto" : "388px",
          maxHeight: "100%",
          "& .components-modal__content, & .components-modal__content::before":
            {
              p: "0",
            },
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            height: !isMobile ? "100%" : "auto",
            position: "relative",
            px: 6,
            py: 3,
            gap: 3,
            textAlign: "center",
            "& svg": { color: "primary" },
          }}
        >
          <SharedJournals />
          <h1
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              lineHeight: "32px",
              m: 2,
            }}
          >
            {__("Shared Journals")}
          </h1>
          <p sx={{ fontSize: 1, mb: 2 }}>
            {__(
              "Shared journals are a private space for your closest friends and family to connect and share memories.",
            )}
          </p>
          {needsKey && (
            <p sx={{ fontSize: 1, color: "textSecondary" }}>
              {__(
                "An encryption key is required to create a new shared journal.",
              )}
            </p>
          )}
          <Button
            onClick={handleClose}
            variant="primary"
            sx={{
              width: "100%",
              textAlign: "center",
              height: "auto",
              mb: 2,
              py: 2,
              justifyContent: "center",
            }}
          >
            {buttonText}
          </Button>
        </Flex>
      </D1Modal>
    );
  },
);
