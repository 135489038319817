import { publicKeyToUint8Array } from "@/crypto/DOCryptoBasics/asymmetric/Public/publicKeyToUint8Array";
import { toBase64 } from "@/crypto/utils";

export const publicKeyToPEM = async (key: CryptoKey) => {
  const exportKey = await publicKeyToUint8Array(key);
  const PEM =
    "-----BEGIN PUBLIC KEY-----\n" +
    // wrap the key in 64 character lines
    toBase64(exportKey)
      .match(/.{1,64}/g)!
      .join("\n") +
    "\n-----END PUBLIC KEY-----\n";
  return PEM;
};
