import { FlexItem } from "@wordpress/components";

import { ToggleControl } from "@/components/Form/ToggleControl";
import { Item } from "@/components/ItemGroup";

interface ToggleSettingProps {
  label: React.ReactNode;
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  className?: string;
}

export const ToggleSetting: React.FC<ToggleSettingProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
  className,
}) => {
  return (
    <Item className={className}>
      <FlexItem sx={{ width: "100%" }}>
        <ToggleControl
          sx={{
            mb: 0,
            "&.components-base-control": {
              mb: 0,
              ".components-h-stack": {
                span: {
                  order: 2,
                },
                ".components-toggle-control__label": {
                  cursor: "pointer",
                  order: 1,
                  flexGrow: 1,
                  fontSize: 1,
                },
                "& .components-form-toggle__input": {
                  cursor: "pointer",
                },
              },
            },
          }}
          label={label}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        ></ToggleControl>
      </FlexItem>
    </Item>
  );
};
ToggleSetting.displayName = "ToggleSetting";
