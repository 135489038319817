import { debounce } from "@wordpress/compose";
import { useCallback, useEffect, useState } from "react";

export type ControllableScrollContainerProps = {
  children: React.ReactNode;
  onScrollTop: (scrollTop: number) => void;
  scrollTop: number;
  onNodeChange?: (node: HTMLElement | null) => void;
  height: number;
} & React.HTMLAttributes<HTMLElement>;

export const ControllableScrollContainer = ({
  children,
  onScrollTop,
  scrollTop,
  onNodeChange,
  height,
  ...props
}: ControllableScrollContainerProps) => {
  const [node, setNode] = useState<HTMLElement | null>(null);

  // Use a callbackRef so to pass to the div so that we get notified when the
  // dom node changes and we can trigger a re-render.
  const callbackRef = useCallback((node: null | HTMLElement) => {
    onNodeChange?.(node);
    setNode(node);
  }, []);

  // If the node changes, or the scrollTop from outside changes, update the
  // scrollTop of the node.
  // We include height as a dependency because on first load we may not have the final height yet
  // which can cause the scroll to be off.
  useEffect(() => {
    if (node && node.scrollTop !== scrollTop) {
      node.scrollTop = scrollTop;
    }
  }, [node, scrollTop, height]);

  // I do this inside of useEffect instead of inside of the callback below
  // because callbackRefs indicate unmounting by receiving a null value.
  // Once we've got a null value, we can't remove the event listener from
  // it. (Arguably, if the DOM node is gone, the event listener should be, too
  // but I'm not convinced the DOM node disappears. In my testing with vite,
  // I'd modify the values inside of this function, and see additional listeners
  // registered without removing the old ones.)
  // Having a `useEffect` here that watches the DOM node allows us to register
  // a function that's called when the component is unmounted, and remove the event
  // listener from the DOM node.
  useEffect(() => {
    if (node) {
      const updatePosition = debounce(() => {
        onScrollTop(node.scrollTop);
      }, 45);
      node.addEventListener("scroll", updatePosition);
      return () => {
        node.removeEventListener("scroll", updatePosition);
      };
    }
  }, [node, onScrollTop]);

  return (
    <div {...props} ref={callbackRef}>
      {children}
    </div>
  );
};
