import { Flex, FlexItem } from "@wordpress/components";
import { ReactNode } from "react";

type Props = {
  icon: JSX.Element;
  iconColor?: string;
  iconSize?: string;
  fontSize?: number;
  main: ReactNode;
  tagline?: string;
  lineHeight?: string | number;
};

export const Blurb: React.FC<Props> = ({
  icon,
  iconColor = "primary",
  iconSize = "25px",
  fontSize = 1,
  main,
  tagline,
  lineHeight = "body",
}) => {
  return (
    <Flex
      sx={{
        justifyContent: "start",
        alignItems: "start",
        gap: 2,
        flexShrink: 0,
        "&& *": { textAlign: "left" },
      }}
    >
      <FlexItem
        sx={{
          mt: 1,
          color: iconColor,
          width: iconSize,
          flexShrink: 0,
          "& > svg": { width: iconSize, height: iconSize },
        }}
      >
        {icon}
      </FlexItem>
      <FlexItem sx={{ pl: 2 }}>
        <div
          sx={{
            fontSize,
            lineHeight,
            fontWeight: 600,
            color: "textPrimary",
          }}
        >
          {main}
        </div>
        {tagline && (
          <p
            sx={{
              fontSize: fontSize - 1,
              color: "textSecondary",
              mb: 2,
              lineHeight: "1.3rem",
            }}
          >
            {tagline}
          </p>
        )}
      </FlexItem>
    </Flex>
  );
};
