import { Icon } from "@wordpress/icons";

import { dayOneBlue } from "@/styles/theme";

export const IconColors = {
  blue: { color: dayOneBlue, bgColor: "#daf2ff" },
  red: { color: "#E74C3C", bgColor: "#FADBD8" },
  green: { color: "#2DCC71", bgColor: "#d4f6e2" },
};

type Props = {
  icon: JSX.Element;
  color: string;
  bgColor: string;
  size?: number;
};

export const NotificationIcon: React.FC<Props> = ({
  icon,
  color,
  bgColor,
  size = 16,
}) => {
  return (
    <div
      className="reaction-icon"
      sx={{
        boxSizing: "content-box",
        width: 3,
        height: 3,
        borderRadius: "rounded",
        color: color,
        backgroundColor: bgColor,
        position: "absolute",
        bottom: "-4px",
        right: "12px",
        display: "flex",
        alignItems: "center",
        svg: {
          margin: "0 auto",
          display: "block",
        },
      }}
    >
      <Icon icon={icon} size={size} />
    </div>
  );
};
