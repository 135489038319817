import { DOCrypto } from "@/crypto/DOCrypto";
import { Key } from "@/data/repositories/Syncables";

export const encryptWithJournalKey = async (
  data: Uint8Array,
  journalKey: Key,
  format: 1 | 2,
) => {
  return DOCrypto.D1.encryptWithLockedKey(
    data,
    journalKey.fingerprint,
    journalKey.public_key,
    format,
  );
};
