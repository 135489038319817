export const RainNight: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.233 9.689C16.653 9.689 16.108 9.805 15.631 10.036C15.273 7.753 13.227 6 10.773 6C8.097 6 5.915 8.084 5.847 10.665C4.244 10.863 3 12.186 3 13.824C3 15.577 4.466 17 6.273 17H17.233C19.313 17 21 15.362 21 13.344C21 11.326 19.313 9.689 17.233 9.689Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0498 6.01402C12.3018 3.77002 14.1858 2.02002 16.4998 2.02002C16.4998 4.50202 18.5148 6.51402 20.9998 6.51402C20.9998 8.01702 20.2538 9.34002 19.1188 10.155"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 20L5.75 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.75 20L13.75 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.75 21L9.75 23"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 21L17.75 22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
