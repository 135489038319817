import { BlockInstance } from "@wordpress/blocks";
import showdown from "showdown";
import { ChecklistBlock } from "types/gutenberg-blocks";

import {
  AUDIO_BLOCK_ID,
  CHECKLIST_ITEM_BLOCK_ID,
  CODE_BLOCK_ID,
  GALLERY_BLOCK_ID,
  HEADING_BLOCK_ID,
  IMAGE_BLOCK_ID,
  LIST_BLOCK_ID,
  LIST_ITEM_BLOCK_ID,
  PARAGRAPH_BLOCK_ID,
  PDF_BLOCK_ID,
  QUOTE_BLOCK_ID,
  SEPARATOR_BLOCK_ID,
  VIDEO_BLOCK_ID,
} from "@/components/Editor/blocks/constants";
import { ZERO_WIDTH_NO_BREAK_SPACE } from "@/components/Editor/rtj2gb/text2paragraph";

const converter = new showdown.Converter({
  noHeaderId: true,
  tables: true,
  literalMidWordUnderscores: true,
  omitExtraWLInCodeBlocks: true,
  simpleLineBreaks: true,
  strikethrough: true,
  tasklists: true,
});

export const convertBlocksToMarkdown = (blocks: BlockInstance[]) => {
  const markdownContent = blocks.reduce((acc, block) => {
    /* core blocks */
    if (block.name === HEADING_BLOCK_ID) {
      // TODO - can we type blocks without casting?
      const hTag = block.attributes.level;
      return (
        acc +
        converter
          .makeMarkdown(`<h${hTag}>${block.attributes.content}</h${hTag}>`)
          .trim() +
        "\n"
      );
    } else if (block.name === LIST_BLOCK_ID) {
      const listHTML = getListHTML(block, true);
      return (
        acc +
        converter
          .makeMarkdown(listHTML)
          .trim()
          // Clean up weird empty comment left by makeMarkdown
          .replace(/<!-- -->/g, "")
      );
    } else if (block.name === SEPARATOR_BLOCK_ID) {
      return acc + `* * *\n`;
      /* dayone blocks */
    } else if (block.name === CHECKLIST_ITEM_BLOCK_ID) {
      return acc + getChecklistItemAsMarkdown(block as ChecklistBlock);
    } else if (
      (block.name === IMAGE_BLOCK_ID ||
        block.name === AUDIO_BLOCK_ID ||
        block.name === PDF_BLOCK_ID ||
        block.name === VIDEO_BLOCK_ID) &&
      "clientId" in block.attributes
    ) {
      return acc + getContentBlockAsMarkdown(block) + "\n";
    } else if (block.name === GALLERY_BLOCK_ID) {
      const galleryMediaMds = block.innerBlocks.map((block) =>
        getContentBlockAsMarkdown(block),
      );
      return acc + galleryMediaMds.join("\n") + "\n";
    } else if (block.name === QUOTE_BLOCK_ID) {
      const quoteHTML = getQuoteHTML(block);
      return (
        acc +
        converter
          .makeMarkdown(quoteHTML)
          .trim()
          // Clean up extra blank line in multiline quotes
          .replace(/<br>\n> /g, "  ") +
        // Put two newlines after a quote block, so that the
        // content following it doesn't get merged into the quote
        // when parsed.
        "\n\n"
      );
    } else if (block.name === CODE_BLOCK_ID) {
      return (
        acc +
        converter.makeMarkdown(
          "<pre><code>" + block.attributes.content + "</code></pre>",
        )
      );
      /* if the block doesn't have special handling, just return its content */
    } else if ("content" in block.attributes) {
      return acc + converter.makeMarkdown(block.attributes.content) + "\n";
    }

    return acc;
  }, "");

  return markdownContent;
};

const getChecklistItemAsMarkdown = (block: ChecklistBlock) => {
  const checked = block.attributes.checked ? "x" : " ";
  const indent = "\t".repeat(block.attributes.indent - 1);
  return `${indent}- [${checked}] ${block.attributes.content}\n`;
};

const getContentBlockAsMarkdown = (block: BlockInstance) => {
  let momentType = "";
  switch (block.name) {
    case IMAGE_BLOCK_ID:
      momentType = "";
      break;
    case VIDEO_BLOCK_ID:
      momentType = "video";
      break;
    case AUDIO_BLOCK_ID:
      momentType = "audio";
      break;
    case PDF_BLOCK_ID:
      momentType = "pdfAttachment";
      break;
    default:
      break;
  }
  return `![](dayone-moment:/${momentType}/${block.attributes.clientId})`;
};

export function getListHTML(block: BlockInstance, removeEmptyItems = false) {
  const interimResult = Array.from(getListHTMLGenerator(block)).join("");
  if (!removeEmptyItems) {
    return interimResult.replace(
      /<li><p><\/p>/g,
      `<li>${ZERO_WIDTH_NO_BREAK_SPACE}`,
    );
  }
  // Remove empty list items in Markdow to avoid Showdown bugs
  return interimResult.replace(/<li><p><\/p><\/li>/g, "");
}

function* getListHTMLGenerator(block: BlockInstance): IterableIterator<string> {
  /* ==> v2 List structure
  attributes:
    ordered: true
    values: ""
  [[Prototype]]: Object
  clientId: "05d213c5-3aef-4cb7-b1fc-faabf1cbf67d"
  innerBlocks: Array(3)
  0:
    clientId: "547ef98b-31b7-4657-906b-a1cfa0cda50d"
    innerBlocks: []
    isValid: true
    name: "core/list-item"
      attributes:
          content: "item1"
  */

  const listContainer = block.attributes.ordered ? "ol" : "ul";
  yield `<${listContainer}>`;
  const { innerBlocks } = block;
  for (const innerBlock of innerBlocks) {
    if (innerBlock.name === LIST_BLOCK_ID) {
      yield* getListHTMLGenerator(innerBlock);
    }

    if (innerBlock.name === LIST_ITEM_BLOCK_ID) {
      const { content } = innerBlock.attributes;
      const { innerBlocks: nestedInnerBlocks } = innerBlock;
      yield `<li>`;
      yield `<p>${content}</p>`;
      for (const nestedInnerBlock of nestedInnerBlocks) {
        if (nestedInnerBlock.name === LIST_BLOCK_ID) {
          yield* getListHTMLGenerator(nestedInnerBlock);
        }
      }
      yield `</li>`;
    }
  }
  yield `</${listContainer}>`;
}

export function getQuoteHTML(block: BlockInstance) {
  return Array.from(getQuoteHTMLGenerator(block)).join("");
}

function* getQuoteHTMLGenerator(
  block: BlockInstance,
): IterableIterator<string> {
  const quoteContainer = "blockquote";
  yield `<${quoteContainer}>`;
  const { innerBlocks } = block;
  for (const innerBlock of innerBlocks) {
    if (innerBlock.name === PARAGRAPH_BLOCK_ID) {
      const { content } = innerBlock.attributes;
      yield `<p>${content}</p>`;
    }
    if (innerBlock.name === QUOTE_BLOCK_ID) {
      yield* getQuoteHTMLGenerator(innerBlock);
    } else {
      const { innerBlocks: nestedInnerBlocks } = innerBlock;
      for (const nestedInnerBlock of nestedInnerBlocks) {
        if (nestedInnerBlock.name === QUOTE_BLOCK_ID) {
          yield* getQuoteHTMLGenerator(nestedInnerBlock);
        }
      }
    }
  }
  yield `</${quoteContainer}>`;
}
