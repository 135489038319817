import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { JournalParticipantDBRow } from "@/data/db/migrations/journal_participant";

export const useSharedJournalParticipant = (
  userId: string | null,
  withAvatar = true,
  token?: string,
) => {
  const [user, setUser] = useState<JournalParticipantDBRow | undefined>();
  useEffect(() => {
    let cancelCallback = () => {};
    if (userId) {
      cancelCallback =
        d1Classes.journalParticipantRepository.subscribeToUserInfo(
          userId,
          withAvatar,
          setUser,
          token,
        );
    }
    return cancelCallback;
  }, [userId]);

  return user;
};
