import { Sentry } from "@/Sentry";
import { decryptEntryD1 } from "@/crypto/DOCrypto/D1/decryptEntryD1";
import { decodeEntryContent } from "@/crypto/DOCrypto/EntryBlob/_entryDecodeContent";
import { Utf8 } from "@/crypto/utf8";
import { VaultRepository } from "@/data/repositories/VaultRepository";
export const decryptEntryBlob = async (
  d1: Uint8Array,
  debugContext: string,
  vaultRepository: VaultRepository,
) => {
  const { decrypted } = await decryptEntryD1(d1, debugContext, vaultRepository);
  const asString = Utf8.fromBufferSource(decrypted);
  let asJson;
  try {
    asJson = JSON.parse(asString);
  } catch (error: any) {
    Sentry.captureException(
      `Invalid rich text JSON for entry ${debugContext} - ${error.message}`,
    );
    throw new Error("Failed To Parse EntryBlob String After Unencrypting");
  }

  return decodeEntryContent(asJson);
};
