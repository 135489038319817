import { getTextSize } from "@/components/Settings/Account/Avatar/utils";

type InitialsProps = {
  initials: string;
  color?: string;
};

export const AvatarInitials: React.FC<InitialsProps> = ({
  initials,
  color,
}) => {
  const bgColor = color
    ? color.startsWith("#")
      ? color
      : `var(--theme-ui-colors-${color})`
    : "var(--theme-ui-colors-SharedJournalColor1)";

  const dimension = 60;
  const textSize = getTextSize(dimension);

  return (
    <div
      sx={{
        maxHeight: "100%",
        maxWidth: "100%",
        height: dimension + "px",
        width: dimension + "px",
        lineHeight: dimension + "px",
        borderRadius: "50%",
        backgroundColor: bgColor,
        textAlign: "center",
        color: "white",
        fontSize: textSize,
        textTransform: "uppercase",
      }}
    >
      {initials}
    </div>
  );
};
