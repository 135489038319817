import { useI18n } from "@wordpress/react-i18n";

import { ToggleSetting } from "@/components/Settings/ToggleSetting";
import { JournalDBRow } from "@/data/db/migrations/journal";

type Props = {
  journal: JournalDBRow;
  setJournal: (journal: JournalDBRow) => void;
  trackOnChange: (toggle: string, value: number) => void;
};

export const ToggleConceal: React.FC<Props> = ({
  journal,
  setJournal,
  trackOnChange,
}) => {
  const { __ } = useI18n();
  return (
    <ToggleSetting
      label={__("Conceal Content")}
      checked={!!journal.conceal}
      onChange={() => {
        const conceal = journal.conceal ? 0 : 1;
        setJournal({ ...journal, conceal });
        trackOnChange("conceal-Content", conceal);
      }}
    />
  );
};
