import { DODexie } from "@/data/db/dexie_db";

export class SearchRepository {
  constructor(protected db: DODexie) {}

  async getSearchText(journalId: string, entryId: string) {
    const searchRow = await this.db.search.get([journalId, entryId]);
    return searchRow?.text;
  }

  async upsertSearchText(journalId: string, entryId: string, text: string) {
    return await this.db.search.put({
      journal_id: journalId,
      entry_id: entryId,
      text,
    });
  }

  async deleteSearchText(journalId: string, entryId: string) {
    return await this.db.search.delete([journalId, entryId]);
  }
}
