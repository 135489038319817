import { useI18n } from "@wordpress/react-i18n";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { d1MainThreadClasses } from "@/D1MainThreadClasses";
import { Sentry } from "@/Sentry";
import { DOCryptoBasics } from "@/crypto/DOCryptoBasics";
import { fromBase64 } from "@/crypto/utils";
import { useAuth } from "@/data/hooks/AuthProvider";
import { UserModel } from "@/data/models/UserModel";
import { useSaveEncryptionKey } from "@/hooks/useSaveEncryptionKey";
import {
  getAndFormatMasterKey,
  getCloudBackupFileName,
  getDerivedKey,
} from "@/utils/masterkeyBackup";
import {
  primaryViewState,
  masterKeyViewState,
  snackbarViewState,
} from "@/view_state/ViewStates";

const formatToken = (token: string) => {
  return encodeURIComponent(token.replace("\n", ""));
};

const baseURL = `https://api.apple-cloudkit.com/database/1/iCloud.com.dayoneapp.dayone-client-only/production/private/records/`;
const lookupURL = `${baseURL}lookup?ckAPIToken=${process.env.VITE_CLOUDKIT_API_KEY}`;
const createURL = `${baseURL}modify?ckAPIToken=${process.env.VITE_CLOUDKIT_API_KEY}`;
const loginURL = `https://api.apple-cloudkit.com/database/1/iCloud.com.dayoneapp.dayone/production/public/users/current?ckAPIToken=${process.env.VITE_CLOUDKIT_LOGIN_API_KEY}`;

// This is a hook that is used to connect to iCloud to get a users encryption key backup from CloudKit
// or save they key to CloudKit.
// The flow to get the key is:
// 1. User clicks "Connect to iCloud" button
// 2. That makes a request that requires authentication to the Day One CloudKit API
// 3. The response to that request is a redirect to the iCloud login page
// 4. We redirect to that login page
// 5. Once the user logs in, iCloud redirects back to Day One with a token
// 6. We use that token to make a request to the Day One CloudKit API to get the encrypted master key
// 7. We decrypt the master key and add it to the app
// We do this through a series of useEffect functions
// The flow to save the key starts the same as above but we return a authorized boolean and a backupKey function
// Once authorized we can call that function to back up the key.
export const useCloudKit = (
  handleClose: () => void,
  context: "retrieve" | "backup" | "login",
) => {
  const [redirectURL, setRedirectURL] = useState("");
  const [token, setToken] = useState("");
  const { __ } = useI18n();
  const auth = useAuth();

  useEffect(() => {
    const getLoginUrl = async () => {
      const URL = context === "login" ? loginURL : lookupURL;
      const result = await d1Classes.fetchWrapper.basicFetch(URL, undefined);
      if (result.status === 421) {
        const json = await result.json();
        setRedirectURL(json.redirectURL);
      } else {
        Sentry.captureException(
          new Error(`Unexpected response (${result.status}) from iCloud`),
        );
      }
    };
    getLoginUrl();
  }, [context]);

  // Once we have the redirect URL, we need to listen for the message from iCloud
  useEffect(() => {
    if (redirectURL === "") {
      return;
    }
    const getToken = (e: MessageEvent<any>) => {
      // Make sure we're listening to the right message. This is our iCloud Day One App ID
      if (e.data.appInfo?.AppId !== "1044867788") {
        return;
      }
      const newToken = e.data.ckSession || e.data.ckWebAuthToken;
      setToken(newToken);
    };
    window.addEventListener("message", getToken);

    return () => {
      window.removeEventListener("message", getToken);
    };
  }, [redirectURL]);

  // Once we have the token, we can make a request to the Day One CloudKit API to get the encrypted master key
  useEffect(() => {
    const user = primaryViewState.user;
    if (!token || !user) {
      return;
    }
    if (context === "retrieve") {
      getKey(user, token);
    }
  }, [token]);

  const {
    workingWithCloudKit,
    setWorkingWithCloudKit,
    setHasSavedKeyToCloudKit,
  } = masterKeyViewState;

  const showSnackbar = (message: string) => {
    snackbarViewState.newMessage(message);
  };

  const { validateAndStoreMasterKey } = useSaveEncryptionKey(
    "cloudkit",
    d1Classes.userKeysStore,
    d1MainThreadClasses.syncService,
  );

  const openiCloudLoginWindow = () => {
    if (!redirectURL) {
      return;
    }

    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - (width / 2 + 10);
    const top = window.screen.height / 2 - (height / 2 + 50);
    const confirmation = window.open(
      redirectURL,
      "iCloud-login",
      `toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=${width}, height=${height}, top=${top}, left=${left}`,
    );
    confirmation?.focus();
  };

  const addEncryptionKey = async (key: string) => {
    if (!key) {
      return;
    }
    setWorkingWithCloudKit(true);
    showSnackbar(__("Retrieving your encryption key from iCloud"));
    try {
      if (key) {
        await validateAndStoreMasterKey(key);
        setHasSavedKeyToCloudKit(true);
        showSnackbar(__("Your encryption key was successfully added."));
        handleClose();
      } else {
        showSnackbar(__("Failed to retrieve your encryption key from iCloud"));
      }
    } catch (error) {
      showSnackbar(__("Failed to add encryption key"));
      const errorMessage = `Failed to add encryption key: ${
        (error as Error).message
      }`;
      Sentry.captureException(new Error(errorMessage));
    } finally {
      setWorkingWithCloudKit(false);
    }
  };

  const getKey = async (user: UserModel, token: string) => {
    const result = await d1Classes.fetchWrapper.basicFetch(
      `${lookupURL}&ckWebAuthToken=${formatToken(token)}`,
      {
        method: "POST",
        body: JSON.stringify({
          records: [{ recordName: getCloudBackupFileName(user) }],
        }),
      },
    );
    if (result.ok) {
      const json = await result.json();
      const encryptedKeyBase64 =
        json.records[0].fields.encryptedMasterKey.value;
      const encryptedKeyBytes = fromBase64(encryptedKeyBase64);
      const masterKeyString = await getAndFormatMasterKey(
        encryptedKeyBytes,
        user.id,
      );
      addEncryptionKey(masterKeyString);
    } else {
      Sentry.captureException(
        new Error(
          `Error fetching key from iCloud with status ${
            result.status
          } and message ${await result.text()}`,
        ),
      );
    }
  };

  const loginWithCloudKit = async () => {
    if (!token) {
      return;
    }
    auth.attemptCloudKitLogin(token);
  };

  const backupKey = async (masterKey: string) => {
    if (!masterKey || !primaryViewState.user || !token) {
      return;
    }
    const key = await getDerivedKey(primaryViewState.user.id);
    const masterKeyParts = masterKey.split("-");
    const cleanedKey = masterKeyParts.slice(2).join("");
    const encryptedMasterKeyString = await DOCryptoBasics.Symmetric.encryptToD1(
      cleanedKey,
      key,
    );
    const toSend = {
      operations: [
        {
          operationType: "create",
          record: {
            recordType: "UserMasterKey",
            fields: {
              encryptedMasterKey: {
                value: encryptedMasterKeyString,
                type: "BYTES",
              },
            },
            recordName: getCloudBackupFileName(primaryViewState.user),
          },
        },
      ],
    };
    const result = await d1Classes.fetchWrapper.basicFetch(
      `${createURL}&ckWebAuthToken=${formatToken(token)}`,
      {
        method: "POST",
        body: JSON.stringify(toSend),
      },
    );

    if (result.ok) {
      setHasSavedKeyToCloudKit(true);
      showSnackbar(
        __("Your encryption key was successfully backed up to iCloud."),
      );
      return true;
    } else {
      Sentry.captureException(
        new Error(
          `Error backing up key to iCloud with status ${
            result.status
          } and message ${await result.json()}`,
        ),
      );
      return false;
    }
  };

  return {
    loading: !redirectURL,
    openiCloudLoginWindow,
    workingWithCloudKit,
    authorized: !!token,
    backupKey,
    loginWithCloudKit,
  };
};
