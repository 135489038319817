export const Podcast: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C5.31946 19.4616 5.95263 19.4616 6.34315 19.0711C6.73367 18.6805 6.73367 18.0474 6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00347 10.4003 4.60897 8.93853C5.21447 7.47672 6.23985 6.22729 7.55544 5.34824C8.87104 4.46919 10.4178 4 12 4C13.5823 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C17.2663 18.0474 17.2663 18.6805 17.6569 19.0711C18.0474 19.4616 18.6805 19.4616 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8079 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.34591 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2Z"
        fill="currentColor"
      />
      <path
        d="M12 6C10.8133 6 9.65328 6.35189 8.66658 7.01118C7.67989 7.67047 6.91085 8.60754 6.45673 9.7039C6.0026 10.8003 5.88378 12.0067 6.11529 13.1705C6.3468 14.3344 6.91825 15.4035 7.75736 16.2426C8.14789 16.6332 8.78105 16.6332 9.17158 16.2426C9.5621 15.8521 9.5621 15.219 9.17158 14.8284C8.61217 14.269 8.2312 13.5563 8.07686 12.7804C7.92252 12.0044 8.00173 11.2002 8.30448 10.4693C8.60723 9.73836 9.11993 9.11365 9.77772 8.67412C10.4355 8.2346 11.2089 8 12 8C12.7911 8 13.5645 8.2346 14.2223 8.67412C14.8801 9.11365 15.3928 9.73836 15.6955 10.4693C15.9983 11.2002 16.0775 12.0044 15.9231 12.7804C15.7688 13.5563 15.3878 14.269 14.8284 14.8284C14.4379 15.219 14.4379 15.8521 14.8284 16.2426C15.219 16.6332 15.8521 16.6332 16.2426 16.2426C17.0818 15.4035 17.6532 14.3344 17.8847 13.1705C18.1162 12.0067 17.9974 10.8003 17.5433 9.7039C17.0892 8.60754 16.3201 7.67047 15.3334 7.01118C14.3467 6.35189 13.1867 6 12 6Z"
        fill="currentColor"
      />
      <path
        d="M13 13.7324C13.5978 13.3866 14 12.7403 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 12.7403 10.4022 13.3866 11 13.7324V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13.7324Z"
        fill="currentColor"
      />
    </svg>
  );
};
