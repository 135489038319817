import { Button } from "@wordpress/components";
import { __ } from "@wordpress/i18n";
import { moreHorizontal } from "@wordpress/icons";

import { D1Dropdown } from "@/components/D1Dropdown";
import { MenuButton } from "@/components/Editor/components/MenuButton";
import { useEditorBlocks } from "@/components/Editor/hooks/useEditorBlocks";
import { iconButtonHoverBackground } from "@/styles/mixins";

type Props = {
  clientId: string;
};

export const SuggestionMenu: React.FC<Props> = ({ clientId }) => {
  const { removeBlock } = useEditorBlocks();
  return (
    <D1Dropdown
      renderToggle={({ isOpen, onToggle }) => (
        <Button
          onClick={onToggle}
          label={__("Suggestion Options Menu")}
          icon={moreHorizontal}
          sx={{
            mr: 3,
            color: "textPrimary",
            ...iconButtonHoverBackground,
          }}
          aria-expanded={isOpen}
        />
      )}
      sx={{
        "& .components-popover__content > div": {
          p: 0,
        },
        "& .components-popover__content": {
          borderWidth: 1,
          borderColor: "borderPrimary",
          borderRadius: "sm",
          boxShadow: "shadow2",
          padding: 0,
          marginTop: "-2",
          outline: "none",
        },
      }}
      renderContent={({ onToggle }) => (
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "editorTextMuted",
            fontSize: 1,
            backgroundColor: "surface_light1_dark4",
            minWidth: "160px",
          }}
        >
          <div sx={{ padding: 2.5, "& button": { mt: 1 } }}>
            <MenuButton
              onClick={() => {
                removeBlock(clientId);
                new KeyboardEvent("keydown", {
                  key: "Delete",
                });
                onToggle();
              }}
            >
              {__("Remove")}
            </MenuButton>
          </div>
        </div>
      )}
    />
  );
};
