import { FlexItem, Icon } from "@wordpress/components";
import { warning } from "@wordpress/icons";
import { useI18n } from "@wordpress/react-i18n";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Item } from "@/components/ItemGroup";
import { InstagramManage } from "@/components/Settings/Account/InstagramManage";
import { Attribute } from "@/components/Settings/Attribute";
import { EditLinkButton } from "@/components/Settings/EditLinkButton";
import { Label } from "@/components/Settings/Label";
import { primaryViewState } from "@/view_state/ViewStates";

export type InstagramConnection = {
  accessToken: string;
  instaUserId: string;
  username: string;
  postCount: number;
  userId: string;
  journalId: string;
  lastSyncedPostId: string;
  initialSync: boolean;
  authDate: number;
  lastSyncedDate: number;
  includeTags: boolean;
  expiresOn: number;
};

export const EXPIRED_TOKEN_PREFIX = "expired_token_";

export const openConfirmationWindow = (authURL?: string) => {
  if (!authURL) {
    return;
  }
  const width = 500;
  const height = 500;
  const left = window.screen.width / 2 - (width / 2 + 10);
  const top = window.screen.height / 2 - (height / 2 + 50);
  const confirmation = window.open(
    authURL,
    "instagram-confirmation",
    `toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, width=${width}, height=${height}, top=${top}, left=${left}`,
  );
  confirmation?.focus();
};

export const getInstagramConnectionURL = async () => {
  const resp = await d1Classes.fetchWrapper.fetchAPI(
    "/instagram/getInstaAuthUrl/web_v2",
  );
  if (resp.ok) {
    const url = await resp.text();
    return url;
  }
};

type Props = {
  enabled?: boolean;
  onClickDisabled: () => void;
};

export const Instagram: React.FC<Props> = observer(
  ({ enabled = false, onClickDisabled }) => {
    const { __ } = useI18n();
    const [connections, setConnections] = useState<
      InstagramConnection[] | null
    >(null);
    const [authURL, setAuthURL] = useState<string | undefined>(undefined);
    const [showManage, setShowManage] = useState(false);
    const { instagramUpdated } = primaryViewState;

    useEffect(() => {
      if (enabled) {
        getInstagramConnections().then((result) => {
          setConnections(result);
          if (!result) {
            setShowManage(false);
          }
        });
        setInstagramConnectionURL();
        d1Classes.keyValueStore.set("instagram-updated", "no");
      }
    }, [instagramUpdated]);

    const getInstagramConnections = async () => {
      const resp = await d1Classes.fetchWrapper.fetchAPI(
        "/instagram/all",
        undefined,
        {
          expectedStatusCodes: [404],
        },
      );
      if (resp.ok) {
        const connections = (await resp.json()) as InstagramConnection[];
        return connections;
      } else {
        return null;
      }
    };

    const setInstagramConnectionURL = async () => {
      const url = await getInstagramConnectionURL();
      setAuthURL(url);
    };

    const hasExpiredConnection = () => {
      if (!connections) {
        return false;
      }
      return connections.some((connection) =>
        connection.accessToken.startsWith(EXPIRED_TOKEN_PREFIX),
      );
    };

    if (!enabled) {
      return (
        <Item>
          <FlexItem sx={{ flexGrow: 0, marginRight: 1 }}>
            <img
              sx={{ width: "20px" }}
              src="/assets/instagram-logo.png"
              alt={__("Instagram")}
            />
          </FlexItem>
          <FlexItem
            sx={{
              flexGrow: 1,
              ml: 2,
              "& svg": {
                top: "4px",
                left: "-4px",
                position: "relative",
                fill: "textSecondary",
              },
            }}
          >
            <Label>{__("Instagram")}</Label>
            <Attribute>
              {__("Enhance your journal with Instagram media")}
            </Attribute>
          </FlexItem>
          <FlexItem>
            <EditLinkButton
              onClick={onClickDisabled}
              title={__("Go Premium")}
              label={__("Go Premium")}
            />
          </FlexItem>
        </Item>
      );
    }

    return (
      <>
        <Item>
          <FlexItem sx={{ flexGrow: 0, marginRight: 1 }}>
            <img
              sx={{ width: "20px" }}
              src="/assets/instagram-logo.png"
              alt={__("Instagram")}
            />
          </FlexItem>
          <FlexItem
            sx={{
              flexGrow: 1,
              ml: 2,
              "& svg": {
                top: "4px",
                left: "-4px",
                position: "relative",
                fill: "textSecondary",
              },
            }}
          >
            <Label>{__("Instagram")}</Label>
            <Attribute>
              {!connections ? (
                __("Connect to enhance your journal with Instagram media")
              ) : hasExpiredConnection() ? (
                <>
                  <Icon icon={warning} size={18} />
                  {__("You have an expired Instagram connection.")}
                </>
              ) : connections.length > 1 ? (
                __("Manage your Instagram connections")
              ) : (
                __("Manage your Instagram connection")
              )}
            </Attribute>
          </FlexItem>
          <FlexItem>
            {!connections && (
              <EditLinkButton
                label={__("Connect to Instagram")}
                onClick={() => {
                  openConfirmationWindow(authURL);
                }}
                title={__("Connect")}
              />
            )}
            {connections && (
              <EditLinkButton
                label={__("Manage Instagram connection")}
                onClick={() => setShowManage(true)}
                title={__("Manage")}
              />
            )}
          </FlexItem>
        </Item>
        {showManage && (
          <InstagramManage
            connections={connections!}
            handleClose={() => setShowManage(false)}
          />
        )}
      </>
    );
  },
);

Instagram.displayName = "Instagram";
