// user profile colors, they are used on shared journal
// for the user profiles not for journals
// the color names are missleading
// this is how they are named on the user
// https://wp.me/pdr0wL-19I/#user-colors
export const userProfileColors = {
  SharedJournalColor1: "#A66059",
  SharedJournalColor2: "#A67E59",
  SharedJournalColor3: "#A69359",
  SharedJournalColor4: "#5AA57A",
  SharedJournalColor5: "#598CA6",
  SharedJournalColor6: "#595CA6",
  SharedJournalColor7: "#9859A6",
  SharedJournalColor8: "#A65977",
  SharedJournalColor9: "#333B40",
  SharedJournalColor10: "#CACED0",
};
