import MiniSearch from "minisearch";

import { SearchWorkerClient } from "@/worker/SearchWorkerClient";
import { FilterableValues } from "@/worker/SearchWorkerTypes";

export class SearchService {
  private worker: SearchWorkerClient;

  constructor() {
    this.worker = new SearchWorkerClient();
  }

  getSearchWorker() {
    return this.worker.getSearchWorker();
  }

  index() {
    this.worker.index();
  }

  refreshIndex() {
    this.worker.refreshIndex();
  }

  setFilter(filter: FilterableValues) {
    this.worker.setFilter(filter);
  }

  search(query: string | null | typeof MiniSearch.wildcard, options: any = {}) {
    this.worker.search(query, options);
  }

  terminate() {
    this.worker.terminate();
  }
}
