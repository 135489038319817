import { Sentry } from "@/Sentry";
import { FetchWrapper } from "@/api/FetchWrapper";
import {
  SupportFormConfig,
  supportFormConfigSchema,
} from "@/data/api_callers/schemas/SupportFormAPISchemas";
import { safelyDecodeAPIResponse } from "@/data/api_callers/utils/safelyDecodeAPIResponse";

export class SupportFormAPICaller {
  constructor(
    private fetchWrapper: FetchWrapper,
    private sentry: Sentry,
  ) {}

  async fetchSupportFormConfig(): Promise<SupportFormConfig> {
    const res = await this.fetchWrapper.fetchAPI("/support/form-config");

    if (!res.ok) {
      this.sentry.captureException(
        new Error(
          `Failed to fetch support form config: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to fetch support form config");
    }

    const config = await res.json();
    return safelyDecodeAPIResponse(supportFormConfigSchema, config);
  }

  async submitSupportForm(data: SubmitSupportFormData, authToken?: string) {
    const formData = new FormData();
    formData.append("comment", data.comment || "null");
    formData.append("email", data.email || "null");
    formData.append("category", data.category || "null");
    formData.append("topic", data.topic || "null");
    formData.append(
      "device_model",
      data.device_model?.replaceAll(" ", "_") || "null",
    );
    formData.append("device_id", data.device_id || "null");
    formData.append("product_name", data.product_name || "null");
    formData.append(
      "app_version",
      data.app_version?.replaceAll(" ", "_") || "null",
    );
    formData.append(
      "system_name",
      data.system_name?.replaceAll(" ", "_") || "null",
    );
    formData.append(
      "system_version",
      data.system_version?.replaceAll(" ", "_") || "null",
    );
    if (data.attachments?.length) {
      data.attachments?.map((attachments, idx) =>
        formData.append(`attachment-${idx}`, attachments),
      );
    }
    const res = await this.fetchWrapper.fetchAPI(
      "/v2/support/ticket",
      {
        method: "POST",
        body: formData,
      },
      { overrideAuthToken: authToken },
    );

    if (!res.ok) {
      this.sentry.captureException(
        new Error(
          `Failed to submit support form: ${res.status} - ${res.statusText}`,
        ),
      );
      throw new Error("Failed to submit support form");
    }
  }
}

export type SubmitSupportFormData = {
  comment: string;
  email: string | null;
  category: string | null;
  topic: string | null;
  device_model: string | null;
  device_id: string | null;
  product_name: "DayOneWeb";
  app_version: string | null;
  system_name: string | null;
  system_version: string | null;
  attachments: File[] | undefined;
};
