import { d1Classes, D1Classes } from "@/D1Classes";
import { FEATURE_FLAG_NAMES } from "@/components/Settings/constants";
import { FeatureFlagsViewState } from "@/data/stores/FeatureFlagViewState";
import { ActiveEntryViewState } from "@/view_state/ActiveEntryViewState";
import { CalendarViewState } from "@/view_state/CalendarViewState";
import { MasterKeyViewState } from "@/view_state/MasterKeyViewState";
import { MediaViewState } from "@/view_state/MediaViewState";
import { ModalRouterViewState } from "@/view_state/ModalRouterViewState";
import { PendingApprovalViewState } from "@/view_state/PendingApprovalViewState";
import { PrimaryViewState } from "@/view_state/PrimaryViewState";
import { SearchViewState } from "@/view_state/SearchViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";
import { SyncViewState } from "@/view_state/SyncViewState";
import { ThemeViewState } from "@/view_state/ThemeViewState";
import { TimelineViewState } from "@/view_state/TimelineViewState";
import { ToolbarViewState } from "@/view_state/ToolbarViewState";
import { UserSettingsViewState } from "@/view_state/UserSettingsViewState";
import { WindowHeightViewState } from "@/view_state/WindowHeightViewState";

// This is where all substantial view states in the app are constructed and stored.
// They can be passed to each other as needed, and they can express dependencies
// on respositories and stores. This entire class can be constructed in tests
// for a clean view state to test against.

export class ViewStates {
  // These view states have been converted to classes already 👍
  activeEntry: ActiveEntryViewState;
  primary: PrimaryViewState;
  theme: ThemeViewState;
  timeline: TimelineViewState;
  calendar: CalendarViewState;
  windowHeight: WindowHeightViewState;
  featureFlags: FeatureFlagsViewState;
  sync: SyncViewState;
  modalRouter: ModalRouterViewState;
  snackbar: SnackbarViewState;
  userSettings: UserSettingsViewState;
  pendingApprovals: PendingApprovalViewState;
  masterKey: MasterKeyViewState;
  media: MediaViewState;
  search: SearchViewState;
  toolbar: ToolbarViewState;

  constructor(d1Classes: D1Classes) {
    this.primary = new PrimaryViewState(
      d1Classes.keyValueStore,
      d1Classes.userStore,
      d1Classes.userKeysStore,
      d1Classes.journalStore,
      d1Classes.entryStore,
      d1Classes.templateStore,
      d1Classes.tagStore,
      d1Classes.journalRepository,
      d1Classes.journalStatsRepository,
      d1Classes.notificationStore,
    );
    this.featureFlags = new FeatureFlagsViewState(
      d1Classes.userSettingsRepository,
    );
    this.userSettings = new UserSettingsViewState(
      d1Classes.userSettingsRepository,
    );
    this.theme = new ThemeViewState(this.userSettings);
    this.activeEntry = new ActiveEntryViewState(
      this.primary,
      d1Classes.entryStore,
      d1Classes.momentRepository,
      d1Classes.tagRepository,
      d1Classes.tagStore,
      this.featureFlags,
      d1Classes.reactionStore,
      this.userSettings,
    );
    this.windowHeight = new WindowHeightViewState();
    this.timeline = new TimelineViewState(
      d1Classes.entryRepository,
      this.windowHeight,
      this.primary,
    );
    this.sync = new SyncViewState(
      d1Classes.syncStateRepository,
      d1Classes.outbox,
    );
    this.calendar = new CalendarViewState(
      d1Classes.entryRepository,
      this.windowHeight,
      this.primary,
    );
    this.media = new MediaViewState(
      d1Classes.momentRepository,
      this.windowHeight,
      this.primary,
    );
    this.modalRouter = new ModalRouterViewState(this.primary);
    this.snackbar = new SnackbarViewState();
    this.pendingApprovals = new PendingApprovalViewState(
      d1Classes.journalStore,
      d1Classes.journalParticipantRepository,
    );
    this.masterKey = new MasterKeyViewState(this.primary);
    this.search = new SearchViewState();
    this.toolbar = new ToolbarViewState();
  }
}

const getTestViewStates = () => {
  const primary = null;
  const calendar = null;
  const activeEntry = null;
  const modalRouter = {};
  const featureFlags = FEATURE_FLAG_NAMES.reduce(
    (acc, flag) => ({ ...acc, [flag]: false }),
    {},
  );
  return {
    primary,
    calendar,
    activeEntry,
    modalRouter,
    featureFlags,
  } as any;
};

const getViewStates = (classes: D1Classes) => {
  const viewStates = new ViewStates(classes);
  return viewStates;
};

// This global is here for convenience. It may be better for testability in the future
// to construct this class in the root component and pass it down to the rest of the app,
// but for now it's fine to have it here.
// But don't set it up in tests, because then if you enable logging in a mobx class you'll
// get multiple copies of logs, this instance, and the one you constructed in your class.
export const viewStates: ViewStates =
  process.env.NODE_ENV === "test"
    ? getTestViewStates()
    : getViewStates(d1Classes);
export const getPrimaryViewState = () => viewStates.primary;
export const getActiveEntryViewState = () => viewStates.activeEntry;
export const primaryViewState = viewStates.primary;
export const calendarViewState = viewStates.calendar;
export const activeEntryViewState = viewStates.activeEntry;
export const modalRouterViewState = viewStates.modalRouter;
export const userSettingsViewState = viewStates.userSettings;
export const featureFlagsViewState = viewStates.featureFlags;
export const snackbarViewState = viewStates.snackbar;
export const masterKeyViewState = viewStates.masterKey;
export const mediaViewState = viewStates.media;
export const searchViewState = viewStates.search;
export const syncViewState = viewStates.sync;
export const toolbarViewState = viewStates.toolbar;
