export const en = {
  "": {
    domain: "default",
    "plural-forms": "nplurals=2; plural=n != 1;",
    lang: "en",
  },
  Back: ["Back"],
  Close: ["Close"],
  "Focus Mode": ["Focus Mode"],
  "New Entry": ["New Entry"],
  "Edit date": ["Edit date"],
  More: ["More"],
  Words: ["Words"],
  Characters: ["Characters"],
  Delete: ["Delete"],
  "Are you sure you want to delete this entry?": [
    "Are you sure you want to delete this entry?",
  ],
  "All Entries": ["All Entries"],
  Entries: ["Entries"],
  Photos: ["Photos"],
  Videos: ["Videos"],
  Audio: ["Audio"],
  "Toggle Journals Sidebar": ["Toggle Journals Sidebar"],
  "Change Colors": ["Change Colors"],
  "Queued for download": ["Queued for download"],
  "No Entries": ["No Entries"],
  "Select a journal to sync its entries.": [
    "Select a journal to sync its entries.",
  ],
  "Journal name cannot be blank": ["Journal name cannot be blank"],
  "Could not create journal. Please try again": [
    "Could not create journal. Please try again",
  ],
  "Edit Journal": ["Edit Journal"],
  "New Journal": ["New Journal"],
  "Could not delete journal. Please try again": [
    "Could not delete journal. Please try again",
  ],
  "Journal Name": ["Journal Name"],
  Description: ["Description"],
  Color: ["Color"],
  "Sort Order": ["Sort Order"],
  "Entry Date": ["Entry Date"],
  "Edit Date": ["Edit Date"],
  "End-to-End Encryption": ["End-to-End Encryption"],
  "Show in On This Day": ["Show in On This Day"],
  "Show in Today View": ["Show in Today View"],
  "Conceal Content": ["Conceal Content"],
  "Create Journal": ["Create Journal"],
  "Delete Journal": ["Delete Journal"],
  Cancel: ["Cancel"],
  "By deleting this journal, all associated entries will be permanently lost. Are you sure you want to continue?":
    [
      "By deleting this journal, all associated entries will be permanently lost. Are you sure you want to continue?",
    ],
  Settings: ["Settings"],
  "queued for download": ["queued for download"],
  "Report a problem": ["Report a problem"],
  "%d Locked Journal": ["%d Locked Journal", "%d Locked Journals"],
  "Click to unlock with key": ["Click to unlock with key"],
  "Profile image must be smaller than 2MB. Please choose a new image.": [
    "Profile image must be smaller than 2MB. Please choose a new image.",
  ],
  "Edit Avatar": ["Edit Avatar"],
  "Choose New Profile Image": ["Choose New Profile Image"],
  "Your profile image has been updated": [
    "Your profile image has been updated",
  ],
  "Profile Photo": ["Profile Photo"],
  Edit: ["Edit"],
  "Day One ID": ["Day One ID"],
  "Account successfully deleted": ["Account successfully deleted"],
  "Delete Account": ["Delete Account"],
  "Permanently delete your Day One account.": [
    "Permanently delete your Day One account.",
  ],
  "Please wait...": ["Please wait..."],
  "Could not update display name at this time.": [
    "Could not update display name at this time.",
  ],
  "Edit Display Name": ["Edit Display Name"],
  Name: ["Name"],
  "Your name has been updated": ["Your name has been updated"],
  "Edit Email": ["Edit Email"],
  Email: ["Email"],
  "Email change requested! Check your email to confirm the change.": [
    "Email change requested! Check your email to confirm the change.",
  ],
  "Encryption Key": ["Encryption Key"],
  Hide: ["Hide"],
  Show: ["Show"],
  "Add your encryption key from the iOS or Mac app": [
    "Add your encryption key from the iOS or Mac app",
  ],
  Add: ["Add"],
  "Sorry, there was an error saving your key. Please check that it's correct or try again later.":
    [
      "Sorry, there was an error saving your key. Please check that it's correct or try again later.",
    ],
  "Your encryption key was successfully added. You can now view, create and manage end-to-end encrypted journals on this device":
    [
      "Your encryption key was successfully added. You can now view, create and manage end-to-end encrypted journals on this device",
    ],
  "Add Encryption Key": ["Add Encryption Key"],
  "Please provide a valid encryption key": [
    "Please provide a valid encryption key",
  ],
  Account: ["Account"],
  "Change options relating to your account.": [
    "Change options relating to your account.",
  ],
  Subscription: ["Subscription"],
  Other: ["Other"],
  "Are you sure you want to remove all of your sync data? It won't be recoverable. If you're absolutely sure you want to continue, type the exact phrase \"I want to remove my data\" (without the quotes) in the box below.":
    [
      "Are you sure you want to remove all of your sync data? It won't be recoverable. If you're absolutely sure you want to continue, type the exact phrase \"I want to remove my data\" (without the quotes) in the box below.",
    ],
  "I want to remove my data": ["I want to remove my data"],
  "Successfully removed sync data.": ["Successfully removed sync data."],
  "Remove All Sync Data": ["Remove All Sync Data"],
  "Remove Day One sync data from servers.": [
    "Remove Day One sync data from servers.",
  ],
  Remove: ["Remove"],
  "Log Out": ["Log Out"],
  "Account Status": ["Account Status"],
  Manage: ["Manage"],
  Expires: ["Expires"],
  "Will renew": ["Will renew"],
  "Premium expired on: ": ["Premium expired on: "],
  "Premium not active.": ["Premium not active."],
  Subscribe: ["Subscribe"],
  "There was a problem loading your subscription info": [
    "There was a problem loading your subscription info",
  ],
  Theme: ["Theme"],
  Light: ["Light"],
  Dark: ["Dark"],
  System: ["System"],
  "Journal Entries": ["Journal Entries"],
  "Change options relating to journal entries.": [
    "Change options relating to journal entries.",
  ],
  Display: ["Display"],
  Developer: ["Developer"],
  General: ["General"],
  Journal: ["Journal"],
  Save: ["Save"],
  "Saving...": ["Saving..."],
  Done: ["Done"],
  "Exit Focus Mode": ["Exit Focus Mode"],
  "Change Image": ["Change Image"],
  "Finish Creating Your Account": ["Finish Creating Your Account"],
  "Account successfully created.": ["Account successfully created."],
  "An error occurred, please try again.": [
    "An error occurred, please try again.",
  ],
  Password: ["Password"],
  "Your password needs to be at least 6 characters": [
    "Your password needs to be at least 6 characters",
  ],
  "Confirm password": ["Confirm password"],
  "Create Account": ["Create Account"],
  "Terms of Service": ["Terms of Service"],
  "Title to confirm journal deletion including journal name\u0004Delete %s": [
    "Delete %s",
  ],

  // Draft action bar
  entry_only_saved_locally_warning: [
    "This entry is only saved in the browser right now. Please log in or create an account to save it to a synced journal. Follow the link below and we'll bring you back here when you're done.",
  ],
  browser_only_entry: ["Browser-only entry"],
  login_or_create: ["Log in or create an account"],
  sending: ["Sending..."],
  send: ["Send"],
  pick_journal: ["Pick a journal"],
  "Loading draft": ["Loading draft"],
  draft_send_done: ["Done! We've sent your draft to its journal."],
  make_new_draft: ["Make a new one?"],
  go_to_journals: ["go to your journals"],
};
