import { ThemeUICSSObject } from "@theme-ui/css";
import { useI18n } from "@wordpress/react-i18n";
import { Reorder } from "framer-motion";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import { Sentry } from "@/Sentry";
import { ItemGroup } from "@/components/ItemGroup";
import { JournalSettingsSlideContent } from "@/components/JournalSettings/JournalSettingsSlideContent";
import { View } from "@/components/Settings/Journals";
import { EncryptedJournalsInfo } from "@/components/Settings/Journals/EncryptedJournalsInfo";
import { JournalItem } from "@/components/Settings/Journals/JournalItem";
import { SettingsPanel } from "@/components/Settings/SettingsPanel";
import { isDeepEqual } from "@/utils/is-equal";
import { primaryViewState } from "@/view_state/ViewStates";

const borderStyles: ThemeUICSSObject = {
  overflow: "hidden",
  "&& li": {
    borderRadius: "0",
  },
  "&& li.dragging-row": {
    zIndex: "2 !important",
    position: "relative",
    opacity: 1,
    "& .drag-icon": {
      display: "flex",
    },
  },
  "&& li.dragging-row > div": {
    borderRadius: "lg",
    border: "1px solid",
    borderColor: "borderPrimary",
    transform: "translateX(10px) scaleY(0.95)",
    backgroundColor: "surfaceDragOpaque",
    boxShadow: "shadow2",
    transition: "transform 0.3s ease-in-out",
  },
};
type Props = {
  onJournalSelect: (view: View) => void;
};

export const JournalList: React.FC<Props> = ({ onJournalSelect }) => {
  const { __ } = useI18n();
  const { unifiedJournals: journals } = primaryViewState;
  const [orderedJournalsUnfiltered, setOrderedJournals] = useState(journals);
  const journalStore = d1Classes.journalStore;
  const encryptedJournalCount = primaryViewState.hiddenJournalCount;

  useEffect(() => {
    if (!isDeepEqual(journals, orderedJournalsUnfiltered)) {
      setOrderedJournals(journals);
    }
  }, [
    // Convert the journal array to something that's comparable
    // by value. The journal array itself would always cause
    // useEffect to re-run, because there isn't a deep comparison
    // going on. Converting the array to a string of IDs makes
    // sure useEffect only runs when the journal array
    // changes in a way we care about.
    JSON.stringify(journals),
  ]);

  const handleReorder = () => {
    (async () => {
      const journalIds = orderedJournalsUnfiltered.map((journal) => journal.id);
      // Put the hidden journals in their original place
      const hiddenIds = await journalStore.getHiddenWithIndex();
      hiddenIds.forEach((hidden) => {
        journalIds.splice(hidden.index, 0, hidden.journalId);
      });

      journalStore.updateUnifiedJournalOrder(journalIds);
    })().catch((err) => {
      Sentry.captureException(err);
    });
  };

  return (
    <SettingsPanel header={<span>{__("Journals")}</span>}>
      <JournalSettingsSlideContent name="list" direction="right">
        <ItemGroup sx={borderStyles} addMargin={true}>
          <Reorder.Group
            values={orderedJournalsUnfiltered}
            onReorder={(r) => setOrderedJournals([...r])}
            sx={{
              "&& li:last-child": {
                borderBottom: "0",
              },
            }}
          >
            {orderedJournalsUnfiltered.map((journal) => {
              return (
                <JournalItem
                  key={journal.id}
                  journal={journal}
                  handleDragStart={() => null}
                  handleReorder={handleReorder}
                  handleClick={() => {
                    onJournalSelect({
                      primary: "edit",
                      secondary: null,
                      journalID: journal.id,
                    });
                  }}
                />
              );
            })}
          </Reorder.Group>
        </ItemGroup>
        <EncryptedJournalsInfo count={encryptedJournalCount} />
      </JournalSettingsSlideContent>
    </SettingsPanel>
  );
};
