import { Flex } from "@wordpress/components";
import { useI18n } from "@wordpress/react-i18n";

import { UpgradeCTA } from "./UpgradeCTA";

import { D1Modal } from "@/components/D1Modal";
import { FeatureList } from "@/components/PremiumUpgrade/FeatureList";
import { Reviews } from "@/components/PremiumUpgrade/Reviews";
import { useDevice } from "@/data/hooks/LayoutProvider";

type ModalProps = {
  onClose: () => void;
};

export const UpgradeModal = ({ onClose }: ModalProps) => {
  const { isMobile } = useDevice();
  const { __ } = useI18n();

  return (
    <D1Modal
      title=""
      onRequestClose={onClose}
      hideHeader
      sx={{
        width: isMobile ? "auto" : "432px",
        mt: "auto",
        mb: isMobile ? 0 : "auto",
        height: isMobile ? "calc(100dvh - 40px)" : "90%",
        maxHeight: isMobile ? "none" : "700px",
        "& .components-modal__content": {
          p: 0,
          m: 0,
          overflow: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        },
      }}
    >
      <Flex
        direction="column"
        data-testid="upgrade-modal"
        sx={{
          px: 4,
          mt: 8,
          mb: 15, // This is to make up the height of the CTA wrapper + a small margin
          gap: 0,
        }}
      >
        <Flex direction="column" align="center">
          <h3 sx={{ fontSize: 5 }}>{__(`"Well worth the money!"`)}</h3>
          <p sx={{ fontSize: 1, mt: 2, color: "textSecondary" }}>
            {__("Join 200.000+ premium journalers and get:")}
          </p>
        </Flex>
        <FeatureList />
        <Reviews />
      </Flex>
      <Flex
        direction="column"
        align="center"
        sx={{
          position: "absolute",
          minHeight: 12,
          height: "auto",
          width: "100%",
          bottom: 0,
          bg: "surface_light1_dark5",
          p: 4,
          boxShadow: "shadow2",
        }}
      >
        <UpgradeCTA type="general" color="primaryPurple" withPricingDetails />
      </Flex>
    </D1Modal>
  );
};
